// import { verifyCode } from "../../../redux/action/employerAction";
import { replace } from 'formik';
import { useSelector } from 'react-redux';
import AccountApproved from '../AddEmployer/AccountApproved';
import AccountPending from '../AddEmployer/AccountPending';
import Providers from '../../../component/Table/Table';
import VerifyEmployer from './VerifyEmploye';
import { useEffect, useState } from 'react';
import Members from '../Members/Member';
import axios from 'axios';
import { server } from '../../../redux/store';
import { decryptData } from '../../../redux/incript&decript/cryptoUtils';
import { useNavigate } from 'react-router-dom';
import DeactiveModal from '../../DeactiveModal';
import { toast } from 'react-toastify';
const Accounts = ({ uses }) => {
	const navigatge = useNavigate();
	const [open, setOpen] = useState(false);
	// const user={
	//     email_verified_at:"12",
	//     is_verified:"1"
	// }
	const { user } = useSelector(state => state.user);
	// console.log('exec', user);
	const [update, setUpdate] = useState(false);
	const [users, setUser] = useState(user);
	// console.log('new', users);
	// console.log('Redux user', user);
	const getUserData = async () => {
		const token = `Bearer ${localStorage.getItem('token')}`;

		const res = await axios.get(`${server}/profile`, {
			headers: { Authorization: token, 'Content-Type': 'application/json' },
		});
		// console.log(res);

		const dec = decryptData(res.data.encrypted_data);

		// console.log(dec);
		if (
			dec?.data.user.user_type == 'enterprise' &&
			dec?.data.user.status == 0
		) {
			if (!localStorage.getItem('employerSignup')) {
				setOpen(true);
				localStorage.setItem('deactivate', true);
				navigatge('/');
				localStorage.clear();
				return;
			}
		}
		setUser(dec?.data.user);
	};
	useEffect(() => {
		getUserData();
	}, [update]);

	return (
		<div>
			<DeactiveModal open={open} setOpen={setOpen} />
			{/* <VerifyEmployer/> */}
			{!users?.email_verified_at && (
				<VerifyEmployer
					setUpdate={setUpdate}
					getUserData={getUserData}
					update={update}
				/>
			)}
			{users?.email_verified_at && users?.is_verified == '0' && (
				<AccountPending />
			)}
			{users?.is_verified == '1' &&
				users?.status == '0' &&
				!users?.is_continue && (
					<AccountPending setUpdate={setUpdate} update={update} />
				)}
			{users?.is_verified == '1' &&
				users?.status == '-1' &&
				!users?.is_continue && (
					<AccountPending setUpdate={setUpdate} update={update} />
				)}
			{users?.is_verified == '1' &&
				users.status == '1' &&
				users?.is_continue == '0' && (
					<AccountApproved setUpdate={setUpdate} update={update} />
				)}
			{users?.status == '1' &&
			users?.is_verified == '1' &&
			users?.is_continue ? (
				<Members />
			) : (
				''
			)}

			{/* <AccountApproved/> */}
		</div>
	);
};

export default Accounts;
