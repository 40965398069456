import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Divider,
	Box,
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import PaymentConfirmed from '../../../../asset/image/PaymentConfirmed.png';

const useStyles = makeStyles({
	buttonAddnew: {
		fontSize: '24px',
		fontWeight: '500',
	},
});

const PayConf = ({
	handleOpenPayConf,
	handleClosePayConf,
	openPayConf,
	handlePaymentCOnfirmedClose,
	paymentConfirmedModal,
}) => {
	const classes = useStyles();

	const homeButton = {
		width: '96px',
		height: '46px',
		background: '#4157FF ',
		color: 'white',
		borderRadius: '24px',
		textTransform: 'none',
		color: 'white',
		fontWeight: '700',
	};

	return (
		<div>
			<Dialog
				open={paymentConfirmedModal}
				onClose={handlePaymentCOnfirmedClose}
				maxWidth='md'
			>
				<DialogTitle className={classes.buttonAddnew}>
					<IconButton
						onClick={handlePaymentCOnfirmedClose}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none',
								borderRadius: '24px',
							}}
						>
							<CloseIcon
								sx={{ margin: '8px 5px', color: 'white' }}
								onCick={handlePaymentCOnfirmedClose}
							/>
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{ marginTop: '10px' }}
					style={{ height: 'auto', overflow: 'hidden', width: '550px' }}
				>
					<div
						style={{
							display: 'grid',
							gap: '74px',
							justifyContent: 'center',
							alignContent: 'center',
							margin: '52px',
						}}
					>
						<img
							style={{ width: '245px', height: '196px' }}
							src={PaymentConfirmed}
							alt=''
						/>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
							}}
						>
							<Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
								Payment Confirmed!
							</Typography>
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
							}}
						>
							<Button style={homeButton} onClick={handlePaymentCOnfirmedClose}>
								Home
							</Button>
						</Box>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default PayConf;
