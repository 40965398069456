import './App.css';
import './component/Home/home.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
	useNavigate,
} from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import PageNotFound from './component/PageNotFound/PageNotFound';
import { toast, Toaster } from 'react-hot-toast';
import { getmyProfile, setTokenBlog } from './redux/action/user';
import Spinner from './component/Spinner/Spiner';
import subject from './helper/Rxjs';
import DynamicRoutes from './config/DynamicRoutes';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, Divider } from '@mui/material';
import { server } from './redux/store';
import LogoIcon from './asset/image/LogoIcon.png';
function App(props) {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const navigate = useNavigate();
	// useEffect(() => {
	// 	const clearLocalStorage = () => {
	// 	localStorage.clear();
	// 	};
	
	// 	window.addEventListener('beforeunload', clearLocalStorage);
	
	// 	return () => {
	// 	window.removeEventListener('beforeunload', clearLocalStorage);
	// 	};
	// }, []);

	const { isAuthenticated, user, error, message, loading } = useSelector(
		state => state.user
	);
	
	const ErrorMessage = localStorage.getItem('error_message');
	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch({
				type: 'clearError',
			});
		}
		if (message) {
			toast.success(message);
			dispatch({
				type: 'clearMessage',
			});
		}
	}, [message, user, dispatch, error]);

	useEffect(() => {
		dispatch(getmyProfile());
		dispatch(setTokenBlog());
	}, [dispatch]);
	useEffect(() => {
		subject.subscribe({
			next(x) {
				if (x == 1) {
					setOpenDialog(true);
				} else if (x == 2) {
					// navigate('/404')
				}
			},
			error(err) {
				console.error('something wrong occurred: ' + err);
			},
			complete() {
				// console.log("done");
			},
		});
	}, []);

	//DeleteAPi
	const logoutUser = async () => {
		setOpenDialog(false);
		try {
			const logoutToken = `Bearer ${localStorage.getItem('token')}`;
			// let { data } = await axios.post(
			//   `${server}/logout`,
			//   {},
			//   {
			//     headers: {
			//       "Content-Type": "application/json",
			//       Authorization: logoutToken,
			//     },
			//   }
			// );

			// toast.success(data.message);
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			localStorage.clear();
			window.location.href = `/`;
		} catch (err) {
			// console.log(err?.response);
		}
	};
	return (
		<>
			{loading ? (
				<Spinner showSpinner={isLoading} />
			) : (
				<>
					<DynamicRoutes role={user?.user_type} />
				</>
			)}
			<Toaster />

			<Dialog open={openDialog} maxWidth='sm' fullWidth={true}>
				<DialogTitle style={{ margin: 'auto' }}>
					<img
						src={LogoIcon}
						alt='logo'
						width={'192px'}

						// onClick={() => navigate("/")}
					/>{' '}
				</DialogTitle>
				<Divider />
				<DialogContent>
					<p style={{ fontWeight: 600, fontSize: '18px', textAlign: 'center' }}>
						{/* You are logout from system please login again to access the system.  */}
						{ErrorMessage}
					</p>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						onClick={() => logoutUser()}
						variant='contained'
						color='error'
					>
						Go to Login
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default App;
