import axios from 'axios';
import { server } from '../store';
import CryptoJS from 'crypto-js';
import { encryptData, decryptData } from '../incript&decript/cryptoUtils';
import { redirect, useNavigate } from 'react-router-dom';
import { async } from 'rxjs';
import { blobToBase64, convertFormDataToString } from '../../helper/indes';
import { toast } from 'react-hot-toast';
const token = `Bearer ${localStorage.getItem('token')}`;

export const login = (email, password, device_by) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({
			type: 'logingRequest',
		});
		// Encrypt login credentials before sending to the backend
		const encrypted_data = encryptData({ email, password, device_by });
		let { data } = await axios.post(
			`${server}/login`,
			{ encrypted_data: encrypted_data }, // Send the encrypted data as a single object
			{
				headers: { 'Content-Type': 'application/json' },
			}
		);

		// Decrypt the response from the backend
		const decryptedData = decryptData(data.encrypted_data);
		console.clear();
		console.log('🚀 ~ login ~ decryptedData:', decryptedData.user[0].user_type);
		console.log('🚀 ~ login ~ decryptedData:', decryptedData.user[0].status);
		// const dd = decryptData.user;

		// return;

		if (
			decryptedData.user[0].user_type == 'enterprise' &&
			decryptedData.user[0].status == '0'
		) {
			toast.error('Your account is deactivated by Admin');
			// alert('ddsfds');
			localStorage.clear();
			window.location.href = `/`;
			return;
		}

		localStorage.setItem('token', decryptedData?.access_token);
		localStorage.setItem('user', JSON.stringify(decryptedData?.user[0]));
		localStorage.setItem('login', true);
		dispatch({
			type: 'loginSuccess',
			message: decryptedData?.message,
			payload: decryptedData?.user[0],
			provider_bank_details: decryptedData?.user[0]?.provider_bank_details,
			token: `Bearer ${decryptedData.access_token}`,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		// console.log(decryptedErrorMessage, 'ErrorMessage');
		dispatch({
			type: 'loginFailure',
			payload: decryptedErrorMessage?.message,
		});
	}
};
export const getmyProfile = () => async dispatch => {
	try {
		const getToken = localStorage.getItem('token');
		if (getToken) {
			const token = `Bearer ${localStorage.getItem('token')}`;
			dispatch({ type: 'loadProfileRequest' });

			const { data } = await axios.get(`${server}/profile`, {
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			});
			const decryptedData = decryptData(data.encrypted_data);
			if (
				decryptedData?.data.user.user_type == 'enterprise' &&
				decryptedData?.data.user.status == 0
			) {
				localStorage.clear();
				redirect('/');
				localStorage.setItem('deactivate', true);
				return;
			}

			dispatch({
				type: 'loadProfileSuccess',
				payload: decryptedData.data.user,
				bankAccountDetails: decryptedData?.data?.bankAccountDetails,
				user: decryptedData.data.user,
			});
		}
	} catch (err) {
		dispatch({
			type: 'loadProfileFail',
			// payload: err.response.data.messages
		});
		console.error(err.response.data.message);
	}
};

export const getAllProviders =
	(query = '', currentPage = 1) =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "loadProviderRequest" });

			const { data } = await axios.get(
				`${server}/providers?search=${query}&page=${currentPage}`,
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data?.encrypted_data);

			dispatch({
				type: 'loadProviderSuccess',
				payload: decryptedData?.data?.providers?.data,
				page: decryptedData?.data?.providers?.last_page,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'loadProviderFail',
				payload: decryptedErrorMessage?.message,
			});
		}
	};
export const getAllEmployees =
	(query = '', currentPage = 1) =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "getAllEmployeeRequest" });

			const { data } = await axios.get(
				`${server}/get_all_enterprise?search=${query}&page=${currentPage}`,
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'getAllEmployeeSuccess',
				payload: decryptedData?.data?.enterprise?.data,
				page: decryptedData?.data?.enterprise?.last_page,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getAllEmployeeFail',
				payload: decryptedErrorMessage.message,
			});
			console.error(err.response.data.message);
		}
	};
export const getAllHospitalDisputes =
	(query = '') =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "getAllEmployeeRequest" });

			const { data } = await axios.get(
				`${server}/get_dispute_payment_request?search=${query}`,
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'getAllDisputesSuccess',
				payload: decryptedData?.data,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getAllDisputesFail',
				payload: decryptedErrorMessage.message,
			});
		}
	};
export const getAllPaymentRequestAction =
	(query = '') =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "getAllEmployeeRequest" });

			const { data } = await axios.get(
				`${server}/get_payment_request/?search=${query}`,
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);

			dispatch({
				type: 'getAllPaymentRequestSuccess',
				payload: decryptedData?.payments,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getAllDisputesFail',
				payload: decryptedErrorMessage.message,
			});
			console.error(err.response.data.message);
		}
	};
export const getAllLogsAction =
	(patientFilter = '', adminFilter = '') =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "loadProviderRequest" });

			const { data } = await axios.get(
				`${server}/get_user_logs?user_id=${patientFilter}&viewer_id=${adminFilter}`,
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'getAllLogsSuccess',
				payload: decryptedData?.data,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getAllLogsFail',
				payload: decryptedErrorMessage.message,
			});
		}
	};

export const logoutUser = (email, password) => async dispatch => {
	try {
		// dispatch({
		//   type: "logoutRequest",
		// });

		// dispatch({
		//   type: "logoutSuccess",
		//   // payload: data.message,
		// });
		const logoutToken = `Bearer ${localStorage.getItem('token')}`;

		let { data } = await axios.post(
			`${server}/logout`,
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: logoutToken,
				},
			}
		);
		//
		window.location.href = `/`;
		localStorage.clear();
	} catch (err) {
		// console.log(err.response.data.message);
		dispatch({
			type: 'logoutFailure',
			payload: err.response.data.message,
		});
	}
};
export const verifyProviders = (id, status, token) => async dispatch => {
	try {
		dispatch({ type: 'verifyProviderRequest' });

		const { data } = await axios.post(
			`${server}/update_verification_status/${id}`,
			{
				status: status,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'verifyProviderSuccess',
			payload: decryptedData?.message,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'verifyProviderFail',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.data.message);
	}
};
export const paymentStatusUpdate = (id, status) => async dispatch => {
	try {
		const paymentStatusToken = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'verifyProviderRequest' });

		const { data } = await axios.post(
			`${server}/update_payment_status/${id}`,
			{
				payment_status: status,
			},
			{
				headers: {
					Authorization: paymentStatusToken,
					'Content-Type': 'application/json',
				},
			}
		);

		dispatch({
			type: 'verifyProviderSuccess',
			payload: data.message,
		});
	} catch (err) {
		dispatch({
			type: 'verifyProviderFail',
			payload: err.response.data.message,
		});
		console.error(err.response.data.message);
	}
};

export const getUserData = (id, status) => async dispatch => {
	if (localStorage.getItem('employerSignup')) return;
	try {
		const getUserToken = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'getUserRequest' });

		const { data } = await axios.post(
			`${server}/get_user_data/${id}`,
			{
				payment_status: status,
			},
			{
				headers: {
					Authorization: getUserToken,
					'Content-Type': 'application/json',
				},
			}
		);

		dispatch({
			type: 'getUserSuccess',
			payload: data.message,
		});
	} catch (err) {
		dispatch({
			type: 'getUserFail',
			payload: err.response.data.message,
		});
		console.error(err.response.data.message);
	}
};
export const setTokenBlog = (id, status) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({
			type: 'tokenSet',
			token: token,
		});
	} catch (err) {
		console.error(err.response.data.message);
	}
};

export const changeAdminPassword =
	(old_password, new_password, confirm_password) => async dispatch => {
		try {
			const getUserToken = `Bearer ${localStorage.getItem('token')}`;
			dispatch({ type: 'updateAdminPasswordRequest' });
			const encrypted_data = encryptData({
				old_password: old_password,
				password: new_password,
				confirm_password: confirm_password,
			});
			const { data } = await axios.post(
				`${server}/change_admin_password `,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: {
						Authorization: getUserToken,
						'Content-Type': 'application/json',
					},
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'updateAdminPasswordSuccess',
				payload: decryptedData.message,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'updateAdminPasswordFail',
				payload: decryptedErrorMessage.message,
			});
		}
	};

export const SignupEmployer =
	({
		first_name,
		company,
		address,
		email,
		phone_number,
		password,
		confirm_password,
		user_type,
	}) =>
	async dispatch => {
		try {
			// const navigate=useNavigate()
			const token = `Bearer ${localStorage.getItem('token')}`;
			dispatch({
				type: 'signupRequest',
			});

			let { data } = await axios.post(
				`${server}/enterprise_registration`,
				{
					first_name,
					company,
					address,
					email,
					phone_number,
					password,
					confirm_password,
					user_type,
				},
				{
					headers: { 'Content-Type': 'application/json' },
				}
			);

			const { data1 } = await axios.get(`${server}/profile`, {
				headers: {
					Authorization: `Bearer ${data?.data?.access_token}`,
					'Content-Type': 'application/json',
				},
			});
			localStorage.setItem('token', data?.data?.access_token);
			localStorage.setItem('user', data1?.data?.user);

			dispatch({
				type: 'signupSuccess',
				payload: data?.message,
				employerEmail: email,
				payload: data?.user,
			});
			//   window.location.href='/verify'
		} catch (err) {
			// console.log(err.response.data.message);
			dispatch({
				type: 'signupFail',
				payload: err.response.data.message,
			});
		}
	};

//
export const verifyCode1 = (email, verification_code) => async dispatch => {
	try {
		// const navigate=useNavigate()
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({
			type: 'verifyRequest',
		});

		dispatch({
			type: 'continueSuccess',
			// payload: data?.message,
			// user: data?.user,
		});
		//   window.location.href='/verify
	} catch (err) {
		// console.log(err.response.data.message);
		dispatch({
			type: 'continueFail',
			payload: err.response.data.message,
		});
	}
};

//

//  2nd work

// export const AddEmployerAction =

//   ({ first_name, company, company_address, email, phone }) =>
//     async (dispatch) => {
//     try {
//       const token = `Bearer ${localStorage.getItem("token")}`;
//       dispatch({
//         type: "addEmployerRequest",
//       });

//       let { data } = await axios.post(
//         `${server}/enterprise_registration_by_admin`,
//         {
//           first_name,
//           company_name: company,
//           company_address,
//           email,
//           phone_number: phone,
//         },
//         {
//           headers: { Authorization: token, "Content-Type": "application/json" },
//         }
//       );

//       dispatch({
//         type: "addEmployerSuccess",
//         message: data?.message,
//       });
//       // if (data?.message) {
//       //   Navigate('/employers')
//       // }

//       // Navigate('/employers')

//       //   window.location.href='/verify'
//     } catch (err) {
//       console.log(err.response.data.message);
//       dispatch({
//         type: "addEmployerFail",
//         payload: err.response.data.message,
//       });
//     }
//   };

export const getEnterpriseById = id => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "getSingleEmployerRequest" });
		const { data } = await axios.get(
			`${server}/get_enterprise_by_id?id=${id}`,
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);

		dispatch({
			type: 'getSingleEmployerSuccess',
			payload: decryptedData[0],
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getSingleEmployerFail',
			payload: decryptedErrorMessage.message,
		});
	}
};
export const getAllEnterprise = () => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'getAllEmployerRequest' });

		const { data } = await axios.get(`${server}/get_all_enterprise`, {
			headers: { Authorization: token, 'Content-Type': 'application/json' },
		});
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'getAllEmployerSuccess',
			payload: decryptedData?.enterprise?.data,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getAllEmployerFail',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.data.message);
	}
};

//resetpassword work
export const ResetPasswordEnterprise = (email, status) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'GetCodeRequest' });
		const encrypted_data = encryptData({ email, status: 1 });
		const { data } = await axios.post(
			`${server}/password_reset`,
			{ encrypted_data: encrypted_data },
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'GetCodeRequestSuccess',
			message: decryptedData.message,
			payload: decryptedData.message,
		});
	} catch (error) {
		const decryptedErrorMessage = decryptData(error?.response?.data?.encrypted_data);

		dispatch({
			type: 'GetCodeRequestFail',
			payload: decryptedErrorMessage.message,
		});
	}
};

//  Singup Employer

export const SignupEnterprise = formdata => async dispatch => {
	try {
		dispatch({ type: 'signupEnterpriseRequest' });

		let { data } = await axios.post(
			`${server}/enterprise_registration`,
			{
				first_name: formdata?.first_name,
				address: formdata?.address,
				phone_number: formdata?.phone_number,
				password: formdata.password,
				confirm_password: formdata?.confirm_password,
				email: formdata.email,
				company: formdata.company,
				user_type: 'enterprise',
			},
			{
				headers: { 'Content-Type': 'application/json' },
			}
		);
		localStorage.setItem('token', data?.data?.access_token);
		dispatch({
			type: 'signupEnterpriseSuccess',
			message: data?.message,
			token: data?.data?.access_token,
		});
	} catch (err) {
		// console.log('err?.response?', err?.response);
		dispatch({
			type: 'signupEnterpriseFail',
			payload: err?.response?.data?.message,
		});
		console.error(err?.response?.data?.message);
	}
};

// Verify code

export const verifyCode = (email, code, callback) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'verifyCodeRequest' });
		const encrypted_data = encryptData({
			email: email,
			verification_code: code,
		});
		let { data } = await axios.post(
			`${server}/verify_code_web`,
			{
				encrypted_data: encrypted_data,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'verifyCodeSuccess',
			message: decryptedData?.message,
		});
		// Call the callback function with the success data
		if (callback && typeof callback === 'function') {
			callback(null, decryptedData?.message);
		}
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'verifyCodeFail',
			payload: decryptedErrorMessage?.message,
		});
		// Call the callback function with the error data
		if (callback && typeof callback === 'function') {
			callback(decryptedErrorMessage?.message, null);
		}

		console.error(err?.response?.data?.message);
	}
};
// Verify code

export const resendVerifyCode = email => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'resendCodeRequest' });

		const encrypted_data = encryptData({ email: email });
		let { data } = await axios.post(
			`${server}/resend_code`,
			{
				encrypted_data: encrypted_data,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'resendCodeSuccess',
			message: decryptedData?.message,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'resendCodeFail',
			payload: decryptedErrorMessage?.message,
		});
		console.error(err?.response?.data?.message);
	}
};

//  Approve Enterprise Account By Admin

export const EnterpriseAccountApproal = (email, status) => async dispatch => {
	try {
		dispatch({ type: 'enterpriseAccountApproalRequest' });

		const token = `Bearer ${localStorage.getItem('token')}`;
		const encrypted_data = encryptData({
			email,
			status: ['0', '-1'].includes(status) ? '1' : '0',
		});
		const gg = decryptData(encrypted_data);

		let { data } = await axios.post(
			`${server}/admin_approved_enterprise`,
			{
				encrypted_data: encrypted_data,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);

		dispatch({
			type: 'enterpriseAccountApproalSuccess',
			message: decryptedData?.message,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'enterpriseAccountApproalFail',
			payload: decryptedErrorMessage?.message,
		});
	}
};

// Continue Enterprise Account

export const continueEnterpriseAccount =
	(email, is_continue, datamessagfe = '') =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			dispatch({ type: 'enterpriseContinueRequest' });
			const encrypted_data = encryptData({
				email: email,
				is_continue: is_continue,
			});
			let { data } = await axios.post(
				`${server}/update_continue_status`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			if (decryptedData) {
				dispatch({
					type: 'enterpriseContinueSuccess',
					message: decryptedData?.message,
				});
			}
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'enterpriseContinueFail',
				payload: decryptedErrorMessage?.message,
			});
			console.error(err?.response?.data?.message);
		}
	};
// Continue Enterprise Account

export const getInvoiceById = month => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'getInvoiceByIDRequest' });

		let { data } = await axios.get(
			`${server}/get_invoice_by_id/?month/${month}`,
			{},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);

		const decryptedData = decryptData(data.encrypted_data);

		dispatch({
			type: 'getInvoiceByIDSuccess',
			payload: decryptedData?.data,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);

		dispatch({
			type: 'getInvoiceByIDFail',
			payload: decryptedErrorMessage?.message,
		});
		console.error(err?.response?.data?.message);
	}
};

export const userRecords = user => async dispatch => {
	try {
		dispatch({
			type: 'singleMemberRecordSuccess',
			payload: user,
		});
	} catch (err) {
		// console.log('err?.response?', err?.response);
		dispatch({
			type: 'singleMemberFail',
			payload: err?.response?.data?.message,
		});
		console.error(err?.response?.data?.message);
	}
};
export const singleMemeberAdd = formData => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'singleMemberRequest' });

		let { data } = await axios.post(
			`${server}/formData`,
			{
				email: formData?.email,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);

		dispatch({
			type: 'singleMemberSuccess',
			message: data?.message,
		});
	} catch (err) {
		// console.log('err?.response?', err?.response);
		dispatch({
			type: 'singleMemberFail',
			payload: err?.response?.data?.message,
		});
		console.error(err?.response?.data?.message);
	}
};
export const settingUpdate = (formData, userprofile) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'settingRequest' });

		const encrypted_data = encryptData({
			first_name: formData?.first_name,
			last_name: formData?.last_name,
			profile_image: formData?.profile_image,
			country: formData?.country,
		});
		let { data } = await axios.post(
			`${server}/update_personal_info`,
			{
				encrypted_data: encrypted_data,
			},
			{
				headers: {
					Authorization: token,
					'Content-Type': 'multipart/form-data',
				},
			}
		);

		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'settingSuccess',
			message: decryptedData?.message,
			payload: decryptedData?.data,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'settingFail',
			payload: decryptedErrorMessage?.message,
		});
	}
};

export const updateAccountRouting = data => async dispatch => {
	dispatch({
		type: 'billingInfoUpdateSuccess',
		payload: data,
	});
};

export const CSVFileRecord = (user, file) => async dispatch => {
	dispatch({
		type: 'bulkMemberRecordSuccess',
		payload: user,
		file: file,
	});
};

export const updateEnterprisePaymentStatus = (id, status) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'updateEnterprisePaymentStatusRequest' });

		const { data } = await axios.post(
			`${server}/update_payment_status/${id}`,
			{
				payment_status: status,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);

		dispatch({
			type: 'updateEnterprisePaymentStatusSuccess',
			payload: data.message,
		});
	} catch (err) {
		dispatch({
			type: 'updateEnterprisePaymentStatusFail',
			payload: err.response.data.message,
		});
		console.error(err.response.data.message);
	}
};
export const updateProviderProfileByAdmin =
	(
		provider_id,
		phone_number,
		reset_password,
		profile,
		location,
		latitude,
		longitude
	) =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			dispatch({ type: 'updateProviderProfileByAdminRequest' });

			const encrypted_data = encryptData({
				provider_id,
				phone_number,
				reset_password,
				profile,
				location,
				latitude,
				longitude,
			});
			const { data } = await axios.post(
				`${server}/admin_update_provider_data`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);

			dispatch({
				type: 'updateProviderProfileByAdminSuccess',
				payload: decryptedData.message,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);

			dispatch({
				type: 'updateProviderProfileByAdminFail',
				payload: decryptedErrorMessage.message,
			});
			console.error(err.response.data.message);
		}
	};
export const adminStatusOffHandle = id => async dispatch => {
	try {
		const getToken = localStorage.getItem('token');
		if (getToken) {
			const token = `Bearer ${localStorage.getItem('token')}`;
			dispatch({ type: 'adminStatusOffRequest' });

			const encrypted_data = encryptData({ id: id });
			const { data } = await axios.post(
				`${server}/user_token_expire`,
				{ encrypted_data: encrypted_data },
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'adminStatusOffSuccess',
				message: decryptedData?.message,
				payload: decryptedData?.data,
			});
		}
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'adminStatusOffFail',
			payload: decryptedErrorMessage.messages,
		});
		console.error(err.response.data.message);
	}
};
export const adminStatusOnHandle = id => async dispatch => {
	try {
		const getToken = localStorage.getItem('token');
		if (getToken) {
			const token = `Bearer ${localStorage.getItem('token')}`;
			dispatch({ type: 'adminStatusOnRequest' });

			const { data } = await axios.post(
				`${server}/update_user_token`,
				{ id: id },
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);

			dispatch({
				type: 'adminStatusOnSuccess',
				message: data?.message,
				payload: data?.data,
			});
		}
	} catch (err) {
		dispatch({
			type: 'adminStatusOnFail',
			payload: err.response.data.messages,
		});
		console.error(err.response.data.message);
	}
};
export const deleteAdmin = userId => async dispatch => {
	try {
		const getToken = localStorage.getItem('token');
		if (getToken) {
			const token = `Bearer ${localStorage.getItem('token')}`;
			dispatch({ type: 'adminDeleteRequest' });
			const encrypted_data = encryptData({ id: userId });
			const { data } = await axios.post(
				`${server}/deleteadmins`,
				{ encrypted_data: encrypted_data },
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: token,
					},
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'adminDeleteSuccess',
				message: decryptedData?.message,
				payload: decryptedData?.data,
			});
		}
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'adminDeleteFail',
			payload: decryptedErrorMessage.messages,
		});
		console.error(err.response.data.message);
	}
};

// Get ALl Admins & super Admins
export const getAllAdmins =
	(query = '') =>
	async dispatch => {
		try {
			const getToken = localStorage.getItem('token');
			if (getToken) {
				if (!query) {
					dispatch({
						type: 'getAllAdminRequest',
					});
				}
				const token = `Bearer ${localStorage.getItem('token')}`;
				const { data } = await axios.get(
					`${server}/get_all_admins?name=${query}`,
					{
						headers: {
							Authorization: token,
							'Content-Type': 'application/json',
						},
					}
				);
				const decryptedData = decryptData(data.encrypted_data);

				dispatch({
					type: 'getAllAdminSuccess',
					payload: decryptedData?.data,
				});
			}
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getAllAdminFail',
				payload: decryptedErrorMessage.messages,
			});
			console.error(err.response.data.message);
		}
	};

// Get ALl Admins & super Admins
export const getAllAdminInvices =
	(search = '', status = '', month = '', year = '') =>
	async dispatch => {
		if (!search && !status && !month && !year) {
			dispatch({
				type: 'getAllAdminInvoicesRequest',
			});
		}
		try {
			const getToken = localStorage.getItem('token');
			if (getToken) {
				const token = `Bearer ${localStorage.getItem('token')}`;
				// const { data } = await axios.get(`${server}/EnterprisesInvoicesHistory`,
				const { data } = await axios.get(
					`${server}/EnterprisesInvoicesHistory?search=${search}&status=${status}&month=${month}&year=${year}`,
					{
						headers: {
							Authorization: token,
							'Content-Type': 'application/json',
						},
					}
				);
				const decryptedData = decryptData(data.encrypted_data);
				dispatch({
					type: 'getAllAdminInvoicesSuccess',
					payload: decryptedData?.data?.enterprises_invoices_history,
				});
			}
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getAllAdminInvoicesFail',
				payload: decryptedErrorMessage.messages,
			});
		}
	};
// Get ALl Admins & super Admins
export const getAdminInvicesDetailed =
	(id, month, year, searchData) => async dispatch => {
		try {
			if (!searchData) {
				dispatch({
					type: 'getAdminInvicesDetailedRequest',
				});
			}
			const getToken = localStorage.getItem('token');
			if (getToken) {
				const token = `Bearer ${localStorage.getItem('token')}`;
				const { data } = await axios.get(
					`${server}/EnterprisesInvoicesHistoryDetails?enterprise_id=${id}&month=${month}&year=${year}&search=${searchData}`,
					{
						headers: {
							Authorization: token,
							'Content-Type': 'application/json',
						},
					}
				);
				const decryptedData = decryptData(data.encrypted_data);

				dispatch({
					type: 'getAdminInvicesDetailedSuccess',
					payload: decryptedData?.data?.EnterprisesInvoicesHistoryDetails,
					message: decryptedData?.data?.message,
				});
			}
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getAdminInvicesDetailedFail',
				payload: decryptedErrorMessage.messages,
			});
			console.error(err.response.data.message);
		}
	};

// Get All Patient for filter in logs
export const getAllPatient = () => async dispatch => {
	try {
		const getToken = localStorage.getItem('token');
		if (getToken) {
			const token = `Bearer ${localStorage.getItem('token')}`;
			const { data } = await axios.get(`${server}/get_all_patients`, {
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			});
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'getAllPatentForFilterSuccess',
				payload: decryptedData?.data,
			});
		}
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getAllPatentForFilterFail',
			payload: decryptedErrorMessage.messages,
		});
	}
};
// Get All super admin and admin for filter in logs
export const getAllSuperAdmins = () => async dispatch => {
	try {
		const getToken = localStorage.getItem('token');
		if (getToken) {
			const token = `Bearer ${localStorage.getItem('token')}`;
			const { data } = await axios.get(`${server}/get_all_super_sub_admins`, {
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			});
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'getAllAdminForFilterSuccess',
				payload: decryptedData?.data,
			});
		}
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getAllAdminForFilterFail',
			payload: decryptedErrorMessage.messages,
		});
	}
};

// Monthly Expenses
export const getMonthlyInvoice = async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		const { data } = await axios.get(`${server}/monthly_invoice`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
		});

		const decryptedData = decryptData(data?.encrypted_data);
		const roundedValue = decryptedData?.data?.totalDebt.toFixed(2);

		dispatch({
			type: 'getMonthlyInvoiceSuccess',
			payload: roundedValue,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(err?.response?.encrypted_data);
		dispatch({
			type: 'getAllAdminForFilterFail',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.message);
	}
};

// Get total member
export const getAllUsers = async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		const { data } = await axios.get(`${server}/total_users`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
		});
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'getAllUsersSuccess',
			payload: decryptedData,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getAllUsersFail',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.message);
	}
};

// AutoDebitApi

export const AutoDebitAPI = async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		const { data } = await axios.get(`${server}/auto_debit`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
		});
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'AutoDebitAPISuccess',
			payload: decryptedData?.data,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(err?.response?.encrypted_data);
		dispatch({
			type: 'AutoDebitAPIFail',
			payload: err.decryptedErrorMessage.message,
		});
		console.error(err.response.message);
	}
};
// admin_send_email_to_enterprise
export const sendEmailToEnterprise = id => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		const encrypted_data = encryptData({ id });
		const { data } = await axios.post(
			`${server}/admin_send_email_to_enterprise`,
			{ encrypted_data: encrypted_data },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: token,
				},
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'sendEmailToEnterpriseSuccess',
			message: decryptedData?.message,
			payload: decryptedData?.data,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'sendEmailToEnterpriseFail',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.message);
	}
};

// ||||||||||||||||||||||||||||||||| Detail |||||||||||||||||||||||||||||||||||||||||||||||

export const getAllDisputePaymentRequest = uid => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "loadDisputePaymentRequest" });

		const { data } = await axios.get(
			`${server}/get_dispute_payment_request_details?id=${uid}`,
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'loadDisputePaymentSuccess',
			payload: decryptedData?.payments,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'loadDisputePaymentFail',
			payload: decryptedErrorMessage.message,
		});
	}
};
export const getAllPaymentRequestDetail = uid => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "loadDisputePaymentRequest" });

		const { data } = await axios.get(
			`${server}/get_payment_request_details?id=${uid}`,
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'getAllPaymentRequestDetailSuccess',
			payload: decryptedData,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getAllPaymentRequestDetailFail',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.data.message);
	}
};
export const getUserLogs =
	(uid, user_id, viewer_id, message) => async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "loadDisputePaymentRequest" });

			const { data } = await axios.post(
				`${server}/user_logs?id=${uid}`,
				{
					user_id: user_id,
					viewer_id: viewer_id,
					message: message,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
		} catch (err) {
			// dispatch({
			//   type: "getAllPaymentRequestDetailFail",
			//   payload: err.response.data.message,
			// });
			console.error(err.response.data.message);
		}
	};
export const getEnterpriseInvoices = enterpriseId => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "loadDisputePaymentRequest" });

		const { data } = await axios.get(
			`${server}/get_invoice?id=${enterpriseId}`,
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'getInvoiceByEnterpriseSuccess',
			payload: decryptedData?.data,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getInvoiceByEnterpriseFailure',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.data.message);
	}
};
export const adminRequestFOrDisputes =
	(payment_id, status, description, timestamp) => async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "adminRequestForDisputesRequest" });
			const encrypted_data = encryptData({
				payment_id: payment_id,
				status: status,
				description: description,
				timestamp: timestamp,
			});
			const { data } = await axios.post(
				`${server}/admin_request_for_dispute`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'adminRequestForDisputesSuccess',
				payload: decryptedData?.success,
				message: decryptedData?.message,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'adminRequestForDisputesFailure',
				payload: decryptedErrorMessage.message,
			});
		}
	};

//  Admin update profile
export const adminUpdateProfile = (uid, img) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'adminUpdateProviderDataRequest' });
		const formData = new FormData();
		formData.append('image', img);
		formData.append('provider_id', uid);
		const { data } = await axios.post(
			`${server}/admin_update_provider_data`,
			formData,
			{
				headers: {
					Authorization: token,
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'adminUpdateProviderDataSuccess',
			payload: decryptedData?.provider?.profile_image,
			message: decryptedData?.message,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'adminUpdateProviderDataFailure',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.data.message);
	}
};

//  Get User Data

export const getProviderTransactionAction = uid => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "getProviderTransactionRequest" });

		const { data } = await axios.get(
			`${server}/get_payment_request/?provider_id=${uid}`,

			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);

		dispatch({
			type: 'getProviderTransactionSuccess',
			payload: decryptedData,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getProviderTransactionFailure',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.data.message);
	}
};
//  Get User Data

export const getUserDatainProvider = uid => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "getUserDetailInProvierRequest" });

		const { data } = await axios.get(
			`${server}/get_user_data/${uid}`,

			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);

		dispatch({
			type: 'getUserDetailInProvierSuccess',
			payload: decryptedData?.data,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getUserDetailInProvierFailure',
			payload: decryptedErrorMessage?.message,
		});
	}
};

// -------------------------------------------------------------------------------------------------

///////  Enterprise

export const invoicesOfEnterprise = () => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "enterpriseInvoicesRequest" });

		const { data } = await axios.get(
			`${server}/get_invoice`,

			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		// console.log(decryptedData, 'sasasaas2121');
		const updatedData = decryptedData?.data.map(item => ({
			...item,
			total_amount:
				item.total_amount !== null ? Number(item.total_amount.toFixed(3)) : 0,
		}));

		dispatch({
			type: 'enterpriseInvoicesSuccess',
			payload: updatedData,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'enterpriseInvoicesFailure',
			payload: decryptedErrorMessage?.message,
		});
	}
};
export const getmembersInEnterprise =
	(query = '', currentPage = 1) =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "enterpriseInvoicesRequest" });

			const { data } = await axios.get(
				`${server}/get_all_members?search=${query}&page=${currentPage}`,

				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'getAllMemberSuccess',
				payload: decryptedData?.data?.members?.data,
				totalPages: decryptedData?.data?.members?.last_page,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getAllMemberFailure',
				payload: decryptedErrorMessage.message,
			});
			console.error(err.response.data.message);
		}
	};
// addSingleMember
export const addSingleMember = recordUser => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'addSingleMemberRequest' });

		const encrypted_data = encryptData({ ...recordUser });
		let { data } = await axios.post(
			`${server}/deduct-And-Transfer-single-user`,
			{
				encrypted_data: encrypted_data,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'addSingleMemberSuccess',
			payload: decryptedData.message,
		});
	} catch (error) {
		const decryptedErrorMessage = decryptData(
			error?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'addSingleMemberFail',
			payload: decryptedErrorMessage.message,
		});
	}
};
// addBulkMembers
export const addBulkMembers = (csvFile, countUser) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		const encrypted_data = encryptData({
			csv_file: csvFile,
			totalUser: countUser,
		});
		let { data } = await axios.post(
			`${server}/deduct-And-Transfer-bulk-user`,
			{
				encrypted_data: encrypted_data,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'addBulkMembersSuccess',
			payload: decryptedData.message,
		});
	} catch (error) {
		const decryptedErrorMessage = decryptData(
			error?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'addBulkMembersFail',
			payload: decryptedErrorMessage.message,
		});
	}
};

//  Delete Member By enterprise
export const deleteMemberByEnterprise = id => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'deleteMemberByEnterpriseRequest' });
		const encrypted_data = encryptData({ id });
		const { data } = await axios.post(
			`${server}/delete_member`,
			{ encrypted_data: encrypted_data },
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'deleteMemberByEnterpriseSuccess',
			payload: decryptedData.message,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'deleteMemberByEnterpriseFailure',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.data.message);
	}
};

//  Total Charges in enterprise
export const totalChargesInEnterprise =
	(email, type, totalUser, grandTotal) => async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "totalChargesEnterpriseRequest" });

			const { data } = await axios.post(
				`${server}/StripePayoutToAdmin`,
				{
					totalUser,
					email,
					type,
					grandTotal,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);

			dispatch({
				type: 'totalChargesEnterpriseSuccess',
				payload: data,
			});
		} catch (err) {
			dispatch({
				type: 'totalChargesEnterpriseFailure',
				payload: err.response.data.message,
			});
			console.error(err.response.data.message);
		}
	};

//  Total charges in setting in enterprise
export const addBillingInfoEnterprise =
	(token_stripe, status, auto_debit) => async dispatch => {
		
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			dispatch({ type: 'billingInfoUpdateRequest' });
			const encrypted_data = encryptData({stripe_token:token_stripe, status,auto_debit});
			const { data } = await axios.post(
				`${server}/add_billing_info`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);

			if (decryptedData?.success === false) {
				dispatch({
					type: 'billingInfoUpdateSuccessfalse',
					payload: 'You must use a test bank account number in test mode.',
					isSuccess: false,
				});
				// return toast.error('You must use a test bank account number in test mode.')
			} else {
				dispatch({
					type: 'billingInfoUpdateSuccess',
					payload: decryptedData,
					message: decryptedData?.message,
					isSuccess: true,
				});
			}
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'billingInfoUpdateFailure',
				payload: decryptedErrorMessage.message,
			});
		}
	};

export const clearBillingStateAfterRouting = () => async dispatch => {
	dispatch({ type: 'clearBillingState' });
};
//  Total charges in setting in enterprise
export const updateBillingInfoEnterprise =
	(token_stripe,status,auto_debit) => async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			dispatch({ type: 'billingInfoUpdateRequest' });
			const encrypted_data = encryptData({stripe_token:token_stripe,status,auto_debit});
			
			const { data } = await axios.post(
				`${server}/updateBankDetail_by_enterprise`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			console.log(decryptedData, 'asdasdsas');
			if (decryptedData?.success == false) {
				dispatch({
					type: 'billingInfoUpdateSuccessfalse',
					payload: 'You must use a test bank account number in test mode.',
					isSuccess: false,
				});
				// return toast.error('You must use a test bank account number in test mode.')
			} else {
				dispatch({
					type: 'billingInfoUpdateSuccess',
					payload: decryptedData,
					message: decryptedData?.message,
					// isSuccess: true,
				});
			}
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'billingInfoUpdateFailure',
				payload: decryptedErrorMessage.message,
			});
			console.error(err.response.data.message);
		}
	};
export const errorBillingInfoAction = () => async dispatch => {
	dispatch({
		type: 'errorBillingInfoClear',
	});
};

// Phase 2

export const getAllServicesInAdmin = () => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "billingInfoUpdateRequest" });

		const { data } = await axios.get(
			`${server}/search-By-service-with-fillter`,
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'getAllServicesSuccess',
			payload: decryptedData?.services,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getAllServicesFailure',
			payload: decryptedErrorMessage.message,
		});
	}
};
export const getAllSpecializtionInAdmin = () => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "billingInfoUpdateRequest" });

		const { data } = await axios.get(`${server}/search_By_specilization`, {
			headers: { Authorization: token, 'Content-Type': 'application/json' },
		});
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'getAllSpecilizationSuccess',
			payload: decryptedData?.specilization,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getAllSpecilizationFailure',
			payload: decryptedErrorMessage.message,
		});
		console.error(err.response.data.message);
	}
};
export const addOutOfNetwoek =
	(
		name,
		email,
		phone,
		longitude,
		latitude,
		location,
		provider_image,
		consultation,
		services
	) =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "billingInfoUpdateRequest" });
			const formData = new FormData();
			formData.append('name', name);
			formData.append('email', email);
			formData.append('latitude', latitude);
			formData.append('longitude', longitude);
			formData.append('location', location?.label);
			formData.append('phone', phone);
			// formData.append('profile_image', provider_image);
			formData.append('consultation_price', consultation?.dollar);
			formData.append('consultation_description', consultation?.discription);
			formData.append('consultation_name', consultation?.Consultation);
			// formData.append("service_name", services);
			services.forEach((service, index) => {
				formData.append(`service_name[${index}]`, service.service_name);
				formData.append(`specialization[${index}]`, service.specialization);
				formData.append(`price[${index}]`, service.price);
				formData.append(`service_id[${index}]`, service.service_id);
				formData.append(
					`specialization_id[${index}]`,
					service.specialization_id
				);
				formData.append(`description[${index}]`, service.description);
				// formData.append(`image[${index}]`, service.image);
			});
			// Convert FormData to string
			const stirngFomrData = await convertFormDataToString(formData);

			// Encrypt the string using CryptoJS
			const params = new URLSearchParams(stirngFomrData);

			const resultObject = {};

			for (const [key, value] of params) {
				if (/\[.*\]$/.test(key)) {
					// If the key contains square brackets, treat it as an array
					const arrayKey = key.replace(/\[.*\]$/, '');
					resultObject[arrayKey] = resultObject[arrayKey] || [];
					resultObject[arrayKey].push(value);
				} else {
					// Otherwise, treat it as a regular key-value pair
					resultObject[key] = value;
				}
			}

			const encrypted_data = encryptData(resultObject);

			const { data } = await axios.post(
				`${server}/out_of_network_provider_registration`,
				{ encrypted_data: encrypted_data, profile_image: provider_image },
				{
					headers: {
						Authorization: token,
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			// console.log(data);

			if (data?.encrypted_data) {
				const decryptedData = decryptData(data.encrypted_data);
				dispatch({
					type: 'addOutOfNetworkSuccess',
					payload: decryptedData?.message,
				});
			}
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'addOutOfNetworkFailure',
				payload: decryptedErrorMessage.message,
			});
		}
	};
export const getDetailOutOfNetwork = id => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "billingInfoUpdateRequest" });

		const { data } = await axios.get(
			`${server}/out_network_cases_details/?id=${id}`,
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);

		dispatch({
			type: 'getDetailOutOfNetworkSuccess',
			payload: decryptedData?.cases,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getDetailOutOfNetworkFailure',
			payload: decryptedErrorMessage.message,
		});
	}
};
export const getAllOutOfNetworkCase = (query) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "billingInfoUpdateRequest" });

		const { data } = await axios.get(`${server}/out_network_cases?search=${query}`, {
			headers: { Authorization: token, 'Content-Type': 'application/json' },
		});
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'getAllOutOfNetworkSuccess',
			payload: decryptedData?.cases,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getAllOutOfNetworkFailure',
			payload: decryptedErrorMessage.message,
		});
	}
};
// Self_pay Patient

export const getSelfPayPatient =
	(query = '') =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "getAllEmployeeRequest" });

			const { data } = await axios.get(
				`${server}/get_selfPayPatient/?search=${query}`,
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'getSelfPayPatientSuccess',
				payload: decryptedData?.patient,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getSelfPayPatientFail',
				payload: decryptedErrorMessage.message,
			});
		}
	};
// SlefPayPatientDetails
export const getSelfPayPatientDetails = uid => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "loadDisputePaymentRequest" });

		const { data } = await axios.get(
			`${server}/get_selfPayPatientDetails?id=${uid}`,
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'getSelfPayPatientDetailsSuccess',
			payload: decryptedData,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getSelfPayPatientDetailslFail',
			payload: decryptedErrorMessage.message,
		});
	}
};
// adminChangePatientPassword
export const adminChangePatientPassword =
	(new_password, confirm_password, uid) => async dispatch => {
		try {
			const getUserToken = `Bearer ${localStorage.getItem('token')}`;
			dispatch({ type: 'updateChangePatientPassword' });
			const encrypted_data = encryptData({
				id: uid,
				password: new_password,
				confirm_password: confirm_password,
			});
			const { data } = await axios.post(
				`${server}/reset_password_patient `,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: {
						Authorization: getUserToken,
						'Content-Type': 'application/json',
					},
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'updateChangePatientPasswordSuccess',
				payload: decryptedData.message,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'updateChangePatientPasswordFail',
				payload: decryptedErrorMessage.message,
			});
		}
	};
// SendReminder
export const adminSendReminder = uid => async dispatch => {
	try {
		const getUserToken = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'updateSendReminder' });

		const { data } = await axios.post(
			`${server}/send_remainder `,
			{
				id: uid,
			},
			{
				headers: {
					Authorization: getUserToken,
					'Content-Type': 'application/json',
				},
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'SendReminderSuccess',
			payload: decryptedData.message,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'SendReminderFail',
			payload: decryptedErrorMessage.message,
		});
	}
};
// resendLoginCredentials
export const resendLoginCredentials = uid => async dispatch => {
	try {
		const getUserToken = `Bearer ${localStorage.getItem('token')}`;
		dispatch({ type: 'resendLoginCredentials' });

		const { data } = await axios.post(
			`${server}/resend_credentials `,
			{
				id: uid,
			},
			{
				headers: {
					Authorization: getUserToken,
					'Content-Type': 'application/json',
				},
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'resendLoginCredentialsSuccess',
			payload: decryptedData.message,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'resendLoginCredentialsFail',
			payload: decryptedErrorMessage.message,
		});
	}
};
// UpdatePatientStatus
export const UpdatePatientStatus = (id, status) => async dispatch => {
	try {
		dispatch({ type: 'UpdatePatientStatusRequest' });

		const token = `Bearer ${localStorage.getItem('token')}`;
		const encrypted_data = encryptData({
			id: id,
			status: status == '0' ? '1' : '0',
		});
		let { data } = await axios.post(
			`${server}/update_patient_status`,
			{
				encrypted_data: encrypted_data,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'UpdatePatientStatusSuccess',
			message: decryptedData?.message,
			payload: decryptedData?.data,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'UpdatePatientStatusFail',
			payload: decryptedErrorMessage?.message,
		});
	}
};
// Emergency Services
export const getEmergencyServices =
	(query = '') =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			const { data } = await axios.get(
				`${server}/get_emergency_case/?search=${query}`,
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'getEmergencyServicesSuccess',
				payload: decryptedData?.cases,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getEmergencyServicesFailure',
				payload: decryptedErrorMessage.message,
			});
		}
	};

// getEmergencyCaseDetails
export const getEmergencyCaseDetails = uid => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "EmergencyCaseDetailsRequest" });

		const { data } = await axios.get(
			`${server}/get_emergencyCaseDetails/?id=${uid}`,
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'getEmergencyCaseDetailsSuccess',
			payload: decryptedData?.cases,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getEmergencyCaseDetailsFailure',
			payload: decryptedErrorMessage.message,
		});
	}
};
// AddEmergencyNotes
export const AddEmergencyNotes =
	(uid, notes, formattedDate) => async dispatch => {
		try {
			dispatch({ type: 'EmergencyNotesRequest' });

			const token = `Bearer ${localStorage.getItem('token')}`;
			const encrypted_data = encryptData({
				id: uid,
				notes: notes,
				timestamp: formattedDate,
			});
			let { data } = await axios.post(
				`${server}/add_emergency_notes`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'UpdateEmergencyNotesSuccess',
				message: decryptedData?.message,
			});
		} catch (error) {
			const decryptedErrorMessage = decryptData(
				error?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'UpdateEmergencyNotesFail',
				payload: decryptedErrorMessage?.message,
			});
		}
	};
// outOfNetwrokNotes
export const outOfNetwrokNotes =
	(network_id, notes, formattedDate) => async dispatch => {
		try {
			dispatch({ type: 'outNetwrokNotesRequest' });

			const token = `Bearer ${localStorage.getItem('token')}`;
			let { data } = await axios.post(
				`${server}/add_out_network_notes`,
				{
					id: network_id,
					notes: notes,
					timestamp: formattedDate,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);

			dispatch({
				type: 'UpdateOutNetworkSuccess',
				message: data?.message,
			});
		} catch (error) {
			dispatch({
				type: 'UpdateOutNetworkFail',
				payload: error?.response?.data?.message,
			});
			console.error(error?.response?.data?.message);
		}
	};

export const clearSelfPayFirstStepAction = () => async dispatch => {
	dispatch({ type: 'clearSelfPayFirstStep' });
};
// SelfPayPatient
export const SelfPayPatient =
	({
		first_name,
		last_name,
		gender,
		phone_number,
		email,
		address,
		password,
		confirm_password,
	}) =>
	async dispatch => {
		try {
			// dispatch({ type: "SelfPaySignupRequest" });

			const token = `Bearer ${localStorage.getItem('token')}`;
			let { data } = await axios.post(
				`${server}/selfPay_patient_registration`,
				{
					encrypted_data: encryptData({
						name: first_name,
						last_name: last_name,
						gender: gender,
						phone: phone_number,
						email: email,
						location: address,
						password: password,
						confirm_password: confirm_password,
						user_type: 'patient',
						latitude: '12',
						longitude: '232',
					}),
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			// console.log('datadatadata', data);
			const dec = decryptData(data.encrypted_data);

			const userdata = dec?.data?.user;
			localStorage.setItem('token', dec?.data?.access_token);
			localStorage.setItem('user', JSON.stringify(userdata));
			// console.log('ddddd', data.message);
			const record = localStorage.getItem('user');
			if (
				dec?.message ==
					'Self-Pay Patient registered successfully and email is sent to patient email address.' &&
				record
			) {
				// window.location.href = '/';
				// return redirect('/');
			}
			dispatch({
				type: 'SelfPaySignupSuccess',
				message: dec?.message,
				payload: userdata,
			});
		} catch (error) {
			const decryptedErrorMessage = decryptData(
				error?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'SelfPaySignupFail',
				payload: decryptedErrorMessage?.message,
			});
			console.error(decryptedErrorMessage?.message);
		}
	};
export const EmailVerified = (phone, verification_code) => async dispatch => {
	try {
		// dispatch({ type: "PhoneVerifiedRequest" });

		const token = `Bearer ${localStorage.getItem('token')}`;
		let { data } = await axios.post(
			`${server}/verify_phoneCode`,
			{
				encrypted_data: encryptData({
					phone,
					verification_code,
				}),
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		// console.log('datadatadata', data);
		const dec = decryptData(data.encrypted_data);
		dispatch({
			type: 'PhoneVerifiedSuccess',
			message: dec?.message,
		});
	} catch (error) {
		const decryptedErrorMessage = decryptData(
			error?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'PhoneVerifiedFail',
			payload: decryptedErrorMessage?.message,
		});
		console.error(decryptedErrorMessage?.message);
	}
};

// getEmergencyCaseDetails
export const getPreConditionData = uid => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		// dispatch({ type: "EmergencyCaseDetailsRequest" });

		const { data } = await axios.get(`${server}/get_preexisting_condition`, {
			headers: { Authorization: token, 'Content-Type': 'application/json' },
		});
		// console.log('ddddddddddddddddddddddddd', data);
		const dec = decryptData(data.encrypted_data);
		dispatch({
			type: 'getPreConditionSuccess',
			payload: dec?.cases,
		});
	} catch (error) {
		const decryptedErrorMessage = decryptData(
			error?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'getPreConditionFailure',
			payload: decryptedErrorMessage?.message,
		});
		console.error(decryptedErrorMessage?.message);
	}
};
// get age
export const getAllUserAge =
	(plan = '') =>
	async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({ type: "EmergencyCaseDetailsRequest" });
			const planIdea = plan == 'full_coverage' ? 'full_access' : plan;

			const { data } = await axios.get(`${server}/get_plans?plan=${planIdea}`, {
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			});
			// console.log('ddddddddddddddddddddddddd', data);
			const dec = decryptData(data.encrypted_data);

			dispatch({
				type: 'getAllAgesSuccess',
				payload: dec?.cases,
			});
		} catch (error) {
			const decryptedErrorMessage = decryptData(
				error?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'getAllAgesFailure',
				payload: decryptedErrorMessage?.message,
			});
			console.error(decryptedErrorMessage?.message);
		}
	};
// updatepatientfamilyPlan
export const updatePatientFamilyPlan =
	deletedChildIdsString => async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			const encrypted_data = encryptData({ id: deletedChildIdsString });
			let { data } = await axios.post(
				`${server}/delete_family`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'updatePatientFamilySuccess',
				payload: decryptedData.message,
			});
		} catch (error) {
			const decryptedErrorMessage = decryptData(
				error?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'updatePatientFamilyFail',
				payload: decryptedErrorMessage.message,
			});
		}
	};

// EnterprisePayPatientFamily
export const EnterprisePayPatientFamily =
	(PatientId, grandTotal) => async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;

			const encrypted_data = encryptData({
				id: PatientId,
				amount: grandTotal,
			});
			let { data } = await axios.post(
				`${server}/enterprise_pay_patient_family`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'submitPatientPaymentSuccess',
				payload: decryptedData.message,
			});
		} catch (error) {
			const decryptedErrorMessage = decryptData(
				error?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'submitPatientPaymentFail',
				payload: decryptedErrorMessage.message,
			});
		}
	};
// resendCredentialsPatient
export const resendCredentialsPatient = PatientId => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		const encrypted_data = encryptData({ id: PatientId });
		let { data } = await axios.post(
			`${server}/resend_credentials_to_patient`,
			{
				encrypted_data: encrypted_data,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'CredentialsPatientSuccess',
			payload: decryptedData.message,
		});
	} catch (error) {
		const decryptedErrorMessage = decryptData(
			error?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'CredentialsPatientFail',
			payload: decryptedErrorMessage.message,
		});
	}
};

// add patient family
export const addPatientFamily =
	(memberData, preExistingArray, kids, step, setStep, child_dob) =>
	async dispatch => {
		try {
			// dispatch({ type: "SelfPaySignupRequest" });

			const {
				full_coverage_gender,
				full_coverage_age,
				patient_dob,
				spouse_dob,
				plan,
				full_coverage_pre_existing,
				full_coverage_partner_plan,
				full_coverage_partner_gender,
				full_coverage_partner_age,
				full_coverage_partner_is_pre_existing,
				preExisting,
				partner_phone_number,
				partner_first_name,
				partner_last_name,
				partner_email,
				isPreExistingCondition,
				full_coverage_kids,
			} = memberData;
			const covertArray = inputArray => {
				return inputArray?.join(',');
			};
			var patientPexEx = [];
			if (full_coverage_pre_existing == 'yes') {
				patientPexEx = preExistingArray?.map(d1 => d1?.id);
				patientPexEx = covertArray(patientPexEx);
			}
			var SpousePexEx = [];
			if (
				full_coverage_partner_plan == 'yes' &&
				full_coverage_partner_is_pre_existing == 'yes'
			) {
				SpousePexEx = preExisting?.map(d1 => d1?.id);
				SpousePexEx = covertArray(SpousePexEx);
			}

			// console.log('preExistingArray?.map(d1=>d1.id)');
			// console.log(
			// 	'patient_gender',
			// 	full_coverage_gender,
			// 	'patient_age',
			// 	full_coverage_age,
			// 	'patient_preids',
			// 	patientPexEx
			// );
			// console.log(
			// 	'partner',
			// 	partner_first_name,
			// 	partner_last_name,
			// 	full_coverage_partner_gender,
			// 	full_coverage_partner_age,
			// 	preExisting,
			// 	partner_phone_number,
			// 	partner_email,
			// 	SpousePexEx
			// );

			// console.log('kidskidskids', kids);
			//
			var child_gender =
				full_coverage_kids == 'yes' && kids && kids.length > 0
					? kids.map(item => item?.gender)
					: [];
			var phoneNumbers =
				full_coverage_kids == 'yes' && kids && kids.length > 0
					? kids.map(item => item?.phone_number)
					: [];
			var child_name =
				full_coverage_kids == 'yes' && kids && kids.length > 0
					? kids.map(item => `${item?.first_name} ${item?.last_name}`)
					: [];
			var child_phone =
				full_coverage_kids == 'yes' && kids && kids.length > 0
					? kids.map(item => item?.phone_number)
					: [];
			var child_email =
				full_coverage_kids == 'yes' && kids && kids.length > 0
					? kids.map(item => item?.email)
					: [];
			var child_age =
				full_coverage_kids == 'yes' && kids && kids.length > 0
					? kids.map(item => item?.id)
					: [];
			const cPreExist = kids?.map(obj =>
				obj.preExistingArray.map(item => item.id).join(',')
			);
			const discountChild = kids?.map((d1, index) => {
				if (index == 0) {
					return 10;
				}
				if (index == 1) {
					return 25;
				}
				if (index > 1) {
					return 30;
				}
			});
			const child_user_type = kids?.map((d1, index) => 'child');
			// console.log('discountChilddiscountChild', discountChild);

			// const preids = data.map(item => item.preExistingArray.join(','));
			// console.log('cPreExistcPreExistcPreExist', cPreExist);

			// console.log(
			// 	'aaaaaaaaaaaaa',
			// 	full_coverage_partner_plan,
			// 	isPreExistingCondition,
			// 	'a',
			// 	child_gender,
			// 	child_name,
			// 	child_phone,
			// 	child_email,
			// 	child_age,
			// 	phoneNumbers
			// );

			// gender,name,phone,preids)

			const token = `Bearer ${localStorage.getItem('token')}`;
			// const data=""
			const payLoad = {
				child_dob: child_dob,
				patient_gender: full_coverage_gender,
				patient_dob: memberData.patient_dob,
				spouse_dob: memberData.spouse_dob,
				patient_age: full_coverage_age ? parseInt(full_coverage_age) : '',
				patient_preids: patientPexEx,
				// ---

				name: child_name,
				gender: child_gender,
				email: child_email,
				phone: child_phone,
				plan_id: child_age,
				preids: cPreExist,
				// discount: discountChild,
				user_type: child_user_type,
			};
			if (full_coverage_partner_plan === 'yes') {
				payLoad.spouse_name = `${partner_first_name} ${partner_last_name}`;
				payLoad.spouse_gender = full_coverage_partner_gender;
				payLoad.spouse_email = partner_email;
				payLoad.spouse_phone = partner_phone_number;
				payLoad.spouse_plan_id = full_coverage_partner_age
					? parseInt(full_coverage_partner_age)
					: '';
				// payLoad.spouse_discount = full_coverage_partner_plan === 'yes' ? 20 : 0;
				payLoad.spouse_user_type = 'spouse';
				payLoad.spouse_preids = SpousePexEx;
			}
			console.log({ payLoad });

			let { data } = await axios.post(
				`${server}/create_patient_family`,
				{
					encrypted_data: encryptData({
						...payLoad,
					}),
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const dec = decryptData(data.encrypted_data);
			// console.log('ddddd', data.message);
			setStep(step + 1);
			localStorage.setItem('user', JSON.stringify(dec?.patient));
			dispatch({
				type: 'AddFamilyMemberSuccess',
				payload: dec?.message,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'AddFamilyMemberFail',
				payload: decryptedErrorMessage?.message,
			});
			console.error(decryptedErrorMessage?.message);
		}
	};

//  Get total Amount

export const getTotalAmount = (network_id, notes) => async dispatch => {
	try {
		// dispatch({ type: "outNetwrokNotesRequest" });

		const token = `Bearer ${localStorage.getItem('token')}`;
		let { data } = await axios.get(
			`${server}/get_total_amount`,

			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		// console.log('ttttt', data);
		const dec = decryptData(data.encrypted_data);
		dispatch({
			type: 'addFamilySuccess',
			payload: dec?.amount,
		});
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'addFamilyFail',
			payload: decryptedErrorMessage?.message,
		});
		console.error(decryptedErrorMessage?.message);
	}
};

// payment api instegration
export const AddPatientDebitCard =
	(cardInfo, step, setStep) => async dispatch => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			const { debit_card, month, year, cvc } = cardInfo;
			const { data } = await axios.post(
				`${server}/add_billing_info_patinet`,
				{
					encrypted_data: encryptData({
						number: debit_card,
						exp_month: month,
						exp_year: year,
						cvc: cvc,
					}),
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: token,
					},
				}
			);
			const dec = decryptData(data.encrypted_data);
			localStorage.setItem('user', JSON.stringify(dec?.data));
			// setStep(8);

			dispatch({
				type: 'addDebitMeetingSuccess',
				payload: dec?.message,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);

			dispatch({
				type: 'addDebitMeetingFail',
				payload: decryptedErrorMessage?.message,
			});
		}
	};
// payment api instegration
export const patientPayoutSubcribtion = (step, setStep) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		const { data } = await axios.post(
			`${server}/patientPayoutSubcribtion`,
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: token,
				},
			}
		);
		const dec = decryptData(data.encrypted_data);
		const user = dec.user[0];
		localStorage.setItem('user', JSON.stringify(user));
		setStep(step + 1);

		dispatch({
			type: 'patientPayoutSubcribtionSuccess',
			payload: dec?.message,
		});

		// nextState()
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'patientPayoutSubcribtionFail',
			payload: decryptedErrorMessage?.message,
		});
		// console.error(err.response?.data?.message);
	}
};
// AdminPayPatientPayment
export const AdminPayPatientPayment =
	(network_id, newAmount, formattedDate) => async dispatch => {
		try {
			dispatch({ type: 'AdminPayPatientPaymentRequest' });

			const token = `Bearer ${localStorage.getItem('token')}`;
			let { data } = await axios.post(
				`${server}/admin_pay_patient`,
				{
					encrypted_data: encryptData({
						id: network_id,
						amount: newAmount,
						timestamp: formattedDate,
					}),
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);

			const dec = decryptData(data.encrypted_data);

			dispatch({
				type: 'AdminPayPatientPaymentSuccess',
				message: dec?.message,
			});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'AdminPayPatientPaymentFail',
				payload: decryptedErrorMessage?.message,
			});
			console.error(decryptedErrorMessage);
		}
	};
// checkUsernameExist
export const checkUsernameExist = email => async dispatch => {
	try {
		dispatch({ type: 'AdminPayPatientPaymentRequest' });

		const trimmedEmail = email.trim();
		if (email.includes(' ')) {
			dispatch({
				type: 'checkUsernameExistFail',
				payload: 'Please enter a username without spaces',
			});
			return; // Exit the function early
		}

		const token = `Bearer ${localStorage.getItem('token')}`;
		let { data } = await axios.get(
			`${server}/checkIfExists?email=${email}`,

			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);

		const dec = decryptData(data.encrypted_data);

		if (dec.data_exists === true) {
			dispatch({
				type: 'checkUsernameExistFail',
				payload: 'This username already exists',
			});
		} else if (dec.data_exists === false) {
			dispatch({
				type: 'checkUsernameExistSuccess',
				message: 'Username is valid',
			});
		}
	} catch (err) {
		const decryptedErrorMessage = decryptData(
			err?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'checkUsernameExistFail',
			payload: decryptedErrorMessage,
		});
		console.error(decryptedErrorMessage);
	}
};
// OutOfNetWorkProvider
export const OutOfNetWorkProvider = (query) => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		let { data } = await axios.get(
			`${server}/out_ofnetwork_providers?search=${query}`, 

			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'OutOfNetWorkProviderSuccess',
			payload: decryptedData?.data?.providers?.data,
			message: decryptedData?.message,
		});
	} catch (error) {
		const decryptedErrorMessage = decryptData(
			error?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'OutOfNetWorkProviderFail',
			payload: decryptedErrorMessage?.message,
		});
	}
};
// OutOfNetWorkProviderDetails
export const OutOfNetWorkProviderDetails = uid => async dispatch => {
	try {
		const token = `Bearer ${localStorage.getItem('token')}`;
		let { data } = await axios.get(
			`${server}/out_ofnetwork_provider_details/?id=${uid}`,
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		// console.log(decryptedData,"alpha")
		dispatch({
			type: 'OutOfNetWorkProviderDetailsSuccess',
			payload: decryptedData?.data,
			// message: data?.message,
		});
	} catch (error) {
		const decryptedErrorMessage = decryptData(
			error?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'OutOfNetWorkProviderDetailsFail',
			payload: decryptedErrorMessage?.message,
		});
		console.error(decryptedErrorMessage?.message);
	}
};
// percentageAdjustment
export const PercentageAdjustment = (inputValue, uid) => async dispatch => {
	try {
		dispatch({ type: 'AdminPayPatientPaymentRequest' });

		const token = `Bearer ${localStorage.getItem('token')}`;
		const encrypted_data = encryptData({
			payment_id: uid,
			admin_percentage: inputValue,
		});
		let { data } = await axios.post(
			`${server}/update_percentage`,
			{
				encrypted_data: encrypted_data,
			},
			{
				headers: { Authorization: token, 'Content-Type': 'application/json' },
			}
		);
		const decryptedData = decryptData(data.encrypted_data);
		dispatch({
			type: 'PercentageAdjustmentSuccess',
			message: decryptedData?.message,
		});
	} catch (error) {
		const decryptedErrorMessage = decryptData(
			error?.response?.data?.encrypted_data
		);
		dispatch({
			type: 'PercentageAdjustmentFail',
			payload: decryptedErrorMessage?.message,
		});
		console.error(error?.response?.data?.message);
	}
};
// AddEnterpriseNote
export const AddEnterpriseNote =
	(id, notes, formattedDate) => async dispatch => {
		try {
			dispatch({ type: 'EnterpriseNotesRequest' });

			const token = `Bearer ${localStorage.getItem('token')}`;
			const encrypted_data = encryptData({
				id: id,
				notes: notes,
				timestamp: formattedDate,
			});
			let { data } = await axios.post(
				`${server}/add_note_in_employee`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'AddEnterpriseNotesSuccess',
				message: decryptedData?.message,
			});
		} catch (error) {
			const decryptedErrorMessage = decryptData(
				error?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'AddEnterpriseNotesFail',
				payload: decryptedErrorMessage?.message,
			});
		}
	};
// addProviderNote
export const AddProviderNote =
	(uid, notes, formattedDate) => async dispatch => {
		try {
			dispatch({ type: 'ProviderNotesRequest' });

			const token = `Bearer ${localStorage.getItem('token')}`;
			const encrypted_data = encryptData({
				id: uid,
				notes: notes,
				timestamp: formattedDate,
			});
			let { data } = await axios.post(
				`${server}/add_note_in_provider`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'AddProviderNoteSuccess',
				message: decryptedData?.message,
			});
		} catch (error) {
			const decryptedErrorMessage = decryptData(
				error?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'AddProviderNoteFail',
				payload: decryptedErrorMessage?.message,
			});
		}
	};
