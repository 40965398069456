/* eslint-disable react-hooks/exhaustive-deps */
import Sidebar from '../../Layout/Sidebar/Sidebar';
import Header from '../../Layout/Header/Header';
import { Grid, Container, FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import upload_icon from '../../../asset/image/upload_icon.svg';
import Dropzone from 'react-dropzone';
import { Link, useNavigate } from 'react-router-dom';
import MakePayment from './Make payment/MakePayment';
import axios from 'axios';
import EmailIcon from '../../../asset/image/EmailIcon.svg';
import downloadIconSetting from '../../../asset/image/downloadIconSetting.svg';
import usSetting from '../../../asset/image/usSetting.svg';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
// redux
import { Elements } from '@stripe/react-stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { setValue } from '../../../redux/action/tabAction';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../redux/reducer/auth.slice';
import ModalSetting from './ModalSetting';
import InputAdornment from '@mui/material/InputAdornment';
import { server } from '../../../redux/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, Toaster } from 'react-hot-toast';
import {
	addBillingInfoEnterprise,
	emptySuccessError,
	getmyProfile,
	settingUpdate,
	totalChargesInSettingInEnterprise,
	updateAccountRouting,
	updateBillingInfoEnterprise,
	updateUserRoutingSetting,
	getMonthlyInvoice,
	getAllUsers,
	AutoDebitAPI,
	clearBillingStateAfterRouting,
} from '../../../redux/action/user';
import {
	decryptData,
	encryptData,
} from '../../../redux/incript&decript/cryptoUtils';
import DeactiveModal from '../../DeactiveModal';
import Stripe from '../../stripe';
const stripePromise = loadStripe(
	'pk_live_51MqyQ0AVuTTGHQvbX2kAWCMYBn9lOoqYryth0XjRqkp0ncV3vJUiYxRfI86hdI8NEvsDZmsZue2xiSlhXnhpswhU007N8ff5PG'
	// 'pk_test_51Ml2S0Bm51xoPSLFuDAbolImh6y7xItiIvfm0PoTHfdvKi1SbQr3XFi5n5OguwKdm09P4EBB8wmXPd5sRHcfZ0EO005Z3w5qxR'
);
//css
const useStyles = makeStyles({
	tabsetting: {
		fontWeight: '500 !important',
		fontSize: '14px !important',
		textTransform: 'none !important',
		// color:'#6941C6 !important',
	},
	bilinginfo: {
		fontWeight: '600 !important',
		fontSize: '18px !important',
		fontFamily: 'DM Sans',
	},
	updateinfoLine: {
		fontWeight: '500 !important',
		fontSize: '18px !important',
		fontFamily: 'DM Sans',
		color: '#667085 !important',
	},
	billingtext: {
		fontWeight: ' 500 !important',
		fontSize: '14px !important',
		color: '#344054 !important',
	},
	TextFieldOutlined: {
		width: '512px !important',
		background: 'white !important',
	},
	paragraphNote: {
		fontWeight: '600 !important',
		fontSize: '14px !important',
	},

	updateinfoPersonal: {
		fontWeight: '600 !important',
		fontSize: '16px',
		fontFamily: 'DM Sans',
		color: '#667085',
	},
	phototext: {
		fontSize: '14px',
		color: '#667085',
	},

	uploadicon: {
		display: 'flex !important',
		justifyContent: 'center !important',
		cursor: 'pointer !important',
		marginTop: '6px',
	},
	dropzone: {
		width: '512px',
		height: '80%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '20px',
		borderWidth: '2px',
		borderRadius: '4px',
		borderColor: '#fff',
		borderStyle: 'groove',
		backgroundColor: '#fff',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
	uploadStyle: {
		fontSize: '14px',
		color: '#6941C6',
		fontWeight: '700',
		cursor: 'pointer',
	},
});
const Settings = ({ settingTab = '' }) => {
	const value = useSelector(state => state.counter.value);
	const [selectedRadio, setSelectedRadio] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// const {user}=useSelector(state=>state.user)
	const [tab, setTab] = useState(window.location.pathname);
	// console.log("window.location.pathname", tab);
	// modal open
	const [show, setShow] = useState(false);
	const [amountRecord, setAmountRecord] = useState({});

	const {
		recordUser,
		countUser,
		isObject,
		user,
		bankAccountDetails,
		successError,
		successFail,
		totalAmountInSettingEnterprise,
		isSuccess,
		UpdateUserAccount,
		errorBillingInfo,
	} = useSelector(state => state.user);
	const [userBankAcount, setUserBankAccount] = useState(bankAccountDetails);
	const [userData, setUserData] = useState(user);
	const [userprofile, setUserProfile] = useState(null);
	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');
	const handleOptionRadio = async (event) => {
		await setSelectedRadio(event.target.value);
	};
	const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
	useEffect(() => {
		if (UpdateUserAccount?.success) {
			navigate('/members');
			dispatch(clearBillingStateAfterRouting());
		}
	}, [UpdateUserAccount?.success]);
	const getUserData = async () => {
		if (localStorage.getItem('employerSignup')) return;
		const token = `Bearer ${localStorage.getItem('token')}`;

		const res = await axios.get(`${server}/profile`, {
			headers: { Authorization: token, 'Content-Type': 'application/json' },
		});
		// console.log(res);

		const dec = decryptData(res.data.encrypted_data);
		// console.log(dec);
		if (
			dec?.data.user.user_type == 'enterprise' &&
			dec?.data.user.status == 0
		) {
			setOpen1(true);
			localStorage.clear();
			navigate('/');
			localStorage.setItem('deactivate', true);
			return;
		}
	};
	// console.log("user", user);
	useEffect(() => {}, [successFail]);
	useEffect(() => {
		setUserData(user);
	}, [user]);
	useEffect(() => {
		getUserData();
	}, []);
	// console.log("bankAccountDetailsbankAccountDetails", userBankAcount);
	// updateSetting
	const saveProfile = () => {
		// return
		dispatch(settingUpdate(userData, userprofile));
	};

	const saveAccountDetail = async () => {
		try {
			if (
				userBankAcount?.account_number == null ||
				!userBankAcount.routing_number ||
				!userBankAcount.auto_debit
			) {
				toast.error('Please fill  all fields.');
				return;
			}
			if (user.is_stripe_configured === 0) {
				await AddBillingInfo();
			} else if (user.is_stripe_configured === 1) {
				await updateBillingInfo();
			}
		} catch (err) {
			toast.error(err?.response?.data?.message);
		}
	};
	const handleSubmit = async result => {
		try {
			if (user.is_stripe_configured === 0) {
				const token_stripe = result.token.id;
				if(!selectedRadio){
					return toast.error('Please select auto debit.');
				}
				const auto_debit = selectedRadio;
				const status = 'card';
				console.log(result.token.id);
				dispatch(addBillingInfoEnterprise(token_stripe, status,auto_debit));
				dispatch(getmyProfile());
				if (isSuccess !== undefined && !isSuccess) {
					return toast.error('Some thing is wrong.');
				}
				// await AddBillingInfo();
			} else if (user.is_stripe_configured === 1) {
				const token_stripe = result.token.id;
				
				if(!selectedRadio){
					return toast.error('Please select auto debit.');
				}
				const status = 'card';
				const auto_debit = selectedRadio;
				console.log(result.token.id);
				dispatch(updateBillingInfoEnterprise(token_stripe, status,auto_debit));
				dispatch(getmyProfile());

				if (isSuccess != undefined && !isSuccess) {
					return toast.error('Some thing is wrong.');
				}
				// await updateBillingInfo();
			}
		} catch (err) {
			toast.error(err?.response?.data?.message);   
		}
	};

	const totalCharges = async email => {
		// try {
		//   const token = `Bearer ${localStorage.getItem("token")}`;
		//   let { data } = await axios.post(
		//     `${server}/StripePayoutToAdmin`,
		//     {
		//       totalUser: countUser,
		//     },
		//     {
		//       headers: { Authorization: token, "Content-Type": "application/json" },
		//     }
		//   );
		//   console.log("Helllo", data)
		// dispatch(totalChargesInSettingInEnterprise(countUser))
		// setAmountRecord(totalAmountInSettingEnterprise)
		// }
		// catch (error) {
		//   // alert(error?.response?.data?.message);
		//   console.log("error?.response?.data?.message", error?.response?.data?.message)
		//   toast.error(error?.response?.data?.message);
		// }
	};
	const handleOpen = () => {
		// totalCharges()
		setShow(true);
	};

	const handleClose = () => {
		setShow(false);
	};

	//
	const classes = useStyles();
	// useState
	// const [value, setValue] = React.useState("1");
	//
	const handleChange = (event, newValue) => {
		dispatch(setValue(newValue));
	};

	//upload or drag and drop
	const UploadFile = () => {
		const classes = useStyles();
		const [file, setFile] = useState(null);

		// const handleDrop = (acceptedFiles) => {
		//   setUserProfile(acceptedFiles[0]);

		//   const img = acceptedFiles[0]

		//   console.log("acceptedFiles", img)
		//   setUserData({ ...userData, profile_image: img })
		// };
		// validation

		const handleDrop = acceptedFiles => {
			const img = acceptedFiles[0];
			setUserProfile(acceptedFiles[0]);

			if (img) {
				const allowedFormats = [
					'image/svg+xml',
					'image/png',
					'image/jpeg',
					'image/jpg',
				];

				if (!allowedFormats.some(format => format.includes(img.type))) {
					toast.error('Invalid file format. Please upload an image file.');
					return;
				}

				setFile(img);
				setUserData({ ...userData, profile_image: img });
			}
		};

		return (
			<>
				<Dropzone onDrop={handleDrop}>
					{({ getRootProps, getInputProps, isDragActive }) => (
						<div
							className={classes.dropzone}
							{...getRootProps()}
							style={
								isDragActive
									? { borderColor: '#2196f3', backgroundColor: '#e3f2fd' }
									: {}
							}
						>
							<img src={downloadIconSetting} alt='' />
							<input {...getInputProps()} />
							<img
								src=''
								alt='upload_icon'
								style={{ cursor: 'pointer' }}
								className={classes.uploadicon}
							/>
							<span>
								{' '}
								<span className={classes.uploadStyle}>
									Click to upload &nbsp;
								</span>
								or drag and drop
							</span>
							<span style={{ fontWeight: 900 }}>
								{userprofile && userprofile?.name}
							</span>
							<span>SVG, PNG, JPG or GIF (max. 800x400px)</span>
						</div>
					)}
				</Dropzone>
				<DeactiveModal open={open1} setOpen={setOpen1} />
			</>
		);
	};
	const handleOpen1 = () => {
		setOpen(true);
	};

	const handleClose1 = () => {
		setOpen(false);
	};
	const removeBorder = {
		border: 'none',
	};

	const [sendTokenToBackend, setSendTokenToBackend] = useState();
	const holderName = userData?.name;
	const AddBillingInfo = async sendTokenToBackend => {
		const stripe = await stripePromise;
		const { token, error } = await stripe.createToken('bank_account', {
			country: 'US',
			currency: 'usd',
			account_holder_type: 'individual',
			account_holder_name: holderName,
			account_number: userBankAcount?.account_number || '',
			routing_number: userBankAcount?.routing_number || '',
			auto_debit: userBankAcount?.auto_debit || '',
		});
		if (error) {
			// toast.error("Bank account not connected")
			toast.error(error.message);

			return;
		} else {
			await setSendTokenToBackend(token.id);
		}
		const token_stripe = token.id;
		const status = 'bank';
		const auto_debit = userBankAcount?.auto_debit || 'No';
		dispatch(addBillingInfoEnterprise(token_stripe, status, auto_debit));

		// Fetch the updated profile right after adding billing info
		dispatch(getmyProfile());

		// Handle error and success messages
		if (isSuccess !== undefined && !isSuccess) {
			return toast.error('Some thing is wrong.');
		}
	};

	const updateBillingInfo = async sendTokenToBackend => {
		const stripe = await stripePromise;
		const { token, error } = await stripe.createToken('bank_account', {
			country: 'US',
			currency: 'usd',
			account_holder_type: 'individual',
			account_holder_name: holderName,
			account_number: userBankAcount?.account_number || '',
			routing_number: userBankAcount?.routing_number || '',
			auto_debit: userBankAcount?.auto_debit || '',
		});
		if (error) {
			// toast.error("Bank account not connected")
			toast.error(error.message);
			return;
		} else {
			await setSendTokenToBackend(token.id);
		}
		const token_stripe = token.id;
		const status = 'bank';
		const auto_debit = userBankAcount?.auto_debit || 'No';
		dispatch(updateBillingInfoEnterprise(token_stripe, status, auto_debit));
		dispatch(getmyProfile());

		if (isSuccess != undefined && !isSuccess) {
			return toast.error('Some thing is wrong.');
		}
	};

	const validationSchema = Yup.object({
		first_name: Yup.string().required('*first name is required'),
		last_name: Yup.string().required('*last name is required'),
		email: Yup.string()
			.required('*email is required')
			.matches(
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				'*please enter a valid email address'
			),
		country: Yup.string().required('*country is required'),
	});

	const formik = useFormik({
		initialValues: {
			first_name: userData?.first_name || '',
			last_name: userData?.last_name || '',
			email: userData?.email || '',

			country: userData?.country || '',
		},
		validationSchema: validationSchema,
		onSubmit: saveProfile, // Assign the saveProfile function as the onSubmit handler
	});

	const countryError = formik.touched.country && Boolean(formik.errors.country);
	const countryHelperText = formik.touched.country && formik.errors.country;
	const emailError = formik.touched.email && Boolean(formik.errors.email);
	const emailHelperText = formik.touched.email && formik.errors.email;

	return (
		<div style={{ display: 'flex', background: '#F8F9FE', height: '100vh' }}>
			<Sidebar
				showSidebar={true}
				showHelpButton={true}
				settingTab={settingTab}
			/>

			<div style={{ flex: 5, background: '#F8F9FE' }}>
				<Header showHeader={true} />

				<Container
					sx={{ background: '#f8f9fe' }}
					maxWidth='xl'
					style={{ position: 'relative', top: '15%' }}
				>
					<Grid container Spacing={2}>
						<Box sx={{ width: '100%', typography: 'body1' }}>
							<Box sx={{ marginLeft: '10px' }}></Box>

							<span
								style={{
									marginLeft: '20px',
									fontWeight: '600 ',
									fontSize: '30px',
								}}
							>
								Settings
							</span>
							<TabContext value={value}>
								<TabList
									sx={{ marginLeft: '22px' }}
									onChange={handleChange}
									aria-label='lab API tabs example'
								>
									<Tab
										className={classes.tabsetting}
										sx={{ minWidth: 'unset', width: '70px' }}
										label={
											<div style={{ gap: '5px', display: 'flex' }}>
												<span>My</span>
												<span>Details</span>
											</div>
										}
										value='1'
									/>
									{/* <Tab

                    className={classes.tabsetting}
                    label="Team"
                    value="2"
                    onClick={() => navigate('/members')}
                  /> */}
									<Tab
										sx={{ minWidth: 'unset', width: '40px' }}
										style={{
											marginLeft: '22px',
										}}
										className={classes.tabsetting}
										label='Billing'
										value='3'
									/>
								</TabList>
								{/* line below tablist */}
								<Box
									sx={{
										marginLeft: '20px',
										borderBottom: 1,
										borderColor: 'divider',
										width: '76vw',
									}}
								></Box>
								{/*  */}
								<TabPanel value='1' sx={{ mb: 12 }}>
									<form onSubmit={formik.handleSubmit}>
										<Box
											sx={{ display: 'flex', justifyContent: 'space-between' }}
										>
											<Box sx={{ display: 'grid' }}>
												<span className={classes.bilinginfo}>
													Personal info
												</span>
												<span className={classes.updateinfoPersonal}>
													Update your photo and personal details here.
												</span>
											</Box>
											<Box sx={{ display: 'flex', gap: '2px' }}>
												<Button
													variant='contained'
													size='small'
													style={{
														background: '#FFFFFF',
														color: '#344054',
														width: '80px',
														height: '40px',
														textTransform: 'none',
														fontWeight: '600',
													}}
													onClick={() => navigate('/members')}
												>
													Cancel
												</Button>
												<Button
													variant='contained'
													size='small'
													style={{
														background: '#4157FF',
														width: '65px',
														height: '40px',
														textTransform: 'none',
														fontWeight: '600',
													}}
													sx={{ ml: 1 }}
													onClick={formik.handleSubmit}
												>
													Save
												</Button>
											</Box>
										</Box>
										<Divider sx={{ mt: 2, mb: 2 }} />
										<Box
											sx={{ display: 'flex', justifyContent: 'space-between' }}
										>
											<Box>
												<span className={classes.billingtext}>Name</span>
											</Box>
											<Box sx={{ display: 'flex', width: '50vw', gap: '10px' }}>
												<TextField
													id='first_name'
													inputProps={{ maxLength: 25 }}
													variant='outlined'
													placeholder='First name'
													value={formik.values.first_name}
													onChange={e => {
														formik.handleChange(e);
														setUserData({
															...userData,
															first_name: e.target.value,
														});
													}}
													error={
														formik.touched.first_name &&
														Boolean(formik.errors.first_name)
													}
													helperText={
														formik.touched.first_name &&
														formik.errors.first_name
													}
													sx={{
														...(formik.touched.first_name &&
															formik.errors.first_name && {
																background: '#f8f9fe',
															}),
													}}
												/>
												<TextField
													id='last_name'
													inputProps={{ maxLength: 25 }}
													variant='outlined'
													placeholder='Last name'
													value={formik.values.last_name}
													onChange={e => {
														formik.handleChange(e);
														setUserData({
															...userData,
															last_name: e.target.value,
														});
													}}
													error={
														formik.touched.last_name &&
														Boolean(formik.errors.last_name)
													}
													helperText={
														formik.touched.last_name && formik.errors.last_name
													}
													sx={{
														...(formik.touched.last_name &&
															formik.errors.last_name && {
																background: '#f8f9fe',
															}),
													}}
												/>
											</Box>
										</Box>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												marginTop: '50px',
											}}
										>
											<Box>
												<span className={classes.billingtext}>
													Email address
												</span>
											</Box>
											<Box sx={{ width: '50vw' }}>
												<TextField
													id='email'
													variant='outlined'
													type='email'
													className={classes.TextFieldOutlined}
													placeholder='Email'
													value={formik.values.email}
													onChange={e => {
														formik.handleChange(e);
														setUserData({ ...userData, email: e.target.value });
													}}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<img src={EmailIcon} alt='Email Icon' />
															</InputAdornment>
														),
													}}
													style={{
														backgroundColor: '#f4f4f4',
														pointerEvents: 'none',
													}}
												/>
												{emailError && (
													<FormHelperText sx={{ color: 'red' }}>
														{emailHelperText}
													</FormHelperText>
												)}
											</Box>
										</Box>
										<Divider sx={{ mt: 2, mb: 2 }} />
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												marginTop: '10px',
											}}
										>
											<Box sx={{ display: 'flex', flexDirection: 'column' }}>
												<Box>
													<span className={classes.billingtext}>
														Your photo
													</span>
												</Box>
												<Box>
													<span className={classes.phototext}>
														This will be displayed on your profile.
													</span>
												</Box>
											</Box>
											<Box sx={{ width: '50vw', cursor: 'pointer' }}>
												<UploadFile />
											</Box>
										</Box>
										<Divider sx={{ mb: 2 }} />
										<Box
											sx={{ display: 'flex', justifyContent: 'space-between' }}
										>
											<Box>
												<span className={classes.billingtext}>Role</span>
											</Box>
											<Box sx={{ width: '50vw' }}>
												<TextField
													disabled
													id='role'
													variant='outlined'
													className={classes.TextFieldOutlined}
													placeholder='Enter role'
													// value={userData?.user_type}
													value={
														userData?.user_type &&
														userData.user_type.charAt(0).toUpperCase() +
															userData.user_type.slice(1)
													}
												/>
											</Box>
										</Box>
										<Divider sx={{ mt: 2, mb: 2 }} />
										<Box
											sx={{ display: 'flex', justifyContent: 'space-between' }}
										>
											<Box>
												<span className={classes.billingtext}>Country</span>
											</Box>
											<Box sx={{ width: '50vw' }}>
												<TextField
													id='country'
													variant='outlined'
													inputProps={{ maxLength: 25 }}
													className={classes.TextFieldOutlined}
													placeholder='Enter country'
													value={formik.values.country}
													onChange={e => {
														formik.handleChange(e);
														setUserData({
															...userData,
															country: e.target.value,
														});
													}}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<img src={usSetting} alt='Email Icon' />
															</InputAdornment>
														),
													}}
												/>
												{countryError && (
													<FormHelperText sx={{ color: 'red' }}>
														{countryHelperText}
													</FormHelperText>
												)}
											</Box>
										</Box>
									</form>
								</TabPanel>
								{/*  */}
								{/* <TabPanel value="2" sx={{ mb: 12 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'grid' }}>
                      <span className={classes.bilinginfo}>Billing Info</span>

                      <span className={classes.updateinfoPersonal}>
                        Update your bank account and billing settings.
                      </span>{" "}
                    </Box>

                    <Box sx={{ display: 'flex', gap: '12px' }}>
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.canclebtn}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.savebtn}
                        onClick={()=>saveProfile()}

                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Divider sx={{ mt: 12 }} />
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Grid container spacing={6}>
                    <Grid item xs={6} md={4} sx={{ ml: 2 }}>
                      <span className={classes.billingtext}>Account Number</span>
                    </Grid>
                    <Grid item xs={6} md={7}>
                      <TextField
                        type="password"
                        variant="outlined"
                        inputProps={{ min: 0 }}
                        className={classes.TextFieldOutlined}
                        placeholder="Enter account number"
                        defaultValue={userBankAcount?.account_number}
                        onChange={(e)=>setUserBankAccount({...userBankAcount,account_number:e.target.value})}
                      />
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Grid container spacing={6}>
                    <Grid item xs={6} md={4} sx={{ ml: 2 }}>
                      <span className={classes.billingtext}>Routing Number</span>
                    </Grid>
                    <Grid item xs={6} md={7}>
                      <TextField
                        type="password"
                        variant="outlined"
                        inputProps={{ min: 0 }}
                        className={classes.TextFieldOutlined}
                        placeholder="Enter routing number"
                        defaultValue={userBankAcount?.routing_number}
                        onChange={(e)=>setUserBankAccount({...userBankAcount,routing_number:e.target.value})}
                      />
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Grid container spacing={6}>
                    <Grid item xs={6} md={4} sx={{ ml: 2 }}>
                      <span className={classes.billingtext}>Auto-Debit</span>
                    </Grid>
                    <Grid item xs={6} md={7}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="Auto_debit"
                        // value={abc}
                        value={bankAccountDetails?.auto_debit}
                        className={classes.TextFieldOutlined}
                      // onChange={handleChange}
                      onChange={(e)=>setUserBankAccount({...userBankAcount,auto_debit:e.target.value})}
                      >
                        <MenuItem value={'Yes'}>Yes</MenuItem> sdfdsf
                        <MenuItem value={'No'}>No</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 6 }}>
                    <Grid item xs={6} md={9} sx={{ ml: 2 }}></Grid>
                    <Grid item xs={6} md={2} sx={{ ml: 4 }}>
                      <Button
                        onClick={handleOpen}
                        variant="contained"
                        size="large"
                        className={classes.makePayment}
                      >
                        <span style={{ fontSize: "14px", fontWeight: "600" }}>
                          Make Payment
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel> */}
								{/*  */}
								<TabPanel value='3' sx={{ mb: 12 }}>
									<Box
										sx={{ display: 'flex', justifyContent: 'space-between' }}
									>
										<Box sx={{ display: 'grid' }}>
											<span className={classes.bilinginfo}>Billing Info</span>
											<span className={classes.updateinfoPersonal}>
												*Add your debit/credit card or add your bank account.
											</span>{' '}
											<Box
												sx={{ display: 'flex', gap: '12px', marginTop: '20px' }}
											>
												<input
													type='radio'
													id='addBankAccount'
													name='accountType'
													value='addBankAccount'
													onChange={handleOptionChange}
												/>
												<label
													style={{ fontWeight: 'bold' }}
													htmlFor='addBankAccount'
												>
													Add Bank Account
												</label>
												<input
													type='radio'
													id='addDebitCard'
													name='accountType'
													value='addDebitCard'
													onChange={handleOptionChange}
												/>
												<label
													style={{ fontWeight: 'bold' }}
													htmlFor='addDebitCard'
												>
													Add Debit/Credit Card
												</label>
											</Box>
										</Box>
									</Box>
									{selectedOption === 'addBankAccount' && (
										<>
											<Box
												sx={{
													display: 'flex',
													gap: '12px',
													justifyContent: 'flex-end',
													marginRight: '12px',
												}}
											>
												<Button
													variant='contained'
													size='small'
													style={{
														background: '#FFFFFF ',
														color: '#344054  ',
														width: '80px ',
														height: '40px ',
														textTransform: 'none ',
														fontWeight: '600 ',
													}}
													onClick={() => navigate('/members')}
												>
													Cancel
												</Button>
												<Button
													variant='contained'
													size='small'
													style={{
														background: '#4157FF ',
														width: '65px ',
														height: '40px ',
														textTransform: 'none ',
														fontWeight: '600 ',
													}}
													onClick={() => saveAccountDetail()}
												>
													Save
												</Button>
											</Box>
											<Box sx={{ marginTop: '18px' }}>
												<Divider
													style={{
														width: '77vw',
														marginBottom: '13px',
													}}
												/>{' '}
											</Box>
											<Box sx={{ marginTop: '104px' }}>
												<Box>
													<Box>
														<Divider
															style={{
																width: '77vw',
																marginBottom: '13px',
															}}
														/>{' '}
													</Box>
													<Box sx={{ marginTop: '20px' }}>
														{/* <Divider style={{ width: '77vw',marginBottom: '13px',}}/> */}
													</Box>
												</Box>
												<Grid container spacing={6}>
													<Grid item xs={6} md={4} sx={{ ml: 2 }}>
														<span className={classes.billingtext}>
															Account Number
														</span>
													</Grid>
													<Grid item xs={6} md={7}>
														<TextField
															id='amount_enter'
															variant='outlined'
															className={classes.TextFieldOutlined}
															placeholder='Enter New Account Number'
															defaultValue={userBankAcount?.account_number}
															onChange={e =>
																setUserBankAccount({
																	...userBankAcount,
																	account_number: e.target.value,
																})
															}
														/>
													</Grid>
												</Grid>
											</Box>
											{/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
											<Grid container spacing={6} sx={{ mt: 1 }}>
												<Grid item xs={6} md={4} sx={{ ml: 2 }}>
													<span className={classes.billingtext}>
														Routing Number
													</span>
												</Grid>
												<Grid item xs={6} md={7}>
													<TextField
														id='routing_number'
														variant='outlined'
														className={classes.TextFieldOutlined}
														placeholder='Enter New Routing Number'
														value={userBankAcount?.routing_number}
														onChange={e =>
															setUserBankAccount({
																...userBankAcount,
																routing_number: e.target.value,
															})
														}
													/>
												</Grid>
											</Grid>
											{/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
											<Grid container spacing={6} sx={{ mt: 1 }}>
												<Grid item xs={6} md={4} sx={{ ml: 2 }}>
													<span className={classes.billingtext}>
														Auto-Debit
													</span>
												</Grid>
												<Grid item xs={6} md={7}>
													<Select
														labelId='demo-simple-select-label'
														id='Auto_debit'
														value={userBankAcount?.auto_debit}
														className={classes.TextFieldOutlined}
														onChange={e =>
															setUserBankAccount({
																...userBankAcount,
																auto_debit: e.target.value,
															})
														}
														// onChange={handleChange}
													>
														<MenuItem value={'Yes'}>Yes</MenuItem>
														<MenuItem value={'No'}>No</MenuItem>
													</Select>
												</Grid>
											</Grid>
										</>
									)}
									{selectedOption === 'addDebitCard' && (
										<>
											<Divider sx={{ mt: 2, mb: 2 }} />
											<Box style={removeBorder}>
												<Box>
													<Box style={{ margin: 'auto', width: '100%' }}>
														{/* Additional radio button group for Yes/No */}
														<FormControl component='fieldset'>
															<FormLabel component='legend' style={{fontWeight:'bold'}}>
																Select an option for Auto Debit
															</FormLabel>
															<RadioGroup
																aria-label='option'
																name='option'
																value={selectedRadio}
																onChange={handleOptionRadio}
																style={{ display: "flex", flexDirection: 'row'}}
															>
																<FormControlLabel
																	value='Yes'
																	control={<Radio />}
																	label='Yes'
																/>
																<FormControlLabel
																	value='No'
																	control={<Radio />}
																	label='No'
																/>
															</RadioGroup>
														</FormControl>
													</Box>
													<Stripe handleSubmit={handleSubmit} />
												</Box>
											</Box>
										</>
									)}
								</TabPanel>
							</TabContext>
						</Box>
					</Grid>
					<MakePayment
						show={show}
						handleOpen={handleOpen}
						handleClose={handleClose}
						total_per_employee_amount={amountRecord?.total_per_employee_amount}
						total_due={amountRecord?.total_due}
					/>
					<ModalSetting
						open={open}
						handleOpen1={handleOpen1}
						handleClose1={handleClose1}
					/>
				</Container>
			</div>
			<Toaster />
		</div>
	);
};

export default Settings;
