import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Logo from '../../asset/image/Logo.svg';
import DeleteServices from '../../asset/image/DeleteServices.png';

import {
	Stack,
	Grid,
	TextField,
	Box,
	Typography,
	Divider,
	Select,
	MenuItem,
} from '@mui/material';
import DropzoneC from '../Network/DropZone';
import { useDropzone } from 'react-dropzone';
import ServiceEdit from '../../asset/image/ServiceEdit.png';
import { object, string } from 'yup';

const useStyles = makeStyles(theme => ({
	isLargeScreen: {
		width: ' 510px',
		maxHeight: '300px',
		overflow: 'auto',
		cursor: 'pointer',
		display: 'grid',
		justifyContent: 'center',
		border: '1px solid var(--grey, #949494)',
		borderRadius: '12px',

		[theme.breakpoints.down('md')]: {
			width: ' 400px',
			height: '300px',
			overflow: 'auto',
			cursor: 'pointer',
			display: 'grid',
			justifyContent: 'center',
			border: '1px solid var(--grey, #949494)',
			borderRadius: '12px',
		},
		[theme.breakpoints.up('lg')]: {
			width: ' 592px',
			height: '300px',
			overflow: 'auto',
			cursor: 'pointer',
			display: 'grid',
			justifyContent: 'center',
			border: '1px solid var(--grey, #949494)',
			borderRadius: '12px',
		},
	},

	rightServices: {
		marginTop: '12px',
		borderRadius: '12px',
		width: '460px',
		// height: "124px",
		minHeight: '124px',
		maxHeight: 'auto',
		padding: '14px',
		border: '1px solid black',
		[theme.breakpoints.down('md')]: {
			marginTop: '12px',
			borderRadius: '12px',
			width: '460px',
			minHeight: '124px',
			maxHeight: 'auto',
			padding: '14px',
			border: '1px solid black',
		},
		[theme.breakpoints.up('lg')]: {
			marginTop: '12px',
			borderRadius: '12px',
			width: '560px',
			minHeight: '124px',
			maxHeight: 'auto',
			padding: '14px',
			border: '1px solid black',
		},
	},
}));

export default function Index({ onFormSubmit, mode = 0, data = null }) {
	const classes = useStyles();
	const { uid } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [consultationObj, setConsultationObj] = useState({});

	const { getAllServicesRecord, getAllSpecilizationRecord } = useSelector(
		state => state.user
	);

	// console.log({ data });
	const {
		control,
		handleSubmit,
		reset,
		setValue,
		// setError,
		formState: { errors },
		getValues,
	} = useForm({
		defaultValues: {
			price: data?.price || '',
			description: data?.description || '',
			// profile_image: data?.image,
		},
	});

	const onSubmit = data1 => {
		// if (parseInt(data1.price) > parseInt(data1.service.min_price)) {

		// 	setError('price', {
		// 		type: 'manual',
		// 		message: `Service price must be less then or equal to min-price of ${data1.service.min_price} `,
		// 	});
		// 	return;
		// }

		const data2 = data1;
		if (data?.id) {
			data2.id = data.id;
		}
		// console.log(data2);
		onFormSubmit(data2);
		reset();
	};

	const [serviceIndex, setServiceIndex] = useState(0);
	const [specIndex, setSpecIndex] = useState(0);

	const [service1, setService1] = useState('');
	const [spec1, setSepec1] = useState(0);

	useEffect(() => {
		if (data?.service_id && mode == 1) {
			const ind = getAllServicesRecord?.findIndex(
				item => item.id === Number(data?.service_id)
			);
			setServiceIndex(ind);
			setValue('service', getAllServicesRecord[ind]);
		}
		if (data?.specialization_id && mode == 1) {
			const ind = getAllSpecilizationRecord?.findIndex(
				item => item.id === Number(data.specialization_id)
			);
			setSpecIndex(ind);
			setValue('specialties', getAllSpecilizationRecord[ind]);
		}
	}, []);

	const onDrop = acceptedFiles => {
		// Assuming only one file is allowed
		if (acceptedFiles && acceptedFiles.length > 0) {
			setValue('profile_image', acceptedFiles[0]);
			// console.log(typeof acceptedFiles[0]);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: 'image/jpeg, image/png',
	});
	useEffect(() => {
		setValue('specialties', getAllSpecilizationRecord[specIndex]);
		setValue('service', getAllServicesRecord[serviceIndex]);
	}, []);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<>
				<Stack spacing={1}>
					<label>Service </label>
					<Controller
						name='service'
						rules={{ required: true }}
						control={control}
						render={({ field }) => (
							<>
								<small style={{ color: 'red' }}>
									{errors.service ? 'Required' : ''}
								</small>
							</>
						)}
					/>
					<Select
						sx={{ height: '40px' }}
						value={service1 || getAllServicesRecord?.[serviceIndex] || ''}
						// value={service1 || getAllServicesRecord[serviceIndex]}
						onChange={e => {
							setService1(e.target.value);
							setValue('service', e.target.value);
						}}
						MenuProps={{
							style: {
								width: '350px',
								height: '300px',
							},
						}}
					>
						{getAllServicesRecord?.map(serice => (
							<MenuItem value={serice} style={{ width: '300px' }}>
								{serice?.name}
							</MenuItem>
						))}
					</Select>

					<label>Speciality</label>

					<Controller
						name='specialties'
						rules={{ required: true }}
						control={control}
						render={({ field }) => (
							<>
								<small style={{ color: 'red' }}>
									{errors.specialties ? 'Required' : ''}
								</small>
							</>
						)}
					/>
					<Select
						sx={{ height: '40px' }}
						value={spec1 || getAllSpecilizationRecord[specIndex]}
						onChange={e => {
							// console.log('dddd', e.target.value);
							setSepec1(e.target.value);
							setValue('specialties', e.target.value);
						}}
						MenuProps={{
							style: {
								width: '350px',
								height: '300px',
							},
						}}
					>
						{getAllSpecilizationRecord?.map(speciality => (
							<MenuItem value={speciality}>{speciality?.name}</MenuItem>
						))}
					</Select>
					<label>Price</label>
					{/* {console.log(errors)} */}
					<Controller
						rules={{ required: true }}
						name='price'
						control={control}
						render={({ field }) => (
							<>
								<TextField
									{...field}
									onChange={e => {
										const numericValue = e.target.value.replace(
											/[^0-9.?]/g,
											''
										);
										field.onChange(numericValue);
									}}
								/>
								<small style={{ color: 'red' }}>
									{errors.price ? 'Required' : ''}
									{/* {errors?.price?.message ? errors?.price?.message : ''} */}
								</small>
							</>
						)}
					/>
					<label>Description</label>
					<Controller
						rules={{ required: true }}
						name='description'
						control={control}
						render={({ field }) => (
							<>
								<TextField {...field} />
								<small style={{ color: 'red' }}>
									{errors.description ? 'Required' : ''}
								</small>
							</>
						)}
					/>

					{/* <Box sx={{ display: 'none' }}>
						<Controller
							name='profile_image'
							rules={{ required: true }}
							control={control}
							render={({ field }) => (
								<>
									<TextField {...field} />
								</>
							)}
						/>
					</Box> */}
				</Stack>

				<Box sx={{ mt: 2 }}>
					{/* <Typography
						sx={{
							fontFamily: 'DM Sans',
							fontSize: '16px',
							fontWeight: '400',
						}}>
						Media
					</Typography> */}

					{/* <Box
						{...getRootProps()}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '115px',
							height: '115px',
							background: '#DEDEDE',
							borderRadius: '12px',
							cursor: 'pointer', // Add cursor pointer on hover
						}}>
						{getValues('profile_image') ||
						data?.image ||
						data?.profile_image ? (
							<img
								src={
									getValues('profile_image') || data?.profile_image
										? URL.createObjectURL(
												getValues('profile_image') || data?.profile_image
										  )
										: 'https://api.healthcoinxchangeportal.com/' + data?.image
								}
								alt='Selected'
								style={{
									maxWidth: '100%',
									maxHeight: '100%',
								}}
							/>
						) : (
							<div>
								<input {...getInputProps()} />
								<CloudUploadIcon fontSize='large' />
							</div>
						)}
					</Box> */}
					<small style={{ color: 'red' }}>
						{errors.profile_image ? 'Required' : ''}
					</small>
					<Grid container>
						<Grid item lg={'auto'} sx={{ mb: 2 }}>
							<Box
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: '25px',
								}}
							>
								<button
									style={{
										width: '325px',
										height: '50px',
										background: 'white',
										color: '#4157FF',
										border: '1px solid #4157FF',
										fontSize: '16px',
										fontWeight: '700',
										borderRadius: '5px',
										cursor: 'pointer',
									}}
									type='submit'
									//   onClick={() => addService()}
								>
									{` ${mode === 1 ? 'Update' : '+Add'} 	 Service`}
								</button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</>
		</form>
	);
}
