// import { TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import Header from '../Layout/Header/Header';
import Sidebar from '../Layout/Sidebar/Sidebar';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import "./info.css";
import RequestDetail from '../Request/RequestDetail';
// import UploadedDocument from "../UploadedDocument";
import { useEffect } from 'react';
import { server } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
// import {  Toaster } from "react-hot-toast";
import Dropzone from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import { toast, Toaster } from 'react-hot-toast';
import InfoDropZone from '../../asset/image/InfoDropZone.svg';
import Shape from '../../asset/image/Header/Shape.png';
import { useNavigate } from 'react-router-dom';
import StatusHandling from '../StatusHandling/StatusHandling';
import Spinner from '../SpinnerTrueFalse/Spinner';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ServiceImage from '../../asset/image/ServiceImage.png';
import DeleteServices from '../../asset/image/DeleteServices.png';
import ServiceEdit from '../../asset/image/ServiceEdit.png';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
	Box,
	Divider,
	TextField,
	Typography,
	Tab,
	Button,
} from '@mui/material';
import GooglePlacesAutocomplete, {
	geocodeByPlaceId,
	getLatLng,
} from 'react-google-places-autocomplete';
import {
	addOutOfNetwoek,
	adminUpdateProfile,
	getAllServicesInAdmin,
	getAllSpecializtionInAdmin,
	getProviderTransactionAction,
	getUserDatainProvider,
	updateProviderProfileByAdmin,
} from '../../redux/action/user';
import moment from 'moment/moment';
const useStyles = makeStyles(theme => ({
	isLargeScreen: {
		width: ' 510px',
		maxHeight: '300px',
		overflow: 'auto',
		cursor: 'pointer',
		display: 'grid',
		justifyContent: 'center',
		border: '1px solid var(--grey, #949494)',
		borderRadius: '12px',

		[theme.breakpoints.down('md')]: {
			width: ' 400px',
			height: '300px',
			overflow: 'auto',
			cursor: 'pointer',
			display: 'grid',
			justifyContent: 'center',
			border: '1px solid var(--grey, #949494)',
			borderRadius: '12px',
		},
		[theme.breakpoints.up('lg')]: {
			width: ' 592px',
			height: '300px',
			overflow: 'auto',
			cursor: 'pointer',
			display: 'grid',
			justifyContent: 'center',
			border: '1px solid var(--grey, #949494)',
			borderRadius: '12px',
		},
	},

	rightServices: {
		marginTop: '12px',
		borderRadius: '12px',
		width: '460px',
		// height: "124px",
		minHeight: '124px',
		maxHeight: 'auto',
		padding: '14px',
		border: '1px solid black',
		[theme.breakpoints.down('md')]: {
			marginTop: '12px',
			borderRadius: '12px',
			width: '460px',
			minHeight: '124px',
			maxHeight: 'auto',
			padding: '14px',
			border: '1px solid black',
		},
		[theme.breakpoints.up('lg')]: {
			marginTop: '12px',
			borderRadius: '12px',
			width: '560px',
			minHeight: '124px',
			maxHeight: 'auto',
			padding: '14px',
			border: '1px solid black',
		},
	},

	root: {
		fontSize: '10px !important',
		fontWeight: 'bold !important',
		textAlign: 'center  !important',

		color: '#23262F !important',
	},
	bold: {
		fontWeight: 'bold !important',
		color: '#3772FF !important',
	},
	headingIcon: {
		display: 'flex',
	},

	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	rounded: {
		borderRadius: '22px !important',
	},
	eyes: {
		cursor: 'pointer',
	},
	verified: {
		color: '#00C48C',
		marginLeft: '6%',
		fontWeight: 'bold',
	},

	centerValue: {
		display: 'flex',
		justifyContent: 'center',
	},

	activebuttonStyle: {
		backgroundColor: '#4CD964',
		color: 'white',
		width: '91px',
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	},
	awaitingbuttonStyle: {
		backgroundColor: '#FFA23A',
		color: 'white',
		width: 'auto',
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	},
	tabsetting: {
		fontWeight: '500 !important',
		fontSize: '14px !important',
		textTransform: 'none !important',
	},
	bilinginfo: {
		fontWeight: '600 !important',
		fontSize: '18px !important',
		fontFamily: 'DM Sans',
	},
	updateinfoLine: {
		fontWeight: '500 !important',
		fontSize: '18px !important',
		fontFamily: 'DM Sans',
		color: '#667085 !important',
	},
	billingtext: {
		fontWeight: ' 500 !important',
		fontSize: '14px !important',
		color: '#344054 !important',
	},
	TextFieldOutlined: {
		width: '512px !important',
		background: 'white !important',
	},
	paragraphNote: {
		fontWeight: '600 !important',
		fontSize: '14px !important',
	},

	updateinfoPersonal: {
		fontWeight: '500 !important',
		fontSize: '16px',
		fontFamily: 'DM Sans',
		color: '#667085',
	},

	twoColumn: {
		background: 'white',
		width: '244px',
	},
	phototext: {
		fontSize: '14px',
		color: '#667085',
	},

	uploadicon: {
		display: 'flex !important',
		justifyContent: 'center !important',
		cursor: 'pointer !important',
		marginTop: '6px',
	},
	payReqIno: {
		color: '#66737F',
		fontFamily: 'DM Sans',
		fontSize: '1rem',
		fontStyle: 'normal',
		fontWeight: 400,
		letterSpacing: '-0.016rem',
	},

	dropzone: {
		boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
		width: '289px',
		minHeight: '127px',
		height: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '20px',
		borderWidth: '2px',
		borderRadius: '4px',
		border: '1px solid grey',
		borderColor: '#fff',
		borderStyle: 'groove',
		backgroundColor: '#fff',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
	uploadStyle: {
		fontSize: '14px',
		color: '#6941C6',
		fontWeight: '700',
		cursor: 'pointer',
	},
}));
// Css
const headLabel = {
	fontWeight: '600',
	fontSize: '14px',
};
const AddServices = () => {
	const classes = useStyles();
	const {
		getAllServicesRecord,
		getAllSpecilizationRecord,
		message,
		isOutNetworkAdded,
	} = useSelector(state => state.user);
	const navigate = useNavigate();

	useEffect(() => {
		console.log(message, isOutNetworkAdded);
		
		if (isOutNetworkAdded) {
			navigate('/NetworkTable');
			dispatch({
				type: 'addOutOfNetworkFailure',
				payload: '',
			});
		}
	}, [isOutNetworkAdded]);
	useEffect(() => {
		if (message && !isOutNetworkAdded) {
			navigate('/AddServices');
		}
	}, [message]);
	const [userprofile, setUserProfile] = useState(null);
	const [cDiscription, setCDiscription] = useState('');
	const [cName, setCName] = useState('');
	const [cPrice, setCPrice] = useState('');
	const [username, setUserName] = useState('');
	const [useremail, setUserEmail] = useState('');
	const [info, setInfo] = useState('Info');
	const [providerProfileImage] = useState('');
	const [loading, setLoading] = useState(false);
	const [userDataPhone, setUserDataPhone] = useState('');
	const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
	const [locationErrorMessage, setLocationErrorMessage] = useState('');
	const [userImageErrorMessage, setUserImageErrorMessage] = useState('');
	const [nameErrorMessage, setNameErrorMessage] = useState('');
	const [emailErrorMessage, setEmailErrorMessage] = useState('');
	const [longitude, setLongitude] = useState('');
	const [latitude, setLatitude] = useState('');
	const [editConsultancyModal, setEditConsultancyModal] = useState(false);
	const [servicesRecords, setServicesRecords] = useState(getAllServicesRecord);
	const [value, setValue] = useState({
		label: '',
		value: {},
	});
	const [serviceName, setServiceName] = React.useState('');
	const [specialityName, setSpecialityName] = React.useState('');
	const [servicePrice, setServicePrice] = React.useState('');
	const [selectedServiceId, setSelectedServiceId] = React.useState('');
	const [serviceDiscription, setServiceDiscription] = React.useState('');
	const [serviceDiscriptionErrorMessage, setServiceDiscriptionErrorMessage] =
		React.useState('');
	const [servicePriceErrorMessage, setServicePriceErrorMessage] =
		React.useState('');
	const [updateServicePriceErrorMessage, setUpdateServicePriceErrorMessage] =
		React.useState('');
	const [updateSpecialityErrorMessage, setUpdateSpecialityErrorMessage] =
		React.useState('');
	const [serviceNameErrorMessage, setServiceNameErrorMessage] =
		React.useState('');
	const [specialityNameErrorMessage, setSpecialityNameErrorMessage] =
		React.useState('');
	const [allServices, setAllServices] = React.useState([]);
	const [updateService, setUpdateService] = useState({});
	const selectRef = useRef(null);
	const handleChange = event => {
		const { value } = event.target;
		setServiceNameErrorMessage('');
		setServiceName(value);
		// const data=JSON.parse(value)
		setSelectedServiceId(value);
	};
	const handleChangeSpeciality = event => {
		setSpecialityNameErrorMessage('');
		const { value } = event.target;

		setSpecialityName(value);
	};
	const handleUpdateService = event => {
		setSpecialityNameErrorMessage('');
		const { value } = event.target;
		const result = JSON.parse(value);
		setUpdateService({
			...updateService,
			stringifyService: value,
			service_id: result?.id,
			service_name: result?.name,
		});
	};
	const handleUpdateSpeciality = event => {
		setSpecialityNameErrorMessage('');
		const { value } = event.target;
		const result = JSON.parse(value);
		setUpdateService({
			...updateService,
			stringifySpeciality: value,
			specialization_id: result?.id,
			specialization: result?.name,
		});
	};

	const [cNameErrorMessae, setCNameErrorMessage] = useState('');
	const [cDiscriptionErrorMessae, setCDiscriptionErrorMessage] = useState('');
	const [cPriceErrorMessae, setCPriceErrorMessage] = useState('');
	const [consultationObj, setConsultationObj] = useState({});
	const dispatch = useDispatch();
	const deleteConsultancy = () => {
		setConsultationObj({});
	};
	useEffect(() => {
		setServicesRecords(getAllServicesRecord);
	}, JSON.stringify(getAllServicesRecord));

	// const [value, setValue] = React.useState("1");
	// const handleChange = (event, newValue) => {
	//   setValue(newValue);
	// };
	// css
	useEffect(() => {
		dispatch(getAllServicesInAdmin());
		dispatch(getAllSpecializtionInAdmin());
	}, []);
	const handleDrop = acceptedFiles => {
		setUserImageErrorMessage('');
		const img = acceptedFiles[0];
		setUserProfile(acceptedFiles[0]);

		if (img) {
			const allowedFormats = [
				'image/svg+xml',
				'image/png',
				'image/jpeg',
				'image/jpg',
			];

			if (!allowedFormats.some(format => format.includes(img.type))) {
				toast.error('Invalid file format. Please upload an image file.');
				return;
			}
		}
	};

	const updateProviderProfile = () => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

		if (!username) {
			return setNameErrorMessage('Please enter a user name');
		}
		if (!useremail) {
			return setEmailErrorMessage('Please enter a user email');
		}
		if (!emailRegex.test(useremail)) {
			return setEmailErrorMessage('Please enter a valid user email');
		}
		if (!userDataPhone) {
			return setPhoneErrorMessage('Please enter a user phone number');
		}
		if (!latitude) {
			return setLocationErrorMessage('Please add a location');
		}
		if (!userprofile) {
			return setUserImageErrorMessage('Please add a user profile image');
		}
		if (allServices?.length < 1) {
			return toast.error('Please select atleast one service');
		}
		if (Object.keys(consultationObj).length === 0) {
			return toast.error('Please add consultation before save.');
		}

		dispatch(
			addOutOfNetwoek(
				username,
				useremail,
				userDataPhone,
				longitude,
				latitude,
				value,
				userprofile,
				consultationObj,
				allServices
			)
		);
	};
	const addService = () => {
		if (!serviceName) {
			return setServiceNameErrorMessage('Please select a service name');
		}
		if (!specialityName) {
			return setSpecialityNameErrorMessage(
				'Please select a service speciality'
			);
		}
		if (!servicePrice) {
			return setServicePriceErrorMessage('Please add a service price');
		}
		// if (parseInt(servicePrice) > parseInt(JSON.parse(serviceName)?.min_price)) {
		// 	return setServicePriceErrorMessage(
		// 		`Service price must be less then or equal to min-price of ${
		// 			JSON.parse(serviceName).min_price
		// 		} `
		// 	);
		// }
		if (!serviceDiscription) {
			return setServiceDiscriptionErrorMessage(
				'Please add a service description'
			);
		}

		// if (!selectedImage) {
		// 	return toast.error('Please add image for service');
		// }
		const parseService = JSON.parse(serviceName);
		const parseSpeciality = JSON.parse(specialityName);
		setAllServices([
			...allServices,
			{
				id: allServices?.length,
				service_id: parseService?.id,
				service_name: parseService?.name,
				specialization: parseSpeciality?.name,
				specialization_id: parseSpeciality?.id,
				price: servicePrice,
				description: serviceDiscription,
				// display_image: URL.createObjectURL(selectedImage),
				// image: selectedImage,
				stringifyService: serviceName,
				stringifySpeciality: specialityName,
			},
		]);

		setServiceName('');
		// setSpecialityName({})
		// setSelectedImage('');
		setSpecialityName('');
		setServicePrice('');
		setServiceDiscription('');
		setSelectedServiceId('');
		selectRef.current.value = '';
		setServicesRecords([]);
		setServicesRecords(getAllServicesRecord);
	};

	const deleteServiceArray = id => {
		setAllServices(allServices.filter(service => service?.id != id));
	};
	const [open, setOpen] = useState(false);
	const [editServiceModal, setEditServiceModal] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleOpenConsultansy = () => {
		setEditConsultancyModal(true);
		setCName(consultationObj?.Consultation);
		setCDiscription(consultationObj?.discription);
		setCPrice(consultationObj?.dollar);
	};
	const handleCloseConsultansy = () => {
		setEditConsultancyModal(false);
		setCName('');
		setCDiscription('');
		setCPrice('');
	};
	const handleCloseService = () => {
		setEditServiceModal(false);
		setCName('');
		setCDiscription('');
		setCPrice('');
	};

	// dropzone
	const [selectedImage, setSelectedImage] = useState(null);
	const [timeStampsNotes, setTimestampNotes] = useState('');
	useEffect(() => {
		const currentDate = moment().format('YYYY-MM-DD');
		const currentTime = moment().format('HH:mm');
		setTimestampNotes(`${currentDate} at ${currentTime}`);
	}, []);
	const onDrop = acceptedFiles => {
		// Assuming only one file is allowed
		if (acceptedFiles && acceptedFiles.length > 0) {
			setSelectedImage(acceptedFiles[0]);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: 'image/jpeg, image/png',
	});

	const handlePlaceSelect = async value => {
		try {
			setLocationErrorMessage('');
			setValue(value);
			const results = await geocodeByPlaceId(value?.value?.place_id);
			if (results && results?.length > 0) {
				const { lat, lng } = await getLatLng(results[0]);
				setLatitude(lat);
				setLongitude(lng);
			}
		} catch (error) {
			console.error('Error fetching geolocation:', error);
		}
	};

	const changePhoneNumber = e => {
		setPhoneErrorMessage('');
		if (userDataPhone?.length <= 16) {
			setUserDataPhone(e.target.value);
		}
	};
	const changeServiceDiscription = e => {
		setServiceDiscriptionErrorMessage('');
		setServiceDiscription(e.target.value);
	};
	const changeServicePrice = e => {
		setServicePriceErrorMessage('');
		const numericValue = e.target.value.replace(/[^0-9.?]/g, '');
		setServicePrice(numericValue);
	};
	const changeUserName = e => {
		setNameErrorMessage('');
		setUserName(e.target.value);
	};
	const changeUserEmail = e => {
		setEmailErrorMessage('');

		setUserEmail(e.target.value);
	};
	//
	const updateServiceDiscription = e => {
		setUpdateSpecialityErrorMessage('');
		setUpdateService({ ...updateService, description: e.target.value });
	};
	const updateServicePrice = e => {
		const sanitizedValue = e.target.value.replace(/[^0-9.?]/g, '');
		setUpdateServicePriceErrorMessage('');
		setUpdateService({ ...updateService, price: sanitizedValue });
	};

	const updateServiceRecord = () => {
		if (!updateService?.price) {
			return setUpdateServicePriceErrorMessage('Please add a service price');
		}
		
		if (!updateService?.description) {
			return setUpdateSpecialityErrorMessage('Please add a service description');
		}
		const newArray = [...allServices];
		const indexOfObjectToUpdate = newArray.findIndex(
			item => item.id === updateService?.id
		);

		if (indexOfObjectToUpdate !== -1) {
			newArray[indexOfObjectToUpdate] = updateService;
			setAllServices(newArray);
		}
		setEditServiceModal(false);
		// setAllServices(allServices.findOne(service=>service.id === updateService.id))
	};

	//
	const editModelOpen = data => {
		setUpdateService(data);
		setEditServiceModal(true);
	};
	const handleSubmit = () => {
		if (!cName) {
			return setCNameErrorMessage('name must be required');
		}
		if (!cPrice) {
			return setCPriceErrorMessage('price must be required');
		}
		if (!cDiscription) {
			return setCDiscriptionErrorMessage('discription must be required');
		}
		if (cName && cPrice && cDiscription) {
			setConsultationObj({
				Consultation: cName,
				dollar: cPrice,
				discription: cDiscription,
			});
			setOpen(false);

			setCName('');
			setCPrice('');
			setCDiscription('');
		}
	};
	const handleUpateSubmit = () => {
		if (!cName) {
			return setCNameErrorMessage('name must be required');
		}
		if (!cPrice) {
			return setCPriceErrorMessage('price must be required');
		}
		if (!cDiscription) {
			return setCDiscriptionErrorMessage('discription must be required');
		}
		if (cName && cPrice && cDiscription) {
			setConsultationObj({
				Consultation: cName,
				dollar: cPrice,
				discription: cDiscription,
			});
			setEditConsultancyModal(false);

			setCName('');
			setCPrice('');
			setCDiscription('');
		}
	};
	const submitContactButton = {
		color: 'white ',
		background: ' #4157FF ',
		borderRadius: '56px ',
		border: 'none',
		width: '203px ',
		height: '50px',
		cursor: 'pointer ',
		fontWeight: '600',
	};
	const notesCreation = () => {};

	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<div className='detail'>
					<div className='home'>
						<Sidebar info={info == 'Transactions' ? 'Transactions' : ''} />
						<div className='homeContainer'>
							<Header
								currentPath=<span
									style={{
										marginLeft: '10px',
										fontSize: '12px',
										fontWeight: '500',
										color: '#D0D1D2',
									}}
								>
									OUT OF NETWORK &nbsp; <img src={Shape} alt='' /> &nbsp;{' '}
									{info == 'Info' && 'INFO'} {info == 'upload' && 'ADD SERVICE'}{' '}
								</span>
							/>
							<div className='requestDetail'>
								<RequestDetail
									saveButton={true}
									// downloadAs={downloadAs}
									// verifyButton={verifyButton}
									show={true}
									name={username}
									email={useremail}
									healthcoin_id={username ? 'not yet' : null}
									profile_image={providerProfileImage}
									// id={userData?.id}
									page='Add Out of Network Provider'
									updateProviderProfile={updateProviderProfile}
								/>
							</div>
							<div
								className='info-content'
								style={{ width: 'auto', backgroundColor: '#F8F9FE' }}
							>
								<div className='info' style={{ width: '96%' }}>
									<br />
									<span
										className={(info === 'Info' || info === 'Info') && 'active'}
										style={{
											fontWeight: 600,
											color: `black`,
											fontSize: '14px',
											opacity: 1,
										}}
										onClick={() => {
											setInfo('Info');
										}}
									>
										Info
									</span>
									&nbsp;
									<span
										className={info == 'upload' && 'active'}
										style={{
											fontWeight: 600,
											color: `black`,
											fontSize: '14px',
											opacity: 1,
										}}
										onClick={() => {
											setInfo('upload');
										}}
									>
										Add Services
									</span>
									{/* abbas */}
								</div>

								{info === 'Info' && (
									<>
										<div
											className='details_edit'
											style={{
												width: '96%',
												minHeight: 'auto',
												height: 'auto',
											}}
										>
											<div className='detail_main_edit'>
												<br />
												<div className='user_info_Update'>
													<Box>
														<Box
															sx={{
																display: 'flex',
																width: '96%',
																justifyContent: 'space-between',
															}}
														>
															<Box style={{}}>
																<Box sx={{ display: 'grid' }}>
																	<Box>
																		<span style={headLabel}>Name</span>
																	</Box>
																	<Box>
																		<TextField
																			// disabled
																			margin='dense'
																			fullWidth
																			InputProps={{ style: { height: '31px' } }}
																			sx={{
																				boxShadow:
																					'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																				width: '289px',
																			}}
																			name='employee_id'
																			defaultValue={username}
																			onChange={changeUserName}
																		/>
																		<p
																			style={{
																				color: 'red',
																				fontSize: '13px',
																				marginTop: '-3px',
																			}}
																		>
																			{nameErrorMessage}
																		</p>
																	</Box>
																</Box>
																<Box sx={{ display: 'grid' }}>
																	<Box>
																		<span style={headLabel}>Email</span>
																	</Box>
																	<Box>
																		<TextField
																			// disabled
																			margin='dense'
																			fullWidth
																			type='email'
																			InputProps={{ style: { height: '31px' } }}
																			sx={{
																				boxShadow:
																					'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																				width: '289px',
																			}}
																			name='employee_id'
																			defaultValue={useremail}
																			onChange={changeUserEmail}
																		/>
																		<p
																			style={{
																				color: 'red',
																				fontSize: '13px',
																				marginTop: '-3px',
																			}}
																		>
																			{emailErrorMessage}
																		</p>
																	</Box>
																</Box>

																<Box sx={{ display: 'grid' }}>
																	<Box>
																		<span style={headLabel}>
																			Clinic Phone Number
																		</span>
																	</Box>
																	<Box>
																		<TextField
																			// disabled
																			margin='dense'
																			fullWidth
																			InputProps={{ style: { height: '31px' } }}
																			sx={{
																				boxShadow:
																					'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																				width: '289px',
																			}}
																			name='employee_id'
																			defaultValue={userDataPhone}
																			onChange={changePhoneNumber}
																		/>
																		<p
																			style={{
																				color: 'red',
																				fontSize: '13px',
																				marginTop: '-3px',
																			}}
																		>
																			{phoneErrorMessage}
																		</p>
																	</Box>
																</Box>
																<Box sx={{ display: 'grid' }}>
																	<Box>
																		<span style={headLabel}>
																			Clinic Address
																		</span>
																	</Box>

																	<div style={{ width: '289px' }}>
																		<GooglePlacesAutocomplete
																			selectProps={{
																				value,
																				onChange: value => {
																					handlePlaceSelect(value);
																				},
																			}}
																			apiKey={
																				'AIzaSyA988SBerEvI97KDts_IaNY4GYVby-fe4g'
																			}
																		/>
																	</div>
																	<p
																		style={{
																			color: 'red',
																			fontSize: '13px',
																			marginTop: '-3px',
																		}}
																	>
																		{locationErrorMessage}
																	</p>
																</Box>
																<br />
																<Box sx={{ display: 'grid' }}></Box>
																<br />
															</Box>
															<Box
																style={{
																	cursor: 'pointer',
																	height: 'auto',
																}}
															>
																<Dropzone onDrop={handleDrop}>
																	{({
																		getRootProps,
																		getInputProps,
																		isDragActive,
																	}) => (
																		<div
																			className={classes.dropzone}
																			{...getRootProps()}
																			style={
																				isDragActive
																					? {
																							borderColor: '#2196f3',
																							backgroundColor: '#e3f2fd',
																							height: 'auto',
																					  }
																					: {}
																			}
																		>
																			{/* <img src={downloadIconSetting} alt="" /> */}
																			<input {...getInputProps()} />
																			{/* <img
                                    src=""
                                    alt="upload_icon"
                                    style={{ cursor: "pointer" }}
                                    className={classes.uploadicon}
                                  /> */}
																			<Box
																				sx={{ display: 'grid', gap: '20px' }}
																			>
																				<Box
																					sx={{
																						display: 'flex',
																						justifyContent: 'center',
																					}}
																				>
																					<img src={InfoDropZone} alt='' />
																				</Box>

																				<Box>
																					<span
																						style={{
																							fontSize: '14px',
																							fontWeight: '400',
																							color: '#4157FF',
																							textDecoration: 'underline',
																						}}
																					>
																						Add Provider Photo
																					</span>
																				</Box>
																			</Box>
																			<span
																				style={{
																					fontWeight: 900,
																					maxWidth: '250px',
																					overflowWrap: 'break-word',
																				}}
																			>
																				{userprofile && userprofile?.name}
																			</span>
																		</div>
																	)}
																</Dropzone>
																<p
																	style={{
																		color: 'red',
																		fontSize: '13px',
																		marginTop: '5px',
																		textAlign: 'center',
																	}}
																>
																	{userImageErrorMessage}
																</p>
															</Box>
														</Box>

														<Divider
															style={{ marginTop: '10px', width: '72vw' }}
														/>
													</Box>

													{/* here Write the code  */}
												</div>
											</div>
										</div>
									</>
								)}
								<br />
								{info === 'Info' && (
									<>
										<Box
											sx={{
												display: 'flex',
												width: '96%',
												margin: 'auto',
												backgroundColor: '#fff',
												marginBottom: '15px',
											}}
										>
											<TextField
												fullWidth
												disabled
												multiline
												placeholder='Notes:'
												name='description'
												rows={4}
												value={timeStampsNotes}
												// onChange={notesCreation}
												style={{ fontSize: '10px', color: '#6E757C' }}
											/>
										</Box>
									</>
								)}

								{info === 'upload' && (
									<>
										<div
											className='details_edit'
											style={{
												width: '96%',
												minHeight: 'auto',
												height: 'auto',
											}}
										>
											<div className='detail_main_edit'>
												<br />
												<div className='user_info_Update'>
													<Box
														style={{
															display: 'flex',
															gap: '40px',
															width: '50vw',
														}}
													>
														<Box style={{ display: 'flex', gap: '20px' }}>
															<Box
																style={{
																	display: 'grid',
																	// gap: "30px",
																	marginBottom: '12px',
																}}
															>
																<Box>
																	<Typography
																		sx={{
																			fontSize: '16px',
																			fontWeight: '400',
																			fontFamily: 'DM Sans',
																		}}
																	>
																		Service Name
																	</Typography>

																	{/* iiiiiiiiiiiiiiiiiiiiiiiii */}
																	<FormControl
																		// variant="standard"
																		sx={{ width: '100%' }}
																	>
																		<InputLabel>
																			Please select service
																		</InputLabel>
																		<Select
																			value={serviceName}
																			ref={selectRef}
																			label='Please select service'
																			onChange={handleChange}
																			MenuProps={{
																				style: {
																					width: '350px',
																					height: '300px',
																				}, // Adjust the width as needed
																			}}
																		>
																			{getAllServicesRecord?.map(service => (
																				<MenuItem
																					value={JSON.stringify(service)}
																					style={{ width: '300px' }}
																				>
																					{service?.name.length > 40 ? (
																						<>
																							{service?.name.slice(0, 40)}
																							<br />
																							{service?.name.slice(40)}
																						</>
																					) : (
																						service?.name
																					)}
																				</MenuItem>
																			))}
																			
																		</Select>
																	</FormControl>
																	<p
																		style={{
																			color: 'red',
																			fontSize: '13px',
																			marginTop: '-3px',
																		}}
																	>
																		{serviceNameErrorMessage}
																	</p>
																</Box>

																<Box>
																	<Typography
																		sx={{
																			fontFamily: 'DM Sans',
																			fontSize: '16px',
																			fontWeight: '400',
																		}}
																	>
																		Specialization
																	</Typography>

																	<FormControl sx={{ minWidth: '100%' }}>
																		<InputLabel>
																			Please select Specialties
																		</InputLabel>
																		<Select
																			value={specialityName}
																			label='Please select speciality'
																			onChange={handleChangeSpeciality}
																			MenuProps={{
																				style: {
																					width: '350px',
																					height: '300px',
																				}, // Adjust the width as needed
																			}}
																		>
																			{/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
																			{getAllSpecilizationRecord?.map(
																				speciality => (
																					<MenuItem
																						value={JSON.stringify(speciality)}
																					>
																						{speciality?.name}
																					</MenuItem>
																				)
																			)}
																			{/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
																		</Select>
																	</FormControl>
																	<p
																		style={{
																			color: 'red',
																			fontSize: '13px',
																			marginTop: '-3px',
																		}}
																	>
																		{specialityNameErrorMessage}
																	</p>
																</Box>

																<Box>
																	<Typography
																		sx={{
																			fontFamily: 'DM Sans',
																			fontSize: '16px',
																			fontWeight: '400',
																		}}
																	>
																		Price $
																	</Typography>
																	<TextField
																		margin='dense'
																		value={servicePrice}
																		onChange={changeServicePrice}
																		fullWidth
																		type='text'
																		// min='0'
																		InputProps={{
																			style: { height: '40px' },
																			// disabled: true,
																		}}
																		sx={{
																			boxShadow:
																				'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																			width: '370px',
																		}}
																		placeholder='$0.0'
																	/>{' '}
																	<p
																		style={{
																			color: 'red',
																			fontSize: '13px',
																			marginTop: '-3px',
																		}}
																	>
																		{servicePriceErrorMessage}
																	</p>
																</Box>

																<Box>
																	<Typography
																		sx={{
																			fontFamily: 'DM Sans',
																			fontSize: '16px',
																			fontWeight: '400',
																		}}
																	>
																		Description
																	</Typography>
																	<TextField
																		fullWidth
																		multiline
																		name='message'
																		rows={6}
																		variant='outlined'
																		sx={{
																			boxShadow:
																				'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																			width: '370px',
																		}}
																		inputProps={{ maxLength: 1500 }}
																		value={serviceDiscription}
																		onChange={changeServiceDiscription}
																	/>
																	<p
																		style={{
																			color: 'red',
																			fontSize: '13px',
																			marginTop: '-3px',
																		}}
																	>
																		{serviceDiscriptionErrorMessage}
																	</p>
																</Box>
															</Box>

															{/* <Box>
																<Typography
																	sx={{
																		fontFamily: 'DM Sans',
																		fontSize: '16px',
																		fontWeight: '400',
																	}}>
																	Media
																</Typography>

																<Box
																	{...getRootProps()}
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		width: '115px',
																		height: '115px',
																		background: '#DEDEDE',
																		borderRadius: '12px',
																		cursor: 'pointer',
																	}}>
																	{selectedImage ? (
																		<img
																			src={URL.createObjectURL(selectedImage)}
																			alt='Selected'
																			style={{
																				maxWidth: '100%',
																				maxHeight: '100%',
																			}}
																		/>
																	) : (
																		<div>
																			<input {...getInputProps()} />
																			<CloudUploadIcon fontSize='large' />
																			
																		</div>
																	)}
																</Box>
															</Box> */}
														</Box>

														<Box
															style={{ marginLeft: '60px', marginTop: '20px' }}
														>
															<Box
																className={`${classes.isLargeScreen} scrollTest`}
															>
																{!(
																	Object.keys(consultationObj).length === 0
																) ? (
																	<>
																		<span
																			style={{
																				textAlign: 'center',
																				fontWeight: 800,
																				fontSize: '16px',
																			}}
																		>
																			Consultation
																		</span>
																		<Box className={classes.rightServices}>
																			<Box style={{ height: 'auto' }}>
																				<Box
																					style={{
																						display: 'flex',
																						justifyContent: 'space-between',
																						gap: '14px',
																						boxSizing: 'border-box',
																						overflow: 'auto',
																						height: 'auto',
																					}}
																				>
																					<Box style={{ marginTop: '37px' }}>
																						<span
																							style={{
																								fontSize: '16px',
																								fontWeight: '700',
																							}}
																						>
																							{consultationObj?.Consultation}
																						</span>
																					</Box>
																					<Box style={{ marginTop: '37px' }}>
																						<span
																							style={{
																								fontSize: '16px',
																								fontWeight: '400',
																							}}
																						>
																							${consultationObj?.dollar}
																						</span>
																					</Box>
																					<Box
																						style={{
																							marginTop: '37px',
																							width: '5vw',
																							height: 'auto',
																						}}
																					>
																						<span
																							style={{
																								fontSize: '16px',
																								fontWeight: '400',
																							}}
																						>
																							{consultationObj?.discription}
																						</span>
																					</Box>
																					<Box
																						style={{
																							display: 'flex',
																							gap: '25px',
																							marginTop: '21px',
																						}}
																					>
																						<Box style={{ display: 'grid' }}>
																							<Box
																								onClick={() =>
																									deleteConsultancy()
																								}
																								style={{ cursor: 'delete' }}
																							>
																								<img
																									style={{
																										width: '21px',
																										height: '24px',
																									}}
																									src={DeleteServices}
																									alt=''
																								/>
																							</Box>
																							<Box
																								style={{
																									marginBottom: '52px',
																									marginLeft: '-11px',
																								}}
																							>
																								<span
																									style={{ fontSize: '14px' }}
																								>
																									{/* Delete */}
																								</span>
																							</Box>
																						</Box>
																						<Box style={{ display: 'grid' }}>
																							<Box
																								onClick={() =>
																									handleOpenConsultansy()
																								}
																							>
																								<img
																									style={{
																										width: '24px',
																										height: '24px',
																									}}
																									src={ServiceEdit}
																									alt=''
																								/>
																							</Box>
																							<Box
																								style={{
																									marginBottom: '52px',
																								}}
																							>
																								<span
																									style={{ fontSize: '14px' }}
																								>
																									{/* Edit */}
																								</span>
																							</Box>
																						</Box>
																					</Box>
																				</Box>
																			</Box>
																			<Divider />
																		</Box>
																		<br />
																		<Divider />
																	</>
																) : (
																	<>
																		<br />
																		<button
																			style={{
																				...submitContactButton,
																				textAlign: 'center',
																				margin: 'auto',
																				width: '460px',
																				backgroundColor: 'rgb(222, 222, 222)',
																				color: 'black',
																				borderRadius: '12px',
																			}}
																			onClick={() => setOpen(true)}
																		>
																			Add Consultation
																		</button>
																		<br />
																	</>
																)}
																{allServices?.map(row => (
																	<Box
																		className={classes.rightServices}
																		style={{ width: 'auto' }}
																	>
																		<Box key={row.id}>
																			<Box
																				style={{
																					display: 'flex',
																					justifyContent: 'space-between',
																					textAlign: 'center',
																					flex: 1,
																					gap: '12px',
																				}}
																			>
																				{/* <Box>
																					<img
																						style={{
																							width: '128',
																							height: '98px',
																						}}
																						src={row?.display_image}
																						alt='row?.image'
																					/>
																				</Box> */}
																				<Box style={{ marginTop: '37px' }}>
																					<span
																						style={{
																							fontSize: '16px',
																							fontWeight: '700',
																						}}
																					>
																						{row?.service_name}
																					</span>
																				</Box>
																				<Box style={{ marginTop: '37px' }}>
																					<span
																						style={{
																							fontSize: '16px',
																							fontWeight: '400',
																						}}
																					>
																						${row?.price}
																					</span>
																				</Box>
																				<Box
																					style={{
																						display: 'flex',
																						gap: '25px',
																						marginTop: '35px',
																					}}
																				>
																					<Box style={{ display: 'grid' }}>
																						<Box
																							onClick={() =>
																								deleteServiceArray(row?.id)
																							}
																						>
																							<img
																								style={{
																									width: '21px',
																									height: '24px',
																								}}
																								src={DeleteServices}
																								alt=''
																							/>
																						</Box>
																						<Box
																							style={{
																								marginBottom: '52px',
																								marginLeft: '-11px',
																							}}
																						>
																							<span
																								style={{ fontSize: '14px' }}
																							>
																								{row.delete}
																							</span>
																						</Box>
																					</Box>
																					<Box style={{ display: 'grid' }}>
																						<Box
																							onClick={() => editModelOpen(row)}
																						>
																							<img
																								style={{
																									width: '24px',
																									height: '24px',
																								}}
																								src={ServiceEdit}
																								alt=''
																							/>
																						</Box>
																						<Box
																							style={{ marginBottom: '52px' }}
																						>
																							<span
																								style={{ fontSize: '14px' }}
																							>
																								{row.edit}
																							</span>
																						</Box>
																					</Box>
																				</Box>
																			</Box>
																		</Box>
																	</Box>
																))}
																<br />
															</Box>
															<Box
																style={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	marginTop: '25px',
																}}
															>
																<button
																	style={{
																		width: '325px',
																		height: '50px',
																		background: 'white',
																		color: '#4157FF',
																		border: '1px solid #4157FF',
																		fontSize: '16px',
																		fontWeight: '700',
																		borderRadius: '5px',
																		cursor: 'pointer',
																	}}
																	onClick={() => addService()}
																>
																	+Add Services
																</button>
															</Box>
														</Box>
													</Box>

													{/* here Write the code  */}
												</div>
											</div>
										</div>
									</>
								)}
								<br></br>
								<br></br>
							</div>
						</div>
						<br />
					</div>
					<Toaster />
				</div>
			)}

			<Dialog open={editConsultancyModal} onClose={handleCloseConsultansy}>
				{' '}
				<DialogTitle className={classes.buttonAddnewHelp}>
					<span className={classes.headingSettingTest}> Consultation</span>
					<IconButton
						onClick={handleCloseConsultansy}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none ',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{' '}
					{/* sx={{ width: '500px' }} */}
					<Box
						sx={{
							display: 'flex',
							gap: '10px',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Box>
							<Typography
								variant='h6'
								sx={{ fontSize: '14px ', fontWeight: '700', color: '#525F7F' }}
								gutterBottom
							>
								Name
							</Typography>
							<TextField
								margin='dense'
								// placeholder="info.healthcoin@gethealthcoin.com"
								value={cName}
								fullWidth
								InputProps={{
									style: { height: '31px' },
									// disabled: true,
								}}
								sx={{
									boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
									width: '489px',
								}}
								onChange={e => {
									setCName(e.target.value);
									setCNameErrorMessage('');
								}}
							/>{' '}
							<p
								style={{
									color: 'red',
									fontSize: '13px',
									marginTop: '-3px',
								}}
							>
								{cNameErrorMessae}
							</p>
						</Box>

						<Box>
							<Typography
								variant='h6'
								sx={{ fontSize: '14px ', fontWeight: '700', color: '#525F7F' }}
								gutterBottom
							>
								Price
							</Typography>
							<TextField
								margin='dense'
								value={cPrice}
								type='text'
								onChange={e => {
									const numericValue = e.target.value.replace(/[^0-9.?]/g, '');
									setCPrice(numericValue);
									setCPriceErrorMessage('');
								}}
								fullWidth
								InputProps={{
									style: {
										height: '31px',
										// disabled: true,
									},
								}}
								sx={{
									boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
									width: '489px',
								}}
							/>
							<p
								style={{
									color: 'red',
									fontSize: '13px',
									marginTop: '-3px',
								}}
							>
								{cPriceErrorMessae}
							</p>
						</Box>
						<Box>
							<Typography
								variant='h6'
								sx={{ fontSize: '14px ', fontWeight: '700', color: '#525F7F' }}
								gutterBottom
							>
								Description
							</Typography>
							<TextField
								margin='dense'
								value={cDiscription}
								onChange={e => {
									setCDiscription(e.target.value);
									setCDiscriptionErrorMessage('');
								}}
								fullWidth
								InputProps={{
									style: {
										height: '31px',
										// disabled: true,
									},
								}}
								sx={{
									boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
									width: '489px',
								}}
							/>
							<p
								style={{
									color: 'red',
									fontSize: '13px',
									marginTop: '-3px',
								}}
							>
								{cDiscriptionErrorMessae}
							</p>
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '30px',
						}}
					>
						<button
							type='submit'
							style={submitContactButton}
							onClick={handleUpateSubmit}
						>
							Update Consultation
						</button>
					</Box>
				</DialogContent>
			</Dialog>

			{/* Edit Consultancy */}
			<Dialog open={open} onClose={handleClose}>
				{' '}
				<DialogTitle className={classes.buttonAddnewHelp}>
					<span className={classes.headingSettingTest}> Consultation</span>
					<IconButton style={{ position: 'absolute', top: 0, right: 0 }}>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none ',
								borderRadius: '24px',
							}}
							onClick={handleClose}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{' '}
					{/* sx={{ width: '500px' }} */}
					<Box
						sx={{
							display: 'flex',
							gap: '10px',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Box>
							<Typography
								variant='h6'
								sx={{ fontSize: '14px ', fontWeight: '700', color: '#525F7F' }}
								gutterBottom
							>
								Name
							</Typography>
							<TextField
								margin='dense'
								// placeholder="info.healthcoin@gethealthcoin.com"
								value={cName}
								fullWidth
								InputProps={{
									style: { height: '31px' },
									// disabled: true,
								}}
								sx={{
									boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
									width: '489px',
								}}
								onChange={e => {
									setCName(e.target.value);
									setCNameErrorMessage('');
								}}
							/>{' '}
							<p
								style={{
									color: 'red',
									fontSize: '13px',
									marginTop: '-3px',
								}}
							>
								{cNameErrorMessae}
							</p>
						</Box>

						<Box>
							<Typography
								variant='h6'
								sx={{ fontSize: '14px ', fontWeight: '700', color: '#525F7F' }}
								gutterBottom
							>
								Price
							</Typography>
							<TextField
								margin='dense'
								type='text'
								value={cPrice}
								onChange={e => {
									const numericValue = e.target.value.replace(/[^0-9.?]/g, '');
									setCPrice(numericValue);
									setCPriceErrorMessage('');
								}}
								fullWidth
								InputProps={{
									style: {
										height: '31px',
										// disabled: true,
									},
								}}
								sx={{
									boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
									width: '489px',
								}}
							/>
							<p
								style={{
									color: 'red',
									fontSize: '13px',
									marginTop: '-3px',
								}}
							>
								{cPriceErrorMessae}
							</p>
						</Box>
						<Box>
							<Typography
								variant='h6'
								sx={{ fontSize: '14px ', fontWeight: '700', color: '#525F7F' }}
								gutterBottom
							>
								Description
							</Typography>
							<TextField
								margin='dense'
								value={cDiscription}
								onChange={e => {
									setCDiscription(e.target.value);
									setCDiscriptionErrorMessage('');
								}}
								fullWidth
								InputProps={{
									style: {
										height: '31px',
										// disabled: true,
									},
								}}
								sx={{
									boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
									width: '489px',
								}}
							/>
							<p
								style={{
									color: 'red',
									fontSize: '13px',
									marginTop: '-3px',
								}}
							>
								{cDiscriptionErrorMessae}
							</p>
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '30px',
						}}
					>
						<button
							type='submit'
							style={submitContactButton}
							onClick={handleSubmit}
						>
							Add Consultation
						</button>
					</Box>
				</DialogContent>
			</Dialog>

			{/* Edit Service */}
			<Dialog open={editServiceModal} onClose={handleCloseService}>
				{' '}
				<DialogTitle className={classes.buttonAddnewHelp}>
					<span className={classes.headingSettingTest}> Service</span>
					<IconButton style={{ position: 'absolute', top: 0, right: 0 }}>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none ',
								borderRadius: '24px',
							}}
							onClick={handleCloseService}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{' '}
					{/* sx={{ width: '500px' }} */}
					<Box
						sx={{
							display: 'flex',
							gap: '10px',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Box>
							<Typography
								sx={{
									fontSize: '16px',
									fontWeight: '400',
									fontFamily: 'DM Sans',
								}}
							>
								Service Name
							</Typography>

							<FormControl variant='standard' sx={{ m: 1, width: '300px' }}>
								<InputLabel>Please select service</InputLabel>
								<Select
									value={updateService?.stringifyService}
									ref={selectRef}
									label='Please select service'
									onChange={handleUpdateService}
									MenuProps={{
										style: {
											width: '350px',
											height: '300px',
										}, // Adjust the width as needed
									}}
								>
									<MenuItem value=''>
										<em>None</em>
									</MenuItem>
									{getAllServicesRecord?.map(serice => (
										<MenuItem
											value={JSON.stringify(serice)}
											style={{ width: '300px' }}
										>
											{serice?.name}
										</MenuItem>
									))}
									{/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
								</Select>
							</FormControl>
							<p
								style={{
									color: 'red',
									fontSize: '13px',
									marginTop: '-3px',
								}}
							>
								{serviceNameErrorMessage}
							</p>
						</Box>

						<Box>
							<Typography
								sx={{
									fontFamily: 'DM Sans',
									fontSize: '16px',
									fontWeight: '400',
								}}
							>
								Specialization
							</Typography>

							<FormControl variant='standard' sx={{ m: 1, minWidth: '94%' }}>
								<InputLabel>Please select Specialtie</InputLabel>
								<Select
									value={updateService?.stringifySpeciality}
									label='Please select speciality'
									onChange={handleUpdateSpeciality}
									MenuProps={{
										style: {
											width: '350px',
											height: '300px',
										}, // Adjust the width as needed
									}}
								>
									{/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
									{getAllSpecilizationRecord?.map(speciality => (
										<MenuItem value={JSON.stringify(speciality)}>
											{speciality?.name}
										</MenuItem>
									))}
									{/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
								</Select>
							</FormControl>
							<p
								style={{
									color: 'red',
									fontSize: '13px',
									marginTop: '-3px',
								}}
							>
								{specialityNameErrorMessage}
							</p>
						</Box>

						<Box>
							<Typography
								sx={{
									fontFamily: 'DM Sans',
									fontSize: '16px',
									fontWeight: '400',
								}}
							>
								Price $
							</Typography>
							<TextField
								margin='dense'
								// placeholder="info.healthcoin@gethealthcoin.com"
								value={updateService?.price}
								onChange={updateServicePrice}
								fullWidth
								type='text'
								InputProps={{
									style: { height: '40px' },
									// disabled: true,
								}}
								placeholder='$0.0'
								sx={{
									boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
									width: '311px',
								}}
							/>{' '}
							<p
								style={{
									color: 'red',
									fontSize: '13px',
									marginTop: '-3px',
								}}
							>
								{updateServicePriceErrorMessage}
							</p>
						</Box>

						<Box>
							<Typography
								sx={{
									fontFamily: 'DM Sans',
									fontSize: '16px',
									fontWeight: '400',
								}}
							>
								Description
							</Typography>
							<TextField
								fullWidth
								multiline
								name='message'
								rows={4}
								variant='outlined'
								sx={{
									boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
									width: '311px',
								}}
								inputProps={{ maxLength: 500 }}
								value={updateService?.description}
								onChange={updateServiceDiscription}
							/>
							<p
								style={{
									color: 'red',
									fontSize: '13px',
									marginTop: '-3px',
								}}
							>
								{updateSpecialityErrorMessage}
							</p>
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '30px',
						}}
					>
						<button
							type='submit'
							style={submitContactButton}
							onClick={updateServiceRecord}
						>
							Update Service
						</button>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default AddServices;
