import React, { useRef, useState } from 'react';
import Header from '../Layout/Header/Header';
import Sidebar from '../Layout/Sidebar/Sidebar';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Grid,
	Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RequestDetail from '../Request/RequestDetail';
import { useParams } from 'react-router-dom';
import Logo from '../../asset/image/Logo.svg';
import { useEffect } from 'react';
import axios from 'axios';
import { server } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { toast, Toaster } from 'react-hot-toast';
import InfoDropZone from '../../asset/image/InfoDropZone.svg';
import Shape from '../../asset/image/Header/Shape.png';
import { useNavigate } from 'react-router-dom';
import StatusHandling from '../StatusHandling/StatusHandling';
import Spinner from '../SpinnerTrueFalse/Spinner';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ServiceImage from '../../asset/image/ServiceImage.png';
import DeleteServices from '../../asset/image/DeleteServices.png';
import ServiceEdit from '../../asset/image/ServiceEdit.png';
import Dropzone, { useDropzone } from 'react-dropzone';
import moment from 'moment/moment';
import AddServiceForm from '../../component/AddServiceForm';
import AddConsultaionForm from '../../component/AddConsultationForm';
import GooglePlacesAutocomplete, {
	geocodeByPlaceId,
	getLatLng,
	geocodeByAddress,
} from 'react-google-places-autocomplete';
import {
	OutOfNetWorkProviderDetails,
	getAllServicesInAdmin,
	getAllSpecializtionInAdmin,
} from '../../redux/action/user';
import { Box, Divider, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { async } from 'rxjs';
import { object } from 'yup';
import { convertFormDataToString } from '../../helper/indes';
import {
	decryptData,
	encryptData,
} from '../../redux/incript&decript/cryptoUtils';

const useStyles = makeStyles(theme => ({
	isLargeScreen: {
		width: ' 95%',
		maxHeight: '300px',
		overflow: 'auto',
		cursor: 'pointer',
		display: 'grid',
		justifyContent: 'center',
		border: '1px solid var(--grey, #949494)',
		borderRadius: '12px',

		[theme.breakpoints.down('md')]: {
			width: '95%',
			height: '300px',
			overflow: 'auto',
			cursor: 'pointer',
			display: 'grid',
			justifyContent: 'center',
			border: '1px solid var(--grey, #949494)',
			borderRadius: '12px',
		},
		[theme.breakpoints.up('lg')]: {
			width: '100%',
			height: '300px',
			overflow: 'auto',
			cursor: 'pointer',
			display: 'grid',
			justifyContent: 'center',
			border: '1px solid var(--grey, #949494)',
			borderRadius: '12px',
		},
	},

	rightServices: {
		marginTop: '12px',
		borderRadius: '12px',
		width: '460px',
		// height: "124px",
		minHeight: '124px',
		maxHeight: 'auto',
		padding: '14px',
		border: '1px solid black',
		[theme.breakpoints.down('md')]: {
			marginTop: '12px',
			borderRadius: '12px',
			width: '460px',
			minHeight: '124px',
			maxHeight: 'auto',
			padding: '14px',
			border: '1px solid black',
		},
		[theme.breakpoints.up('lg')]: {
			marginTop: '12px',
			borderRadius: '12px',
			width: '560px',
			minHeight: '124px',
			maxHeight: 'auto',
			padding: '14px',
			border: '1px solid black',
		},
	},

	root: {
		fontSize: '10px !important',
		fontWeight: 'bold !important',
		textAlign: 'center  !important',

		color: '#23262F !important',
	},
	bold: {
		fontWeight: 'bold !important',
		color: '#3772FF !important',
	},
	headingIcon: {
		display: 'flex',
	},

	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	rounded: {
		borderRadius: '22px !important',
	},
	eyes: {
		cursor: 'pointer',
	},
	verified: {
		color: '#00C48C',
		marginLeft: '6%',
		fontWeight: 'bold',
	},

	centerValue: {
		display: 'flex',
		justifyContent: 'center',
	},

	activebuttonStyle: {
		backgroundColor: '#4CD964',
		color: 'white',
		width: '91px',
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	},
	awaitingbuttonStyle: {
		backgroundColor: '#FFA23A',
		color: 'white',
		width: 'auto',
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	},
	tabsetting: {
		fontWeight: '500 !important',
		fontSize: '14px !important',
		textTransform: 'none !important',
	},
	bilinginfo: {
		fontWeight: '600 !important',
		fontSize: '18px !important',
		fontFamily: 'DM Sans',
	},
	updateinfoLine: {
		fontWeight: '500 !important',
		fontSize: '18px !important',
		fontFamily: 'DM Sans',
		color: '#667085 !important',
	},
	billingtext: {
		fontWeight: ' 500 !important',
		fontSize: '14px !important',
		color: '#344054 !important',
	},
	TextFieldOutlined: {
		width: '512px !important',
		background: 'white !important',
	},
	paragraphNote: {
		fontWeight: '600 !important',
		fontSize: '14px !important',
	},

	updateinfoPersonal: {
		fontWeight: '500 !important',
		fontSize: '16px',
		fontFamily: 'DM Sans',
		color: '#667085',
	},

	twoColumn: {
		background: 'white',
		width: '244px',
	},
	phototext: {
		fontSize: '14px',
		color: '#667085',
	},

	uploadicon: {
		display: 'flex !important',
		justifyContent: 'center !important',
		cursor: 'pointer !important',
		marginTop: '6px',
	},
	payReqIno: {
		color: '#66737F',
		fontFamily: 'DM Sans',
		fontSize: '1rem',
		fontStyle: 'normal',
		fontWeight: 400,
		letterSpacing: '-0.016rem',
	},

	dropzone: {
		boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
		width: '289px',
		minHeight: '127px',
		height: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '20px',
		borderWidth: '2px',
		borderRadius: '4px',
		border: '1px solid grey',
		borderColor: '#fff',
		borderStyle: 'groove',
		backgroundColor: '#fff',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
	uploadStyle: {
		fontSize: '14px',
		color: '#6941C6',
		fontWeight: '700',
		cursor: 'pointer',
	},
}));

const submitContactButton = {
	color: 'white ',
	background: ' #4157FF ',
	borderRadius: '56px ',
	border: 'none',
	width: '203px ',
	height: '50px',
	cursor: 'pointer ',
	fontWeight: '600',
};

const NetworkProviderDetails = () => {
	const classes = useStyles();
	const { uid } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { getProviderDetails, getAllServicesRecord } = useSelector(
		state => state.user
	);

	const [localServices, setLocalServices] = useState([]);
	const [localConsultation, setLocalConsultation] = useState('');

	useEffect(() => {
		// console.log(getProviderDetails);
		if (getProviderDetails?.providerservice) {
			setLocalServices([...getProviderDetails?.providerservice]);
		}
		if (getProviderDetails?.providerconsultation) {
			setLocalConsultation({ ...getProviderDetails?.providerconsultation });
		}
	}, [JSON.stringify(getProviderDetails)]);

	const [loading, setLoading] = useState(false);
	const [info, setInfo] = useState('Info');
	const [userprofile, setUserProfile] = useState(null);
	const [userImageErrorMessage, setUserImageErrorMessage] = useState('');
	const [consultationObj, setConsultationObj] = useState({});
	const [timeStampsNotes, setTimestampNotes] = useState('');
	const [longitude, setLongitude] = useState('');
	const [serviceEditDialog, setServicdEditDialog] = useState(false);
	const [consultatonEditDialog, setConsultatondEditDialog] = useState(false);
	const [serviceEditData, setServiceEditData] = useState('');
	const [selectedImage, setSelectedImage] = useState(null);
	const updateProviderProfile = async () => {
		const ddd = getValues('userImage');

		let lati, lngi;
		if (localServices?.length < 1) {
			return toast.error('Please select atleast one service');
		}
		if (Object.keys(consultationObj).length === 1) {
			return toast.error('Please add consultation before save.');
		}

		const cc = getValues('location');

		const results = await geocodeByPlaceId(
			getValues('location')?.value?.place_id ||
			getValues('location')?.value?.[0].place_id
		);

		if (results && results?.length > 0) {
			const { lat, lng } = await getLatLng(results[0]);
			lati = lat;
			lngi = lng;
		}

		try {
			const formData = new FormData();
			formData.append('name', getValues('name'));
			formData.append('email', getValues('email'));
			formData.append('provider_id', getProviderDetails.id);
			formData.append('latitude', lati);
			formData.append('longitude', lngi);
			formData.append('location', getValues('location').label);
			formData.append('phone', getValues('phoneNumber'));
			formData.append(
				'consultation_price',
				localConsultation.consultation_price
			);
			formData.append(
				'consultation_description',
				localConsultation?.description
			);
			formData.append('consultation_name', localConsultation.consultation_name);
			formData.append('consultation_id', localConsultation.id);

			const mapedServices = localServices.map((service, index) => {
				return {
					id: service.id || '',
					service_name: service.service_name,
					specialization: service.specialization,
					price: service.price,
					service_id: service.service_id,
					specialization_id: service.specialization_id,
					description: service.description,
				};
			});

			const resultObject = {};
			for (let entry of formData.entries()) {
				const [key, value] = entry;
				resultObject[key] = value;
			}
			// resultObject.service = mapedServices;

			// resultObject.service = mapedServices;
			const encrypted_data = encryptData(resultObject);
			// const encrypted_services = encryptData(mapedServices);
			const token = `Bearer ${localStorage.getItem('token')}`;

			let { data } = await axios.post(
				`${server}/update_outnetowrk_provider`,
				{
					encrypted_data: encrypted_data,
					service: mapedServices,
					profile_image: userprofile,
				},
				{
					headers: {
						Authorization: token,
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			if (data?.encrypted_data) {
				const decryptedData = decryptData(data.encrypted_data);

				if (decryptedData?.success) {
					toast.success('Provider Updated.');
					navigate('/NetworkTable');
				} else {
					toast.error('Failed to update provide.');
				}
				dispatch({
					type: 'OutOfNetWorkProviderSuccess',
					payload: decryptedData?.data?.providers?.data,
					message: decryptedData?.message,
				});
			}
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'OutOfNetWorkProviderFail',
				payload: decryptedErrorMessage?.message,
			});
			console.error(decryptedErrorMessage?.message);
		}
	};

	const handleServiceEdit = (e, index, row) => {
		setServicdEditDialog(true);
		setServiceEditData({ ...row, indexS: index });
	};

	const handleConsultationEdit = () => {
		setConsultatondEditDialog(true);
	};

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
	} = useForm({
		defaultValues: {},
	});
	const onSubmit = data => console.log(data);

	// call APis
	useEffect(() => {
		dispatch(getAllServicesInAdmin());
		dispatch(getAllSpecializtionInAdmin());
		dispatch(OutOfNetWorkProviderDetails(uid));
	}, []);

	const d = async () => {
		if (getProviderDetails?.location) {
			const d = await geocodeByAddress(getProviderDetails?.location);

			setValue('location', {
				label: getProviderDetails?.location,
				value: d,
			});
		}
	};

	useEffect(() => {
		// console.log(getProviderDetails);
		if (getProviderDetails?.location) {
			d();
		}
		setValue('name', getProviderDetails?.name);
		setValue('email', getProviderDetails?.email);
		setValue('phoneNumber', getProviderDetails?.phone);
	}, [getProviderDetails]);

	const onDrop = acceptedFiles => {
		// Assuming only one file is allowed
		if (acceptedFiles && acceptedFiles.length > 0) {
			setSelectedImage(acceptedFiles[0]);
		}
	};

	// dropzone image

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: 'image/jpeg, image/png',
	});

	const handleDrop = acceptedFiles => {
		setUserImageErrorMessage('');
		const img = acceptedFiles[0];

		setUserProfile(acceptedFiles[0]);
		setValue('userImage', acceptedFiles[0]);

		if (img) {
			const allowedFormats = [
				'image/svg+xml',
				'image/png',
				'image/jpeg',
				'image/jpg',
			];

			if (!allowedFormats.some(format => format.includes(img.type))) {
				toast.error('Invalid file format. Please upload an image file.');
				return;
			}
		}
	};
	useEffect(() => {
		// Set default value based on getProviderDetails?.profile_image
		if (getProviderDetails && getProviderDetails.profile_image) {
			setUserProfile(getProviderDetails.profile_image);
		}
	}, []);

	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<div className='detail'>
					<div className='home'>
						<Sidebar info={info == 'Transactions' ? 'Transactions' : ''} />
						<div className='homeContainer'>
							<Header
								currentPath=<span
									style={{
										marginLeft: '10px',
										fontSize: '12px',
										fontWeight: '500',
										color: '#D0D1D2',
									}}
								>
									OUT OF NETWORK &nbsp; <img src={Shape} alt='' /> &nbsp;{' '}
									{info == 'Info' && 'INFO'}{' '}
									{info == 'upload' && 'UPDATE SERVICE'}{' '}
								</span>
							/>

							<div className='requestDetail'>
								<RequestDetail
									saveButton={true}
									// downloadAs={downloadAs}
									// verifyButton={verifyButton}
									show={true}
									name={getValues('name')}
									email={getValues('email')}
									healthcoin_id={getProviderDetails?.healthcoin_id}
									profile_image={getProviderDetails?.profile_image}
									// id={userData?.id}
									page=' Update Out of Network Provider'
									updateProviderProfile={updateProviderProfile}
								/>
							</div>

							<div
								className='info-content'
								style={{ width: 'auto', backgroundColor: '#F8F9FE' }}
							>
								<div className='info' style={{ width: '96%' }}>
									<br />
									<span
										className={(info === 'Info' || info === 'Info') && 'active'}
										style={{
											fontWeight: 600,
											color: `black`,
											fontSize: '14px',
											opacity: 1,
										}}
										onClick={() => {
											setInfo('Info');
										}}
									>
										Info
									</span>
									&nbsp;
									<span
										className={info == 'upload' && 'active'}
										style={{
											fontWeight: 600,
											color: `black`,
											fontSize: '14px',
											opacity: 1,
										}}
										onClick={() => {
											setInfo('upload');
										}}
									>
										Add Services
									</span>
									{/* abbas */}
								</div>

								<form onSubmit={handleSubmit(onSubmit)}>
									{info === 'Info' && (
										<>
											<Grid container sx={{ padding: '30px' }}>
												<Grid item xs={5}>
													<Stack spacing={4}>
														<Controller
															name='name'
															rules={{ required: true }}
															control={control}
															render={({ field }) => (
																<TextField focused label='Name' {...field} />
															)}
														/>
														{errors.name && <small>This is required.</small>}
														<Controller
															name='email'
															// rules={{ required: true }}
															control={control}
															render={({ field }) => (
																<TextField
																	focused
																	label='Email'
																	{...field}
																	InputProps={{ readOnly: true }}
																/>
															)}
														/>
														<Controller
															name='phoneNumber'
															rules={{ required: true }}
															control={control}
															render={({ field }) => (
																<TextField
																	focused
																	label='Clinic Phone Number'
																	{...field}
																/>
															)}
														/>

														<Controller
															name='location'
															rules={{ required: true }}
															control={control}
															render={({ field }) => (
																<GooglePlacesAutocomplete
																	selectProps={{
																		...field,
																		// value: getProviderDetails?.location || '',
																		// onChange: (value) => {
																		// 	handlePlaceSelect(value);
																		// },
																	}}
																	apiKey={process.env.REACT_APP_API_KEY}
																/>
															)}
														/>
													</Stack>
												</Grid>
												<Grid
													style={{ cursor: 'pointer' }}
													item
													xs={6}
													display={'flex'}
													justifyContent={'flex-end'}
												>
													<Controller
														name='userImage'
														rules={{ required: true }}
														control={control}
														render={({ field }) => (
															<Dropzone {...field} onDrop={handleDrop}>
																{({
																	getRootProps,
																	getInputProps,
																	isDragActive,
																}) => (
																	<section>
																		<div
																			className={classes.dropzone}
																			{...getRootProps()}
																			style={
																				isDragActive
																					? {
																						borderColor: '#2196f3',
																						backgroundColor: '#e3f2fd',
																						height: 'auto',
																					}
																					: {}
																			}
																		>
																			<input {...getInputProps()} />
																			<Box>
																				<Box
																					sx={{
																						display: 'flex',
																						justifyContent: 'center',
																					}}
																				>
																					<img src={InfoDropZone} alt='' />
																				</Box>
																				<Box
																					sx={{
																						width: '100px',
																						height: '100px',
																						display: 'flex',
																						justifyContent: 'center',
																						alignItems: 'center',
																					}}
																				>
																					+User Image
																				</Box>
																			</Box>
																			{userprofile?.name}
																		</div>
																	</section>
																)}
															</Dropzone>
														)}
													/>
												</Grid>
											</Grid>
										</>
									)}

									{info === 'Info' && (
										<>
											<Box
												sx={{
													display: 'flex',
													width: '300px',
													marginLeft: '30px',
													backgroundColor: '#fff',
													marginBottom: '15px',
												}}
											>
												{/* <TextField
													fullWidth
													disabled
													multiline
													placeholder='Notes:'
													name='description'
													rows={4}
													value={timeStampsNotes}
													onChange={notesCreation}
													style={{ fontSize: '10px', color: '#6E757C' }}
												/> */}
											</Box>
										</>
									)}
									{/* <input style={{ marginLeft: '30px' }} type='submit' /> */}
								</form>

								<Grid container padding={8} columnSpacing={8}>
									{info === 'upload' && (
										<Grid item xs={12} lg={5}>
											<AddServiceForm
												onFormSubmit={data => {
													// console.log(data);
													const prevCopy = [...localServices];
													prevCopy.push({
														service_name: data.service.name,
														service_id: data.service.id,
														specialization: data.specialties.name,
														specialization_id: data.specialties.id,
														price: data.price,
														description: data.description,
														profile_image: data.profile_image,
													});
													setLocalServices([...prevCopy]);
												}}
											/>
										</Grid>
									)}

									{info === 'upload' && (
										<Grid
											display={'flex'}
											justifyContent={'flex-end'}
											item
											xs={12}
											lg={7}
										>
											<Box>
												<Box
													className={`${classes.isLargeScreen} scrollTest`}
													style={{ padding: '10px' }}
												>
													{!(Object.keys(consultationObj).length === 1) ? (
														<>
															<span
																style={{
																	textAlign: 'center',
																	fontWeight: 800,
																	fontSize: '16px',
																}}
															>
																Consultation
															</span>
															<Box
																className={classes.rightServices}
																style={{
																	marginLeft: '22px',
																}}
															>
																<Box style={{ height: 'auto' }}>
																	<Box
																		style={{
																			display: 'flex',
																			justifyContent: 'space-between',
																			gap: '14px',
																			boxSizing: 'border-box',
																			overflow: 'auto',
																			height: 'auto',
																		}}
																	>
																		<Box style={{ marginTop: '37px' }}>
																			<span
																				style={{
																					fontSize: '16px',
																					fontWeight: '700',
																				}}
																			>
																				{localConsultation?.consultation_name}
																			</span>
																		</Box>
																		<Box style={{ marginTop: '37px' }}>
																			<span
																				style={{
																					fontSize: '16px',
																					fontWeight: '400',
																				}}
																			>
																				${localConsultation?.consultation_price}
																			</span>
																		</Box>
																		<Box
																			style={{
																				marginTop: '37px',
																				width: '5vw',
																				height: 'auto',
																			}}
																		>
																			<span
																				style={{
																					fontSize: '16px',
																					fontWeight: '400',
																				}}
																			>
																				{localConsultation?.description}
																			</span>
																		</Box>
																		<Box
																			style={{
																				display: 'flex',
																				gap: '25px',
																				marginTop: '21px',
																			}}
																		>
																			<Box style={{ display: 'grid' }}>
																				<Box
																					style={{
																						marginBottom: '52px',
																						marginLeft: '-11px',
																					}}
																				>
																					<span
																						style={{
																							fontSize: '14px',
																						}}
																					>
																						{/* Delete */}
																					</span>
																				</Box>
																			</Box>
																			<Box style={{ display: 'grid' }}>
																				<Box
																				// onClick={() => handleOpenConsultansy() }
																				>
																					<img
																						onClick={handleConsultationEdit}
																						style={{
																							width: '24px',
																							height: '24px',
																						}}
																						src={ServiceEdit}
																						alt=''
																					/>
																				</Box>
																				<Box
																					style={{
																						marginBottom: '52px',
																					}}
																				>
																					<span
																						style={{
																							fontSize: '14px',
																						}}
																					>
																						{/* Edit */}
																					</span>
																				</Box>
																			</Box>
																		</Box>
																	</Box>
																</Box>
																<Divider />
															</Box>
															<br />
															<Divider />
														</>
													) : (
														<>
															<button
																style={{
																	...submitContactButton,
																	textAlign: 'center',
																	margin: 'auto',
																	width: '460px',
																	backgroundColor: 'rgb(222, 222, 222)',
																	color: 'black',
																	borderRadius: '12px',
																}}
															//   onClick={() => setOpen(true)}
															>
																Add Consultation
															</button>
															<br />
														</>
													)}
													{localServices?.map((row, index) => (
														<Box
															className={classes.rightServices}
															style={{ width: 'auto' }}
														>
															<Box key={row.id}>
																<Box
																	style={{
																		display: 'flex',
																		justifyContent: 'space-between',
																		textAlign: 'center',
																		flex: 1,
																		gap: '12px',
																	}}
																>
																	{/* <Box>
																		<img
																			style={{
																				width: '128',
																				height: '98px',
																			}}
																			src={
																				row?.image
																					? 'https://api.healthcoinxchangeportal.com/' + row?.image
																					: row?.profile_image
																					? URL.createObjectURL(
																							row?.profile_image
																					  )
																					: ''
																			}
																			alt='no image'
																		/>
																	</Box> */}
																	<Box style={{ marginTop: '37px' }}>
																		<span
																			style={{
																				fontSize: '16px',
																				fontWeight: '700',
																			}}
																		>
																			{row?.service_name}
																		</span>
																	</Box>
																	<Box style={{ marginTop: '37px' }}>
																		<span
																			style={{
																				fontSize: '16px',
																				fontWeight: '700',
																			}}
																		>
																			{row?.specialization}
																		</span>
																	</Box>
																	<Box style={{ marginTop: '37px' }}>
																		<span
																			style={{
																				fontSize: '16px',
																				fontWeight: '400',
																			}}
																		>
																			${row?.price}
																		</span>
																	</Box>
																	<Box
																		style={{
																			display: 'flex',
																			gap: '25px',
																			marginTop: '35px',
																		}}
																	>
																		<Box style={{ display: 'grid' }}>
																			<Box
																				onClick={() => {
																					const prevCopy = [...localServices];
																					prevCopy.splice(index, 1);
																					setLocalServices([...prevCopy]);
																				}}
																			>
																				<img
																					style={{
																						width: '21px',
																						height: '24px',
																					}}
																					src={DeleteServices}
																					alt=''
																				/>
																			</Box>
																			<Box
																				style={{
																					marginBottom: '52px',
																					marginLeft: '-11px',
																				}}
																			>
																				<span
																					style={{
																						fontSize: '14px',
																					}}
																				>
																					{row.delete}
																				</span>
																			</Box>
																		</Box>
																		<Box style={{ display: 'grid' }}>
																			<Box
																			//   onClick={() => editModelOpen(row)}
																			>
																				<img
																					onClick={e =>
																						handleServiceEdit(e, index, row)
																					}
																					style={{
																						width: '24px',
																						height: '24px',
																					}}
																					src={ServiceEdit}
																					alt=''
																				/>
																			</Box>
																			<Box
																				style={{
																					marginBottom: '52px',
																				}}
																			>
																				<span
																					style={{
																						fontSize: '14px',
																					}}
																				>
																					{row.edit}
																				</span>
																			</Box>
																		</Box>
																	</Box>
																</Box>
															</Box>
														</Box>
													))}
													<br />
												</Box>
												{/* <Box
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														marginTop: '25px',
													}}>
													<button
														style={{
															width: '325px',
															height: '50px',
															background: 'white',
															color: '#4157FF',
															border: '1px solid #4157FF',
															fontSize: '16px',
															fontWeight: '700',
															borderRadius: '5px',
															cursor: 'pointer',
														}}
														  onClick={() => addService()}
													>
														+Add Servicesd s
													</button>
												</Box> */}
											</Box>
										</Grid>
									)}
								</Grid>
							</div>
						</div>
						<br />
					</div>
					<Toaster />
					<Dialog
						onClose={() => setServicdEditDialog(false)}
						open={serviceEditDialog}
					>
						<DialogTitle>Edit Service</DialogTitle>
						<Box sx={{ padding: '40px' }}>
							<AddServiceForm
								onFormSubmit={data1 => {
									const data = data1;
									setServicdEditDialog(false);
									const lsc = [...localServices];
									if (!data.profile_image) {
										data.image = serviceEditData.image;
									}
									lsc[serviceEditData.indexS] = data;
									lsc[serviceEditData.indexS].service_id = data.service.id;
									lsc[serviceEditData.indexS].id = data.id;
									lsc[serviceEditData.indexS].service_name = data.service.name;
									lsc[serviceEditData.indexS].specialization =
										data.specialties.name;
									lsc[serviceEditData.indexS].specialization_id =
										data.specialties.id;

									setLocalServices([...lsc]);
								}}
								mode={1}
								data={serviceEditData}
							/>
						</Box>
					</Dialog>

					<Dialog
						onClose={() => setConsultatondEditDialog(false)}
						open={consultatonEditDialog}
					>
						<DialogTitle>Edit Consultation</DialogTitle>
						<DialogContent>
							{/* <Box sx={{ padding: '40px' }}> */}
							<AddConsultaionForm
								onFormSubmit={data => {
									setLocalConsultation(data);
									setConsultatondEditDialog(false);
								}}
								mode={1}
								data={localConsultation}
							/>
							{/* </Box> */}
						</DialogContent>
					</Dialog>
				</div>
			)}
		</>
	);
};

export default NetworkProviderDetails;
