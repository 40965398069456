import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Divider,
	Box,
} from '@mui/material';
import TranscationHealthcoinLogo from '../../asset/image/TranscationHealthcoinLogo.svg';
import { toast, Toaster } from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const useStyles = makeStyles({
	transactionHeading: {
		fontSize: '13px',
		fontWeight: '600',
	},
	transactionNumber: {
		fontSize: '12px',
		fontWeight: '700',
		opacity: '.7',
		textTransform: 'uppercase',
	},
	reqNumber: {
		fontSize: '11px',
		fontWeight: '600',
	},
	profileData: {},
	dateTransaction: {
		textTransform: 'uppercase',
		fontSize: '14px',
		fontWeight: '700',

		opacity: '.5',
	},
	dateTra: {
		fontSize: '14px',
		color: 'black',
		fontWeight: '500',
	},
});

const TransactionRequest = () => {
	const sendPayment = {
		width: '153px',
		height: '40px',
		color: 'white',
		background: '#4157FF',
		borderRadius: '21px',
		fontSize: '14px',
		border: 'none',
		cursor: 'pointer',
		fontWeight: '700',
	};
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	// download the Pdf
	const handleDownloadPDF = () => {
		const input = document.getElementById('pdf-content');

		const options = {
			dpi: 300, // Adjust the DPI value (e.g., 150, 300, etc.)
			scale: 2, // Adjust the scale value to increase the resolution
			useCORS: true, // Enable cross-origin resource sharing if needed
			allowTaint: true, // Allow tainting of the canvas (may be required if your content contains images from other domains)
		};

		html2canvas(input, options).then(canvas => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF('p', 'pt', 'a4'); // Set the document size and orientation as per your requirements
			const imgProps = pdf.getImageProperties(imgData);
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

			pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
			pdf.save('Transaction-History.pdf');
		});
	};

	return (
		<div>
			<button style={sendPayment} onClick={handleOpen}>
				View PDF
			</button>
			<Dialog open={open} onClose={handleClose} maxWidth='xl'>
				<div id='pdf-content'>
					<DialogContent
						sx={{ marginTop: '10px' }}
						style={{ width: '1102px', height: '828px' }}
					>
						<Box sx={{ margin: '22px' }}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Box>
									<img src={TranscationHealthcoinLogo} alt='' />
								</Box>

								<Box sx={{ display: 'grid' }}>
									<span
										style={{
											fontSize: '12px',
											fontWeight: '700',
											opacity: '.7',
										}}
									>
										TRANSACTION NUMBER
									</span>
									<span
										style={{
											fontSize: '24px',
											fontWeight: '700',
											marginLeft: '65px',
										}}
									>
										#20201
									</span>
								</Box>
							</Box>

							<Box sx={{ display: 'flex', gap: '8px', marginTop: '30px' }}>
								<Box sx={{ display: 'flex' }}>
									<span
										style={{
											fontSize: '14px',
											fontWeight: '600',
											color: 'grey',
										}}
									>
										FROM
									</span>
								</Box>
								<Box sx={{ marginTop: '10px' }}>
									<Divider style={{ width: '28vw' }} />
								</Box>
								<Box>
									<div style={{ display: 'flex', gap: '10px' }}>
										<span
											style={{
												fontSize: '14px',
												fontWeight: '600',
												color: 'grey',
											}}
										>
											BILL
										</span>
										<span
											style={{
												fontSize: '14px',
												fontWeight: '600',
												color: 'grey',
											}}
										>
											TO
										</span>
									</div>
								</Box>
								<Box sx={{ marginTop: '10px' }}>
									<Divider style={{ width: '27vw' }} />
								</Box>
							</Box>

							<Box sx={{ display: 'flex', gap: '179px' }}>
								{/*left side of healthcoin exchange */}
								<Box sx={{ margin: '20px 54px' }}>
									<Box sx={{ display: 'grid' }}>
										<span className={classes.transactionHeading}>
											Healthcoin Exchange
										</span>
										<span className={classes.transactionHeading}>
											8 W. Blue Spring St.
										</span>
										<span className={classes.transactionHeading}>
											New York, NY 10128
										</span>
										<span className={classes.transactionHeading}>
											United States
										</span>

										<Box
											sx={{ marginTop: '10px', display: 'flex', gap: '25px' }}
										>
											<Box>
												<Box>
													<span className={classes.transactionNumber}>
														Company Number
													</span>
												</Box>

												<Box
													sx={{
														display: 'grid',
														marginTop: '15px',
														gap: '10px',
													}}
												>
													<span className={classes.transactionNumber}>
														VAT NUMBER
													</span>
													<span className={classes.transactionNumber}>
														Contact
													</span>
												</Box>
											</Box>

											<Box>
												<Box sx={{ display: 'grid' }}>
													<Box sx={{ display: 'grid' }}>
														<span className={classes.reqNumber}>73649283</span>
														<span className={classes.reqNumber}>
															GB938572048
														</span>
													</Box>
													<Box sx={{ display: 'grid' }}>
														<Box sx={{ display: 'grid', marginTop: '3px' }}>
															<span className={classes.reqNumber}>
																John Doe
															</span>
															<span className={classes.reqNumber}>
																john.doe@healthcoin.app
															</span>
														</Box>

														<Box sx={{ marginTop: '13px' }}>
															<span className={classes.reqNumber}>
																The supply is tax exempt.
															</span>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>

								{/* right side of healthcoin exchange */}
								<Box sx={{ marginTop: '19px' }}>
									<Box sx={{ display: 'grid' }}>
										<span className={classes.transactionHeading}>
											Dr. Jane Smith
										</span>
										<span className={classes.transactionHeading}>
											8 W. Blue Spring St.{' '}
										</span>
										<span className={classes.transactionHeading}>
											New York, NY 10128
										</span>
										<span className={classes.transactionHeading}>
											United States
										</span>

										<Box
											sx={{ marginTop: '10px', display: 'flex', gap: '25px' }}
										>
											<Box>
												<Box sx={{ display: 'grid' }}>
													<span className={classes.transactionNumber}>
														Tax ID
													</span>
													<span className={classes.transactionNumber}>ID</span>
													<span className={classes.transactionNumber}>
														BANK
													</span>
													<span className={classes.transactionNumber}>
														Payment form
													</span>
													<span className={classes.transactionNumber}>
														IBAN
													</span>
													<span className={classes.transactionNumber}>
														SWIFT
													</span>
													<span className={classes.transactionNumber}>
														Constant symbol
													</span>
												</Box>
											</Box>

											<Box>
												<Box sx={{ display: 'grid' }}>
													<Box sx={{ display: 'grid' }}>
														<span className={classes.reqNumber}>83950683</span>
														<span className={classes.reqNumber}>
															83749502873{' '}
														</span>
														<span className={classes.reqNumber}>
															Revolut Bank
														</span>
														<span className={classes.reqNumber}>
															Bank transfer
														</span>
														<span className={classes.reqNumber}>
															UK4242000000004242424242{' '}
														</span>
														<span className={classes.reqNumber}>
															REVOLUTUKBX
														</span>
														<span className={classes.reqNumber}>6868</span>
														<span className={classes.reqNumber}>
															{' '}
															Not VAT registered
														</span>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
							{/* description and amount divider line */}
							<Box sx={{ display: 'flex', gap: '8px', marginTop: '30px' }}>
								<Box sx={{ display: 'flex' }}>
									<span
										style={{
											fontSize: '14px',
											fontWeight: '600',
											color: 'grey',
											textTransform: 'uppercase',
										}}
									>
										Description
									</span>
								</Box>
								<Box sx={{ width: '82vw', marginTop: '10px' }}>
									<Divider />
								</Box>
								<Box>
									<span
										style={{
											fontSize: '14px',
											fontWeight: '600',
											color: 'grey',
											textTransform: 'uppercase',
										}}
									>
										Amount
									</span>
								</Box>
							</Box>

							{/* below description and amount */}

							<Box
								sx={{
									marginTop: '10px',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Box sx={{ display: 'grid', gap: '2px' }}>
									<Box>
										<span className={classes.transactionHeading}>
											Procedure No.
										</span>
										<span className={classes.transactionHeading}>
											123456789werty23456{' '}
										</span>
									</Box>

									<Box>
										<span className={classes.transactionHeading}>
											Consultation for MAria Minigrova HC ID no.
										</span>
										<span className={classes.transactionHeading}>
											HC1234567890
										</span>
									</Box>
									<span className={classes.transactionHeading}>
										Consultation \ Stitches \ Woundcare
									</span>
								</Box>

								<Box sx={{ marginTop: '17px' }}>
									<span style={{ fontSize: '20px', fontWeight: '500' }}>
										$850
									</span>
								</Box>
							</Box>

							{/* total price divider line */}
							<Box sx={{ display: 'flex', gap: '8px', marginTop: '30px' }}>
								<Box sx={{ marginTop: '10px' }}>
									<Divider style={{ width: '56vw' }} />
								</Box>
								<Box>
									<div style={{ display: 'flex', gap: '7px' }}>
										<span
											style={{
												fontSize: '14px',
												fontWeight: '600',
												color: 'grey',
												textTransform: 'uppercase',
											}}
										>
											Total
										</span>
										<span
											style={{
												fontSize: '14px',
												fontWeight: '600',
												color: 'grey',
												textTransform: 'uppercase',
											}}
										>
											{' '}
											Price
										</span>
									</div>
								</Box>
							</Box>

							{/* Date */}

							<Box sx={{ display: 'flex', gap: '30px', marginTop: '10px' }}>
								<Box sx={{ display: 'flex', gap: '5px' }}>
									<span className={classes.dateTransaction}>Inovice date</span>
									<span className={classes.dateTra}>31.01.2022</span>
								</Box>

								<Box sx={{ display: 'flex', gap: '5px' }}>
									<span className={classes.dateTransaction}>
										procedure date
									</span>
									<span className={classes.dateTra}>31.01.2022</span>
								</Box>

								<Box sx={{ display: 'flex', gap: '5px' }}>
									<span className={classes.dateTransaction}> Payment paid</span>
									<span className={classes.dateTra}>15.02.2022</span>
									<span className={classes.dateTra}>$850</span>
								</Box>
							</Box>

							{/* Bank detail, bank account number,variable symbol */}

							<Box
								sx={{
									width: '1000px',
									marginTop: '40px',
									height: '97px',
									border: '2px solid black',
									borderRadius: '15px',
								}}
							>
								<Box sx={{ marginTop: '20px', marginLeft: '40px' }}>
									<Box sx={{ display: 'flex', gap: '170px' }}>
										<Box sx={{ display: 'grid' }}>
											<Box sx={{ marginLeft: '35px' }}>
												<span
													style={{
														fontSize: '12px',
														fontWeight: '700',
														textTransform: 'uppercase',
														opacity: '0.5',
													}}
												>
													Bank account number / IBAN
												</span>
											</Box>
											<Box>
												<span style={{ fontSize: '20px', fontWeight: '700' }}>
													US4242000000004242424242
												</span>
											</Box>
										</Box>

										<Box sx={{ display: 'grid' }}>
											<Box>
												<span
													style={{
														fontSize: '12px',
														textTransform: 'uppercase',
														fontWeight: '700',
														opacity: '0.5',
													}}
												>
													Variable symbol
												</span>
											</Box>
											<Box sx={{ marginLeft: '26px' }}>
												<span style={{ fontSize: '20px', fontWeight: '700' }}>
													20201
												</span>
											</Box>
										</Box>

										<Box sx={{ display: 'grid', marginLeft: '0px' }}>
											<Box sx={{ display: 'flex', gap: '5px' }}>
												<span
													style={{
														fontSize: '12px',
														textTransform: 'uppercase',
														fontWeight: '700',
														opacity: '0.5',
													}}
												>
													Total{' '}
												</span>
												<span
													style={{
														fontSize: '12px',
														textTransform: 'uppercase',
														fontWeight: '700',
														opacity: '0.5',
													}}
												>
													amount to pay{' '}
												</span>
											</Box>
											<Box sx={{ marginLeft: '40px' }}>
												<span style={{ fontSize: '20px', fontWeight: '700' }}>
													$850
												</span>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</DialogContent>
				</div>
				<DialogActions
					sx={{
						display: 'flex',
						justifyContent: 'end',
						margin: '0px 0px 36px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							marginTop: '0px',
							justifyContent: 'flex-end',
							marginRight: '43px',
						}}
					>
						<button
							style={{
								width: '155px',
								height: '45px',
								color: 'white',
								background: '#4157FF',
								borderRadius: '21px',
								fontSize: '14px',
								border: 'none',
								cursor: 'pointer',
								fontWeight: '700',
							}}
							onClick={handleDownloadPDF}
						>
							Download as PDF
						</button>
					</Box>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default TransactionRequest;
