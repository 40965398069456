import moment from 'moment';

const convertToLocalTime = utcTimeString => {
	const localTime = moment.utc(utcTimeString).local();
	const dateFormat = localTime.format('YYYY-MM-DD HH:mm:ss');
	return dateFormat;
};
const convertLocalTimeToUTC = () => {
	const localTime = moment();
	// Convert local time to UTC time
	const utcTime = localTime.utc();
	const formattedUtcDateTime = utcTime.format('YYYY-MM-DD HH:mm:ss');
	return formattedUtcDateTime;
};

export { convertToLocalTime, convertLocalTimeToUTC };
