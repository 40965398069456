import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Box,
} from '@mui/material';
import { toast, Toaster } from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import TransactionRequest from './TransactionRequest';
import { server } from '../../redux/store';
import { getAllPaymentRequestDetail } from '../../redux/action/user';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
	encryptData,
	decryptData,
} from '../../redux/incript&decript/cryptoUtils';

const SendPaymentContact = ({
	hc_bill,
	id,
	payment_request_status,
	adjustment_status,
}) => {
	const { user, PaymentRequestDetail } = useSelector(state => state.user);

	const [open, setOpen] = useState(false);
	const [submitPayment, setSubmitPayment] = useState(null);
	const dispatch = useDispatch();
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleSubmit = () => {
		alert('false');
	};
	const submitHandler = async e => {
		// e.preventDefault();
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// console.log(token, "token");
			const { data } = await axios.post(
				`${server}/AdminPayoutToProvider`,
				{
					payment_id: id,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: token,
					},
				}
			);

			const decryptedData = decryptData(data.encrypted_data);
			toast.success(decryptedData?.message);
			setOpen(false);
			setSubmitPayment(decryptedData?.data);
			dispatch(getAllPaymentRequestDetail(id));
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			// console.log(err);
			toast.error(err?.data?.message);
			toast.error(decryptedErrorMessage?.message);
		}
	};

	return (
		<div>
			{!(
				payment_request_status === '4' ||
				payment_request_status === '2' ||
				payment_request_status === '1' ||
				PaymentRequestDetail?.payments?.adjustment_status == '1' ||
				adjustment_status == '1'
			) && (
				<button
					style={{
						width: '155px',
						height: '47px',
						color: 'white',
						background: '#4157FF',
						borderRadius: '21px',
						fontSize: '14px',
						border: 'none',
						cursor: 'pointer',
						fontWeight: '700',
						backgroundColor:
							submitPayment?.payment_request_status === '4'
								? '#ddd'
								: '#4157FF',
					}}
					onClick={handleOpen}
					disabled={
						submitPayment?.payment_request_status === '4' ||
						submitPayment?.payment_request_status === '1' ||
						submitPayment?.payment_request_status === '2' ||
						PaymentRequestDetail?.payments?.adjustment_status == '1'
					}
				>
					Send Payment
				</button>
			)}

			{/* <Form > */}
			<Dialog open={open} onClose={handleClose} maxWidth='md'>
				{' '}
				{/* maxWidth="sm" ,md,lg */}
				<DialogTitle>
					<span
						style={{
							fontSize: '24px ',
							fontWeight: '500 ',
							fontFamily: 'DM Sans',
							color: '#32325D',
						}}
					>
						Make Payment
					</span>
					<IconButton
						onClick={handleClose}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px ',
								boxShadow: 'none ',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{ marginTop: '10px' }}
					style={{ minWidth: '550px', height: 'auto' }}
				>
					<Box sx={{ display: 'flex', gap: '22px', flexDirection: 'column' }}>
						<Box>
							<Typography
								variant='h6'
								sx={{
									fontSize: '14px ',
									fontWeight: '700',
									color: '#525F7F',
								}}
								gutterBottom
							>
								Total Due
							</Typography>
							<TextField
								disabled
								margin='dense'
								// onChange={handleChange}
								value={hc_bill}
								fullWidth
								InputProps={{
									readOnly: true,
									style: { color: 'black' },
								}}
								sx={{
									boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
									width: '489px',
								}}
							/>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						justifyContent: 'center',
						margin: '0px 0px 36px',
					}}
				>
					{/* <TransactionRequest/> */}
					<Button
						onClick={() => submitHandler()}
						style={{
							height: '46px ',
							width: '169px ',
							borderRadius: '24px ',
							background: '#4157FF ',
							textTransform: 'none',
							fontSize: '15px ',
							fontWeight: 700,
						}}
						variant='contained'
						color='primary'
					>
						Submit Payment
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SendPaymentContact;
