import healthcoinlogo from '../../asset/image/employerlogo.png';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AddEmployerAction } from '../../redux/action/user';
import './model.css';
import axios from 'axios';
import {
	encryptData,
	decryptData,
} from '../../redux/incript&decript/cryptoUtils';
import { server } from '../../redux/store';
const AddNewEmployeeModel = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const initialValues = {
		first_name: '',
		company: '',
		company_address: '',
		email: '',
		phone: '',
		// user_type: "enterprise"
	};

	const handleButtonClick = (setFieldValue, submitForm, errors) => {
		setFieldValue('published');
		submitForm();
		// console.log("published123", errors);
	};
	const validationSchema = Yup.object().shape({
		first_name: Yup.string()
			.required('*name is required')
			.min(3, '*name must be at least 3 characters'),
		company: Yup.string()
			.required('*company name is required')
			.min(3, '*company name must be at least 3 characters'),
		company_address: Yup.string().required('*company address is required'),
		email: Yup.string()
			.required('*email is required')
			.matches(
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				'*Please enter a valid email address'
			),
		phone: Yup.string()
			.required('*phone is required')
			.matches(
				/^(\+?1[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
				'invalid phone number. please enter a valid USA phone number.'
			),
	});
	// const formSubmit = (values) => {
	//   console.log("vaueeeeeeeeeeeeeeeeeeee", values);
	//   dispatch(AddEmployerAction(values));
	//   navigate('/employers')

	// };
	const formSubmit = async values => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			dispatch({
				type: 'addEmployerRequest',
			});

			const { first_name, company, company_address, email, phone } = values; // Destructure values to access the required variables
			const encrypted_data = encryptData({
				first_name,
				company_name: company,
				company_address,
				email,
				phone_number: phone,
			});
			let { data } = await axios.post(
				`${server}/enterprise_registration_by_admin`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			dispatch({
				type: 'addEmployerSuccess',
				message: decryptedData?.message,
			});
			navigate('/employers');
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			dispatch({
				type: 'addEmployerFail',
				payload: decryptedErrorMessage.message,
			});
		}
	};

	const submitHandler = e => {
		e.preventDefault();
	};

	return (
		<div className='emp_main' style={{ minHeight: '120vh' }}>
			<br />
			<div className='emp_container1' style={{ marginTop: '1%' }}>
				<div>
					<br />
					{/* <img src={Logo} alt="logo" /> */}
				</div>
				<div>
					<img src={healthcoinlogo} alt='healthcoinlogo' width='400px' />
				</div>
				<br />
				<div className='emp_form'>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting, resetForm }) => {
							// console.log("Values About fee", values);
							formSubmit(values, setSubmitting, resetForm);
						}}
					>
						{({
							errors,
							touched,
							values,
							handleChange,
							setFieldValue,
							submitForm,
						}) => (
							<Form onSubmit={submitHandler}>
								<div className='child'>
									<TextField
										// label="With normal TextField"
										className='mailField'
										onChange={handleChange}
										value={values.first_name}
										inputProps={{ maxLength: 17 }}
										name='first_name'
										placeholder='First & Last Name (Person Managing Acct)* '
										id='outlined-start-adornment'
										sx={{ m: 1, width: '25ch' }}
										style={{ width: '100%' }}
									/>
									{errors.first_name && touched.first_name ? (
										<div className={`red mb-1 fs15`}>{errors.first_name}</div>
									) : null}
									<TextField
										// label="With normal TextField"
										className='mailField'
										placeholder='Company Name*'
										inputProps={{ maxLength: 17 }}
										id='outlined-start-adornment'
										sx={{ m: 1, width: '25ch' }}
										style={{ width: '100%' }}
										name='company'
										onChange={handleChange}
										value={values.company}
									/>
									{errors.company && touched.company ? (
										<div className={`red mb-1 fs15`}>{errors.company}</div>
									) : null}
									<TextField
										// label="With normal TextField"
										className='mailField'
										placeholder='Company Address*'
										inputProps={{ maxLength: 25 }}
										id='outlined-start-adornment'
										sx={{ m: 1, width: '25ch' }}
										style={{ width: '100%' }}
										name='company_address'
										onChange={handleChange}
										value={values.company_address}
									/>
									{errors.company_address && touched.company_address ? (
										<div className={`red mb-1 fs15`}>
											{errors.company_address}
										</div>
									) : null}
									<TextField
										// label="With normal TextField"
										className='mailField'
										placeholder='Email Address*'
										id='outlined-start-adornment'
										sx={{ m: 1, width: '25ch' }}
										style={{ width: '100%' }}
										name='email'
										onChange={handleChange}
										value={values.email}
									/>
									{errors.email && touched.email ? (
										<div className={`red mb-1 fs15`}>{errors.email}</div>
									) : null}
									<TextField
										// label="With normal TextField"
										className='mailField'
										placeholder='Phone Number* '
										id='outlined-start-adornment'
										sx={{ m: 1, width: '100%' }}
										inputProps={{ maxLength: 15 }}
										name='phone'
										onChange={handleChange}
										value={values.phone}
									/>
									{errors.phone && touched.phone ? (
										<div className={`red mb-1 fs15`}>{errors.phone}</div>
									) : null}

									<div className='emp_btn-group'>
										<button
											className='emp_btn'
											onClick={() =>
												handleButtonClick(setFieldValue, submitForm, errors)
											}
										>
											SUBMIT
										</button>
									</div>
									<br />
									<br />
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
		// <div className="emp_main" style={{ minHeight: "100vh" }}>
		//   <br />
		//   <div className="emp_container1">
		//     <div>
		//       <br />
		//       {/* <img src={Logo} alt="logo" /> */}
		//     </div>
		//     <div>
		//       <img src={healthcoinlogo} alt="healthcoinlogo" width="400px" />
		//     </div>
		//     <br />
		//     <div className="emp_form">
		//       <Formik
		//         initialValues={initialValues}
		//         validationSchema={validationSchema}
		//         onSubmit={(values, { setSubmitting, resetForm }) => {
		//           console.log("Values About fee", values);
		//           formSubmit(values, setSubmitting, resetForm);
		//         }}
		//       >
		//         {({
		//           errors,
		//           touched,
		//           values,
		//           handleChange,
		//           setFieldValue,
		//           submitForm,
		//         }) => (
		//           <Form onSubmit={submitHandler}>
		//             <div className="child">
		//               <TextField
		//                 // label="With normal TextField"
		//                 className="mailField"
		//                 onChange={handleChange}
		//                 value={values.first_name}
		//                 name="first_name"
		//                 inputProps={{ maxLength: 40 }}
		//                 placeholder="First & Last Name (Person Managing Acct)* "
		//                 id="outlined-start-adornment"
		//                 sx={{ m: 1, width: "100%" }}

		//               />
		//               {errors.first_name && touched.first_name ? (
		//                 <div className={`red mb-1 fs15`}>{errors.first_name}</div>
		//               ) : null}
		//               <TextField
		//                 // label="With normal TextField"
		//                 className="mailField"
		//                 placeholder="Company Name*"
		//                 inputProps={{ maxLength: 50 }}
		//                 id="outlined-start-adornment"
		//                 sx={{ m: 1, width: "100%" }}

		//                 name="company"
		//                 onChange={handleChange}
		//                 value={values.company}
		//               />
		//               {errors.company && touched.company ? (
		//                 <div className={`red mb-1 fs15`}>{errors.company}</div>
		//               ) : null}
		//               <TextField
		//                 // label="With normal TextField"
		//                 className="mailField"
		//                 placeholder="Company Address*"
		//                inputProps={{ maxLength: 50 }}
		//                 id="outlined-start-adornment"

		//                 sx={{ m: 1, width: "100%" }}
		//                 name="company_address"
		//                 onChange={handleChange}
		//                 value={values.company_address}
		//               />
		//               {errors.company_address && touched.company_address ? (
		//                 <div className={`red mb-1 fs15`}>
		//                   {errors.company_address}
		//                 </div>
		//               ) : null}
		//               <TextField
		//                 // label="With normal TextField"
		//                 className="mailField"
		//                 placeholder="Email Address*"
		//                 id="outlined-start-adornment"
		//                 sx={{ m: 1, width: "100%" }}
		//                 // inputProps={{ maxLength: 22 }}

		//                 name="email"
		//                 onChange={handleChange}
		//                 value={values.email}
		//               />
		//               {errors.email && touched.email ? (
		//                 <div className={`red mb-1 fs15`}>{errors.email}</div>
		//               ) : null}
		//               <TextField
		//                 // label="With normal TextField"
		//                 className="mailField"
		//                 placeholder="Phone Number* "
		//                 id="outlined-start-adornment"
		//                 sx={{ m: 1, width: "100%" }}
		//                 inputProps={{ maxLength: 15 }}

		//                 name="phone"
		//                 onChange={handleChange}
		//                 value={values.phone}
		//               />
		//               {errors.phone && touched.phone ? (
		//                 <div className={`red mb-1 fs15`}>{errors.phone}</div>
		//               ) : null}

		//               <div style={{width:"100%"}}>
		//                 <button
		//                   className="emp_btnAddNewEmployer"
		//                   onClick={() =>
		//                     handleButtonClick(setFieldValue, submitForm, errors)
		//                   }
		//                 >
		//                   SUBMIT
		//                 </button>
		//               </div>
		//               <br />
		//               <br />
		//             </div>
		//           </Form>
		//         )}
		//       </Formik>
		//     </div>
		//   </div>
		// </div>
	);
};
export default AddNewEmployeeModel;
