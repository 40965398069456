import { Avatar } from '@mui/material';
import React, { useState, useEffect } from 'react';
import './CompanyDetails.css';
import { deepOrange } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { EnterpriseById, sendEmailToEnterprise } from '../../redux/action/user';
import avatar from '../../asset/image/avatar.svg';
import { toast, Toaster } from 'react-hot-toast';
import { server } from '../../redux/store';
import axios from 'axios';
const CompanyDetails = ({ companyData, handleSendNote }) => {
	// const [companyData, setCompanyData] = useState(null);
	const dispatch = useDispatch();
	const id = companyData?.id;

	const handleSendEmailClick = id => {
		dispatch(sendEmailToEnterprise(id));
	};

	// const sendEmailToEnterprise = async (id) => {
	//   try {
	//     const token = `Bearer ${localStorage.getItem("token")}`;
	//     const { data } = await axios.post(
	//       `${server}/admin_send_email_to_enterprise`,
	//       {id},
	//       {
	//         headers: {
	//           "Content-Type": "application/json",
	//           Authorization: token,
	//         },
	//       }
	//     );
	//     toast.success(data.message);

	//     // After successful deletion, refresh the list of members
	//     // await allMembers();
	//   } catch (err) {
	//     console.log(err);
	//   }
	// };

	return (
		<>
			<div className='header'>
				<div className='main1234'>
					<div className='child-right'>
						<div>
							<img
								src={
									companyData?.profile_image
										? 'https://api.healthcoinxchangeportal.com/' +
										companyData?.profile_image
										: avatar
								}
								alt=''
								style={{
									borderRadius: '50px',
									width: '60px',
									height: '60px',
								}}
							/>
							{/* <Avatar sx={{ width: 60, height: 60, bgcolor: deepOrange[500] }} /> */}
						</div>{' '}
						&nbsp;&nbsp;&nbsp;
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								boxSizing: 'border-box',
							}}
						>
							<span style={{ fontWeight: '700' }}>{companyData?.company}</span>
							<span style={{ color: '#6E757C', fontSize: '14px' }}>
								{companyData?.email}
							</span>
							<span style={{ color: '#6E757C', fontSize: '14px' }}>
								HC ID:{companyData?.healthcoin_id}
							</span>
						</div>
					</div>
					{/* {show && */}
					<div style={{ display: 'flex' }}>
						<div
							className='child-right'
							style={{ marginTop: '30px', marginRight: '10px' }}
						>
							<button
								className='verify_account'
								onClick={() => handleSendNote(id)}
							>
								Save
							</button>
						</div>
						<div
							className='child-right'
							style={{ marginTop: '30px', marginLeft: '10px' }}
						>
							<button
								className='verify_account'
								onClick={() => handleSendEmailClick(id)}
							>
								Send Invoice
							</button>
						</div>
					</div>
					{/* } */}
				</div>

				{/* </Container> */}
			</div>
			<Toaster />
		</>
	);
};

export default CompanyDetails;
