import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogContentText,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Divider,
	Input,
	Box,
	TextareaAutosize,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { toast, Toaster } from 'react-hot-toast';
import Provider from '../../asset/image/provider.png';
const useStyles = makeStyles({
	buttonAddnewHelp: {
		fontSize: '24px',
		fontWeight: '500',
	},
	sendCredButton: {
		height: '46px',
		width: '190px',
		borderRadius: '24px',
		background: '#4157FF',
	},
	headingSettingTest: {
		fontSize: '24px',
		fontWeight: '500',
	},
});

const ProviderPopModal = () => {
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// css
	const contactProvider = {
		color: 'black',
		fontWeight: '700',
		cursor: 'pointer',
	};

	const submitContactButton = {
		color: 'white ',
		background: ' #4157FF ',
		borderRadius: '56px ',
		border: 'none',
		width: '203px ',
		height: '50px',
		cursor: 'pointer ',
		fontWeight: '600',
	};

	return (
		<>
			<div>
				<img
					src={Provider}
					alt='Provider'
					style={{
						
						cursor: 'pointer',
						height: '270px',
						width: '240px',
					}}
					onClick={handleOpen}
				/>
				<div>
			<Dialog
				open={open}
				onClose={() => {
					handleClose();
				}}
				maxWidth={400}
			>
				<DialogTitle id='scroll-dialog-title'>
					<Box style={{ display: 'grid', justifyContent: 'flex-start' }}>
						<Box>
							<span
								style={{
									fontSize: '24px',
									fontWeight: '500',
								}}
							>
								Providers
							</span>
						</Box>

						
					
					</Box>
					<IconButton
						onClick={() => {
							handleClose();
						}}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: 'rgb(65, 87, 255)',
								width: '31px',
								height: '31px',
								fontSize: '11px',
								boxShadow: 'none',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '3px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>

				<DialogContent>
					<DialogContentText id='scroll-dialog-description'>
						<Box
							style={{
								display: 'grid',
								justifyContent: 'center',
								alignItem: 'center',
							
								width: 400,
								background: 'white',
								borderRadius: '12px',
								height: 'auto',
							}}
						>
							<Box style={{display:'grid'}}>
								<div>
								<span style={{color:'black'}}>
									Provider registration is done through the mobile app, <br />
									click the link to start getting paid the SAME DAY you <br />
								</span>
								</div>
							
							<div style={{display:'flex',justifyContent:'center'}}>
							<span style={{color:'black',}}>see your patients IN FULL!</span>
							</div>
							
						</Box>
						
						</Box>
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						borderRadius: '0 0 20px 20px',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItem: 'center',
							gap:'10px',
						}}
					>
					
					<Box>
					<Button
								style={{
									background: '#4157ff',
									fontWeight: '500',
									color: 'white',
								}}
							>
								<a
									href='https://play.google.com/store/apps/details?id=com.healthcoin.app'
									target='_blank'
									rel='noreferrer'
									style={{ color: 'white', textDecoration: 'none' }}
								>
									Get Started <br />
									for Android
								</a>
					</Button>
					</Box>

					<Box>
						<Button
								style={{
									background: '#4157ff',
									fontWeight: '500',
									color: 'white',
								}}
							>
								<a
									href='https://apps.apple.com/us/app/healthcoin-exchange-inc/id6447052141'
									target='_blank'
									rel='noreferrer'
									style={{ color: 'white', textDecoration: 'none' }}
								>
									Get Started <br />
									for IOS
								</a>
						</Button>
						</Box>
					</Box>
				</DialogActions>
			</Dialog>
		</div>
				<Toaster />
			</div>
		</>
	);
};
export default ProviderPopModal;
