import { configureStore } from '@reduxjs/toolkit';
import EmployerReducer from './reducer/employerReducer';
import userReducer from './reducer/user';
import tabReducer from './reducer/tabReducer';
import authSlice from './reducer/auth.slice';

// export const server = 'https://api.healthcoinxchangeportal.com/api/v1';
export const server = 'https://api.healthcoinxchangeportal.com/api/v1';
// export const server = "http://192.168.19.23:8001/";

const store = configureStore({
	reducer: {
		user: userReducer,
		enterprise: EmployerReducer,
		auth: authSlice.reducer,
		counter: tabReducer,
	},
});

export default store;
