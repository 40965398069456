import React, { useEffect, useState } from 'react';
import Union from '../../../asset/image/Header/Union.svg';
import Shape from '../../../asset/image/Header/Shape.png';
import Logo from '../../../asset/image/Logo.svg';
import Profile from '../../Profile/Profile';
import {
	Box,
	Button,
	Container,
	TextField,
	// Link,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Switch,
	// Rating,
	Grid,
	TableBody,
	Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

const Header = ({ currentPath = '' }) => {
	const [tab, setTab] = useState(window.location.pathname);
	const { user } = useSelector(state => state.user);
	const [data, setData] = useState('');
	useEffect(() => {
		const path = tab;
		const providerInfo = tab;
		const regex = /^\/?provider_info/;
		const startsWithProviderInfo = regex.test(providerInfo);
		if (startsWithProviderInfo) {
			setData('Provider Info');
		} else {
			const newPath = path.replace(/^\/+/, '');
			// "login"
			setData(newPath);
		}
	}, [tab]);
	useEffect(() => {
		const path = tab;
		const employeeDetails_info = tab;
		const regex = /^\/?employeeDetails_info/;
		const startsWithemployeeDetails_info = regex.test(employeeDetails_info);
		if (startsWithemployeeDetails_info) {
			setData('employeeDetails_info');
		} else {
			const newPath = path.replace(/^\/+/, '');
			// "login"
			setData(newPath);
		}
	}, [tab]);
	useEffect(() => {
		const path = tab;
		const employers_Info = tab;
		const regex = /^\/?employers_Info/;
		const startsWithemployers_Info = regex.test(employers_Info);
		if (startsWithemployers_Info) {
			setData('employers_Info');
		} else {
			const newPath = path.replace(/^\/+/, '');
			// "login"
			setData(newPath);
		}
	}, [tab]);

	//
	useEffect(() => {
		const path = tab;
		const invoicesDetails = tab;
		const regex = /^\/?invoicesDetails/;
		const startsWithinvoicesDetails = regex.test(invoicesDetails);
		if (startsWithinvoicesDetails) {
			setData('invoicesDetails');
		} else {
			const newPath = path.replace(/^\/+/, '');
			// "login"
			setData(newPath);
		}
	}, [tab]);

	const [open, setOpen] = useState(false);
	const [roleType, setRoleType] = useState('');

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (user?.user_type == 'enterprise') {
			setRoleType('MEMBERS');
		} else {
			setRoleType('PROVIDERS');
		}
	}, [JSON.stringify(user)]);
	return (
		<div>
			<Box
				sx={{
					display: 'flex',
					background: 'white',
					height: '80px',
					width: '100vw',
					zIndex: '100',
					position: 'fixed',
				}}
			>
				<Box sx={{ margin: '22px' }}>
					<span
						style={{ color: '#4157FF', fontSize: '12px', fontWeight: '700' }}
					>
						DASHBOARD &nbsp;&nbsp;
					</span>
					<img src={Shape} alt='' />
					{currentPath ? (
						<>{currentPath}</>
					) : (
						<span
							style={{ fontSize: '12px', fontWeight: '500', color: '#D0D1D2' }}
						>
							&nbsp;&nbsp;{data === 'payment_request' && 'PYMENT REQUEST'}{' '}
							{data === 'setting' && 'SETTINGS'}{' '}
							{data === 'hospital_disputes' && 'HOSPITAL DISPUTES'}
							{data === 'providers' && 'PROVIDERS'} {data === '' && roleType}{' '}
							{data === 'Provider Info' && 'Provider > Provider Info'}{' '}
							{data === 'employers' && 'EMPLOYERS'}
							{/* {/ Employers Header /} */}
							{data === 'members' && 'MEMBERS'}
							{data === 'invoices' && 'INVOICES'}
							{data === 'settings' && 'SETTINGS'}
							{data === 'employeeDetails_info' && 'EMPLOYEE’S DETAILS'}
							{data === 'employers_Info' && 'EMPLOYEES DETAILS'}
							{data === 'invoicesDetails' && 'INVOICES'}
							{data === 'users' && 'USERS'}
							{data === 'invoice_page' && 'INVOICES'}
							{data === 'invoice_list' && 'INVOICES'}
							{data === 'NetworkTable' && 'OUT OF NETWORK'}
							{data === 'self_pay_patient' && 'PATIENT SELF-PAY'}
							{data === 'EmergencyService' && 'EMERGENCY SERVICE CASE'}
						</span>
					)}
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						marginLeft: 'auto',
						marginRight: '295px',
					}}
				>
					<Box sx={{ display: 'flex', gap: '10px' }}>
						{/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '100px', background: 'rgba(203, 203, 221, 0.1)', width: '46px', height: '46px' }}>
              <img src={Union} />
            </Box> */}
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '50px',
								height: '50px',
								borderRadius: '50%',
								overflow: 'hidden',
								background: 'black',
							}}
						>
							{user?.profile_image ? (
								<img
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										borderRadius: '50%',
										cursor: 'pointer',
										overflow: 'hidden',
									}}
									src={
										'https://api.healthcoinxchangeportal.com/' +
										user?.profile_image
									}
									onClick={handleOpen}
									alt='User Profile'
								/>
							) : (
								<img
									style={{
										width: '100%',
										height: '31px',
										objectFit: 'cover',
										borderRadius: '50%',
										cursor: 'pointer',
									}}
									src={Logo}
									onClick={handleOpen}
									alt='User Profile'
								/>
							)}
						</Box>
					</Box>
				</Box>
				<Profile
					handleOpen={handleOpen}
					handleClose={handleClose}
					open={open}
				/>
			</Box>
		</div>
	);
};

export default Header;
