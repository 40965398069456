import {
	Box,
	Button,
	Checkbox,
	Container,
	FormControl,
	FormControlLabel,
	FormGroup,
	Modal,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState, useRef, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import Vector from '../../asset/image/vector.png';
import BorderDesign from '../../asset/image/BorderDesign.png';
import Imbolance from '../../asset/image/imbolance.png';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import SummaryPatient from './Summary';
import CloseIcon from '@mui/icons-material/Close';
import AddDebitCard from './AddDebitCard';
import MemberShip from './MemberShip';
import MemberShipSuccess from './MembershipSuccess';
import { toast, Toaster } from 'react-hot-toast';
import './PatientRegistrationcss.css';
import { MdDelete } from 'react-icons/md';
import { IoArrowBackCircle } from 'react-icons/io5';
import {
	SelfPayPatient,
	addPatientFamily,
	getAllUserAge,
	getPreConditionData,
	checkUsernameExist,
} from '../../redux/action/user';
import { useDispatch, useSelector } from 'react-redux';
import OTPscreen from './OTPscreen';
import PhoneNumberVerified from './PhoneNumberVerified';
import TermAndCondition from './TermAndConditions';
// Date picker
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	useNavigate,
	UNSAFE_NavigationContext,
} from 'react-router-dom';
import { useContext } from 'react';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	error: {
		color: 'red',
		fontSize: '14px',
	},
	customList: {
		// listStyleType: 'none', // Remove default bullet points
		padding: '5px', // Remove default padding
		margin: 0, // Remove default margin
	},
	customListItem: {
		fontSize: '16px',
		marginBottom: theme.spacing(2), // Customize spacing between list items
		fontWeight: 700,
	},
	customListPrice: {
		// listStyleType: 'none', // Remove default bullet points
		padding: '5px', // Remove default padding
		margin: 0, // Remove default margin
	},
	customListItemPrice: {
		fontSize: '15px',
		marginBottom: theme.spacing(1), // Customize spacing between list items
		fontWeight: 600,
		lineHeight: '18px',
	},
	menuListBorder: {
		borderBottom: '1px solid red',
		// borderBottom:'1px solid #CBCBDD',
		padding: '400px',
	},
	registrationScreenResponsive: {
		width: '350px',
		[theme.breakpoints.down('sm')]: {
			width: '290px',
		},
	},
}));
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	backgroundColor: '#ddd',
	// border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const defaultTheme = createTheme();
const theme = createTheme({
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					// fontSize: "2em",
					color: 'black',
					backgroundColor: 'white',
					marginTop: '100px',
				},
			},
		},
	},
});

const LoginMemberFlow = () => {
	const [selectedDate, setSelectedDate] = useState(null);
	const [partnerDate, setPartnerDate] = useState(null);
	const [partnerForamtedDate, setPartnerForamtedDate] = useState(null);
	const [childDate, setChildDate] = useState([null]);
	const [age, setAge] = useState(null);
	const [partnerAge, setPartnerAge] = useState(null);
	const [childAge, setChildAge] = useState([null]);
	const [showModal, setShowModal] = useState(false);

	const dispatch = useDispatch();
	const menuListBorderItem = {
		border: '1 px solid red',
	};
	// const [age, setAge] = React.useState("");
	const classes = useStyles();
	const handleChange = event => {
		setAgeErrorMessage('');
		setAge(event.target.value);
	};

	const [memberData, setMemberData] = useState({
		plan: '',
		gender: '',
		marital_status: '',
		patient_dob: '',
		spouse_dob: '',
		full_coverage_gender: '',
		full_coverage_age: '',
		full_coverage_marital_status: '',

		full_coverage_partner_plan: '', // neeed to remove
		full_coverage_partner_gender: '',
		full_coverage_partner_age: '',
		full_coverage_partner_is_pre_existing: '',
		preExisting: [],

		full_coverage_kids: '',

		full_coverage_pre_existing: false,
		full_coverage_total_pre_existing: 5,

		partner_first_name: '',
		partner_last_name: '',
		partner_phone_number: '',
		partner_email: '',
		partner_address: '',
	});

	const [accountDetailed, setAccountDetailed] = useState({
		debit_card: '',
		month: '',
		year: '',
		cvc: '',
	});
	// setkids([])
	// setPreExistingArray([])
	const [partnerChecked, setPartnerChecked] = useState('');
	const [isContinue, setIsContinue] = useState(true);
	const [genderChecked, setGenderChecked] = useState('');
	const [maritChecked, setMaritChecked] = useState('');
	const [partnerErrorMessage, setPartnerErrorMessage] = useState('');
	const [genderErrorMessage, setGenderErrorMessage] = useState('');
	const [maritalErrorMessage, setMaritalErrorMessage] = useState('');
	const [ageErrorMessage, setAgeErrorMessage] = useState('');
	const [showPassword, setShowPassword] = React.useState(false);

	const [confirmPassword, setConfirmPassword] = React.useState(false);
	const initialArray = Array(memberData?.full_coverage_total_pre_existing).fill(
		''
	);

	const [preExistingArray, setPreExistingArray] = React.useState([]);
	const [kids, setkids] = React.useState([
		{
			id: Math.floor(Math.random() * 10000000),
			gender: '',
			first_name: '',
			last_name: '',
			phone_number: '',
			email: '',
			address: '',
			date_of_birth: null,
			age: '',
			isPreExistingCondition: '',

			preExistingArray: [],
			price: '',
		},
	]);

	const navigation = useContext(UNSAFE_NavigationContext).navigator;

	const localStorageUser = JSON.parse(localStorage.getItem('user'));
	const [step, setStep] = useState(1);
	useEffect(() => {
		console.log(step);
	}, [step]);
	const [logedinuser, setLogedIn] = useState('abc');

	const [timeLeft, setTimeLeft] = useState(60);
	//  Step 4 for modal tooltip
	const [childAges, setChildAges] = useState([]);
	const [patientAges, setPatientAges] = useState([]);
	const navigate = useNavigate();
	const [openPhoneVerifiedModal, setOpenPhoneVerifiedModal] =
		React.useState(false); // OTP Verification Modal
	const [open, setOpen] = React.useState(false); // success Phone Verification Modal
	const handleOpen = () => setOpen(true);
	const handleOpenPhoneModal = () => setOpenPhoneVerifiedModal(true);
	const handleClosePhoneModal = () => setOpenPhoneVerifiedModal(false);
	const inputRef = useRef();
	const handleClose = () => setOpen(false);
	const handlePartnerCheckboxChange = event => {
		setPartnerErrorMessage('');
		if (event.target.checked) {
			setPartnerChecked(event.target.value);
		} else {
			setPartnerChecked('');
		}
	};
	const { message, allPreConditions, allUserAges } = useSelector(state => state.user);
	let  user  = useSelector(state => state.user);
	if (user.token === 'Bearer null') {
		const userDataFromLocalStorage = JSON.parse(localStorage.getItem('user'));
		if (userDataFromLocalStorage) {
			user =userDataFromLocalStorage;
		}
	}
	console.log(user,"assadew")
	const [informationDetail, setInformationDetail] = useState({
		first_name: '',
		last_name: '',
		gender: '',
		phone_number: '',
		email: '',
		address: '',
		password: '',
		confirm_password: '',
	});

	// console.log('cccccc', childAges, patientAges);
	const changeInformationDetail = e => {
		const { name, value } = e.target;

		setInformationDetail({
			...informationDetail,
			[name]: value,
		});
	};

	useEffect(() => {
		if (!timeLeft) return;

		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);
	useEffect(() => {
		if (
			message ==
			'Self-Pay Patient registered successfully and email is sent to patient email address.'
		) {
			setOpenPhoneVerifiedModal(true);
		}
		if (message == 'Code Verified.') {
			setOpenPhoneVerifiedModal(false);
			setOpen(true);
		}
	}, [message]);
	useEffect(() => {
		dispatch(getPreConditionData());
	}, []);

	useEffect(() => {
		dispatch(getAllUserAge(memberData?.plan));
	}, [memberData?.plan]);
	useEffect(() => {
		// console.log('user.user_typeuser.user_typeuser.user_type', user);
		if (user?.email_verified_at) {
			setStep(4);
		}
	}, [JSON.stringify(user)]);

	const validateKidsArray = kids => {
		// Check if kids is an array
		if (!Array.isArray(kids)) {
			return false;
		}

		// Iterate through each object in the array
		for (let i = 0; i < kids.length; i++) {
			const kid = kids[i];
			// Check if 'age' and 'gender' fields are present and not empty
			if (
				!kid.hasOwnProperty('age') ||
				!kid.hasOwnProperty('gender') ||
				kid.age === '' ||
				kid.gender === ''
			) {
				return false;
			}
			if (
				!kid.hasOwnProperty('isPreExistingCondition') ||
				!kid.isPreExistingCondition
			) {
				return false;
			}
			if (
				!kid.hasOwnProperty('isPreExistingCondition') ||
				(kid.isPreExistingCondition == 'yes' &&
					(!kid.hasOwnProperty('preExistingArray') ||
						kid.preExistingArray.length < 1))
			) {
				return false;
			}
		}

		// If all objects in the array have 'age' and 'gender' fields, return true
		return true;
	};
	function validateEmail(email) {
		// Regular expression for a basic email validation
		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return emailPattern.test(email);
	}

	function findLargestNumber(array) {
		let largestNumber = Number.NEGATIVE_INFINITY; // Initialize with negative infinity

		for (const str of array) {
			const numbers = str
				.split('-')
				.map(numStr => parseInt(numStr.trim(), 10)) // Parse and trim each number
				.filter(num => !isNaN(num)); // Filter out any NaN values

			for (const num of numbers) {
				if (num > largestNumber) {
					largestNumber = num;
				}
			}
		}

		return largestNumber;
	}
	const validateKidsArrayData = kids => {
		// Check if kids is an array
		if (!Array.isArray(kids)) {
			return false;
		}

		// Iterate through each object in the array
		for (let i = 0; i < kids.length; i++) {
			const kid = kids[i];

			// Check if 'age' and 'gender' fields are present and not empty
			if (
				!kid.hasOwnProperty('age') ||
				!kid.hasOwnProperty('gender') ||
				kid.age === '' ||
				kid.gender === '' ||
				!kid.hasOwnProperty('first_name') ||
				!kid.hasOwnProperty('last_name') ||
				kid.first_name === '' ||
				kid.last_name === '' ||
				// !kid.hasOwnProperty("phone_number") ||
				!kid.hasOwnProperty('email') ||
				// kid.phone_number === "" ||
				kid.email === ''
			) {
				return false;
			}
		}

		// If all objects in the array have 'age' and 'gender' fields, return true
		return true;
	};
	const validateKidsArrayDataNew = kids => {
		// Check if kids is an array
		// Iterate through each object in the array

		for (let i = 0; i < kids.length; i++) {
			const kid = kids[i];
			// console.log('kkkkkkkkkkkkk1', kid);

			// if (kid?.phone_number?.length != 10) {
			//   return toast.error(`Phone number is not valid for children ${i + 1}`);
			// } else if (!validateEmail(kid.email)) {
			//   return toast.error(`Email is not valid for children ${i + 1}`);
			// }
			// if (!validateEmail(kid.email)) {
			//   return toast.error(`Email is not valid for children ${i + 1}`);
			// }
		}
	};

	function findRangeForNumber(arr, number) {
		const pattern = /\d+\+|\+\d+/;
		const numberWithPlusSign = arr.filter(str => pattern.test(str));

		const numberWithoutPlus = parseInt(
			numberWithPlusSign[0]?.replace('+', ''),
			10
		);
		//  console.log(numberWithoutPlus, number)
		if (numberWithoutPlus) {
			if (numberWithoutPlus <= parseInt(number)) return numberWithPlusSign[0];
		} else {
			const largetNumber = findLargestNumber(arr, number);
			if (largetNumber < number) return 'OUT_OF_RANGE';
		}

		for (let i = 0; i < arr.length; i++) {
			const range = arr[i].split('-');
			const min = parseInt(range[0], 10);
			const max = range[1] === '+' ? Infinity : parseInt(range[1], 10);

			if (number >= min && number <= max) {
				return arr[i];
			}
		}
		return 'Number is not in any specified range';
	}

	const getPatientAgeId = range => {
		const result = patientAges.find(item => item.age === range.toString());

		return result?.id;
	};

	const getChildAgeId = range => {
		const result = childAges.find(item => item.age === range.toString());

		return result;
	};

	const getCalculateAge = date => {
		const currentDate = dayjs();
		const birthDate = dayjs(date);
		const calculatedAge = currentDate.diff(birthDate, 'year');

		return calculatedAge;
	};

	const handlePartnerDate = date => {
		const dateObject = new Date(date);
		const year = dateObject.getFullYear();
		const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
		const day = dateObject.getDate().toString().padStart(2, '0');

		const formattedDate = `${year}-${month}-${day}`;

		const calculatedAge = getCalculateAge(date);
		// Check if the age is greater than or equal to 18
		if (calculatedAge >= 18) {
			setPartnerDate(date);
			setPartnerForamtedDate(formattedDate);
			setPartnerAge(calculatedAge);
			const ranges = patientAges?.map(item => item.age);
			const number = calculatedAge;
			const range = findRangeForNumber(ranges, number);
			const ageId = getPatientAgeId(range);
			setMemberData({
				...memberData,
				full_coverage_partner_age: ageId,
			});

			//here1
		} else {
			// Reset date and age if less than 18
			setPartnerDate(null);
			setPartnerForamtedDate(null);
			setPartnerAge(null);
			toast.error('Minimum age requirement not met (18 years)');
		}
	};

	const handleDateChange = date => {
		const calculatedAge = getCalculateAge(date);

		// Check if the age is greater than or equal to 18
		if (calculatedAge >= 18) {
			setSelectedDate(date);
			setAge(calculatedAge);
			const ranges = patientAges?.map(item => item.age);
			const number = calculatedAge;
			const range = findRangeForNumber(ranges, number);
			const ageId = getPatientAgeId(range);
			setMemberData({
				...memberData,
				full_coverage_age: ageId,
			});

			//here1
		} else {
			// Reset date and age if less than 18
			setSelectedDate(null);
			setAge(null);
			toast.error('Minimum age requirement not met (18 years)');
		}
	};

	const [child_dob, setChilDob] = useState([]);

	const handleChildDate = (date, index) => {
		const calculatedAge = getCalculateAge(date);
		const birthDate = dayjs(date).format('YYYY-MM-DD');
		const child_dob_copy = [...child_dob];
		child_dob_copy[index] = birthDate;
		setChilDob([...child_dob_copy]);

		const ranges = childAges?.map(item => item.age);
		const number = calculatedAge;
		const range = findRangeForNumber(ranges, number);

		// Check if the age is greater than or equal to 24
		// console.log(calculatedAge, range);

		if (calculatedAge <= 24 || range !== 'OUT_OF_RANGE') {
			const childDateCopy = [...childDate];
			childDateCopy[index] = dayjs(date);
			setChildDate([...childDateCopy]);
			const childAgeCopy = [...childAge];
			childAgeCopy[index] = calculatedAge;
			setChildAge([...childAgeCopy]);
			const filerChild = getChildAgeId(range);
			const kidsCopy = [...kids];

			kidsCopy[index].age = calculatedAge;
			kidsCopy[index].id = filerChild?.id;
			kidsCopy[index].price = filerChild?.price;
			kidsCopy[index].range = range;
			kidsCopy[index].date_of_birth = dayjs(date);
			setkids([...kidsCopy]);

			//here1
		} else {
			// Reset date and age if less than 18
			const childDateCopy = [...childDate];
			childDateCopy[index] = null;
			setChildDate([...childDateCopy]);
			const childAgeCopy = [...childAge];
			childAgeCopy[index] = 'Limit Exceed';
			setChildAge([...childAgeCopy]);
			toast.error('Minimum age requirement not met.');
		}
	};
	let formattedDate;

	if (selectedDate) {
		formattedDate = new Date(selectedDate).toISOString().split('T')[0];
	}

	useEffect(() => {
		if (formattedDate) {
			setMemberData(prevMemberData => ({
				...prevMemberData,
				patient_dob: formattedDate,
			}));
		}
	}, [selectedDate]);

	// useEffect(() => {
	//   if (selectedDate) {
	//     // Calculate age based on the selected date
	//     const currentDate = dayjs();
	//     const birthDate = dayjs(selectedDate);
	//     const calculatedAge = currentDate.diff(birthDate, 'year');

	//     if (calculatedAge >= 18) {  // Check if the age is greater than or equal to 18
	//       setAge(calculatedAge);
	//     } else {
	//       setAge(null);
	//       toast.error('Minimum age requirement not met (18 years)');
	//     }
	//   }
	// }, [selectedDate]);

	useEffect(() => {
		var indexing = 3;
		if (memberData?.plan == 'full_coverage') {
			indexing = 3;
		} else {
			indexing = 3;
		}
		const forChild = allUserAges ? [...allUserAges] : [];
		const forPatient = allUserAges ? [...allUserAges] : [];
		forChild?.splice(indexing);
		forPatient?.splice(0, indexing - 1);

		setChildAges(forChild);
		setPatientAges(forPatient);
	}, [JSON.stringify(allUserAges)]);

	const handleGenderCheckboxChange = event => {
		setGenderErrorMessage('');
		if (event.target.checked) {
			setGenderChecked(event.target.value);
		} else {
			setGenderChecked('');
		}
	};
	const handleMaritCheckboxChange = event => {
		setMaritalErrorMessage('');
		if (event.target.checked) {
			setMaritChecked(event.target.value);
		} else {
			setMaritChecked('');
		}
	};

	const nextState = () => {
		const { plan, full_coverage_gender, full_coverage_age } = memberData;

		if (step == 4) {
			// if (!memberData?.full_coverage_pre_existing) {
			//   return toast.error('Please select pre existing');
			// }
			if (!plan) {
				return toast.error('Please select a plan');
			}
			if (!full_coverage_gender) {
				return toast.error('Please select a gender');
			}
			if (!full_coverage_age) {
				return toast.error('Please select a age');
			}
			if (
				memberData.full_coverage_partner_plan == 'yes' &&
				!memberData.full_coverage_partner_gender
			) {
				return toast.error('Please select a partner gender');
			}
			if (
				memberData.full_coverage_partner_plan == 'yes' &&
				!memberData.full_coverage_partner_age
			) {
				return toast.error('Please select a partner age');
			}
			if (
				memberData.full_coverage_partner_plan == 'yes' &&
				!memberData?.full_coverage_partner_is_pre_existing
			) {
				return toast.error('Please select a partner pre existing');
			}

			if (!memberData.full_coverage_partner_plan) {
				return toast.error('Please select a partner plan');
			}
			if (!memberData?.full_coverage_pre_existing) {
				return toast.error(
					'Please select a patient preexisting condition option'
				);
			}
			if (
				!memberData?.full_coverage_partner_is_pre_existing &&
				memberData.full_coverage_partner_plan === 'yes'
			) {
				return toast.error(
					'Please select a Spouse/partner preexisting condition option'
				);
			}
			//

			if (
				memberData.full_coverage_partner_is_pre_existing == 'yes' &&
				memberData.preExisting.length < 1
			) {
				return toast.error('Please select a partner pre-existing');
			}
			if (!memberData.full_coverage_kids) {
				return toast.error('Please select kids option');
			}

			if (memberData?.full_coverage_kids == 'yes' && !validateKidsArray(kids)) {
				return toast.error("Please provide complete data for child's");
			}
			if (
				memberData?.full_coverage_pre_existing == 'yes' &&
				preExistingArray.length < 1
			) {
				return toast.error('Please select patient preexisting condition');
			}
			if (memberData?.full_coverage_kids === 'yes' && kids.length === 0) {
				return toast.error('Please add at least one child');
			}
			// if (!full_coverage_marital_status) {
			//   return toast.error("Please select a spouse marital status");
			// }
			if (
				memberData?.full_coverage_pre_existing == 'yes' &&
				preExistingArray.length
			) {
				setShowModal(true);
				return;
			}
			for (let i = 0; i < kids.length; i++) {
				const kid = kids[i];
				if (
					kid?.isPreExistingCondition == 'yes' &&
					kid.preExistingArray.length
				) {
					setShowModal(true);
					return;
				}
			}

			if (memberData?.full_coverage_partner_is_pre_existing === 'yes') {
				setShowModal(true);
				return;
			}

			setStep(6);
		} else if (step == 2) {
			const {
				first_name,
				last_name,
				gender,
				phone_number,
				email,
				address,
				password,
				confirm_password,
			} = informationDetail;
			if (!first_name) {
				return toast.error('First name must be required');
			}
			if (!last_name) {
				return toast.error('Last name must be required');
			}
			if (!gender) {
				return toast.error('Gender must be required');
			}
			if (!phone_number) {
				return toast.error('Phone number must be required');
			}
			if (!email) {
				return toast.error('Email must be required');
			}
			if (!address) {
				return toast.error('Address must be required');
			}
			if (!password) {
				return toast.error('Password must be required');
			}
			if (!confirm_password) {
				return toast.error('Confirm Password must be required');
			}
			if (password !== confirm_password) {
				return toast.error('Confirm Password is not matched');
			}

			dispatch(SelfPayPatient(informationDetail));

			if (message) {
				// setOpenPhoneVerifiedModal(true);
			}
			return;
		} else if (step == 6) {
			if (
				memberData?.full_coverage_kids == 'yes' &&
				!validateKidsArrayData(kids)
			) {
				return toast.error('Please provide complete data for childs');
			}
			if (
				memberData?.full_coverage_kids == 'yes' &&
				validateKidsArrayDataNew(kids)
			) {
				return;
			}
			if (
				memberData.full_coverage_partner_plan == 'yes' &&
				!memberData?.partner_first_name
			) {
				return toast.error('Please add spouse/partner first name');
			}
			if (
				memberData.full_coverage_partner_plan == 'yes' &&
				!memberData?.partner_last_name
			) {
				return toast.error('Please add spouse/partner last name');
			}
			if (
				memberData.full_coverage_partner_plan == 'yes' &&
				!memberData?.partner_phone_number
			) {
				return toast.error('Please add spouse/partner phone number');
			}
			if (
				memberData.full_coverage_partner_plan == 'yes' &&
				memberData.partner_phone_number.length != 10
			) {
				return toast.error('Please add spouse/partner valid phone number');
			}

			if (
				memberData.full_coverage_partner_plan == 'yes' &&
				!memberData?.partner_email
			) {
				return toast.error('Please add spouse/partner email');
			}
			if (
				memberData.full_coverage_partner_plan == 'yes' &&
				!validateEmail(memberData.partner_email)
			) {
				return toast.error('Please add spouse/partner valid email');
			}

			let go = true;

			kids.forEach((item, index) => {
				if (!item?.isUserNameUnique) {
					go = false;
					return toast.error(`Child ${index + 1} user name is wrong`);
				}
			});

			if (!go) return;
		}
		if (step == 7) {
			// console.log(
			// 	'memberDatamemberDatamemberData',
			// 	memberData,
			// 	preExistingArray
			// );
			const {
				full_coverage_gender,
				full_coverage_age,
				patient_dob,
				plan,
				full_coverage_pre_existing,
				full_coverage_partner_plan,
				full_coverage_partner_gender,
				full_coverage_partner_age,
				preExisting,
				partner_phone_number,
				partner_first_name,
				partner_last_name,
				partner_email,
			} = memberData;
			// patient_gender  full_coverage_gender
			// patient_age     full_coverage_age
			// patient_preids  preExistingArray
			// ---
			// spouse_name     partner_first_name+partner_last_name
			// spouse_gender  full_coverage_partner_gender
			// spouse_email
			// spouse_phone   partner_phone_number
			// spouse_plan_id  full_coverage_partner_age
			// spouse_discount  20
			// spouse_user_type   spouse
			// spouse_preids  preExisting

			// ---

			// name[]  kids.first_name+kids.last_name
			// gender[]  kids.gender
			// plan_id[] kids.age
			// email[]    kids.email
			// phone[]    kids.phone
			//discount[]  [10,25,30,30]
			// isPreExistingCondition true or false
			// preids[]   preExistingArray
			// patient_gender[]  kids.
			// user_type[]  child
			// alert("hello")
			if (memberData.full_coverage_partner_plan === 'no') {
				delete memberData?.full_coverage_partner_plan;
			}
			if (memberData.full_coverage_partner_plan == 'yes') {
				memberData.spouse_dob = partnerForamtedDate;
			} else {
				delete memberData?.spouse_dob;
			}

			dispatch(
				addPatientFamily(
					memberData,
					preExistingArray,
					kids,
					step,
					setStep,
					child_dob
				)
			);
			return;
		}

		if (step < 10) {
			if (step == 4) {
				setStep(step + 2);
			} else {
				setStep(step + 1);
			}
		}
	};

	const backStep = () => {
		// alert(JSON.stringify(step));
		if (step == 6) {
			return setStep(step - 2);
		}
		if (step >= 5) {
			return setStep(step - 1);
		}
		if ([1, 4].includes(step)) {
			navigate('/patient_registration');
		}
	};
	const handleClickShowPassword = () => setShowPassword(show => !show);
	const handleClickShowConfirmPassword = () =>
		setConfirmPassword(show => !show);

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};
	const addChildren = () => {
		setChildDate([...childDate, null]);
		setkids([
			...kids,
			{
				first_name: '',
				last_name: '',
				phone_number: '',
				email: '',
				address: '',
				date_of_birth: null,

				preExistingArray: [],
				price: '',
			},
		]);
	};

	const [usernameList, setUsernameList] = useState([]);

	const handleEmailExist = async (email, index) => {
		const kidsCopy = [...kids];
		const trimmedEmail = email.trim().toLowerCase();
		// Check if the username already exists in the local list
		if (usernameList.includes(trimmedEmail)) {
			kidsCopy[index]['isUserNameUnique'] = false;
			return toast.error('Username already exists'); // You can display an error message or take appropriate action
		} else {
			kidsCopy[index]['isUserNameUnique'] = true;
			setUsernameList([...usernameList, trimmedEmail]); // Add the username to the local list
		}
		await dispatch(checkUsernameExist(email));
	};

	const handleInputChange = (index, field, result) => {
		if (field == 'gender' || field == 'isPreExistingCondition') {
			const { checked, value } = result;
			if (checked) {
				const newData = [...kids];
				newData[index][field] = value;
				setkids(newData);
			} else {
				const newData = [...kids];
				newData[index][field] = '';
				setkids(newData);
			}
		} else if (
			field == 'first_name' ||
			field == 'last_name' ||
			// field == "phone_number" ||
			field == 'email' ||
			field == 'address' ||
			field == 'date_of_birth'
		) {
			const { value } = result;
			// console.log('field', field, value, value.length);

			const newData = [...kids];
			newData[index][field] = value;
			setkids(newData);
		} else {
			const newData = [...kids];
			newData[index][field] = result;
			setkids(newData);
		}
	};

	const handleChangePreExistingCondition = (index, e) => {
		const { value } = e.target;

		const result = [...preExistingArray];
		result[index] = value;
		setPreExistingArray(result);
	};
	const handleChangePreExistingConditionChild = (
		index,
		e,
		existingChildIndex
	) => {
		const { value } = e.target;

		const record = kids[index].preEcistingChildArray;
		result[existingChildIndex] = value;
		const result = { ...kids, preEcistingChildArray: result };
		setMemberData(result);
	};
	const resendCode = () => {};
	const htmlContent = (
		<div
			style={{
				width: '870px',
				padding: '23px',
				margin: 0,
				// marginTop:'50px',

				backgroundColor: '#F7FBFF',
				border: '1px solid #3772FF',
				borderRadius: '16px',
				marginTop: '38px',
				marginLeft: '-200px',
				// borderImage: `url(${Border}) 30 round`,
			}}
		>
			<h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>
				Catastrophic + Direct Primary Care + Medications
			</h1>
			<div
				style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
			>
				<div style={{ width: '300px' }}>
					<h1 style={{ textDecoration: 'underline' }}>What You Get</h1>
					<ul className={classes.customList}>
						<li className={classes.customListItem}>Free Direct Primary Care</li>
						<li className={classes.customListItem}> Savings on Medications</li>
						<li className={classes.customListItem}>
							{' '}
							Annual Membership Buy-Downs*
						</li>
						<li className={classes.customListItem}>
							Free Pharmaceuticals Coming Soon*
						</li>
						<li className={classes.customListItem}>
							Full Access Gifting After First $10,000 Spent
						</li>
						<li className={classes.customListItem}>
							ER, Surgical, & Cancer Access
						</li>
						<li className={classes.customListItem}>
							Access to Responsive & Caring Support{' '}
						</li>
					</ul>
				</div>
				<div style={{ width: '264px' }}>
					<h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>
						What You Are Responsible For
					</h1>
					<div>
						<p
							style={{
								fontSize: '14px',
								fontWeight: '700',
								textAlign: 'center',
							}}
						>
							DPC (Direct Primary Care) is a<br /> membership based form of
							healthcare.
							<br /> HealthCoin is picking up the tab.
							<br /> Go as often as you’d like. It’s on us!
						</p>

						<div style={{ textAlign: 'center', margin: 'auto' }}>
							<img
								src={BorderDesign}
								alt='BorderDesign'
								width={'80px'}
								height={'7.6px'}
							/>
						</div>

						<p
							style={{
								fontSize: '14px',
								fontWeight: '700',
								textAlign: 'center',
							}}
						>
							Catastrophic access takes care <br /> of any medical services
							after the
							<br />
							first $10,000 spent by the patient.
						</p>

						<div style={{ textAlign: 'center', margin: 'auto' }}>
							<img
								src={BorderDesign}
								alt='BorderDesign'
								width={'80px'}
								height={'7.6px'}
							/>
						</div>
						<p
							style={{
								fontSize: '14px',
								fontWeight: '700',
								textAlign: 'center',
							}}
						>
							This plan is best for healthy <br /> individuals who want access
							to a dedicated <br /> provider, pharmaceutical savings, and peace{' '}
							<br /> of mind for major medical bills in case of emergency
						</p>
						<br />
					</div>
				</div>
				<div>
					<h1 style={{ textDecoration: 'underline' }}>Pricing</h1>

					<ul className={classes.customListPrice}>
						<li className={classes.customListItemPrice}>
							Age 0-7 M/F = $210/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 7-17 M/F = $230/mo{' '}
						</li>
						<li className={classes.customListItemPrice}>
							Age 18-24 M/F = $197/mo{' '}
						</li>
						<li className={classes.customListItemPrice}>
							Age 25-32 M/F = $262/mo{' '}
						</li>
						<li className={classes.customListItemPrice}>
							Age 33-44 M/F = $295/mo{' '}
						</li>
						<li className={classes.customListItemPrice}>
							Age 45-54 M/F = $361/mo{' '}
						</li>
						<li className={classes.customListItemPrice}>
							Age 55+ M/F = $450/mo{' '}
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
	const CatastrophicContent = (
		<div
			style={{
				width: '880px',
				padding: '23px',
				margin: 0,
				// marginTop:'90px',
				backgroundColor: '#F7FBFF',
				// border: "10px solid transparent",
				// borderImage: `url(${Border}) 30 round`,
				border: '1px solid #3772FF',
				borderRadius: '16px',
			}}
		>
			<h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>
				Catastrophic
			</h1>
			<div
				style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
			>
				<div style={{ width: '300px' }}>
					<h1 style={{ textDecoration: 'underline' }}>What You Get</h1>
					<ul className={classes.customList}>
						<li className={classes.customListItem}>
							Emergency Access Over $10,000
						</li>
						<br />
						<li className={classes.customListItem}>
							{' '}
							ER, Surgical, & Cancer Access
						</li>
						<br />
						<li className={classes.customListItem}>
							{' '}
							Access to Responsive & Caring Support
						</li>
						<br />
					</ul>
					<div style={{ marginTop: '30px' }}>
						<img src={Imbolance} alt='Imbolance' />
					</div>
				</div>
				<div style={{ width: '264px' }}>
					<h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>
						What You Are Responsible For
					</h1>
					<div>
						<p
							style={{
								fontSize: '14px',
								fontWeight: '700',
								textAlign: 'center',
							}}
						>
							Catastrophic Access takes care
							<br /> of any medical services above
							<br />
							$10,000.
						</p>
						<div style={{ textAlign: 'center', margin: 'auto' }}>
							<img
								src={BorderDesign}
								alt='BorderDesign'
								width={'80px'}
								height={'7.6px'}
							/>
						</div>
						<p
							style={{
								fontSize: '14px',
								fontWeight: '700',
								textAlign: 'center',
							}}
						>
							Regular check-ups, lab work,
							<br /> minor surgeries, etc are all the
							<br />
							responsibility of the patient.
						</p>
						<div style={{ textAlign: 'center', margin: 'auto' }}>
							<img
								src={BorderDesign}
								alt='BorderDesign'
								width={'80px'}
								height={'7.6px'}
							/>
						</div>
						<p
							style={{
								fontSize: '14px',
								fontWeight: '700',
								textAlign: 'center',
							}}
						>
							This plan is best for healthy young <br /> individuals who do not
							mind <br />
							covering their doctor visits but do
							<br /> no want to run the risk of major
							<br /> hospital bills in case of <br />
							emergency.
						</p>
						<br />
					</div>
				</div>
				<div>
					<h1 style={{ textDecoration: 'underline' }}>Pricing</h1>

					<ul className={classes.customListPrice}>
						<li className={classes.customListItemPrice}>
							Age 0-7 M/F = $95/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 7-17 M/F = $80/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 18-24 M/F = $70/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 25-32 M/F = $110/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 33-44 M/F = $185/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 45-54 M/F = $200/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 55+ M/F = $300/mo
						</li>
					</ul>
				</div>
			</div>
		</div>
	);

	const handleCheckboxChange = field => event => {
		const { checked, value } = event.target;

		if (field === 'full_coverage_kids' && value === 'yes') {
			addChildren();
		}
		if (field === 'full_coverage_kids' && value === 'no') {
			setChildDate([]);
			setkids([]);
		}
		if (field == 'plan') {
			setPreExistingArray([]);
			setSelectedDate(null);
			setPartnerDate(null);
			setPartnerForamtedDate(null);
			setChildDate([null]);
			setAge(null);
			setPartnerAge(null);
			setChildAge([null]);
			setkids([]);
			setMemberData({
				plan: '',
				gender: '',
				marital_status: '',
				patient_dob: '',
				spouse_dob: '',
				full_coverage_gender: '',
				full_coverage_age: '',
				full_coverage_marital_status: '',

				full_coverage_partner_plan: '', // neeed to remove
				full_coverage_partner_gender: '',
				full_coverage_partner_age: '',
				full_coverage_partner_is_pre_existing: '',
				preExisting: [],

				full_coverage_kids: '',

				full_coverage_pre_existing: false,
				full_coverage_total_pre_existing: 5,

				partner_first_name: '',
				partner_last_name: '',
				partner_phone_number: '',
				partner_email: '',
				partner_address: '',
			});
		}

		if (checked) {
			setMemberData(prevState => ({
				...prevState,
				[field]: value,
			}));
		} else {
			setMemberData(prevState => ({
				...prevState,
				[field]: '',
			}));
		}

		if (field === 'full_coverage_pre_existing' && value === 'no') {
			setPreExistingArray([]);
		}

		if (field === 'full_coverage_partner_is_pre_existing' && value === 'no') {
			setMemberData(prevData => ({
				...prevData,
				preExisting: [],
			}));
		}
	};

	// console.log(',,,,,,,,,,,,,,,,,,,,,,,,,,', memberData, kids, preExistingArray);

	const addPreExistingCondition = () => {
		if (preExistingArray.length > 3) {
			return toast.error('You can add max 4 PreExisting conditions');
		} else {
			setPreExistingArray([...preExistingArray, '']);
		}
	};
	// -------------------------------------------
	const [formData, setFormData] = useState([
		{
			age: '',
			gender: '',
			preExistingArray: [],
			price: '',
		},
		{
			age: '',
			gender: '',
			preExistingArray: [],
			price: '',
		},
	]);
	const preExistingNames = [
		{ id: '1', name: 'Cancer' },
		{ id: '2', name: 'Diabetes' },
		{ id: '3', name: 'Heart Disease' },
		{ id: '4', name: 'Pregnancy' },
		{ id: '5', name: 'Not Listed' },

		// Add more pre-existing names as needed
	];
	const [avaliableCondition, setAvaliableCondition] =
		useState(preExistingArray);
	const handleNameChange = (event, selectedOptions, index) => {
		setkids(prevData => {
			const newData = [...prevData];
			newData[index].preExistingArray = selectedOptions;
			return newData;
		});
	};
	const handleNameChangeSpouse = newValue => {
		setMemberData(prevData => ({
			...prevData,
			preExisting: newValue,
		}));
	};
	const handlePreexistingChangePatient = newValue => {
		setPreExistingArray(newValue);
	};
	const handlePreExistingChange = event => {
		const optionId = event.target.name;
		const isChecked = event.target.checked;

		setMemberData(prevData => {
			if (isChecked) {
				return {
					...prevData,
					preExisting: [...prevData.preExisting, optionId],
				};
			} else {
				return {
					...prevData,
					preExisting: prevData.preExisting.filter(item => item !== optionId),
				};
			}
		});
	};

	// const exitData
	const selectedOptionIds = memberData.preExisting?.map(option => option.id);
	const availableOptionsSpouse = allPreConditions?.filter(
		option => !selectedOptionIds?.includes(option.id)
	);
	// setPreExistingArray
	const selectedOptionIdsPatient = preExistingArray?.map(option => option.id);
	const availableOptionsPatient = allPreConditions?.filter(
		option => !selectedOptionIdsPatient?.includes(option.id)
	);

	// ////////////////////////////////////////

	// const calculateFee=()=>{
	// const {full_coverage_age,plan,full_coverage_partner_age}=memberData
	// const getFee=allUserAges?.find(data=>data.id==full_coverage_age)
	// const full_coverage_partner_age_Fee=allUserAges?.find(data=>data.id==full_coverage_partner_age)
	// var patientFee=+getFee?.price
	// var totalFee=+getFee?.price
	// var partnerFee=+full_coverage_partner_age_Fee?.price
	// console.log("totalFeetotalFeetotalFee",totalFee,full_coverage_age,plan,totalFee);
	// // full_coverage_partner_plan
	// if(memberData?.plan=='full_coverage'){
	//   const spouseDiscount=(totalFee/100)*20
	//   totalFee=totalFee-spouseDiscount
	// }
	// if(memberData?.full_coverage_partner_plan=='yes'){
	//   totalFee+=partnerFee
	// }
	// if(memberData?.full_coverage_partner_is_pre_existing=='yes'){
	//   const spousePreexisting=memberData?.preExisting?.length
	//   var patientprExistingFee
	//   if(memberData?.plan=='full_coverage'){
	//     const spouseDiscount=(partnerFee/100)*20
	//      patientprExistingFee=partnerFee-spouseDiscount
	//   }else{
	//     patientprExistingFee=partnerFee
	//   }
	//   const price=(patientprExistingFee/100*20)*spousePreexisting
	//   totalFee=totalFee+price
	// }
	// if(preExistingArray?.length>0){
	//   const selectedPreexisting=preExistingArray?.length
	//   const patientpreExistingCost=(patientFee/100*20)*selectedPreexisting;
	//   totalFee+=patientpreExistingCost
	// }

	// if(memberData.full_coverage_kids == "yes"&& kids.length>0){

	//   kids.map((kid=>{
	//     const kidAge=allUserAges?.find(data=>data.id==kid.age)
	//     var kidFee=+kidAge?.price
	//     console.log("isPreExistingConditionisPreExistingConditionisPreExistingCondition",kid)
	//     totalFee+=kidFee;

	//     if(kid.isPreExistingCondition=='yes'){
	//       const kidPreExistingFee=(kidFee/100*20)*kid.preExistingArray.length
	//       totalFee+=kidPreExistingFee
	//     }

	//   }))

	// }
	// console.log("totalFeetotalFeetotalFee",totalFee,full_coverage_age,plan,totalFee,getFee);
	// }

	const deleteChildren = index => {
		// console.log(kids);

		if (kids.length > 1) {
			const kidsCopy = [...kids];
			kidsCopy.splice(index, 1);
			setkids([...kidsCopy]);
		}
	};
	// console.log('localStorageUserlocalStorageUser', localStorageUser);
	// console.log({ memberData }, 'sass12212121s');
	// console.log({ kids });
	const handleCloseModel = () => {
		setShowModal(false);
	};
	return (
		<div>
			<Container>
				{localStorageUser?.plans_id &&
					localStorageUser?.is_stripe_configured == 0 && (
						<AddDebitCard setStep={setStep} />
					)}
				{step == 9 && <MemberShip step={step} setStep={setStep} />}
				{localStorageUser?.membership == 1 && <MemberShipSuccess />}
				{localStorageUser?.plans_id &&
				!localStorageUser?.is_stripe_configured ? (
					''
				) : (
					<>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
								position: 'relative',
							}}
						>
							<div
								style={{
									position: 'absolute',
									top: 1,
									left: '-50px',
									zIndex: '9999999',
								}}
							>
								<IoArrowBackCircle onClick={backStep} fontSize={32} />
							</div>
							<span
								style={{
									fontSize: '1.5rem',
									fontWeight: 700,
									marginLeft: '50px',
								}}
							>
								{step == 1 && 'Personal information'}
								{step == 2 || step == 6 ? 'Information details' : ''}
								{step == 3 || step == 4 ? 'Information details' : ''}
								{step == 5 ? (
									<span style={{ fontWeight: '400' }}>Price plan</span>
								) : (
									''
								)}
							</span>
							{step == 1 || step == 3 ? (
								<span
									style={{
										fontSize: '1.5rem',
										color: '#4157FF',
										fontWeight: 700,
									}}
								>
									More Info
								</span>
							) : (
								''
							)}
						</div>
						{step == 1 ? (
							<>
								<OTPscreen
									isShow={true}
									phone_number={user?.phone}
									setStep={setStep}
									step={step}
									setIsContinue={setIsContinue}
								/>
							</>
						) : (
							''
						)}
						{step == 4 && !isContinue ? (
							<>
								<PhoneNumberVerified
									isShown={true}
									setIsContinue={setIsContinue}
								/>
							</>
						) : (
							''
						)}

						{step == 4 && isContinue ? (
							<div style={{ marginLeft: '50px' }}>
								<div>
									<h4>Plan</h4>
									<div>
										<FormGroup>
											<FormControl
												control={<Checkbox defaultChecked />}
												label='Male'
											/>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<FormControlLabel
													required
													checked={memberData?.plan == 'full_coverage'}
													onChange={handleCheckboxChange('plan')}
													value='full_coverage'
													control={<Checkbox />}
													label={
														<span style={{ fontWeight: 'bold' }}>
															Catastrophic + DPC + Meds
														</span>
													}
												/>
												<ThemeProvider theme={theme}>
													<Tooltip
														title={htmlContent}
														placement='right'
														style={{
															padding: 0,
															margin: 0,
															backgroundColor: 'red',
															border: 'none',
															outline: 'none',
														}}
														classes={{ tooltip: classes.customTooltip }}
													>
														<img
															src={Vector}
															alt='Vector'
															width={'18px'}
															height={'18px'}
															style={{
																alignItems: 'center',
																textAlign: 'center',
																cursor: 'pointer',
															}}
														/>
													</Tooltip>
												</ThemeProvider>
											</div>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<FormControlLabel
													required
													checked={memberData?.plan == 'catastrophic'}
													onChange={handleCheckboxChange('plan')}
													value='catastrophic'
													control={<Checkbox />}
													label={
														<span style={{ fontWeight: 'bold' }}>
															Catastrophic
														</span>
													}
												/>
												<ThemeProvider theme={theme}>
													<Tooltip
														title={CatastrophicContent}
														placement='right'
														style={{
															padding: 0,
															margin: 0,
															backgroundColor: 'red',
															border: 'none',
															outline: 'none',
														}}
														classes={{ tooltip: classes.customTooltip }}
													>
														<img
															src={Vector}
															alt='Vector'
															width={'18px'}
															height={'18px'}
															style={{
																alignItems: 'center',
																textAlign: 'center',
																marginLeft: '7px',
																cursor: 'pointer',
															}}
														/>
													</Tooltip>
												</ThemeProvider>
											</div>
										</FormGroup>
										{partnerErrorMessage ? (
											<p className={classes.error}>{partnerErrorMessage}</p>
										) : (
											''
										)}
									</div>
								</div>
								{/*  */}
								<div
									style={{
										display: 'flex',
										width: '700px',
										justifyContent: 'space-between',
									}}
								>
									<div style={{ width: '350px' }}>
										<h4>Gender</h4>
										<FormGroup>
											<FormControl
												control={<Checkbox defaultChecked />}
												label='Male'
											/>
											<FormControlLabel
												required
												checked={memberData?.full_coverage_gender == 'male'}
												onChange={handleCheckboxChange('full_coverage_gender')}
												value='male'
												control={<Checkbox />}
												label={<span style={{ fontWeight: 'bold' }}>Male</span>}
												style={{ width: '20px' }}
											/>
											<FormControlLabel
												checked={memberData?.full_coverage_gender == 'female'}
												onChange={handleCheckboxChange('full_coverage_gender')}
												value='female'
												required
												control={<Checkbox />}
												label={
													<span style={{ fontWeight: 'bold' }}>Female</span>
												}
												style={{ width: '20px' }}
											/>
										</FormGroup>
										{partnerErrorMessage ? (
											<p className={classes.error}>{partnerErrorMessage}</p>
										) : (
											''
										)}
									</div>
									<div>
										<div>
											<h4>Age </h4>

											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DemoContainer components={['DatePicker']}>
													<DatePicker
														label='Select date of birth'
														value={selectedDate}
														disableFuture
														onChange={handleDateChange}
														renderInput={params => (
															<TextField
																{...params}
																views={['day', 'month', 'year']}
																openTo='month'
															/>
														)}
													/>
													{age !== null && <p>Age: {age} years</p>}
												</DemoContainer>
											</LocalizationProvider>

											<FormControl sx={{ m: 1, minWidth: 350, mt: 2 }}>
												<InputLabel id='demo-simple-select-helper-label'>
													Age
												</InputLabel>
												<Select
													labelId='demo-simple-select-helper-label'
													id='demo-simple-select-helper'
													value={memberData?.full_coverage_age}
													label={
														<span style={{ fontWeight: 'bold' }}>Age</span>
													}
													onChange={e => {
														setMemberData({
															...memberData,
															full_coverage_age: e.target.value,
														});
													}}
													disabled={true}
												>
													{patientAges?.map(data => {
														return (
															<MenuItem value={data.id}>{data?.age}</MenuItem>
														);
													})}
												</Select>
											</FormControl>

											{ageErrorMessage ? (
												<p className={classes.error}>{ageErrorMessage}</p>
											) : (
												''
											)}
										</div>
									</div>
								</div>
								{/*  */}
								<div
									style={{
										display: 'flex',
										width: '700px',
										justifyContent: 'space-between',
									}}
								>
									<div sx={{ m: 1, minWidth: 350 }}>
										<h4>Preexisting Condition</h4>
										<FormGroup>
											<FormControl
												control={<Checkbox defaultChecked />}
												label={'Male'}
											/>
											<FormControlLabel
												required
												checked={
													memberData?.full_coverage_pre_existing == 'yes'
												}
												onChange={handleCheckboxChange(
													'full_coverage_pre_existing'
												)}
												value={'yes'}
												control={<Checkbox />}
												label={<span style={{ fontWeight: 'bold' }}>Yes</span>}
												style={{ width: '20px' }}
											/>
											<FormControlLabel
												checked={memberData?.full_coverage_pre_existing == 'no'}
												onChange={handleCheckboxChange(
													'full_coverage_pre_existing'
												)}
												value={'no'}
												required
												control={<Checkbox />}
												label={<span style={{ fontWeight: 'bold' }}>No</span>}
												style={{ width: '20px' }}
											/>
										</FormGroup>
										{partnerErrorMessage ? (
											<p className={classes.error}>{partnerErrorMessage}</p>
										) : (
											''
										)}
									</div>
									<div></div>
								</div>
								{/* Ho many */}
								{memberData?.full_coverage_pre_existing == 'yes' && (
									<div
										style={{
											display: 'flex',
											width: '700px',
											justifyContent: 'space-between',
										}}
									>
										<div>
											<div style={{ width: '300px' }}>
												<Autocomplete
													multiple
													id={`pre-existing-names`}
													options={
														preExistingArray?.length < 5
															? availableOptionsPatient
															: []
													}
													getOptionLabel={option => option.condition}
													value={preExistingArray}
													onChange={(event, newValue) => {
														handlePreexistingChangePatient(newValue);
													}}
													renderInput={params => (
														<TextField
															{...params}
															label='Select Pre-existing Names'
															variant='outlined'
														/>
													)}
												/>
											</div>
											{/* {preExistingArray.map((data, index) => {
                    return (
                      <div>
                        <h4>Preexisting Condition {index + 1} </h4>
                        
                        <FormControl sx={{ m: 0, minWidth: 350 }}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            // value={age}
                            label=""
                            onChange={(e) =>
                              handleChangePreExistingCondition(index, e)
                            }
                            isplayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={menuProps} // Apply the menuProps
                          >
                            <MenuItem
                              value={"Cancer"}
                              sx={{
                                borderBottom: "1px solid #CBCBDD",
                                color: "#CBCBDD",
                                "&:hover": {
                                  color: "black",
                                },
                              }}
                            >
                              Cancer
                            </MenuItem>
                            <MenuItem
                              value={"Diabetes"}
                              sx={{
                                borderBottom: "1px solid #CBCBDD",
                                color: "#CBCBDD",
                                "&:hover": {
                                  // backgroundColor: 'red', // Change the background color on hover
                                  color: "black",
                                },
                              }}
                            >
                              Diabetes
                            </MenuItem>
                            <MenuItem
                              value={"Heart Disease"}
                              sx={{
                                borderBottom: "1px solid #CBCBDD",
                                color: "#CBCBDD",
                                "&:hover": {
                                  color: "black",
                                },
                              }}
                            >
                              Heart Disease
                            </MenuItem>
                            <MenuItem
                              value={"Pregnancy"}
                              sx={{
                                borderBottom: "1px solid #CBCBDD",
                                color: "#CBCBDD",
                                "&:hover": {
                                  color: "black",
                                },
                              }}
                            >
                              Pregnancy
                            </MenuItem>
                            <MenuItem
                              value={"Not Listed"}
                              sx={{
                                borderBottom: "1px solid #CBCBDD",
                                color: "#CBCBDD",
                                "&:hover": {
                                  color: "black",
                                },
                              }}
                            >
                              Not Listed
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {ageErrorMessage ? (
                          <p className={classes.error}>{ageErrorMessage}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })} */}
											<br />
										</div>
										<div></div>
									</div>
								)}
								{/* Ho many */}
								{/*  */}
								<div
									style={{
										display: 'flex',
										width: '710px',
										justifyContent: 'space-between',
									}}
								>
									{/* {memberData.full_coverage_marital_status == "partner" && ( */}
									<div>
										<div style={{ float: 'right' }}>
											<h4 style={{ marginRight: '-9px' }}>
												Would you like your partner/spouse on the plan too?{' '}
											</h4>
											{/* <br/> */}
											<FormGroup>
												<FormControl
													control={<Checkbox defaultChecked />}
													label='Male'
												/>
												<FormControlLabel
													required
													checked={
														memberData.full_coverage_partner_plan == 'yes'
													}
													onChange={handleCheckboxChange(
														'full_coverage_partner_plan'
													)}
													value={'yes'}
													control={<Checkbox />}
													label={
														<span style={{ fontWeight: 'bold' }}>Yes</span>
													}
													style={{ width: '20px' }}
												/>
												<FormControlLabel
													checked={
														memberData.full_coverage_partner_plan == 'no'
													}
													onChange={handleCheckboxChange(
														'full_coverage_partner_plan'
													)}
													value='no'
													required
													control={<Checkbox />}
													label={<span style={{ fontWeight: 'bold' }}>No</span>}
													style={{ width: '20px' }}
												/>
											</FormGroup>
											{partnerErrorMessage ? (
												<p className={classes.error}>{partnerErrorMessage}</p>
											) : (
												''
											)}
										</div>
									</div>
									{/* )} */}
								</div>
								{/*  */}
								{memberData.full_coverage_partner_plan == 'yes' && (
									<>
										<div
											style={{
												display: 'flex',
												width: '700px',
												justifyContent: 'space-between',
											}}
										>
											<div style={{ width: '350px' }}>
												<h4>Spouse/partner gender</h4>
												<FormGroup>
													<FormControl
														control={<Checkbox defaultChecked />}
														label='Male'
													/>
													<FormControlLabel
														required
														checked={
															memberData?.full_coverage_partner_gender == 'male'
														}
														onChange={handleCheckboxChange(
															'full_coverage_partner_gender'
														)}
														value='male'
														control={<Checkbox />}
														label={
															<span style={{ fontWeight: 'bold' }}>Male</span>
														}
														style={{ width: '20px' }}
													/>
													<FormControlLabel
														checked={
															memberData?.full_coverage_partner_gender ==
															'female'
														}
														onChange={handleCheckboxChange(
															'full_coverage_partner_gender'
														)}
														value='female'
														required
														control={<Checkbox />}
														label={
															<span style={{ fontWeight: 'bold' }}>Female</span>
														}
														style={{ width: '20px' }}
													/>
												</FormGroup>
												{partnerErrorMessage ? (
													<p className={classes.error}>{partnerErrorMessage}</p>
												) : (
													''
												)}
											</div>
											<div>
												<div>
													<h4>Spouse/partner age </h4>
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DemoContainer components={['DatePicker']}>
															<DatePicker
																disableFuture
																label='Select date of birth'
																value={partnerDate}
																onChange={handlePartnerDate}
																renderInput={params => (
																	<TextField
																		{...params}
																		views={['day', 'month', 'year']}
																		openTo='month'
																	/>
																)}
															/>
															{partnerAge !== null && (
																<p>Age: {partnerAge} years</p>
															)}
														</DemoContainer>
													</LocalizationProvider>
													<FormControl sx={{ m: 1, minWidth: 350 }}>
														<InputLabel id='demo-simple-select-helper-label'>
															Age
														</InputLabel>
														<Select
															labelId='demo-simple-select-helper-label'
															id='demo-simple-select-helper'
															value={memberData?.full_coverage_partner_age}
															label='Age'
															onChange={e => {
																setMemberData({
																	...memberData,
																	full_coverage_partner_age: e.target.value,
																});
															}}
															disabled={true}
														>
															{patientAges?.map(data => {
																return (
																	<MenuItem value={data.id}>
																		{data?.age}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
													{ageErrorMessage ? (
														<p className={classes.error}>{ageErrorMessage}</p>
													) : (
														''
													)}
												</div>
											</div>
										</div>
										<div sx={{ m: 1, minWidth: 350 }}>
											<h4>Preexisting Condition</h4>
											<FormGroup>
												<FormControl
													control={<Checkbox defaultChecked />}
													label={'Yes'}
												/>
												<FormControlLabel
													required
													checked={
														memberData?.full_coverage_partner_is_pre_existing ==
														'yes'
													}
													onChange={handleCheckboxChange(
														'full_coverage_partner_is_pre_existing'
													)}
													value={'yes'}
													control={<Checkbox />}
													label={
														<span style={{ fontWeight: 'bold' }}>Yes</span>
													}
													style={{ width: '20px' }}
												/>
												<FormControlLabel
													checked={
														memberData?.full_coverage_partner_is_pre_existing ==
														'no'
													}
													onChange={handleCheckboxChange(
														'full_coverage_partner_is_pre_existing'
													)}
													value={'no'}
													required
													control={<Checkbox />}
													label={<span style={{ fontWeight: 'bold' }}>No</span>}
													style={{ width: '20px' }}
												/>
											</FormGroup>
											{partnerErrorMessage ? (
												<p className={classes.error}>{partnerErrorMessage}</p>
											) : (
												''
											)}
										</div>
										<div
											style={{
												display: 'flex',
												width: '700px',
												justifyContent: 'space-between',
											}}
										>
											{memberData?.full_coverage_partner_is_pre_existing ==
												'yes' && (
												<>
													<div style={{ width: '350px' }}>
														<h4>Spouse/partner preexisting condition</h4>
														<div>
															<Autocomplete
																multiple
																id={`pre-existing-names`}
																options={
																	memberData.preExisting.length < 5
																		? availableOptionsSpouse
																		: []
																}
																getOptionLabel={option => option.condition}
																value={memberData.preExisting}
																onChange={(event, newValue) => {
																	handleNameChangeSpouse(newValue);
																}}
																renderInput={params => (
																	<TextField
																		{...params}
																		label='Select Pre-existing Names'
																		variant='outlined'
																	/>
																)}
															/>
														</div>
														{partnerErrorMessage ? (
															<p className={classes.error}>
																{partnerErrorMessage}
															</p>
														) : (
															''
														)}
													</div>
												</>
											)}
										</div>
									</>
								)}
								{/*  */}
								{/* memberData.full_coverage_partner_plan == 'yes' */}
								{
									<div
										style={{
											display: 'flex',
											width: '700px',
											justifyContent: 'space-between',
										}}
									>
										<div style={{ width: '350px' }}>
											<h4>Do you have kids?</h4>
											<FormGroup>
												<FormControl
													control={<Checkbox defaultChecked />}
													label='Male'
												/>
												<FormControlLabel
													required
													checked={memberData.full_coverage_kids == 'yes'}
													onChange={handleCheckboxChange('full_coverage_kids')}
													value={'yes'}
													control={<Checkbox />}
													label={
														<span style={{ fontWeight: 'bold' }}>Yes</span>
													}
													style={{ width: '20px' }}
												/>
												<FormControlLabel
													checked={memberData?.full_coverage_kids == 'no'}
													onChange={handleCheckboxChange('full_coverage_kids')}
													value={'no'}
													required
													control={<Checkbox />}
													label={<span style={{ fontWeight: 'bold' }}>No</span>}
													style={{ width: '20px' }}
												/>
											</FormGroup>
											{partnerErrorMessage ? (
												<p className={classes.error}>{partnerErrorMessage}</p>
											) : (
												''
											)}
										</div>
										<div></div>
									</div>
								}
								{/* Array */}
								{memberData.full_coverage_kids == 'yes' && (
									<>
										{kids?.map((child, index) => {
											const selectedOptionIds = child?.preExistingArray?.map(
												option => option.id
											);
											const availableOptions = allPreConditions.filter(
												option => !selectedOptionIds?.includes(option.id)
											);
											return (
												<div
													style={{
														display: 'flex',
														width: '700px',
														justifyContent: 'space-between',
													}}
												>
													<div style={{ width: '350px' }}>
														<h4>Child {index + 1} Gender</h4>
														<FormGroup>
															<FormControl
																control={<Checkbox defaultChecked />}
																label='Male'
																onChange={e =>
																	handleInputChange(index, 'gender', e.target)
																}
															/>
															<FormControlLabel
																required
																checked={child.gender == 'male'}
																onChange={e =>
																	handleInputChange(index, 'gender', e.target)
																}
																value='male'
																control={<Checkbox />}
																label={
																	<span style={{ fontWeight: 'bold' }}>
																		Male
																	</span>
																}
																style={{ width: '20px' }}
															/>
															<FormControlLabel
																checked={child.gender == 'female'}
																onChange={e =>
																	handleInputChange(index, 'gender', e.target)
																}
																value='female'
																required
																control={<Checkbox />}
																label={
																	<span style={{ fontWeight: 'bold' }}>
																		Female
																	</span>
																}
																style={{ width: '20px' }}
															/>
														</FormGroup>
														{partnerErrorMessage ? (
															<p className={classes.error}>
																{partnerErrorMessage}
															</p>
														) : (
															''
														)}

														<div sx={{ m: 1, minWidth: 350 }}>
															<h4>Preexisting Condition</h4>
															<FormGroup>
																<FormControl
																	control={<Checkbox defaultChecked />}
																	label={'Male'}
																/>
																<FormControlLabel
																	required
																	checked={
																		child.isPreExistingCondition == 'yes'
																	}
																	onChange={e =>
																		handleInputChange(
																			index,
																			'isPreExistingCondition',
																			e.target
																		)
																	}
																	value='yes'
																	control={<Checkbox />}
																	label={
																		<span style={{ fontWeight: 'bold' }}>
																			Yes
																		</span>
																	}
																	style={{ width: '20px' }}
																/>
																<FormControlLabel
																	checked={child.isPreExistingCondition == 'no'}
																	onChange={e =>
																		handleInputChange(
																			index,
																			'isPreExistingCondition',
																			e.target
																		)
																	}
																	value={'no'}
																	required
																	control={<Checkbox />}
																	label={
																		<span style={{ fontWeight: 'bold' }}>
																			No
																		</span>
																	}
																	style={{ width: '20px' }}
																/>
															</FormGroup>
															{partnerErrorMessage ? (
																<p className={classes.error}>
																	{partnerErrorMessage}
																</p>
															) : (
																''
															)}
														</div>
														{child?.isPreExistingCondition == 'yes' && (
															<>
																<div>
																	{/*  ///////////////////// */}

																	{/* ............................................ */}
																	{/* {preExistingArray.map((data, index) => {
                                  // preExistingNames
                                  console.log("dataaaaaaaaaaaaa",data,child)
                                  
                                  const selectedOptionIds = child?.preExistingArray?.map((option) => option.id);
                                  const availableOptions = preExistingNames.filter(
                                    (option) => !selectedOptionIds?.includes(option.id)
                                  ); */}

																	{/* return ( */}
																	<div>
																		<h4>
																			Preexisting Condition Child {index + 1}{' '}
																		</h4>
																		<Autocomplete
																			multiple
																			id={`pre-existing-names-${index + 1}`}
																			options={
																				child.preExistingArray.length < 5
																					? availableOptions
																					: []
																			}
																			getOptionLabel={option =>
																				option.condition
																			}
																			value={child.preExistingArray}
																			onChange={(event, newValue) =>
																				handleNameChange(event, newValue, index)
																			}
																			renderInput={params => (
																				<TextField
																					{...params}
																					label='Select Pre-existing Names'
																					variant='outlined'
																				/>
																			)}
																		/>
																	</div>
																	{/* ); */}
																	{/* })} */}
																	<br />
																</div>
															</>
														)}
													</div>

													<div>
														<div style={{}}>
															<h4>Child {index + 1} Age </h4>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'center',
																	textAlign: 'center',
																	gap: 2,
																}}
															>
																<LocalizationProvider
																	dateAdapter={AdapterDayjs}
																>
																	<DemoContainer components={['DatePicker']}>
																		<DatePicker
																			label='Select date of birth'
																			// maxDate={new Date().getDate()}
																			disableFuture
																			value={child.date_of_birth}
																			onChange={date =>
																				handleChildDate(date, index)
																			}
																			renderInput={params => (
																				<TextField
																					{...params}
																					views={['day', 'month', 'year']}
																					openTo='month'
																				/>
																			)}
																		/>
																		{childAge[index] !== null && (
																			<p>Age: {childAge[index]} years</p>
																		)}
																	</DemoContainer>
																</LocalizationProvider>

																{kids?.length > 1 ? (
																	<div
																		style={{
																			marginTop: '25px',
																			marginLeft: '30px',
																			cursor: 'pointer',
																		}}
																		onClick={() => deleteChildren(index)}
																	>
																		<MdDelete size={24} />
																	</div>
																) : (
																	''
																)}
															</div>
															{ageErrorMessage ? (
																<p className={classes.error}>
																	{ageErrorMessage}
																</p>
															) : (
																''
															)}
														</div>
													</div>
													<br />
												</div>
											);
										})}
										<br />
										<Button
											variant='outlined'
											onClick={() => addChildren()}
											style={{ color: 'black' }}
										>
											<span style={{ textTransform: 'none' }}>
												Add another child
											</span>
										</Button>
										{/*  */}
									</>
								)}
								<br />
								<div style={{ width: '73%', float: 'left' }}>
									<p style={{ fontSize: '15px' }}>
										<span style={{ fontWeight: 'bold' }}>Note: </span>
										DPC Providers are limited to areas of availability. Reduced
										membership costs only begin when you establish care with a
										DPC.
										<br />
										DPC’s memberships are paid in full by HealthCoin Exchange.
										Please go see one immediately after registration.Once
										registered with <br />
										a DPC, you will be eligible for annual membership premium
										reduced membership fees to our platform. Reduced membership
										costs
										<br />
										are not guaranteed and are gifted at HealthCoin Exchange’s
										discretion. Pricing listed in info pop-up above assumes no
										preexisting <br />
										conditions. For catastrophic members the $10,000 minimum
										does not qualify a patient for full access benefits or
										access to the marketplace of providers.
										{/*  */}
									</p>
									<br />
								</div>
							</div>
						) : (
							''
						)}

						{/* {step == 5 ? (
          <div style={{ marginLeft: "50px" }}>
            <PricePlan />
          </div>
        ) : (
          ""
        )} */}

						{step == 6 ? (
							<div style={{ marginLeft: '50px' }}>
								<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>
									Account Holder (You)
								</p>
								<div className={classes.registrationScreenResponsive}>
									<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>Name</p>

									<br />
									<br />
									<TextField
										fullWidth
										id='outlined-basic'
										label='Full name'
										variant='outlined'
										value={user?.name}
										InputProps={{
											style: {
												color: 'black',
											},
											readOnly: true,
										}}
									/>
									<br />
									<br />
									<TextField
										fullWidth
										id='outlined-basic'
										label='Gender'
										variant='outlined'
										value={
											memberData?.full_coverage_gender == 'male'
												? 'Male'
												: 'Female'
										}
										InputProps={{
											style: {
												color: 'black',
											},
											readOnly: true,
										}}
									/>
									<br />
									<br />
								</div>
								<div className={classes.registrationScreenResponsive}>
									<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>
										Details
									</p>
									<TextField
										fullWidth
										id='outlined-basic'
										label='Phone number'
										variant='outlined'
										value={user?.phone}
										type='number'
										InputProps={{
											style: {
												color: 'black',
											},
											readOnly: true,
										}}
									/>
									<br />
									<br />
									<TextField
										fullWidth
										id='outlined-basic'
										label='Email'
										variant='outlined'
										value={user?.email}
										InputProps={{
											style: {
												color: 'black',
											},
											readOnly: true,
										}}
									/>
									<br />
									<br />

									<br />
								</div>
								<br />
								<br />
								{memberData.full_coverage_partner_plan == 'yes' && (
									<>
										<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>
											Spouse/Partner
										</p>
										<div className={classes.registrationScreenResponsive}>
											<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>
												Name
											</p>
											<TextField
												fullWidth
												id='outlined-basic'
												label='First name'
												variant='outlined'
												value={memberData?.partner_first_name}
												onChange={e => {
													setMemberData({
														...memberData,
														partner_first_name: e.target.value,
													});
												}}
											/>
											<br />
											<br />
											<TextField
												fullWidth
												id='outlined-basic'
												label='Last name'
												variant='outlined'
												value={memberData?.partner_last_name}
												onChange={e => {
													setMemberData({
														...memberData,
														partner_last_name: e.target.value,
													});
												}}
											/>
											<br />
											<br />
											<TextField
												fullWidth
												id='outlined-basic'
												label='Gender'
												variant='outlined'
												value={
													memberData?.full_coverage_partner_gender == 'male'
														? 'Male'
														: 'Female'
												}
												InputProps={{
													style: {
														color: 'black',
													},
													readOnly: true,
												}}
												// onChange={(e)=>{setMemberData({...memberData,partner_first_name:e.target.value})}}
											/>
											<br />
											<br />
										</div>
										<div className={classes.registrationScreenResponsive}>
											<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>
												Details
											</p>
											<TextField
												fullWidth
												// type='number'
												id='outlined-basic'
												label='Phone number'
												variant='outlined'
												value={memberData.partner_phone_number}
												onChange={e => {
													const record = e.target.value;
													if (record.length > 10) {
													} else {
														setMemberData({
															...memberData,
															partner_phone_number: e.target.value,
														});
													}
												}}
											/>
											<br />
											<br />
											<TextField
												fullWidth
												id='outlined-basic'
												label='Email'
												variant='outlined'
												type='email'
												value={memberData.partner_email}
												onChange={e => {
													setMemberData({
														...memberData,
														partner_email: e.target.value,
													});
												}}
											/>
											<br />
											<br />

											<br />

											<br />
										</div>
										<br /> <br />
									</>
								)}
								{/* Children */}
								{memberData.full_coverage_kids == 'yes' && (
									<>
										{kids?.map((child, index) => {
											return (
												<>
													<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>
														Child {index + 1}
													</p>
													<b />
													<div className={classes.registrationScreenResponsive}>
														<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>
															Name
														</p>
														<TextField
															fullWidth
															id='outlined-basic'
															label='First name'
															variant='outlined'
															value={child.first_name}
															onChange={e =>
																handleInputChange(index, 'first_name', e.target)
															}
														/>
														<br />
														<br />
														<TextField
															fullWidth
															id='outlined-basic'
															label='Last name'
															variant='outlined'
															value={child.last_name}
															onChange={e =>
																handleInputChange(index, 'last_name', e.target)
															}
														/>
														<br />
														<br />
														<TextField
															fullWidth
															id='outlined-basic'
															label='Gender'
															variant='outlined'
															value={child.gender}
															InputProps={{
																style: {
																	color: 'black',
																},
																readOnly: true,
															}}
														/>
														<br />
														<br />
													</div>
													<div className={classes.registrationScreenResponsive}>
														<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>
															Details
														</p>
														{/* <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Phone number"
                              variant="outlined"
                              value={child.phone_number}
                              type="number"
                              onChange={(e) => {
                                const record = e.target.value;
                                if (record?.length > 10) {
                                  return;
                                } else {
                                  handleInputChange(
                                    index,
                                    "phone_number",
                                    e.target
                                  );
                                }
                              }}
                            /> */}
														<br />
														<br />
														<TextField
															fullWidth
															id={`child-username-${index}`}
															label='Enter UserName'
															type='text'
															variant='outlined'
															value={child.email}
															onChange={e => {
																const inputValue = e.target.value;
																handleInputChange(index, 'email', e.target);
																if (e.target.value.length >= 4) {
																	handleEmailExist(e.target.value, index);
																}
															}}
														/>
														<br />
														<br />

														<br />
														<br />
													</div>
													<br /> <br />
												</>
											);
										})}
									</>
								)}
							</div>
						) : (
							''
						)}

						{step == 7 ? (
							<div style={{ marginLeft: '50px' }}>
								<SummaryPatient
									nextState={nextState}
									memberData={memberData}
									kids={kids}
									allUserAges={allUserAges}
									preExistingArray={preExistingArray}
									setkids={setkids}
									// child_dob={child_dob}
								/>
							</div>
						) : (
							''
						)}
						{step == 8 ? (
							<div style={{ marginLeft: '50px' }}>
								<AddDebitCard
									// nextState={nextState}
									// accountDetailed={accountDetailed}
									// setAccountDetailed={setAccountDetailed}
									// step={step}
									setStep={setStep}
								/>
							</div>
						) : (
							''
						)}
						{step == 9 ? (
							<div style={{ marginLeft: '50px' }}>
								<MemberShip
									// nextState={nextState}
									step={step}
									setStep={setStep}
								/>
							</div>
						) : (
							''
						)}

						{step == 10 ? (
							<div style={{ marginLeft: '50px' }}>
								<MemberShipSuccess nextState={nextState} />
							</div>
						) : (
							''
						)}
						<div style={{ float: 'right' }}>
							{step == 7 ? (
								<p style={{ textAlign: 'center', cursor: 'pointer' }}>
									{' '}
									<TermAndCondition />
								</p>
							) : (
								''
							)}
							{step == 8 || step == 9 || step == 10 ? (
								''
							) : (
								<>
									{step == 4 && (
										<>
											<br />
										</>
									)}
									<Button
										variant='outlined'
										size='large'
										onClick={() => backStep()}
										style={{
											width: '150px',
											color: '#66737F',
											borderColor: '#66737F',
										}}
									>
										BACK
									</Button>{' '}
									&nbsp;&nbsp;
									<Button
										variant='contained'
										color='primary'
										size='large'
										onClick={nextState}
										style={{ width: '150px' }}
									>
										{step == 1 ? 'NEXT' : ''}
										{step == 2 ? 'SUBMIT' : ''}
										{step == 3 ? 'NEXT' : ''}
										{step == 4 ? 'NEXT' : ''}
										{step == 5 ? 'CONTINUE' : ''}
										{step == 6 ? 'CONTINUE' : ''}
										{step == 7 ? 'JOIN' : ''}
									</Button>
								</>
							)}
							<br />
							<br />
						</div>

						<Toaster />
					</>
				)}
			</Container>
			<Modal
				open={showModal}
				onClose={handleCloseModel}
				// onClose={()}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					sx={style}
					style={{
						boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
						borderRadius: '10px',
					}}
				>
					<Typography id='modal-modal-title' variant='h6' component='h2'>
						Attention
					</Typography>
					<IconButton
						onClick={handleCloseModel}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none ',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
					<Typography id='modal-modal-description' sx={{ mt: 2 }}>
						Please contact our support staff to discuss options for registration
						at +1 (484) 291-1574
					</Typography>
					{/* <Button
						onClick={() => {
							setStep(6); 
							setShowModal(false);
						}}
						sx={{ float: 'right' }}
						style={{
							color: 'white',
							fontWeight: 'bold',
							background: 'DodgerBlue',
						}}
					>
						Ok
					</Button> */}
				</Box>
			</Modal>
		</div>
	);
};

export default LoginMemberFlow;
