import { Avatar } from '@mui/material';
import React from 'react';
import './requestdetail.css';
import { deepOrange } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { verifyProviders } from '../../redux/action/user';
import ContactProvider from './ContactProvider';
import TransactionRequest from '../PatientModule/TransactionRequest';
import { useNavigate } from 'react-router-dom';
import Logo from '../../asset/image/Logo.svg';
const RequestDetail = ({
	show,
	name,
	email,
	page,
	id,
	profile_image,
	healthcoin_id,
	verifyButton,
	saveButton,
	downloadAs,
	updateProviderProfile,
	resendCredentials,
	updateSendReminder,
	resendLoginCredentional = false,
	sendReminder = false,
	contactProvider = true,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token } = useSelector(state => state.user);
	const verifyAccount = () => {
		const status = '1';
		dispatch(verifyProviders(id, status, token));
	};

	return (
		<div className='header' style={{ marginTop: '24px' }}>
			{/* {/ <Container> /} */}
			<br />
			{/* <span style={{ marginLeft: '5%', fontSize: '24px', fontWeight: 500 }}>{page}</span> */}
			<br />
			<br />
			<span style={{ marginLeft: '19px', fontSize: '24px', fontWeight: 600 }}>
				{page}
			</span>
			<br />
			<br />
			<div
				style={{
					background: 'white',
					width: '96%',
					marginLeft: '20px',
					padding: '10px',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<div className='child-right'>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '50px',
							height: '50px',
							borderRadius: '50%',
							overflow: 'hidden',
							background: 'black',
						}}
					>
						<img
							src={
								profile_image
									? 'https://api.healthcoinxchangeportal.com/' + profile_image
									: Logo
							}
							alt=''
							// sx={{ width: 60, height: 60, bgcolor: deepOrange[500] }}
							style={{
								borderRadius: '35px ',
								width: '44px',
								height: '44px',

								objectFit: 'cover',
							}}
						/>
					</div>{' '}
					&nbsp;&nbsp;&nbsp;
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							boxSizing: 'border-box',
						}}
					>
						<span style={{ fontWeight: '700' }}>{name}</span>
						<span style={{ color: '#6E757C', fontSize: '14px' }}>{email}</span>
						<span style={{ color: '#6E757C', fontSize: '14px' }}>
							{healthcoin_id && 'HC ID:'} {healthcoin_id}
						</span>
					</div>
				</div>
				{show && (
					<div className='child-right'>
						{verifyButton && (
							<button
								className='verify_account'
								style={{ fontWeight: '700', fontFamily: 'DM Sans' }}
								onClick={() => verifyAccount()}
							>
								Verify Account
							</button>
						)}
						{saveButton && (
							<button className='save_button' onClick={updateProviderProfile}>
								Save
							</button>
						)}
						{resendLoginCredentional && (
							<button
								className='save_button'
								style={{
									backgroundColor: '#4CD964',
									width: '230px',
									height: '46px',
								}}
								onClick={resendCredentials}
							>
								Resend Login Credentials
							</button>
						)}
						{sendReminder && (
							<button
								className='save_button'
								style={{ width: '142px', height: '46px' }}
								onClick={updateSendReminder}
							>
								Send Reminder
							</button>
						)}

						{downloadAs && (
							<>
								{/* <span
                  style={{
                    color: "#66737F",
                    fontSize: "16px",
                    marginTop: "-30px",
                    marginRight: "-153px",
                  }}
                >
                  Transaction History
                </span> */}
								{/* <TransactionRequest className='save_button' /> */}
								<button
									className='save_button'
									onClick={() => navigate(`/TransactionDetailsPage/${id}`)}
								>
									View Transactions
								</button>
							</>
						)}

						{contactProvider ? (
							<ContactProvider name={name} email={email} />
						) : (
							''
						)}

						{/* <button className='contact-provider' style={{fontWeight:'700',fontFamily:'DM Sans'}}>Contact Provider</button> */}
					</div>
				)}
			</div>

			{/* {/ </Container > /} */}
		</div>
	);
};

export default RequestDetail;
