import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import React, { useState } from 'react'
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import './ContactSupport.css'

const useStyles = makeStyles({

  buttonAddnewHelp: {
    fontSize: "24px",
    fontWeight: "500",

  },

  headingSettingTest: {
    fontSize: "24px",
    fontWeight: "500",

  }

});

const ContactSupport = () => {

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  return (
    <div>
      <Button
        // style={{
        //   width: '90px',
        //   fontSize: '15px',
        //   fontWeight: 700,
        //   borderRadius: '24px',
        //   color: 'white',
        // }}
        style={{ width: '90px', fontSize: '15px', fontWeight: 700, marginTop: '260px', height: '46px', borderRadius: '24px', textTransform: 'none', color: 'white', background: '#4157FF ' }}
        variant="outlined" color="primary" onClick={handleOpen}>
        HELP
      </Button>
      <Dialog open={open} onClose={handleClose}  >
        {" "}
        <DialogTitle className={classes.buttonAddnewHelp}>
          <span className={classes.headingSettingTest}>Contact Support</span>
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <Box
              style={{
                background: "#4157FF",
                width: "42px",
                height: "42px",
                fontSize: "11px  ",
                boxShadow: "none ",
                borderRadius: "24px",
              }}
             
            >
              <CloseIcon sx={{ margin: "8px 5px", color: "white" }} />
            </Box>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ marginTop: "10px" }} style={{ Width: "550px", height: '511PX' }}>
          {" "}
          {/* sx={{ width: '500px' }} */}

          <Box sx={{ display: 'flex', gap: '70px', flexDirection: 'column', justifyContent: "center", marginTop: '72px' }}>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: "14px ", fontWeight: "700" ,color:'#525F7F' }}
                gutterBottom
              >
                Email Us At:
              </Typography>
              <TextField
                disabled
                margin="dense"
                // placeholder="info.healthcoin@gethealthcoin.com"
                value={'info.healthcoin@gethealthcoin.com'}
                fullWidth
                InputProps={{
                  style: { height: '31px' },
                  // disabled: true,
                }}
                sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", width: '489px' }}
              />{" "}
            </Box>

            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: "14px ", fontWeight: "700", color: '#525F7F' }}
                gutterBottom
              >
                Call Support
              </Typography>
              <TextField
                margin="dense"
                disabled
                // placeholder="+1 (813)843-1155"
                value={"(484)291-1574"}
                fullWidth
                InputProps={{
                  style: {
                    height: '31px',
                    // disabled: true,
                  }
                }}
                sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", width: '489px' }}
              />
            </Box>

          </Box>



        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContactSupport;
