import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Divider,
	Box,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
	buttonAddnew: {
		fontSize: '24px',
		fontWeight: '500',
	},
});

const InitiateBiling = ({
	handleClose,
	handleOpen,
	setOpen,
	open,
	nextBillingStep,
}) => {
	const BillingOption = {
		width: '153px',
		height: '46px',
		borderRadius: '24px',
		textTransform: 'none',
		color: 'white',
		background: '#4157FF',
		fontWeight: 700,
		fontSize: '15px',
	};

	// console.log("openopen",open)

	const classes = useStyles();

	return (
		<div>
			{/* <Button
        style={{
          width: "120px",
          height: "46px",
          borderRadius: "24px",
          textTransform: "none",
          color: "white",
         

        }}
        variant="outlined"
        color="primary"
        onClick={handleOpen}
      >
        Initial Biling
      </Button> */}
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth='md'
				style={{ borderRadius: '10px' }}
			>
				{' '}
				{/* maxWidth="sm" ,md,lg */}
				<DialogTitle className={classes.buttonAddnew}>
					<IconButton
						onClick={handleClose}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{ marginTop: '10px' }}
					style={{ minWidth: '550px', height: 'auto', overflow: 'auto' }}
				>
					<Box
						sx={{
							display: 'flex',
							gap: '43px',
							margin: '1px 22px',
							marginTop: '62px',
							flexDirection: 'column',
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<span
							style={{ fontSize: '24px', color: '#32325D', fontWeight: 600 }}
						>
							Initiate Billing
						</span>
						<span
							style={{
								width: '357px',
								height: '55px',
								// textAlign: "justify",
								fontSize: '13px',
								margin: '1px 22px',
								fontWeight: '600',
								marginLeft: '58px',
							}}
						>
							Note: Initiating payment with auto-debit will charge your account
							ONLY once your imported employees have fully onboarded. At any
							time you may swipe the blue tab next to an employee to pause their
							benefits and payment.
						</span>
					</Box>
					<Box
						sx={{
							display: 'grid',
							justifyContent: 'center',
							alignContent: 'center',
							marginTop: '285px',
							flexDirection: 'column',
						}}
					>
						<Button style={BillingOption} onClick={nextBillingStep}>
							Billing Options
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default InitiateBiling;
