import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Divider,
  Container,
  Box,
  Grid,
} from "@mui/material";
import TranscationHealthcoinLogo from "../../asset/image/TranscationHealthcoinLogo.svg";
import { toast, Toaster } from "react-hot-toast";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Sidebar from "../Layout/Sidebar/Sidebar";
import Header from "../Layout/Header/Header";
import axios from "axios";
import { server } from "../../redux/store";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { encryptData, decryptData } from '../../redux/incript&decript/cryptoUtils';
const useStyles = makeStyles({
  transactionHeading: {
    fontSize: "13px",
    fontWeight: "600",
  },
  transactionNumber: {
    fontSize: "12px",
    fontWeight: "700",
    opacity: ".7",
    textTransform: "uppercase",
  },
  reqNumber: {
    fontSize: "11px",
    fontWeight: "600",
  },
  profileData: {},
  dateTransaction: {
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "700",

    opacity: ".5",
  },
  dateTra: {
    fontSize: "14px",
    color: "black",
    fontWeight: "500",
  },
});

const TransactionRequest = () => {
  const sendPayment = {
    width: "153px",
    height: "40px",
    color: "white",
    background: "#4157FF",
    borderRadius: "21px",
    fontSize: "14px",
    border: "none",
    cursor: "pointer",
    fontWeight: "700",
  };
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState("TRANSACTIONS");
  const [transactionData, setTransactionData] = useState(null);
  const { token } = useSelector((state) => state.user);
  const { provider_id } = useParams()
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getTransactionData();
  }, [])
  const getTransactionData = async () => {
    try {
      const token=`Bearer ${localStorage.getItem('token')}`;
      const { data } = await axios.get(`${server}/provider_transaction_history_PDF/?provider_id=${provider_id}`, {
        headers: { Authorization: token, "Content-Type": "application/json" },
      });
      const decryptedData = decryptData(data.encrypted_data);
     
      setTransactionData(decryptedData);
    } catch (err) {
      // console.log("error", err.response.data);
    }
  };

  // download the Pdf
  const handleDownloadPDF = () => {
    const input = document.getElementById("pdf-content");

    const options = {
      dpi: 300, // Adjust the DPI value (e.g., 150, 300, etc.)
      scale: 2, // Adjust the scale value to increase the resolution
      useCORS: true, // Enable cross-origin resource sharing if needed
      allowTaint: true, // Allow tainting of the canvas (may be required if your content contains images from other domains)
    };

    html2canvas(input, options).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4"); // Set the document size and orientation as per your requirements
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("Transaction-History.pdf");
    });
  };

  return (
    <>
      {/* <button
                style={sendPayment}
                onClick={handleOpen}>
                View PDF
            </button> */}
      <div className="home">
        <div>
          <Sidebar showSidebar={false} showHelpButton={false} />
        </div>
        <div className="homeContainer" style={{ height: "fit-content" }}>
          <Header
            showHeader={true}
            currentPath=<span
              style={{
                marginLeft: "10px",
                fontSize: "12px",
                fontWeight: "500",
                color: "#D0D1D2",
              }}
            >
              {path}
            </span>
          />
          <Container
            style={{
              position: "relative",
              marginTop: "115px",
              background: "#f8f9fe",
            }}

          // open={open} onClose={handleClose} maxWidth="xl"
          >
            <Grid
              style={{
                width: '75vw',
                background: "white",
                boxSizing: "border-box",
                borderRadius: "10px",
                boxShadow: "box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;",
              }}
            >
              <div id="pdf-content">
                <Box
                  sx={{ marginTop: "10px" }}
                // style={{ width: "1097px", height: "auto" }}
                >
                  <Box sx={{ margin: "22px" }}>


                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: '22px',
                        paddingTop: '41px',
                        width: '67vw'
                      }}
                    >
                      <Box>
                        <img src={TranscationHealthcoinLogo} alt="" />
                      </Box>

                      <Box sx={{ display: "flex", flexDirection: 'column' }}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "700",
                            opacity: ".7",

                          }}
                        >
                          TRANSACTION NUMBER
                        </span>
                        <span
                          style={{
                            fontSize: "24px",
                            fontWeight: "700",
                            display: 'flex',
                            justifyContent: 'flex-end'

                          }}
                        >
                          #{("" + Math.random()).substring(2, 7)}
                        </span>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "30px",
                        marginTop: "30px",
                        marginLeft: "32px",
                        width: '67vw'
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "grey",

                            }}
                          >
                            FROM
                          </span>

                        </div>
                      </Box>
                      <Box sx={{ marginTop: "10px" }}>
                        <Divider style={{ width: "28vw" }} />
                      </Box>
                      <Box>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "grey",
                            }}
                          >
                            BILL
                          </span>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "grey",
                            }}
                          >
                            TO
                          </span>
                        </div>

                      </Box>
                      <Box sx={{ marginTop: "10px" }}>
                        <Divider style={{ width: "24vw" }} />
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", gap: "179px" }}>
                      {/*left side of healthcoin exchange */}
                      <Box sx={{ margin: "20px 54px" }}>
                        <Box sx={{ display: "grid" }}>
                          <span className={classes.transactionHeading}>
                            {transactionData?.bill_to?.name}
                          </span>
                          <span className={classes.transactionHeading}>
                            {transactionData?.bill_to?.company_address}
                          </span>
                          {/* <span className={classes.transactionHeading}>
                            New York, NY 10128
                          </span> */}
                          <span className={classes.transactionHeading}>
                            {transactionData?.bill_to?.country}
                          </span>

                          <Box
                            sx={{
                              marginTop: "10px",
                              display: "flex",
                              gap: "25px",
                            }}
                          >
                            <Box>
                              <Box>
                                <span className={classes.transactionNumber}>
                                  Company Number
                                </span>
                              </Box>

                              <Box
                                sx={{
                                  display: "grid",
                                  marginTop: "15px",
                                  gap: "10px",
                                }}
                              >
                                <span className={classes.transactionNumber}>
                                  VAT NUMBER
                                </span>
                                <span className={classes.transactionNumber}>
                                  Contact
                                </span>
                              </Box>
                            </Box>

                            <Box>
                              <Box sx={{ display: "grid" }}>
                                <Box sx={{ display: "grid" }}>
                                  <span className={classes.reqNumber}>
                                    {transactionData?.bill_to?.phone}&nbsp;
                                  </span>
                                  {/* <span className={classes.reqNumber}>
                                    GB938572048
                                  </span> */}
                                </Box>
                                <Box sx={{ display: "grid" }}>
                                  <Box
                                    sx={{ display: "grid", marginTop: "3px" }}
                                  >
                                    <span className={classes.reqNumber}>
                                      &nbsp;
                                    </span>
                                    <span className={classes.reqNumber}>
                                      {transactionData?.bill_to?.name}&nbsp;
                                    </span>
                                    <span className={classes.reqNumber}>
                                      {transactionData?.bill_to?.email}&nbsp;
                                    </span>
                                  </Box>

                                  <Box sx={{ marginTop: "13px" }}>
                                    <span className={classes.reqNumber}>
                                      The supply is tax exempt.
                                    </span>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      {/* right side of healthcoin exchange */}
                      <Box sx={{ marginTop: "19px", marginLeft: '78px', }}>
                        <Box sx={{ display: "grid" }}>
                          <span className={classes.transactionHeading}>
                            {transactionData?.paid_from?.name}&nbsp;
                          </span>
                          <span className={classes.transactionHeading}>
                            {transactionData?.paid_from?.location}&nbsp;
                          </span>
                          {/* <span className={classes.transactionHeading}>
                            New York, NY 10128
                          </span> */}
                          <span className={classes.transactionHeading}>
                            {transactionData?.paid_from?.user_state?.name} &nbsp;
                          </span>

                          <Box
                            sx={{
                              marginTop: "10px",
                              display: "flex",
                              gap: "25px",
                            }}
                          >
                            <Box>
                              <Box sx={{ display: "grid" }}>
                                <span className={classes.transactionNumber}>
                                  Tax ID
                                </span>
                                <span className={classes.transactionNumber}>
                                  ID
                                </span>
                                <span className={classes.transactionNumber}>
                                  BANK
                                </span>
                                <span className={classes.transactionNumber}>
                                  Payment form
                                </span>
                                <span className={classes.transactionNumber}>
                                  IBAN
                                </span>
                                <span className={classes.transactionNumber}>
                                  SWIFT
                                </span>
                                <span className={classes.transactionNumber}>
                                  Constant symbol
                                </span>
                              </Box>
                            </Box>

                            <Box>
                              <Box sx={{ display: "grid" }}>
                                <Box sx={{ display: "grid" }}>
                                  <span className={classes.reqNumber}>
                                    &nbsp;
                                  </span>
                                  <span className={classes.reqNumber}>
                                    {transactionData?.bankDetails?.id}&nbsp;
                                  </span>
                                  <span className={classes.reqNumber}>
                                    {transactionData?.bankDetails?.bank_name}&nbsp;
                                  </span>
                                  <span className={classes.reqNumber}>
                                    Bank transfer&nbsp;
                                  </span>
                                  <span className={classes.reqNumber}>
                                    {transactionData?.bankDetails?.account_number}&nbsp;
                                  </span>
                                  <span className={classes.reqNumber}>
                                    &nbsp;
                                  </span>
                                  <span className={classes.reqNumber}>
                                    &nbsp;
                                  </span>
                                  <span className={classes.reqNumber}>
                                    &nbsp;
                                  </span>
                                  <span className={classes.reqNumber}>
                                    {" "}
                                    Not VAT registered
                                  </span>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* description and amount divider line */}
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        marginTop: "30px",
                        marginLeft: "32px",
                        width: '67vw'
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "grey",
                            textTransform: "uppercase",
                          }}
                        >
                          Description
                        </span>
                      </Box>
                      <Box sx={{ width: "54.7vw", marginTop: "10px" }}>
                        <Divider />
                      </Box>
                      <Box>
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "grey",
                            textTransform: "uppercase",
                          }}
                        >
                          Amount
                        </span>
                      </Box>
                    </Box>

                    {/* below description and amount */}

                    <Box
                      sx={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: "space-between",
                        marginLeft: "32px",
                        width: '67vw'
                      }}
                    >
                      {transactionData?.description?.map((d1) => {
                        return <>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                              <Box sx={{ display: "grid", gap: "2px" }}>
                                <Box>
                                  <span className={classes.transactionHeading}>
                                    Procedure No.
                                  </span>
                                  <span className={classes.transactionHeading}>
                                    &nbsp;
                                  </span>
                                </Box>

                                <Box>
                                  <span className={classes.transactionHeading}>
                                    Consultation for {d1?.patient?.name}&nbsp; HC ID No.
                                  </span>
                                  <span className={classes.transactionHeading}>
                                    {d1?.patient?.healthcoin_id}
                                  </span>
                                </Box>
                                <span className={classes.transactionHeading}>
                                  {d1?.consultant?.consultation_name} {d1?.billing_details?.map(item => <span>\ {item?.service_name}&nbsp;</span>)}
                                </span>
                                <Box>
                                  <span style={{ fontSize: "20px", marginTop: '-55px', fontWeight: "500", float: 'right' }}>
                                    {/* ${d1?.consultant?.consultation_price+(d1?.billing_details.reduce((accumulator, item) => {return accumulator + item.price}, 0))} */}
                                    ${d1?.hc_bill}
                                  </span>
                                </Box>
                              </Box>

                              {/* total price divider line */}
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "8px",
                                  marginTop: "30px",


                                  width: '67vw'

                                }}
                              >
                                <Box sx={{ marginTop: "10px" }}>
                                  <Divider style={{ width: "60vw", }} />
                                </Box>
                                <Box>
                                  <div style={{ display: "flex", gap: "7px" }}>
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "grey",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      Total
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "grey",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      {" "}
                                      Price
                                    </span>
                                  </div>
                                </Box>
                              </Box>
                              {/* Date */}
                            </div>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "30px",
                                marginTop: "10px",
                                marginLeft: "0px",

                              }}
                            >
                              <Box sx={{ display: "flex", gap: "5px" }}>
                                <span className={classes.dateTransaction}>
                                  Inovice date
                                </span>
                                <span className={classes.dateTra}>{d1?.bill_added_date}</span>
                              </Box>

                              <Box sx={{ display: "flex", gap: "5px" }}>
                                <span className={classes.dateTransaction}>
                                  procedure date
                                </span>
                                <span className={classes.dateTra}>{d1?.bill_added_date}</span>
                              </Box>
                              <br />
                              <Box sx={{ display: "flex", gap: "5px" }}>
                                <span className={classes.dateTransaction}>
                                  {" "}
                                  Payment paid
                                </span>
                                <span className={classes.dateTra}>{d1?.bill_added_date}</span>
                                <span className={classes.dateTra}><b>${d1?.hc_bill}</b></span>
                              </Box>
                            </Box>

                          </div>
                        </>
                      }
                      )}

                    </Box>

                    {/* total price divider line
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        marginTop: "30px",
                        marginLeft: "32px",
                      }}
                    >
                      <Box sx={{ marginTop: "10px" }}>
                        <Divider style={{ width: "57.5vw" }} />
                      </Box>
                      <Box>
                        <div style={{ display: "flex", gap: "7px" }}>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "grey",
                              textTransform: "uppercase",
                            }}
                          >
                            Total
                          </span>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "grey",
                              textTransform: "uppercase",
                            }}
                          >
                            {" "}
                            Price
                          </span>
                        </div>
                      </Box>
                    </Box> */}

                    {/* Date */}
                    {/* 
                    <Box
                      sx={{
                        display: "flex",
                        gap: "30px",
                        marginTop: "10px",
                        marginLeft: "32px",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <span className={classes.dateTransaction}>
                          Inovice date
                        </span>
                        <span className={classes.dateTra}>31.01.2022</span>
                      </Box>

                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <span className={classes.dateTransaction}>
                          procedure date
                        </span>
                        <span className={classes.dateTra}>31.01.2022</span>
                      </Box>

                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <span className={classes.dateTransaction}>
                          {" "}
                          Payment paid
                        </span>
                        <span className={classes.dateTra}>15.02.2022</span>
                        <span className={classes.dateTra}>$850</span>
                      </Box>
                    </Box> */}

                    {/* Bank detail, bank account number,variable symbol */}

                    <Box
                      sx={{
                        width: "67vw",
                        marginLeft: "32px",
                        marginTop: "40px",
                        height: "97px",
                        border: "2px solid black",
                        borderRadius: "15px",
                      }}
                    >
                      <Box sx={{ marginTop: "20px", marginLeft: "40px" }}>
                        <Box sx={{ display: "flex", gap: "206px" }}>
                          <Box sx={{ display: "grid" }}>
                            <Box sx={{ marginLeft: "35px" }}>
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  textTransform: "uppercase",
                                  opacity: "0.5",
                                }}
                              >
                                Bank account number / IBAN
                              </span>
                            </Box>
                            <Box>
                              <span
                                style={{ fontSize: "20px", fontWeight: "700", marginLeft:'30px' }}
                              >
                                {transactionData?.bankDetails?.account_number}
                                &nbsp; ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;****
                              </span>
                            </Box>
                          </Box>

                          <Box sx={{ display: "grid" }}>
                            <Box>
                              <span
                                style={{
                                  fontSize: "12px",
                                  textTransform: "uppercase",
                                  fontWeight: "700",
                                  opacity: "0.5",
                                }}
                              >
                                Variable symbol
                              </span>
                            </Box>
                            <Box sx={{ marginLeft: "26px" }}>
                              <span
                                style={{ fontSize: "20px", fontWeight: "700" }}
                              >
                                &nbsp;
                              </span>
                            </Box>
                          </Box>

                          <Box sx={{ display: "grid", marginLeft: "0px" }}>
                            <Box sx={{ display: "flex", gap: "5px" }}>
                              <span
                                style={{
                                  fontSize: "12px",
                                  textTransform: "uppercase",
                                  fontWeight: "700",
                                  opacity: "0.5",
                                }}
                              >
                                Total{" "}
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  textTransform: "uppercase",
                                  fontWeight: "700",
                                  opacity: "0.5",
                                }}
                              >
                                amount to pay{" "}
                              </span>
                            </Box>
                            <Box sx={{ marginLeft: "40px" }}>
                              <span
                                style={{ fontSize: "20px", fontWeight: "700" }}
                              >
                                ${(transactionData?.description?.reduce((accumulator, current) => accumulator + current.hc_bill, 0))?.toFixed(2)}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "40px",
                  justifyContent: "flex-end",
                  marginRight: "73px",
                }}
              >
                <button
                  style={{
                    width: "155px",
                    height: "45px",
                    color: "white",
                    background: "#4157FF",
                    borderRadius: "21px",
                    fontSize: "14px",
                    border: "none",
                    cursor: "pointer",
                    fontWeight: "700",
                  }}
                  onClick={handleDownloadPDF}
                >
                  Download as PDF
                </button>
              </Box>
              <br />
              <br />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "0px 0px 36px",
                }}
              ></Box>
            </Grid>
            <br />
            <br />
          </Container>
        </div>
      </div>
    </>
  );
};

export default TransactionRequest;
