import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	IconButton,
	Typography,
	Divider,
	Box,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
	buttonAddnew: {
		fontSize: '24px',
		fontWeight: '500',
	},
});

const ExcelHeader = () => {
	const [open, setOpen] = useState(false);

	const continueButtons = {
		width: '137px',
		height: '46px',
		borderRadius: '24px',
		textTransform: 'none',
		color: 'white',
		background: '#4157FF ',
		fontWeight: 'bold',
	};
	// css
	const infoFile = {
		width: 'auto',
		height: '49px',
		borderRadius: '24px',
		textTransform: 'none',
		color: 'white',
		fontSize: '15px',
		fontWeight: '700',
		background: '#4157FF  ',
		marginLeft: '12px',
	};

	const dumyExcelFile = {
		width: 'auto',
		height: '49px',
		borderRadius: '24px',
		textTransform: 'none',
		color: 'white',
		fontSize: '15px',
		fontWeight: '600',
		background: '#4157FF ',
		marginLeft: '12px',
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const classes = useStyles();

	// CSV File

	const downloadCSV = () => {
		const data = [['Email'], ['alex@yopmail.com'], ['jhon@yopmail.com']];

		const csvContent =
			'data:text/csv;charset=utf-8,' +
			data.map(row => row.join(',')).join('\n');
		const encodedUri = encodeURI(csvContent);

		// Create a link element and set its attributes
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'members.csv');

		// Append the link to the document and click it
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<div>
			{/* <Button
				style={infoFile}
				variant='outlined'
				color='primary'
				onClick={handleOpen}
			>
				Info File
			</Button> */}
			<Button
				style={dumyExcelFile}
				variant='outlined'
				color='primary'
				onClick={downloadCSV}
			>
				Download Example File
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth='md'>
				{' '}
				{/* maxWidth="sm" ,md,lg */}
				<DialogTitle className={classes.buttonAddnew}>
					<IconButton
						onClick={handleClose}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{ marginTop: '10px' }}
					style={{ minWidth: '574px', height: '491px' }}
				>
					<Box
						sx={{
							display: 'flex',
							gap: '43px',
							margin: '1px 22px',
							marginTop: '62px',
							flexDirection: 'column',
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<span style={{ fontSize: '24px', color: '#FF5652' }}>
							Adjust Excel Headers
						</span>
						<span
							style={{
								width: '337px',
								height: '55px',
								textAlign: 'center',
								fontSize: '13px',
								margin: '1px 62px',
								fontWeight: '600',
								color: '#FF0436',
							}}
						>
							Headers must include all employee Email Addresses. Please modify
							your spreadsheet to include the following then re-submit.
						</span>
					</Box>
					<Box
						sx={{
							display: 'grid',
							justifyContent: 'center',
							alignContent: 'center',
							marginTop: '218px',
							flexDirection: 'column',
						}}
					>
						<Button style={continueButtons} onClick={handleClose}>
							Continue
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ExcelHeader;
