import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Container, TextField } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { AddPatientDebitCard } from '../../redux/action/user';
import { useEffect } from 'react';
import { server } from '../../redux/store';
import {
	decryptData,
	encryptData,
} from '../../redux/incript&decript/cryptoUtils';
import axios from 'axios';
import Stripe from '../stripe';

const AddDebitCard = ({
	// nextState,
	// accountDetailed1,
	// setAccountDetailed1,
	// step,
	setStep,
}) => {
	const removeBorder = {
		border: 'none',
	};
	const dispatch = useDispatch();
	const [openPhoneVerifiedModal, setOpenPhoneVerifiedModal] =
		React.useState(true); // OTP Verification Modal
	const [open, setOpen] = React.useState(false); // success Phone Verification Modal
	const handleOpen = () => setOpen(true);
	const handleOpenPhoneModal = () => setOpenPhoneVerifiedModal(true);
	const handleClosePhoneModal = () => setOpenPhoneVerifiedModal(false);
	const [accountDetailed, setAccountDetailed] = useState({
		debit_card: '',
		month: '',
		year: '',
		cvc: '',
	});
	const handleChange = e => {
		const { name, value } = e.target;
		if (name == 'cvc') {
			if (value.length > 3) {
			} else {
				setAccountDetailed({
					...accountDetailed,
					[name]: value,
				});
			}
		} else if (name == 'month') {
			if (value.length > 2) {
			} else {
				setAccountDetailed({
					...accountDetailed,
					[name]: value,
				});
			}
		} else if (name == 'year') {
			if (value.length > 4) {
			} else {
				setAccountDetailed({
					...accountDetailed,
					[name]: value,
				});
			}
		} else if (name == 'debit_card') {
			if (value.length > 16) {
			} else {
				setAccountDetailed({
					...accountDetailed,
					[name]: value,
				});
			}
		}
	};

	const handleSubmit = async (result) => {
		// if (!accountDetailed || accountDetailed?.debit_card?.length < 16) {
		// 	return toast.error('Please enter valid debit card number');
		// }
		// if (!accountDetailed || accountDetailed?.month?.length < 1) {
		// 	return toast.error('Please enter valid month');
		// }
		// if (!accountDetailed || accountDetailed?.year?.length < 4) {
		// 	return toast.error('Please enter valid year');
		// }
		// if (!accountDetailed || accountDetailed?.cvc < 3) {
		// 	return toast.error('Please enter valid cvc number');
		// }
		// dispatch(AddPatientDebitCard(accountDetailed, step, setStep));
		
		const token = `Bearer ${localStorage.getItem('token')}`;
		console.log(result.token.id)
		
		// const { debit_card, month, year, cvc } = accountDetailed;
		try {
			const { data } = await axios.post(
				`${server}/add_billing_info_patinet`,
				{
					encrypted_data: encryptData({
						stripe_token: result.token.id
					}),
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: token,
					},
				}
			);
			const dec = decryptData(data.encrypted_data);
			// localStorage.setItem('user', JSON.stringify(dec));

			if (dec?.status || dec.message === 'Card saved successfully.') {
				setStep(9);
			} else {
				toast.info(dec?.message);
				return;
			}
		} catch (err) {
			console.log(err);

			toast.error('Failded to add card');
		}
		// nextState()
	};

	return (
		<div>
			<Container>
				<Modal
					//  className={classes.modal}

					open={openPhoneVerifiedModal}
					// onClose={handleClosePhoneModal}
					aria-labelledby='modal-title'
					aria-describedby='modal-description'
					BackdropProps={{
						timeout: 500,
						style: { backdropFilter: 'blur(2px)' }, 
					}}
				>
					<Box style={removeBorder}>
						<Box
							sx={{
								position: 'absolute',
								borderRadius: '1rem',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: '445px',
								height: '500px',
								margin: 'auto',
								bgcolor: 'background.paper',
								boxShadow: 24,
								p: 4,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Box>
								<Box style={{ margin: 'auto', width: '100%' }}>
									{/* <p
										style={{
											fontSize: '22px',
											fontWeight: 800,
											textAlign: 'center',
										}}
									>
										Add Debit Card
									</p>
									<br /> */}
								</Box>
								<Stripe handleSubmit={handleSubmit} />
								{/* <Box>
									<p>Credit Card Number</p>
									<TextField
										type='number'
										name='debit_card'
										value={accountDetailed.debit_card}
										onChange={handleChange}
										placeholder='Enter Credit Card Number'
										style={{ width: '100%' }}
										inputProps={{
											maxLength: 16,
										}}
									/>
								</Box>
								<Box>
									<p>Expiration Date</p>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											gap: 4,
										}}
									>
										<TextField
											type='number'
											name='month'
											value={accountDetailed.month}
											onChange={handleChange}
											placeholder='Month'
											inputProps={{
												maxLength: 2,
											}}
										/>

										<TextField
											type='number'
											name='year'
											value={accountDetailed.year}
											onChange={handleChange}
											placeholder='Year'
											inputProps={{
												maxLength: 4,
											}}
										/>
									</div>
								</Box>
								<Box>
									<p>CVC</p>
									<TextField
										type='number'
										name='cvc'
										value={accountDetailed.cvc}
										onChange={handleChange}
										placeholder='CVC'
										style={{ width: '100%' }}
										inputProps={{
											maxLength: 3,
										}}
									/>
								</Box> */}
								<br />
								{/* <Box style={{ width: '100%', margin: 'auto' }}>
									<Button
										style={{
											width: '100%',
											borderRadius: '20px',
											height: '45px',
										}}
										variant='contained'
										color='primary'
										onClick={handleSubmit}
									>
										Proceed
									</Button>
								</Box> */}
							</Box>
						</Box>
					</Box>
				</Modal>
				<Toaster />
			</Container>
		</div>
	);
};

export default AddDebitCard;
