import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Container, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MemberDetails from './MemberDetails';
import Sidebar from '../../Layout/Sidebar/Sidebar';
import Header from '../../Layout/Header/Header';
import { useEffect } from 'react';
import axios from 'axios';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { server } from '../../../redux/store';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import delete_icon from '../../../asset/image/delete_icon.png';
import StatusHandling from '../../StatusHandling/StatusHandling';
import Spinner from '../../SpinnerTrueFalse/Spinner';
import PaymentGuidanceModel from './PaymentGuidanceModel';
import GooglePlacesAutocomplete, {
	geocodeByPlaceId,
	getLatLng,
} from 'react-google-places-autocomplete';
import {
	updatePatientFamilyPlan,
	EnterprisePayPatientFamily,
	resendCredentialsPatient,
} from '../../../redux/action/user';
import { useDispatch, useSelector } from 'react-redux';
import {
	encryptData,
	decryptData,
} from '../../../redux/incript&decript/cryptoUtils';
const useStyles = makeStyles({
	labelstyling: {
		fontWeight: '500 !important',
		fontSize: '16px !important',
		color: '#000000 !important',
		fontFamily: 'DM sans !important',
	},
	companySet: {
		marginLeft: '17px',
		fontWeight: 600,
		fontSize: '24px',
	},
	tabsetting: {
		fontWeight: '600 !important',
		fontSize: '16px !important',
		textTransform: 'none !important',
		color: '#949494 !important',
		'&:focus': {
			color: '#000000 !important',
		},
		'&:active': {
			color: '#000000 !important',
		},
	},
	memberBoldFont: {
		fontWeight: 600,
	},
});
const MemberDetailsPage = props => {
	// css
	const headLabel = {
		fontWeight: '600',
		fontSize: '14px',
	};
	const boxStyling = {
		width: '100%',
		borderRadius: '2px ',
		margin: 'auto ',
		backgroundColor: 'white ',
		display: 'flex ',
		justifyContent: 'space-between ',
	};
	const boxStylingFamily = {
		width: '100%',
		minHeight: '418px',
		borderRadius: '2px',
		margin: 'auto',
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	};

	//
	const dispatch = useDispatch();
	const [showFirstButton, setShowFirstButton] = useState(true);
	const [showSecondButton, setShowSecondButton] = useState(false);
	const [showNewBox, setShowNewBox] = useState(false);
	const [showExistingBox, setShowExistingBox] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [results, setResults] = useState([]);
	const [query, setQuery] = useState('');
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [memberObject, setMemberObject] = useState({});
	const [familyPlan, setFamilyPlan] = useState([]);
	const [grandTotal, setGrandTotal] = useState(0);
	const [load, setLoad] = useState(false);
	const [editedMember, setEditedMember] = useState({});
	const classes = useStyles();
	const [value, setValue] = React.useState('2');
	const [dataAvailable, setDataAvailable] = useState(false);
	const [memberLoading, setMemberLoading] = useState(true);
	const [longitude, setLongitude] = useState('');
	const [latitude, setLatitude] = useState('');
	const [accountStatus, setAccountStatus] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [memberToDeleteId, setMemberToDeleteId] = useState(null);
	const [deletedMember, setDeletedMember] = useState([]);
	const { user} = useSelector(state => state.user);
	const [googleLocation, setGoogleLocation] = useState({
		label: '',
		value: {},
	});
	const navigate = useNavigate();

	const handleClickButton = () => {
		setShowFirstButton(false);
		setShowSecondButton(true);
		setShowNewBox(true);
		setShowExistingBox(false);
	};

	//

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const [path, setPath] = useState(
		<span
			style={{
				marginLeft: '10px',
				fontSize: '12px',
				fontWeight: '500',
				color: '#D0D1D2',
			}}
		>
			EMPLOYEE'S DETAILS
		</span>
	);
	let { id } = useParams();

	// Get_member_by_id APis
	const getMemberById = async () => {
		try {
			setLoad(true);
			const { data } = await axios.get(`${server}/get_members_by_id?id=${id}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: token,
				},
			});
			const decryptedData = decryptData(data.encrypted_data);
			// console.log(decryptedData,"asedsd12")
			setMemberObject(decryptedData?.members_by_id);
			setFamilyPlan(decryptedData?.family);
			setEditedMember(decryptedData?.members_by_id);
			setGoogleLocation({
				...googleLocation,
				label: data?.members_by_id?.location,
			});
			//   setTotalPages(data?.data?.members?.last_page);
			setDataAvailable(decryptedData?.members_by_id !== null);
			setMemberLoading(false);

			setLoading(false);
			setLoad(false);
		} catch (err) {
			setLoad(false);
			// console.log(err);

			setDataAvailable(false);
			setMemberLoading(false);

			setLoading(false);
		}
	};

	// Update patient info apis
	const handleSaveChanges = async () => {
		try {
			const updatedId = memberObject?.id; // Update the id with the correct value

			const encrypted_data = encryptData({
				...editedMember,
				location: googleLocation?.label,
				longitude,
				latitude,
				address: googleLocation?.label,
			});
			const { data } = await axios.post(
				`${server}/update_patient_profile?id=${updatedId}`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: token,
					},
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			toast.success(decryptedData?.message);

			navigate('/members');
			setEditedMember({});
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			toast.error(decryptedErrorMessage.message);
			console.error('Error updating member information:', err);
		}
	};
	// Function to handle changes in the input fields
	const handleInputChange = e => {
		const { name, value } = e.target;

		// Set the value as an empty string if it is empty
		const formattedValue = value === '' ? '' : value;

		setEditedMember(prevEditedMember => ({
			...prevEditedMember,
			[name]: formattedValue,
		}));
	};

	useEffect(() => {
		getMemberById();
	}, [currentPage, query]);

	const handlePlaceSelect = async value => {
		try {
			setGoogleLocation(value);
			const results = await geocodeByPlaceId(value?.value?.place_id);
			if (results && results?.length > 0) {
				const { lat, lng } = await getLatLng(results[0]);
				setLatitude(lat);
				setLongitude(lng);
			}
		} catch (error) {
			console.error('Error fetching geolocation:', error);
		}
	};

	// detete Family plan
	// const handleDeleteRow = (index) => {
	//   const deletedMember = familyPlan[index];
	//   setDeletedMember((prevIds) => [...prevIds, deletedMember.id]); // Set deletedMember in the state
	//   const updatedFamilyPlan = familyPlan.filter((_, i) => i !== index);
	//   setFamilyPlan(updatedFamilyPlan);
	//   setGrandTotal((prevTotal) => prevTotal - deletedMember.total);
	// };
	const handleOpenDialog = index => {
		setOpenDialog(true);
		setMemberToDeleteId(index);
	};
	const handleDelete = index => {
		const deletedMember = familyPlan[index];
		setDeletedMember(prevIds => [...prevIds, deletedMember.id]); // Set deletedMember in the state
		const updatedFamilyPlan = familyPlan.filter((_, i) => i !== index);
		setFamilyPlan(updatedFamilyPlan);
		setGrandTotal(prevTotal => prevTotal - deletedMember.total);
		setOpenDialog(false);
	};
	useEffect(() => {
		let total = 0;
		// Calculate the total from familyPlan
		familyPlan.forEach(row => {
			total += row.total;
		});
		// Add memberObject's total to the total
		if (memberObject.total) {
			total += memberObject.total;
		}
		setGrandTotal(total);
	}, [familyPlan, memberObject]);

	const updateFamilyPlan = async deletedMember => {
		try {
			const deletedChildIdsString = deletedMember.join(',');
			await dispatch(updatePatientFamilyPlan(deletedChildIdsString));

			setDeletedMember([]);
			await getMemberById();
		} catch (error) {
			toast.error('Error updating family plan');
		}
	};
	const openAccountStatusDialogue = () => {
		setAccountStatus(true);
	};
	const closeAccountStatusDialogue = () => {
		setAccountStatus(false);
	};
	const PatientId = memberObject?.id;
	const SubmitFamilyPayment = (PatientId, grandTotal) => {
		
		if (user.is_stripe_configured === 0) {
			openAccountStatusDialogue();
			return;
		}
		dispatch(EnterprisePayPatientFamily(PatientId, grandTotal));
	};
	const ResendLoginCredentialPatient = PatientId => {
		dispatch(resendCredentialsPatient(PatientId));
	};
	//
	function capitalizeFirstLetter(str) {
		if (!str) return null;
		return str.charAt(0).toUpperCase() + str.slice(1);
	}
	let childCount = 0;

	const plan_id = memberObject?.plans_id;

	return (
		<>
			{memberLoading ? (
				<div className='home'>
					<div>
						<Sidebar showSidebar={true} showHelpButton={true} />
					</div>
					<div className='homeContainer'>
						<Header showHeader={true} />
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								minHeight: '100vh',
							}}
						>
							<InfinitySpin width='300' color='#4157FF' />
						</div>
					</div>
				</div>
			) : !dataAvailable ? (
				<StatusHandling showStatusHandling={true} />
			) : (
				<div className='home'>
					<div>
						<Sidebar showSidebar={true} showHelpButton={true} />
					</div>
					<div>
						<Header showHeader={true} currentPath={path} />
						<Container
							style={{ position: 'relative', background: '#F8F9FE' }}
							maxWidth='xl'
						>
							<Sidebar showSidebar={true} showHelpButton={true} />

							<p className={classes.companySet}>Employee Details</p>
							<MemberDetails
								name={memberObject?.name}
								email={memberObject?.email}
								healthcoin_id={memberObject?.healthcoin_id}
								profile_image={memberObject?.profile_image}
								handleClickButton={handleClickButton}
								handleSaveChanges={handleSaveChanges}
								updateFamilyPlan={() => updateFamilyPlan(deletedMember)}
								deletedMember={deletedMember}
								SubmitFamilyPayment={SubmitFamilyPayment}
								ResendLoginCredentialPatient={ResendLoginCredentialPatient}
								PatientId={PatientId}
								grandTotal={grandTotal}
								plan_id={plan_id}
								showFirstButton={showFirstButton}
								showSecondButton={showSecondButton}
								value={value} // Pass the selected tab value
								handleChange={handleChange}
								SpousePlan={memberObject?.include_plan}
								KindsPlan={memberObject?.kids}
							/>
							<PaymentGuidanceModel
								accountStatus={accountStatus}
								openAccountStatusDialogue={openAccountStatusDialogue}
								closeAccountStatusDialogue={closeAccountStatusDialogue}
										/>
							<TabContext sx={{ mt: 2 }} value={value}>
								<Box>
									<TabList
										sx={{ marginLeft: '22px' }}
										onChange={handleChange}
										aria-label='lab API tabs example'
									>
										<Tab
											sx={{
												minWidth: 'unset',
												width: '30px',
												'&.Mui-selected': {
													color: '#000000 !important',
												},
											}}
											className={classes.tabsetting}
											label='Info'
											value='1'
										/>
										<Tab
											className={classes.tabsetting}
											// label="Payment Details"
											sx={{
												minWidth: 'unset',
												width: '50px',
												marginLeft: '20px',
												'&.Mui-selected': {
													color: '#000000 !important',
												},
											}}
											label='Family'
											value='2'
										/>
									</TabList>
								</Box>
								<TabPanel value='1'>
									<Box style={boxStyling}>
										{showExistingBox && (
											<Grid
												sx={{ mt: 2, ml: 3 }}
												className={classes.labelstyling}
											>
												<p>
													<span className={classes.memberBoldFont}>
														First Name:
													</span>{' '}
													{memberObject?.first_name}
												</p>
												<p>
													<span className={classes.memberBoldFont}>
														Last Name:
													</span>{' '}
													{memberObject?.last_name}
												</p>
												<p>
													<span className={classes.memberBoldFont}>
														Employee ID:
													</span>{' '}
													{memberObject?.employee_id}
												</p>
												<p>
													<span className={classes.memberBoldFont}>
														Birth Date:
													</span>{' '}
													{memberObject?.dateofbirth}
												</p>
												<p>
													<span className={classes.memberBoldFont}>
														{' '}
														Department:
													</span>{' '}
													{memberObject?.department}
												</p>
												<p>
													<span className={classes.memberBoldFont}>
														Address:
													</span>{' '}
													{memberObject?.location}
												</p>
												<p>
													<span className={classes.memberBoldFont}>
														Healthcoin ID:
													</span>{' '}
													{memberObject?.healthcoin_id}
												</p>
												{/* <p>Healthcoin ID: {memberObject?.id}</p> */}
											</Grid>
										)}

										{showNewBox && (
											<Box>
												<Box
													sx={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'space-between',
														width: '60vw',
														margin: '20px',
													}}
												>
													{/* Input field for First Name */}
													<Box sx={{ display: 'grid' }}>
														<Box>
															<span style={headLabel}>First Name</span>
														</Box>
														<Box>
															<TextField
																margin='dense'
																// placeholder="First Name"
																fullWidth
																InputProps={{ style: { height: '31px' } }}
																sx={{
																	boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																	width: '289px',
																}}
																name='first_name'
																value={
																	editedMember.first_name
																	// || memberObject?.first_name || ''
																}
																onChange={handleInputChange}
															/>
														</Box>
													</Box>

													{/* Input field for Last Name */}
													<Box sx={{ display: 'grid' }}>
														<Box>
															<span style={headLabel}>Last Name</span>
														</Box>
														<Box>
															<TextField
																margin='dense'
																// placeholder="Last Name"
																fullWidth
																InputProps={{ style: { height: '31px' } }}
																sx={{
																	boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																	width: '289px',
																}}
																name='last_name'
																value={editedMember.last_name}
																onChange={handleInputChange}
															/>
														</Box>
													</Box>
												</Box>

												{/* Input fields for Employee ID and Birth Date */}
												<Box
													sx={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'space-between',
														width: '60vw',
														margin: '20px',
													}}
												>
													<Box sx={{ display: 'grid' }}>
														<Box>
															<span style={headLabel}>Employee Id</span>
														</Box>
														<Box>
															<TextField
																disabled
																margin='dense'
																placeholder='Employee Id'
																fullWidth
																InputProps={{ style: { height: '31px' } }}
																sx={{
																	boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																	width: '289px',
																}}
																name='employee_id'
																value={
																	editedMember.employee_id ||
																	memberObject?.employee_id ||
																	''
																}
																onChange={handleInputChange}
															/>
														</Box>
													</Box>
													<Box sx={{ display: 'grid' }}>
														<Box>
															<span style={headLabel}>Birth Date</span>
														</Box>
														<Box>
															<TextField
																margin='dense'
																type='date'
																name='dateofbirth'
																fullWidth
																InputProps={{ style: { height: '31px' } }}
																sx={{ width: '289px' }}
																value={editedMember.dateofbirth || ''}
																onChange={handleInputChange}
															/>
														</Box>
													</Box>
												</Box>

												{/* Input fields for Department and Address */}
												<Box
													sx={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'space-between',
														width: '60vw',
														margin: '20px',
													}}
												>
													<Box sx={{ display: 'grid' }}>
														<Box>
															<span style={headLabel}>Department</span>
														</Box>
														<Box>
															<TextField
																margin='dense'
																// placeholder="Department"
																fullWidth
																InputProps={{ style: { height: '31px' } }}
																sx={{
																	boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																	width: '289px',
																}}
																name='department'
																value={editedMember.department}
																onChange={handleInputChange}
															/>
														</Box>
													</Box>
													<Box sx={{ display: 'grid' }}>
														<Box>
															<span style={headLabel}>Address</span>
														</Box>
														<Box>
															{/* <TextField
                                margin="dense"
                                // placeholder="Address"
                                fullWidth
                                InputProps={{ style: { height: '31px' } }}
                                sx={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', width: '289px' }}
                                name="address"
                                value={editedMember.address
                                  // || memberObject?.address
                                }
                                onChange={handleInputChange}
                              /> */}
															<div style={{ width: '289px' }}>
																<GooglePlacesAutocomplete
																	selectProps={{
																		value: googleLocation,
																		onChange: value => {
																			handlePlaceSelect(value);
																		},
																	}}
																	apiKey={process.env.REACT_APP_API_KEY}
																/>
															</div>
														</Box>
													</Box>
												</Box>

												{/* Input field for HealthCoin ID */}
												<Box
													sx={{
														display: 'flex',
														bottom: '100px',
														justifyContent: 'space-between',
														alignItems: 'space-between',
														width: '60vw',
														margin: '20px',
													}}
												>
													<Box sx={{ display: 'grid' }}>
														<Box>
															<span style={headLabel}>HealthCoin Id</span>
														</Box>
														<Box>
															<TextField
																disabled
																margin='dense'
																fullWidth
																placeholder='HealthCoin Id'
																InputProps={{ style: { height: '31px' } }}
																sx={{
																	boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																	width: '289px',
																}}
																name='healthcoin_id'
																value={
																	editedMember.healthcoin_id ||
																	memberObject?.healthcoin_id ||
																	''
																}
																onChange={handleInputChange}
															/>
														</Box>
													</Box>
												</Box>

												<Box
													sx={{
														display: 'flex',
														justifyContent: 'flex-end',
														margin: '20px',
													}}
												></Box>
											</Box>
										)}
									</Box>
								</TabPanel>
								{/*  */}
								<TabPanel value='2'>
									<Box style={boxStylingFamily}>
										<Grid container spacing={2}>
											{/* First Column */}
											<Grid item xs={4}>
												<Box
													style={{
														marginTop: '15px',
														display: 'grid',
														gap: '6px',
													}}
												>
													<Box>
														<Typography
															style={{
																fontWeight: '700',
																fontSize: '24px',
																// textAlign: "Center",
																marginLeft: '50px',
																fontFamily: 'DM Sans',
															}}
														>
															Employee Plan
														</Typography>
													</Box>
													<Box sx={{ mt: 1 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '700',
																fontSize: '16px',
																fontFamily: 'DM Sans',
															}}
														>
															Include:{' '}
														</Typography>
													</Box>
													<Box sx={{ mt: 2 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '700',
																fontSize: '16px',
																fontFamily: 'DM Sans',
															}}
														>
															Employee:{' '}
														</Typography>
													</Box>
													<Box sx={{ mt: 1 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '400',
																fontSize: '16px',
																fontFamily: 'DM Sans',
																color: 'black',
															}}
														>
															Gender:{' '}
															<span
																style={{ fontWeight: '700', fontSize: '16px' }}
															>
																{memberObject?.gender
																	? memberObject.gender
																			.charAt(0)
																			.toUpperCase() +
																	  memberObject.gender.slice(1)
																	: null}

																{/* {memberObject?.gender} */}
															</span>
														</Typography>
													</Box>
													<Box sx={{ mt: 1 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '400',
																fontSize: '16px',
																fontFamily: 'DM Sans',
																color: 'black',
															}}
														>
															Age:{' '}
															<span
																style={{ fontWeight: '700', fontSize: '16px' }}
															>
																{' '}
																{memberObject?.plans?.age}
															</span>
														</Typography>
													</Box>
													<Box sx={{ mt: 1 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '400',
																fontSize: '16px',
																fontFamily: 'DM Sans',
																color: 'black',
															}}
														>
															Marital status:{' '}
															<span
																style={{ fontWeight: '700', fontSize: '16px' }}
															>
																{' '}
																{memberObject?.martial_status}
															</span>
														</Typography>
													</Box>
													<Box sx={{ mt: 1 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '400',
																fontSize: '16px',
																fontFamily: 'DM Sans',
																color: 'black',
															}}
														>
															Include to the plan:{' '}
															<span
																style={{ fontWeight: '700', fontSize: '16px' }}
															>
																{memberObject?.include_plan}
															</span>
														</Typography>
													</Box>
													<Box sx={{ mt: 2 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '700',
																fontSize: '16px',
																fontFamily: 'DM Sans',
															}}
														>
															Spouse/partner{' '}
														</Typography>
													</Box>
													<Box sx={{ mt: 1 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '400',
																fontSize: '16px',
																fontFamily: 'DM Sans',
																color: 'black',
															}}
														>
															Gender:{' '}
															<span
																style={{ fontWeight: '700', fontSize: '16px' }}
															>
																{familyPlan[0]?.user_type === 'spouse' &&
																familyPlan[0]?.gender
																	? familyPlan[0].gender
																			.charAt(0)
																			.toUpperCase() +
																	  familyPlan[0].gender.slice(1)
																	: null}

																{/* {familyPlan[0]?.user_type === "spouse" ? familyPlan[0]?.gender : null} */}
															</span>
														</Typography>
													</Box>
													<Box sx={{ mt: 1 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '400',
																fontSize: '16px',
																fontFamily: 'DM Sans',
																color: 'black',
															}}
														>
															Age:{' '}
															<span
																style={{ fontWeight: '700', fontSize: '16px' }}
															>
																{familyPlan[0]?.user_type === 'spouse'
																	? familyPlan[0]?.plans?.age
																		? familyPlan[0]?.plans?.age
																				.charAt(0)
																				.toUpperCase() +
																		  familyPlan[0]?.plans?.age.slice(1)
																		: null
																	: null}
															</span>
														</Typography>
													</Box>
												</Box>
											</Grid>

											{/* Second Column */}
											<Grid item xs={3}>
												<Box
													style={{
														marginTop: '34px',
														display: 'grid',
														gap: '6px',
													}}
												>
													<Box>
														<Typography
															style={{
																fontWeight: '700',
																fontSize: '16px',
																// textAlign: "Center",
																marginLeft: '50px',
																fontFamily: 'DM Sans',
															}}
														>
															Kids
														</Typography>
													</Box>

													<Box sx={{ mt: 1 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '400',
																fontSize: '16px',
																fontFamily: 'DM Sans',
																color: 'black',
															}}
														>
															Have kids:{' '}
															<span
																style={{ fontWeight: '700', fontSize: '16px' }}
															>
																{memberObject?.kids}
															</span>
														</Typography>
													</Box>
													<Box sx={{ mt: 1 }}>
														<Typography
															style={{
																marginLeft: '52px',
																fontWeight: '400',
																fontSize: '16px',
																fontFamily: 'DM Sans',
																color: 'black',
															}}
														>
															How many kids:{' '}
															<span
																style={{ fontWeight: '700', fontSize: '16px' }}
															>
																{' '}
																{memberObject?.total_kids}
															</span>
														</Typography>
													</Box>
													{familyPlan.map((child, index) => {
														if (child.user_type === 'child') {
															const childNumber = index + 0; // Calculate the child number
															const capitalizedGender = capitalizeFirstLetter(
																child.gender
															); // Capitalize the gender
															return (
																<div key={index}>
																	<Box sx={{ mt: 2 }}>
																		<Typography
																			style={{
																				marginLeft: '52px',
																				fontWeight: '700',
																				fontSize: '16px',
																				fontFamily: 'DM Sans',
																			}}
																		>
																			Child {childNumber}
																		</Typography>
																	</Box>
																	<Box sx={{ mt: 1 }}>
																		<Typography
																			style={{
																				marginLeft: '52px',
																				fontWeight: '400',
																				fontSize: '16px',
																				fontFamily: 'DM Sans',
																				color: 'black',
																			}}
																		>
																			Gender:{' '}
																			<span
																				style={{
																					fontWeight: '700',
																					fontSize: '16px',
																				}}
																			>
																				{capitalizedGender}
																				{/* {child.gender} */}
																			</span>
																		</Typography>
																	</Box>
																	<Box sx={{ mt: 1 }}>
																		<Typography
																			style={{
																				marginLeft: '52px',
																				fontWeight: '400',
																				fontSize: '16px',
																				fontFamily: 'DM Sans',
																				color: 'black',
																			}}
																		>
																			Age:{' '}
																			<span
																				style={{
																					fontWeight: '700',
																					fontSize: '16px',
																				}}
																			>
																				{child.plans.age}
																			</span>
																		</Typography>
																	</Box>
																</div>
															);
														}
														return null; // Skip if it's not a child
													})}
												</Box>
											</Grid>

											{/* Third Column */}
											<Grid item xs={5}>
												<Box
													style={{
														marginTop: '24px',
														display: 'grid',
														gap: '6px',
														overflow: 'auto',
													}}
												>
													<Box
														style={{
															border: '1px solid #949494',
															width: '400px',
															height: '381px',
															overflow: 'auto',
															borderRadius: '10px',
														}}
													>
														<Box>
															<Typography
																style={{
																	fontWeight: '700',
																	fontSize: '24px',
																	// textAlign: "Center",
																	marginLeft: '15px',
																	marginTop: '13px',
																	fontFamily: 'DM Sans',
																}}
															>
																Summary
															</Typography>
														</Box>
														<Box>
															<Box style={{ display: 'flex' }}>
																<Grid item xs={8}>
																	<Box sx={{ mt: 3 }}>
																		<Typography
																			style={{
																				marginLeft: '15px',
																				fontWeight: '400',
																				fontSize: '14px',
																				fontFamily: 'DM Sans',
																				color: '#000000',
																			}}
																		>
																			{memberObject?.plans?.plan
																				? memberObject.plans.plan
																						.charAt(0)
																						.toUpperCase() +
																				  memberObject.plans.plan.slice(1)
																				: ''}
																			/Age {memberObject?.plans?.age}{' '}
																			{memberObject?.gender
																				? memberObject.gender
																						.charAt(0)
																						.toUpperCase() +
																				  memberObject.gender.slice(1)
																				: ''}
																		</Typography>
																	</Box>
																</Grid>
																<Grid item xs={2}>
																	<Box sx={{ mt: 3 }}>
																		<span>${memberObject?.total}/mo</span>{' '}
																	</Box>
																</Grid>
															</Box>
															{familyPlan.map((row, index) => {
																if (row.user_type === 'child') {
																	childCount++;
																}
																return (
																	<div key={index}>
																		<Box style={{ display: 'flex' }}>
																			<Grid item xs={8}>
																				<Box sx={{ mt: 3 }}>
																					<Typography
																						style={{
																							marginLeft: '15px',
																							fontWeight: '400',
																							fontSize: '14px',
																							fontFamily: 'DM Sans',
																							color: '#000000',
																						}}
																					>
																						{/* For memberObject?.plans.plan */}
																						{memberObject?.plans.plan
																							? memberObject.plans.plan
																									.charAt(0)
																									.toUpperCase() +
																							  memberObject.plans.plan.slice(1)
																							: null}
																						{/* For row?.plans?.age */}
																						{/* For row?.plans?.age */}
																						/Age {row?.plans?.age}{' '}
																						{row?.gender
																							? row.gender
																									.charAt(0)
																									.toUpperCase() +
																							  row.gender.slice(1)
																							: null}
																						{/* For row?.discount */}
																						{row.discount !== null &&
																						memberObject?.plans.plan !==
																							'catastrophic'
																							? `= ${row.discount} % off`
																							: null}{' '}
																						&nbsp;
																						{/* {row?.discount !== null ? `= ${row.discount}% off` : null}&nbsp; */}
																						{/* For row?.user_type */}
																						{/* {row?.user_type ? row.user_type.charAt(0).toUpperCase() + row.user_type.slice(1) : null} {childNumber} */}
																						{row?.user_type === 'child'
																							? `Child ${childCount}`
																							: row?.user_type // Render user_type if not "child"
																							? row.user_type
																									.charAt(0)
																									.toUpperCase() +
																							  row.user_type.slice(1)
																							: null}
																					</Typography>
																				</Box>
																			</Grid>
																			<Grid item xs={2}>
																				<Box sx={{ mt: 3 }}>
																					<span>${row?.total}/mo</span>{' '}
																				</Box>
																			</Grid>
																			<Grid item xs={2}>
																				<Box sx={{ mt: 3 }}>
																					<img
																						src={delete_icon}
																						alt='delete_icon'
																						style={{
																							cursor: 'pointer',
																							marginLeft: '24px',
																						}}
																						onClick={() =>
																							handleOpenDialog(index)
																						}
																					/>
																				</Box>
																			</Grid>
																		</Box>
																	</div>
																);
															})}
															<Box style={{ display: 'flex' }}>
																<Grid item xs={8}>
																	<Box sx={{ mt: 3 }}>
																		<Typography
																			style={{
																				marginLeft: '15px',
																				fontWeight: '400',
																				fontSize: '14px',
																				fontFamily: 'DM Sans',
																				color: '#000000',
																			}}
																		>
																			Total =
																		</Typography>
																	</Box>
																</Grid>
																<Grid item xs={2}>
																	<Box sx={{ mt: 3 }}>
																		<span>${grandTotal}/mo</span>{' '}
																	</Box>
																</Grid>
																<Grid item xs={2}></Grid>
															</Box>
														</Box>
													</Box>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</TabPanel>
							</TabContext>
						</Container>
						<Toaster />
						<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
							<DialogTitle>
								<IconButton
									onClick={() => setOpenDialog(false)}
									color='inherit'
									aria-label='close'
									style={{
										display: 'flex',
										marginLeft: 'auto',
										padding: '0px',
									}}
								>
									<Box
										style={{
											background: '#4157FF',
											width: '34px',
											height: '34px',
											fontSize: '11px',
											boxShadow: 'none',
											borderRadius: '24px',
										}}
									>
										<CloseIcon style={{ margin: '5px 5px', color: 'white' }} />
									</Box>
								</IconButton>
							</DialogTitle>
							<Divider />
							<DialogContent>
								{/* <p style={{ fontWeight: 600 }}>
                      Are you sure want to delete this member family?
                    </p> */}
								<p style={{ fontWeight: 600 }}>
									Note: This user will be permanently deleted
								</p>
								<p
									style={{
										fontWeight: 600,
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									when you click "Update Changes" button.
								</p>
							</DialogContent>
							<DialogActions sx={{ justifyContent: 'center' }}>
								<Button
									onClick={() => handleDelete(memberToDeleteId)}
									variant='contained'
									color='error'
								>
									Delete
								</Button>
								<Button
									onClick={() => setOpenDialog(false)}
									variant='contained'
									style={{ background: '#4157FF' }}
								>
									Cancel
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				</div>
			)}
		</>
	);
};

export default MemberDetailsPage;
