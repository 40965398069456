import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Grid,
  TextField,
  TableContainer,
} from "@mui/material";
import { InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import search from "../../asset/image/search.svg";
import Sidebar from "../Layout/Sidebar/Sidebar";
import Header from "../Layout/Header/Header";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminInvicesDetailed } from "../../redux/action/user";
import StatusHandling from "../StatusHandling/StatusHandling";
import Spinner from "../SpinnerTrueFalse/Spinner";
import download from "../../asset/image/download.png";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const useStyles = makeStyles({
  novHeading: {
    fontSize: "30px !important",
    fontWeight: "600  !important",
    fontFamily: "DM Sans  !important",
  },
  totalBalance: {
    fontSize: "10px  !important",
    fontWeight: "600  !important",
    marginTop: "4px  !important",
  },
  amountHeading: {
    fontSize: "16px  !important",
    fontWeight: "600  !important",
  },

  headingCell: {
    border: "none  !important",
    fontSize: "12px  !important",
    fontWeight: "600  !important",
    padding: "3px !important",
  },
  bodyCell: {
    border: "none  !important",
    fontSize: "12px  !important",
    fontWeight: "500  !important",
    width: "150px  !important",
  },
});
const InvoiceList = () => {
  const classes = useStyles();
  const { month, id, year } = useParams();
  const [path, setPath] = useState(
    <span
      style={{
        marginLeft: "10px",
        textTransform: "uppercase",
        fontSize: "12px",
        fontWeight: "500",
        color: "#D0D1D2",
      }}
    >
      Invoices
    </span>
  );
  const { adminInvoiceDetailed, loadingForInVoiceDetailed } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    dispatch(getAdminInvicesDetailed(id, month, year, searchData));
  }, [searchData]);

  // download the Pdf
  const handleDownloadPDF = () => {
    const input = document.getElementById("pdf-content");

    const options = {
      dpi: 300, // Adjust the DPI value (e.g., 150, 300, etc.)
      scale: 2, // Adjust the scale value to increase the resolution
      useCORS: true, // Enable cross-origin resource sharing if needed
      allowTaint: true, // Allow tainting of the canvas (may be required if your content contains images from other domains)
    };

    html2canvas(input, options).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4"); // Set the document size and orientation as per your requirements
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("Invoices.pdf");
    });
  };
  return (
    <>
      {loadingForInVoiceDetailed ? (
        <Spinner />
      ) : (
        <div className="home">
          <div>
            <Sidebar showSidebar={false} showHelpButton={false} active={true} />
          </div>
          <div className="homeContainer">
            <Header showHeader={true} currentPath={path} />

            <Container
              style={{
                position: "relative",
                top: "78px",
                background: "#f8f9fe",
              }}
              maxWidth="xl"
              sx={{ mt: 4 }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "-40px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70vw",
                    marginBottom: "20px",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ marginLeft: "-22px" }}>
                    <TextField
                      id="outlined-start-adornment"
                      placeholder="Search"
                      // value={query}
                      onChange={(e) => setSearchData(e.target.value)}
                      sx={{ marginLeft: "18px", width: "30ch" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={search} alt="search" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",

                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        width: "155px",
                        height: "45px",
                        color: "white",
                        background: "#4157FF",
                        borderRadius: "21px",
                        fontSize: "14px",
                        border: "none",
                        cursor: "pointer",
                        fontWeight: "700",
                      }}
                      onClick={handleDownloadPDF}
                    >
                      Download as PDF
                    </button>
                  </Box>
                </Box>
              </Box>
              <div id="pdf-content">
                <br />
                <br />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "50px",
                  }}
                >
                  <Box></Box>
                  <Box>
                    {adminInvoiceDetailed?.length > 0 ? (
                      <>
                        <span
                          style={{
                            fontSize: "14px",
                            marginTop: "2px",
                            fontWeight: "600",
                          }}
                        >
                          RUNNING BALANCE:
                        </span>
                        <span style={{ fontSize: "17px", fontWeight: "600" }}>
                          $
                          {adminInvoiceDetailed
                            ?.reduce((accumulator, currentValue) => {
                              return accumulator + +currentValue?.amount;
                            }, 0)
                            .toFixed(2)}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <br />
                <TableContainer
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Table sx={{ width: "70vw" }}>
                    <TableHead>
                      <TableRow
                        sx={{ background: "none !important", border: "none" }}
                      >
                        <TableCell className={classes.headingCell}>
                          UNIT
                          <KeyboardArrowDownIcon sx={{ paddingTop: "10px" }} />
                        </TableCell>
                        <TableCell className={classes.headingCell}>
                          DEBIT
                          <KeyboardArrowDownIcon
                            sx={{ paddingTop: "10px" }}
                          />{" "}
                        </TableCell>
                        <TableCell className={classes.headingCell}>
                          CREDIT
                          <KeyboardArrowDownIcon sx={{ paddingTop: "10px" }} />
                        </TableCell>
                        <TableCell className={classes.headingCell}>
                          RUNNING BALANCE
                          <KeyboardArrowDownIcon sx={{ paddingTop: "8px" }} />
                        </TableCell>
                        {/* </Box> */}
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ background: "white" }}>
                      {adminInvoiceDetailed?.length === 0 ? (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            align="center"
                            style={{ fontWeight: "bold", background: "#f8f9fe" }}
                          >
                            No Data Found
                          </TableCell>
                        </TableRow>
                      ) : (
                        adminInvoiceDetailed?.map((row, index, id) => (
                          <TableRow>
                            <TableCell
                              sx={{
                                border: "none",
                                fontSize: "12px",
                                fontWeight: "500",
                                fontFamily: "DM Sans",
                              }}
                            >
                              PROVIDER PAYMENT ({row?.members[0]?.name})
                            </TableCell>

                            <TableCell className={classes.bodyCell}>
                              {row?.debit}
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              {row?.amount}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.bodyCell}
                            >
                              {row?.amount}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceList;
