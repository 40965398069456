import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Divider,
	Input,
	Box,
	TextareaAutosize,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { toast, Toaster } from 'react-hot-toast';

const useStyles = makeStyles({
	buttonAddnewHelp: {
		fontSize: '24px',
		fontWeight: '500',
	},
	sendCredButton: {
		height: '46px',
		width: '190px',
		borderRadius: '24px',
		background: '#4157FF',
	},
	headingSettingTest: {
		fontSize: '24px',
		fontWeight: '500',
	},
});

const ModalSetting = ({ open, handleOpen1, handleClose1 }) => {
	// const [open, setOpen] = useState(false)
	const classes = useStyles();

	// css
	const contactProvider = {
		color: 'black',
		fontWeight: '700',
		cursor: 'pointer',
	};
	const containerStyle = {
		fontFamily: 'Times New Roman',
		color: 'rgb(0, 0, 0)',
		margin: '20px',
	};

	const headingStyle = {
		fontFamily: 'Times New Roman',
		color: 'rgb(0, 0, 0)',
		textDecoration: 'underline',
		fontSize: '18px',
	};

	const subHeadingStyle = {
		fontFamily: 'Times New Roman',
		color: 'rgb(0, 0, 0)',
		textDecoration: 'underline',
		fontSize: '19px',
	};

	const paragraphStyle = {
		textAlign: 'left',
		fontSize: '16px',
		fontFamily: 'Times New Roman',
		color: 'rgb(0, 0, 0)',
	};

	const listStyle = {
		listStyleType: 'none',
	};

	const linkStyle = {
		fontFamily: 'Times New Roman',
		color: 'rgb(0, 0, 0)',
		textDecoration: 'underline',
	};

	return (
		<>
			<Dialog open={open} onClose={handleClose1}>
				<DialogTitle className={classes.buttonAddnewHelp}>
					<span className={classes.headingSettingTest}>Invite Employees</span>
					<IconButton
						onClick={handleClose1}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none ',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>

				<DialogContent>
					Select an option to invite employees to enroll
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ModalSetting;
