import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import CloseIcon from '@mui/icons-material/Close';

import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
	TextField,
	TableContainer,
	Tab,
	Dialog,
	DialogTitle,
	IconButton,
	DialogContent,
	Typography,
} from '@mui/material';
import Checkbox from '../Table/Checkbox';
import React, { useState, useEffect } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import avatar from '../../asset/image/avatar.svg';
import EyeView from '../../asset/image/eyeView.svg';
import Shape from '../../asset/image/Header/Shape.png';
import eyeView from '../../asset/image/eyeView.svg';
import { useParams, useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import PaymentContact from './Contact';
import axios from 'axios';
import { server } from '../../redux/store';
import StatusHandling from '../StatusHandling/StatusHandling';
import Spinner from '../SpinnerTrueFalse/Spinner';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
	adminRequestFOrDisputes,
	getAllDisputePaymentRequest,
	getAllHospitalDisputes,
	PercentageAdjustment,
} from '../../redux/action/user';
import { convertToLocalTime, convertLocalTimeToUTC } from '../../utils/date';
import Button from '@mui/material/Button';
// Css Style
const useStyles = makeStyles({
	usdHeading: {
		fontSize: '14px',
		fontWeight: '400',
		fontFamily: 'DM Sans',
		color: '#23262F',
	},
	companySet: {
		marginLeft: '21px',
		fontWeight: 600,
		fontSize: '24px',
	},
	tabsetting: {
		fontWeight: '600 !important',
		fontSize: '16px !important',
		textTransform: 'none !important',
		color: '#949494 !important',
		'&:focus': {
			color: '#000000 !important',
		},
		'&:active': {
			color: '#000000 !important',
		},
	},

	headingIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	eyes: {
		cursor: 'pointer',
	},
	tablerow: {
		cursor: 'pointer',
	},
	payReqIno: {
		fontFamily: 'DM Sans',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '34px',
	},
	headingCellInvoice: {
		fontSize: '10px',
		fontWeight: '500',
		fontFamily: 'DM Sans',
	},
	bodyCell: {
		fontSize: '10px',
	},
	forgetDebit: {
		width: '203px',
		fontSize: '16px',
		fontWeight: '700',
		color: 'white',
		height: '51px',
		background: '#4CD964',
		borderRadius: '56px',
		cursor: 'pointer',
		border: 'none',
	},
	approveClaim: {
		width: '203px',
		fontSize: '16px',
		fontWeight: '700',
		color: 'white',
		height: '51px',
		background: '#4157FF',
		borderRadius: '56px',
		cursor: 'pointer',
		border: 'none',
	},
	dennyClaim: {
		width: '203px',
		fontSize: '16px',
		fontWeight: '700',
		color: 'white',
		height: '51px',
		background: '#FF4141',
		borderRadius: '56px',
		cursor: 'pointer',
		border: 'none',
	},
});

const HospitalsDisputesDetails = () => {
	const classes = useStyles();
	const { uid } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { disputePaymentDetail, success } = useSelector(state => state.user);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [patient, setPatient] = useState(null);
	const [fraudModal, setFraudModal] = useState(false);
	const [approveModal, setApproveModal] = useState(false);
	const [provider, setProvider] = useState(null);
	const [appointment, setAppointment] = useState(null);
	const [payments, setPayments] = useState(null);
	const [consultant, setConsultant] = useState(null);
	const [services, setServices] = useState(null);
	const [image, setImage] = useState(null);
	const [load, setLoad] = useState(false);
	const [dataAvailable, setDataAvailable] = useState(false);
	const [loading, setLoading] = useState(true);
	const [description, setDescription] = useState('');
	const [timestamp, setTimestamp] = useState(new Date().toISOString());
	const [buttonsDisabled, setButtonsDisabled] = useState(false);
	const [statusChecked, setStatusChecked] = useState(true);
	const [inputValue, setInputValue] = useState();

	// css
	const root = {
		fontSize: '12px',
		fontWeight: 'bold',
		textAlign: 'center',
		color: '#23262F',
		margin: '0',
		padding: '0',
	};
	const [value, setValue] = React.useState('1');
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const [path, setPath] = useState(
		<span
			style={{
				marginLeft: '10px',
				fontSize: '12px',
				fontWeight: '500',
				color: '#D0D1D2',
				textTransform: 'uppercase',
			}}
		>
			HOSPITAL DISPUTES
		</span>
	);

	useEffect(() => {
		if (success) {
			setButtonsDisabled(true);
		}
	}, [success]);
	useEffect(() => {
		dispatch(getAllDisputePaymentRequest(uid));
		setPayments(disputePaymentDetail);
		setPatient(disputePaymentDetail?.patient);
		setProvider(disputePaymentDetail?.provider);
		setServices(disputePaymentDetail?.services);
		setConsultant(disputePaymentDetail?.consultant);
		setAppointment(disputePaymentDetail?.appointment);
		setImage(disputePaymentDetail?.image);
		setDescription(disputePaymentDetail?.admin_note);

		if (
			disputePaymentDetail?.dispute == 'forgive' ||
			disputePaymentDetail?.dispute == 'approve' ||
			disputePaymentDetail?.dispute == 'deny'
		) {
			setStatusChecked(false);
		}
		console.log(disputePaymentDetail, 'helopakistan');
		// if (["forgive", "approve", "deny"].includes(disputePaymentDetail?.dispute)) {
		//   setStatusChecked(false);
		// }

		setDataAvailable(disputePaymentDetail !== null);
		setLoading(false);
	}, [JSON.stringify(disputePaymentDetail)]);

	const handleSubmit = async (status, description) => {
		if (status === 'approve') {
			setApproveModal(false);
			if (!description) {
				return toast.error('Please add description.');
			}
		}
		if (status === 'forgive') {
			setFraudModal(false);
		}
		const token = `Bearer ${localStorage.getItem('token')}`;
		const payment_id = payments?.id;

		dispatch(
			adminRequestFOrDisputes(payment_id, status, description, timestamp)
		);
		// dispatch(getAllHospitalDisputes())
		dispatch(getAllDisputePaymentRequest(uid));

		setTimeout(() => {
			navigate('/hospital_disputes');
		}, 500);

		if (success) {
			setButtonsDisabled(true);
		}
	};
	useEffect(() => {
		dispatch(getAllHospitalDisputes());
	}, [getAllHospitalDisputes]);
	// {}

	// percentage Adjustment
	const adminPayment = payments?.admin_paid_percentage;
	const handleInputChange = e => {
		const value = e.target.value;
		if (!isNaN(value) && value >= 0 && value <= adminPayment) {
			setInputValue(value);
		}
	};
	const handlePercentageAdjustment = () => {
		dispatch(PercentageAdjustment(inputValue, uid));
		dispatch(getAllDisputePaymentRequest(uid));
		// dispatch(getAllPaymentRequestDetail(uid));
	};
	const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
	return (
		<>
			{loading ? (
				<Spinner />
			) : !dataAvailable ? (
				<StatusHandling />
			) : (
				<div className='home'>
					<div>
						<Sidebar showSidebar={false} showHelpButton={false} />
					</div>
					<div className='homeContainer'>
						<Header showHeader={true} currentPath={path} />

						<Container
							maxWidth='xl'
							style={{
								position: 'relative',
								top: '80px',
								height: 'auto',
								background: '#f8f9fe',
							}}
						>
							<Box>
								<p className={classes.companySet}>Dispute Details</p>

								<Box
									sx={{
										width: '78vw',
										marginLeft: '21px',
										height: '121px',
										background: 'white',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											padding: '20px',
											justifyContent: 'space-between',
										}}
									>
										<Box sx={{ display: 'flex', gap: '20px' }}>
											<Box>
												<img
													src={
														provider?.profile_image
															? 'https://api.healthcoinxchangeportal.com/' +
															provider?.profile_image
															: avatar
													}
													// src={avatar}
													style={{
														width: '71px',
														height: '71px',
														borderRadius: '50px',
													}}
													alt='avatar'
												/>
											</Box>
											{/* card_info */}
											<Box sx={{ display: 'grid' }}>
												<Box>
													<span style={{ fontSize: '16px', fontWeight: '700' }}>
														{provider?.name}
													</span>
												</Box>
												<Box sx={{ display: 'grid' }}>
													<span sx={{ color: '#6E757C', fontSize: '14px' }}>
														{provider?.email}
													</span>
													<span sx={{ color: '#6E757C', fontSize: '14px' }}>
														HC ID:{provider?.healthcoin_id}
													</span>
												</Box>
											</Box>
										</Box>

										<Box sx={{ display: 'grid' }}>
											<span
												style={{
													fontSize: '14px',
													color: '#6E757C',
													fontWeight: '400',
													fontFamily: 'DM Sans',
												}}
											>
												Total Amount Requested
											</span>
											<span style={{ fontWeight: '700', fontSize: '24px' }}>
												${payments?.hc_bill}
											</span>
										</Box>
										<Box
											sx={{
												display: 'flex',
												marginLeft: '22px',
												gap: '20px',
												marginTop: '12px',
											}}
										>
											<PaymentContact
												name={provider?.name}
												email={provider?.email}
											/>
										</Box>
									</Box>
								</Box>
								<TabContext sx={{ mt: 2 }} value={value}>
									<Box>
										<TabList
											onChange={handleChange}
											aria-label='lab API tabs example'
											sx={{ marginLeft: '30px', marginTop: '22px' }}
										>
											<Tab
												sx={{ minWidth: 'unset', width: '30px' }}
												className={classes.tabsetting}
												label='Info'
												value='1'
											/>
											<Tab
												className={classes.tabsetting}
												// label="Payment Details"
												sx={{
													minWidth: 'unset',
													width: '130px',
													marginLeft: '30px',
												}}
												label={
													<div style={{ gap: '5px', display: 'flex' }}>
														<span>Payment</span>
														<span>Details</span>
													</div>
												}
												value='2'
											/>
											<Tab
												className={classes.tabsetting}
												sx={{
													minWidth: 'unset',
													width: '171px',
													marginLeft: '30px',
												}}
												label={
													<div style={{ gap: '5px', display: 'flex' }}>
														<span>Uploaded</span>
														<span>Documents</span>
													</div>
												}
												value='3'
											/>
											<Tab
												className={classes.tabsetting}
												// label="Fraud Report"
												sx={{
													minWidth: 'unset',
													width: '109px',
													marginLeft: '30px',
												}}
												label={
													<div style={{ gap: '4px', display: 'flex' }}>
														<span>Fraud</span>
														<span>Report</span>
													</div>
												}
												value='4'
											/>
											{/* {disputePaymentDetail?.dispute === 'deny' ? (
												<Tab
													className={classes.tabsetting}
													sx={{
														minWidth: 'unset',
														width: '171px',
														marginLeft: '30px',
													}}
													label={
														<div style={{ gap: '4px', display: 'flex' }}>
															<span>Patient% </span>
															<span>Adjustment</span>
														</div>
													}
													value='5'
												/>
											) : null} */}
										</TabList>
									</Box>
									<TabPanel value='1'>
										<Box
											style={{
												width: '100% ',
												height: '400px ',
												borderRadius: '2px ',
												margin: 'auto ',
												backgroundColor: 'white',
											}}
										>
											<Box sx={{ display: 'flex', padding: '20px' }}>
												<Box sx={{ display: 'flex', gap: '280px' }}>
													<Box sx={{ display: 'grid', gap: '0px' }}>
														<span className={classes.payReqIno}>
															Appointment ID: {appointment?.id}
														</span>
														<span className={classes.payReqIno}>
															Appointment date / time :{' '}
															{convertToLocalTime(appointment?.date)}{' '}
														</span>
														<span className={classes.payReqIno}>
															Ends: {convertToLocalTime(appointment?.end_date)}
														</span>
														<span className={classes.payReqIno}>
															Phone number: {patient?.phone}
														</span>
														<span className={classes.payReqIno}>
															Location: {provider?.location}
														</span>
														<span className={classes.payReqIno}>
															Date requested:{' '}
															{convertToLocalTime(payments?.bill_added_date)}
														</span>
														<Box style={{ marginTop: '4px' }}>
															<span
																style={{
																	fontSize: '16px',
																	fontWeight: '600',
																	gap: '3px',
																}}
															>
																Status Details:
																<br />
																<span className={classes.payReqIno}>
																	Dispute create date:{' '}
																	{convertLocalTimeToUTC(
																		payments?.dispute_create_date
																	)}{' '}
																	<br />
																</span>
															</span>
														</Box>
													</Box>
													<Box
														sx={{
															display: 'grid',
															marginTop: '8px',
															gap: '0px',
														}}
													>
														<span
															style={{ fontSize: '14px', fontWeight: '500' }}
														>
															Patient’s name:&nbsp;
															<span
																style={{ color: '#3772FF', fontWeight: '700' }}
															>
																{patient?.name}
															</span>
														</span>
														<span
															style={{ fontSize: '14px', fontWeight: '500' }}
														>
															Patient’s Flag:&nbsp;
															<span
																style={{ color: '#3772FF', fontWeight: '700' }}
															>
																{patient?.flag}
															</span>
														</span>
														<span
															style={{ fontSize: '14px', fontWeight: '500' }}
														>
															Patient’s Wallet:&nbsp;
															<span
																style={{ color: '#3772FF', fontWeight: '700' }}
															>
																{patient?.balance >= 0
																	? `$${patient?.balance}`
																	: `-$${Math.abs(patient?.balance)}`}
															</span>
														</span>
														<span
															style={{ fontSize: '14px', fontWeight: '500' }}
														>
															Provider’s ID: {provider?.id}
														</span>
														<span
															style={{ fontSize: '14px', fontWeight: '700' }}
														>
															Procedure / Services:
														</span>
														<span
															style={{ fontSize: '14px', fontWeight: '500' }}
														>
															Consultation: {consultant?.consultation_name}
														</span>
														<div>
															{services?.map(item => (
																<React.Fragment key={item.id}>
																	<span
																		style={{
																			fontSize: '14px',
																			fontWeight: '500',
																		}}
																	>
																		{item?.service_name}
																	</span>
																	<br />{' '}
																	{/* Add line break after each service_name */}
																</React.Fragment>
															))}
														</div>
													</Box>
												</Box>
											</Box>
										</Box>
									</TabPanel>
									{/*  */}
									<TabPanel value='2'>
										<Box
											style={{
												width: '100% ',
												height: '400px ',
												borderRadius: '2px ',
												margin: 'auto ',
												backgroundColor: 'white',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
											>
												<Box sx={{ margin: '22px', display: 'flex' }}>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															gap: '8px',
														}}
													>
														<span className={classes.usdHeading}>
															Consultation:{' '}
														</span>
														<div>
															{services?.map(item => (
																<React.Fragment key={item.id}>
																	<span className={classes.usdHeading}>
																		{item?.service_name}
																	</span>
																	<br />{' '}
																	{/* Add line break after each service_name */}
																</React.Fragment>
															))}
														</div>
														<span className={classes.usdHeading}>
															HC contribution <br />(
															{payments?.admin_paid_percentage}% deductible)
														</span>
														<span className={classes.usdHeading}>
															Convenience Fee
														</span>
														<span className={classes.usdHeading}>
															Patient Owes
														</span>
													</Box>
													{/* usd is on right site */}
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															marginLeft: '100px',
															gap: '8px',
														}}
													>
														<span className={classes.usdHeading}>
															${consultant?.consultation_price} USD
														</span>
														<div>
															{services?.map(item => (
																<React.Fragment key={item.id}>
																	<span className={classes.usdHeading}>
																		${item?.price} USD
																	</span>
																	<br />{' '}
																	{/* Add line break after each service_name */}
																</React.Fragment>
															))}
														</div>
														<Box sx={{ marginTop: '8px' }}>
															<span className={classes.usdHeading}>
																${payments?.hc_bill} USD
															</span>
														</Box>
														<Box
															sx={{
																marginTop: '15px',
																gap: '5px',
																display: 'grid',
															}}
														>
															<span className={classes.usdHeading}>
																${payments?.convenience_fee} USD
															</span>
															<span className={classes.usdHeading}>
																${payments?.patient_bill} USD
															</span>
														</Box>
													</Box>
												</Box>
												{/* right site */}
												<Box
													sx={{
														margin: '22px',
														marginTop: '40px',
														display: 'flex',
														marginRight: '18rem',
													}}
												>
													<Box
														sx={{ display: 'flex', flexDirection: 'column' }}
													>
														<Box
															sx={{ display: 'flex', flexDirection: 'column' }}
														>
															<span
																style={{ fontSize: '15px', fontWeight: '700' }}
															>
																Paid by Patient:
															</span>

															{payments?.dispute == 'forgive' ||
																payments?.dispute == 'approve' ? (
																<span
																	style={{ fontSize: '10px', color: '#0cc92c' }}
																>
																	Payment complete
																</span>
															) : (
																<span
																	style={{ fontSize: '10px', color: '#FFA23A' }}
																>
																	Awaiting payment
																</span>
															)}

															<span
																style={{ fontSize: '10px', color: '#4cd964' }}
															>
																Disputes Status=
																{payments?.dispute &&
																	payments.dispute.charAt(0).toUpperCase() +
																	payments.dispute.slice(1)}
															</span>
															{payments?.adjustment_bill !== null && (
																<span
																	style={{
																		fontSize: '15px',
																		fontWeight: '700',
																		marginTop: '5px',
																	}}
																>
																	Paid by Patient(Adjustment):
																</span>
															)}
														</Box>
														<Box
															sx={{
																display: 'flex',
																flexDirection: 'column',
																marginTop: '30px',
															}}
														>
															<span
																style={{ fontSize: '15px', fontWeight: '700' }}
															>
																Total Amount{' '}
															</span>
															<span
																style={{ fontSize: '15px', fontWeight: '700' }}
															>
																to disburse:
															</span>
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															marginLeft: '100px',
														}}
													>
														<Box sx={{ marginTop: '10px' }}>
															<span
																style={{ fontSize: '14px', fontWeight: '700' }}
															>
																${payments?.patient_bill} USD
															</span>
														</Box>
														<Box sx={{ marginTop: '10px' }}>
															<span
																style={{ fontSize: '14px', fontWeight: '700' }}
															>
																{payments?.adjustment_bill && (
																	<>{`${payments.adjustment_bill} USD`}</>
																)}
															</span>
														</Box>
														<Box sx={{ marginTop: '50px' }}>
															<span
																style={{ fontSize: '14px', fontWeight: '700' }}
															>
																${payments?.hc_bill} USD
															</span>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									</TabPanel>
									<TabPanel value='3'>
										<Box
											style={{
												width: '100% ',
												height: 'auto ',
												borderRadius: '2px ',
												margin: 'auto ',
												backgroundColor: 'white',
											}}
										>
											<TableContainer
												style={{ margin: 'auto', width: '90%' }}
												sx={{ mt: 2 }}
											>
												<Table>
													<TableHead>
														<TableRow>
															{/* <TableCell style={{ padding: "-0px", margin: "-12px" }}>
                              <Checkbox
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                              />
                            </TableCell> */}
															<TableCell style={root}>
																<span className={classes.headingIcon}>
																	FILE NAME
																	<KeyboardArrowDownIcon
																		style={{ padding: '2px' }}
																	/>
																</span>
															</TableCell>
															<TableCell style={root} align='center'>
																<span className={classes.headingIcon}>
																	UPLOADED BY
																	<KeyboardArrowDownIcon
																		style={{ padding: '2px' }}
																	/>
																</span>
															</TableCell>
															<TableCell style={root} align='center'>
																<span className={classes.headingIcon}>
																	ACTION
																	<KeyboardArrowDownIcon
																		style={{ padding: '2px' }}
																	/>
																</span>
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{image?.map(item => (
															<TableRow>
																{/* <TableCell style={{ padding: '0', margin: '0' }}>
                                <Checkbox
                                  type="checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                />
                              </TableCell> */}
																<TableCell
																	align='center'
																	className={classes.bodyCell}
																	style={{
																		padding: '0px',
																		margin: '0px',
																		opacity: 0.8,
																		fontSize: '10px',
																		fontFamily: 'DM Sans',
																	}}
																>
																	{item.image.split('/').pop()}
																</TableCell>
																<TableCell
																	align='center'
																	className={classes.bodyCell}
																	style={{
																		padding: '0px',
																		margin: '0px',
																		opacity: 0.8,
																		fontSize: '10px',
																		fontFamily: 'DM Sans',
																	}}
																>
																	{provider?.name}
																</TableCell>
																<TableCell align='center'>
																	<Box sx={{ cursor: 'pointer' }}>
																		{/* <img src={eyeView} alt="" /> */}
																		<a
																			href={
																				'https://api.healthcoinxchangeportal.com/' +
																				item?.image
																			}
																			target='_blank'
																		>
																			<img
																				src={eyeView}
																				alt='eyeView'
																				className={classes.eyes}
																			/>
																		</a>
																	</Box>
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
										</Box>
									</TabPanel>
									<TabPanel value='4'>
										<Box>
											<span style={{ fontSize: '12px', fontWeight: '600' }}>
												PATIENT FRAUD REPORT
											</span>
										</Box>{' '}
										<br />
										<Box
											style={{
												width: '100% ',
												height: '150px ',
												borderRadius: '2px ',
												margin: 'auto ',
												backgroundColor: 'white',
												overflowY: 'auto',
											}}
										>
											<Box>
												<Box sx={{ display: 'flex', padding: '22px' }}>
													<span style={{ fontSize: '10px', color: '#6E757C' }}>
														<span style={{ fontWeight: '600' }}>
															Patient Description:{' '}
														</span>{' '}
														{payments?.dispute_description} <br />
														<span style={{ fontWeight: '600' }}>
															Admin Description:
														</span>{' '}
														{payments?.admin_note}
													</span>
												</Box>
											</Box>
										</Box>
										<br />
										<hr style={{ border: '1px solid #dfdfdf' }} />
										<br />
										<span style={{ fontSize: '12px', fontWeight: '600' }}>
											ADMIN NOTES
										</span>
										<br />
										<Box
											style={{
												width: '100% ',
												height: '165px ',
												borderRadius: '2px ',
												margin: 'auto ',
												backgroundColor: 'white',
											}}
										>
											{!statusChecked ? (
												<Box sx={{ display: 'flex', padding: '22px' }}>
													<span style={{ fontSize: '10px', color: '#6E757C' }}>
														<span style={{ fontWeight: '600' }}>
															{description}{' '}
														</span>
													</span>
												</Box>
											) : (
												<Box>
													<Box sx={{ display: 'flex', padding: '22px' }}>
														<TextField
															fullWidth
															multiline
															placeholder='Description:'
															name='description'
															rows={4}
															value={description}
															onChange={e => {
																setDescription(e.target.value);
																setTimestamp(new Date().toISOString());
															}}
															style={{ fontSize: '10px', color: '#6E757C' }}
														/>
													</Box>
												</Box>
											)}
										</Box>
										{disputePaymentDetail?.dispute != 'forgive' &&
											disputePaymentDetail?.dispute != 'approve' &&
											disputePaymentDetail?.dispute != 'deny' ? (
											<Box
												sx={{
													display: 'flex',
													gap: '20px',
													marginTop: '30px',
													justifyContent: 'flex-end',
												}}
											>
												<button
													className={classes.forgetDebit}
													name='forgive'
													onClick={() => setFraudModal(true)}
													disabled={
														disputePaymentDetail?.dispute !==
														'uploaddocumentsby_provider'
													}
													style={{
														cursor:
															disputePaymentDetail?.dispute !==
																'uploaddocumentsby_provider'
																? 'not-allowed'
																: 'pointer',
													}}
												>
													FORGIVE DEBT
												</button>

												<button
													className={classes.approveClaim}
													name='approve'
													onClick={() => setApproveModal(true)}
													disabled={
														disputePaymentDetail?.dispute !==
														'uploaddocumentsby_provider'
													}
													style={{
														cursor:
															disputePaymentDetail?.dispute !==
																'uploaddocumentsby_provider'
																? 'not-allowed'
																: 'pointer',
													}}
												>
													APPROVE CLAIM
												</button>

												<button
													className={classes.dennyClaim}
													name='deny'
													onClick={() => handleSubmit('deny', description)}
													disabled={
														disputePaymentDetail?.dispute !==
														'uploaddocumentsby_provider'
													}
													style={{
														cursor:
															disputePaymentDetail?.dispute !==
																'uploaddocumentsby_provider'
																? 'not-allowed'
																: 'pointer',
													}}
												>
													DENY CLAIM
												</button>
											</Box>
										) : (
											''
										)}
									</TabPanel>
									{/* <TabPanel value='5'>
										<Box>
											<span style={{ fontSize: '12px', fontWeight: '600' }}>
												Patient % Adjustment
											</span>
										</Box>{' '}
										<br />
										<Box
											style={{
												width: '100% ',
												height: '400px ',
												borderRadius: '2px ',
												margin: 'auto ',
												backgroundColor: 'white',
											}}
										>
											<Box>
												<Box sx={{ display: 'flex', padding: '22px' }}>
													<TextField
														label='Enter Percentage'
														variant='outlined'
														value={inputValue}
														onChange={handleInputChange}
														inputProps={{
															maxLength: 3, // Maximum length of 3 characters
														}}
													/>
													<br />
													<Box style={{ marginLeft: '12px' }}>
														{!payments?.adjustment_bill && (
															<Button
																variant='contained'
																onClick={handlePercentageAdjustment}
																style={{
																	marginTop: '10px',
																	borderRadius: '56px',
																	cursor: 'pointer',
																	fontSize: '16px',
																	fontWeight: '700',
																	textTransform: 'none',
																}}
															>
																Percentage Adjustment
															</Button>
														)}
													</Box>
												</Box>
											</Box>
										</Box>
									</TabPanel> */}
									{/*  */}
								</TabContext>
							</Box>
						</Container>
					</div>
				</div>
			)}
			<Toaster />

			<Dialog open={fraudModal} onClose={() => setFraudModal(false)}>
				<DialogTitle className={classes.buttonAddnewHelp}>
					<span className={classes.headingSettingTest}>Forgive</span>
					<IconButton
						onClick={() => setFraudModal(false)}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none ',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: 'flex',
							gap: '10px',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Box>
							<Typography
								variant='h6'
								sx={{
									fontSize: '14px ',
									fontWeight: '700',
									color: '#525F7F',
								}}
								gutterBottom
							>
								After forgive debit full amount will be send to provide on this
								of Forgive Payment.
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '30px',
						}}
					>
						<button
							type='submit'
							className='btn btn-success'
							// style={submitContactButton}
							onClick={() => handleSubmit('forgive', description)}
						>
							Forgive Debit
						</button>
					</Box>
				</DialogContent>
			</Dialog>

			<Dialog open={approveModal} onClose={() => setApproveModal(false)}>
				<DialogTitle className={classes.buttonAddnewHelp}>
					<span className={classes.headingSettingTest}>Approve</span>
					<IconButton
						onClick={() => setApproveModal(false)}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none ',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: 'flex',
							gap: '10px',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Box>
							<Typography
								variant='h6'
								sx={{
									fontSize: '14px ',
									fontWeight: '700',
									color: '#525F7F',
								}}
								gutterBottom
							>
								After approve debit both admin and patient can't send payment to
								provider.
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '30px',
						}}
					>
						<button
							type='submit'
							className='btn btn-success'
							// style={submitContactButton}
							onClick={() => handleSubmit('approve', description)}
						>
							Approve Claim
						</button>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default HospitalsDisputesDetails;
