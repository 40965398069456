import { createReducer } from '@reduxjs/toolkit';

const EmployerReducer = createReducer(
	{},
	{
		//  Verify Email Code

		verifyRequest: state => {
			state.enterprise_loading = true;
		},
		verifySuccess: (state, action) => {
			state.enterprise_loading = false;
			state.enterprise_isAuthenticated = true;
			state.enterprise_message = action.payload;
		},
		verifyFail: (state, action) => {
			state.enterprise_loading = false;
			state.enterprise_isAuthenticated = false;
			state.enterprise_error = action.payload;
		},

		clearError: (state, action) => {
			state.enterprise_error = null;
		},
		clearMessage: (state, action) => {
			state.enterprise_message = null;
		},
	}
);

export default EmployerReducer;
