import Cards from '../Card/Cards';
import './Members.css';
import React, { useState, useEffect } from 'react';
import {
	Box,
	Button,
	Container,
	TextField,
	// Link,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Switch,
	// Rating,
	Grid,
	TableBody,
	Typography,
} from '@mui/material';
import User from '../Users/User';
import MemberModel from './MemberModel';
import Checkbox from '../../Table/Checkbox';
import Divider from '@mui/material/Divider';
import Sidebar from '../../Layout/Sidebar/Sidebar';
import Header from '../../Layout/Header/Header';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import click from '../../../asset/image/memberImages/click.svg';
import EyeView from '../../../asset/image/memberImages/eyeView.svg';
import Delete from '../../../asset/image/memberImages/delete.svg';
import verified from './images/Group 3618.svg';
import unverified from './images/Group 3617.svg';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAllProviders,
	updateEnterprisePaymentStatus,
	getMonthlyInvoice,
	getAllUsers,
	AutoDebitAPI,
	getmembersInEnterprise,
	deleteMemberByEnterprise,
	totalChargesInEnterprise,
	logoutUser,
	getmyProfile,
} from '../../../redux/action/user';
import { server } from '../../../redux/store';
import axios from 'axios';
import { paymentStatusUpdate } from '../../../redux/action/user';
import { FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MemberMakePayment from './MemberMakePayment';
import BillingInfo from '../../BillingInfo/BillingInfo';
import avatar from '../../../asset/image/avatar.svg';
import MemberSidebar from './MemberSidebar';
import Pagination from '@mui/material/Pagination';
import { toast, Toaster } from 'react-hot-toast';
import search from '../../../asset/image/search.svg';
import { useTheme, useMediaQuery } from '@mui/material';
import Settings from '../Setting/Settings';
import PaymentGuidanceModel from './PaymentGuidanceModel';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const label2 = { inputProps: { 'aria-label': 'Switch demo' } };
const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };

// css
const useStyles = makeStyles({
	eyesMember: {
		cursor: 'pointer',
	},
	tablerowMember: {
		cursor: 'pointer',
	},
});
const Members = () => {
	const classes = useStyles();
	const theme = useTheme();

	const formatName = name => {
		if (name?.length > 12) {
			return name.replace(/(.{12})/g, '$1\n');
		}
		return name;
	};

	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	// const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
	const [profileCheck, setProfileCheck] = useState(false);
	const [showMembers, setShowMembers] = useState(true);
	const [step, setStep] = useState(1);
	// const [autoDebit, setAutoDebit] = useState("");
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [accountStatus, setAccountStatus] = useState(false);

	const dispatch = useDispatch();

	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	// const [token, setToken] = useState(`Bearer ${localStorage.getItem("token")}`);
	// const { get_all_members } = useSelector((state) => state.user);
	const [load, setLoad] = useState(false);
	const navigate = useNavigate();
	// const [arrayState, setArrayState] = useState(get_all_members);
	const [results, setResults] = useState([]);
	const [query, setQuery] = useState('');
	const [showImportFile, setShowImportFile] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [arrayState, setArrayState] = useState([]);
	// const [allUser, setAllUser] = useState("");
	// const [MonthlyExpenses, setMonthlyExpenses] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const { allMembersRecord, totalMemberPages, totalAmountEnterprise } =
		useSelector(state => state.user);
	const [openDialog, setOpenDialog] = useState(false);
	const [memberToDeleteId, setMemberToDeleteId] = useState(null);
	const monthlyExpenses = useSelector(state => state.user.monthlyExpenses);
	const EnrolledPendingUsers = useSelector(
		state => state.user.EnrolledPendingUsers
	);
	const auto_debit = useSelector(state => state.user.auto_debit);

	useEffect(() => {
		dispatch(getmyProfile);
		dispatch(getMonthlyInvoice);
		dispatch(getAllUsers);
		dispatch(AutoDebitAPI);
	}, []);
	// if (memberProfile?.user_type == 'enterprise' && memberProfile?.status == 0) {
	// 	dispatch(logoutUser());
	// 	// setOpenDialog1(true)
	// }

	useEffect(() => {
		dispatch(getmembersInEnterprise(query, currentPage));
	}, [query, currentPage, refresh]);

	useEffect(() => {
		setArrayState(allMembersRecord);
		setTotalPages(totalMemberPages);
	}, [JSON.stringify(allMembersRecord), query, currentPage, refresh]);

	useEffect(() => {
		setIsCheckAll(false);
		setSelectedEmails([]);
		// setIsCheck(false);
		setIsCheck([]);
		// allMembers();
		setIsCheckAll(false);
		// console.log("hello");
	}, [currentPage, query, refresh]);

	useEffect(() => {
		setAmountRecord(totalAmountEnterprise);
	}, [JSON.stringify(totalAmountEnterprise)]);

	const handleOpenDialog = memberId => {
		setOpenDialog(true);
		setMemberToDeleteId(memberId);
	};

	//DeleteAPi
	const handleDelete = async id => {
		dispatch(deleteMemberByEnterprise(id));
		dispatch(getmembersInEnterprise(query, currentPage));
	};

	// Search functionality
	const handleSearch = event => {
		const query = event.target.value;
		// console.log(query, 'employee123');
		setQuery(query);
		setCurrentPage(1);
	};
	const openAccountStatusDialogue = () => {
		setAccountStatus(true);
	};
	const closeAccountStatusDialogue = () => {
		setAccountStatus(false);
	};
	const handleOpenImportFile = () => {
		// if(!bankAccountDetails){
		//   openAccountStatusDialogue()
		//   return
		// }
		// if (user.is_stripe_configured === 0) {
		// 	openAccountStatusDialogue();
		// 	return;
		// }
		setShowImportFile(true);
	};

	const handleCloseImportFile = () => {
		setShowImportFile(false);
	};

	const handleChange = (e, id) => {
		// console.log('eeeee', e.target.checked);
		let payment_status;
		if (e.target.checked) {
			payment_status = '1';
		} else {
			payment_status = '0';
		}
		// const id=3;

		dispatch(paymentStatusUpdate(id, payment_status));
	};

	// onclick Add new user show dailog card
	const [open, setOpen] = useState(false);
	const [show, setShow] = useState(false);
	const [openBilling, setOpenBilling] = useState(false);
	const [amountRecord, setAmountRecord] = useState({});
	const { recordUser, isObject, user, bankAccountDetails } = useSelector(
		state => state.user
	);
	const [selectedEmails, setSelectedEmails] = useState([]);
	const [paymentConfirmedModal, setPaymentConfirmedModal] = useState(false);
	const [grandTotal, setGrandTotal] = useState(0);

	const handleOpen = () => {
		// if(!bankAccountDetails){
		//   openAccountStatusDialogue()
		//   return
		// }
		// if (user?.is_stripe_configured === 0) {
		// 	openAccountStatusDialogue();
		// 	return; VS
		// }
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	// pay modal  Apis
	let totalUser = selectedEmails.length;

	const totalCharges = async (email, type, totalUser, grandTotal) => {
		dispatch(totalChargesInEnterprise(email, type, totalUser, grandTotal));
	};

	const handleOpenPayment = () => {
		if (selectedEmails.length === 0) {
			// No user selected, display error toast message
			// console.log('Please select at least one patient.');
			toast.error('Please select at least one patient.');
			return;
		}

		let totalUser = selectedEmails.length; // Get the count of selected users

		if (totalUser === 1) {
			totalCharges(selectedEmails[0], 'singleUser', totalUser);
		} else {
			totalCharges(selectedEmails, 'multiUsers', totalUser);
		}
		setShow(true);
	};

	const handlePaymentCOnfirmedOpen = () => {
		setPaymentConfirmedModal(true);
	};

	const handleClosePayment = () => {
		setShow(false);
		handleCloseImportFile();
		handleClose();
		setStep(0);
	};

	const handleSelectAll = () => {
		const selectedEmails = arrayState
			.filter(row => row.payment_status !== '0' && row.status !== 'on')
			.map(row => row.email);

		setIsCheckAll(!isCheckAll);
		setSelectedEmails(isCheckAll ? [] : selectedEmails);

		// Calculate the GrandTotal based on selected checkboxes
		const selectedTotal = arrayState
			.filter(row => selectedEmails.includes(row.email))
			.reduce((total, row) => total + parseFloat(row.total_amount), 0);

		setGrandTotal(selectedTotal);
	};

	const handleClick = (id, checked) => {
		const selectedRow = arrayState.find(row => row.email === id);

		if (selectedRow.status === 'on' || selectedRow.payment_status === '0') {
			return; // Don't select checkboxes for this condition
		}

		setSelectedEmails(prevEmails => {
			if (checked) {
				return [...prevEmails, id];
			} else {
				return prevEmails.filter(email => email !== id);
			}
		});

		setIsCheckAll(selectedEmails.length === arrayState.length);

		// Calculate the GrandTotal based on selected checkboxes
		const selectedTotal = selectedRow ? selectedRow.total_amount : 0;
		setGrandTotal(prevTotal =>
			checked ? prevTotal + selectedTotal : prevTotal - selectedTotal
		);
	};

	const handlePaymentStatus = (e, row, paymentStatus) => {
		const userPaymentStatus = paymentStatus ? '0' : '1';
		dispatch(updateEnterprisePaymentStatus(row?.id, userPaymentStatus));
	};
	return (
		<>
			{showMembers ? (
				<>
					<div
						style={{
							display: 'flex',
							background: '#F8F9FE',
							height: '100vh',
						}}
					>
						<MemberSidebar showSidebar={true} showHelpButton={true} />
						<div
							style={{
								flex: 5,
								background: '#F8F9FE',
							}}
						>
							<Header showHeader={true} />
							<Box
								sx={{ background: '#f8f9fe' }}
								style={{ position: 'relative', top: '11%' }}
							>
								<Box
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
										justifyContent: 'space-between',
										margin: '23px 23px',
									}}
								>
									<Cards
										value={`$${monthlyExpenses !== undefined ? monthlyExpenses : ''
											}`}
										description='Monthly Expenses'
									/>
									<Cards
										value={EnrolledPendingUsers?.total_users}
										description='Enrolled Employees'
									/>
									<Cards
										value={EnrolledPendingUsers?.unpaid_users}
										description='Pending Response'
									/>
									<Cards value={auto_debit} description='Auto-Pay' />
								</Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										margin: '42px 22px',
									}}
								>
									<Box sx={{ display: 'flex', gap: '21px' }}>
										<TextField
											id='outlined-start-adornment'
											placeholder='Search'
											value={query}
											onChange={handleSearch}
											sx={{ marginLeft: '3px', width: '30ch' }}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<img src={search} alt='search' />
													</InputAdornment>
												),
											}}
										/>
										<Button
											style={{
												width: '80px ',
												height: '46px ',
												background: '#4157FF ',
												color: 'white ',
												borderRadius: '24px ',
												textTransform: 'none ',
												fontWeight: '700 ',
											}}
											onClick={handleOpenPayment}
										>
											Pay
										</Button>
									</Box>

									<Box style={{ display: 'flex', gap: '21px' }}>
										<PaymentGuidanceModel
											accountStatus={accountStatus}
											openAccountStatusDialogue={openAccountStatusDialogue}
											closeAccountStatusDialogue={closeAccountStatusDialogue}
										/>

										<Typography
											style={{
												cursor: 'pointer',
												marginTop: '10px',
												color: 'black',
												fontSize: '14px',
												textDecoration: 'underline',
												marginTop: '16px',
											}}
											onClick={handleOpenImportFile}
										>
											Invite By Groups
										</Typography>
										<Button
											style={{
												width: 'auto ',
												height: '46px',
												background: '#4157FF ',
												color: 'white ',
												borderRadius: '24px ',
												textTransform: 'none ',
												fontWeight: '700 ',
											}}
											onClick={handleOpen}
										>
											Invite Individually
										</Button>
									</Box>
								</Box>

								<Box>
									<Container maxWidth='xl' sx={{ mt: 4 }}>
										<Grid container spacing={2}></Grid>
										<TableContainer
											// style={{ maxHeight: '400px' }}
											component={Paper}
											sx={{ mt: 2 }}
										>
											<Table aria-label='simple table'>
												<TableHead>
													<TableRow style={{ backgroundColor: '#F8F9FE' }}>
														<TableCell>
															<Checkbox
																{...label1}
																type='checkbox'
																name='selectAll'
																id='selectAll'
																handleClick={handleSelectAll}
																isChecked={isCheckAll}
															/>
														</TableCell>
														<TableCell></TableCell>
														<TableCell
															padding='none'
															align='center'
															style={{
																paddingRight: '98px',

																fontSize: '12px ',
																fontWeight: 'bold ',

																color: '#23262F',
															}}
														>
															<span>
																EMPLOYEE{' '}
																<KeyboardArrowDownIcon
																	style={{
																		cursor: 'pointer',
																		margin: '-10px',
																		marginLeft: '-1px',
																	}}
																/>
															</span>
														</TableCell>

														{/* <TableCell className={classes.root} >PROVIDER</TableCell> */}
														<TableCell
															padding='none'
															style={{
																fontSize: '12px ',
																fontWeight: 'bold ',
																textAlign: 'center  ',
																color: '#23262F',
															}}
															// className={classes.rootMember}
															align='right'
														>
															<span>
																HEALTHCOIN ID{' '}
																<KeyboardArrowDownIcon
																	style={{
																		cursor: 'pointer',
																		margin: '-10px',
																		marginLeft: '-1px',
																	}}
																/>
															</span>{' '}
														</TableCell>
														<TableCell
															padding='none'
															style={{
																fontSize: '12px ',
																fontWeight: 'bold ',
																textAlign: 'center  ',
																color: '#23262F',
															}}
															// className={classes.rootMember}
															align='right'
														>
															<span>
																EMPLOYEE ID{' '}
																<KeyboardArrowDownIcon
																	style={{
																		cursor: 'pointer',
																		margin: '-10px',
																		marginLeft: '-1px',
																	}}
																/>
															</span>{' '}
														</TableCell>
														<TableCell
															padding='none'
															style={{
																fontSize: '12px ',
																fontWeight: 'bold ',
																textAlign: 'center  ',
																color: '#23262F',
															}}
															// className={classes.rootMember}
															align='right'
														>
															<span>
																INTERVAL{' '}
																<KeyboardArrowDownIcon
																	style={{
																		cursor: 'pointer',
																		margin: '-10px',
																		marginLeft: '-1px',
																	}}
																/>
															</span>
														</TableCell>
														<TableCell
															padding='none'
															style={{
																fontSize: '12px ',
																fontWeight: 'bold ',
																textAlign: 'center  ',
																color: '#23262F',
															}}
															// className={classes.rootMember}
															align='right'
														>
															<span>
																BENEFITS STATUS{' '}
																<KeyboardArrowDownIcon
																	style={{
																		cursor: 'pointer',
																		margin: '-10px',
																		marginLeft: '-1px',
																	}}
																/>
															</span>{' '}
														</TableCell>
														<TableCell
															padding='none'
															style={{
																fontSize: '12px ',
																fontWeight: 'bold ',
																textAlign: 'center  ',
																color: '#23262F',
															}}
															// className={classes.rootMember}
															align='right'
														>
															<span>
																PAYMENT STATUS{' '}
																<KeyboardArrowDownIcon
																	style={{
																		cursor: 'pointer',
																		margin: '-10px',
																		marginLeft: '-1px',
																	}}
																/>
															</span>{' '}
														</TableCell>
														<TableCell
															padding='none'
															style={{
																fontSize: '12px ',
																fontWeight: 'bold ',
																textAlign: 'center  ',
																color: '#23262F',
															}}
															// className={classes.rootMember}
															align='right'
														>
															<span>
																ACTION{' '}
																<KeyboardArrowDownIcon
																	style={{
																		cursor: 'pointer',
																		margin: '-10px',
																		marginLeft: '-1px',
																	}}
																/>
															</span>{' '}
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody sx={{ background: 'white' }}>
													{arrayState?.length === 0 || arrayState?.length === null || arrayState?.length === undefined ? (
														<TableRow>
															<TableCell
																colSpan={9}
																align='center'
																style={{
																	fontWeight: 'bold',
																	background: '#f8f9fe',
																}}
															>
																No Data Found
																{/* <CircularProgress  style={{width:'42px', color: 'blue',}}/> */}
															</TableCell>
														</TableRow>
													) : (
														arrayState?.map((row, index, id) => {
															{
																/* let payment;
															if (row.payment_status == '1') {
																payment = true;
															} else {
																payment = false;
															} */
															}
															const backgroundColor =
																row?.status === 'on' || row?.patient_status === 'on' ? '#FFA23A' : 'none';
															return (
																<>
																	<React.Fragment key={row.id}>
																		{/* {selectedRowIndex === -1 || selectedRowIndex === index ? ( */}
																		<TableRow
																			style={{
																				background: backgroundColor,
																				cursor: 'pointer',
																			}}
																			// onClick={() => handleRowClick(index)}
																			sx={{
																				'&:last-child td, &:last-child th': {
																					border: 0,
																				},
																			}}
																		>
																			<TableCell>
																				<Checkbox
																					{...label1}
																					key={row.id}
																					type='checkbox'
																					id={row.email} // Use the email value as the checkbox id
																					handleClick={e =>
																						handleClick(
																							row.email,
																							e.target.checked
																						)
																					}
																					isChecked={selectedEmails.includes(
																						row.email
																					)} // Check for email inclusion in selectedEmails
																				/>
																			</TableCell>

																			<TableCell align='right'>
																				<img
																					src={
																						row.profile_image
																							? 'https://api.healthcoinxchangeportal.com/' +
																							row.profile_image
																							: avatar
																					}
																					alt='avatar'
																					style={{
																						borderRadius: '22px ',
																						width: '39px',
																						height: '39px',
																					}}
																				// className={classes.imageCircleMember}
																				/>
																			</TableCell>
																			<TableCell
																				// onClick={() => handleRowClick(index)}
																				component='th'
																				scope='row'
																				onClick={() => navigate(`/employeeDetails_info/${row?.id}`)}
																			>
																				<span
																					style={{
																						fontWeight: 'bold',
																						color: '#3772FF ',
																						whiteSpace: 'pre-line',
																						wordBreak: 'break-word',
																					}}
																					key={row.id}
																				>
																					{formatName(row.name)}
																				</span>

																				<br />
																				<span className='textcolorchange'>
																					{row.email}
																				</span>
																			</TableCell>
																			<TableCell
																				// onClick={() => handleRowClick(index)}
																				align='center'
																				className='textcolorchange'
																				onClick={() => navigate(`/employeeDetails_info/${row?.id}`)}
																			>
																				{row?.healthcoin_id}
																			</TableCell>
																			<TableCell
																				// onClick={() => handleRowClick(index)}
																				align='center'
																				className='textcolorchange'
																				onClick={() => navigate(`/employeeDetails_info/${row?.id}`)}
																			>
																				{row?.employee_id}
																			</TableCell>
																			<TableCell
																				// onClick={() => handleRowClick(index)}
																				align='center'
																				className='textcolorchange'
																				onClick={() => navigate(`/employeeDetails_info/${row?.id}`)}
																			>
																				{row?.intervals}
																			</TableCell>
																			<TableCell align='center'>
																				{' '}
																				<Switch
																					{...label2}
																					checked={row?.benefit_status === '1'}
																				// checked={payment}
																				/>{' '}
																			</TableCell>
																			<TableCell align='center'>
																				<Switch
																					{...label2}
																					checked={row?.payment_status === '1'}
																				/>
																				{/* <Switch
																				
																					{...label2}
																					onChange={(e) =>handlePaymentStatus(e, row, payment)}
																					defaultChecked={payment}
																					disabled={payment}
																				/> */}
																			</TableCell>

																			<TableCell align='center'>
																				<img
																					src={EyeView}
																					alt='eyeView'
																					onClick={() => navigate(`/employeeDetails_info/${row?.id}`)}
																					className={classes.eyesMember}
																				/>
																				&nbsp;&nbsp;
																				<img
																					src={Delete}
																					alt='delete'
																					className={classes.eyesMember}
																					onClick={() => handleOpenDialog(row?.id)}
																				// onClick={() => handleDelete(row?.id)}
																				/>
																			</TableCell>
																		</TableRow>
																		{/* ) : null} */}
																	</React.Fragment>
																</>
															);
														})
													)}
												</TableBody>
											</Table>
										</TableContainer>
										{/* <Box
                      style={{
                        marginTop: "30px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Grid>
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={(event, page) => setCurrentPage(page)}
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                        />
                      </Grid>
                    </Box>{" "} */}
										<br />
									</Container>
								</Box>
								<Toaster />

								<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
									<DialogTitle>
										<IconButton
											onClick={() => setOpenDialog(false)}
											color='inherit'
											aria-label='close'
											style={{
												display: 'flex',
												marginLeft: 'auto',
												padding: '0px',
											}}
										>
											<Box
												style={{
													background: '#4157FF',
													width: '34px',
													height: '34px',
													fontSize: '11px',
													boxShadow: 'none',
													borderRadius: '24px',
												}}
											>
												<CloseIcon
													style={{ margin: '5px 5px', color: 'white' }}
												/>
											</Box>
										</IconButton>
									</DialogTitle>
									<Divider />
									<DialogContent>
										<p style={{ fontWeight: 600 }}>
											Are you sure want to delete this member?
										</p>
									</DialogContent>
									<DialogActions sx={{ justifyContent: 'center' }}>
										<Button
											onClick={() => handleDelete(memberToDeleteId)}
											variant='contained'
											color='error'
										>
											Delete
										</Button>
										<Button
											onClick={() => setOpenDialog(false)}
											variant='contained'
											style={{ background: '#4157FF' }}
										>
											Cancel
										</Button>
									</DialogActions>
								</Dialog>

								<User
									handleOpen={handleOpen}
									step={step}
									setStep={setStep}
									handleClose={handleClose}
									open={open}
									query={query}
									currentPage={currentPage}
									showMembers={showMembers}
									setShowMembers={setShowMembers}
								/>
								<MemberModel
									showImportFile={showImportFile}
									handleOpenImportFile={handleOpenImportFile}
									handleCloseImportFile={handleCloseImportFile}
									step={step}
									setStep={setStep}
									showMembers={showMembers}
									setShowMembers={setShowMembers}
									query={query}
									currentPage={currentPage}
								/>
								<MemberMakePayment
									show={show}
									handleOpen={handleOpenPayment}
									handleClose={handleClosePayment}
									setRefresh={setRefresh}
									refresh={refresh}
									total_per_employee_amount={
										amountRecord?.total_per_employee_amount
									}
									total_due={amountRecord?.total_due}
									selectedEmails={selectedEmails}
									grandTotal={grandTotal}
									totalUser={totalUser}
									handlePaymentCOnfirmedOpen={handlePaymentCOnfirmedOpen}
								/>
							</Box>
						</div>
					</div>
				</>
			) : (
				<BillingInfo
					settingTab={true}
					showMembers={showMembers}
					setShowMembers={setShowMembers}
					setStep={setStep}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			)}
		</>
	);
};

export default Members;
