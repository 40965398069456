import React, { useState, useEffect } from 'react';
import './employeeTable.css';
import Shape from '../../asset/image/Header/Shape.png';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import { makeStyles } from '@mui/styles';
import { Grid, Container } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import {
	EnterpriseById,
	getEnterpriseById,
	getEnterpriseInvoices,
	AddEnterpriseNote,
} from '../../redux/action/user';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CompanyDetails from './CompanyDetails';
import avatar from '../../asset/image/avatar.svg';

import {
	Table,
	TableHead,
	TableContainer,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import EyeView from '../../asset/image/eyeView.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Checkbox from './Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../redux/store';
import { setValue } from '../../redux/action/tabAction';
import StatusHandling from '../StatusHandling/StatusHandling';
import Spinner from '../SpinnerTrueFalse/Spinner';

const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };

// css
const useStyles = makeStyles({
	companySet: {
		marginLeft: '24px',
		fontWeight: 600,
		fontSize: '24px',
	},
	tabsetting: {
		fontWeight: '600 ',
		fontSize: '16px ',
		textTransform: 'none ',
		color: '#949494 !important',
		'&:focus': {
			color: '#000000 !important',
		},
		'&:active': {
			color: '#000000 !important',
		},
	},

	rootEmployer: {
		fontSize: '12px ',
		fontWeight: 'bold',
		textAlign: 'center ',
		color: '#23262F',
	},

	headingIconEmployer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	imageCircleEmployer: {
		borderRadius: '22px',
		width: '39px',
		height: '39px',
	},
	eyesEmployer: {
		cursor: 'pointer',
	},
	tablerow: {
		cursor: 'pointer',
	},
});
const EmployeeDetails = () => {
	// tab redux
	const value = useSelector(state => state.counter.value);
	const { enterpriseUser, enterpriseInvoices } = useSelector(
		state => state.user
	);

	const classes = useStyles();
	const { id } = useParams();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheckAll2, setIsCheckAll2] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const [companyData, setCompanyData] = useState(null);
	const [companyMembers, setCompanyMembers] = useState([]);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [load, setLoad] = useState(false);
	const [status, setStatus] = useState('true');
	const [tableData, setTableData] = useState([]);
	const [notes, setNotes] = useState('');
	const [path, setPath] = useState(
		<span
			style={{
				marginLeft: '10px',
				fontSize: '12px',
				fontWeight: '500',
				color: '#D0D1D2',
			}}
		>
			EMPLOYERS DETAILS
		</span>
	);
	const navigate = useNavigate();
	const [enterpriseId, setEnterpriseId] = useState(null);
	const [dataAvailable, setDataAvailable] = useState(false);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	// Get getEnterpriseData
	useEffect(() => {
		getEnterpriseData();
		dispatch(getEnterpriseById(id));
	}, [JSON.stringify(enterpriseUser), JSON.stringify(enterpriseInvoices)]);

	const getEnterpriseData = async () => {
		setLoad(true);
		setCompanyData(enterpriseUser?.enterprise);
		setCompanyMembers(enterpriseUser?.enterprise_members);
		setLoad(false);

		const enterpriseId = enterpriseUser?.enterprise.id;
		setEnterpriseId(enterpriseId);
		// console.log(enterpriseId, "hellopakistan")
		await getInvoiceByMonth(enterpriseId);
		//

		setDataAvailable(enterpriseUser?.enterprise !== null);
		setLoading(false);
	};

	// useEffect(() => {
	//   getInvoiceByMonth()
	// }, [])
	// console.log("enterpriseInvoicesenterpriseInvoices",enterpriseInvoices)
	const getInvoiceByMonth = async enterpriseId => {
		if (enterpriseId) {
			dispatch(getEnterpriseInvoices(enterpriseId));
			setTableData(enterpriseInvoices);
		}
	};

	const handleClickExpensiveDetails = month => {
		navigate(`/invoicesDetails/${month}/${enterpriseId}`);
	};

	const handleChange = (event, newValue) => {
		dispatch(setValue(newValue));
	};
	// member CheckBox
	const handleSelectAll = e => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(companyMembers?.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, 'checkall');
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};

	// invoice Checkbox

	const handleSelectAllCheckbox = e => {
		setIsCheckAll2(!isCheckAll2);
		setIsCheck(tableData?.map(li => li.id));
		if (isCheckAll2) {
			setIsCheck([]);
		}
	};
	const handleClick2 = e => {
		const { id, checked } = e.target;
		// console.log(id, 'checkall');
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};
	// save notes
	const handleNotesChange = event => {
		setNotes(event.target.value);
	};
	const handleSendNote = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
		const day = String(currentDate.getDate()).padStart(2, '0');
		const hours = String(currentDate.getHours()).padStart(2, '0');
		const minutes = String(currentDate.getMinutes()).padStart(2, '0');
		const seconds = String(currentDate.getSeconds()).padStart(2, '0');
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

		// Send the formatted date and time along with notes
		dispatch(AddEnterpriseNote(id, notes, formattedDate));
	};
	useEffect(() => {
		// Update the 'notes' state when 'getDetailsEmergency' changes
		if (enterpriseUser?.enterprise?.notes) {
			setNotes(enterpriseUser?.enterprise?.notes);
		}
	}, [enterpriseUser]);
	return (
		<>
			{loading ? (
				<Spinner />
			) : !dataAvailable ? (
				<StatusHandling />
			) : (
				<div className='home'>
					<div>
						<Sidebar showSidebar={false} showHelpButton={false} />
					</div>
					<div className='homeContainer'>
						<Header showHeader={true} currentPath={path} />

						<Container
							style={{
								position: 'relative',
								top: '78px',
								background: '#F8F9FE',
							}}
							maxWidth='xl'
						>
							<Grid container Spacing={2}>
								<Box sx={{ width: '100%', typography: 'body1' }}>
									<p className={classes.companySet}>Company Details</p>
									<CompanyDetails
										companyData={companyData}
										handleSendNote={handleSendNote}
									/>
									<TabContext sx={{ mt: 2 }} value={value}>
										<Box>
											<TabList
												onChange={handleChange}
												aria-label='lab API tabs example'
												sx={{ marginLeft: '30px', marginTop: '22px' }}
											>
												<Tab
													sx={{ minWidth: 'unset', width: '30px' }}
													className={classes.tabsetting}
													label='Info'
													value='1'
													onClick={() =>
														setPath(
															<span
																style={{
																	marginLeft: '10px',
																	fontSize: '12px',
																	fontWeight: '500',
																	color: '#D0D1D2',
																}}
															>
																EMPLOYERS DETAILS
															</span>
														)
													}
												/>
												<Tab
													sx={{
														minWidth: 'unset',
														width: '71px',
														marginLeft: '20px',
													}}
													className={classes.tabsetting}
													label='Members'
													value='2'
													onClick={() =>
														setPath(
															<span
																style={{
																	marginLeft: '10px',
																	fontSize: '12px',
																	fontWeight: '500',
																	color: '#D0D1D2',
																}}
															>
																EMPLOYERS{' '}
																<span
																	style={{
																		fontSize: '15px',
																		marginLeft: '10px',
																	}}
																>
																	{' '}
																	<img src={Shape} alt='' />
																</span>
																<span
																	style={{
																		marginLeft: '10px',
																		fontSize: '12px',
																		fontWeight: '500',
																		color: '#D0D1D2',
																	}}
																>
																	MEMBERS
																</span>
															</span>
														)
													}
												/>
												<Tab
													sx={{
														minWidth: 'unset',
														width: '65px',
														marginLeft: '20px',
													}}
													className={classes.tabsetting}
													label='Invoices'
													value='3'
													onClick={() =>
														setPath(
															<span
																style={{
																	marginLeft: '10px',
																	fontSize: '12px',
																	fontWeight: '500',
																	color: '#D0D1D2',
																}}
															>
																EMPLOYERS{' '}
																<span
																	style={{
																		fontSize: '15px',
																		marginLeft: '10px',
																	}}
																>
																	{' '}
																	<img src={Shape} alt='' />
																</span>
																<span
																	style={{
																		marginLeft: '10px',
																		fontSize: '12px',
																		fontWeight: '500',
																		color: '#D0D1D2',
																	}}
																>
																	INVOICES
																</span>
															</span>
															// <div style={{ display: "flex", gap: "6px" }}>
															//   <span style={{ marginLeft: "5px", fontSize: '12px', fontWeight: '500', color: '#D0D1D2' }}>
															//     EMPLOYERS
															//  {" "}
															//   <span style={{ marginLeft: "5px", fontSize: '12px', fontWeight: '500', color: '#D0D1D2' }}> <img src={Shape} alt="" /></span>
															//     <span style={{ fontSize: '12px', fontWeight: '500', color: '#D0D1D2' }}>INVOICES</span>
															//   </span>
															// </div>
														)
													}
												/>
											</TabList>
										</Box>
										<TabPanel value='1'>
											<Box
												style={{
													width: '100%',
													height: 'auto',
													borderRadius: '2px',
													margin: 'auto',
													backgroundColor: 'white',
													display: 'flex',
													justifyContent: 'space-between',
												}}
											>
												<Grid
													sx={{ mt: 2, ml: 3 }}
													style={{
														fontWeight: '500 ',
														fontSize: '14px ',
														color: '#000000',
														fontFamily: 'DM sans',
													}}
												>
													<p>
														<span style={{ fontWeight: 600 }}>
															Company Name: &nbsp;
														</span>{' '}
														{companyData?.company}
													</p>
													<p>
														<span style={{ fontWeight: 600 }}>
															Enterprise Name: &nbsp;
														</span>{' '}
														{companyData?.name}
													</p>
													<p>
														<span style={{ fontWeight: 600 }}>
															Phone number: &nbsp;
														</span>{' '}
														{companyData?.phone}
													</p>
													<p>
														{' '}
														<span style={{ fontWeight: 600 }}>
															Address: &nbsp;
														</span>{' '}
														{companyData?.company_address}
													</p>
													<hr style={{ width: 'auto' }} />
													{enterpriseUser?.enterprise?.notes ? (
														enterpriseUser?.enterprise?.notes.map(
															(note, index) => (
																<div key={index}>
																	{note.note ? (
																		<p
																			style={{
																				maxWidth: '1115px',
																				wordBreak: 'break-all',
																			}}
																		>
																			Admin Notes:{' '}
																			<span>
																				{note.timestamp}:&nbsp; {note.note}
																			</span>
																		</p>
																	) : (
																		<p>Admin Notes</p>
																	)}
																</div>
															)
														)
													) : (
														<p>No notes available</p>
													)}
												</Grid>
											</Box>
											<Box style={{ width: '100%', marginTop: '30px' }}>
												<TextareaAutosize
													style={{ width: '100%', padding: '10px' }}
													aria-label='minimum height'
													minRows={6}
													placeholder='Notes'
													onChange={handleNotesChange}
												/>
											</Box>
										</TabPanel>
										{/*  */}
										<TabPanel value='2'>
											<TableContainer component={Paper}>
												<Table
													sx={{ minWidth: 650 }}
													style={{
														overflowX: 'auto',
														overflowWrap: 'anywhere',
													}}
													aria-label='simple table'
												>
													<TableHead>
														<TableRow style={{ backgroundColor: '#F8F9FE' }}>
															{/* <TableCell>
                              <Checkbox
                                {...label1}
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                handleClick={handleSelectAll}
                                isChecked={isCheckAll}
                              />
                            </TableCell> */}
															<TableCell></TableCell>
															<TableCell
																padding='none'
																sx={{
																	fontSize: '12px',
																	fontWeight: 'bold',
																	color: '#23262F ',
																}}
															>
																PATIENT NAME
																<KeyboardArrowDownIcon
																	sx={{ marginBottom: '-8px' }}
																/>
															</TableCell>
															<TableCell
																padding='none'
																sx={{
																	fontSize: '12px ',
																	fontWeight: 'bold',
																	color: '#23262F',
																}}
															>
																EMPLOYEE ID
																<KeyboardArrowDownIcon
																	sx={{ marginBottom: '-8px' }}
																/>
															</TableCell>
															<TableCell
																padding='none'
																sx={{
																	fontSize: '12px ',
																	fontWeight: 'bold ',
																	color: '#23262F',
																}}
															>
																PAYMENT STATUS
																<KeyboardArrowDownIcon
																	sx={{ marginBottom: '-8px' }}
																/>
															</TableCell>
															<TableCell
																padding='none'
																sx={{
																	fontSize: '12px',
																	fontWeight: 'bold',
																	color: '#23262F',
																}}
															>
																MONTHLY TOTAL
																<KeyboardArrowDownIcon
																	sx={{ marginBottom: '-8px' }}
																/>
															</TableCell>
															<TableCell
																align='center'
																padding='none'
																sx={{
																	fontSize: '12px',
																	fontWeight: 'bold',
																	color: '#23262F',
																}}
															>
																INTERVAL
																<KeyboardArrowDownIcon
																	sx={{ marginBottom: '-8px' }}
																/>
															</TableCell>
															<TableCell
																padding='none'
																sx={{
																	fontSize: '12px',
																	fontWeight: 'bold',
																	color: '#23262F',
																}}
															>
																ACTION
																<KeyboardArrowDownIcon
																	sx={{ marginBottom: '-8px' }}
																/>
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{companyMembers?.length === 0 ? (
															<TableRow>
																<TableCell
																	colSpan={9}
																	align='center'
																	style={{
																		fontWeight: 'bold',
																		background: '#f8f9fe',
																	}}
																>
																	No Data Found
																</TableCell>
															</TableRow>
														) : (
															companyMembers?.map((row, index, id) => {
																return (
																	<>
																		<React.Fragment key={row.id}>
																			<TableRow
																				className={classes.tablerow}
																				style={{ backgroundColor: '#fff' }}
																				sx={{
																					'&:last-child td, &:last-child th': {
																						border: 0,
																					},
																				}}
																			>
																				{/* <TableCell>
                                      <Checkbox
                                        {...label1}
                                        type="checkbox"
                                        key={row.id}
                                        // name={name}
                                        id={row.id}
                                        handleClick={handleClick}
                                        isChecked={isCheck.includes(row.id)}
                                      />
                                    </TableCell> */}

																				<TableCell align='center'>
																					<img
																						src={
																							row?.profile_image
																								? 'https://api.healthcoinxchangeportal.com/' +
																								row?.profile_image
																								: avatar
																						}
																						alt='avatar'
																						className={
																							classes.imageCircleEmployer
																						}
																					/>
																				</TableCell>
																				<TableCell
																					//   onClick={() => handleRowClick(index)}
																					component='th'
																					scope='row'
																				>
																					<span
																						style={{
																							fontWeight: 'bold',
																							color: '#3772FF',
																						}}
																					>
																						{row?.name}
																					</span>
																					<br />
																					<span
																						className='textcolorchange'
																						style={{ textAlign: 'center' }}
																					>
																						HC ID:{row?.healthcoin_id}
																					</span>
																				</TableCell>
																				<TableCell align='center'>
																					{row?.employee_id}
																				</TableCell>
																				<TableCell align='center'>
																					{row?.payment_status == '0'
																						? 'Unpaid'
																						: 'Paid'}
																				</TableCell>
																				<TableCell align='center'>
																					{row.monthly_total}
																				</TableCell>
																				<TableCell align='center'>
																					{row?.intarval}
																				</TableCell>
																				<TableCell align='center'>
																					{' '}
																					<img
																						src={EyeView}
																						alt='eyeView'
																						className={classes.eyesEmployer}
																					/>{' '}
																				</TableCell>
																			</TableRow>
																			{/* ) : null} */}
																		</React.Fragment>
																	</>
																);
															})
														)}
													</TableBody>
												</Table>
											</TableContainer>
										</TabPanel>
										{/*  */}
										<TabPanel value='3'>
											<TableContainer
												component={Paper}
												style={{ width: '100%' }}
												sx={{ mt: 2 }}
											>
												<Table aria-label='simple table'>
													<TableHead style={{ backgroundColor: '#F8F9FA' }}>
														<TableRow style={{ backgroundColor: '#F8F9FE' }}>
															{/* <TableCell
                              style={{ padding: "-0px", margin: "-12px" }}
                            >
                              <Checkbox
                                {...label1}
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                handleClick={handleSelectAllCheckbox}
                                isChecked={isCheckAll2}
                                size="small"
                              />
                            </TableCell> */}
															<TableCell
																style={{ padding: '0px', margin: '0px' }}
																className={classes.rootEmployer}
															>
																{' '}
																<span className={classes.headingIconEmployer}>
																	MONTH <KeyboardArrowDownIcon />
																</span>
															</TableCell>
															<TableCell
																style={{ padding: '0px', margin: '0px' }}
																className={classes.rootEmployer}
																align='center'
															>
																<span className={classes.headingIconEmployer}>
																	STATUS <KeyboardArrowDownIcon />
																</span>{' '}
															</TableCell>
															<TableCell
																style={{ padding: '0px', margin: '0px' }}
																className={classes.rootEmployer}
																align='center'
															>
																{' '}
																<span className={classes.headingIconEmployer}>
																	{' '}
																	ACTION <KeyboardArrowDownIcon />
																</span>
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody
														sx={{ background: 'white' }}
														enterpriseId={enterpriseId}
													>
														{tableData?.length === 0 ? (
															<TableRow>
																<TableCell
																	colSpan={9}
																	align='center'
																	style={{
																		fontWeight: 'bold',
																		background: '#f8f9fe',
																	}}
																>
																	No Data Found
																</TableCell>
															</TableRow>
														) : (
															tableData?.map((data, index) => (
																<TableRow key={index}>
																	{/* <TableCell>
																	<Checkbox1
																		{...label1}
																		type="checkbox"
																		{...label1}
																		key={row.id}
																		// name={name}
																		id={row.id}
																		handleClick={handleClick}
																		isChecked={isCheck.includes(row.id)}
																	/>
																	</TableCell> */}
																	<TableCell
																		align='center'
																		className={classes.bodyCell}
																	>
																		Invoices of {data.month}
																	</TableCell>

																	<TableCell
																		style={{
																			padding: '10px',
																			margin: '0px',
																			opacity: 0.8,
																		}}
																		align='center'
																	>
																		{data?.status}
																	</TableCell>
																	<TableCell
																		style={{
																			padding: '10px',
																			margin: '0px',
																			opacity: 0.8,
																		}}
																		align='center'
																	>
																		<img
																			src={EyeView}
																			alt='eyeView'
																			className={classes.eyesEmployer}
																			// onClick={() => handleClickExpensiveDetails(row?.month)}
																			onClick={() =>
																				handleClickExpensiveDetails(
																					data?.month,
																					enterpriseId
																				)
																			}
																		/>{' '}
																	</TableCell>
																</TableRow>
															))
														)}
													</TableBody>
												</Table>
											</TableContainer>
										</TabPanel>
									</TabContext>
								</Box>
							</Grid>
						</Container>
					</div>
				</div>
			)}
		</>
	);
};

export default EmployeeDetails;
