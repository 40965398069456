import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import {
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
	Elements,
	ElementsConsumer,
} from '@stripe/react-stripe-js';
import './Styles.css';
import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';
const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
		
	},
	hidePostalCode: true, 
};
function CardSection() {
	return (
		<Stack gap={1}>
			<p
				style={{
					fontSize: '22px',
					fontWeight: 800,
					textAlign: 'center',
				}}
			>
				Add Debit/Credit Card
			</p>
			<br />

			<CardElement options={CARD_ELEMENT_OPTIONS} />
		</Stack>
	);
}
export default CardSection;
