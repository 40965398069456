import { InfinitySpin } from 'react-loader-spinner';
import React from 'react';
import './Spinner.css';
const Spinner = () => {
	return (
		<div className='spinnerCOntainer'>
			<div className='spinner'>
				<InfinitySpin
					width='300'
					color='#4157FF'
					// color="white"
				/>
			</div>
		</div>
	);
};
export default Spinner;
