import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import { useNavigate } from 'react-router-dom';
import { Grid, Container } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import upload_icon from '../../asset/image/upload_icon.svg';
import Dropzone from 'react-dropzone';
import MakePayment from '../Employer module/Setting/Make payment/MakePayment';
import PayConf from '../Employer module/Setting/Payment Confirmed/PayConf';
import axios from 'axios';
import { server } from '../../redux/store';
import { toast, Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
//css
const useStyles = makeStyles({
	billingtext: {
		fontWeight: ' 500 !important',
		fontSize: '14px !important',
		color: '#344054 !important',
	},
	TextFieldOutlined: {
		width: '512px !important',
		background: 'white !important',
	},
	dropzone: {
		width: '512px',
		height: '80%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '20px',
		borderWidth: '2px',
		borderRadius: '4px',
		borderColor: '#fff',
		borderStyle: 'groove',
		backgroundColor: '#fff',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
	uploadStyle: {
		fontSize: '14px',
		color: '#6941C6',
		fontWeight: '700',
		cursor: 'pointer',
	},
});
const BillingInfo = ({ setShowMembers, setStep, refresh, setRefresh }) => {
	// modal open
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [paymentConfirmedModal, setPaymentConfirmedModal] = useState(false);

	const [amountRecord, setAmountRecord] = useState({});
	const { recordUser, countUser, isObject, user, bankAccountDetails } =
		useSelector(state => state.user);

	const totalCharges = async email => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			let { data } = await axios.post(
				`${server}/StripePayoutToAdmin`,
				{
					totalUser: countUser,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);

			setAmountRecord(data);
		} catch (error) {
			// alert(error?.response?.data?.message);
			// console.log("error?.response?.data?.message",error?.response?.data?.message)
			toast.error(error?.response?.data?.message);
		}
	};
	const handleOpen = () => {
		totalCharges();
		setShow(true);
	};

	const handleClose = () => {
		setShow(false);
	};
	const handlePaymentCOnfirmedOpen = () => {
		setPaymentConfirmedModal(true);
	};

	const handlePaymentCOnfirmedClose = () => {
		setPaymentConfirmedModal(false);
		setShowMembers(true);

		navigate('/members');
		setStep(1);
		setRefresh(!refresh);
	};
	//
	const classes = useStyles();
	// useState
	const [value, setValue] = React.useState('1');
	//
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	//upload or drag and drop
	const UploadFile = () => {
		const classes = useStyles();
		const [file, setFile] = useState(null);

		const handleDrop = acceptedFiles => {
			setFile(acceptedFiles[0]);
		};

		return (
			<>
				<Dropzone onDrop={handleDrop}>
					{({ getRootProps, getInputProps, isDragActive }) => (
						<div
							className={classes.dropzone}
							{...getRootProps()}
							style={
								isDragActive
									? { borderColor: '#2196f3', backgroundColor: '#e3f2fd' }
									: {}
							}
						>
							<input {...getInputProps()} />
							<img
								src={upload_icon}
								alt='upload_icon'
								style={{
									display: 'flex ',
									justifyContent: 'center ',
									cursor: 'pointer ',
									marginTop: '6px',
									cursor: 'pointer',
								}}
							/>
							<span>
								{' '}
								<span className={classes.uploadStyle}>
									Click to upload &nbsp;
								</span>
								or drag and drop
							</span>
							<span>{file && file.name}</span>
							<span>SVG, PNG, JPG or GIF (max. 800x400px)</span>
						</div>
					)}
				</Dropzone>
			</>
		);
	};
	const accountNumber = bankAccountDetails?.account_number || '**************';
	const routing_number = bankAccountDetails?.routing_number || '**************';

	return (
		<div className='home'>
			<div>
				<Sidebar showSidebar={false} showHelpButton={false} />
			</div>
			<div className='homeContainer'>
				<Header showHeader={true} />

				<Container
					sx={{ background: '#f8f9fe' }}
					maxWidth='xl'
					style={{ position: 'relative', top: '40px' }}
				>
					<Sidebar showSidebar={true} showHelpButton={true} />

					<Grid container Spacing={2}>
						<Box sx={{ width: '100%', typography: 'body1' }}>
							<TabContext value={value}>
								{/*  */}
								{/* <TabPanel value='1' sx={{ mb: 12 }}>
									<span
										style={{
											fontWeight: '600 ',
											fontSize: '18px',
											fontFamily: 'DM Sans',
										}}
									>
										Billing Info
									</span>{' '}
									<br />
									<span
										style={{
											fontWeight: '500 ',
											fontSize: '18px ',
											fontFamily: 'DM Sans',
											color: '#667085 ',
										}}
									>
										
									</span>{' '}
									<br /> <br />
									<div>
										<Divider
											style={{
												width: '96%',
												marginBottom: '13px',
												marginLeft: '15px',
											}}
										/>{' '}
									</div>
									<div>
										<Divider style={{ width: '100%', marginBottom: '13px' }} />
									</div>
									<div>
										<Divider style={{ width: '96%', marginLeft: '15px' }} />
									</div>
									<br />
									<Grid container spacing={6}>
										<Grid item xs={6} md={4} sx={{ ml: 2 }}>
											<span className={classes.billingtext}>
												Account Number
											</span>
										</Grid>
										<Grid item xs={6} md={7}>
											<TextField
												disabled
												id='amount_enter'
												variant='outlined'
												style={{ color: 'black' }}
												className={classes.TextFieldOutlined}
												// value={bankAccountDetails?.account_number}
												value={accountNumber}
												InputProps={{
													readOnly: true,
													style: { color: 'black' },
												}}
											/>
										</Grid>
									</Grid>
									<Divider sx={{ mt: 2, mb: 2 }} />
									<Grid container spacing={6}>
										<Grid item xs={6} md={4} sx={{ ml: 2 }}>
											<span className={classes.billingtext}>
												Routing Number
											</span>
										</Grid>
										<Grid item xs={6} md={7}>
											<TextField
												disabled
												id='routing_number'
												variant='outlined'
												className={classes.TextFieldOutlined}
												// placeholder="Your Account Routing Number"
												// value={bankAccountDetails?.routing_number}
												value={routing_number}
												InputProps={{
													readOnly: true,
													style: { color: 'black' },
												}}
											/>
										</Grid>
									</Grid>
									<Divider sx={{ mt: 2, mb: 2 }} />
									<Grid container spacing={6}>
										<Grid item xs={6} md={4} sx={{ ml: 2 }}>
											<span className={classes.billingtext}>Auto-Debit</span>
										</Grid>
										<Grid item xs={6} md={7}>
											<Select
												disabled
												labelId='demo-simple-select-label'
												id='Auto_debit'
												// value={bankAccountDetails?.auto_debit}
												value={bankAccountDetails?.auto_debit || 'Yes'}
												className={classes.TextFieldOutlined}
												InputProps={{
													readOnly: true,
													style: { color: 'black' },
												}}
												// onChange={handleChange}
											>
												<MenuItem value='Yes'>Yes</MenuItem>
												<MenuItem value='No'>No</MenuItem>
											</Select>
										</Grid>
									</Grid>
									<Grid container spacing={6} sx={{ mt: 1 }}>
										<Grid item xs={6} md={4} sx={{ ml: 2 }}>
											<span className={classes.billingtext}>
												Initiation Date
											</span>
										</Grid>
										<Grid item xs={6} md={7}>
											<TextField
												disabled
												id='Initiation_date'
												variant='outlined'
												InputProps={{
													readOnly: true,
													style: { color: 'black' },
												}}
												className={classes.TextFieldOutlined}
												placeholder='Billing Start Date'
												// value={`${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()}`}
												value={`${new Date().getDate()}/${
													new Date().getMonth() + 1
												}/${new Date().getFullYear()}`}
											/>
										</Grid>
									</Grid>
									<Grid container sx={{ mt: 6 }}>
										<Grid item xs={6} md={9} sx={{ ml: 2 }}>
											<span
												style={{
													fontWeight: '600',
													fontSize: '14px ',
												}}
											>
												Note: Initiating payment with auto-debit will charge
												your account ONLY once your imported employees have
												fully onboarded. At any time you may swipe the blue tab
												next to an employee to pause their benefits and payment.{' '}
											</span>
										</Grid>
										<Grid item xs={6} md={2} sx={{ ml: 8 }}>
											<Button
												variant='contained'
												// disabled={(!(bankAccountDetails?.routing_number)||!(bankAccountDetails?.account_number))?true:false}
												size='large'
												style={{
													width: '167px',
													height: '46px ',
													borderRadius: '24px ',
													textTransform: 'none ',
													background: '#4157FF',
												}}
												onClick={handleOpen}
											>
												<span style={{ fontSize: '15px', fontWeight: '700' }}>
													Initiate Payment
												</span>
											</Button>
										</Grid>
									</Grid>
								</TabPanel> */}
								{/*  */}
							</TabContext>
						</Box>
					</Grid>
					<MakePayment
						show={show}
						handleOpen={handleOpen}
						handleClose={handleClose}
						total_per_employee_amount={amountRecord?.total_per_employee_amount}
						total_due={amountRecord?.total_due}
						handlePaymentCOnfirmedOpen={handlePaymentCOnfirmedOpen}
					/>
					<PayConf
						paymentConfirmedModal={paymentConfirmedModal}
						handlePaymentCOnfirmedClose={handlePaymentCOnfirmedClose}
						setRefresh={setRefresh}
						refresh={refresh}
					/>
				</Container>
			</div>
		</div>
	);
};

export default BillingInfo;
