import { Provider, useDispatch, useSelector } from 'react-redux';
import PageNotFound from '../component/PageNotFound/PageNotFound';
import { toast, Toaster } from 'react-hot-toast';
import { getmyProfile, setTokenBlog } from '../redux/action/user';
import Spinner from '../component/Spinner/Spiner';
import Login from '../component/Login/Login';
// patient  or Provider 1st Sprint/
import Providers from '../component/Table/Table';
import Detail from '../component/Request/Detail';
import RequestDetail from '../component/Request/RequestDetail';
import InfoDetail from '../component/Info/Info';
import Employer from '../component/AddNewEmployer/Employer';
import AddNewEmployee from '../component/AddNewEmployer/AddNewEmployeeModel';
import CompanyDetails from '../component/EmployeeDetails/CompanyDetails';
import EmployeeDetails from '../component/EmployeeDetails/EmployeeDetails';
import InvoicesDetails from '../component/EmployeeDetails/InvoicesDetails';

//enterprise or Members 2nd Sprint /
import Members from '../component/Employer module/Members/Member';
import Settings from '../component/Employer module/Setting/Settings';
import EmployeeDetailsInfo from '../component/Employer module/Members/MemberDetailsPage';
import InvoiceMonthTable from '../component/Employer module/Invoices/InvoiceMonthTable/InvoiceMonthTable';
import InvoicesAllMonthTable from '../component/Employer module/Invoices/InvoicesAllMonth/InvoicesAllMonthTable';
import AddEmployer from '../component/Employer module/AddEmployer/AddEmployer';
import ResetPassword from '../component/Auth/ResetPassword';
import GetCode from '../component/Auth/GetCode';
import TemPassword from '../component/Auth/TemPassword';
import RequireRoute from '../ProtectedRoutes';
import PubicRoute from '../PublicRoute';
import VerifyEmploye from '../component/Employer module/VerifyEmployer/VerifyEmploye';
import Accounts from '../component/Employer module/VerifyEmployer/Accounts';
import BillingInfo from '../component/BillingInfo/BillingInfo';
// Sprint 5
import PaymentDetails from '../component/PatientModule/PaymentRequest';
import PaymentRequestDetail from '../component/PatientModule/PaymentRequestDetail';
import TransactionDetailsPage from '../component/PatientModule/TransactionDetailsPage';
import SetEnterprisePassword from '../component/LinkSetPassword/SetEnterprisePassword';
import UserModule from '../component/Users/UsersPanel';
import InvoiceList from '../component/EnterpriseInvoices/InvoiceList';
import InvoicesPage from '../component/EnterpriseInvoices/InvoicesPage';
import HospitalsDisputes from '../component/Disputes/HospitalDisputes';
import HospitalsDisputesDetails from '../component/Disputes/HospitalsDisputesDetails';
import HospitalDisputes from '../component/Disputes/HospitalDisputes';
import StatusHandling from '../component/StatusHandling/StatusHandling';
import HandlingEnterprise from '../component/HandlingEnterprise/HandlingEnterprise';
import AuditLog from '../component/Users/AuditLog';

// second phase
import NetworkTable from '../component/Network/NetworkTable';
import NetworkTableDetails from '../component/Network/NetworkTableDetails';
import NetworkProviderDetails from '../component/Network/NetworkProviderDetails';

import AddServices from '../component/Network/AddServices';
import SelfPayFunc from '../component/SelfPay/SelfPayRecord';
import SelfPayDetail from '../component/SelfPay/selfPayDetail';
import EmergencyService from '../component/Emergency/EmergencyService';
import EmergencyServiceDetails from '../component/Emergency/EmergencyServiceDetails';
import SignupSelection from '../component/PatientRegistration/SignupSelection';
import PatientRegistration from '../component/PatientRegistration/PatientRegistration';
import LoginMemberFlow from '../component/PatientRegistration/loginMemberFlow';

export const UserRoutes = {
	sub_admin: [
		{ path: '/patient', component: <LoginMemberFlow /> },
		{ path: '/', component: <Providers /> },
		{ path: '/providers', component: <Providers /> },
		{ path: '/setting', component: <Detail /> },
		{ path: '/provider_info/:uid', component: <InfoDetail /> },
		{ path: '/employers', component: <Employer /> },
		{ path: '/addNewEmployer', component: <AddNewEmployee /> },
		{ path: '/companyDetails', component: <CompanyDetails /> },
		{ path: '/employers_Info/:id', component: <EmployeeDetails /> },
		{ path: '/invoicesDetails/:id/:hid', component: <InvoicesDetails /> },
		{ path: '/paymentDetails', component: <PaymentDetails /> },
		{ path: '/PaymentRequestDetail/:uid', component: <PaymentRequestDetail /> },
		{ path: '/AuditLog', component: <AuditLog /> },
		{
			path: '/TransactionDetailsPage/:provider_id',
			component: <TransactionDetailsPage />,
		},

		{ path: '/users', component: <UserModule /> },
		{
			path: '/invoice_list/:id/month/:month/year/:year',
			component: <InvoiceList />,
		},
		{ path: '/invoice_page', component: <InvoicesPage /> },
		{
			path: '/hospital_disputes_details/:uid',
			component: <HospitalsDisputesDetails />,
		},
		{ path: '/hospital_disputes', component: <HospitalDisputes /> },
		{ path: '/404', component: <StatusHandling /> },
		{ path: '/NetworkTable', component: <NetworkTable /> },
		{
			path: '/NetworkTableDetails/:network_id',
			component: <NetworkTableDetails />,
		},
		{
			path: '/NetworkProviderDetails/:uid',
			component: <NetworkProviderDetails />,
		},

		{ path: '/AddServices', component: <AddServices /> },
		{ path: '/self_pay_patient', component: <SelfPayFunc /> },
		{ path: '/self_pay_detail/:uid', component: <SelfPayDetail /> },
		{
			path: '/EmergencyServiceDetails/:uid',
			component: <EmergencyServiceDetails />,
		},
		{ path: '/EmergencyService', component: <EmergencyService /> },
	],
	patient: [
		{ path: '/', component: <LoginMemberFlow /> },
		{ path: '/patient_registration', component: <PatientRegistration /> },
	],
	enterprise: [
		// { path: "/members", component: <Members /> },
		{ path: '/', component: <Members /> },
		{ path: '/settings', component: <Settings /> },
		{ path: '/employeeDetails_info/:id', component: <EmployeeDetailsInfo /> },
		{ path: '/InvoiceMonthTable/:id', component: <InvoiceMonthTable /> },
		{ path: '/invoices', component: <InvoicesAllMonthTable /> },
		{ path: '/members', component: <Accounts /> },
		{ path: '/404', component: <HandlingEnterprise /> },
	],

	Public: [
		{ path: '/enterprise_signup', component: <AddEmployer /> },
		{ path: '/signup', component: <SignupSelection /> },
		{ path: '/patient_registration', component: <PatientRegistration /> },
		{ path: '/', component: <Login /> },
		{ path: '/resetpassword', component: <ResetPassword /> },
		{ path: '/getCode', component: <GetCode /> },
		{ path: '/setPassword', component: <TemPassword /> },
		{ path: '/SetEnterprisePassword', component: <SetEnterprisePassword /> },
	],
};
