import './SelfPay.css';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Rating from '@mui/material/Rating';
import Click from '../../asset/image/click.svg';
import EyeView from '../../asset/image/eyeView.svg';
import search from '../../asset/image/search.svg';
import Delete from '../../asset/image/delete.svg';
import Switch from '@mui/material/Switch';
import Spinner from '../SpinnerTrueFalse/Spinner';
import { Divider } from '@mui/material';
import verified from '../../asset/image/Group 3618.svg';
import unverified from '../../asset/image/Group 3617.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import {
	getSelfPayPatient,
	UpdatePatientStatus,
} from '../../redux/action/user';
import { FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Checkbox from './Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Pagination from '@mui/material/Pagination';
import avatar from '../../asset/image/avatar.svg';
import { FaCheck, FaTimes } from 'react-icons/fa';

// Css
const useStyles = makeStyles({
	root: {
		fontSize: '12px !important',
		fontWeight: 'bold !important',
		textAlign: 'center  !important',
		color: '#23262F !important',
	},
	bold: {
		fontWeight: 'bold !important',
		color: '#3772FF !important',
	},
	headingIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	headingIconProvider: {
		display: 'flex',
		// justifyContent: "center",
		alignItems: 'center',
	},
	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	rounded: {
		borderRadius: '22px !important',
	},
	eyes: {
		cursor: 'pointer',
	},
	verified: {
		color: '#00C48C',
		marginLeft: '6%',
		fontWeight: 'bold',
	},
	tablerow: {
		cursor: 'pointer',
	},
	centerValue: {
		display: 'flex',
		justifyContent: 'center',
	},
});
const label2 = { inputProps: { 'aria-label': 'Switch demo' } };
const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
const SelfPayFunc = () => {
	const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
	const [profileCheck, setProfileCheck] = useState(false);

	const dispatch = useDispatch();

	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const { providers, providerPages } = useSelector(state => state.user);
	const [load, setLoad] = useState(false);
	const navigate = useNavigate();
	const [arrayState, setArrayState] = useState([]);
	const [results, setResults] = useState([]);
	const [query, setQuery] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isActive, setIsActive] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(false);
	const { allSelfPayPatient } = useSelector(state => state.user);
	const handleRowClick = index => {
		setSelectedRowIndex(index);
	};
	const handleProviderClick = rowId => {
		navigate(`/self_pay_detail/${rowId}`);
	};
	const handleButtonClick = (id, status) => {
		const row = allSelfPayPatient.find(patient => patient.id === id);
		setCurrentStatus(row?.status == '0' ? true : false);
		dispatch(UpdatePatientStatus(id, status));
	};

	useEffect(() => {
		dispatch(getSelfPayPatient(query));
		setArrayState(allSelfPayPatient);
	}, [query]);

	const handleSearch = event => {
		const query = event.target.value;
		setQuery(query);
		setCurrentPage(1);
	};

	const activebuttonStyle = {
		backgroundColor: '#4CD964',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};
	const deActiveButtonStyle = {
		backgroundColor: 'rgb(232, 80, 91)',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};
	useEffect(() => {
		setIsCheckAll(false);
		setIsCheck([]);
		setTotalPages(providerPages);
	}, [JSON.stringify(providers), query, currentPage, currentStatus]);
	const classes = useStyles();
	const handleChange = (e, id) => {
		// console.log("eeeee", e.target.checked);
		let payment_status;
		if (e.target.checked) {
			payment_status = '1';
		} else {
			payment_status = '0';
		}
		// const id=3;

		// dispatch(paymentStatusUpdate(id, payment_status));
	};

	const handleSelectAll = e => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(arrayState.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, "checkall")
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};
	const formatName = location => {
		if (location?.length > 35) {
			return location.replace(/(.{35})/g, '$1\n');
		}
		return location;
	};

	return (
		<>
			{/* <Spinner showSpinner={isLoading} /> */}
			<div className='home'>
				<div>
					<Sidebar showSidebar={false} showHelpButton={false} />
				</div>
				<div className='homeContainer'>
					<Header showHeader={true} />
					<Container
						maxWidth='xl'
						sx={{ mt: 4 }}
						style={{
							position: 'relative',
							top: '122px',
							height: 'auto',
							background: '#f8f9fe',
						}}
					>
						<Grid container spacing={2}>
							<Grid md={10}>
								<TextField
									id='outlined-start-adornment'
									placeholder='Search'
									sx={{ marginLeft: '3px', width: '30ch' }}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<img src={search} alt='search' />
											</InputAdornment>
										),
									}}
									defaultValue={query}
									onChange={handleSearch}
								/>
							</Grid>
							{/* <Grid sx={{ mt: 1 }} md={2}>
                <Button
                  size="large"
                  style={{
                    width: "140px",
                    height: "46px",
                    textTransform: "none",
                    backgroundColor: "#4157FF",
                    fontFamily: "DM Sans",
                    float: "right",
                    fontWeight: "700",
                    fontFamily: "DM Sans",
                    borderRadius: "24px",
                    color: "white",
                  }}
                >
                  Send Invoice
                </Button>
              </Grid> */}
						</Grid>
						<TableContainer
							// style={{ maxHeight: "400px" }}
							component={Paper}
							sx={{ mt: 2 }}
						>
							<Table aria-label='simple table'>
								<TableHead>
									<TableRow sx={{ backgroundColor: '#F8F9FE' }}>
										{/* <TableCell ><Checkbox

                      {...label1}
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                    /></TableCell> */}
										<TableCell></TableCell>
										<TableCell className={classes.root} align='center'>
											<span className={classes.headingIconProvider}>
												COMPANY'S NAME
												<KeyboardArrowDownIcon />
											</span>
										</TableCell>
										<TableCell className={classes.root} align='right'>
											<span className={classes.headingIcon}>
												LOCATION <KeyboardArrowDownIcon />
											</span>{' '}
										</TableCell>
										<TableCell className={classes.root} align='right'>
											<span className={classes.headingIcon}>
												HEALTHCOIN ID <KeyboardArrowDownIcon />
											</span>{' '}
										</TableCell>

										<TableCell className={classes.root} align='right'>
											<span className={classes.headingIcon}>
												STATUS <KeyboardArrowDownIcon />
											</span>{' '}
										</TableCell>
										<TableCell className={classes.root} align='right'>
											<span className={classes.headingIcon}>
												ACTION <KeyboardArrowDownIcon />
											</span>{' '}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody sx={{ background: 'white' }}>
									{arrayState?.length === 0 ? (
										<TableRow>
											<TableCell
												colSpan={9}
												align='center'
												style={{ fontWeight: 'bold', background: '#f8f9fe' }}
											>
												No Data Found
											</TableCell>
										</TableRow>
									) : (
										allSelfPayPatient?.map((row, index, id) => {
											return (
												<>
													<React.Fragment key={row.id}>
														<TableRow
															className={classes.tablerow}
															// onClick={() => handleRowClick(index)}
															sx={{
																'&:last-child td, &:last-child th': {
																	border: 0,
																},
															}}
														>
															{/* <TableCell>
                                  <Checkbox
                                    {...label1}
                                    key={row.id}
                                    type="checkbox"
                                    // name={name}
                                    id={row.id}
                                    handleClick={handleClick}
                                    isChecked={isCheck.includes(row.id)}
                                  />
                                </TableCell> */}

															<TableCell align='right'>
																<img
																	src={
																		row.profile_image
																			? 'https://api.healthcoinxchangeportal.com/' +
																			row.profile_image
																			: avatar
																	}
																	// src={avatar}
																	alt='avatar'
																	className={classes.imageCircle}
																/>
															</TableCell>
															<TableCell
																//   onClick={() =>
																//     navigate(`/provider_info/${row.id}`)
																//   }
																component='th'
																scope='row'
															>
																<span className={classes.bold}>{row.name}</span>
																<br />
																<span className='textcolorchange'>
																	HC ID: {row.healthcoin_id}
																</span>
															</TableCell>
															<TableCell
																align='center'
																className='textcolorchange'
															>
																<span
																	key={row.location}
																	style={{
																		// fontWeight: "bold",
																		// color: "#3772FF",
																		whiteSpace: 'pre-line',
																		wordBreak: 'break-word',
																	}}
																>
																	{formatName(row?.location)}
																</span>
																{/* {row?.location} */}
															</TableCell>
															<TableCell
																align='center'
																className='textcolorchange'
															>
																{row?.healthcoin_id}
															</TableCell>
															<TableCell align='center'>
																<button
																	style={
																		!row?.status || row?.status === '0'
																			? deActiveButtonStyle
																			: activebuttonStyle
																	}
																	onClick={() =>
																		handleButtonClick(
																			row?.id,
																			row?.status,
																			row?.is_continue
																		)
																	}
																>
																	{!row?.status || row?.status === '0' ? (
																		<>
																			<FaTimes /> &nbsp; DEACTIVE
																		</>
																	) : (
																		<>
																			<FaCheck /> &nbsp; ACTIVE
																		</>
																	)}
																</button>
															</TableCell>

															<TableCell align='center'>
																<img
																	src={EyeView}
																	alt='eyeView'
																	onClick={() => handleProviderClick(row.id)}
																	className={classes.eyes}
																/>
																&nbsp;&nbsp;
																{/* <img
                                    src={Delete}
                                    alt="delete"
                                    className={classes.eyes}
                                  /> */}
															</TableCell>
														</TableRow>
													</React.Fragment>
												</>
											);
										})
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{/* <Box
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, page) => setCurrentPage(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Grid>
            </Box>{" "} */}
						<br />
					</Container>
				</div>
			</div>
		</>
	);
};
export default SelfPayFunc;
