import React, { useState, useEffect } from 'react';
// import "./employeeTable.css";
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import { makeStyles } from '@mui/styles';
import { Grid, Container, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { MdModeEdit } from 'react-icons/md';
import eyeView from '../../asset/image/eyeView.svg';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import avatar from '../../asset/image/avatar.svg';
import {
	Table,
	TableHead,
	TableContainer,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import EyeView from '../../asset/image/eyeView.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Checkbox from "./Checkbox";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../redux/store';
// import { setValue } from '../../redux/action/tabAction';
import StatusHandling from '../StatusHandling/StatusHandling';
import Spinner from '../SpinnerTrueFalse/Spinner';
import {
	getDetailOutOfNetwork,
	outOfNetwrokNotes,
	AdminPayPatientPayment,
} from '../../redux/action/user';

const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };

// css
const useStyles = makeStyles({
	companySet: {
		marginLeft: '24px',
		fontWeight: 600,
		fontSize: '24px',
	},
	tabsetting: {
		fontWeight: '600 ',
		fontSize: '16px ',
		textTransform: 'none ',
		color: 'black !important',
		'&:focus': {
			color: '#000000 !important',
		},
		'&:active': {
			color: '#000000 !important',
		},
	},
	rootEmployer: {
		fontSize: '12px ',
		fontWeight: 'bold',
		textAlign: 'center ',
		color: '#23262F',
	},

	headingIconEmployer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	imageCircleEmployer: {
		borderRadius: '22px',
		width: '39px',
		height: '39px',
	},
	eyesEmployer: {
		cursor: 'pointer',
	},
	tablerow: {
		cursor: 'pointer',
	},
});
const root = {
	fontSize: '12px',
	fontWeight: 'bold',
	textAlign: 'center',
	color: '#23262F',
	margin: '0',
	padding: '0',
};
const NetworkTableDetails = () => {
	// tab redux
	const value = useSelector(state => state.counter.value);
	const { enterpriseUser, enterpriseInvoices } = useSelector(
		state => state.user
	);
	const { getDetailOutOfNetworkRecord } = useSelector(state => state.user);
	const { network_id } = useParams();

	useEffect(() => {
		dispatch(getDetailOutOfNetwork(network_id));
	}, []);
	const classes = useStyles();
	const { id } = useParams();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheckAll2, setIsCheckAll2] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const [companyData, setCompanyData] = useState(null);
	const [companyMembers, setCompanyMembers] = useState([]);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [load, setLoad] = useState(false);
	const [status, setStatus] = useState('true');
	const [tableData, setTableData] = useState([]);
	const [path, setPath] = useState(
		<span
			style={{
				marginLeft: '10px',
				fontSize: '12px',
				fontWeight: '500',
				color: '#D0D1D2',
			}}
		>
			OUT OF NETWORK
		</span>
	);
	const navigate = useNavigate();
	const [enterpriseId, setEnterpriseId] = useState(null);
	const [dataAvailable, setDataAvailable] = useState(false);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const [editable, setEditable] = useState(false);
	const [value1, setValue1] = useState();
	const [amount, setAmount] = useState(0);
	const [notes, setNotes] = useState('');
	const [newAmount, setNewAmount] = useState(
		getDetailOutOfNetworkRecord?.amount
	);
	useEffect(() => {
		setNewAmount(getDetailOutOfNetworkRecord?.amount);
	}, [JSON.stringify(getDetailOutOfNetworkRecord)]);
	const handleEditClick = () => {
		setEditable(true);
	};
	const handleAmountChange = event => {
		setNewAmount(event.target.value);
	};
	const handleSaveClick = () => {
		setAmount(newAmount);
		setEditable(false);
	};

	const handleChange = (event, newValue) => {
		dispatch(setValue1(newValue));
	};
	// CheckBox
	const handleSelectAll = e => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(companyMembers?.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};
	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, "checkall");
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};

	// notes
	const handleNotesChange = event => {
		setNotes(event.target.value);
	};
	const handleSubmit = () => {
		// Get the current system default date and time and format it
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
		const day = String(currentDate.getDate()).padStart(2, '0');
		const hours = String(currentDate.getHours()).padStart(2, '0');
		const minutes = String(currentDate.getMinutes()).padStart(2, '0');
		const seconds = String(currentDate.getSeconds()).padStart(2, '0');
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

		// Send the formatted date and time along with notes
		dispatch(outOfNetwrokNotes(network_id, notes, formattedDate));
	};

	const handleSubmitPayment = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
		const day = String(currentDate.getDate()).padStart(2, '0');
		const hours = String(currentDate.getHours()).padStart(2, '0');
		const minutes = String(currentDate.getMinutes()).padStart(2, '0');
		const seconds = String(currentDate.getSeconds()).padStart(2, '0');
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

		dispatch(AdminPayPatientPayment(network_id, newAmount, formattedDate));
		dispatch(getDetailOutOfNetwork(network_id));
	};

	return (
		<>
			<div className='home'>
				<div>
					<Sidebar showSidebar={false} showHelpButton={false} />
				</div>
				<div className='homeContainer'>
					<Header showHeader={true} currentPath={path} />

					<Container
						style={{
							position: 'relative',
							top: '78px',
							background: '#F8F9FE',
						}}
						maxWidth='xl'
					>
						<Grid container Spacing={2}>
							<Box sx={{ width: '100%', typography: 'body1' }}>
								<p className={classes.companySet}>Out of Network Case</p>
								<div className='header'>
									<div className='main1234'>
										<div className='child-right'>
											<div>
												<img
													src={
														companyData?.profile_image
															? 'https://api.healthcoinxchangeportal.com/' +
															getDetailOutOfNetworkRecord?.patient
																?.profile_image
															: avatar
													}
													alt=''
													style={{
														borderRadius: '50px',
														width: '60px',
														height: '60px',
													}}
												/>
											</div>{' '}
											&nbsp;&nbsp;&nbsp;
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													boxSizing: 'border-box',
												}}
											>
												<span style={{ fontWeight: '700' }}>
													{getDetailOutOfNetworkRecord?.patient?.name}
												</span>
												<span style={{ color: '#6E757C', fontSize: '14px' }}>
													{getDetailOutOfNetworkRecord?.patient?.email}
												</span>
												<span>
													HC ID:{' '}
													{getDetailOutOfNetworkRecord?.patient?.healthcoin_id}
												</span>
											</div>
										</div>
										<div
											className='child-right'
											style={{
												display: 'flex',
												flexDirection: 'column',
												boxSizing: 'border-box',
											}}
										>
											<span style={{ color: '#6E757C', fontSize: '14px' }}>
												Total amount requested
											</span>
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												{editable ? (
													<>
														<input
															type='number'
															value={newAmount}
															min='0'
															onChange={handleAmountChange}
															style={{
																fontSize: '24px',
																fontWeight: '700',
																width: '123px',
																borderRadius: '6px',
															}}
														/>
														<button
															style={{
																width: '62px',
																height: '34px',
																background: '#4CD964',
																color: 'white',
																cursor: 'pointer',
															}}
															onClick={handleSaveClick}
														>
															Update
														</button>
													</>
												) : (
													<>
														<span
															style={{ fontSize: '24px', fontWeight: '700' }}
														>
															${newAmount}
														</span>
														<MdModeEdit
															style={{
																marginTop: '6px',
																marginLeft: '15px',
																cursor: 'pointer',
															}}
															onClick={handleEditClick}
														/>
													</>
												)}
											</div>
										</div>
										<div className='child-right' style={{ marginTop: '26px' }}>
											<button
												style={{
													background: '#4157FF',
													color: 'white',
													fontWeight: '700',
													height: '44px',
													cursor: 'pointer',
													display:
														getDetailOutOfNetworkRecord?.show === 'no'
															? 'none'
															: 'block',
												}}
												onClick={handleSubmitPayment}
											>
												Send Payment
											</button>
											<button
												style={{
													background: '#4CD964',
													color: 'white',
													fontWeight: '700',
													height: '44px',
													cursor: 'pointer',
												}}
												onClick={handleSubmit}
											>
												Save
											</button>
										</div>
									</div>
								</div>
								<TabContext sx={{ mt: 2 }} value1={value1}>
									<Box>
										<TabList
											onChange={handleChange}
											aria-label='lab API tabs example'
											sx={{ marginLeft: '30px', marginTop: '22px' }}
										>
											<Tab
												sx={{ minWidth: 'unset', width: '30px' }}
												className={classes.tabsetting}
												label='Info'
												value1='1'
											/>
											{/* <Tab
												className={classes.tabsetting}
												sx={{
													minWidth: 'unset',
													width: '171px',
													marginLeft: '30px',
												}}
												label={
													<div style={{ gap: '5px', display: 'flex' }}>
														<span>Uploaded</span>
														<span>Documents</span>
													</div>
												}
												value='2'
											/> */}
										</TabList>
									</Box>
									<TabPanel value1='1'>
										<TableContainer
											style={{
												margin: 'auto',
												width: '100%',
												overflow: 'auto',
											}}
											sx={{ mt: 2 }}
										>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell style={root}>
															<span className={classes.headingIcon}>
																FILE NAME
																<KeyboardArrowDownIcon
																	style={{ padding: '2px' }}
																/>
															</span>
														</TableCell>

														<TableCell style={root} align='center'>
															<span className={classes.headingIcon}>
																UPLOADED BY
																<KeyboardArrowDownIcon
																	style={{ padding: '2px' }}
																/>
															</span>
														</TableCell>

														<TableCell style={root} align='center'>
															<span className={classes.headingIcon}>
																ACTION
																<KeyboardArrowDownIcon
																	style={{ padding: '2px' }}
																/>
															</span>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody style={{ background: 'white' }}>
													{getDetailOutOfNetworkRecord?.document?.map(
														(item, index) => (
															<TableRow key={index}>
																<TableCell
																	align='center'
																	className={classes.bodyCell}
																	style={{
																		padding: '0px',
																		margin: '0px',
																		opacity: 0.8,
																		fontSize: '10px',
																		fontFamily: 'DM Sans',
																	}}
																>
																	{item?.document.split('/').pop()}
																	{/* {item?.document} */}
																</TableCell>
																<TableCell
																	align='center'
																	className={classes.bodyCell}
																	style={{
																		padding: '0px',
																		margin: '0px',
																		opacity: 0.8,
																		fontSize: '10px',
																		fontFamily: 'DM Sans',
																	}}
																>
																	{getDetailOutOfNetworkRecord?.patient?.name}
																</TableCell>
																<TableCell align='center'>
																	<Box sx={{ cursor: 'pointer' }}>
																		{/* <img src={eyeView} alt="" /> */}
																		<a
																			href={
																				'https://api.healthcoinxchangeportal.com/' +
																				item?.document
																			}
																			target='_blank'
																		>
																			<img
																				src={eyeView}
																				alt='eyeView'
																				className={classes.eyes}
																			/>
																		</a>
																	</Box>
																</TableCell>
															</TableRow>
														)
													)}
												</TableBody>
											</Table>
										</TableContainer>
										<br />
										<br />
										<Box
											style={{
												width: '96%',
												minHeight: '180px',
												borderRadius: '2px',
												margin: 'auto',
												backgroundColor: 'white',
												display: 'flex',
												flexDirection: 'column', // Stack content vertically
												justifyContent: 'space-between',
												overflow: 'auto',
											}}
										>
											<Grid
												sx={{ mt: 2, ml: 3 }}
												style={{
													fontWeight: '500 ',
													fontSize: '14px ',
													color: '#000000',
													fontFamily: 'DM sans',
												}}
											>
												<p
													style={{
														maxWidth: '1115px',
														wordBreak: 'break-all',
													}}
												>
													<span style={{ fontWeight: '600' }}>Comments: </span>
													<span>
														{' '}
														{getDetailOutOfNetworkRecord?.description || null}
													</span>
												</p>
												{getDetailOutOfNetworkRecord?.notes ? (
													getDetailOutOfNetworkRecord?.notes.map(
														(note, index) => (
															<div key={index}>
																{note.note ? (
																	<p
																		style={{
																			maxWidth: '1115px',
																			wordBreak: 'break-all',
																		}}
																	>
																		<span style={{ fontWeight: '600' }}>
																			Admin Notes:{' '}
																		</span>
																		<span>
																			{note?.timestamp}:&nbsp; {note?.note}
																		</span>
																	</p>
																) : (
																	<p>Admin Notes</p>
																)}
															</div>
														)
													)
												) : (
													<p>No notes available</p>
												)}
											</Grid>
										</Box>
										<br />
										<br />
										<Box
											sx={{
												display: 'flex',
												width: '96%',
												margin: 'auto',
												backgroundColor: '#fff',
												marginBottom: '15px',
											}}
										>
											<TextField
												fullWidth
												multiline
												placeholder='Notes:'
												name='description'
												rows={4}
												// value={notes}
												onChange={handleNotesChange}
												style={{ fontSize: '10px', color: '#6E757C' }}
											/>
										</Box>
									</TabPanel>
								</TabContext>
							</Box>
						</Grid>
					</Container>
				</div>
			</div>
			{/* )} */}
		</>
	);
};

export default NetworkTableDetails;
