const duplicateData = [
    {name:'abbas',email:'abbas@gamil.com' },
    {name:'abbas',email:'abbas@gamil.com' },
    {name:'john',email:'john@gamil.com' },
    {name:'jolly',email:'jolly@gamil.com' },
    {name:'jolly',email:'jolly@gamil.com' },
    {name:'abbas',email:'abbas@gamil.com' },
    {name:'abbas',email:'abbas@gamil.com' },
    {name:'john',email:'john@gamil.com' },
    {name:'jolly',email:'jolly@gamil.com' },
    {name:'jolly',email:'jolly@gamil.com' }, 
    {name:'abbas',email:'abbas@gamil.com' },
    {name:'abbas',email:'abbas@gamil.com' },
    {name:'john',email:'john@gamil.com' },
    {name:'jolly',email:'jolly@gamil.com' },
    {name:'jolly',email:'jolly@gamil.com' }, 
    {name:'abbas',email:'abbas@gamil.com' },
    {name:'abbas',email:'abbas@gamil.com' },
    {name:'john',email:'john@gamil.com' },
    {name:'jolly',email:'jolly@gamil.com' },
    {name:'jolly',email:'jolly@gamil.com' },

   
  ];
  
  export default duplicateData;