// import { TextField } from '@mui/material';
import React, { useState } from 'react';
import Header from '../Layout/Header/Header';
import Sidebar from '../Layout/Sidebar/Sidebar';
import './info.css';
import RequestDetail from '../Request/RequestDetail';
import { useParams } from 'react-router-dom';
import UploadedDocument from './UploadedDocument';
import { useEffect } from 'react';
import axios from 'axios';
import { server } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
// import {  Toaster } from "react-hot-toast";
import Dropzone from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import { toast, Toaster } from 'react-hot-toast';
import downloadIconSetting from '../../asset/image/downloadIconSetting.svg';
import InfoDropZone from '../../asset/image/InfoDropZone.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FaCheck } from 'react-icons/fa';
import EyeView from '../../asset/image/eyeView.svg';
import avatar from '../../asset/image/avatar.svg';
import Shape from '../../asset/image/Header/Shape.png';
import { useNavigate } from 'react-router-dom';
import StatusHandling from '../StatusHandling/StatusHandling';
import Spinner from '../SpinnerTrueFalse/Spinner';

import GooglePlacesAutocomplete, {
	geocodeByPlaceId,
	getLatLng,
} from 'react-google-places-autocomplete';
import {
	Table,
	TableHead,
	TableContainer,
	TableRow,
	TableCell,
	TableBody,
	Checkbox,
	Grid,
	Box,
	Divider,
	Tab,
	Paper,
	TextField,
	Button,
} from '@mui/material';
import {
	adminUpdateProfile,
	getProviderTransactionAction,
	getUserDatainProvider,
	updateProviderProfileByAdmin,
	AddProviderNote,
} from '../../redux/action/user';
const useStyles = makeStyles({
	root: {
		fontSize: '10px !important',
		fontWeight: 'bold !important',
		textAlign: 'center  !important',

		color: '#23262F !important',
	},
	bold: {
		fontWeight: 'bold !important',
		color: '#3772FF !important',
	},
	headingIcon: {
		display: 'flex',
	},

	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	rounded: {
		borderRadius: '22px !important',
	},
	eyes: {
		cursor: 'pointer',
	},
	verified: {
		color: '#00C48C',
		marginLeft: '6%',
		fontWeight: 'bold',
	},

	centerValue: {
		display: 'flex',
		justifyContent: 'center',
	},

	activebuttonStyle: {
		backgroundColor: '#4CD964',
		color: 'white',
		width: '91px',
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	},
	awaitingbuttonStyle: {
		backgroundColor: '#FFA23A',
		color: 'white',
		width: 'auto',
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	},
	tabsetting: {
		fontWeight: '500 !important',
		fontSize: '14px !important',
		textTransform: 'none !important',
		// color:'#6941C6 !important',
	},
	bilinginfo: {
		fontWeight: '600 !important',
		fontSize: '18px !important',
		fontFamily: 'DM Sans',
	},
	updateinfoLine: {
		fontWeight: '500 !important',
		fontSize: '18px !important',
		fontFamily: 'DM Sans',
		color: '#667085 !important',
	},
	billingtext: {
		fontWeight: ' 500 !important',
		fontSize: '14px !important',
		color: '#344054 !important',
	},
	TextFieldOutlined: {
		width: '512px !important',
		background: 'white !important',
	},
	paragraphNote: {
		fontWeight: '600 !important',
		fontSize: '14px !important',
	},

	updateinfoPersonal: {
		fontWeight: '500 !important',
		fontSize: '16px',
		fontFamily: 'DM Sans',
		color: '#667085',
	},

	twoColumn: {
		background: 'white',
		width: '244px',
	},
	phototext: {
		fontSize: '14px',
		color: '#667085',
	},

	uploadicon: {
		display: 'flex !important',
		justifyContent: 'center !important',
		cursor: 'pointer !important',
		marginTop: '6px',
	},

	dropzone: {
		boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
		width: '289px',
		minHeight: '127px',
		height: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '20px',
		borderWidth: '2px',
		borderRadius: '4px',
		border: '1px solid grey',
		borderColor: '#fff',
		borderStyle: 'groove',
		backgroundColor: '#fff',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
	uploadStyle: {
		fontSize: '14px',
		color: '#6941C6',
		fontWeight: '700',
		cursor: 'pointer',
	},

});
// Css
const InfoDetail = () => {
	const classes = useStyles();
	const [verifyButton, setVerifyButton] = useState(true);
	const [saveButton, setSaveButton] = useState(false);
	const [downloadAs, setDownloadAs] = useState(false);
	const [longitude, setLongitude] = useState('');
	const [latitude, setLatitude] = useState('');
	// const [getUserUpdatedData,setGetUserUpdatedData] = useState(getProviderUserDetail?getProviderUserDetail:{});
	const [providerTransaction, setProviderTransaction] = useState([]);
	const [ProviderTransactionAdminPercentage, setProviderTransactionAdminPercentage] = useState();
	const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const navigate = useNavigate();
	const handleSpanClick = () => {
		setVerifyButton(false);
		setSaveButton(true);
		setDownloadAs(false);
	};

	const handleVerifyButton = () => {
		setVerifyButton(true);
		setSaveButton(false);
		setDownloadAs(false);
	};

	const handleDownloadAs = () => {
		setVerifyButton(false);
		setSaveButton(false);
		setDownloadAs(true);
	};

	const [path, setPath] = useState('VERIFICATION');
	const [userprofile, setUserProfile] = useState(null);
	const [file, setFile] = useState(null);
	const { uid } = useParams();
	const { token } = useSelector(state => state.user);
	const [info, setInfo] = useState('Info');
	const [userData, setUserData] = useState({});
	const [userDataPhone, setUserDataPhone] = useState('');
	const [userDataPassword, setUserDataPassword] = useState('');
	const [userDataProviderProfile, setUserDataProviderProfile] = useState('');
	const [providerProfileImage, setProviderProfileImage] = useState('');
	const [isCheck, setIsCheck] = useState(false);
	const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
	const [dataAvailable, setDataAvailable] = useState(false);
	const [loading, setLoading] = useState(true);
	const [notes, setNotes] = useState('');
	const {
		updateProviderImage,
		getProviderUserDetail = '',
		getProviderTransactionRecord,
	} = useSelector(state => state.user);
	const [value, setValue] = useState({
		label: '',
		value: {},
	});
	// css

	const headLabel = {
		fontWeight: '600',
		fontSize: '14px',
	};
	const boxStyling = {
		width: '100%',
		borderRadius: '2px ',
		margin: 'auto ',
		backgroundColor: 'white ',
		display: 'flex ',
		justifyContent: 'space-between ',
	};
	const dispatch = useDispatch();
	//

	useEffect(() => {
		if (value) {
			// handlePlaceSelect()
		}
	}, [value]);

	const getProviderTransaction = async () => {
		dispatch(getProviderTransactionAction(uid));
		setProviderTransaction(getProviderTransactionRecord?.payments);
		setProviderTransactionAdminPercentage(getProviderTransactionRecord);
	};
	const getUser = async () => {
		dispatch(getUserDatainProvider(uid));
		setUserData(getProviderUserDetail);
		setProviderProfileImage(getProviderUserDetail?.profile_image);
		setUserDataPhone(getProviderUserDetail?.phone);
		setUserDataProviderProfile(
			getProviderUserDetail?.providerconsultation?.description
		);
		setValue({ ...value, label: getProviderUserDetail?.location });
		// data available
		setDataAvailable(getProviderUserDetail !== null);
		setLoading(false);
	};
	const updateProviderProfile = async e => {
		e.preventDefault();
		// console.log("userDataPhone", userDataPhone?.length);
		if (userDataPhone?.length < 7) {
			return setPhoneErrorMessage('Phone number must be at least 7 characters');
		} else if (userDataPhone?.length > 16) {
			setPhoneErrorMessage('Phone number have maximum 16 characters');
		} else if (userDataProviderProfile?.length > 500) {
			return setPhoneErrorMessage(
				'Provider profile  have maximum 500 characters'
			);
		} else {
			dispatch(
				updateProviderProfileByAdmin(
					uid,
					userDataPhone,
					userDataPassword,
					userDataProviderProfile,
					value?.label,
					latitude,
					longitude
				)
			);
		}
	};

	useEffect(() => {
		getProviderTransaction();
	}, [JSON.stringify(getProviderTransactionRecord)]);
	useEffect(() => {
		getUser();
	}, [JSON.stringify(getProviderUserDetail)]);

	const handleDrop = acceptedFiles => {
		const img = acceptedFiles[0];
		setUserProfile(acceptedFiles[0]);

		if (img) {
			const allowedFormats = [
				'image/svg+xml',
				'image/png',
				'image/jpeg',
				'image/jpg',
			];

			if (!allowedFormats.some(format => format.includes(img.type))) {
				toast.error('Invalid file format. Please upload an image file.');
				return;
			}

			// profileImgUpdate(img);
			dispatch(adminUpdateProfile(uid, img));
			setProviderProfileImage(updateProviderImage);
			// setFile(img);
			// setUserData({ ...userData, profile_image: img });
		}
	};

	const handlePlaceSelect = async value => {
		try {
			setValue(value);
			const results = await geocodeByPlaceId(value?.value?.place_id);
			if (results && results?.length > 0) {
				const { lat, lng } = await getLatLng(results[0]);
				setLatitude(lat);
				setLongitude(lng);
			}
		} catch (error) {
			console.error('Error fetching geolocation:', error);
		}
	};
	const changePhoneNumber = e => {
		const trimmedValue = e.target.value.trim(); // Trim whitespace from input value
		//   console.log("userDataPhoneLength", trimmedValue.length);

		setPhoneErrorMessage('');
		if (trimmedValue.length >= 7 && trimmedValue.length <= 16) {
			setUserDataPhone(trimmedValue);
		}
	};

	const changeUserDataProviderProfile = e => {
		if (userDataProviderProfile?.length <= 500) {
			setUserDataProviderProfile(e.target.value);
		}
	};
	// notes

	const handleNotesChange = event => {
		setNotes(event.target.value);
	};
	const handleSubmitNotes = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
		const day = String(currentDate.getDate()).padStart(2, '0');
		const hours = String(currentDate.getHours()).padStart(2, '0');
		const minutes = String(currentDate.getMinutes()).padStart(2, '0');
		const seconds = String(currentDate.getSeconds()).padStart(2, '0');
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		dispatch(AddProviderNote(uid, notes, formattedDate));
	};
	useEffect(() => {
		// Update the 'notes' state when 'getDetailsEmergency' changes
		if (getProviderUserDetail?.notes) {
			setNotes(getProviderUserDetail?.notes);
		}
	}, [getProviderUserDetail]);
	return (
		<>
			{loading ? (
				<Spinner />
			) : !dataAvailable ? (
				<StatusHandling showStatusHandling={false} />
			) : (
				<div className='detail'>
					<div className='home'>
						<Sidebar info={info == 'Transactions' ? 'Transactions' : ''} />
						<div className='homeContainer'>
							<Header
								currentPath=<span
									style={{
										marginLeft: '10px',
										fontSize: '12px',
										fontWeight: '500',
										color: '#D0D1D2',
									}}
								>
									PROVIDERS &nbsp; <img src={Shape} alt='' /> &nbsp;{' '}
									{info == 'Info' && 'INFO'}{' '}
									{info == 'upload' && 'UPLOADED DOCUMENTS'}{' '}
									{info == 'edit' && 'EDIT PROFILE'}{' '}
									{info == 'Transactions' && 'TRANSACTION'}
								</span>
							/>
							<div className='requestDetail'>
								<RequestDetail
									saveButton={saveButton}
									downloadAs={downloadAs}
									verifyButton={verifyButton}
									show={true}
									name={userData?.name}
									email={userData?.email}
									profile_image={providerProfileImage}
									id={userData?.id}
									healthcoin_id={userData?.healthcoin_id}
									page='Provider Details'
									updateProviderProfile={updateProviderProfile}
								/>
							</div>
							<div className='info-content'>
								<div className='info'>
									<br />
									<span
										className={(info === 'Info' || info === 'Info') && 'active'}
										style={{
											fontWeight: 600,
											color: `#949494`,
											fontSize: '14px',
										}}
										onClick={() => {
											setInfo('Info');
											handleVerifyButton();
										}}
									>
										Info &nbsp;
									</span>
									<span
										className={info == 'upload' && 'active'}
										style={{
											fontWeight: 600,
											color: `#949494`,
											fontSize: '14px',
										}}
										onClick={() => {
											setInfo('upload');
											handleVerifyButton();
										}}
									>
										Uploaded Documents &nbsp;
									</span>
									{/* abbas */}

									<span
										className={info == 'edit' && 'active'}
										style={{
											fontWeight: 600,
											color: `#949494`,
											fontSize: '14px',
										}}
										onClick={() => {
											setInfo('edit');
											handleSpanClick();
										}}
									>
										Manual Profile Edit &nbsp;
									</span>

									<span
										className={info == 'Transactions' && 'active'}
										style={{
											fontWeight: 600,
											color: `#949494`,
											fontSize: '14px',
										}}
										onClick={() => {
											setInfo('Transactions');
											handleDownloadAs();
										}}
									>
										Transactions &nbsp;
									</span>
								</div>
								{(info === 'info' || info === 'Info') && (
									<>
										<div className='detail-header'>
											<div className='detail-main'>
												<br />
												<div className='user-info'>
													{/* <span className='info-color'>NPI: 1234567890</span><br /> */}
													<span className='info-color'>
														<span className='fontWeight-info'>Professional License ID:&nbsp;</span>
														{userData?.professional_license_id}
													</span>
													<br />
													<span className='info-color'>
														<span className='fontWeight-info'>HC ID:&nbsp;</span> {userData?.healthcoin_id}
													</span>
													<br />
													<span className='info-color'>
														{/* {index!=(userData?.providerservice?.length-1)&&<>,</>} */}
														<span className='fontWeight-info'>Specialties:</span> &nbsp;
														{userData?.providerservice?.map((d1, index) => (
															<span style={{ opacity: 0.9 }}>
																{d1?.service_name}
																{index !=
																	userData?.providerservice?.length - 1 && (
																		<>, </>
																	)}{' '}
															</span>
														))}
													</span>
													<br />
													<span
														className='info-color'
														style={{ overflowWrap: 'break-word' }}
													>
														<span className='fontWeight-info'>Clinic Address:&nbsp;</span>	 {userData?.location}
													</span>
													<br />
													<span
														className='info-color'
														style={{ overflowWrap: 'break-word' }}
													>
														<span className='fontWeight-info'>Clinic Phone Number:&nbsp;</span> {userData?.phone}
													</span>
													<br />
													{/* <span className="info-color">Location: {userData?.location}</span>
                    <br /> */}
													<span
														className='info-color'
														style={{ overflowWrap: 'break-word' }}
													>
														<span className='fontWeight-info'>Provider Profile:&nbsp;</span>{' '}
														{userData?.providerconsultation?.description}
													</span>
													<br />
													<hr style={{ width: '97%', marginRight: '50px' }} />
													{getProviderUserDetail?.notes ? (
														getProviderUserDetail?.notes.map((note, index) => (
															<div key={index}>
																{note.note ? (
																	<p
																		style={{
																			maxWidth: '1115px',
																			wordBreak: 'break-all',
																		}}
																	>
																		<span style={{ fontWeight: '600' }}>Admin Notes:{' '}</span>
																		<span
																			style={{ color: 'black', opacity: '1' }}
																		>
																			{note.timestamp}:&nbsp; {note.note}
																		</span>
																	</p>
																) : (
																	<p>Admin Notes</p>
																)}
															</div>
														))
													) : (
														<p>No notes available</p>
													)}
													<hr style={{ width: '97%', marginRight: '50px' }} />
												</div>
											</div>
										</div>
										<div
											style={{
												width: '90%',
												height: 'auto',
												margin: 'auto',
											}}
										>
											<TextField
												fullWidth
												multiline
												placeholder='Notes:'
												name='description'
												rows={4}
												// value={notes}
												onChange={handleNotesChange}
												style={{
													fontSize: '10px',
													color: '#6E757C',
													marginTop: '5%',
												}}
											/>
											<br /> <br />
											<button
												style={{
													background: '#4157FF',
													color: 'white',
													fontWeight: '700',
													height: '44px',
													cursor: 'pointer',
													borderRadius: '20px',
													border: 'none',
													width: '12%',
												}}
												onClick={handleSubmitNotes}
											>
												Save Notes
											</button>
											<br />
											<br />
										</div>
										<br />
									</>
								)}
								{info === 'upload' && (
									<div>
										<UploadedDocument
											name={userData.name}
											profile_image={userData?.profile_image}
											frontid_image={userData?.frontid_image}
										/>
									</div>
								)}

								{info === 'edit' && (
									<div className='details_edit'>
										<div className='detail_main_edit'>
											<br />
											<div className='user_info_Update'>
												<Box>
													<Box
														sx={{
															display: 'flex',
															width: '61vw',
															justifyContent: 'space-between',
														}}
													>
														<Box style={{ marginTop: '40px' }}>
															<span style={{ ...headLabel, padding: '15px' }}>
																Provider Image{' '}
															</span>
														</Box>
														<Box
															style={{
																cursor: 'pointer',
																height: 'auto',
															}}
														>
															<Dropzone onDrop={handleDrop}>
																{({
																	getRootProps,
																	getInputProps,
																	isDragActive,
																}) => (
																	<div
																		className={classes.dropzone}
																		{...getRootProps()}
																		style={
																			isDragActive
																				? {
																					borderColor: '#2196f3',
																					backgroundColor: '#e3f2fd',
																					height: 'auto',
																				}
																				: {}
																		}
																	>
																		{/* <img src={downloadIconSetting} alt="" /> */}
																		<input {...getInputProps()} />
																		{/* <img
                                    src=""
                                    alt="upload_icon"
                                    style={{ cursor: "pointer" }}
                                    className={classes.uploadicon}
                                  /> */}
																		<Box sx={{ display: 'grid', gap: '20px' }}>
																			<Box
																				sx={{
																					display: 'flex',
																					justifyContent: 'center',
																				}}
																			>
																				<img src={InfoDropZone} alt='' />
																			</Box>

																			<Box>
																				<span
																					style={{
																						fontSize: '14px',
																						fontWeight: '400',
																						color: '#4157FF',
																						textDecoration: 'underline',
																					}}
																				>
																					Edit Provider Photo
																				</span>
																			</Box>
																		</Box>
																		<span
																			style={{
																				fontWeight: 900,
																				maxWidth: '250px',
																				overflowWrap: 'break-word',
																			}}
																		>
																			{userprofile && userprofile?.name}
																		</span>
																	</div>
																)}
															</Dropzone>
														</Box>
													</Box>

													<Divider
														style={{ marginTop: '10px', width: '72vw' }}
													/>
													<Box
														sx={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'space-between',
															width: '60vw',
															margin: '20px',
														}}
													>
														{/* Input field for First Name */}
														<Box sx={{ display: 'grid' }}>
															<Box>
																<span style={headLabel}>
																	Set Clinic Location
																</span>
															</Box>

															<div style={{ width: '289px' }}>
																<GooglePlacesAutocomplete
																	selectProps={{
																		value,
																		onChange: value => {
																			handlePlaceSelect(value);
																		},
																	}}
																	apiKey={process.env.REACT_APP_API_KEY}
																/>
															</div>
														</Box>

														<Box sx={{ display: 'grid' }}>
															<Box>
																<span style={headLabel}>
																	Clinic Phone Number
																</span>
															</Box>
															<Box>
																<TextField
																	// disabled
																	margin='dense'
																	fullWidth
																	InputProps={{ style: { height: '31px' } }}
																	inputProps={{ maxLength: 16 }}
																	sx={{
																		boxShadow:
																			'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																		width: '289px',
																	}}
																	name='employee_id'
																	defaultValue={userDataPhone}
																	onChange={changePhoneNumber}
																/>
																<p
																	style={{
																		color: 'red',
																		fontSize: '13px',
																		marginTop: '-3px',
																	}}
																>
																	{phoneErrorMessage}
																</p>
															</Box>
														</Box>
													</Box>

													<Box
														sx={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'space-between',
															width: '60vw',
															margin: '20px',
														}}
													>
														<Box>
															<span style={headLabel}>Reset Password</span>
															<br />

															<TextField
																margin='dense'
																fullWidth
																InputProps={{ style: { height: '31px' } }}
																sx={{
																	boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																	width: '289px',
																}}
																name='last_name'
																defaultValue={userDataPassword}
																onChange={e =>
																	setUserDataPassword(e.target.value)
																}
															/>
														</Box>

														<Box sx={{ display: 'grid' }}>
															<Box>
																<span style={headLabel}>Provider Profile </span>
															</Box>
															<Box>
																<TextField
																	fullWidth
																	multiline
																	name='message'
																	rows={4}
																	variant='outlined'
																	sx={{
																		boxShadow:
																			'rgba(0, 0, 0, 0.16) 0px 1px 4px',
																		width: '289px',
																	}}
																	inputProps={{ maxLength: 500 }}
																	defaultValue={userDataProviderProfile}
																	onChange={e =>
																		changeUserDataProviderProfile(e)
																	}
																/>
															</Box>
														</Box>
													</Box>

													{/* Save button */}
													<Box
														sx={{
															display: 'flex',
															justifyContent: 'flex-end',
															margin: '20px',
														}}
													>
														{/* <Button variant="contained" color="primary" onClick={handleSubmit}>
                                                Save
                                                </Button> */}
													</Box>
												</Box>

												{/* here Write the code  */}
											</div>
										</div>
									</div>
								)}

								{info === 'Transactions' && (
									<div>
										<div className='detail_main_patient'>
											<br />
											<div className='user_info_patient'>
												{/* here Write the code write table */}
												<div className='user_info_transactions'>
													<TableContainer
														style={{ width: '80vw' }}
														component={Paper}
													>
														<Table aria-label='simple table'>
															<TableHead>
																<TableRow sx={{ backgroundColor: '#F8F9FE' }}>
																	{/* <TableCell>
                                <div>
                                  <Checkbox
                                    {...label1}
                                    type="checkbox"
                                    name="selectAll"
                                    id="selectAll"
                                      handleClick={handleSelectAll}
                                      isChecked={isCheckAll}
                                  />
                                </div>
                              </TableCell> */}

																	{/* <TableCell className={classes.root} ><span className={classes.headingIcon}>PATIENT’S NAME<KeyboardArrowDownIcon /></span></TableCell> */}

																	<TableCell className={classes.root}>
																		<div
																			style={{
																				display: 'flex',
																				paddingLeft: '25px',
																			}}
																		>
																			<span style={{ fontSize: '12px' }}>
																				PATIENT’S
																			</span>{' '}
																			&nbsp;&nbsp;
																			<span style={{ fontSize: '12px' }}>
																				{' '}
																				NAME
																			</span>
																			<span>
																				<KeyboardArrowDownIcon />
																			</span>{' '}
																		</div>{' '}
																	</TableCell>
																	{/* <TableCell className={classes.root} ><span className={classes.headingIcon}>PROVIDER’S NAME<KeyboardArrowDownIcon /></span> </TableCell> */}

																	<TableCell className={classes.root}>
																		<div style={{ display: 'flex' }}>
																			<span
																				style={{
																					fontSize: '12px',
																					textTransform: 'uppercase',
																				}}
																			>
																				procedure
																			</span>
																			&nbsp;&nbsp;
																			<span
																				style={{
																					fontSize: '12px',
																					textTransform: 'uppercase',
																				}}
																			>
																				id
																			</span>
																			<span>
																				<KeyboardArrowDownIcon />
																			</span>{' '}
																		</div>{' '}
																	</TableCell>

																	<TableCell className={classes.root}>
																		<div style={{ display: 'flex' }}>
																			<span
																				style={{
																					fontSize: '12px',
																					textTransform: 'uppercase',
																				}}
																			>
																				{ProviderTransactionAdminPercentage?.patient_percentage}% payment
																			</span>
																			<span>
																				<KeyboardArrowDownIcon />
																			</span>{' '}
																		</div>{' '}
																	</TableCell>
																	<TableCell className={classes.root}>
																		<div style={{ display: 'flex' }}>
																			<span
																				style={{
																					fontSize: '12px',
																					textTransform: 'uppercase',
																				}}
																			>
																				{ProviderTransactionAdminPercentage?.admin_percentage}% payable
																			</span>
																			<span>
																				<KeyboardArrowDownIcon />
																			</span>{' '}
																		</div>{' '}
																	</TableCell>
																	<TableCell></TableCell>

																	<TableCell className={classes.root}>
																		<div style={{ display: 'flex' }}>
																			<span style={{ fontSize: '12px' }}>
																				STATUS
																			</span>
																			<span>
																				<KeyboardArrowDownIcon />
																			</span>{' '}
																		</div>{' '}
																	</TableCell>

																	{/* <TableCell className={classes.root}>
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: "-33px",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    ACTION
                                  </span>
                                  <span>
                                    <KeyboardArrowDownIcon />
                                  </span>{" "}
                                </div>{" "}
                              </TableCell> */}
																</TableRow>
															</TableHead>
															<TableBody style={{ background: 'white' }}>
																{providerTransaction?.length === 0 ? (
																	<TableRow>
																		<TableCell
																			colspan='8'
																			style={{
																				fontWeight: 'bold',
																				background: '#f8f9fe',
																			}}
																		>
																			<span
																				style={{
																					fontWeight: 'bold',
																					display: 'flex',
																					justifyContent: 'center',
																				}}
																			>
																				No Data Found
																			</span>
																		</TableCell>
																	</TableRow>
																) : (
																	providerTransaction?.map((row, index, id) => {
																		//   let status
																		//   if (row.status == "1") {
																		//     status = <span className={classes.centerValue}><img src={Click} alt="click" /><span sx={{ ml: 2, mb: 2 }} className={classes.verified}>  <span style={{ color: '#00C48C' }}>VERIFIED</span></span></span>
																		//   } else if (row.status == "0") {
																		//     status = <> <span className={classes.centerValue}><img src={verified} alt="click" /><span className={classes.verified} style={{ color: '#E8505B' }}>UNVERIFIED</span> </span>
																		//     </>

																		//   } else {
																		//     {/* status = <span className={classes.centerValue}> <img src={unverified} width="20px" alt="click" /><span sx={{ ml: 2, mb: 2 }} className={classes.verified}> <span style={{ color: 'rgb(110 124 230)' }}>PENDING</span> </span></span> */ }
																		//     status = <span className={classes.centerValue}><img src={verified} alt="click" /><span className={classes.verified} style={{ color: '#E8505B' }}>UNVERIFIED</span> </span>
																		//   }
																		//   let payment
																		//   if (row.payment_status == "1") {
																		//     payment = true
																		//   } else {
																		//     payment = false
																		//   }
																		return (
																			<>
																				<React.Fragment key={row.id}>
																					<TableRow
																						className={classes.tablerow}
																						sx={{
																							'&:last-child td, &:last-child th':
																							{
																								border: 0,
																							},
																						}}
																					>
																						{/* <TableCell>
                                          <Checkbox
                                            {...label1}
                                            //   key={row.id}
                                            type="checkbox"
                                            // name={name}
                                            id={row.id}
                                            //   handleClick={handleClick}
                                            //   isChecked={isCheck.includes(row.id)}
                                          />
                                        </TableCell> */}

																						<TableCell>
																							<div
																								style={{
																									display: 'flex',
																									gap: '30px',
																								}}
																							>
																								<div>
																									<img
																										src={
																											row?.patient
																												?.profile_image
																												? 'https://api.healthcoinxchangeportal.com/' +
																												row?.patient
																													?.profile_image
																												: avatar
																										}
																										alt='avatar'
																										className={
																											classes.imageCircle
																										}
																									/>
																								</div>

																								<div>
																									<span
																										style={{ fontSize: '12px' }}
																										className={classes.bold}
																									>
																										{row?.patient?.name}
																									</span>
																									<br />
																									<span
																										style={{ fontSize: '12px' }}
																										className='textcolorchange'
																									>
																										{row.patient.email}
																									</span>
																								</div>
																							</div>
																						</TableCell>
																						<TableCell
																							style={{ fontSize: '12px' }}
																							className='textcolorchange'
																						>
																							{row?.procedure_id}
																						</TableCell>
																						<TableCell
																							align='center'
																							style={{ fontSize: '12px' }}
																							className='textcolorchange'
																						>
																							{row?.patient_bill}
																						</TableCell>
																						<TableCell
																							align='center'
																							style={{ fontSize: '12px' }}
																							className='textcolorchange'
																						>
																							{row?.hc_bill}
																						</TableCell>
																						<TableCell
																							style={{ fontSize: '12px' }}
																							className='textcolorchange'
																						>
																							{row?.user_state?.name}
																						</TableCell>
																						<TableCell>
																							{' '}
																							{row?.payment_request_status ==
																								'4' ? (
																								<button
																									className={
																										classes.activebuttonStyle
																									}
																								>
																									<FaCheck /> &nbsp;{' '}
																									<span
																										style={{ fontSize: '12px' }}
																									>
																										COMPLETED
																									</span>
																								</button>
																							) : (
																								<button
																									className={
																										classes.awaitingbuttonStyle
																									}
																								>
																									<FaCheck /> &nbsp;{' '}
																									<span
																										style={{ fontSize: '12px' }}
																									>
																										AWAITING PAYMENT
																									</span>
																								</button>
																							)}
																						</TableCell>

																						{/* <TableCell>
                                          <img
                                            src={EyeView}
                                            alt="eyeView"
                                            onClick={() =>
                                              navigate(
                                                "/PaymentRequestDetail/:123"
                                              )
                                            }

                                            className={classes.eyes}
                                          />
                                          &nbsp;&nbsp;
                                        </TableCell> */}
																					</TableRow>
																				</React.Fragment>
																			</>
																		);
																	})
																)}
															</TableBody>
														</Table>
													</TableContainer>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						<br />
					</div>
					<Toaster />
				</div>
			)}
		</>
	);
};

export default InfoDetail;
