import React from "react";
import Checkbox from '@mui/material/Checkbox';

const Checkbox1 = ({ id, type, name, handleClick, isChecked}) => {
    return (
        <>
        <Checkbox
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
        />
       
        </>
    );
};

export default Checkbox1;
