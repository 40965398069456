import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    IconButton,
    Typography,
    Divider,
    Input,
    Box,
    TextareaAutosize,
  } from "@mui/material";
  import React, { useState } from 'react'
  import { makeStyles } from "@material-ui/styles";
  import CloseIcon from "@mui/icons-material/Close";
  import { server } from "../../redux/store";
  import axios from "axios";
  import { Formik, Form, Field, ErrorMessage } from 'formik';
  import { useNavigate } from "react-router-dom";
  import { useDispatch } from "react-redux";
 
  import { toast, Toaster } from "react-hot-toast";
  import * as Yup from 'yup';
  
  
  const useStyles = makeStyles({
  
    buttonAddnewHelp: {
      fontSize: "24px",
      fontWeight: "500",
  
    },
    sendCredButton: {
      height: "46px",
      width: "190px",
      borderRadius: "24px",
      background: '#4157FF',
    },
    headingSettingTest: {
      fontSize: "24px",
      fontWeight: "500",
  
    }
  
  });
  
  // const validationSchema = Yup.object({
  //   message: Yup.string()
  //     .required('message is required')
  
  // });
  const ContactHospital = ({ name, number }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [emailSupport, setEmailSupport] = useState('');
  
  
    const [open, setOpen] = useState(false)
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const classes = useStyles();
  
    // css
    const ContactHospital = {
      width: '155.1px',
      height: '45px',
      background: '#4CD964',
      borderRadius: ' 21px',
      color: 'white',
      fontSize: '14px',
      fontWeight: '700',
      cursor: 'pointer',
      fontFamily: 'DM Sans'
    }
  
    const submitContactButton = {
      color: "white ",
      background: " #4157FF ",
      borderRadius: "56px ",
      border: "none",
      width: "203px ",
      height: "50px",
      cursor: "pointer ",
      fontWeight: "600",
    }
  
    // const handleSubmit = async (event) => {
    //   event.preventDefault();
    //   try {
    //     const token = `Bearer ${localStorage.getItem("token")}`;
    //     let { data } = await axios.post(
    //       `${server}/mail_to_provider_by_admin`,
    //       {
    //         message: message,
    //         email: email,
    //       },
    //       {
    //         headers: { Authorization: token, "Content-Type": "application/json" },
    //       }
    //     );
    //     console.log(data, 'helloadwd')
    //     toast.success(data?.message);
    //     // navigate('/providers');
  
    //   } catch ({response}) {
    //     // console.log(err.data?.message);
    //     toast.error(response.data?.message);
    //   }
    // };
  
  
    return (
      <>
        <div>
          <button
  
            style={ContactHospital}
            onClick={handleOpen}>
            Contact Hospital
          </button>
          <Dialog open={open} onClose={handleClose}  >
            {" "}
            <DialogTitle className={classes.buttonAddnewHelp}>
              <span className={classes.headingSettingTest}>Contact Hospital</span>
              <IconButton
                onClick={handleClose}
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                <Box
                  style={{
                    background: "#4157FF",
                    width: "42px",
                    height: "42px",
                    fontSize: "11px  ",
                    boxShadow: "none ",
                    borderRadius: "24px",
                  }}
                >
                  <CloseIcon sx={{ margin: "8px 5px", color: "white" }} />
                </Box>
              </IconButton>
            </DialogTitle>
  
            <DialogContent >
              {" "}
              {/* sx={{ width: '500px' }} */}
  
              <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: "center" }}>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "14px ", fontWeight: "700", color: '#525F7F' }}
                    gutterBottom
                  >
                   Hospital Name
                  </Typography>
                  <TextField
                    disabled
                    margin="dense"
                    // placeholder="info.healthcoin@gethealthcoin.com"
                    value={name}
                    fullWidth
                    InputProps={{
                      style: { height: '31px' },
                      // disabled: true,
                    }}
                    sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", width: '489px' }}
                  />{" "}
                </Box>
  
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "14px ", fontWeight: "700", color: '#525F7F' }}
                    gutterBottom
                  >
                    Contact Number
                  </Typography>
                  <TextField
                    margin="dense"
                    disabled
                    value={number}
                    onChange={(e) => setEmailSupport(e.target.value)}
                    fullWidth
                    InputProps={{
                      style: {
                        height: '31px',
                        // disabled: true,
                      }
                    }}
                    sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", width: '489px' }}
                  />
                </Box>
              </Box>
  
              {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                <button type="submit" style={submitContactButton} onClick={handleSubmit}>Submit</button>
              </Box> */}
  
  
  
            </DialogContent>
  
          </Dialog>
  
          <Toaster />
        </div>
  
      </>
    );
  };
  
  export default ContactHospital;
  
  