import Logo from "../../asset/image/Logo.svg";
import healthcoinlogo from "../../asset/image/healthcoinlogo.svg";
import Email from "../../asset/image/email.svg";
import Password from "../../asset/image/password.svg";
import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import GrpHealthcoinLogo from "../../asset/image/GrpHealthcoinLogo.svg";
import { makeStyles } from "@mui/styles";
import "./ResetPassword.css";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { resetPassword } from "../../redux/action/user";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast, Toaster } from "react-hot-toast";
import { server } from "../../redux/store";
import 'react-toastify/dist/ReactToastify.css';
import { encryptData, decryptData } from '../../redux/incript&decript/cryptoUtils';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  FormControlLabel,
} from "@mui/material";
const useStyles = makeStyles({
  card: {
    width: "600px",
    height: "auto",
    borderRadius: "20px",
    overflow:'auto',
  },
  groupLogo: {
    width: "290px",
  },
  groupLogoBox: {
    display: "flex",
    flexDirection: "coloumn",
    justifyContent: "center",
    alignContent: "center",
  },
  tempPassHeading: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: "30px",
  },


});

const validationSchema = Yup.object({
  old_password: Yup.string()
    .required('*verification code is required')
    .min(4, '*verification code must be at least 4 characters'),
  password: Yup.string()
    .required('*new password is required')
    .min(6, '*new password must be at least 6 characters'),
  
  password: Yup.string()
    .required('*new password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      '*password must contain at least 1 uppercase letter,\n 1 lowercase letter, 1 number, and 1 symbol'
    )
    .min(6, "new password must be at least 6 characters"),
  confirm_password: Yup.string()
    .required('*confirm password is required')
    
    .oneOf([Yup.ref('password'), null], '*passwords must match'),
});
const TemPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleSubmit = async (values) => {
    try {
      const token = `Bearer ${localStorage.getItem("token")}`;
      const encrypted_data = encryptData({
        old_password: values.old_password,
        password: values.password,
        confirm_password: values.confirm_password, });
      let { data } = await axios.post(
        `${server}/password_change`,
        {
          encrypted_data: encrypted_data
        },
        {
          headers: { Authorization: token, "Content-Type": "application/json" },
        }
      );
    
      const decryptedData = decryptData(data.encrypted_data);
      toast.success(decryptedData.message)
   ;
      navigate('/');
    } catch (err) {
      const decryptedErrorMessage = decryptData(err?.response?.data?.encrypted_data);
      toast.error(decryptedErrorMessage.message);
    }
  };

  return (
    <>
      <Box
        sx={{
          height: "100vh",
        }}
        className="mainBox"
      >
        <Card className={classes.card}>
          <CardContent>
            {/* health coin logo */}

            <Box className={classes.groupLogoBox}>
              <img src={GrpHealthcoinLogo} className={classes.groupLogo} />
            </Box>

            <Box className={classes.tempPassHeading}>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Your Verification Code
              </Typography>
            </Box>
            <Formik
              initialValues={{
                old_password: '',
                password: '',
                confirm_password: '',
                rememberMe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <Box
                  style={{
                    display: 'grid',
                    flexDirection: 'column',
                    gap: '10px',
                    justifyContent: 'center',
                    alignContent: 'center',
                    margin: '32px',
                  }}
                >
                  <Field
                    type="password"
                    name="old_password"
                    placeholder=" Enter verification code (Check Email)"
                    fullWidth
                    inputProps={{ maxLength: 4 }}
                    sx={{ width: '351px' }}
                    size="sm"
                    as={TextField}
                  />
                  <ErrorMessage name="old_password">
                    {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                  </ErrorMessage>
                  <Box
                    sx={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                      Create New Password
                    </Typography>
                  </Box>

                  <Field
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                    fullWidth
                    sx={{ width: '351px' }}
                    size="sm"
                    as={TextField}
                  />
                  <ErrorMessage name="password">
                    {/* {(msg) => <div style={{ color: 'red' }}>{msg}</div>} */}
                    {(msg) => <div style={{ color: 'red' }}>{msg.split('\n').map((line, index) => <React.Fragment key={index}>{line}<br /></React.Fragment>)}</div>}
                  </ErrorMessage>


                  <Field
                    type="password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    fullWidth
                    sx={{ width: '351px' }}
                    size="sm"
                    as={TextField}
                  />
                  <ErrorMessage name="confirm_password">
                    {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                  </ErrorMessage>
                </Box>

                <Box sx={{ marginLeft: '112px', marginTop: '22px' }}>
                  <Field name="rememberMe">
                    {({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox {...field} color="primary" defaultChecked={false} />
                        }
                        label="Remember me"
                        style={{ color: '#8898AA', fontSize: '14px' }}
                      />
                    )}
                  </Field>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginTop: '46px',
                  }}
                >
                  <Button
                    type="submit"
                    style={{
                      color: 'white ',
                      background: ' #4157FF ',
                      borderRadius: '56px ',
                      border: 'none',
                      width: '273px',
                      height: '55px',
                      cursor: 'pointer',
                      fontWeight: '600',
                    }}
                  >
                    SUBMIT
                  </Button>
                </Box>
              </Form>
            </Formik>
          </CardContent>
        </Card>
      </Box>
      <Toaster />
    </>
  );
};

export default TemPassword;
