import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Divider,
	Box,
	MenuItem,
	Select,
	Typography,
	InputLabel,
	FormControl,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { server } from '../../redux/store';
import {
	encryptData,
	decryptData,
} from '../../redux/incript&decript/cryptoUtils';
const useStyles = makeStyles({
	buttonAddnew: {
		fontSize: '24px',
		fontWeight: '600 !important',
	},
});

const UsersPanelModal = ({ userFlag, setUserFlag, refreshAdminUsersList }) => {
	// select items
	const classes = useStyles();
	const { user } = useSelector(state => state.user);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const initialValues = {
		first_name: '',
		last_name: '',
		phone: '',
		email: '',
		user_role: '',
		// user_type: "enterprise"
	};
	const [showTypography, setShowTypography] = useState(true);
	const [selectedValue, setSelectedValue] = useState('');

	const handleChange = event => {
		setSelectedValue(event.target.value);
		setShowTypography(false);
	};

	// Modal Open and Closed
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleButtonClick = (setFieldValue, submitForm, errors) => {
		setFieldValue('published');
		submitForm();
		// console.log("published123", errors);
	};
	const validationSchema = Yup.object().shape({
		first_name: Yup.string()
			.required('*first name is required')
			.min(3, '*name must be at least 3 characters'),
		last_name: Yup.string().required('*last name is required'),
		// .min(3, "*company name must be at least 3 characters"),
		email: Yup.string()
			.required('*email is required')
			.matches(
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				'*Please enter a valid email address'
			),
		user_role: Yup.string().required('*user role is required'),
		phone: Yup.string()
			.required('*phone number is required')
			.matches(
				/^(\+?1[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
				'invalid phone number. please enter a valid USA phone number.'
			),
	});
	const formSubmit = async values => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			// dispatch({
			//   type: "addEmployerRequest",
			// });
			// console.log("valuesvalues", values);
			const { first_name, last_name, email, user_role, phone } = values; // Destructure values to access the required variables
			const encrypted_data = encryptData({
				first_name,
				last_name,
				email,
				user_type: user_role,
				phone,
			});
			let { data } = await axios.post(
				`${server}/admin_registration`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);
			handleClose();
			const decryptedData = decryptData(data.encrypted_data);
			toast.success(decryptedData.message);
			setUserFlag(!userFlag);
			refreshAdminUsersList();
			// navigate('/users')
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			toast.error(decryptedErrorMessage?.message);
		}
	};

	const submitHandler = e => {
		e.preventDefault();
	};
	return (
		<div>
			<Button
				onClick={handleOpen}
				size='large'
				style={{
					width: '120px',
					height: '46px',
					textTransform: 'none',
					backgroundColor: '#4157FF',
					float: 'right',
					fontWeight: 700,
					borderRadius: '24px',
					color: 'white',
				}}
			>
				+ Add New
			</Button>

			<Dialog
				open={open}
				onClose={() => {
					handleClose();
				}}
				maxWidth='md'
			>
				{' '}
				{/* maxWidth="sm" ,md,lg */}
				<DialogTitle className={classes.buttonAddnew}>
					<span style={{ fontSize: '24px', fontWeight: '500' }}>
						Add new user
					</span>
					<IconButton
						onClick={() => {
							handleClose();
						}}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px',
								boxShadow: 'none',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{ marginTop: '10px' }}
					style={{ minWidth: '550px', minHeight: '384px' }}
				>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting, resetForm }) => {
							// console.log("VAalues bout fee", values);
							formSubmit(values, setSubmitting, resetForm);
						}}
					>
						{({
							errors,
							touched,
							values,
							handleChange,
							setFieldValue,
							submitForm,
						}) => (
							<Form onSubmit={submitHandler}>
								<Box
									sx={{ display: 'flex', gap: '12px', flexDirection: 'column' }}
								>
									<Box>
										<Typography
											variant='h6'
											sx={{ fontSize: '14px ', fontWeight: '700' }}
											gutterBottom
										>
											First Name
										</Typography>
										<TextField
											margin='dense'
											placeholder='First name'
											name='first_name'
											onChange={handleChange}
											value={values.first_name}
											inputProps={{ maxLength: 40 }}
											fullWidth
											InputProps={{
												style: { height: '46px' },
											}}
											sx={{ width: '489px' }}
										/>{' '}
										{errors.first_name && touched.first_name ? (
											<div className={`red mb-1 fs15`}>{errors.first_name}</div>
										) : null}
									</Box>

									<Box>
										<Typography
											variant='h6'
											sx={{ fontSize: '14px ', fontWeight: '700' }}
											gutterBottom
										>
											Last Name
										</Typography>
										<TextField
											margin='dense'
											placeholder='Last name'
											inputProps={{ maxLength: 40 }}
											name='last_name'
											value={values.last_name}
											onChange={handleChange}
											fullWidth
											InputProps={{
												style: { height: '46px' },
											}}
											sx={{ width: '489px' }}
										/>
										{errors.last_name && touched.last_name ? (
											<div className={`red mb-1 fs15`}>{errors.last_name}</div>
										) : null}
									</Box>
									<Box>
										<Typography
											variant='h6'
											sx={{ fontSize: '14px ', fontWeight: '700' }}
											gutterBottom
										>
											Phone Number
										</Typography>
										<TextField
											margin='dense'
											placeholder='Phone number'
											inputProps={{ maxLength: 40 }}
											name='phone'
											value={values.phone}
											onChange={handleChange}
											fullWidth
											InputProps={{
												style: { height: '46px' },
											}}
											sx={{ width: '489px' }}
										/>
										{errors.phone && touched.phone ? (
											<div className={`red mb-1 fs15`}>{errors.phone}</div>
										) : null}
									</Box>

									<Box>
										<Typography
											variant='h6'
											sx={{ fontSize: '14px ', fontWeight: '700' }}
											gutterBottom
										>
											Email
										</Typography>
										<TextField
											margin='dense'
											placeholder='Email'
											name='email'
											onChange={handleChange}
											value={values.email}
											fullWidth
											InputProps={{
												style: { height: '46px' },
											}}
											sx={{ width: '489px' }}
										/>
										{errors.email && touched.email ? (
											<div className={`red mb-1 fs15`}>{errors.email}</div>
										) : null}
									</Box>

									<Box>
										<Typography
											variant='h6'
											sx={{ fontSize: '14px ', fontWeight: '700' }}
											gutterBottom
										>
											Role
										</Typography>
										<FormControl>
											{!values?.user_role && (
												<Typography
													variant='body1'
													component='label'
													style={{
														position: 'relative',
														opacity: '.4',
														marginTop: '17px',
														margin: '15px',
													}}
												>
													Select role
												</Typography>
											)}
											<Select
												margin='dense'
												fullWidth
												placeholder='Select Option'
												InputProps={{
													style: { height: '46px' },
												}}
												style={{ width: '489px', position: 'absolute' }}
												value={values.user_role}
												name='user_role'
												onChange={handleChange}
											>
												<MenuItem value='sub_admin'>Admin </MenuItem>
												{user?.user_type == 'super_admin' ? (
													<MenuItem value='super_admin'>Super Admin </MenuItem>
												) : (
													''
												)}
											</Select>
										</FormControl>
										{errors.user_role && touched.user_role ? (
											<div className={`red mb-1 fs15`}>{errors.user_role}</div>
										) : null}
									</Box>
									<br />
									<Box sx={{ margin: 'auto' }}>
										<Button
											style={{
												height: '48px ',
												width: '195px ',
												borderRadius: '24px ',
												background: '#4157FF ',
												fontSize: '15px  ',
												color: 'white  ',
												fontWeight: '700 ',
												margin: 'auto',
											}}
											variant='contained'
											color='primary'
											onClick={() =>
												handleButtonClick(setFieldValue, submitForm, errors)
											}
										>
											SUBMIT
										</Button>
									</Box>
								</Box>
							</Form>
						)}
					</Formik>
				</DialogContent>
				<DialogActions
					sx={{
						borderRadius: '0 0 20px 20px',
						// padding: "8px 24px",
						display: 'flex',
						justifyContent: 'center',
					}}
				></DialogActions>
			</Dialog>

			<Toaster />
		</div>
	);
};

export default UsersPanelModal;
