import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import Sidebar from "../Layout/Sidebar/Sidebar";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Header from "../Layout/Header/Header";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceById } from "../../redux/action/user";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { server } from "../../redux/store";
import Shape from "../../asset/image/Header/Shape.png";
import { encryptData, decryptData } from '../../redux/incript&decript/cryptoUtils';

const useStyles = makeStyles({
  novHeading: {
    fontSize: "30px",
    fontWeight: "600 ",
    fontFamily: "DM Sans ",
  },
  totalBalance: {
    fontSize: "10px  ",
    fontWeight: "600 ",
    marginTop: "4px ",
  },
  amountHeading: {
    fontSize: "16px ",
    fontWeight: "600 ",
  },

  headingCell: {
    border: "none ",
    fontSize: "12px ",
    fontWeight: "600 ",
    padding: "3px",
  },
  bodyCell: {
    border: "none",
    fontSize: "12px",
    fontWeight: "500",
    width: "150px",
  },
});
const InvoicesDetails = () => {
  const loadStyle = {
    color: "white",
    background: "#4157FF",
    borderRadius: "56px",
    border: "none",
    width: "203px",
    height: "50px",
    textTransform: 'capitalize',
    cursor: "pointer",
    fontWeight: 700,
    fontFamily: " DM Sans",
    cursor: "not-allowed",
  };
  let { id } = useParams();
  // console.log("idddddddddd", id);
  const [tableData, setTableData] = useState([]);
  const [totalDebit, setTotalDebit] = useState("");
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const { month, hid } = useParams();
  useEffect(() => {
    getInvoiceById(hid);
  }, []);

  const getInvoiceById = async (hid) => {
    try {
      const token = `Bearer ${localStorage.getItem("token")}`;

      let { data } = await axios.get(
        `${server}/get_invoice_by_id/?month=${id}&id=${hid}`,
        {
          headers: { Authorization: token, "Content-Type": "application/json" },
        }
      );

      const decryptedData = decryptData(data.encrypted_data);
      setTableData(decryptedData?.data?.invoices);
      setTotalDebit(decryptedData?.data?.totalDebt);
    } catch (err) {
      // console.log("err?.response?", err?.response?.data?.message);
    }
  };

  //  // download the Pdf
  const handleDownloadPDF = () => {
    const input = document.getElementById("pdf-content");
    setLoad(true);

    const options = {
      dpi: 300, // Adjust the DPI value (e.g., 150, 300, etc.)
      scale: 2, // Adjust the scale value to increase the resolution
      useCORS: true, // Enable cross-origin resource sharing if needed
      allowTaint: true, // Allow tainting of the canvas (may be required if your content contains images from other domains)
    };

    html2canvas(input, options).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4"); // Set the document size and orientation as per your requirements
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice_page.pdf");
      setLoad(false);
    });
  };

  const [path, setPath] = useState(
    <span
      style={{
        marginLeft: "10px",
        fontSize: "12px",
        fontWeight: "500",
        color: "#D0D1D2",
      }}
    >
      INVOICES{" "}
      <span style={{ fontSize: "15px", marginLeft: "10px" }}>
        {" "}
        <img src={Shape} alt="" />
      </span>
      <span
        style={{
          marginLeft: "10px",
          fontSize: "12px",
          fontWeight: "500",
          color: "#D0D1D2",
        }}
      >
        {id?.toUpperCase()}
      </span>
    </span>
  );

  const classes = useStyles();

  return (
    <div style={{ display: "flex", background: "#F8F9FE", height: "auto" }}>
      <Sidebar showSidebar={false} showHelpButton={false} />

      <div style={{ background: "#F8F9FE" }}>
        <Header showHeader={true} currentPath={path} />
        <Box
          style={{ position: "relative", top: "0px", marginTop: "92px" }}
          sx={{ background: "#f8f9fe" }}
        >
          {/* <Sidebar showSidebar={false} showHelpButton={false} /> */}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              {load ? (
                <LoadingButton
                  style={loadStyle}
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                  disabled
                >
                  Download As PDF
                </LoadingButton>
              ) : (
                <button
                  style={{
                    color: "white",
                    background: "#4157FF",
                    borderRadius: "56px",
                    border: "none",
                    width: "203px",
                    height: "50px",
                    cursor: "pointer",
                    fontWeight: 700,
                    fontFamily: " DM Sans",
                  }}
                  onClick={handleDownloadPDF}
                >
                  Download as PDF
                </button>
              )}
            </Box>

            <div id="pdf-content">
              <Box
                sx={{
                  display: "flex",
                  margin: "20px",
                  justifyContent: "space-between",
                  flexDirection: "coloumn",
                }}
              >
                <Box sx={{ display: "flex", ml: 8 }}>
                  <Typography className={classes.novHeading}>
                    {id?.toUpperCase()}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "12px",
                    flexWrap: "wrap",
                    marginRight: "-20px",
                  }}
                >
                  <Box sx={{ display: "grid", gap: "5px" }}>
                    <Box>
                      <Box>
                        <Typography className={classes.totalBalance}>
                          TOTAL BALANCE: &nbsp;{" "}
                          <span className={classes.amountHeading}>
                            {" "}
                            ${totalDebit}{" "}
                          </span>
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ marginTop: "-10px", marginLeft: "7px" }}>
                      {/* <Button
                style={{
                  width: "132px  ",
                  height: "46px  ",
                  background: " #4157FF ",
                  borderRadius: "24px  ",
                  color: "white  ",
                  textTransform: 'none  ',
                }}
               >Pay Invoice</Button> */}
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* table */}

              <Box sx={{ marginLeft: "80px" }}>
                <TableContainer>
                  <Table sx={{ width: "70vw" }}>
                    <TableHead>
                      <TableRow
                        sx={{ background: "none !important", border: "none" }}
                      >
                        <TableCell className={classes.headingCell}>
                          UNIT
                          <KeyboardArrowDownIcon sx={{ paddingTop: "10px" }} />
                        </TableCell>

                        {/* <Box sx={{display:'flex', justifyContent:'flex-end'}}> */}
                        {/* <TableCell className={classes.headingCell} >DEBIT<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} /> </TableCell>
                  <TableCell className={classes.headingCell}>CREDIT<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} /></TableCell> */}
                        <TableCell className={classes.headingCell}>
                          RUNNING BALANCE
                          <KeyboardArrowDownIcon sx={{ paddingTop: "8px" }} />
                        </TableCell>
                        {/* </Box> */}
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ background: "white" }}>
                      {tableData?.map((index) => (
                        <TableRow key={index.id}>
                          <TableCell
                            sx={{
                              border: "none",
                              fontSize: "14px",
                              fontWeight: "500",
                              fontFamily: "DM Sans",
                            }}
                          >
                            <span style={{ fontWeight:'bold',color: index?.payment_status === 'rejected' ? 'red' : index?.payment_status === 'pending' ? 'blue' : 'green' }}>
                              Payment Status: {index?.payment_status ? index.payment_status : 'approved'}
                            </span>
                            <br />
														<span style={{fontWeight:'bold',}}>Payment With:{' '}{index?.payment_with ? index.payment_with : 'card'}{' '}</span><br />
                            <span>EMPLOYEE ID</span>:{index?.patient_id} <br />
                            <span>
                              {index?.members[0]?.first_name}{" "}
                              {index?.members[0]?.last_name} (monthly interval)
                            </span>
                          </TableCell>

                          {/* <TableCell className={classes.bodyCell} >{index?.payer_debit}</TableCell>
                    <TableCell className={classes.bodyCell}>{index?.payer_credit}</TableCell> */}
                          <TableCell
                            align="center"
                            className={classes.bodyCell}
                          >
                            {/* {index?.payer_running_balance} */}
                            {index?.payer_debit}
                          </TableCell>
                          {/* </Box> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  marginLeft: "auto",
                  marginTop: "15px",
                  marginBottom: "auto",
                  justifyContent: "end",
                }}
              >
                <span
                  style={{ fontSize: "10px", fontWeight: "600", margin: "5px" }}
                >
                  TOTAL BALANCE:
                </span>
                <span style={{ fontSize: "16px", fontWeight: "600" }}>
                  ${totalDebit}
                </span>
              </Box>
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default InvoicesDetails;
