import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
} from '@mui/material';
import React, { useState } from 'react';
import LogoIcon from '../asset/image/LogoIcon.png';
import { logoutUser } from '../redux/action/user';
import { useDispatch } from 'react-redux';

export default function DeactiveModal({ open = false, setOpen }) {
	const dispatch = useDispatch();
	const logoutModal = () => {
		dispatch(logoutUser());
	};
	return (
		<Dialog open={open} maxWidth='sm' fullWidth={true}>
			<DialogTitle style={{ margin: 'auto' }}>
				<img src={LogoIcon} alt='logo' width={'192px'} />{' '}
			</DialogTitle>
			<Divider />
			<DialogContent>
				<p
					style={{
						fontWeight: 600,
						fontSize: '18px',
						textAlign: 'center',
					}}
				>
					Your Account is <span style={{ color: 'red' }}> Deactivated</span>.
					Activate your account by contacting HC Team.
				</p>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center' }}>
				<Button
					onClick={() => {
						setOpen(false);
						localStorage.clear();
					}}
					variant='contained'
					color='error'
				>
					ok
				</Button>
			</DialogActions>
		</Dialog>
	);
}
