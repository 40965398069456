import React from 'react';
import LogoIcon from '../../asset/image/LogoIcon.png';
import MemberIcon from '../../asset/image/memberIcon.png';
import Employer from '../../asset/image/employer.png';
import Provider from '../../asset/image/provider.png';
import { useNavigate } from 'react-router-dom';
import ProviderPopModal from './ProviderPopModal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
	Button,
} from '@mui/material';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
	SignUpResponsiveness: {
		[theme.breakpoints.down('sm')]: {
			width: '400px',
			marginTop: '50px',
			display: 'grid',
		},
	},
	imgResponsive: {
		display: 'flex',
		marginTop: '30px',
		justifyContent: 'center',
		height: '270px',
		[theme.breakpoints.down('sm')]: {
			display: 'flow-root',
		},
	},
	singupImagesResponsive: {
		display: 'flex',
		justifyContent: 'center',
		gap: '10px',
		marginTop: '60px',

		[theme.breakpoints.between(0, 850)]: {
			display: 'grid',
		},
	},
	selectOptionHeading: {
		marginTop: '30px',
		fontWeight: 700,
		fontFamily: 'DM Sans',
		fontSize: '1.5rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px',
		},
	},
	youlookingFor: {
		fontSize: '1.5rem',
		fontWeight: 700,
		fontFamily: 'DM Sans',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px',
		},
	},
}));
const SignupSelection = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const handleValueLogin = () => {
		navigate('/?formAlreadyRegEmail=true');
	};

	return (
		<>
			<Box className={classes.SignUpResponsiveness}>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '50px',
					}}
				>
					<img
						src={LogoIcon}
						alt='logo'
						style={{ width: '173px', height: '28px' }}
					/>
				</Box>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '5px',
					}}
				>
					<Box style={{ display: 'grid' }}>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<span className={classes.selectOptionHeading}>
								Select which option best describes
							</span>
						</Box>

						<Box style={{ display: 'flex', justifyContent: 'center' }}>
							<span className={classes.youlookingFor}>
								what you are looking for?
							</span>
						</Box>
					</Box>
				</Box>

				<Box className={classes.singupImagesResponsive}>
					<img
						src={MemberIcon}
						alt='membericon'
						style={{ cursor: 'pointer', height: '270px', width: '240px' }}
						onClick={() => navigate('/patient_registration')}
					/>

					<ProviderPopModal />

					<img
						src={Employer}
						alt='employer'
						style={{ cursor: 'pointer', height: '270px', width: '240px' }}
						onClick={() => navigate('/enterprise_signup')}
					/>
				</Box>

				<Box
					style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}
				>
					<Box>
						<span style={{ color: '#66737F', fontSize: '14px' }}>
							If your membership is paid through your employer, please check
							your work email for login info, then download the mobile app to
							enjoy your benefits.
						</span>
						<br />

						<Box style={{ display: 'flex', gap: '6px', marginTop: '10px' }}>
							<Box>
								<span
									style={{
										color: '#66737F',
										fontSize: '20px',
										display: 'flex',
										justifyContent: 'center',
										fontWeight: 'bold',
									}}
								>
									If you've already registered for a patient account, please log
									in to complete the patient process.
								</span>
							</Box>

							<Box
								style={{
								}}
							>
								<Button
									onClick={handleValueLogin}
									style={{
										background: '#4157FF',
										width: '82px',
										height: '32px',
										borderRadius: '24px',
										color: 'white',
										fontSize: '15px',
										fontWeight: '700',
										cursor: 'pointer',
									}}
								>
									Login
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default SignupSelection;
