import React from 'react';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './checkoutform';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
	// 'pk_test_51Ml2S0Bm51xoPSLFuDAbolImh6y7xItiIvfm0PoTHfdvKi1SbQr3XFi5n5OguwKdm09P4EBB8wmXPd5sRHcfZ0EO005Z3w5qxR'
	'pk_live_51MqyQ0AVuTTGHQvbX2kAWCMYBn9lOoqYryth0XjRqkp0ncV3vJUiYxRfI86hdI8NEvsDZmsZue2xiSlhXnhpswhU007N8ff5PG'
);

const options = {
	mode: 'payment',
	currency: 'usd',
	amount: 1099,
};

function Stripe({handleSubmit}) {
	return (
		<Elements stripe={stripePromise} options={options}>
			<CheckoutForm handleSubmit1={handleSubmit} />
		</Elements>
	);
}

export default Stripe;
