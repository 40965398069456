import React, { useState } from 'react';
import search from '../../../../asset/image/search.svg';
import {
	TextField,
	Box,
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableHead,
	Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import eyeView from '../../../../asset/image/eyeView.svg';
import download from '../../../../asset/image/download.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Sidebar from '../../../Layout/Sidebar/Sidebar';
import Header from '../../../Layout/Header/Header';
import { server } from '../../../../redux/store';
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { invoicesOfEnterprise } from '../../../../redux/action/user';
import { decryptData } from '../../../../redux/incript&decript/cryptoUtils';
import DeactiveModal from '../../../DeactiveModal';
const useStyles = makeStyles({
	headingCellInvoice: {
		fontSize: '10px !important',
		fontWeight: '600 !important',
		fontFamily: 'DM Sans',
	},
	bodyCell: {
		fontSize: '12px',
		fontWeight: '500',
	},
});

const InvoicesAllMonthTable = () => {
	const classes = useStyles();

	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const { enterpriseInvoicesRecord } = useSelector(state => state.user);
	const [query, setQuery] = useState('');
	const [tableData, setTableData] = useState(enterpriseInvoicesRecord);
	const [totalDebit, setTotalDebit] = useState('');
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const handleClickExpensiveDetails = month => {
		navigate(`/InvoiceMonthTable/${month}`);
	};
	const getUserData = async () => {
		if (localStorage.getItem('employerSignup')) return;
		const token = `Bearer ${localStorage.getItem('token')}`;

		const res = await axios.get(`${server}/profile`, {
			headers: { Authorization: token, 'Content-Type': 'application/json' },
		});
		// console.log(res);

		const dec = decryptData(res.data.encrypted_data);
		// console.log(dec);
		if (
			dec?.data.user.user_type == 'enterprise' &&
			dec?.data.user.status == 0
		) {
			setOpen(true);
			localStorage.clear();
			navigate('/');
			localStorage.setItem('deactivate', true);
			return;
		}
	};
	useEffect(() => {
		getUserData();
		dispatch(invoicesOfEnterprise());
	}, []);
	useEffect(() => {
		setTableData(enterpriseInvoicesRecord);
	}, [JSON.stringify(enterpriseInvoicesRecord)]);
	// checkBox
	const Checkbox1 = ({ id, type, name, handleClick, isChecked }) => {
		return (
			<>
				<Checkbox
					id={id}
					name={name}
					type={type}
					onChange={handleClick}
					checked={isChecked}
				/>
			</>
		);
	};

	const handleSearch = event => {
		const query = event.target.value;
		// console.log(query, "pakistani123");
		setQuery(query);
	};

	const handleSelectAll = e => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(tableData?.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};

	const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
	return (
		<div style={{ display: 'flex', background: '#F8F9FE', height: '100vh' }}>
			<Sidebar showSidebar={true} showHelpButton={true} />

			<div style={{ flex: 5, background: '#F8F9FE' }}>
				<Header showHeader={true} />
				<Box
					style={{ position: 'relative', top: '12%' }}
					sx={{ background: '#f8f9fe' }}
				>
					<Box sx={{ margin: '22px' }}>
						<TextField
							id='outlined-start-adornment'
							placeholder='Search'
							value={query}
							onChange={handleSearch}
							sx={{ marginLeft: '3px', width: '30ch' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<img src={search} alt='search' />
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box sx={{ width: '80vw', margin: '20px 24px' }}>
						<Table>
							<TableHead>
								<TableRow>
									{/* <TableCell>
                    <Checkbox1
                      {...label1}
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}


                    />
                  </TableCell> */}
									<TableCell
										align='center'
										className={classes.headingCellInvoice}
									>
										MONTH <KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
									</TableCell>
									<TableCell
										align='center'
										className={classes.headingCellInvoice}
									>
										EXPENSES
										<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
									</TableCell>
									<TableCell
										align='right'
										className={classes.headingCellInvoice}
									>
										ACTION
										<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody sx={{ background: 'white' }}>
								{tableData?.length === 0 ? (
									<TableRow>
										<TableCell
											colSpan={9}
											align='center'
											style={{ fontWeight: 'bold', background: '#f8f9fe' }}
										>
											No Data Found
										</TableCell>
									</TableRow>
								) : (
									tableData?.map((data, index) => (
										<TableRow key={index}>
											{/* <TableCell>
											<Checkbox1
												{...label1}
												type="checkbox"
												{...label1}
												key={row.id}
												// name={name}
												id={row.id}
												handleClick={handleClick}
												isChecked={isCheck.includes(row.id)}
											/>
											</TableCell> */}
											<TableCell align='center' className={classes.bodyCell}>
												Invoices of {data.month}
											</TableCell>
											<TableCell align='center' className={classes.bodyCell}>
												Total Amount: ${data.total_amount}
											</TableCell>
											<TableCell className={classes.bodyCell}>
												<Box
													sx={{
														display: 'flex',
														gap: '14px',
														justifyContent: 'flex-end',
														marginRight: '6%',
													}}
												>
													<img
														style={{ cursor: 'pointer' }}
														onClick={() =>
															handleClickExpensiveDetails(data?.month)
														}
														src={eyeView}
														alt=''
													/>
												</Box>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</Box>
				</Box>
			</div>
			<DeactiveModal open={open} setOpen={setOpen} />
		</div>
	);
};

export default InvoicesAllMonthTable;
