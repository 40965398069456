// import React from 'react'

// const HospitalDisputes = () => {
//   return (
//     <div>HospitalDisputes</div>
//   )
// }

// export default HospitalDisputes

import React, { useEffect, useState } from 'react';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { convertToLocalTime } from '../../utils/date';
import {
	Container,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
	Paper,
	InputAdornment,
	TextField,
	Switch,
} from '@mui/material';
import { FaCheck } from 'react-icons/fa';
import { RxCross1 } from 'react-icons/rx';
import { CiClock2 } from 'react-icons/ci';
import search from '../../asset/image/search.svg';
import Checkbox from '../Table/Checkbox';

import EyeView from '../../asset/image/eyeView.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { server } from '../../redux/store';
import axios from 'axios';
import avatar from '../../asset/image/avatar.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllHospitalDisputes } from '../../redux/action/user';

// Css Style
const useStyles = makeStyles({
	root: {
		fontSize: '10px !important',
		fontWeight: 'bold !important',
		textAlign: 'center  !important',

		color: '#23262F !important',
	},
	bold: {
		fontWeight: 'bold !important',
		color: '#3772FF !important',
	},
	headingIcon: {
		display: 'flex',
	},

	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	rounded: {
		borderRadius: '22px !important',
	},
	eyes: {
		cursor: 'pointer',
	},
	verified: {
		color: '#00C48C',
		marginLeft: '6%',
		fontWeight: 'bold',
	},

	centerValue: {
		display: 'flex',
		justifyContent: 'center',
	},
	successbuttonColor: {
		backgroundColor: '#4CD964',
	},
	pendingbuttonColor: {
		backgroundColor: '#FFA23A',
	},
	fraudbuttonColor: {
		backgroundColor: '#E8505B',
	},

	activebuttonStyle: {
		// backgroundColor: "#4CD964",
		color: 'white',
		minWidth: '91px',
		minHeight: '23px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '8rem',
	},
});
const label2 = { inputProps: { 'aria-label': 'Switch demo' } };
const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
const HospitalDisputes = () => {
	const classes = useStyles();

	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const navigate = useNavigate();
	const [arrayState, setArrayState] = useState([]);
	const { allDisputes } = useSelector(state => state.user);
	const [query, setQuery] = useState('');
	const dispatch = useDispatch();

	// checkbox
	const handleSelectAll = e => {
		// console.log(isCheck, 'CheckAll')
		setIsCheckAll(!isCheckAll);
		setIsCheck(arrayState.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, "singlecheck")
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};
	const handleChange = (e, id) => {
		return;
	};
	const [path, setPath] = useState(
		<span
			style={{
				marginLeft: '10px',
				textTransform: 'uppercase',
				fontSize: '12px',
				fontWeight: '500',
				color: '#D0D1D2',
			}}
		>
			hospital disputes
		</span>
	);

	useEffect(() => {
		dispatch(getAllHospitalDisputes(query));
		setArrayState(allDisputes);
	}, [query]);

	const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };

	return (
		<div className='home'>
			<div>
				<Sidebar showSidebar={false} showHelpButton={false} />
			</div>
			<div className='homeContainer'>
				<Header showHeader={true} currentPath={path} />
				<Container
					maxWidth='xl'
					style={{
						position: 'relative',
						top: '110px',
						height: 'auto',
						background: '#f8f9fe',
					}}
				>
					<Box sx={{ marginBottom: '30px' }}>
						<TextField
							id='outlined-start-adornment'
							placeholder='Search'
							sx={{ marginLeft: '3px', width: '30ch' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<img src={search} alt='search' />
									</InputAdornment>
								),
							}}
							defaultValue={query}
							onChange={e => setQuery(e.target.value)}
						/>
					</Box>

					<TableContainer component={Paper}>
						<Table aria-label='simple table'>
							<TableHead>
								<TableRow sx={{ backgroundColor: '#F8F9FE' }}>
									{/* <TableCell>
                    <div>
                      <Checkbox
                        {...label1}
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                          handleClick={handleSelectAll}
                          isChecked={isCheckAll}
                      />
                    </div>
                  </TableCell> */}

									<TableCell className={classes.root}>
										<div style={{ display: 'flex', paddingLeft: '25px' }}>
											<span style={{ fontSize: '12px' }}>PATIENT’S</span>{' '}
											&nbsp;&nbsp;
											<span style={{ fontSize: '12px' }}> NAME</span>
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
									<TableCell className={classes.root}>
										<div style={{ display: 'flex', paddingLeft: '20px' }}>
											<span
												style={{ fontSize: '12px', textTransform: 'uppercase' }}
											>
												Hospital
											</span>
											&nbsp;&nbsp;
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
									<TableCell className={classes.root}>
										<div style={{ display: 'flex' }}>
											<span style={{ fontSize: '12px' }}>HEALTHCOIN</span>
											&nbsp;&nbsp;
											<span style={{ fontSize: '12px' }}>ID</span>
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
									<TableCell className={classes.root}>
										<div style={{ display: 'flex' }}>
											<span style={{ fontSize: '12px' }}>STATUS</span>
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
									<TableCell className={classes.root}>
										<div style={{ display: 'flex' }}>
											<span
												style={{ fontSize: '12px', textTransform: 'uppercase' }}
											>
												date{' '}
											</span>{' '}
											&nbsp;&nbsp;
											<span
												style={{ fontSize: '12px', textTransform: 'uppercase' }}
											>
												submitted
											</span>
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody style={{ background: 'white' }}>
								<React.Fragment>
									{allDisputes?.length === 0 ? (
										<TableRow>
											<TableCell
												colSpan={9}
												align='center'
												style={{ fontWeight: 'bold', background: '#f8f9fe' }}
											>
												No Data Found
											</TableCell>
										</TableRow>
									) : (
										allDisputes?.map((row, index, id) => {
											const checkStatus =
												row?.dispute == 'approve' ||
													row?.dispute == 'deny' ||
													row?.dispute == 'forgive'
													? true
													: false;
											return (
												<TableRow
													key={row.id}
													className={classes.tablerow}
													sx={{
														'&:last-child td, &:last-child th': { border: 0 },
													}}
													style={{ cursor: 'pointer' }}
												>
													{/* <TableCell>
                          <Checkbox
                            {...label1}
                            type="checkbox"
                           
                            id={row.id}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(row.id)}
                          />
                        </TableCell> */}

													<TableCell
														onClick={() =>
															navigate(`/hospital_disputes_details/${row?.id}`)
														}
													>
														<div style={{ display: 'flex', gap: '30px' }}>
															<div>
																<img
																	// src={avatar}
																	src={
																		row?.patient?.profile_image
																			? 'https://api.healthcoinxchangeportal.com/' +
																			row?.patient?.profile_image
																			: avatar
																	}
																	alt='avatar'
																	className={classes.imageCircle}
																/>
															</div>

															<div>
																<span
																	style={{ fontSize: '12px' }}
																	className={classes.bold}
																>
																	{row?.patient?.name}
																</span>
																<br />
																<span
																	style={{ fontSize: '12px' }}
																	className='textcolorchange'
																>
																	{row?.patient?.email}
																</span>
															</div>
														</div>
													</TableCell>
													<TableCell
														onClick={() =>
															navigate(`/hospital_disputes_details/${row?.id}`)
														}
														style={{ fontSize: '12px' }}
														className='textcolorchange'
													>
														{row?.provider?.location}
													</TableCell>
													<TableCell
														onClick={() =>
															navigate(`/hospital_disputes_details/${row?.id}`)
														}
														style={{ fontSize: '12px' }}
														className='textcolorchange'
													>
														{row?.patient?.healthcoin_id}
													</TableCell>
													<TableCell
														onClick={() =>
															navigate(`/hospital_disputes_details/${row?.id}`)
														}
														style={{ fontSize: '12px' }}
														className='textcolorchange'
													>
														<Switch
															{...label2}
															onChange={e => handleChange(e, row.id)}
															defaultChecked={checkStatus}
															style={{ pointerEvents: 'none' }}
														/>{' '}
													</TableCell>
													<TableCell
														onClick={() =>
															navigate(`/hospital_disputes_details/${row?.id}`)
														}
														style={{ fontSize: '12px' }}
														className='textcolorchange'
													>
														{convertToLocalTime(row?.dispute_create_date)}
													</TableCell>
												</TableRow>
											);
										})
									)}
								</React.Fragment>
							</TableBody>
						</Table>
					</TableContainer>
				</Container>
			</div>
		</div>
	);
};

export default HospitalDisputes;
