import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Stack, TextField, Box } from '@mui/material';

export default function Index({ onFormSubmit, mode = 0, data = null }) {
	const { uid } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// console.log({ data });
	const { control, handleSubmit, reset, setValue, getValues } = useForm({
		defaultValues: {
			consultation_name: data?.consultation_name || '',
			consultation_price: data?.consultation_price || '',
			description: data?.description || '',
		},
	});
	const onSubmit = formData => {
		// console.log(formData);
		const dataClone = { ...formData };
		dataClone.id = data.id;
		dataClone.user_id = data.user_id;
		onFormSubmit(dataClone);
		reset();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<>
				<Stack spacing={1}>
					<label>Name</label>
					<Controller
						name='consultation_name'
						control={control}
						render={({ field }) => (
							<>
								<TextField {...field} />
							</>
						)}
					/>

					<label>Price</label>
					<Controller
						name='consultation_price'
						control={control}
						render={({ field }) => (
							<>
								<TextField {...field} />
							</>
						)}
					/>
					<label>Description</label>
					<Controller
						name='description'
						control={control}
						render={({ field }) => (
							<>
								<TextField {...field} />
							</>
						)}
					/>
				</Stack>

				<Box sx={{ mt: 2 }}>
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '25px',
						}}
					>
						<button
							style={{
								width: '325px',
								height: '50px',
								background: 'white',
								color: '#4157FF',
								border: '1px solid #4157FF',
								fontSize: '16px',
								fontWeight: '700',
								borderRadius: '5px',
								cursor: 'pointer',
							}}
							type='submit'
							//   onClick={() => addService()}
						>
							{` ${mode === 1 ? 'Update' : '+Add'} 	 Consultaion`}
						</button>
					</Box>
				</Box>
			</>
		</form>
	);
}
