
import './employer.css';

import healthcoinlogo from '../../../asset/image/employerlogo.png';
import bg from '../../../asset/image/bg.svg';

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../../redux/action/user';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SignupEmployer } from '../../../redux/action/user';
import { InputAdornment, IconButton, Box } from '@mui/material';
// import { Visibility, VisibilityOff } from ' @mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { server } from '../../../redux/store';
import { toast, Toaster } from 'react-hot-toast';
import GooglePlacesAutocomplete, {
	geocodeByPlaceId,
	getLatLng,
} from 'react-google-places-autocomplete';
import { useSelector } from 'react-redux';
import {
	encryptData,
	decryptData,
} from '../../../redux/incript&decript/cryptoUtils';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme => ({
	containerOfAddEmployer:{
		backgroundImage: `url(${bg})`,
		backgroundSize: 'cover', 
		backgroundPosition: 'center', 
		backgroundRepeat: 'no-repeat', 
		width: '100%', 
		height: 'auto',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: -1, 

	[theme.breakpoints.between(1090,1890)]:{
			height: '1100px', 
		}
	},

	emuCard:{
		width: '520px',
		minHeight: '753px',
		margin: 'auto auto',
		borderRadius: '20px',
		backgroundColor: 'white',
		display:' flex',
		flexDirection: 'column',
		alignItems:' center',

		[theme.breakpoints.down('sm')]:{
			width:'351px',
		}
	},

	healthCoinLogoResponsive:{
		width:'300px',
		[theme.breakpoints.down('sm')]:{
			width:'200px',
		}
	},

	footerSignup:{
		display: 'flex',
		justifyContent: 'space-between',
		width: '545px',
		margin: 'auto',
		color: 'white',
		marginTop: '12px',
		
		[theme.breakpoints.down('sm')]:{
			width: '345px',
		}
	}
}));
const AddEmployer = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [longitude, setLongitude] = useState('');
	const [latitude, setLatitude] = useState('');
	const [address, setAddress] = useState('');
	const { recordUser } = useSelector(state => state.user);
	const [value, setValue] = useState({
		label: '',
		value: {},
	});
	const initialValues = {
		first_name: '',
		company: '',
		address: '',
		email: '',
		phone_number: '',
		password: '',
		confirm_password: '',
		user_type: 'enterprise',
	};

	const handleButtonClick = (setFieldValue, submitForm, errors) => {
		setFieldValue('published');
		submitForm();
		console.log('published123', errors);
	};
	const validationSchema = Yup.object().shape({
		first_name: Yup.string()
			.required('*name is required')
			.min(3, '*name must be at least 3 characters'),
		company: Yup.string()
			.required('*company name is required')
			.min(3, '*company name must be at least 3 characters'),
		// address: Yup.string().required("*company address is required"),
		email: Yup.string()
			.required('*email is required')
			.matches(
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				'*Please enter a valid email address'
			),
		phone_number: Yup.string()

			.required('*phone is required')
			.min(7, '*phone number at least 7 characters')
			.matches(
				/^[\d()+\- ]+$/,
				'invalid phone number. please enter only numbers, spaces, and special characters: +, -, (, )'
			),
		password: Yup.string()
			.required('*password is required')
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
				'*password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol'
			)
			.min(6, 'password must be at least 6 characters'),
		confirm_password: Yup.string()
			.required('*confirm password is required')
			.oneOf([Yup.ref('password')], 'passwords do not match'),
	});
	const SignupEnterprise = async formdata => {
		const recordUser = { ...value, address: value.label, longitude, latitude };
		await handlePlaceSelect(value);
		try {
			const encrypted_data = encryptData({
				first_name: formdata?.first_name,
				address: recordUser?.address,
				latitude: latitude,
				longitude: longitude,
				// address: address,
				phone_number: formdata?.phone_number,
				password: formdata.password,
				confirm_password: formdata?.confirm_password,
				email: formdata.email,
				company: formdata.company,
				user_type: 'enterprise',
			});
			let { data } = await axios.post(
				`${server}/enterprise_registration`,
				{
					encrypted_data: encrypted_data,
				},
				{
					headers: { 'Content-Type': 'application/json' },
				}
			);

			const decryptedData = decryptData(data.encrypted_data);
			const success = JSON.stringify(decryptedData?.user);
			if (success) {
				toast.success(decryptedData?.message);

				localStorage.setItem('token', decryptedData?.data?.access_token);
				localStorage.setItem('user', success);
				localStorage.setItem('employerSignup', true);
				setTimeout(() => {
					navigate(`/members`);
				}, 1000);
			}
		} catch (err) {
			const decryptedErrorMessage = decryptData(
				err?.response?.data?.encrypted_data
			);
			// console.log("errrrrr",err?.response?.data?.message)
			toast.error(decryptedErrorMessage?.message);
		}
	};
	const formSubmit = values => {
		SignupEnterprise(values);
	};
	const submitHandler = e => {
		e.preventDefault();
	};
	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const handleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};
	const handlePasswordChange = event => {
		setPassword(event.target.value);
	};
	const handlePlaceSelect = async value => {
		try {
			setValue(value);
			const results = await geocodeByPlaceId(value?.value?.place_id);
			if (results && results?.length > 0) {
				const { lat, lng } = await getLatLng(results[0]);
				setLatitude(lat);
				setLongitude(lng);
			}
		} catch (error) {
			console.error('Error fetching geolocation:', error);
		}
	};

	return (
		<div className={classes.containerOfAddEmployer} >
			<div style={{position:'relative'}}>
			<br />
			<div className={classes.emuCard} style={{ marginTop: '1%' }}>
				<div>
					<br />
					{/* <img src={Logo} alt="logo" /> */}
				</div>
				<div>
					<img src={healthcoinlogo} alt='healthcoinlogo' className={classes.healthCoinLogoResponsive} />
				</div>
				<br />
				<div className='emp_form_signup'>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting, resetForm }) => {
							console.log('Values About fee', values);
							formSubmit(values, setSubmitting, resetForm);
						}}
					>
						{({
							errors,
							touched,
							values,
							handleChange,
							setFieldValue,
							submitForm,
						}) => (
							<Form onSubmit={submitHandler}>
								<div className='child'>
									<div style={{display:'grid',justifyContent:'center'}}>
									<span style={{ marginLeft: '8px', fontWeight: '500' }}>
										First & Last Name
									</span>
									<TextField
										// label="First & Last Name"
										className='mailField'
										onChange={handleChange}
										value={values.first_name}
										// inputProps={{ maxLength: 17 }}
										name='first_name'
										placeholder='First & Last Name (Person Managing Acct)* '
										id='outlined-start-adornment'
										sx={{ m: 1, width: '25ch' }}
										style={{ width: '300px' }}
									/>
									</div>
									{errors.first_name && touched.first_name ? (
										<div className={`red mb-1 fs15`}>{errors.first_name}</div>
									) : null}
									<div style={{display:'grid',justifyContent:'center'}}>
									<span
										style={{
											marginLeft: '8px',
											fontWeight: '500',
											marginTop: '6px',
											marginBottom: '6px',
										}}
									>
										Company Name
									</span>
									<TextField
										// label="Company Name"
										className='mailField'
										placeholder='Company Name*'
										// inputProps={{ maxLength: 17 }}
										id='outlined-start-adornment'
										sx={{ m: 1, width: '25ch' }}
										style={{ width: '300px' }}
										name='company'
										onChange={handleChange}
										value={values.company}
									/>
									</div>
									{errors.company && touched.company ? (
										<div className={`red mb-1 fs15`}>{errors.company}</div>
									) : null}
									<div style={{display:'grid',justifyContent:'center'}}>
									<span
										style={{
											marginLeft: '8px',
											fontWeight: '500',
											marginTop: '6px',
											marginBottom: '6px',
										}}
									>
										Email Address
									</span>
									<TextField
										// label="Email Address"
										className='mailField'
										placeholder='Email Address*'
										id='outlined-start-adornment'
										sx={{ m: 1, width: '25ch' }}
										style={{ width: '300px' }}
										name='email'
										onChange={handleChange}
										value={values.email}
									/>
									</div>
									
									{errors.email && touched.email ? (
										<div className={`red mb-1 fs15`}>{errors.email}</div>
									) : null}
									<div style={{display:'grid',justifyContent:'center'}}>
									<span
										style={{
											marginLeft: '8px',
											fontWeight: '500',
											marginTop: '6px',
											marginBottom: '6px',
										}}
									>
										Phone Number
									</span>
									<TextField
										// label="Phone Number"
										className='mailField'
										placeholder='Phone Number*'
										inputProps={{ maxLength: 16 }}
										id='outlined-start-adornment'
										sx={{ m: 1, width: '25ch' }}
										style={{ width: '300px' }}
										name='phone_number'
										onChange={handleChange}
										value={values.phone_number}
									/>
									</div>
									{errors.phone_number && touched.phone_number ? (
										<div className={`red mb-1 fs15`}>{errors.phone_number}</div>
									) : null}
									<Box
										style={{
											marginTop: '6px',
											marginBottom: '6px',
											marginLeft: '17px',
										}}
									>
										Address
										<div style={{ width: '300px', }}>
										<GooglePlacesAutocomplete
											selectProps={{
												value,
												onChange: value => {
													handlePlaceSelect(value);
												},
												
											}}
											apiKey={process.env.REACT_APP_API_KEY}
											placeholder='Enter your placeholder address'
										/>
										</div>
									</Box>
									{errors.address && touched.address ? (
										<div className={`red mb-1 fs15`}>{errors.address}</div>
									) : null}
									<div style={{display:'grid',justifyContent:'center'}}>
									<span
										style={{
											marginLeft: '8px',
											fontWeight: '500',
											marginTop: '6px',
											marginBottom: '6px',
										}}
									>
										Password
									</span>
									<TextField
										// label="Password"
										type={showPassword ? 'text' : 'password'}
										className='mailField'
										placeholder='Password*'
										inputProps={{ maxLength: 40 }}
										id='outlined-start-adornment'
										sx={{ m: 1, width: '25ch' }}
										style={{ width: '300px' }}
										name='password'
										onChange={handleChange}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton onClick={handleTogglePasswordVisibility}>
														{showPassword ? (
															<VisibilityIcon sx={{ color: '#3772FF' }} />
														) : (
															<VisibilityOffIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
										value={values.password}
									/>
									</div>

									{errors.password && touched.password ? (
										<div className={`red mb-1 fs15`}>{errors.password}</div>
									) : null}
									<div style={{display:'grid',justifyContent:'center'}}>
									<span
										style={{
											marginLeft: '8px',
											fontWeight: '500',
											marginTop: '6px',
											marginBottom: '6px',
										}}
									>
										Confirm Password
									</span>
									<TextField
										// label="With normal TextField"
										type={showConfirmPassword ? 'text' : 'password'}
										className='mailField'
										// label="Confirm Password"
										placeholder='Confirm Password*'
										inputProps={{ maxLength: 40 }}
										id='outlined-start-adornment'
										sx={{ m: 1, width: '25ch' }}
										style={{ width: '300px' }}
										name='confirm_password'
										onChange={handleChange}
										value={values.confirm_password}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton onClick={handleConfirmPasswordVisibility}>
														{showConfirmPassword ? (
															<VisibilityIcon sx={{ color: '#3772FF' }} />
														) : (
															<VisibilityOffIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</div>
									{errors.confirm_password && touched.confirm_password ? (
										<div className={`red mb-1 fs15`}>
											{errors.confirm_password}
										</div>
									) : null}
									<div className='emp_btn-group'>
										<button
											className='emp_btn'
											onClick={() =>
												handleButtonClick(setFieldValue, submitForm, errors)
											}
										>
											SUBMIT
										</button>
									</div>
									<br />
									<br />
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>

			<div className={classes.footerSignup}>
				<div>
					{' '}
					<Link
						className='link-styling'
						to='/resetpassword'
						style={{ textDecoration: 'underline' }}
					>
						Forgot password?
					</Link>
				</div>
				<div>
					<Link
						className='link-styling'
						to='/'
						style={{ textDecoration: 'underline' }}
					>
						Already have an account? Login
					</Link>
				</div>
			</div>
			<Toaster />
			</div>
		</div>
	);
};

export default AddEmployer;
