import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Divider,
	Modal,
	Card,
	CardContent,
	ReactModal,
	Paper,
	Box,
} from '@mui/material';
import LinearProgress, {
	linearProgressClasses,
} from '@mui/material/LinearProgress';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import uploadFiles from '../../../asset/image/memberImages/uploadFiles.png';
import filedownload from '../../../asset/image/memberImages/file.svg';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import cross from '../../../asset/image/memberImages/cross.png';
import ExcelHeader from './ExcelHeader';
import { server } from '../../../redux/store';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import duplicateData from './duplicateData';
import { Link, useNavigate } from 'react-router-dom';
import InitiateBiling from '../Invoices/InitiateBiling';
import 'react-toastify/dist/ReactToastify.css';
import {
	encryptData,
	decryptData,
} from '../../../redux/incript&decript/cryptoUtils';
import {
	CSVFileRecord,
	userRecords,
	addBulkMembers,
	getmembersInEnterprise,
	continueEnterpriseAccount,
} from '../../../redux/action/user';
import { useDispatch, useSelector } from 'react-redux';
const useStyles = makeStyles({
	importTitle: {
		fontSize: '24px',
		fontWeight: '500',
	},

	// uploadDocCard:{
	//   width: "480px",
	//   height: "229px",
	//   border: "1px solid rgba(145, 158, 171, 0.32)",
	//   borderRadius: "10px",
	//   marginLeft: "11px",
	// },

	dragBox: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dropZoneBox: {
		width: '479px',
		height: '229px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '20px',
		borderWidth: '2px',
		borderRadius: '4px',
		borderColor: '#fff',
		borderStyle: 'groove',
		backgroundColor: '#fff',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
});

const MemberModel = ({
	showImportFile,
	handleOpenImportFile,
	handleCloseImportFile,
	showMembers,
	setShowMembers,
	step,
	setStep,
	currentPage,
	query,
	handleClose,
	update,
	setUpdate,
}) => {
	const [openBilling, setOpenBilling] = useState(false);
	const [file, setFile] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [showDuplicates, setShowDuplicates] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [bulkUser, setBulkUser] = useState([]);
	const classes = useStyles();
	const [open, setOpen] = useState(true);
	const [dublicateEmail, setDublicateEmail] = useState([]);
	const [showDublicateModal, setShowDublicateModal] = useState(false);
	const [allUserRegistedModal, setAllUserRegistedModal] = useState(false);
	const { countUser, recordUser, isObject, csvFile } = useSelector(
		state => state.user
	);
	const { user } = useSelector(state => state?.user);

	// css
	const importButtonUpload = {
		borderRadius: '24px',
		Padding: '2px, 27px, 12px, 27px',
		background: '#4157FF ',
		textTransform: 'none ',
		color: 'white',
		fontSize: '15px',
		fontWeight: '600',
		width: 'auto',
		height: '49px',
	};
	const closeButttonDuplicate = {
		background: '#4157FF ',
		borderRadius: '24px',
		color: 'white',
		fontWeight: 'bold',
		height: '46px',
		width: '110px',
	};

	//

	const dispatch = useDispatch();
	/////////Upload excel and csv file////////////////
	const acceptedFileTypes = ['text/csv'];

	const requiredHeaders = ['Email'];

	const handleDrop = acceptedFiles => {
		const file = acceptedFiles[0];
		if (file && acceptedFileTypes.includes(file.type)) {
			setFile(file);
			validateFile(file);
		} else {
			toast.error('Please select a valid CSV file.');
		}
	};
	//

	const handleFileChange = event => {
		const file = event.target.files[0];
		// setCSVFile(file)
		if (file && acceptedFileTypes.includes(file.type)) {
			setFile(file);
			validateFile(file);
		} else {
			toast.error('Please select a valid CSV file.');
		}
	};

	//

	const validateFile = file => {
		const reader = new FileReader();
		reader.onload = e => {
			const contents = e.target.result;
			const lines = contents.split('\n');
			const headers = lines[0].split(',').map(header => header.trim());
			const missingHeaders = requiredHeaders.filter(
				header => !headers.includes(header)
			);
			const incorrectArrangement = !requiredHeaders.every(
				(header, index) => header === headers[index]
			);
			const misspelledHeaders = headers.filter(
				header => !requiredHeaders.includes(header)
			);
			const missingDataFields = [];

			if (
				missingHeaders.length === 0 &&
				!incorrectArrangement &&
				misspelledHeaders.length === 0
			) {
				for (let i = 0; i < lines.length; i++) {
					const data = lines[i].split(',');
					for (let j = 1; j < headers.length; j++) {
						if (headers[j] && data[j]?.trim().length === 0) {
							missingDataFields.push(headers[j]);
						}
					}
				}

				if (missingDataFields.length === 0) {
					setFile(file);
				} else {
					setFile(null);
					const missingFieldNames = missingDataFields
						.map(field => `${field} (${headers.indexOf(field) + 1})`)
						.join(', ');
					toast.error(
						`The following data fields are missing: ${missingFieldNames}`
					);
				}
			} else {
				setFile(null);
				if (missingHeaders.length > 0) {
					toast.error(
						`The file is missing the following headers: ${missingHeaders.join(
							', '
						)}. Please modify your CSV file to include the following then re-submit.`
					);
				} else if (incorrectArrangement) {
					toast.error(
						`<div dangerouslySetInnerHTML={{__html:'The file headers order is incorrect.<br />Note: CSV file must only contain employee email addresses and must have header in column A that says Email.',}}/>`
					);
				} else if (misspelledHeaders.length > 0) {
					toast.error(
						`The file contains the following misspelled headers: ${misspelledHeaders.join(
							', '
						)}`
					);
				} else {
					toast.error('Invalid file. Please check the file headers.');
				}
			}
			const data = [];
			for (let i = 1; i < lines.length; i++) {
				const row = lines[i].split(',').map(value => value.trim());
				const rowData = {};
				for (let j = 0; j < headers.length; j++) {
					rowData[headers[j]] = row[j];
				}
				data.push(rowData);
			}
			setBulkUser(data);
		};

		reader.onerror = () => {
			toast.error('Error reading file. Please try again.');
		};

		reader.readAsText(file);
	};

	//

	// duplicate enteries
	const navigate = useNavigate();

	const [openDialog, setOpenDialog] = useState(false);

	// Function to open the dialog
	const openDuplicateDialog = () => {
		setOpenDialog(true);
	};

	// Function to close the dialog
	const closeDuplicateDialog = () => {
		setOpenDialog(false);
	};

	const getDuplicateEntries = () => {
		const duplicateEntries = duplicateData.filter(
			(item, index) =>
				duplicateData.findIndex(
					obj => obj.name === item.name && obj.email === item.email
				) !== index
		);

		return duplicateEntries;
	};

	const duplicateEntries = getDuplicateEntries();
	//

	const handleUpload = async () => {
		if (!file) {
			toast.error('Please select a file to upload.');
			return;
		}

		if (!showDuplicates) {
			setShowDuplicates(true);
			setOpenDialog(true);
		} else {
			setShowDuplicates(false);
			setOpenDialog(false);
			if (!showDuplicates) {
				navigate('/employers');
			}
		}

		const formData = new FormData();
		formData.append('csv_file', file);

		checkDublicateEmail();

		// dispatch(addBulkMembers(csvFile, countUser));
		// dispatch(getmembersInEnterprise(query, currentPage));
		// handleCloseImportFile();

		formData.append('totalUser', countUser);
		formData.append('csv_file', csvFile);
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;
			const encrypted_data = encryptData({ formData });
			let { data } = await axios.post(
				`${server}/deduct-And-Transfer-bulk-user`,
				formData,
				{
					headers: {
						Authorization: token,
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			console.log(decryptedData, 'sadsad');
			await dispatch(getmembersInEnterprise(query, currentPage));
			setUpdate(!update);
			await dispatch(continueEnterpriseAccount(user?.email, 1));
			await handleCloseImportFile();
		} catch (error) {
			const decryptedErrorMessage = decryptData(
				error?.response?.data?.encrypted_data
			);

			// console.log("error?.response?.data?.message", error?.response?.data?.message)
			toast.error(decryptedErrorMessage?.message);
		}
	};

	const checkDublicateEmail = async () => {
		try {
			const formData = new FormData();
			formData.append('csv_file', file);
			const { data } = await axios.post(
				`${server}/bulk_member_email_check`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: token,
					},
				}
			);
			const decryptedData = decryptData(data.encrypted_data);
			// console.log("File upload complete");
			// console.log(decryptedData,"asddsadsaas332");
			dispatch(CSVFileRecord(decryptedData?.non_existing_email_count, file));
			if (decryptedData?.non_existing_email_count < 1) {
				setAllUserRegistedModal(true);
				handleCloseImportFile();
				return setDublicateEmail(decryptedData?.existing_emails);
			}
			if (decryptedData?.existing_email_count > 0) {
				setShowDublicateModal(true);
				handleCloseImportFile();
				return setDublicateEmail(decryptedData?.existing_emails);
			}

			setStep(2);
			// setOpenBilling(true);
			handleCloseImportFile();
			setShowDublicateModal(false);
		} catch (error) {
			console.error('File upload failed', error);
		}
	};

	const skipDublicateEmail = () => {
		setStep(2);
		setOpenBilling(true);
		handleCloseImportFile();
		setShowDublicateModal(false);
	};
	const handleCloseBilling = () => {
		setOpenBilling(false);
		handleClose();
		setStep(1);
	};
	const handleOpenBilling = () => {
		setOpenBilling(true);
	};
	const nextBillingStep = () => {
		setOpenBilling(false);
		setStep(3);
		setShowMembers(false);
	};
	const closeModal = () => {
		closeDuplicateDialog();
		setShowDublicateModal();
		handleCloseImportFile();
		setAllUserRegistedModal(false);
	};
	return (
		<div>
			{showDublicateModal ? (
				<Dialog open={showDublicateModal} onClose={closeModal} maxHeight={400}>
					<DialogTitle
						sx={{ display: 'flex', justifyContent: 'space-between' }}
					>
						<span style={{ fontSize: '24px', fontWeight: '600' }}>
							Duplicate Data
						</span>
						<IconButton
							onClick={closeModal}
							style={{ position: 'absolute', top: 0, right: 0 }}
						>
							<Box
								style={{
									background: '#4157FF',
									width: '42px',
									height: '42px',
									fontSize: '11px',
									boxShadow: 'none',
									borderRadius: '24px',
								}}
							>
								<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
							</Box>
						</IconButton>
					</DialogTitle>
					<DialogContent
						sx={{ marginTop: '10px' }}
						style={{ minWidth: '550px' }}
					>
						{showDublicateModal
							? dublicateEmail?.map((entry, index) => (
									<Box key={index}>
										<Box
											sx={{
												display: 'flex',
												marginTop: '10px',
												justifyContent: 'space-between',
												flexDirection: 'column',
											}}
										>
											<Typography style={{ marginTop: '4px' }}>
												{' '}
												<span style={{ fontWeight: '600' }}>Email:</span>{' '}
												{entry.email}
											</Typography>
										</Box>
										{index !== dublicateEmail.length - 1 && (
											<Divider sx={{ marginTop: '22px' }} />
										)}{' '}
										{/* Add a divider if it's not the last entry */}
									</Box>
							  ))
							: ''}
					</DialogContent>

					<DialogActions
						sx={{
							display: 'flex',
							marginTop: '10px',
							justifyContent: 'center',
						}}
					>
						{/* <Button
							sx={{
								background: '#4157FF !important',
								borderRadius: '24px',
								color: 'white',
								fontWeight: 'bold',
								height: '46px',
								minWidth: '110px',
							}}
							onClick={() => skipDublicateEmail()}
						>
							Skip Dublicate User
						</Button> */}
					</DialogActions>
				</Dialog>
			) : (
				''
			)}

			{allUserRegistedModal ? (
				<Dialog
					open={allUserRegistedModal}
					onClose={closeModal}
					maxHeight={400}
				>
					<DialogTitle
						sx={{ display: 'flex', justifyContent: 'space-between' }}
					>
						<span style={{ fontSize: '24px', fontWeight: '600' }}>
							Duplicate Data
						</span>
						<IconButton
							onClick={closeModal}
							style={{ position: 'absolute', top: 0, right: 0 }}
						>
							<Box
								style={{
									background: '#4157FF',
									width: '42px',
									height: '42px',
									fontSize: '11px',
									boxShadow: 'none',
									borderRadius: '24px',
								}}
							>
								<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
							</Box>
						</IconButton>
					</DialogTitle>
					<DialogContent
						sx={{ marginTop: '10px' }}
						style={{ minWidth: '550px' }}
					>
						<h2 style={{ textAlign: 'center' }}>
							All members are already registered
						</h2>
						<p
							style={{
								marginTop: '10px',
								fontSize: '15px',
								textAlign: 'center',
							}}
						>
							{' '}
							<span style={{ fontWeight: 'bold', fontSize: '15px' }}>
								Note:
							</span>{' '}
							CSV file must only contain employee email addresses and must have
							header in column A that says Email.
						</p>
					</DialogContent>

					<DialogActions
						sx={{
							display: 'flex',
							marginTop: '10px',
							justifyContent: 'center',
						}}
					>
						<Button style={closeButttonDuplicate} onClick={() => closeModal()}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			) : (
				''
			)}
			<ToastContainer />
			<Dialog
				open={showImportFile}
				onClose={handleCloseImportFile}
				maxWidth='md'
			>
				{' '}
				{/* maxWidth="sm" ,md,lg */}
				<DialogTitle className={classes.importTitle}>
					Import Document
					<IconButton
						onClick={handleCloseImportFile}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px',
								boxShadow: 'none',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent sx={{ marginTop: '10px' }}>
					{' '}
					{/* sx={{ width: '500px' }} */}
					<DialogContent className={classes.uploadDocCard}>
						<Dropzone onDrop={handleDrop} onChange={handleFileChange}>
							{({ getRootProps, getInputProps, isDragActive }) => (
								<Box
									className={classes.dropZoneBox}
									{...getRootProps()}
									style={
										isDragActive
											? { borderColor: '#2196f3', backgroundColor: '#e3f2fd' }
											: {}
									}
								>
									<Box sx={{ marginLeft: '6px' }}>
										<input {...getInputProps()} />
										<img
											style={{
												width: '42px',
												height: '28px',
												cursor: 'pointer',
											}}
											src={uploadFiles}
											alt='uploadFile'
										/>
									</Box>
									<Typography
										style={{
											color: 'black',
											fontSize: '14px',
											fontWeight: '400',
											marginTop: '5px',
											marginLeft: '4px',
										}}
									>
										{' '}
										<span
											style={{
												borderBottom: '1px solid black',
												cursor: 'pointer',
											}}
										>
											Upload Files
										</span>{' '}
									</Typography>
									<p style={{ color: 'black' }}>{file && file.name}</p>
									<span
										style={{
											color: 'black',
											fontSize: '12px',
											fontWeight: '400',
										}}
									>
										Drag or Drop files here
									</span>
									<span
										style={{
											color: 'black',
											fontSize: '14px',
											fontWeight: '400',
										}}
									>
										{/* Max 4 files */}
										(CSV File Size 5mbs)
									</span>
								</Box>
							)}
						</Dropzone>
					</DialogContent>
					{/* upload bar file below card */}
					{/* <Box sx={{ display: "flex", margin: "22px", gap: "14px" }}>
            <Box>
              <img src={filedownload} alt="" />
            </Box>
            <Box sx={{ display: "grid" }}>
              <span style={{ fontSize: "12px" }}>Member List</span>

              {uploadProgress > 0 && (
                <LinearProgress
                  sx={{ width: "400px" }}
                  variant="determinate"
                  value={uploadProgress}
                />
              )}
              <LinearProgress
                sx={{ width: "400px" }}
                variant="determinate"
                value={progress}
              />
            </Box>
            <Box>
              <img src={cross} alt="" />
            </Box>
          </Box> */}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: '14px',
							marginButton: '2px',
						}}
					>
						<p style={{ fontSize: '15px', textAlign: 'center' }}>
							CSV file must only contain employee email addresses <br />
							and must have header in column A that says Email.
						</p>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: '52px',
						}}
					>
						<Button style={importButtonUpload} onClick={handleUpload}>
							Submit Upload
						</Button>

						<ExcelHeader />
					</Box>
				</DialogContent>
			</Dialog>
			{step == 2 && (
				<InitiateBiling
					setStep={setStep}
					handleClose={handleCloseBilling}
					open={openBilling}
					setOpen={setOpenBilling}
					handleOpen={handleOpenBilling}
					nextBillingStep={nextBillingStep}
				/>
			)}
		</div>
	);
};

export default MemberModel;
