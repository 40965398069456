import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VerificationInput from 'react-verification-input';
import { useRef } from 'react';
import {
	EmailVerified,
	clearSelfPayFirstStepAction,
	resendVerifyCode,
} from '../../redux/action/user';
import { useDispatch, useSelector } from 'react-redux';
import { StaticTimePicker } from '@mui/lab';
const OTPscreen = ({ isShow, phone_number, setStep, step, setIsContinue }) => {
	const inputRef = useRef();
	const removeBorder = {
		border: 'none',
	};

	const [openPhoneVerifiedModal, setOpenPhoneVerifiedModal] =
		React.useState(isShow); // OTP Verification Modal
	const state = useSelector(state => state.user);
	const dispatch = useDispatch();
	const [timeLeft, setTimeLeft] = useState(60);
	useEffect(() => {
		if (!timeLeft) return;

		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [JSON.stringify(timeLeft)]);
	const resendCode = () => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		// console.log('loginUser', loginUser);
		const email = loginUser?.email;
		dispatch(resendVerifyCode(email));
	};
	
	const nextState = () => {

		if (!phone_number) {
			const user = JSON.parse(localStorage.getItem('user'));
			if (user && user.phone) {
				phone_number = user.phone;
			} else {
				console.error('Phone number not found in localStorage');
				return;
			}
		}
		const code = inputRef.current.value;
		dispatch(EmailVerified(phone_number, code), () => {});
	};

	useEffect(() => {
		if (state.isSignupCodeVerified === true) {
			setStep(4);
			setIsContinue(false);
		} else {
			setIsContinue(true);
			setTimeLeft(60);
		}
	}, [state.isSignupCodeVerified]);

	useEffect(() => {
		dispatch(clearSelfPayFirstStepAction());
	}, []);
	return (
		<div>
			<Modal
				//  className={classes.modal}

				open={openPhoneVerifiedModal}
				// onClose={handleClosePhoneModal}
				aria-labelledby='modal-title'
				aria-describedby='modal-description'
				BackdropProps={{
					timeout: 500,
					style: { backdropFilter: 'blur(3px)' }, // Apply the blur effect
				}}
			>
				<Box style={removeBorder}>
					<Box
						sx={{
							position: 'absolute',
							borderRadius: '3.125rem',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '445px',
							height: '500px',
							margin: 'auto',
							bgcolor: 'background.paper',
							boxShadow: 24,
							p: 4,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Box
							sx={{
								display: 'grid',
								gap: '20px',
								flexDirection: 'column',
								justifyContent: 'center',
								width: '300px',
								alignItems: 'center',
								textAlign: 'center',
								margin: 'auto auto',
							}}
						>
							<span style={{ fontSize: '1.5rem', fontWeight: 700 }}>
								Enter the verify code
							</span>
							<span
								style={{
									fontSize: '0.875rem',
									color: 'rgba(9, 15, 71, 0.45)',
									marginTop: '-12px',
								}}
							>
								We just send you a verification code email via phone {phone_number}
							</span>
							<Box>
								<VerificationInput
									classNames={{
										character: 'character',
										characterInactive: 'character--inactive',
										characterSelected: 'character--selected',
									}}
									length={4}
									ref={inputRef}
								/>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<span style={{ color: 'grey' }}>
									The verify code will expire in {timeLeft}
								</span>
								<br />
								{timeLeft < 1 ? (
									<span
										style={{
											textAlign: 'center',
											color: '#3772FF',
											cursor: 'pointer',
										}}
										onClick={() => resendCode()}
									>
										Resend Code
									</span>
								) : (
									''
								)}
							</Box>

							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Button
									variant='contained'
									color='primary'
									size='large'
									onClick={nextState}
									disabled={timeLeft < 1}
								>
									SUBMIT CODE
								</Button>
								<br />
								{/* <Button variant="outlined" size="large">
                  BACK
                </Button>{" "} */}
							</Box>
						</Box>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default OTPscreen;
