import React from 'react';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import avatar from '../../asset/image/avatar.svg';
import { useState } from 'react';

// Css Style
const useStyles = makeStyles({
	root: {
		fontSize: '10px !important',
		fontWeight: 'bold !important',
		textAlign: 'center  !important',

		color: '#23262F !important',
	},
	bold: {
		fontWeight: 'bold !important',
		color: '#3772FF !important',
	},
	headingIcon: {
		display: 'flex',
	},

	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	rounded: {
		borderRadius: '22px !important',
	},
	eyes: {
		cursor: 'pointer',
	},
	verified: {
		color: '#00C48C',
		marginLeft: '6%',
		fontWeight: 'bold',
	},

	centerValue: {
		display: 'flex',
		justifyContent: 'center',
	},
	successbuttonColor: {
		backgroundColor: '#4CD964',
	},
	pendingbuttonColor: {
		backgroundColor: '#FFA23A',
	},
	fraudbuttonColor: {
		backgroundColor: '#E8505B',
	},

	activebuttonStyle: {
		// backgroundColor: "#4CD964",
		color: 'white',
		minWidth: '91px',
		minHeight: '23px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '8rem',
	},
});

const StatusHandling = ({ children, currentPath, showStatusHandling }) => {
	const classes = useStyles();

	const navigate = useNavigate();

	// const showStatusHandling = props.showStatusHandling;

	// const [showStatusHandling, setShowStatusHandling] = useState(true);

	return (
		<div className='home'>
			<div>
				{/* <Sidebar  /> */}
				{showStatusHandling ? (
					<>
						<Sidebar showSidebar={true} showHelpButton={true} />
					</>
				) : (
					<>
						<Sidebar showSidebar={false} showHelpButton={false} />
					</>
				)}
			</div>
			<div className='homeContainer'>
				<Header showHeader={true} currentPath={currentPath} />
				<Container
					maxWidth='xl'
					style={{
						position: 'relative',
						top: '110px',
						height: 'auto',
						background: '#f8f9fe',
					}}
				>
					<Box style={{ display: 'flex', justifyContent: 'center' }}>
						<Box
							style={{
								border: '25px',
								background: 'white',
								width: '80vw',
								height: '400px',
								marginTop: '80px',
							}}
						>
							<Box
								style={{
									display: 'flex',
									gap: '40px',
									flexDirection: 'column',
									marginTop: '5rem',
								}}
							>
								<Box
									style={{
										margin: '20px',
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<span style={{ fontSize: '22px', fontWeight: '700' }}>
										{children
											? children
											: 'Data is not present related to that ID'}
									</span>
								</Box>

								<Box style={{ display: 'flex', justifyContent: 'center' }}>
									<button
										onClick={() => navigate('/')}
										style={{
											color: 'white',
											background: '#4157FF',
											borderRadius: '56px',
											border: 'none',
											width: '203px',
											height: '50px',
											cursor: 'pointer',
											fontWeight: 700,
											fontFamily: ' DM Sans',
										}}
									>
										Go to Home
									</button>
								</Box>
							</Box>
						</Box>
					</Box>
				</Container>
			</div>
		</div>
	);
};

export default StatusHandling;
