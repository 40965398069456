import './login.css';
import Logo from '../../asset/image/Logo.svg';
import healthcoinLogo from '../../asset/image/healthcoinLogo.png';
import Email from '../../asset/image/email.svg';
import Password from '../../asset/image/password.svg';
import React, { useEffect, useState } from 'react';
import { setUser } from '../../redux/reducer/auth.slice';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import './login.css';
import {
	Box,
	Card,
	CardContent,
	Typography,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Button,
} from '@mui/material';
import {
	useNavigate,
	Link,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { login } from '../../redux/action/user';
import { server } from '../../redux/store';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import OTPScreen from '../PatientRegistration/OTPscreen';
import * as Yup from 'yup';
import DeactiveModal from '../DeactiveModal';
import {
	decryptData,
	encryptData,
} from '../../redux/incript&decript/cryptoUtils';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
	card: {
		width: 445,
		height: ({ formAlreadyRegEmail }) => formAlreadyRegEmail ? 500 : 550,
		borderRadius: '20px !important',

		[theme.breakpoints.down('sm')]: {
			width: 351,
		},
	},
	forgotCreatePassDiv: {
		display: 'flex',
		marginTop: '22px',
		gap: '163px',
		[theme.breakpoints.down('sm')]: {
			gap: '63px',
		},
	},
	logoTopGrid: {
		marginTop: '22px',
	},
	topLogo: {
		width: '253px',
		height: '63',
	},
	healthcoinlogo: {
		width: '273px',
		height: '49.26px',
	},
	loginHeadingGrid: {
		marginLeft: '22px',
		marginTop: '28px',
	},

	loginHeadingBox: {
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		margin: '33px',
	},
	formGrid: {
		marginLeft: '33px',
		marginTop: '33px',
	},
	textFieldBox: {
		display: 'grid',
		gap: '10px',
		flexDirection: 'coloumn',
		alignContent: 'center',
		justifyContent: 'center',
	},
	loginBox: {
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		margin: '32px',
	},

	footer: {
		display: 'flex',
		gap: '141px',
		marginTop: '19px',
	},
}));

const Home = (setStep, step, setIsContinue) => {
	const [searchParams] = useSearchParams();
	const formAlreadyRegEmail = searchParams.get('formAlreadyRegEmail');

	const classes = useStyles({ formAlreadyRegEmail });
	const [openPhoneVerifiedModal, setOpenPhoneVerifiedModal] =useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const {  selpPayFirstStep } = useSelector(state => state.user);
	useEffect(() => {
		const dd = localStorage.getItem('deactivate');
		if (dd) {
			setOpen(true);
		}
	}, []);

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.required('*email is required')
			.matches(
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				'*Please enter a valid email address'
			),
		password: Yup.string().required('*password is required'),
	});

	const getUserData = async () => {
		const token = `Bearer ${localStorage.getItem('token')}`;

		const res = await axios.get(`${server}/profile`, {
			headers: { Authorization: token, 'Content-Type': 'application/json' },
		});
		// console.log(res);

		const dec = decryptData(res.data.encrypted_data);
		// console.log(dec);

		if (
			dec?.data.user.user_type == 'enterprise' &&
			dec?.data.user.status == 0
		) {
			setOpen(true);
			localStorage.clear();
			return;
		}
		setUser(dec?.data.user);
	};

	const submitHandler = (values, { setSubmitting }) => {
		if (formAlreadyRegEmail) {
			const token = `Bearer ${localStorage.getItem('token')}`;
			const encrypted_data = encryptData({
				email: values.email,
				password: values.password,
			});
	
			axios.post(`${server}/web-login`, {
				encrypted_data: encrypted_data,
			}, {
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
			}).then(({ data }) => {
				const decryptedData = decryptData(data.encrypted_data);
	
				if (decryptedData?.success === true) {
					toast.success(decryptedData.message);
					localStorage.setItem('token', decryptedData?.access_token);
					localStorage.setItem('user', JSON.stringify(decryptedData?.user));
	
					if (decryptedData?.user.email_verified_at === null) {
						toast.success(decryptedData.message);
						navigate('/');
					} else {
						navigate('/');
					}
				} 
			}).catch(err => {
				
				const decryptedDataError =decryptData(err.response.data.encrypted_data);
				toast.success(decryptedDataError.message);
			});
			return;
		}
	
		let device_by = 'web';
		dispatch(login(values.email, values.password, device_by));
		setSubmitting(false);
	};
	
	useEffect(() => {
        console.log( selpPayFirstStep ,'abcds');
        if (selpPayFirstStep) {
            navigate('/');
        }
    }, [selpPayFirstStep]);
	return (
		<>
		<div>
            {openPhoneVerifiedModal && (
                <OTPScreen
                    isShow={true} // You can pass other props here
                    // other props
                />
            )}
        </div>
			<ToastContainer />
			<Box
				className='mainBox'
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<Box>
					<Card className={classes.card}>
						<CardContent>
							{/* health coin logo */}
							<Box
								sx={{
									display: 'grid',
									flexDirection: 'coloumn',
									justifyContent: 'center',
									alignContent: 'center',
								}}
							>
								<img className={classes.topLogo} src={Logo} alt='Logo' />
								<img
									style={{ marginTop: '4px' }}
									className={classes.healthcoinlogo}
									src={healthcoinLogo}
									alt='healthcoinlogo'
								/>
							</Box>

							{/* Login to your account heading */}
							<Box className={classes.loginHeadingBox}>
							{!formAlreadyRegEmail && (
								<Typography
									variant='h1'
									style={{
										fontWeight: '500 ',
										color: '#23262F ',
										fontSize: '24px ',
									}}
								>
									Login to your account
								</Typography>
							)}
							</Box>

							{/* Formik form */}
							<Formik
								initialValues={{
									email: '',
									password: '',
								}}
								validationSchema={validationSchema}
								onSubmit={submitHandler}
							>
								<Form>
									{/* email field */}
									<Box className={classes.textFieldBox}>
										<Field
											type='text'
											name='email'
											placeholder='Email'
											className='mailField'
											as={TextField}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<img src={Email} alt='email' />
													</InputAdornment>
												),
											}}
											fullWidth
											style={{ width: '300px' }}
											size='sm'
										/>
										<ErrorMessage
											name='email'
											component='div'
											style={{ color: 'red' }}
										/>

										{/* password field */}
										<Field
											type='password'
											name='password'
											placeholder='Password'
											className='mailField'
											as={TextField}
											InputProps={{
												startAdornment: (
													<InputAdornment
														position='start'
														style={{ backgroundClip: 'red' }}
													>
														<img src={Password} alt='email' />
													</InputAdornment>
												),
											}}
											fullWidth
											style={{ width: '300px' }}
											size='sm'
										/>
										<ErrorMessage
											name='password'
											component='div'
											style={{ color: 'red' }}
										/>
									</Box>

									{/* remember me checked box */}
									<Box sx={{ margin: '22px' }}>
									{!formAlreadyRegEmail && (
										<FormGroup className='fromGroupleft'>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label='Remember me'
												style={{ color: '#8898AA', fontSize: '14px' }}
											/>
										</FormGroup>
									)}
									</Box>

									{/* login button */}
									<Box className={classes.loginBox}>
										{formAlreadyRegEmail ? (
											<Button
												type='submit'
												style={{
													color: 'white',
													background: '#4157FF',
													borderRadius: '15px',
													border: 'none',
													width: 'auto',
													height: '50px',
													cursor: 'pointer',
													fontWeight: '600',
													marginTop:'30px',
												}}
											>
												Proceed your registration
											</Button>
										) : (
											<Button
												type='submit'
												style={{
													color: 'white',
													background: '#4157FF',
													borderRadius: '56px',
													border: 'none',
													width: '203px',
													height: '50px',
													cursor: 'pointer',
													fontWeight: '600',
												}}
											>
												Login
											</Button>
										)}
									</Box>
								</Form>
							</Formik>
						</CardContent>
					</Card>

					<Box className={classes.forgotCreatePassDiv}>
						{/* 63px */}
						{!formAlreadyRegEmail && (
							<Box>
								<Link
									style={{ color: 'white', textDecoration: 'underline' }}
									to='/resetpassword'
								>
									Forgot password?
								</Link>
							</Box>
						)}
						{!formAlreadyRegEmail && (
							<Box>
								<Link
									style={{ color: 'white', textDecoration: 'underline' }}
									to='/signup'
								>
									Create new account
								</Link>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
			<DeactiveModal open={open} setOpen={setOpen} />
		</>
	);
};

export default Home;
