import React, { useState, useEffect } from 'react';
import {
	Box,
	Button,
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../Layout/Sidebar/Sidebar';
import Header from '../../../Layout/Header/Header';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceById } from '../../../../redux/action/user';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { server } from '../../../../redux/store';
import Shape from '../../../../asset/image/Header/Shape.png';
import {
	encryptData,
	decryptData,
} from '../../../../redux/incript&decript/cryptoUtils';
const useStyles = makeStyles({
	novHeading: {
		fontSize: '30px !important',
		fontWeight: '600  !important',
		fontFamily: 'DM Sans  !important',
	},
	totalBalance: {
		fontSize: '10px  !important',
		fontWeight: '600  !important',
		marginTop: '4px  !important',
	},
	amountHeading: {
		fontSize: '16px  !important',
		fontWeight: '600  !important',
	},

	headingCell: {
		border: 'none  !important',
		fontSize: '12px  !important',
		fontWeight: '600  !important',
		padding: '3px !important',
	},
	bodyCell: {
		border: 'none  !important',
		fontSize: '12px  !important',
		fontWeight: '500  !important',
		width: '150px  !important',
	},
});
const InvoiceMonthTable = ({ month }) => {
	const loadStyle = {
		color: 'white',
		background: '#4157FF',
		borderRadius: '56px',
		border: 'none',
		width: '203px',
		height: '50px',
		textTransform: 'capitalize',
		cursor: 'pointer',
		fontWeight: 700,
		fontFamily: ' DM Sans',
		cursor: 'not-allowed',
	};
	const [load, setLoad] = useState(false);
	let { id } = useParams();
	const [tableData, setTableData] = useState([]);
	const [totalDebit, setTotalDebit] = useState('');
	const dispatch = useDispatch();
	useEffect(() => {
		getInvoiceById(id);
	}, []);
	const getInvoiceById = async () => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;

			// console.log("Coming Data",data);
			let { data } = await axios.get(
				`${server}/get_invoice_by_id/?month=${id}`,

				{
					headers: { Authorization: token, 'Content-Type': 'application/json' },
				}
			);

			const decryptedData = decryptData(data.encrypted_data);
			setTableData(decryptedData?.data?.invoices);
			const roundedValue = decryptedData?.data?.totalDebt.toFixed(2);
			setTotalDebit(roundedValue);
		} catch (err) {
			// console.log("err?.response?", err?.response?.data?.message);
		}
	};

	// download the Pdf
	const handleDownloadPDF = () => {
		const input = document.getElementById('pdf-content');
		setLoad(true);

		const options = {
			dpi: 300, // Adjust the DPI value (e.g., 150, 300, etc.)
			scale: 2, // Adjust the scale value to increase the resolution
			useCORS: true, // Enable cross-origin resource sharing if needed
			allowTaint: true, // Allow tainting of the canvas (may be required if your content contains images from other domains)
		};

		html2canvas(input, options).then(canvas => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF('p', 'pt', 'a4'); // Set the document size and orientation as per your requirements
			const imgProps = pdf.getImageProperties(imgData);
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

			pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
			pdf.save('invoice_page.pdf');
			setLoad(false);
		});
	};

	const [path, setPath] = useState(
		<span
			style={{
				marginLeft: '10px',
				fontSize: '12px',
				fontWeight: '500',
				color: '#D0D1D2',
			}}
		>
			INVOICES{' '}
			<span style={{ fontSize: '15px', marginLeft: '10px' }}>
				{' '}
				<img src={Shape} alt='' />
			</span>
			<span
				style={{
					marginLeft: '10px',
					fontSize: '12px',
					fontWeight: '500',
					color: '#D0D1D2',
				}}
			>
				{id?.toUpperCase()}
			</span>
		</span>
	);

	const classes = useStyles();

	return (
		<div style={{ display: 'flex', background: '#F8F9FE', height: 'auto' }}>
			<Sidebar showSidebar={false} showHelpButton={false} />

			<div style={{ background: '#F8F9FE' }}>
				<Header showHeader={true} currentPath={path} />
				<Box
					style={{ position: 'relative', top: '0', marginTop: '32px' }}
					sx={{ background: '#f8f9fe' }}
				>
					<Sidebar showSidebar={true} showHelpButton={true} />
					<Box sx={{ display: 'flex', flexDirection: 'column', width: '78vw' }}>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginRight: '48px',
							}}
						>
							{load ? (
								<LoadingButton
									style={loadStyle}
									loading
									loadingPosition='start'
									startIcon={<SaveIcon />}
									variant='outlined'
									disabled
								>
									Download As PDF
								</LoadingButton>
							) : (
								<button
									style={{
										color: 'white',
										background: '#4157FF',
										borderRadius: '56px',
										border: 'none',
										width: '203px',
										height: '50px',
										cursor: 'pointer',
										fontWeight: 700,
										fontFamily: ' DM Sans',
									}}
									onClick={handleDownloadPDF}
								>
									Download as PDF
								</button>
							)}
						</Box>
						<div id='pdf-content'>
							<Box
								sx={{
									display: 'flex',
									margin: '20px',
									justifyContent: 'space-between',
									flexDirection: 'coloumn',
								}}
							>
								<Box sx={{ display: 'flex', ml: 8 }}>
									<Typography className={classes.novHeading}>
										{id?.toUpperCase()}
									</Typography>
								</Box>
								<Box
									sx={{
										display: 'flex',
										gap: '12px',
										flexWrap: 'wrap',
										marginRight: '-20px',
									}}
								>
									<Box sx={{ display: 'grid', gap: '5px' }}>
										<Box>
											<Box style={{ marginRight: '48px' }}>
												<Typography className={classes.totalBalance}>
													TOTAL BALANCE: &nbsp;{' '}
													<span className={classes.amountHeading}>
														{' '}
														${totalDebit}{' '}
													</span>
												</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
							{/* table */}
							<Box sx={{ marginLeft: '80px' }}>
								<TableContainer>
									<Table sx={{ width: '70vw' }}>
										<TableHead>
											<TableRow
												sx={{ background: 'none !important', border: 'none' }}
											>
												<TableCell className={classes.headingCell}>
													UNIT
													<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
												</TableCell>

												{/* <Box sx={{display:'flex', justifyContent:'flex-end'}}> */}
												{/* <TableCell className={classes.headingCell} >DEBIT<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} /> </TableCell>
                  <TableCell className={classes.headingCell}>CREDIT<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} /></TableCell> */}
												<TableCell className={classes.headingCell}>
													RUNNING BALANCE
													<KeyboardArrowDownIcon sx={{ paddingTop: '8px' }} />
												</TableCell>
												{/* </Box> */}
											</TableRow>
										</TableHead>
										<TableBody sx={{ background: 'white' }}>
											{tableData?.map(index => (
												<TableRow key={index.id}>
													<TableCell
														sx={{
															border: 'none',
															fontSize: '14px',
															fontWeight: '500',
															fontFamily: 'DM Sans',
														}}
													>
														<span style={{ fontWeight:'bold',color: index?.payment_status === 'rejected' ? 'red' : index?.payment_status === 'pending' ? 'blue' : 'green' }}>
															Payment Status: {index?.payment_status ? index.payment_status : 'approved'}
														</span>
															<br />
															<span style={{fontWeight:'bold',}}>Payment With:{' '}{index?.payment_with ? index.payment_with : 'card'}{' '}</span><br />
														<span>EMPLOYEE ID</span>:{index?.patient_id} <br />
														<span>
															{index?.members[0]?.first_name}{' '}
															{index?.members[0]?.last_name} (monthly interval)
														</span>
													</TableCell>

													{/* <TableCell className={classes.bodyCell} >{index?.payer_debit}</TableCell>
                          <TableCell className={classes.bodyCell}>{index?.payer_credit}</TableCell> */}
													<TableCell
														align='center'
														className={classes.bodyCell}
													>
														{/* {index?.payer_running_balance} */}
														{index?.payer_debit}
													</TableCell>
													{/* </Box> */}
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>

							<Box
								sx={{
									display: 'flex',
									gap: '5px',
									marginLeft: 'auto',
									marginTop: '15px',
									marginBottom: 'auto',
									justifyContent: 'end',
									marginRight: '44px',
								}}
							>
								<span
									style={{ fontSize: '10px', fontWeight: '600', margin: '5px' }}
								>
									TOTAL BALANCE:
								</span>
								<span style={{ fontSize: '16px', fontWeight: '600' }}>
									${totalDebit}
								</span>
							</Box>
						</div>
					</Box>
				</Box>
			</div>
		</div>
	);
};

export default InvoiceMonthTable;
