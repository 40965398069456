import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
	Tab,
	TableContainer,
} from '@mui/material';
import Checkbox from '../Table/Checkbox';
import React, { useState, useEffect } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import avatar from '../../asset/image/avatar.svg';
import eyeView from '../../asset/image/eyeView.svg';
import { useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import PaymentContact from './PaymentContact';
import SendPaymentContact from './SendPaymentContact';
import axios from 'axios';
import { server } from '../../redux/store';
import StatusHandling from '../StatusHandling/StatusHandling';
import Spinner from '../SpinnerTrueFalse/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import moment from 'moment';
import {
	getAllPaymentRequestDetail,
	getUserLogs,
	PercentageAdjustment,
} from '../../redux/action/user';
import { convertToLocalTime, convertLocalTimeToUTC } from '../../utils/date';
import { LoadingButton } from '@mui/lab';
// Css Style
const useStyles = makeStyles({
	usdHeading: {
		fontSize: '14px',
		fontWeight: '400',
		fontFamily: 'DM Sans',
		color: '#23262F',
	},
	companySet: {
		marginLeft: '21px',
		fontWeight: 600,
		fontSize: '24px',
	},
	tabsetting: {
		fontWeight: '600 !important',
		fontSize: '16px !important',
		textTransform: 'none !important',
		color: '#949494 !important',
		'&:focus': {
			color: '#000000 !important',
		},
		'&:active': {
			color: '#000000 !important',
		},
	},

	headingIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	eyes: {
		cursor: 'pointer',
	},
	tablerow: {
		cursor: 'pointer',
	},
	payReqIno: {
		fontFamily: 'DM Sans',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '34px',
	},
	headingCellInvoice: {
		fontSize: '10px',
		fontWeight: '500',
		fontFamily: 'DM Sans',
	},
	bodyCell: {
		fontSize: '10px',
	},
	forgetDebit: {
		width: '203px',
		fontSize: '16px',
		fontWeight: '700',
		color: 'white',
		height: '51px',
		background: '#4CD964',
		borderRadius: '56px',
		cursor: 'pointer',
		border: 'none',
	},
});

const PaymentRequestDetail = () => {
	const classes = useStyles();
	const { uid } = useParams();

	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [patient, setPatient] = useState(null);
	const [provider, setProvider] = useState(null);
	const [appointment, setAppointment] = useState(null);
	const [payments, setPayments] = useState(null);
	const [consultant, setConsultant] = useState(null);
	const [services, setServices] = useState(null);
	const [image, setImage] = useState(null);
	const [load, setLoad] = useState(false);
	const [dataAvailable, setDataAvailable] = useState(false);
	const [loading, setLoading] = useState(true);
	const { user, PaymentRequestDetail } = useSelector(state => state.user);
	const [user_id, setUser_id] = useState(null);
	const [inputValue, setInputValue] = useState();
	const dispatch = useDispatch();
	// css
	const root = {
		fontSize: '12px',
		fontWeight: 'bold',
		textAlign: 'center',
		color: '#23262F',
		margin: '0',
		padding: '0',
	};
	const [value, setValue] = React.useState('1');
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const [path, setPath] = useState(
		<span
			style={{
				marginLeft: '10px',
				fontSize: '12px',
				fontWeight: '500',
				color: '#D0D1D2',
				textTransform: 'uppercase',
			}}
		>
			Payment Request
		</span>
	);

	useEffect(() => {
		dispatch(getAllPaymentRequestDetail(uid));
		fetchPaymentRequestDetails();
	}, [JSON.stringify(PaymentRequestDetail)]);
	useEffect(() => {
		SetUserLog();
	}, [user_id]);
	// Apis get_payment_request_details
	const fetchPaymentRequestDetails = async () => {
		setLoad(true);

		setPayments(PaymentRequestDetail?.payments);
		setPatient(PaymentRequestDetail?.payments?.patient);
		setProvider(PaymentRequestDetail?.payments?.provider);
		setServices(PaymentRequestDetail?.payments?.services);
		setConsultant(PaymentRequestDetail?.payments?.consultant);
		setAppointment(PaymentRequestDetail?.payments?.appointment);
		setImage(PaymentRequestDetail?.payments?.image);
		setUser_id(PaymentRequestDetail?.payments.patient_id);
		setDataAvailable(PaymentRequestDetail !== null);
		setLoading(false);
		// finally {
		//   setLoad(false);
		// }
	};

	const viewer_id = user?.id;
	const formattedUtcDateTime = convertLocalTimeToUTC();
	const message = `Admin '${user?.name}'(${user?.id}, ${user?.email}) has accessed the sensitive information of the patient '${patient?.name}'(${patient?.id}, ${patient?.email}) on ${formattedUtcDateTime}`;
	// set user log

	const SetUserLog = async () => {
		// Make sure user_id is not null before making the API call
		if (user_id) {
			dispatch(getUserLogs(uid, user_id, viewer_id, message));
		}
	};
	// percentageAdjustment
	const adminPayment = payments?.admin_paid_percentage;
	const handleInputChange = e => {
		const value = e.target.value;
		if (!isNaN(value) && value >= 0 && value <= adminPayment) {
			setInputValue(value);
		}
	};
	const [percentLoading, setPercentLoading] = useState(false);
	const handlePercentageAdjustment = async () => {
		setPercentLoading(true);
		dispatch(PercentageAdjustment(inputValue, uid));
		setTimeout(() => {
			dispatch(getAllPaymentRequestDetail(uid));
			setTimeout(() => {
				setPercentLoading(false);
			}, [200]);
		}, [500]);
	};
	const shouldShowButton =
		payments?.payment_request_status == 4 ||
		payments?.adjustment_bill !== null ||
		patient?.flag == 3;
	return (
		<>
			{loading ? (
				<Spinner />
			) : !dataAvailable ? (
				<StatusHandling />
			) : (
				<div className='home'>
					<div>
						<Sidebar showSidebar={false} showHelpButton={false} />
					</div>
					<div className='homeContainer'>
						<Header showHeader={true} currentPath={path} />
						<Container
							maxWidth='xl'
							style={{
								position: 'relative',
								top: '80px',
								height: 'auto',
								background: '#f8f9fe',
							}}
						>
							<Box>
								<p className={classes.companySet}>Request Details</p>

								<Box
									sx={{
										width: '78vw',
										marginLeft: '21px',
										height: '121px',
										background: 'white',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											padding: '20px',
											justifyContent: 'space-between',
										}}
									>
										<Box sx={{ display: 'flex', gap: '20px' }}>
											<Box>
												<img
													src={
														provider?.profile_image
															? 'https://api.healthcoinxchangeportal.com/' +
															provider?.profile_image
															: avatar
													}
													// src={avatar}
													style={{
														width: '71px',
														height: '71px',
														borderRadius: '50px',
													}}
													alt='avatar'
												/>
											</Box>
											{/* card_info */}
											<Box sx={{ display: 'grid' }}>
												<Box>
													<span style={{ fontSize: '16px', fontWeight: '700' }}>
														{provider?.name}
													</span>
												</Box>
												<Box sx={{ display: 'grid' }}>
													<span sx={{ color: '#6E757C', fontSize: '14px' }}>
														{provider?.email}
													</span>
													<span sx={{ color: '#6E757C', fontSize: '14px' }}>
														HC ID:{provider?.healthcoin_id}
													</span>
												</Box>
											</Box>
										</Box>

										<Box sx={{ display: 'grid' }}>
											<span
												style={{
													fontSize: '14px',
													color: '#6E757C',
													fontWeight: '400',
													fontFamily: 'DM Sans',
												}}
											>
												Total Amount Requested
											</span>
											{patient?.flag == 3 ? (
												<span style={{ fontWeight: '700', fontSize: '24px' }}>
													${payments?.patient_bill + payments?.hc_bill}
												</span>
											) : (
												<span style={{ fontWeight: '700', fontSize: '24px' }}>
													${payments?.hc_bill}
												</span>
											)}
										</Box>

										<Box
											sx={{
												display: 'flex',
												marginLeft: '22px',
												gap: '20px',
												marginTop: '14px',
											}}
										>
											<SendPaymentContact
												// hc_bill={payments?.hc_bill}
												hc_bill={patient?.flag == 3 ? payments?.patient_bill + payments?.hc_bill : payments?.hc_bill}
												id={payments?.id}
												payment_request_status={payments?.payment_request_status}
												adjustment_status={payments?.adjustment_status}
											/>
											<PaymentContact
												name={provider?.name}
												email={provider?.email}
											/>
										</Box>
									</Box>
								</Box>
								<TabContext sx={{ mt: 2 }} value={value}>
									<Box>
										<TabList
											onChange={handleChange}
											aria-label='lab API tabs example'
											sx={{ marginLeft: '30px', marginTop: '22px' }}
										>
											<Tab
												sx={{ minWidth: 'unset', width: '30px' }}
												className={classes.tabsetting}
												label='Info'
												value='1'
											/>
											<Tab
												className={classes.tabsetting}
												// label="Payment Details"
												sx={{
													minWidth: 'unset',
													width: '130px',
													marginLeft: '30px',
												}}
												label={
													<div style={{ gap: '5px', display: 'flex' }}>
														<span>Payment</span>
														<span>Details</span>
													</div>
												}
												value='2'
											/>
											<Tab
												className={classes.tabsetting}
												sx={{
													minWidth: 'unset',
													width: '171px',
													marginLeft: '30px',
												}}
												label={
													<div style={{ gap: '5px', display: 'flex' }}>
														<span>Uploaded</span>
														<span>Documents</span>
													</div>
												}
												value='3'
											/>
											<Tab
												className={classes.tabsetting}
												// label="Fraud Report"
												sx={{
													minWidth: 'unset',
													width: '171px',
													marginLeft: '30px',
												}}
												label={
													<div style={{ gap: '4px', display: 'flex' }}>
														<span>Patient% </span>
														<span>Adjustment</span>
													</div>
												}
												value='4'
											/>
										</TabList>
									</Box>
									<TabPanel value='1'>
										<Box
											style={{
												width: '100% ',
												height: '400px ',
												borderRadius: '2px ',
												margin: 'auto ',
												backgroundColor: 'white',
											}}
										>
											<Box sx={{ display: 'flex', padding: '20px' }}>
												<Box sx={{ display: 'flex', gap: '280px' }}>
													<Box
														sx={{ display: 'grid', gap: '0px', width: '50%' }}
													>
														<span className={classes.payReqIno}>
															Appointment ID: {appointment?.id}
														</span>
														<span className={classes.payReqIno}>
															{/* Appointment date / time : {appointment?.date}{" "} */}
															Appointment date / time :{' '}
															{convertToLocalTime(appointment?.date)}{' '}
														</span>
														<span className={classes.payReqIno}>
															Ends: {convertToLocalTime(appointment?.end_date)}
														</span>
														<span className={classes.payReqIno}>
															Phone number: {patient?.phone}
														</span>
														<span className={classes.payReqIno}>
															Location: {provider?.location}
														</span>
														<span className={classes.payReqIno}>
															Date requested:{' '}
															{convertToLocalTime(payments?.bill_added_date)}
														</span>

														<Box style={{ marginTop: '4px' }}>
															<span
																style={{
																	fontSize: '16px',
																	fontWeight: '600',
																	gap: '3px',
																}}
															>
																Status Details:
																<br />
																<span className={classes.payReqIno}>
																	Patient paid date:{' '}
																	{convertToLocalTime(
																		payments?.patient_paid_date
																	)}{' '}
																	<br />
																	Provider upload document date:{' '}
																	{convertToLocalTime(
																		payments?.provider_upload_document_date
																	)}
																	<br />
																	Admin approved date:{' '}
																	{convertToLocalTime(
																		payments?.admin_approved_date
																	)}
																</span>
															</span>
														</Box>
													</Box>

													<Box
														sx={{
															display: 'grid',
															marginTop: '8px',
															gap: '0px',
														}}
													>
														<span
															style={{ fontSize: '14px', fontWeight: '500' }}
														>
															Patient’s name:&nbsp;
															<span
																style={{ color: '#3772FF', fontWeight: '700' }}
															>
																{patient?.name}
															</span>
														</span>
														<span
															style={{ fontSize: '14px', fontWeight: '500' }}
														>
															Patient’s Flag:&nbsp;
															<span
																style={{ color: '#3772FF', fontWeight: '700' }}
															>
																{patient?.flag}
															</span>
														</span>
														<span
															style={{ fontSize: '14px', fontWeight: '500' }}
														>
															Patient’s Wallet:&nbsp;
															<span
																style={{ color: '#3772FF', fontWeight: '700' }}
															>
																{patient?.balance >= 0
																	? `$${patient?.balance}`
																	: `-$${Math.abs(patient?.balance)}`}
															</span>
														</span>
														<span
															style={{ fontSize: '14px', fontWeight: '500' }}
														>
															Provider’s ID: {provider?.id}
														</span>
														<span
															style={{ fontSize: '14px', fontWeight: '700' }}
														>
															Procedure / Services:
														</span>
														<span
															style={{ fontSize: '14px', fontWeight: '500' }}
														>
															Consultation: {consultant?.consultation_name}
														</span>
														<div>
															{services?.map(item => (
																<React.Fragment key={item.id}>
																	<span
																		style={{
																			fontSize: '14px',
																			fontWeight: '500',
																		}}
																	>
																		{item?.service_name}
																	</span>
																	<br />{' '}
																	{/* Add line break after each service_name */}
																</React.Fragment>
															))}
														</div>
													</Box>
												</Box>
											</Box>
										</Box>
									</TabPanel>
									{/*  */}
									<TabPanel value='2'>
										<Box
											style={{
												width: '100% ',
												height: '400px ',
												borderRadius: '2px ',
												margin: 'auto ',
												backgroundColor: 'white',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
											>
												<Box sx={{ margin: '22px', display: 'flex' }}>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															gap: '8px',
														}}
													>
														<span className={classes.usdHeading}>
															Consultation:{' '}
														</span>
														<div>
															{services?.map(item => (
																<React.Fragment key={item.id}>
																	<span className={classes.usdHeading}>
																		{item?.service_name}
																	</span>
																	<br />{' '}
																	{/* Add line break after each service_name */}
																</React.Fragment>
															))}
														</div>
														{patient?.flag == 3 ? (
															<span className={classes.usdHeading}>
																HC contribution <br /> (0%) deductible
															</span>
														) : (
															<span className={classes.usdHeading}>
																HC contribution <br />(
																{payments?.admin_paid_percentage}% deductible)
															</span>
														)}

														<span className={classes.usdHeading}>
															Convenience Fee
														</span>
														<span className={classes.usdHeading}>
															Patient Owes
														</span>
													</Box>

													{/* usd is on right site */}

													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															marginLeft: '100px',
															gap: '8px',
														}}
													>
														<span className={classes.usdHeading}>
															${consultant?.consultation_price} USD
														</span>
														<div>
															{services?.map(item => (
																<React.Fragment key={item.id}>
																	<span className={classes.usdHeading}>
																		${item?.price} USD
																	</span>
																	<br />{' '}
																	{/* Add line break after each service_name */}
																</React.Fragment>
															))}
														</div>
														<Box sx={{ marginTop: '8px' }}>
															{patient?.flag == 3 ? (
																<span className={classes.usdHeading}>
																	$0.00 USD
																</span>
															) : (
																<span className={classes.usdHeading}>
																	${payments?.hc_bill} USD
																</span>
															)}
														</Box>
														<Box
															sx={{
																marginTop: '15px',
																gap: '5px',
																display: 'grid',
															}}
														>
															<span className={classes.usdHeading}>
																${payments?.convenience_fee} USD
															</span>
															{patient?.flag == 3 ? (
																<span className={classes.usdHeading}>
																	${payments?.patient_bill + payments?.hc_bill}{' '}
																	USD
																</span>
															) : (
																<span className={classes.usdHeading}>
																	${payments?.patient_bill} USD
																</span>
															)}
															{/* <span className={classes.usdHeading}>
																${payments?.patient_bill} USD
															</span> */}
														</Box>
													</Box>
												</Box>

												{/* right site */}

												<Box
													sx={{
														margin: '22px',
														marginTop: '40px',
														display: 'flex',
														marginRight: '18rem',
													}}
												>
													<Box
														sx={{ display: 'flex', flexDirection: 'column' }}
													>
														<Box
															sx={{ display: 'flex', flexDirection: 'column' }}
														>
															<span
																style={{ fontSize: '15px', fontWeight: '700' }}
															>
																Paid by Patient:
															</span>
															{payments?.payment_request_status === '2' ||
																payments?.payment_request_status === '3' ||
																payments?.payment_request_status === '4' ? (
																<span
																	style={{ fontSize: '10px', color: '#0cc92c' }}
																>
																	Payment complete
																</span>
															) : (
																<span
																	style={{ fontSize: '10px', color: '#FFA23A' }}
																>
																	Awaiting payment
																</span>
															)}

															{payments?.adjustment_bill !== null && (
																<span
																	style={{
																		fontSize: '15px',
																		fontWeight: '700',
																		marginTop: '5px',
																	}}
																>
																	Paid by Patient(Adjustment):
																</span>
															)}
															{payments?.adjustment_status === 2 &&
																payments?.adjustment_bill !== null ? (
																<span
																	style={{ fontSize: '10px', color: '#0cc92c' }}
																>
																	Payment Complete
																</span>
															) : payments?.adjustment_bill !== null ? (
																<span
																	style={{ fontSize: '10px', color: '#FFA23A' }}
																>
																	Awaiting Payment
																</span>
															) : null}
														</Box>
														<Box
															sx={{
																display: 'flex',
																flexDirection: 'column',
																marginTop: '30px',
															}}
														>
															<span
																style={{ fontSize: '15px', fontWeight: '700' }}
															>
																Total Amount{' '}
															</span>
															<span
																style={{ fontSize: '15px', fontWeight: '700' }}
															>
																to disburse:
															</span>
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															marginLeft: '100px',
														}}
													>
														<Box sx={{ marginTop: '10px' }}>
															{patient?.flag == 3 ? (
																<span
																	style={{
																		fontSize: '14px',
																		fontWeight: '700',
																	}}
																>
																	${payments?.patient_bill + payments?.hc_bill}{' '}
																	USD
																</span>
															) : (
																<span
																	style={{
																		fontSize: '14px',
																		fontWeight: '700',
																	}}
																>
																	${payments?.patient_bill} USD
																</span>
															)}
														</Box>
														<Box sx={{ marginTop: '10px' }}>
															<span
																style={{ fontSize: '14px', fontWeight: '700' }}
															>
																{payments?.adjustment_bill && (
																	<>${payments?.adjustment_bill} USD</>
																)}
															</span>
														</Box>
														<Box sx={{ marginTop: '10px' }}>
															<span
																style={{ fontSize: '14px', fontWeight: '700' }}
															>
																{payments?.adjustment_bill !== null &&
																	payments?.patient_bill !== null && (
																		<span>
																			Total ={' '}
																			{parseFloat(payments?.adjustment_bill) +
																				parseFloat(payments?.patient_bill)}{' '}
																			USD
																		</span>
																	)}
															</span>
														</Box>
														<Box sx={{ marginTop: '50px' }}>
															{patient?.flag == 3 ? (
																<span
																	style={{
																		fontSize: '14px',
																		fontWeight: '700',
																	}}
																>
																	${payments?.patient_bill + payments?.hc_bill}{' '}
																	USD
																</span>
															) : (
																<span
																	style={{
																		fontSize: '14px',
																		fontWeight: '700',
																	}}
																>
																	${payments?.hc_bill} USD
																</span>
															)}
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									</TabPanel>
									<TabPanel value='3'>
										{/* <Box
                      style={{
                        width: "100% ",
                        height: "auto ",
                        borderRadius: "2px ",
                        margin: "auto ",
                        backgroundColor: "white"
                      }}
                    > */}
										<TableContainer
											style={{ margin: 'auto', width: '90%' }}
											sx={{ mt: 2 }}
										>
											<Table>
												<TableHead>
													<TableRow>
														{/* <TableCell style={{ padding: "-0px", margin: "-12px" }}>
                              <Checkbox
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                              />
                            </TableCell> */}

														<TableCell style={root}>
															<span className={classes.headingIcon}>
																FILE NAME
																<KeyboardArrowDownIcon
																	style={{ padding: '2px' }}
																/>
															</span>
														</TableCell>

														<TableCell style={root} align='center'>
															<span className={classes.headingIcon}>
																UPLOADED BY
																<KeyboardArrowDownIcon
																	style={{ padding: '2px' }}
																/>
															</span>
														</TableCell>

														<TableCell style={root} align='center'>
															<span className={classes.headingIcon}>
																ACTION
																<KeyboardArrowDownIcon
																	style={{ padding: '2px' }}
																/>
															</span>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody style={{ background: 'white' }}>
													{image?.map(item => (
														<TableRow>
															{/* <TableCell style={{ padding: '0', margin: '0' }}>
                                <Checkbox
                                  type="checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                />
                              </TableCell> */}
															<TableCell
																align='center'
																className={classes.bodyCell}
																style={{
																	padding: '0px',
																	margin: '0px',
																	opacity: 0.8,
																	fontSize: '10px',
																	fontFamily: 'DM Sans',
																}}
															>
																{item.image.split('/').pop()}
															</TableCell>
															<TableCell
																align='center'
																className={classes.bodyCell}
																style={{
																	padding: '0px',
																	margin: '0px',
																	opacity: 0.8,
																	fontSize: '10px',
																	fontFamily: 'DM Sans',
																}}
															>
																{provider?.name}
															</TableCell>
															<TableCell align='center'>
																<Box sx={{ cursor: 'pointer' }}>
																	{/* <img src={eyeView} alt="" /> */}
																	<a
																		href={
																			'https://api.healthcoinxchangeportal.com/' +
																			item?.image
																		}
																		target='_blank'
																	>
																		<img
																			src={eyeView}
																			alt='eyeView'
																			className={classes.eyes}
																		/>
																	</a>
																</Box>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
										{/* </Box> */}
									</TabPanel>

									<TabPanel value='4'>
										<Box>
											<span style={{ fontSize: '12px', fontWeight: '600' }}>
												Patient % Adjustment
											</span>
										</Box>{' '}
										<br />
										<Box
											style={{
												width: '100% ',
												height: '400px ',
												borderRadius: '2px ',
												margin: 'auto ',
												backgroundColor: 'white',
											}}
										>
											<Box>
												<Box sx={{ display: 'flex', padding: '22px' }}>
													<TextField
														label='Enter Percentage'
														variant='outlined'
														value={inputValue}
														onChange={handleInputChange}
														inputProps={{
															maxLength: 3, // Maximum length of 3 characters
														}}
													/>
													<br />
													<Box style={{ marginLeft: '12px' }}>
														{!shouldShowButton && (
															<LoadingButton
																loading={percentLoading}
																variant='contained'
																onClick={handlePercentageAdjustment}
																style={{
																	marginTop: '10px',
																	borderRadius: '56px',
																	cursor: 'pointer',
																	fontSize: '16px',
																	fontWeight: '700',
																	textTransform: 'none',
																}}
															>
																Percentage Adjustment
															</LoadingButton>
														)}
													</Box>
												</Box>
											</Box>
										</Box>
									</TabPanel>
								</TabContext>
							</Box>
						</Container>
					</div>
				</div>
			)}
		</>
	);
};

export default PaymentRequestDetail;
