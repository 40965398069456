// counterReducer.js

import { createReducer } from '@reduxjs/toolkit';
import { setValue } from '../action/tabAction';

const initialState = {
	value: '1',
};

const tabReducer = createReducer(initialState, builder => {
	builder.addCase(setValue, (state, action) => {
		state.value = action.payload;
	});
});

export default tabReducer;
