import React, { useEffect } from 'react';
// import Logo from './images/Logo.png';
import Logo from '../../asset/image/Logo.svg';
import lock from './images/lock.svg';
import logout from './images/logout.svg';
import Vector from './images/Vector.svg';
import './Profile.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
// import { logoutUser } from '../../redux/action/user';
import { setUser } from '../../redux/reducer/auth.slice';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../redux/store';
import { Toaster } from 'react-hot-toast';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
};
const Profile = ({ handleOpen, handleClose, open }) => {
	const { user } = useSelector(state => state.user);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// console.log("userrrr", user);
	// const RedirectchangePassword =() => {
	//   navigate('/setting')
	// }
	const data = user?.user_type == 'enterprise' ? '/settings' : '/setting';

	const logoutUser = async () => {
		try {
			const logoutToken = `Bearer ${localStorage.getItem('token')}`;
			let { data } = await axios.post(
				`${server}/logout`,
				{},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: logoutToken,
					},
				}
			);
			// toast.success(data.message);
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			window.location.href = `/`;
		} catch (err) {
			// console.log(err?.response);
		}
	};
	return (
		<>
			<React.Fragment>
				<ToastContainer />

				<Modal
					className='modaldesign'
					open={open}
					onClose={handleClose}
					aria-labelledby='child-modal-title'
					aria-describedby='child-modal-description'
				>
					<Box>
						<div className='profile-container' sx={{ ...style, width: 200 }}>
							<div className='firs-site'>
								<div
									style={{
										background: 'black',
										width: '100px',
										height: '100px',
										borderRadius: '50px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									{user?.profile_image ? (
										<img
											style={{
												width: '100%',
												height: '100%',
												objectFit: 'cover',
												borderRadius: '50%',
												cursor: 'pointer',
												overflow: 'hidden',
											}}
											src={
												'https://api.healthcoinxchangeportal.com/' +
												user?.profile_image
											}
											onClick={handleOpen}
											alt='User Profile'
										/>
									) : (
										<img
											style={{
												width: '100%',
												height: '60px',
												objectFit: 'cover',
												borderRadius: '50%',
												cursor: 'pointer',
											}}
											src={Logo}
											onClick={handleOpen}
											alt='User Profile'
										/>
									)}
								</div>

								<div className='admin-site'>
									<span style={{ fontSize: '16px' }}>
										{user?.first_name} {user?.last_name}
									</span>
									<span style={{ fontSize: '14px' }}>{user?.email}</span>
								</div>
							</div>
							<hr
								style={{ background: 'red', width: '100%', marginTop: '14px' }}
							/>
							<div className='setting'>
								<div
									style={{
										display: 'flex',
										gap: '12px',
									}}
									className='account'
								>
									<div className='account-title' onClick={() => navigate(data)}>
										<img src={Vector} alt='' />
										<span className='leftValue'>Account Settings</span>
									</div>
								</div>
								{user?.user_type == 'enterprise' ? (
									''
								) : (
									<div
										style={{
											display: 'flex',
											gap: '12px',
										}}
										className='account'
									>
										<div
											className='account-title'
											onClick={() => navigate(data)}
										>
											<img src={lock} alt='' />
											<span
												className='leftValue'
											// onClick={RedirectchangePassword}
											>
												Change Password
											</span>
										</div>
									</div>
								)}

								<div
									style={{
										display: 'flex',
										gap: '12px',
									}}
									className='Lagout12'
								>
									<div className='lagoutValue' onClick={() => logoutUser()}>
										<img src={logout} alt='' />
										<span
											className='leftValue'
											onClick={() => {
												//  dispatch(logoutUser())
											}}
										>
											Logout
										</span>
									</div>
								</div>
							</div>
						</div>
					</Box>
				</Modal>
				<Toaster />
			</React.Fragment>
		</>
	);
};

export default Profile;
