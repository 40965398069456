import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Divider,
	Input,
	Box,
	TextareaAutosize,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { toast, Toaster } from 'react-hot-toast';

const useStyles = makeStyles({
	buttonAddnewHelp: {
		fontSize: '24px',
		fontWeight: '500',
	},
	sendCredButton: {
		height: '46px',
		width: '190px',
		borderRadius: '24px',
		background: '#4157FF',
	},
	headingSettingTest: {
		fontSize: '24px',
		fontWeight: '500',
	},
});

const TermAndConditions = () => {
	const [open, setOpen] = useState(false);
	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState('md');
	const classes = useStyles();
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// css
	const contactProvider = {
		color: 'black',
		fontWeight: '700',
		fontSize:'16px',
		cursor: 'pointer',
	};
	const containerStyle = {
		fontFamily: 'Arial, sans-serif',
		color: '#000',
		margin: '20px',
	};

	const windowTextStyle = {
		color: 'windowtext',
	};
	const introductionStyle = {
		textAlign: 'left',
		marginTop: '10px',
		marginBottom: '10px',
	};
	const listStyle = {
		listStyleType: 'disc',
		marginLeft: '20px',
	};
	const linkStyle = {
		// background: 'lightgrey',
	};
	const headingStyle = {
		fontSize: '19px',
	};
	const timesNewRomanStyle = {
		fontFamily: 'Times New Roman, serif',
	};
	const paragraphIndentStyle = {
		marginLeft: '0.25in',
	};
	return (
		<>
			<div>
				<p style={contactProvider} onClick={handleOpen}>
					<span style={{fontWeight:'bold'}}>Terms & Conditions apply</span>
				</p>
				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={open}
					onClose={handleClose}
				>
					{' '}
					<DialogTitle className={classes.buttonAddnewHelp}>
						<span className={classes.headingSettingTest}>
							Terms And Conditions
						</span>
						<IconButton
							onClick={handleClose}
							style={{ position: 'absolute', top: 0, right: 0 }}
						>
							<Box
								style={{
									background: '#4157FF',
									width: '42px',
									height: '42px',
									fontSize: '11px  ',
									boxShadow: 'none ',
									borderRadius: '24px',
								}}
							>
								<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
							</Box>
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Box style={containerStyle}>
							<p>
								<strong>
									<u>
										<span style={windowTextStyle}>
											THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU
											MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO
											THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.
										</span>
									</u>
								</strong>
							</p>
							<p style={introductionStyle}>
								<strong>
									<u>
										<span style={{ fontSize: '19px' }}>Introduction</span>
									</u>
								</strong>
							</p>
							<p>
								HealthCoin Exchange Inc., a North Carolina Corporation (
								<strong>Company</strong> or <strong>We</strong>) respect your
								privacy and are committed to protecting it through our
								compliance with this policy. This policy describes:
							</p>
							<ul style={listStyle}>
								<li>
									The types of information we may collect or that you may
									provide when you purchase, download, install, register with,
									access, or use the HealthCoin Exchange Inc. application (the "
									<strong>App</strong>").
								</li>
								<li>
									Our practices for collecting, using, maintaining, protecting,
									and disclosing that information.
								</li>
							</ul>
							{/*  */}
							<p>
								This policy applies only to information we collect in this App,
								and in email, text, via website and other electronic
								communications sent through or in connection with this App.
							</p>
							<p>This policy DOES NOT apply to information that:</p>
							<ul style={listStyle}>
								<li>
									We collect offline or on any other Company apps or websites,
									including websites you may access through this App.
								</li>
								<li>
									You provide to or is collected by any third party (see{' '}
									<a href='#a982672' 
									// style={linkStyle}
									>
										Third-Party Information Collection
									</a>
									).
								</li>
							</ul>
							<p>
								Our websites and apps, and these other third parties may have
								their own privacy policies, which we encourage you to read
								before providing information on or through them.
							</p>
							<p>
								Please read this policy carefully to understand our policies and
								practices regarding your information and how we will treat it.
								If you do not agree with our policies and practices, do not
								download, register with, or use this App. By downloading,
								registering with, or using this App, you agree to this privacy
								policy. This policy may change from time to time (see{' '}
								<a href='#a797574' 
								// style={linkStyle}
								>
									Changes to Our Privacy Policy
								</a>
								). Your continued use of this App after we revise this policy
								means you accept those changes, so please check the policy
								periodically for updates.
							</p>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<u>
										<span style={headingStyle}>
											Children Under the Age of 13
										</span>
									</u>
								</strong>
							</p>
							{/*  */}
							<p>
								The App is not intended for children under 13 years of age, and
								we do not knowingly collect personal information from children
								under 13. If we learn we have collected or received personal
								information from a child under 13 without verification of
								parental consent, we will delete that information. If you
								believe we might have any information from or about a child
								under 13, please contact us at{' '}
								<span>
									[EMAIL]@gethealthcoin.com
								</span>
								.
							</p>
							<p>
								California residents under 16 years of age may have additional
								rights regarding the collection and sale of their personal
								information. Please see{' '}
								<a href='#a820658' 
								style={linkStyle}
								>
									Your State Privacy Rights
								</a>{' '}
								for more information.
							</p>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<u>
										<span style={headingStyle}>
											Information We Collect and How We Collect It
										</span>
									</u>
								</strong>
							</p>
							<p>We collect information from and about users of our App:</p>
							<ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
								<li>Directly from you when you provide it to us.</li>
								<li>Automatically when you use the App.</li>
								<li>
									Through third parties when you engage with our Community
									(collectively any service provider, service, or offering
									available to you within the Healthcoin Exchange, Inc.
									network).
								</li>
							</ul>
							{/*  */}
							<p style={{ textAlign: 'left' }}>
								<strong>
									<em>
										<span style={headingStyle}>
											Information You Provide to Us
										</span>
									</em>
								</strong>
							</p>
							<p>
								When you download, register with, or use this App, we may ask
								you to provide information:
							</p>
							<ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
								<li>
									By which you may be personally identified, such as name,
									postal address, email address, telephone number, social
									security number, or any other identifier by which you may be
									contacted online or offline ("
									<strong>personal information</strong>").
								</li>
								<li>
									That is about you but individually does not identify you.
								</li>
							</ul>
							<p>This information includes:</p>
							<ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
								<li>
									Information that you provide by filling in forms in the App.
									This includes information provided at the time of registering
									to use the App, subscribing to our service, posting material,
									and requesting further services. We may also ask you for
									information when you enter a contest or promotion sponsored by
									us, and when you report a problem with the App.
								</li>
								<li>
									Records and copies of your correspondence (including email
									addresses and phone numbers) if you contact us.
								</li>
								<li>
									Your responses to surveys that we might ask you to complete
									for research purposes.
								</li>
								<li>
									Details of transactions you carry out through the App and of
									the fulfillment of your orders. You may be required to provide
									financial information before placing an order through the App.
								</li>
								<li>Your search queries on the App.</li>
								<li>
									The Providers (defined as Medical Professionals or any other
									offerors of services in our Community) that you select to
									engage with.
								</li>
								<li>The Services that you choose.</li>
							</ul>
							{/*  */}
							<p>
								You may also provide information for publication or display ("
								<strong>Posted</strong>") on public areas of the app or websites
								you access through the App (collectively, "
								<strong>User Contributions</strong>"). Your User Contributions
								are Posted and transmitted to others at your own risk. Although
								you may set certain privacy settings for such information by
								logging into your account profile, please be aware that no
								security measures are perfect or impenetrable. Additionally, we
								cannot control the actions of third parties with whom you may
								choose to share your User Contributions. Therefore, we cannot
								and do not guarantee that your User Contributions will not be
								viewed by unauthorized persons.
							</p>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<em>
										<span style={headingStyle}>
											Automatic Information Collection and Tracking
										</span>
									</em>
								</strong>
							</p>
							<p>
								When you download, access, and use the App, it may use
								technology to automatically collect:
							</p>
							<ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
								<li>
									<strong>Usage Details.</strong> When you access and use the
									App, we may automatically collect certain details of your
									access to and use of the App, including traffic data, location
									data, logs, and other communication data and the resources
									that you access and use on or through the App.
								</li>
								<li>
									<strong>Device Information.</strong> We may collect
									information about your mobile device and internet connection,
									including the device's unique device identifier, IP address,
									operating system, browser type, mobile network information,
									and the device's telephone number.
								</li>
								<li>
									<strong>Stored Information and Files.</strong> The App also
									may access metadata and other information associated with
									other files stored on your device. This may include, for
									example, photographs, audio and video clips, personal
									contacts, and address book information.
								</li>
								<li>
									<strong>Location Information.</strong> This App collects
									real-time information about the location of your device.
								</li>
							</ul>
							{/*  */}
							<p>
								If you do not want us to collect this information, do not
								download the App or delete it from your device.
								<span
									style={{
										fontSize: '11px',
										fontFamily: 'Calibri, sans-serif',
									}}
								>
									<a
										id='_anchor_1'
										href='#_msocom_1'
										language='JavaScript'
										name='_msoanchor_1'
									>
										[SG1]
									</a>
								</span>
								. For more information, see{' '}
								<a href='#a102108' style={linkStyle}>
									Your Choices About Our Collection, Use, and Disclosure of Your
									Information
								</a>
								.
								<span>
									Note, however, that opting out of the App's collection of
									location information will disable its location-based features.
								</span>
							</p>
							<p>
								We also may use these technologies to collect information about
								your activities over time and across third-party websites, apps,
								or other online services (behavioral tracking).
								<span>
									Click here{' '}
									<a
										href='[LINK_TO_DO_NOT_TRACK_DISCLOSURES]'
										style={linkStyle}
									>
										[LINK TO DO NOT TRACK DISCLOSURES]
									</a>{' '}
									for information on how you can opt out of behavioral tracking
									on or through this app and how we respond to browser signals
									and other mechanisms that enable consumers to exercise choice
									about behavioral tracking.
								</span>
							</p>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<em>
										<span style={headingStyle}>
											Information Collection and Tracking Technologies&nbsp;
										</span>
									</em>
								</strong>
							</p>
							<p>
								The technologies we use for automatic information collection may
								include:
							</p>
							<ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
								<li>
									<strong>Cookies (or mobile cookies).</strong> A cookie is a
									small file placed on your smartphone. It may be possible to
									refuse to accept mobile cookies by activating the appropriate
									setting on your smartphone. However, if you select this
									setting you may be unable to access certain parts of our App.
								</li>
								<li>
									<strong>Web Beacons.</strong> Pages of the App and our emails
									may contain small electronic files known as web beacons (also
									referred to as clear gifs, pixel tags, and single-pixel gifs)
									that permit the Company, for example, to count users who have
									visited those pages or opened an email and for other related
									app statistics (for example, recording the popularity of
									certain app content and verifying system and server
									integrity).
									<span style={{ fontFamily: 'Calibri, sans-serif' }}>
									
									</span>
								</li>
							</ul>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<em>
										<span style={headingStyle}>
											Third-Party Information Collection
										</span>
									</em>
								</strong>
							</p>
							<p>
								When you use the App or its content, certain third parties may
								use automatic information collection technologies to collect
								information about you or your device. These third parties may
								include:
							</p>
							{/*  */}
							<ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
								<li>Providers of Services within our Community.</li>
								<li>Advertisers, ad networks, and ad servers.</li>
								<li>Analytics companies.</li>
								<li>Your mobile device manufacturer.</li>
								<li>Your mobile service provider.</li>
							</ul>
							<p>
								These third parties may use tracking technologies to collect
								information about you when you use this app. The information
								they collect may be associated with your personal information or
								they may collect information, including personal information,
								about your online activities over time and across different
								websites, apps, and other online services websites. They may use
								this information to provide you with interest-based (behavioral)
								advertising or other targeted content.
							</p>
							<p>
								We do not control these third parties' tracking technologies or
								how they may be used. If you have any questions about an
								advertisement or other targeted content, you should contact the
								responsible provider directly.
								<span>
									For information about how you can opt out of receiving
									targeted advertising from many providers, see{' '}
									<a href='#a102108'>
										Your Choices About Our Collection, Use, and Disclosure of
										Your Information
									</a>
									.
								</span>
							</p>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<u>
										<span style={headingStyle}>
											How We Use Your Information
										</span>
									</u>
								</strong>
							</p>
							{/*  */}
							<p>
								We use information that we collect about you or that you provide
								to us, including any personal information, to:
							</p>
							<ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
								<li>
									Provide you with the App and its contents, and any other
									information, products, suggestions, or Services that you may
									or may not request from us.
								</li>
								<li>Fulfill any other purpose for which you provide it.</li>
								<li>
									Give you notices about your account, including expiration and
									renewal notices.
								</li>
								<li>
									Carry out our obligations and enforce our rights arising from
									any contracts entered into between you and us or you and a
									Provider, including for billing and collection.
								</li>
								<li>
									Notify you when App updates are available, and of changes to
									any products or services we offer or provide though it.
								</li>
							</ul>
							<p>
								The usage information we collect helps us to improve our App and
								to deliver a better and more personalized experience by enabling
								us to:
							</p>
							<ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
								<li>Estimate our audience size and usage patterns.</li>
								<li>
									Store information about your preferences, allowing us to
									customize our App according to your individual interests.
								</li>
								<li>Speed up your searches.</li>
								<li>Recognize you when you use the App.</li>
							</ul>
							<p>
								We use location information we collect to recommend Services or
								Providers based on the information collected.
							</p>
							{/*  */}
							<p>
								We may also use your information to contact you about our own
								and third parties' goods and Services that may be of interest to
								you. If you do not want us to use your information in this way,
								please advise us in writing of your decision.
								
								For more information, see{' '}
								<a href='#a102108' 
								// style={linkStyle}
								>
									Your Choices About Our Collection, Use, and Disclosure of Your
									Information
								</a>
								.
							</p>
							<p>
								We may use the information we collect to display advertisements
								to our advertisers' target audiences. Even though we do not
								disclose your personal information for these purposes without
								your consent, if you click on or otherwise interact with an
								advertisement, the advertiser may assume that you meet its
								target criteria.
							</p>
							<p style={{ marginLeft: '0in', textIndent: '0in' }}>
								<strong>
									<u>
										<span style={headingStyle}>Our Responsibility</span>
									</u>
								</strong>
							</p>
							<ul
								style={{
									listStyleType: 'disc',
									marginLeft: '20px',
									...timesNewRomanStyle,
								}}
							>
								<li>
									We are required by law to maintain the privacy and security of
									your protected health information.
								</li>
								<li>
									We will let you know promptly if a breach occurs that may have
									compromised the privacy or security of your information.
								</li>
								<li>
									We must follow the duties and privacy practices described in
									this policy and give you a copy of it.
								</li>
								<li>
									We will not use or share your information other than as
									described herein unless you tell us we can in writing. If you
									tell us we can, you have the ability to change your mind by
									stating your decision in writing.
								</li>
							</ul>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<u>
										<span style={headingStyle}>
											Disclosure of Your Information
										</span>
									</u>
								</strong>
							</p>
							<p>
								We may disclose aggregated information about our users, and
								information that does not identify any individual or device,
								without restriction.
							</p>
							<p>
								In addition, we may disclose personal non-health information
								that we collect or you provide:
							</p>
							<ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
								<li>To our subsidiaries and affiliates.</li>
								<li>
									To contractors, service providers, and other third parties we
									use to support our business and who are bound by contractual
									obligations to keep personal information confidential and use
									it only for the purposes for which we disclose it to them.
								</li>
								<li>
									To a buyer or other successor in the event of a merger,
									divestiture, restructuring, reorganization, dissolution, or
									other sale or transfer of some or all of the Healthcoin
									Exchange, Inc.'s assets, whether as a going concern or as part
									of bankruptcy, liquidation, or similar proceeding, in which
									personal information held by Healthcoin Exchange, Inc. about
									our App users is among the assets transferred.
								</li>
								<li>
									To third parties to market their products or services to you
									if you have not opted out of these disclosures. We
									contractually require these third parties to keep personal
									information confidential and use it only for the purposes for
									which we disclose it to them. For more information, see{' '}
									<a href='#a102108' style={linkStyle}>
										Your Choices About Our Collection, Use, and Disclosure of
										Your Information
									</a>
									.
								</li>
								<li>
									To fulfill the purpose for which you provide it. For example,
									if you give us an email address to use the "email a friend"
									feature of our website or App, we will transmit the contents
									of that email and your email address to the recipients.
								</li>
								<li>
									For any other purpose disclosed by us when you provide the
									information.
								</li>
								<li>With your consent.</li>
								<li>
									To comply with any court order, law, or legal process,
									including to respond to any government or regulatory request.
								</li>
								<li>
									To enforce our rights arising from any contracts entered into
									between you and us, including the App EULA, terms of sale and
									for billing and collection.
								</li>
								<li>
									If we believe disclosure is necessary or appropriate to
									protect the rights, property, or safety of Healthcoin
									Exchange, Inc., our customers, or others. This includes
									exchanging information with other companies and organizations
									for the purposes of fraud protection and credit risk
									reduction.
								</li>
							</ul>
							{/*  */}
							<p style={{ textAlign: 'left' }}>
								<strong>
									<u>
										<span style={headingStyle}>
											Your Choices About Our Collection, Use, and Disclosure of
											Your Information
										</span>
									</u>
								</strong>
							</p>
							<p>
								We strive to provide you with choices regarding the personal
								information you provide to us. This section describes mechanisms
								we provide for you to control certain uses and disclosures of
								your information.
								<span
									style={{
										fontSize: '11px',
										fontFamily: 'Calibri, sans-serif',
									}}
								>
									<a
										id='_anchor_4'
										href='#_msocom_4'
										language='JavaScript'
										name='_msoanchor_4'
									>
										[SG4]
									</a>
								</span>
							</p>
							<ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
								<li>
									<strong>Tracking Technologies.</strong> You can set your
									browser to refuse all or some browser cookies, or to alert you
									when cookies are being sent. You can choose whether or not to
									allow the App to collect information through other tracking
									technologies utilizing the security settings on your device.
									If you disable or refuse cookies or block the use of other
									tracking technologies, some parts of the App may then be
									inaccessible or not function properly.
								</li>
								<li>
									<strong>Location Information.</strong> You can choose whether
									or not to allow the App to collect and use real-time
									information about your device's location through the device's
									privacy settings. If you block the use of location
									information, some parts of the App may become inaccessible or
									not function properly.
								</li>
								<li>
									<strong>Promotion by the Company.</strong> If you do not want
									us to use your email address to promote our own or third
									parties' products or services,
									<span>
										you can always opt-out by logging into the App and adjusting
										your user preferences in your account profile by checking or
										unchecking the relevant boxes or by sending us an email
									</span>
									stating your request to
									<span>
										[EMAIL]@gethealthcoin.com.
									</span>
								</li>
								<li>
									<strong>Targeted Advertising by the Company.</strong> If you
									do not want us to use information that we collect or that you
									provide to us to deliver advertisements according to our
									advertisers' target-audience preferences, you can opt-out by
									<span >
										adjusting your user advertising preferences in your account
										profile
									</span>
									by checking or unchecking the relevant boxes or by sending us
									an email stating your request
									<span>
										to [EMAIL]@gethealthcoin.com.
									</span>
								</li>
								<li>
									<strong>
										Disclosure of Your Information for Third-Party Advertising
										and Marketing.
									</strong>{' '}
									If you do not want us to share your personal information with
									unaffiliated or non-agent third parties for advertising and
									marketing purposes, you can opt-out by
									<span>
										adjusting your user advertising preferences in your account
										profile
									</span>
									by checking or unchecking the relevant boxes or by sending us
									an email stating your request
									<span >
										to [EMAIL]@gethealthcoin.com.
									</span>
								</li>
								<li>
									<strong>
										For certain health information, you can tell us your choices
										about what we share.&nbsp;
									</strong>
									If you have a clear preference for how we share your
									information, let us know in writing and we will follow your
									instructions.
									<ul style={{ listStyleType: 'disc', marginLeft: '0px' }}>
										<li>
											In these cases, you have both the right and choice to tell
											us to: share information with your family, close friends,
											or others involved in your care, share information in a
											disaster relief situation, include your information in a
											hospital directory.
										</li>
									</ul>
								</li>
							</ul>
							{/*  */}
							<p style={paragraphIndentStyle}>
								<em>
									<span
										style={{
											fontSize: '16px',
											fontFamily: 'Times New Roman, serif',
											color: 'black',
										}}
									>
										If you are not able to tell us your preference, for example,
										if you are unconscious, we may go ahead and share your
										information if we believe it is in your best interest. We
										may also share your information when needed to lessen a
										serious and imminent threat to health or safety.
									</span>
								</em>
							</p>
							<p>
								We do not control third parties' collection or use of your
								information to serve interest-based advertising. However, these
								third parties may provide you with ways to choose not to have
								your information collected or used in this way. You can opt out
								of receiving targeted ads from members of the Network
								Advertising Initiative ("<strong>NAI</strong>") on the NAI's
								website.
							</p>
							<p>
								Residents in certain states, such as California, may have
								additional personal information rights and choices. Please see
								<a href='#a820658'>
									<span>
										Your State Privacy Rights
									</span>
								</a>
								for more information.
							</p>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<u>
										<span style={headingStyle}>
											Accessing and Correcting Your Personal Information
										</span>
									</u>
								</strong>
							</p>
							<p>
								You can review and change your personal information by logging
								into the App and visiting your account profile page.
							</p>
							<p>
								You may also send us an email at
								<span>
									[EMAIL]@gethealthcoin.com
								</span>
								to request access to, correct, or delete any personal
								information that you have provided to us. We cannot delete your
								personal information except by also deleting your user account.
								We may not accommodate a request to change information if we
								believe the change would violate any law or legal requirement or
								cause the information to be incorrect.
							</p>
							<p>
								If you delete your User Contributions from the App, copies of
								your User Contributions may remain viewable in cached and
								archived pages or might have been copied or stored by other App
								users. Proper access and use of information provided on the App,
								including User Contributions, is governed by our terms of use.
								To request a copy of our terms of use, please visit
								<span>
									[LINK] or send an email to [EMAIL]@gethealthcoin.com.
								</span>
							</p>
							<p>
								Residents in certain states, such as California, may have
								additional personal information rights and choices. Please see
								<a href='#a820658'>
									<span >
										Your State Privacy Rights
									</span>
								</a>
								for more information.
							</p>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<u>
										<span style={headingStyle}>Your State Privacy Rights</span>
									</u>
								</strong>
							</p>
							{/*  */}
							<p>
								State consumer privacy laws may provide their residents with
								additional rights regarding our use of their personal
								information. To learn more about California residents' privacy
								rights, visit &nbsp; 
								<a
									href='https://oag.ca.gov/privacy/ccpa'
									target='_blank'
									rel='noopener noreferrer'
								>
									the California Consumer Privacy Act
								</a>
								. California's "Shine the Light" law (Civil Code Section §
								1798.83) permits users of our App that are California residents
								to request certain information regarding our disclosure of
								personal information to third parties for their direct marketing
								purposes. To make such a request, please send an email to
								<span>
									[EMAIL]@gethealthcoin.com
								</span>
								or write us at: 257 Church St N, Concord, NC 28025.
							</p>
							<p>
								To learn more about consumer privacy rights in other states and
								how to exercise them, visit
								<span>
									[WEBSITE LINK TO STATE PRIVACY NOTICES].
								</span>
							</p>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<u>
										<span style={headingStyle}>Data Security</span>
									</u>
								</strong>
							</p>
							<p>
								We have implemented measures designed to secure your personal
								information from accidental loss and from unauthorized access,
								use, alteration, and disclosure. All information you provide to
								us is stored on our secure servers behind firewalls. Any payment
								transactions and the transmission of personal information will
								be encrypted.
							</p>
							<p>
								The safety and security of your information also depends on you.
								Where we have given you (or where you have chosen) a password
								for access to certain parts of our App, you are responsible for
								keeping this password confidential. We ask you not to share your
								password with anyone. We urge you to be careful about giving out
								information in public areas of the App like message boards or
								other communications made within the Community. The information
								you share in public areas may be viewed by any user of the App.
							</p>
							<p>
								Unfortunately, the transmission of information via the internet
								and mobile platforms is not completely secure. Although we do
								our best to protect your personal information, we cannot
								guarantee the security of your personal information transmitted
								through our App. Any transmission of personal information is at
								your own risk. We are not responsible for circumvention of any
								privacy settings or security measures we provide.
							</p>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<u>
										<span style={headingStyle}>
											Changes to Our Privacy Policy
										</span>
									</u>
								</strong>
							</p>
							<p>
								We may update our privacy policy from time to time. If we make
								material changes to how we treat our users' personal
								information, we will post the new privacy policy on this page
								with a notice that the privacy policy has been updated and
								notify you by email to the primary email address specified in
								your account and
								<span>
									an in-App alert the first time you use the App after we make
									the change.
								</span>
							</p>
							<p>
								The date the privacy policy was last revised is identified at
								the top of the page. You are responsible for ensuring we have an
								up-to-date active and deliverable email address for you and for
								periodically visiting this privacy policy to check for any
								changes.
							</p>
							<p style={{ textAlign: 'left' }}>
								<strong>
									<u>
										<span style={headingStyle}>Contact Information</span>
									</u>
								</strong>
							</p>
							<p>
								To ask questions, comment about this privacy policy and our
								privacy practices or register a complaint or concern, contact us
								at:
							</p>
							<p>
								<span>
									[EMAIL]@gethealthcoin.com
								</span>
							</p>
						</Box>
					</DialogContent>
				</Dialog>

				<Toaster />
			</div>
		</>
	);
};

export default TermAndConditions;
