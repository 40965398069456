import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import avatar from '../../asset/image/avatar.svg';
import Search from '../../asset/image/search.svg';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { FaCheck, FaTimes } from 'react-icons/fa';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../Table/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import {
	getAllOutOfNetworkCase,
	OutOfNetWorkProvider,
} from '../../redux/action/user';
import { setValue } from '../../redux/action/tabAction';
import moment from 'moment';
// Css
const useStyles = makeStyles({
	root: {
		fontSize: '12px !important',
		fontWeight: 'bold !important',
		textAlign: 'center !important',
	},
	eyes: {
		cursor: 'pointer',
	},
	tabsetting: {
		fontWeight: '600 ',
		fontSize: '16px ',
		textTransform: 'none ',
		color: '#949494 !important',
		'&:focus': {
			color: '#000000 !important',
		},
		'&:active': {
			color: '#000000 !important',
		},
	},
});

const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
const NetworkTable = () => {
	const value = useSelector(state => state.counter.value);
	const { getAllOutOfNetworkRecord } = useSelector(state => state.user);
	const { getAllNetworkProvider } = useSelector(state => state.user);
	const formatName = patient_name => {
		if (patient_name?.length > 15) {
			return patient_name.replace(/(.{15})/g, '$1\n');
		}
		return patient_name;
	};
	const navigate = useNavigate();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const [query, setQuery] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [arrayState, setArrayState] = useState([]);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [currentstatus, setCurrentstatus] = useState(false);
	//   const { allEmployes, employeePage } = useSelector((state) => state.user);

	useEffect(() => { });

	const allEmployes = [
		{
			patient_name: 'John Doe',
			email: 'john.doe@example.com',
			doctor_name: 'Dr. Jane Smith',
			healthcoin_id: 'HC123456',
			date: 'November 30th, 2023',
		},
		{
			patient_name: 'Alice Johnson',
			email: 'alice.johnson@example.com',
			doctor_name: 'Dr. Michael Brown',
			healthcoin_id: 'HC789012',
			date: 'November 30th, 2023',
		},
		{
			patient_name: 'Ethan Williams',
			email: 'ethan.williams@example.com',
			doctor_name: 'Dr. Emily Davis',
			healthcoin_id: 'HC345678',
			date: 'November 30th, 2023',
		},
	];

	// Search

	const handleSearch = event => {
		const query = event.target.value;
		setQuery(query);
		setCurrentPage(1);
	};
	// const dispatch=useDispatch()
	useEffect(() => {
		dispatch(getAllOutOfNetworkCase(query));
	}, [dispatch, query]);
	useEffect(() => {
		dispatch(OutOfNetWorkProvider(query));
	}, [dispatch, query]);

	const activebuttonStyle = {
		backgroundColor: '#4CD964',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};
	const deActiveButtonStyle = {
		backgroundColor: 'rgb(232, 80, 91)',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};

	const handleClickNew = () => {
		navigate('/AddServices');
	};
	// Checkbox
	const handleSelectAll = e => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(allEmployes.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, "checkall")
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};
	const handleChange = (event, newValue) => {
		dispatch(setValue(newValue));
	};
	return (
		<>
			<div className='home'>
				<div>
					<Sidebar showSidebar={false} showHelpButton={false} />
				</div>
				<div className='homeContainer'>
					<Header showHeader={true} />
					<Container
						style={{ position: 'relative', top: '78px', background: '#F8F9FE' }}
						maxWidth='xl'
					>
						<Grid container Spacing={2}>
							<Box sx={{ width: '100%', typography: 'body1' }}>
								<TabContext sx={{ mt: 2 }} value={value}>
									<Box>
										<TabList
											onChange={handleChange}
											aria-label='lab API tabs example'
											sx={{ marginLeft: '30px', marginTop: '22px' }}
										>
											<Tab
												className={classes.tabsetting}
												// label="Payment Details"
												sx={{
													minWidth: 'unset',
													width: '165px',
													marginLeft: '30px',
													'&.Mui-selected': {
														color: '#000000 !important',
													},
												}}
												label='Out of Providers'
												value='1'
											/>
											<Tab
												sx={{
													minWidth: 'unset',
													width: '129px',
													'&.Mui-selected': {
														color: '#000000 !important',
													},
												}}
												className={classes.tabsetting}
												label='Out of Case'
												value='2'
											/>
										</TabList>
									</Box>

									<TabPanel value='1'>
										<Grid container spacing={2}>
											<Grid xs={6} md={10}>
												<TextField
													id='outlined-start-adornment'
													placeholder='Search'
													value={query}
													onChange={handleSearch}
													sx={{ m: 1, width: '30ch' }}
													style={{ marginLeft: '15px' }}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<img src={Search} alt='search' />
															</InputAdornment>
														),
													}}
												/>
											</Grid>
											<Grid sx={{ mt: 2, ml: 0 }} md={2}>
												<Button
													onClick={handleClickNew}
													size='large'
													style={{
														width: '120px',
														height: '46px',
														textTransform: 'none',
														backgroundColor: '#4157FF',
														float: 'right',
														fontWeight: 700,
														borderRadius: '24px',
														color: 'white',
													}}
												>
													+ Add New
												</Button>
											</Grid>
										</Grid>
										<TableContainer component={Paper} sx={{ mt: 2 }}>
											<Table sx={{ minWidth: 650 }} aria-label='simple table'>
												<TableHead>
													<TableRow
														sx={{
															background: '#F8F9FA !important',
															border: 'none',
														}}
													>
														{/* <TableCell>
                      <Checkbox
                        {...label1}
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </TableCell> */}
														<TableCell></TableCell>
														<TableCell className={classes.root}>
															PROVIDER'S NAME{' '}
															<KeyboardArrowDownIcon
																sx={{ paddingTop: '10px' }}
															/>
														</TableCell>
														<TableCell className={classes.root} align='right'>
															EMAIL{' '}
															<KeyboardArrowDownIcon
																sx={{ paddingTop: '10px' }}
															/>
														</TableCell>
														<TableCell className={classes.root} align='right'>
															HEALTHCOIN ID{' '}
															<KeyboardArrowDownIcon
																sx={{ paddingTop: '10px' }}
															/>
														</TableCell>
														<TableCell className={classes.root} align='right'>
															DATE SUBMITTED{' '}
															<KeyboardArrowDownIcon
																sx={{ paddingTop: '10px' }}
															/>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody sx={{ background: 'white' }}>
													{getAllNetworkProvider?.length === 0 ? (
														<TableRow>
															<TableCell
																colSpan={9}
																align='center'
																style={{
																	fontWeight: 'bold',
																	background: '#f8f9fe',
																}}
															>
																No Data Found
															</TableCell>
														</TableRow>
													) : (
														getAllNetworkProvider?.map((row, index, id) => {
															const dateString = row?.created_at;

															// Convert the date string to a Moment object
															const dateMoment = moment(
																dateString,
																'YYYY-MM-DD HH:mm:ss'
															);

															// Format the date as "MMMM Do, YYYY"
															const formattedDate =
																dateMoment.format('MMMM Do, YYYY');
															return (
																<TableRow
																	// key={row.name}
																	sx={{
																		'&:last-child td, &:last-child th': {
																			border: 0,
																		},
																	}}
																>
																	{/* <TableCell>
                          <Checkbox
                            {...label1}
                            key={row.id}
                            type="checkbox"
                            // name={name}
                            id={row.id}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(row?.id)}
                          />
                        </TableCell> */}

																	<TableCell
																		align='center'
																		onClick={() =>
																			navigate(
																				`/NetworkProviderDetails/${row?.id}`
																			)
																		}
																		style={{ cursor: 'pointer' }}
																	>
																		<img
																			src={
																				row.profile_image
																					? 'https://api.healthcoinxchangeportal.com/' +
																					row.profile_image
																					: avatar
																			}
																			alt='avatar'
																			style={{
																				borderRadius: '22px',
																				width: '39px',
																				height: '39px',
																			}}
																		/>
																	</TableCell>
																	<TableCell
																		component='th'
																		scope='row'
																		// align="center"
																		onClick={() =>
																			navigate(
																				`/NetworkProviderDetails/${row?.id}`
																			)
																		}
																		style={{ cursor: 'pointer' }}
																	>
																		{/* <span className={classes.bold}>{row.company}</span> */}
																		<span
																			key={row.patient_name}
																			style={{
																				fontWeight: 'bold',
																				color: '#3772FF',
																				whiteSpace: 'pre-line',
																				wordBreak: 'break-word',
																			}}
																		>
																			{formatName(row?.name)}
																		</span>
																		<br />
																		<span className='textcolorchange'>
																			HC ID:{row?.healthcoin_id}
																		</span>
																	</TableCell>
																	<TableCell
																		align='center'
																		className='textcolorchange'
																		style={{ cursor: 'pointer' }}
																		onClick={() =>
																			navigate(
																				`/NetworkProviderDetails/${row?.id}`
																			)
																		}
																	>
																		{row?.email}
																	</TableCell>
																	<TableCell
																		align='center'
																		className='textcolorchange'
																		style={{ cursor: 'pointer' }}
																		onClick={() =>
																			navigate(
																				`/NetworkProviderDetails/${row?.id}`
																			)
																		}
																	>
																		{row?.healthcoin_id}
																	</TableCell>
																	<TableCell
																		align='center'
																		className='textcolorchange'
																		style={{ cursor: 'pointer' }}
																		onClick={() =>
																			navigate(
																				`/NetworkProviderDetails/${row?.id}`
																			)
																		}
																	>
																		{formattedDate}
																	</TableCell>
																</TableRow>
															);
														})
													)}
												</TableBody>
											</Table>
										</TableContainer>
									</TabPanel>
									<TabPanel value='2'>
										<Grid container spacing={2}>
											<Grid xs={6} md={10}>
												<TextField
													id='outlined-start-adornment'
													placeholder='Search'
													value={query}
													onChange={handleSearch}
													sx={{ m: 1, width: '30ch' }}
													style={{ marginLeft: '15px' }}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<img src={Search} alt='search' />
															</InputAdornment>
														),
													}}
												/>
											</Grid>
										</Grid>
										<TableContainer component={Paper} sx={{ mt: 2 }}>
											<Table sx={{ minWidth: 650 }} aria-label='simple table'>
												<TableHead>
													<TableRow
														sx={{
															background: '#F8F9FA !important',
															border: 'none',
														}}
													>
														{/* <TableCell>
                      <Checkbox
                        {...label1}
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </TableCell> */}
														<TableCell></TableCell>
														<TableCell className={classes.root}>
															PATIENT'S NAME{' '}
															<KeyboardArrowDownIcon
																sx={{ paddingTop: '10px' }}
															/>
														</TableCell>
														<TableCell className={classes.root} align='right'>
															HOSPITAL{' '}
															<KeyboardArrowDownIcon
																sx={{ paddingTop: '10px' }}
															/>
														</TableCell>
														<TableCell className={classes.root} align='right'>
															HEALTHCOIN ID{' '}
															<KeyboardArrowDownIcon
																sx={{ paddingTop: '10px' }}
															/>
														</TableCell>
														<TableCell className={classes.root} align='right'>
															DATE SUBMITTED{' '}
															<KeyboardArrowDownIcon
																sx={{ paddingTop: '10px' }}
															/>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody sx={{ background: 'white' }}>
													{getAllOutOfNetworkRecord?.length === 0 ? (
														<TableRow>
															<TableCell
																colSpan={9}
																align='center'
																style={{
																	fontWeight: 'bold',
																	background: '#f8f9fe',
																}}
															>
																No Data Found
															</TableCell>
														</TableRow>
													) : (
														// getAllOutOfNetworkRecord
														getAllOutOfNetworkRecord?.map((row, index, id) => {
															const dateString = row?.submitted_date;

															// Convert the date string to a Moment object
															const dateMoment = moment(
																dateString,
																'YYYY-MM-DD HH:mm:ss'
															);

															// Format the date as "MMMM Do, YYYY"
															const formattedDate =
																dateMoment.format('MMMM Do, YYYY');
															return (
																<TableRow
																	// key={row.name}
																	sx={{
																		'&:last-child td, &:last-child th': {
																			border: 0,
																		},
																	}}
																>
																	{/* <TableCell>
                          <Checkbox
                            {...label1}
                            key={row.id}
                            type="checkbox"
                            // name={name}
                            id={row.id}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(row?.id)}
                          />
                        </TableCell> */}

																	<TableCell
																		align='center'
																		onClick={() =>
																			navigate(
																				`/NetworkTableDetails/${row?.id}`
																			)
																		}
																		style={{ cursor: 'pointer' }}
																	>
																		<img
																			src={
																				row.profile_image
																					? 'https://api.healthcoinxchangeportal.com/' +
																					row?.patient?.profile_image
																					: avatar
																			}
																			alt=''
																			style={{
																				borderRadius: '22px',
																				width: '39px',
																				height: '39px',
																			}}
																		/>
																	</TableCell>
																	<TableCell
																		component='th'
																		scope='row'
																		// align="center"
																		onClick={() =>
																			navigate(
																				`/NetworkTableDetails/${row?.id}`
																			)
																		}
																		style={{ cursor: 'pointer' }}
																	>
																		{/* <span className={classes.bold}>{row.company}</span> */}
																		<span
																			key={row.patient_name}
																			style={{
																				fontWeight: 'bold',
																				color: '#3772FF',
																				whiteSpace: 'pre-line',
																				wordBreak: 'break-word',
																			}}
																		>
																			{formatName(row?.patient?.name)}
																		</span>
																		<br />
																		<span className='textcolorchange'>
																			{row?.patient?.email}
																		</span>
																	</TableCell>
																	<TableCell
																		align='center'
																		className='textcolorchange'
																		style={{ cursor: 'pointer' }}
																		onClick={() =>
																			navigate(
																				`/NetworkTableDetails/${row?.id}`
																			)
																		}
																	>
																		{row?.provider?.name}
																	</TableCell>
																	<TableCell
																		align='center'
																		className='textcolorchange'
																		style={{ cursor: 'pointer' }}
																		onClick={() =>
																			navigate(
																				`/NetworkTableDetails/${row?.id}`
																			)
																		}
																	>
																		{row?.patient?.healthcoin_id}
																	</TableCell>
																	<TableCell
																		align='center'
																		className='textcolorchang'
																		style={{ cursor: 'pointer' }}
																		onClick={() =>
																			navigate(
																				`/NetworkTableDetails/${row?.id}`
																			)
																		}
																	>
																		{formattedDate}
																	</TableCell>
																</TableRow>
															);
														})
													)}
												</TableBody>
											</Table>
										</TableContainer>
										{/* <Box
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, page) => setCurrentPage(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Grid>
            </Box>{" "} */}
									</TabPanel>
								</TabContext>
							</Box>
						</Grid>
					</Container>
				</div>
			</div>
		</>
	);
};
export default NetworkTable;
