import './VerifyEmploye.css';
import healthcoinlogo from '../../../asset/image/employerlogo.png';
import bg from '../../../asset/image/bg.svg';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Toaster } from "react-hot-toast";
import VerificationInput from 'react-verification-input';
import { resendVerifyCode, verifyCode } from '../../../redux/action/user';
import Sidebar from '../../Layout/Sidebar/Sidebar';
import { Box, Card, CardContent } from '@mui/material';
import axios from 'axios';
import { server } from '../../../redux/store';
import { makeStyles } from '@mui/styles';

// import { logoutUser } from "../../../redux/action/user";
import {
	encryptData,
	decryptData,
} from '../../../redux/incript&decript/cryptoUtils';

const useStyles = makeStyles(theme => ({

	containerOfVerifyEmployer:{
		backgroundImage: `url(${bg})`,
		backgroundSize: 'cover', 
		backgroundPosition: 'center', 
		backgroundRepeat: 'no-repeat', 
		width: '100%', 
		height: '100vh',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: -1, 

	[theme.breakpoints.between(1090,1890)]:{
			height: '1100px', 
		}
	},
	cardOfVerifyEmployer:{
		width: 445,
		height: 'auto',
		borderRadius: '20px !important',

		[theme.breakpoints.down('sm')]:{
			width:340,
		}
	}
}));
const VerifyEmployer = ({ setUpdate, update }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector(state => state.user);
	const inputRef = useRef();
	const [timeLeft, setTimeLeft] = useState(60);
	const [users, setUsers] = useState(user);

	const getUserData = async () => {
		const token = `Bearer ${localStorage.getItem('token')}`;

		const { data } = await axios.get(`${server}/profile`, {
			headers: { Authorization: token, 'Content-Type': 'application/json' },
		});
		const decryptedData = decryptData(data.encrypted_data);
		// console.log(data?.data);
		setUsers(decryptedData?.data.user);
	};

	useEffect(() => {
		getUserData();
	}, []);

	useEffect(() => {
		if (!timeLeft) return;

		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [timeLeft]);

	const submit = (e, email) => {
		e.preventDefault();
		const code = inputRef?.current.value;
		// console.log('-pppppppppppppppppppppp', user);

		// setUpdate(!update);
		// if (code.length < 4) {
		// 	toast.error('code not valid');
		// }

		dispatch(
			verifyCode(user?.email, code, (error, message) => {
				if (error) {
					console.error(error);
				} else {
					// console.log(message);
					setUpdate(!update);
				}
			})
		);
		// verifyEmloyeEmail(user?.email, code)
	};

	const resendCode = email => {
		dispatch(resendVerifyCode(users?.email));
	};
	const logoutUser = async () => {
		try {
			const logoutToken = `Bearer ${localStorage.getItem('token')}`;
			let { data } = await axios.post(
				`${server}/logout`,
				{},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: logoutToken,
					},
				}
			);
			// toast.success(data.message);
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			window.location.href = `/`;
		} catch (err) {
			// console.log(err?.response);
		}
	};
	return (
		<>
			<Box
			className={classes.containerOfVerifyEmployer}
			>
				<Box style={{position:'relative'}}>
				<Box style={{display:'flex',justifyContent:'center',alignContent:'center',marginTop:'70px'}}>
					<Card
						className={classes.cardOfVerifyEmployer}
					>
						<CardContent>
							<Box>
								<Box
									sx={{
										display: 'flex',
										gap: '52px',
										flexDirection: 'column',
										justifyContent: 'center',
										alignContent: 'center',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											textAlign: 'center',
										}}
									>
										<img
											style={{ width: '253px' }}
											src={healthcoinlogo}
											alt='healthcoinlogo'
											width='273px'
										/>
									</Box>

									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignContent: 'center',
											flexDirection: 'column',
											gap: '8px',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											<span
												style={{
													textAlign: 'center',
													fontSize: '20px',
													fontWeight: '600',
												}}
											>
												Enter Verification Code
											</span>
										</Box>

										<Box
											sx={{
												display: 'flex',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											<span
												style={{
													textAlign: 'center',
													fontSize: '15px',
													color: 'grey',
												}}
											>
												Please enter the verification code
												{/* to reset <br /> your password */}
											</span>
										</Box>
									</Box>
								</Box>

								<Box
									sx={{
										display: 'grid',
										gap: '20px',
										flexDirection: 'column',
										justifyContent: 'center',
										marginTop: '40px',
										textAlign: 'center',
									}}
								>
									<Box>
										<VerificationInput
											classNames={{
												character: 'character',
												characterInactive: 'character--inactive',
												characterSelected: 'character--selected',
											}}
											length={4}
											ref={inputRef}
										/>
									</Box>
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									{timeLeft !== 0 ?(
										<span style={{ color: 'grey' }}>
											The verify code will expire in {timeLeft}
										</span>
										):(
										
										
											<span
												style={{
													textAlign: 'center',
													color: '#3772FF',
													cursor: 'pointer',
												}}
												onClick={() => resendCode(user?.email)}
											>
												Resend Code
											</span>
										)}
									</Box>

									{/* <Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<span
											style={{
												textAlign: 'center',
												color: '#3772FF',
												cursor: 'pointer',
											}}
											onClick={() => logoutUser()}
										>
											Logout
										</span>
									</Box> */}
								</Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										textAlign: 'center',
										marginTop: '18px',
									}}
								>
									<button
										style={{
											borderRadius: '40px',
											padding: '20px',
											width: '315.38px',
											height: ' 50px',
											color: 'white',
											fontSize: '15px',
											fontWeight: 700,
											backgroundColor: ' #4157ff',
											border: 'none',
											cursor: 'pointer',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
										className={`emp_btn12 ${timeLeft < 1 && 'disabled'}`}
										onClick={e => submit(e, user?.email)}
									>
										SUBMIT CODE
									</button>
								</Box>
							</Box>
						</CardContent>
					</Card>
				</Box>
				</Box>
			</Box>
			{/* <DeactiveModal open={open} setOpen={setOpen} /> */}
		</>
	);
};

export default VerifyEmployer;
