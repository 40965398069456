import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Divider,
	Box,
} from '@mui/material';
import { toast, Toaster } from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import PayConf from '../Payment Confirmed/PayConf';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../../../redux/store';

const useStyles = makeStyles({
	// buttonAddnew: {
	//   fontSize: "24px !important",
	//   fontWeight: "500 !important",
	//   fontFamily: 'DM Sans',
	//   color: '#32325D'
	// },
});

const MakePayment = ({
	total_per_employee_amount,
	total_due,
	handleOpen,
	handleClose,
	show,
	handlePaymentCOnfirmedOpen,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// modal open

	const [openPayConf, setPayConf] = useState(false);
	const [userSignature, setUserSignature] = useState('');
	const [perMember, setPerMember] = useState(total_per_employee_amount);
	const [totalDue, setTotalDue] = useState(total_due);
	const { countUser, recordUser, isObject, csvFile } = useSelector(
		state => state.user
	);
	useEffect(() => {
		setPerMember(total_per_employee_amount);
		setTotalDue(total_due);
	}, [total_per_employee_amount, total_due]);
	const handleOpenPayConf = () => {
		setPayConf(true);
	};

	const handleClosePayConf = () => {
		setPayConf(false);
	};
	//

	const [initialValues, setInitiateValue] = useState({
		monthly_payment: perMember ? perMember : '0',
		total_due: totalDue ? perMember : '0',
		signature: '',
	});
	const handleButtonClick = (setFieldValue, submitForm, errors) => {
		setFieldValue('published');
		submitForm();
		// console.log("published123", errors);
	};
	const validationSchema = Yup.object().shape({
		monthly_payment: Yup.string().required('*Monthly payment is required'),
		total_due: Yup.string().required('*Total due is required'),
	});
	const formSubmit = values => {
		// console.log("abcd123", values,recordUser,countUser);
		// addMember()
		// dispatch(SignupEmployer(values))
		// navigate('/')
		// call api theres
	};
	const submitHandler = e => {
		e.preventDefault();
		// dispatch(SignupEmployer())
		// dispatch(login(email, password));
		// navigate('/providers')
	};
	const addMember = async () => {
		if (isObject) {
			try {
				const token = `Bearer ${localStorage.getItem('token')}`;
				let { data } = await axios.post(
					`${server}/deduct-And-Transfer-single-user`,
					{
						totalUser: countUser,
						...recordUser,
						dateofbirth: recordUser.date_of_birth,
						signature: userSignature,
					},
					{
						headers: {
							Authorization: token,
							'Content-Type': 'application/json',
						},
					}
				);
				// console.log("Helllo",data)
				handleClose();
				handlePaymentCOnfirmedOpen();
			} catch (error) {
				// alert(error?.response?.data?.message);
				// console.log("error?.response?.data?.message",error?.response?.data?.message)
				toast.error(error?.response?.data?.message);
			}
		} else {
			try {
				const token = `Bearer ${localStorage.getItem('token')}`;
				let { data } = await axios.post(
					`${server}/deduct-And-Transfer-bulk-user`,
					{
						totalUser: countUser,
						csv_file: csvFile,
						// signature:userSignature
					},
					{
						headers: {
							Authorization: token,
							'Content-Type': 'multipart/form-data',
						},
					}
				);
				// console.log("Helllo",data)
				handleClose();
				handlePaymentCOnfirmedOpen();
			} catch (error) {
				// alert(error?.response?.data?.message);
				// console.log("error?.response?.data?.message",error?.response?.data?.message)
				toast.error(error?.response?.data?.message);
			}
		}
	};

	return (
		<div>
			<Toaster />
			<PayConf
				openPayConf={openPayConf}
				handleOpenPayConf={handleOpenPayConf}
				handleClosePayConf={handleClosePayConf}
			/>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					// console.log("Values About fee", values);
					formSubmit(values, setSubmitting, resetForm);
				}}
			>
				{({
					errors,
					touched,
					values,
					handleChange,
					setFieldValue,
					submitForm,
				}) => (
					<Form onSubmit={submitHandler}>
						<Dialog open={show} onClose={handleClose} maxWidth='md'>
							{' '}
							{/* maxWidth="sm" ,md,lg */}
							<DialogTitle className={classes.buttonAddnew}>
								<span
									style={{
										fontSize: '24px ',
										fontWeight: '500 ',
										fontFamily: 'DM Sans',
										color: '#32325D',
									}}
								>
									Make Payment
								</span>
								<IconButton
									onClick={handleClose}
									style={{ position: 'absolute', top: 0, right: 0 }}
								>
									<Box
										style={{
											background: '#4157FF',
											width: '42px',
											height: '42px',
											fontSize: '11px ',
											boxShadow: 'none ',
											borderRadius: '24px',
										}}
									>
										<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
									</Box>
								</IconButton>
							</DialogTitle>
							<DialogContent
								sx={{ marginTop: '10px' }}
								style={{ minWidth: '550px', height: '480px' }}
							>
								<Box
									sx={{ display: 'flex', gap: '22px', flexDirection: 'column' }}
								>
									<Box>
										<Typography
											variant='h6'
											sx={{
												fontSize: '14px ',
												fontWeight: '700',
												color: '#525F7F',
											}}
											gutterBottom
										>
											Monthly Payment Total Per Employee
										</Typography>

										<TextField
											margin='dense'
											// disabled
											// placeholder="$"
											fullWidth
											// onChange={handleChange}
											value={total_per_employee_amount}
											InputProps={{
												readOnly: true,
												style: { color: 'black' },
											}}
											sx={{
												boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
												width: '489px',
											}}
										/>
										{errors.monthly_payment && touched.monthly_payment ? (
											<div className={`red mb-1 fs15`}>
												{errors.monthly_payment}
											</div>
										) : null}
									</Box>

									<Box>
										<Typography
											variant='h6'
											sx={{
												fontSize: '14px ',
												fontWeight: '700',
												color: '#525F7F',
											}}
											gutterBottom
										>
											Total Due
										</Typography>
										<TextField
											// disabled
											margin='dense'
											// placeholder="$16,200"
											// onChange={handleChange}
											value={total_due}
											fullWidth
											InputProps={{
												readOnly: true,
												style: { color: 'black' },
											}}
											sx={{
												boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
												width: '489px',
											}}
										/>
										{errors.total_due && touched.total_due ? (
											<div className={`red mb-1 fs15`}>{errors.total_due}</div>
										) : null}
									</Box>

									<Box>
										<Typography
											variant='h6'
											inputProps={{ maxLength: 15 }}
											sx={{
												fontSize: '14px ',
												fontWeight: '700',
												color: '#525F7F',
											}}
											gutterBottom
										>
											Signature
										</Typography>
										<TextField
											margin='dense'
											placeholder='By typing your name you authorize today’s payment.'
											fullWidth
											inputProps={{ maxLength: 70 }}
											onChange={e => setUserSignature(e.target.value)}
											defaultValue={userSignature}
											InputProps={
												{
													// style: {height:'31px'}
												}
											}
											sx={{
												boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
												width: '489px',
											}}
										/>
										{errors.signature && touched.signature ? (
											<div className={`red mb-1 fs15`}>{errors.signature}</div>
										) : null}
									</Box>
								</Box>
							</DialogContent>
							<DialogActions
								sx={{
									display: 'flex',
									justifyContent: 'center',
									margin: '0px 0px 36px',
								}}
							>
								<Button
									// onClick={handleOpenPayConf}
									style={{
										height: '46px ',
										width: '169px ',
										borderRadius: '24px ',
										background: '#4157FF ',
										textTransform: 'none',
										fontSize: '15px ',
										fontWeight: 700,
									}}
									variant='contained'
									color='primary'
									onClick={() =>
										handleButtonClick(setFieldValue, submitForm, errors)
									}
								>
									Submit Payment
								</Button>
							</DialogActions>
						</Dialog>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default MakePayment;
