import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import Rating from '@mui/material/Rating';
import Click from '../../asset/image/click.svg';
import EyeView from '../../asset/image/eyeView.svg';
import Search from '../../asset/image/search.svg';
import Delete from '../../asset/image/delete.svg';
import Switch from '@mui/material/Switch';
// import Spinner from '../Spinner/Spiner'
//
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import Box from '@mui/material/Box';
import { FaCheck, FaTimes } from 'react-icons/fa';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
// import AddNewEmployee from "./AddNewEmployeeModel";
import Checkbox from '../Table/Checkbox';
import {
	getAllPatient,
	getAllSuperAdmins,
	getAllLogsAction,
} from '../../redux/action/user';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { server } from '../../redux/store';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import { createStore } from 'redux'; // Css
import avatar from '../../asset/image/avatar.svg';
import { setValue } from '../../redux/action/tabAction';

import Pagination from '@mui/material/Pagination';
import UsersPanelModal from './UsersPanelModal';
import { toast, Toaster } from 'react-hot-toast';

import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
// Css
const useStyles = makeStyles({
	root: {
		fontSize: '12px ',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	bold: {
		fontWeight: 'bold',
		color: '#3772FF ',
		whiteSpace: 'pre-line',
		wordBreak: 'break-word',
	},

	imageCircle: {
		borderRadius: '22px',
		width: '39px',
		height: '39px',
	},
	rounded: {
		borderRadius: '22px ',
	},
	eyes: {
		cursor: 'pointer',
	},
	adminActivityStyle: {
		opacity: '0.2',
	},
});

const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
const UsersPanel = () => {
	const printHippa = {
		color: 'white',
		background: '#4157FF',
		borderRadius: '56px',
		border: 'none',
		width: '203px',
		height: '50px',
		cursor: 'pointer',
		fontWeight: 700,
		fontFamily: ' DM Sans',
	};
	const loadStyle = {
		color: 'white',
		background: '#4157FF',
		borderRadius: '56px',
		border: 'none',
		width: '203px',
		height: '50px',
		cursor: 'pointer',
		fontWeight: 700,
		fontFamily: ' DM Sans',
		cursor: 'not-allowed',
	};
	const value = useSelector(state => state.counter.value);
	const { user, allPatients, allSuperAdmins, paymentLogs } = useSelector(
		state => state.user
	);

	const [adminFilter, setAdminFilter] = useState('');
	const [load, setLoad] = useState(false);
	const [patientFilter, sePatientFilter] = useState('');
	const [showTypography, setShowTypography] = useState(true);
	const [showPatient, setShowPatient] = useState(true);
	const handleChangePatient = event => {
		sePatientFilter(event.target.value);
		// setShowPatient(false);
	};

	const handleChange = event => {
		setAdminFilter(event.target.value);
		setShowTypography(false);
	};

	useEffect(() => {
		dispatch(getAllPatient());
		dispatch(getAllSuperAdmins());
	}, []);
	//   console.log("paymentLogspaymentLogs",paymentLogs)
	const formatName = name => {
		if (name?.length > 12) {
			return name.replace(/(.{12})/g, '$1\n');
		}
		return name;
	};
	const navigate = useNavigate();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [path, setPath] = useState(
		<span
			style={{
				fontSize: '12px',
				marginLeft: '10px',
				fontWeight: '500',
				color: '#D0D1D2',
			}}
		>
			LOG
		</span>
	);
	const [isCheck, setIsCheck] = useState([]);
	const [query, setQuery] = useState('');
	const [isActive, setIsActive] = useState(false);
	// const arrayState = useSelector(data => data?.user?.allEnterprise);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [tabValue, setTabValue] = useState('1');
	const [activeTab, setActiveTab] = useState('1');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [arrayState, setArrayState] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentstatus, setCurrentstatus] = useState(false);
	const [userFlag, setUserFlag] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [userId, setUserId] = useState('');
	const label2 = { inputProps: { 'aria-label': 'Switch demo' } };

	useEffect(() => {
		dispatch(getAllLogsAction(patientFilter, adminFilter));
	}, [patientFilter, adminFilter]);

	// Search

	const handleSearch = event => {
		const query = event.target.value;
		// console.log(query, "ehkoase");
		setQuery(query);
	};

	const activebuttonStyle = {
		backgroundColor: '#4CD964',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};
	const adminActivityStyle = {
		opacity: '0.2',
		backgroundColor: 'red',
		color: 'blue',
	};
	const superAdminActivityStyle = {
		backgroundColor: '#4CD964',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};
	const deActiveButtonStyle = {
		backgroundColor: 'rgb(232, 80, 91)',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};

	const handleSelectAll = e => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(paymentLogs?.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, "checkall")
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};

	// download the Pdf
	const handleDownloadPDF = () => {
		setLoad(true);
		const input = document.getElementById('pdf-content');

		const options = {
			dpi: 300, // Adjust the DPI value (e.g., 150, 300, etc.)
			scale: 2, // Adjust the scale value to increase the resolution
			useCORS: true, // Enable cross-origin resource sharing if needed
			allowTaint: true, // Allow tainting of the canvas (may be required if your content contains images from other domains)
		};

		html2canvas(input, options).then(canvas => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF('p', 'pt', 'a4'); // Set the document size and orientation as per your requirements
			const imgProps = pdf.getImageProperties(imgData);
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

			pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
			pdf.save('Payment_logs.pdf');
			//   console.log('result')
			setLoad(false);
		});
	};
	const handleOpenDialog = id => {
		setOpenDialog(true);
		setUserId(id);
	};

	return (
		<>
			<div className='home'>
				<div>
					<Sidebar showSidebar={false} showHelpButton={false} />
				</div>
				<div className='homeContainer'>
					<Header showHeader={true} currentPath={path} />

					<Container
						style={{ position: 'relative', top: '78px', background: '#f8f9fe' }}
						maxWidth='xl'
						sx={{ mt: 4 }}
					>
						<Box style={{ display: 'flex', gap: '10px' }}>
							<Box>
								<FormControl>
									<InputLabel id='demo-admin-simple-select-label'>
										Filter by Admin
									</InputLabel>
									<Select
										margin='dense'
										fullWidth
										placeholder='Select Option'
										// InputProps={{
										//   style: { marginTop: "0px !important" },
										// }}
										label='Filter by Admin'
										style={{ width: '218px', position: 'relative' }}
										defaultValue={adminFilter}
										onChange={handleChange}
									>
										{allSuperAdmins?.map(patient => {
											return (
												<MenuItem value={patient?.id}>{patient?.name}</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Box>

							<Box>
								<FormControl>
									<InputLabel id='demo-simple-select-label'>
										Filter by patient
									</InputLabel>
									<Select
										label='Filter by patient'
										margin='dense'
										fullWidth
										placeholder='Select Option'
										style={{ width: '218px', position: 'relative' }}
										defaultValue={patientFilter}
										onChange={handleChangePatient}
									>
										{allPatients?.map(patient => {
											return (
												<MenuItem value={patient?.id}>{patient?.name}</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Box>
						</Box>
						<Grid container spacing={2}>
							<Grid xs={6} md={10}></Grid>
						</Grid>
						<div id='pdf-content'>
							<TableContainer component={Paper} sx={{ mt: 2 }}>
								<Table sx={{ minWidth: 650 }} aria-label='simple table'>
									<TableHead>
										<TableRow
											sx={{ background: '#F8F9FA !important', border: 'none' }}
										>
											{/* <TableCell>
                      <Checkbox
                        {...label1}
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </TableCell> */}

											<TableCell className={classes.root}>
												<div
													style={{
														display: 'flex',
														justifyContent: 'flex-start',
														marginLeft: '76px',
													}}
												>
													<span>
														PATIENT
														<KeyboardArrowDownIcon
															sx={{ paddingTop: '10px' }}
														/>
													</span>
												</div>
											</TableCell>
											<TableCell className={classes.root}>
												<div
													style={{
														display: 'flex',
														justifyContent: 'flex-start',
														marginLeft: '7px',
													}}
												>
													<span>
														VIEWER
														<KeyboardArrowDownIcon
															sx={{ paddingTop: '10px' }}
														/>
													</span>
												</div>
											</TableCell>
											<TableCell className={classes.root}>
												<div
													style={{
														display: 'flex',
														justifyContent: 'flex-start',
														marginLeft: '7px',
													}}
												>
													<span>
														MESSAGE
														<KeyboardArrowDownIcon
															sx={{ paddingTop: '10px' }}
														/>
													</span>
												</div>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody sx={{ background: 'white' }}>
										{paymentLogs?.length === 0 ? (
											<TableRow>
												<TableCell
													colSpan={9}
													align='center'
													style={{ fontWeight: 'bold', background: '#f8f9fe' }}
												>
													No Data Found
												</TableCell>
											</TableRow>
										) : (
											paymentLogs?.map((row, patient) => (
												<TableRow
													// key={row.name}
													sx={{
														'&:last-child td, &:last-child th': { border: 0 },
													}}
												>
													{/* <TableCell>
                                                    <Checkbox
                                                        {...label1}
                                                        key={row.id}
                                                        type="checkbox"
                                                        // name={name}
                                                        id={row.id}
                                                        handleClick={handleClick}
                                                        isChecked={isCheck.includes(row.id)}
                                                    />
                                                    </TableCell> */}

													<TableCell>
														<div style={{ display: 'flex', gap: '30px' }}>
															<div>
																<img
																	src={
																		row.profile_image
																			? 'https://api.healthcoinxchangeportal.com/' +
																			row.profile_image
																			: avatar
																	}
																	alt=''
																	className={classes.imageCircle}
																/>
															</div>

															<div style={{ display: 'grid' }}>
																<span className={classes.bold}>
																	{row.user[0]?.name}
																</span>
																<span className='textcolorchange'>
																	{' '}
																	{row.user[0]?.email}
																</span>
																<span className='textcolorchange'>
																	ID: {row.user[0]?.id}
																</span>
															</div>
														</div>
													</TableCell>
													<TableCell>
														<div style={{ display: 'flex', gap: '30px' }}>
															<div style={{ display: 'grid' }}>
																<span className={classes.bold}>
																	{row.viewer[0]?.name}
																</span>
																<span className='textcolorchange'>
																	{' '}
																	{row.viewer[0]?.email}
																</span>
																<span className='textcolorchange'>
																	ID: {row.viewer[0]?.id}
																</span>
															</div>
														</div>
													</TableCell>
													<TableCell
														align='left'
														col={2}
														component='th'
														scope='row'
													>
														<Box style={{ width: '18vw' }}>
															<span> {row?.message}</span>
														</Box>
													</TableCell>
												</TableRow>
											))
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<Box
							style={{
								marginTop: '30px',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Grid>
								{/* <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={(event, page) => setCurrentPage(page)}
                                    color="primary"
                                    variant="outlined"
                                    shape="rounded"
                                /> */}
							</Grid>
						</Box>{' '}
						<br />
						<Box
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginTop: '-22px',
							}}
						>
							{load ? (
								<LoadingButton
									style={loadStyle}
									loading
									loadingPosition='start'
									startIcon={<SaveIcon />}
									variant='outlined'
									disabled
								>
									PRINT HIPAA PDF
								</LoadingButton>
							) : (
								<button style={printHippa} onClick={handleDownloadPDF}>
									PRINT HIPAA PDF
								</button>
							)}
						</Box>
						<br />
					</Container>
				</div>
			</div>
			<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
				<DialogTitle style={{ padding: '8px' }}>
					<IconButton
						onClick={() => setOpenDialog(false)}
						color='inherit'
						aria-label='close'
						style={{ display: 'flex', marginLeft: 'auto', padding: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '34px',
								height: '34px',
								fontSize: '11px',
								boxShadow: 'none',
								borderRadius: '24px',
							}}
						>
							<CloseIcon style={{ margin: '5px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<Divider />
				<DialogContent>
					<p style={{ fontWeight: 600 }}>
						Are you sure want to delete this user?
					</p>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						// onClick={() => deleteUser()}
						variant='contained'
						color='error'
					>
						Delete
					</Button>
					<Button
						onClick={() => setOpenDialog(false)}
						variant='contained'
						style={{ background: '#4157FF' }}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
			<Toaster />
		</>
	);
};
export default UsersPanel;
