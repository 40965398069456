import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import EyeView from '../../asset/image/eyeView.svg';

//
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Css
const useStyles = makeStyles({
	root: {
		fontSize: '12px !important',
		fontWeight: 'bold !important',
		textAlign: 'center  !important',
	},
	font: {
		margin: '12px',
	},
	bold: {
		fontWeight: 'bold !important',
	},

	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	headingIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	rounded: {
		borderRadius: '22px !important',
	},
	eyes: {
		cursor: 'pointer',
	},
	verified: {
		color: '#00C48C',
	},
});

// const label2 = { inputProps: { 'aria-label': 'Switch demo' } };
const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
const UploadedDocument = ({ name, profile_image, frontid_image }) => {
	const classes = useStyles();
	function createData(fullName, uploadedBy, action) {
		return { fullName, uploadedBy };
	}

	return (
		<>
			{/* <Container sx={{ mt: 2 }} > */}

			<TableContainer
				component={Paper}
				style={{ margin: 'auto', width: '90%' }}
				sx={{ mt: 2 }}
			>
				<Table aria-label='simple table'>
					<TableHead style={{ backgroundColor: '#F8F9FA' }}>
						<TableRow>
							{/* <TableCell style={{ padding: '-0px', margin: '-12px' }}><Checkbox {...label1} size="small" /></TableCell> */}

							<TableCell
								style={{ padding: '10px', margin: '0px' }}
								className={classes.root}
							>
								<span className={classes.headingIcon}>
									FILE NAME
									<KeyboardArrowDownIcon style={{ padding: '2px' }} />
								</span>
							</TableCell>
							<TableCell
								style={{ padding: '10px', margin: '0px' }}
								className={classes.root}
								align='center'
							>
								{' '}
								<span className={classes.headingIcon}>
									UPLOADED BY
									<KeyboardArrowDownIcon style={{ padding: '2px' }} />
								</span>
							</TableCell>

							<TableCell
								style={{ padding: '10px', margin: '0px' }}
								className={classes.root}
								align='center'
							>
								<span className={classes.headingIcon}>
									ACTION
									<KeyboardArrowDownIcon style={{ padding: '2px' }} />
								</span>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{frontid_image && (
							<TableRow
								key={'1'}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								{/* <TableCell style={{ padding: '0px', margin: '-12px' }}><Checkbox {...label1} size="small" /></TableCell> */}
								<TableCell
									style={{ padding: '10px', margin: '0px', opacity: 0.8 }}
									align='center'
								>
									{'frontid_image'}
								</TableCell>
								<TableCell
									style={{ padding: '10px', margin: '0px', opacity: 0.8 }}
									align='center'
								>
									{name}
								</TableCell>
								<TableCell
									style={{ padding: '10px', margin: '0px', opacity: 0.8 }}
									align='center'
								>
									<a
										href={
											'https://api.healthcoinxchangeportal.com/' + frontid_image
										}
										target='_blank'
									>
										<img src={EyeView} alt='eyeView' className={classes.eyes} />
									</a>
								</TableCell>
							</TableRow>
						)}
						{profile_image && (
							<TableRow
								key={'1'}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								{/* <TableCell style={{ padding: '0px', margin: '-12px' }}><Checkbox {...label1} size="small" /></TableCell> */}
								<TableCell
									style={{ padding: '10px', margin: '0px', opacity: 0.8 }}
									align='center'
								>
									{'profile_image'}
								</TableCell>
								<TableCell
									style={{ padding: '10px', margin: '0px', opacity: 0.8 }}
									align='center'
								>
									{name}
								</TableCell>
								<TableCell
									style={{ padding: '10px', margin: '0px', opacity: 0.8 }}
									align='center'
								>
									<a
										href={
											'https://api.healthcoinxchangeportal.com/' + profile_image
										}
										target='_blank'
									>
										<img src={EyeView} alt='eyeView' className={classes.eyes} />
									</a>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			{/* </Container> */}
		</>
	);
};
export default UploadedDocument;
