import avatar from '../../../asset/image/avatar.svg';
import React, { useState } from 'react';
import './MemberDetails.css';
import { deepOrange } from '@mui/material/colors';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Divider,
	Box,
	MenuItem,
	Select,
	Typography,
	InputLabel,
	FormControl,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

const MemberDetails = ({
	healthcoin_id = 12311232,
	name,
	email,
	profile_image,
	handleClickButton,
	handleSaveChanges,
	updateFamilyPlan,
	deletedMember,
	showFirstButton,
	showSecondButton,
	SubmitFamilyPayment,
	PatientId,
	grandTotal,
	plan_id,
	ResendLoginCredentialPatient,
	value,
	handleChange,
	SpousePlan,
	KindsPlan,
}) => {
	// Modal Open and Closed
	const [open, setOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handlePayNowClick = async () => {
		await setOpenDialog(true);
	};
	const handlePayButtonClick = async (PatientId, grandTotal) => {
		await SubmitFamilyPayment(PatientId, grandTotal);
		setOpenDialog(false);
	};
	const handleNotificationModalClose = () => {
		setOpenDialog(false);
	};
	console.log(SpousePlan,
		KindsPlan,)
	return (
		<>
			<div
				style={{
					background: 'white',
					marginLeft: '22px',
					width: '78vw',
					height: '107px',
				}}
			>
				<div
					style={{
						display: 'flex',
						padding: '15px',
						justifyContent: 'space-between',
					}}
				>
					<div style={{ display: 'flex' }}>
						<div>
							<img
								src={
									profile_image
										? 'https://api.healthcoinxchangeportal.com/' +
										profile_image
										: avatar
								}
								alt='avatar'
								style={{ width: '70px', height: '70px', borderRadius: '50px' }}
							/>
						</div>{' '}
						&nbsp;&nbsp;&nbsp;
						<div style={{ display: 'grid', gap: '5px' }}>
							<span style={{ fontWeight: '700' }}>{name}</span>
							<span style={{ color: '#6E757C', fontSize: '14px' }}>
								{email}
							</span>
							<span style={{ color: '#6E757C', fontSize: '14px' }}>
								HC ID:{healthcoin_id}
							</span>
						</div>
					</div>

					<div>
						{value === '1' && (
							<>
								<div className='child-right'>
									<button
										style={{
											background: '#4CD964',
											width: '229px',
											height: '40px',
											borderRadius: '24px',
											color: 'white',
											fontSize: '15px',
											fontWeight: '700',
											cursor: 'pointer',
										}}
										onClick={() => ResendLoginCredentialPatient(PatientId)}
									>
										Resend Login Credentials
									</button>
									{showFirstButton && (
										<button
											className='verify_account'
											style={{ fontSize: '15px', fontWeight: '700' }}
											onClick={handleClickButton}
										>
											Edit Info
										</button>
									)}
									{showSecondButton && (
										<button
											className='verify_account'
											onClick={handleSaveChanges}
											style={{ fontSize: '15px', fontWeight: '700' }}
										>
											Save Changes
										</button>
									)}
								</div>
							</>
						)}

						{value === '2' && (
							<div className='child-right'>
								{plan_id !== null && (
									<button
										style={{
											background: '#4CD964',
											width: '161px',
											height: '40px',
											borderRadius: '24px',
											color: 'white',
											fontSize: '15px',
											fontWeight: '700',
											cursor: 'pointer',
										}}
										onClick={handlePayNowClick}
									>
										Pay Now
									</button>
								)}
								{SpousePlan === "Yes" || KindsPlan === "Yes" ? (
									<button
										style={{
											background: '#4157FF',
											width: '151px',
											height: '40px',
											borderRadius: '24px',
											color: 'white',
											fontSize: '15px',
											fontWeight: '700',
											cursor: 'pointer',
										}}
										onClick={() => updateFamilyPlan(deletedMember)}
									>
										Update Changes
									</button>
								) : null}

								<button
									onClick={handleOpen}
									style={{
										background: '#4CD964',
										width: '151px',
										height: '40px',
										borderRadius: '24px',
										color: 'white',
										fontSize: '15px',
										fontWeight: '700',
										cursor: 'pointer',
									}}
								>
									Split The Bill
								</button>

								<Dialog
									open={open}
									onClose={() => {
										handleClose();
									}}
									maxWidth='md'
								>
									{' '}
									{/* maxWidth="sm" ,md,lg */}
									<DialogTitle>
										<span style={{ fontSize: '24px', fontWeight: '600' }}>
											How to Split the Bill
										</span>
										<IconButton
											onClick={() => {
												handleClose();
											}}
											style={{ position: 'absolute', top: 0, right: 0 }}
										>
											<Box
												style={{
													background: '#4157FF',
													width: '42px',
													height: '42px',
													fontSize: '11px',
													boxShadow: 'none',
													borderRadius: '24px',
												}}
											>
												<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
											</Box>
										</IconButton>
									</DialogTitle>
									<DialogContent
										sx={{ marginTop: '10px', width: 550 }}
									// style={{ minWidth: '550px', minHeight: '384px' }}
									>
										<Box style={{ display: 'flex', justifyContent: 'center' }}>
											<Typography style={{ textAlign: 'center' }}>
												Splitting the bill is handled externally from our
												software. Simply decide with your employee(s) what
												percentage you will pay on their behalf and deduct the
												remainder from their paycheck. In our system you will be
												require to pay the bill in full, regardless of what
												portion you deem appropriate to collect from your staff.
												Click the Pay Now button on the previous screen
											</Typography>
										</Box>
									</DialogContent>
									<DialogActions
										sx={{
											borderRadius: '0 0 20px 20px',
											display: 'flex',
											justifyContent: 'center',
										}}
									></DialogActions>
								</Dialog>

								{/* NotificationModal for Payment */}
								<Dialog open={openDialog} maxWidth='md'>
									{' '}
									{/* maxWidth="sm" ,md,lg */}
									<DialogTitle>
										<span style={{ fontSize: '24px', fontWeight: '600' }}>
											Payment
										</span>
										<IconButton
											onClick={() => {
												handleNotificationModalClose();
											}}
											// onClick={{handleNotificationModalClose}}
											style={{ position: 'absolute', top: 0, right: 0 }}
										>
											<Box
												style={{
													background: '#4157FF',
													width: '42px',
													height: '42px',
													fontSize: '11px',
													boxShadow: 'none',
													borderRadius: '24px',
												}}
											>
												<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
											</Box>
										</IconButton>
									</DialogTitle>
									<DialogContent
										sx={{ marginTop: '10px', width: 550 }}
									// style={{ minWidth: '550px', minHeight: '384px' }}
									>
										<Box style={{ display: 'flex', justifyContent: 'center' }}>
											<Typography style={{ textAlign: 'justify' }}>
												If you are paying with card, the patient's amount due will be deducted now.
												If you are paying using a checking or savings account, the payment will be
												completed in 3 to 5 business days. After the successful completion of the
												payment, the patient will then be able to use the mobile app.
											</Typography>
										</Box>
									</DialogContent>
									<DialogActions
										sx={{
											borderRadius: '0 0 20px 20px',
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										<button
											style={{
												background: '#4CD964',
												width: '161px',
												height: '40px',
												borderRadius: '24px',
												color: 'white',
												fontSize: '15px',
												fontWeight: '700',
												cursor: 'pointer',
												border: 'none',
											}}
											onClick={() =>
												handlePayButtonClick(PatientId, grandTotal)
											}
										>
											Pay
										</button>
									</DialogActions>
								</Dialog>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default MemberDetails;
