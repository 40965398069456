import React, { useEffect, useState } from 'react';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
	Container,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
	Paper,
	InputAdornment,
	TextField,
} from '@mui/material';
import { FaCheck } from 'react-icons/fa';
import { RxCross1 } from 'react-icons/rx';
import { CiClock2 } from 'react-icons/ci';
import search from '../../asset/image/search.svg';
import Checkbox from '../Table/Checkbox';
import avatar from '../../asset/image/avatar.svg';
import EyeView from '../../asset/image/eyeView.svg';
import corssStatus from '../../asset/image/corssStatus.svg';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPaymentRequestAction } from '../../redux/action/user';

// Css Style
const useStyles = makeStyles({
	root: {
		fontSize: '10px !important',
		fontWeight: 'bold !important',
		textAlign: 'center  !important',

		color: '#23262F !important',
	},
	bold: {
		fontWeight: 'bold !important',
		color: '#3772FF !important',
	},
	headingIcon: {
		display: 'flex',
	},

	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	rounded: {
		borderRadius: '22px !important',
	},
	eyes: {
		cursor: 'pointer',
	},
	corssStatus: {
		cursor: 'pointer',
		width: '20px',
	},
	verified: {
		color: '#00C48C',
		marginLeft: '6%',
		fontWeight: 'bold',
	},

	centerValue: {
		display: 'flex',
		justifyContent: 'center',
	},
	successbuttonColor: {
		backgroundColor: '#4CD964',
	},
	pendingbuttonColor: {
		backgroundColor: '#FFA23A',
		paddingRight: '31px !important',
	},
	fraudbuttonColor: {
		backgroundColor: '#E8505B',
	},

	activebuttonStyle: {
		// backgroundColor: "#4CD964",
		color: 'white',
		minWidth: '91px',
		minHeight: '23px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '8rem',
	},
});

const PaymentRequest = () => {
	const classes = useStyles();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const { allAdminPayments } = useSelector(state => state.user);
	const dispatch = useDispatch();
	const handleSelectAll = e => {
		// console.log(isCheck, "CheckAll");
		setIsCheckAll(!isCheckAll);
		setIsCheck(arrayState.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, "singlecheck");
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};

	const navigate = useNavigate();
	const [arrayState, setArrayState] = useState([]);
	const [query, setQuery] = useState('');

	useEffect(() => {
		dispatch(getAllPaymentRequestAction(query));
		setArrayState(allAdminPayments);
	}, [query]);
	const [path, setPath] = useState(
		<span
			style={{
				marginLeft: '10px',
				textTransform: 'uppercase',
				fontSize: '12px',
				fontWeight: '500',
				color: '#D0D1D2',
			}}
		>
			Payment Request
		</span>
	);
	const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };

	const handleClickSubmit = rowId => {
		navigate(`/PaymentRequestDetail/${rowId}`);
	};

	return (
		<div className='home'>
			<div>
				<Sidebar showSidebar={false} showHelpButton={false} />
			</div>
			<div className='homeContainer'>
				<Header showHeader={true} currentPath={path} />
				<Container
					maxWidth='xl'
					style={{
						position: 'relative',
						top: '110px',
						height: 'auto',
						background: '#f8f9fe',
					}}
				>
					<Box sx={{ marginBottom: '30px' }}>
						<TextField
							id='outlined-start-adornment'
							placeholder='Search'
							sx={{ marginLeft: '3px', width: '30ch' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<img src={search} alt='search' />
									</InputAdornment>
								),
							}}
							defaultValue={query}
							onChange={e => setQuery(e.target.value)}
						/>
					</Box>

					<TableContainer component={Paper}>
						<Table aria-label='simple table'>
							<TableHead>
								<TableRow sx={{ backgroundColor: '#F8F9FE' }}>
									{/* <TableCell >
                    <div >
                      <Checkbox

                        {...label1}
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      /></div>
                  </TableCell> */}

									<TableCell className={classes.root}>
										<div style={{ display: 'flex', paddingLeft: '25px' }}>
											<span style={{ fontSize: '12px' }}>PATIENT’S</span>{' '}
											&nbsp;&nbsp;
											<span style={{ fontSize: '12px' }}> NAME</span>
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
									<TableCell className={classes.root}>
										<div style={{ display: 'flex' }}>
											<span style={{ fontSize: '12px' }}>PROVIDER’S</span>
											&nbsp;&nbsp;
											<span style={{ fontSize: '12px' }}> NAME</span>
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
									<TableCell className={classes.root}>
										<div style={{ display: 'flex' }}>
											<span style={{ fontSize: '12px' }}>HEALTHCOIN</span>
											&nbsp;&nbsp;
											<span style={{ fontSize: '12px' }}>ID</span>
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
									<TableCell className={classes.root}>
										<div style={{ display: 'flex' }}>
											<span style={{ fontSize: '12px' }}>PROCEDURE</span>{' '}
											&nbsp;&nbsp;
											<span style={{ fontSize: '12px' }}>ID</span>
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
									<TableCell></TableCell>
									<TableCell className={classes.root}>
										<div style={{ display: 'flex' }}>
											<span style={{ fontSize: '12px' }}>STATUS</span>
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
									<TableCell className={classes.root}>
										<div style={{ display: 'flex', marginLeft: '-33px' }}>
											<span style={{ fontSize: '12px' }}>ACTION</span>
											<span>
												<KeyboardArrowDownIcon />
											</span>{' '}
										</div>{' '}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody style={{ background: 'white' }}>
								{allAdminPayments?.length < 1 ? (
									<TableRow>
										<TableCell colspan='8'>
											<span
												style={{
													fontWeight: 'bold',
													display: 'flex',
													justifyContent: 'center',
												}}
											>
												No Data Found
											</span>
										</TableCell>
									</TableRow>
								) : (
									allAdminPayments?.map((row, index, id) => {
										let paymentStatus = row?.payment_request_status;

										return (
											<>
												<React.Fragment key={row.id}>
													<TableRow
														className={classes.tablerow}
														sx={{
															'&:last-child td, &:last-child th': { border: 0 },
														}}
													>
														{/* <TableCell>
                            <Checkbox
                             {...label1}
                             key={row.id}
                             type="checkbox"
                             // name={name}
                             id={row.id}
                             handleClick={handleClick}
                             isChecked={isCheck.includes(row.id)}

                            />
                          </TableCell> */}

														<TableCell>
															<div style={{ display: 'flex', gap: '30px' }}>
																<div>
																	<img
																		src={
																			row?.patient?.profile_image
																				? 'https://api.healthcoinxchangeportal.com/' +
																				row?.patient?.profile_image
																				: avatar
																		}
																		alt='avatar'
																		className={classes.imageCircle}
																	/>
																</div>

																<div>
																	<span
																		style={{ fontSize: '12px' }}
																		className={classes.bold}
																	>
																		{row?.patient?.name}
																	</span>
																	<br />
																	<span
																		style={{ fontSize: '12px' }}
																		className='textcolorchange'
																	>
																		{row?.patient?.email}
																	</span>
																</div>
															</div>
														</TableCell>
														<TableCell
															style={{ fontSize: '12px' }}
															className='textcolorchange'
														>
															{row?.provider?.name}
														</TableCell>
														<TableCell
															align='center'
															style={{ fontSize: '12px' }}
															className='textcolorchange'
														>
															{row?.patient?.healthcoin_id}
														</TableCell>
														<TableCell
															align='center'
															style={{ fontSize: '12px' }}
															className='textcolorchange'
														>
															{row?.procedure_id}
														</TableCell>
														<TableCell
															style={{ fontSize: '12px' }}
															className='textcolorchange'
														>
															{row?.user_state?.name}
														</TableCell>
														<TableCell>
															{' '}
															<button
																className={`${classes.activebuttonStyle} ${paymentStatus == '4'
																	? classes.successbuttonColor
																	: classes.pendingbuttonColor
																	}`}
															// className={classes.activebuttonStyle  }
															// style={{backgroundColor:'#E8505B'}}                  >
															// style={{backgroundColor:'#FFA23A'}}
															>
																<>
																	{/* <FaCheck /> &nbsp; <span  style={{ fontSize: "12px" }}>COMPLETED</span> */}
																	{/* <RxCross1 /> &nbsp; <span  style={{ fontSize: "12px" }}>FRAUD REPORT</span> */}
																	<span
																		style={{
																			fontSize: '12px',
																			display: 'flex',
																			justifyContent: 'center',
																			alignItems: 'center',
																		}}
																	>
																		{paymentStatus == '4' ? (
																			<>
																				{' '}
																				<FaCheck /> &nbsp; COMPLETED
																			</>
																		) : (
																			<>
																				<CiClock2 size={'18px'} /> &nbsp;
																				PENDING
																			</>
																		)}
																	</span>
																</>
															</button>
														</TableCell>

														<TableCell>
															{row?.patient_status === 1 && !['child', 'spouse'].includes(row?.patient?.user_type) ? (
																<img
																	src={corssStatus}
																	alt='corssStatus'
																	className={classes.corssStatus}
																/>
															) : null}{' '}
															&nbsp;&nbsp;
															<img
																src={EyeView}
																alt='eyeView'
																onClick={() => handleClickSubmit(row?.id)}
																className={classes.eyes}
															/>{' '}
														</TableCell>
													</TableRow>
												</React.Fragment>
											</>
										);
									})
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Container>
			</div>
		</div>
	);
};

export default PaymentRequest;
