// import './login.css'
import Logo from '../../../asset/image/Logo.svg';
import ApprovedAccount from '../../../asset/image/approvedAccount.svg';
import healthcoinLogo from '../../../asset/image/healthcoinLogo.png';

import Email from '../../../asset/image/email.svg';
import bg from '../../../asset/image/bg.svg';
import Password from '../../../asset/image/password.svg';
import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import MemberModel from '../Members/MemberModel';
// import Logo from '../../../asset/image/Logo'
// import './login.css'
import {
	Box,
	Card,
	CardContent,
	Grid,
	Typography,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Button,
	Link,
} from '@mui/material';
import User from '../Users/User';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { continueProfileUser, login } from "../../../redux/action/user";
import { continueEnterpriseAccount, login } from '../../../redux/action/user';
const useStyles = makeStyles(theme => ({
	accountPendingMain: {
		backgroundImage: `url(${bg})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		width: '100%',
		height: '100vh',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: -1,

		[theme.breakpoints.between(850, 1890)]: {
			height: '100vh',
		},

		[theme.breakpoints.between(0, 850)]: {
			height: '100vh',
		},
	},
	card: {
		width: 445,
		height: 'auto',
		borderRadius: '25px ',
		[theme.breakpoints.between(0, 850)]: {
			width: 340,
			marginTop: '20px',
		},
	},
	accountPendingTopImage: {
		marginTop: '20px',
		[theme.breakpoints.between(0, 850)]: {
			display: 'none',
		},
	},
	centerText: {
		textAlign: 'center',
		color: 'grey',
		fontWeight:'600',
		fontSize: '15px',
	},
	logoTopGrid: {
		marginTop: '22px',
	},
	topLogo: {
		width: '253px',
		height: '63',
	},
	healthcoinlogo: {
		width: '250px',
		height: '115px',
	},
	loginHeadingGrid: {
		marginLeft: '22px',
		marginTop: '28px',
	},

	formGrid: {
		marginLeft: '33px',
		marginTop: '33px',
	},
	msgTxt: {
		display:'grid',
		justifyContent:'center',
		marginTop:'30px',
		
	},

	footerApproval: {
		display: 'flex',
		gap: '78px',
		marginTop: '19px',
	},
}));
const AccountApproved = ({ setUpdate, update }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [open, setOpen] = useState(false);
	const [showImportFile, setShowImportFile] = useState(false);
	const [password, setPassword] = useState('');
	const [step, setStep] = useState(1);
	const { user } = useSelector(state => state?.user);
	const submitHandler = e => {
		e.preventDefault();
		dispatch(login(email, password));
		// navigate('/providers')
	};
	const continueProfile = () => {
		setUpdate(!update);
		dispatch(continueEnterpriseAccount(user?.email, 1));
	};
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleOpenImportFile = () => {
		setShowImportFile(true);
	};
	const handleCloseImportFile = () => {
		setShowImportFile(false);
	};
	return (
		<>
			<Box className={classes.accountPendingMain}>
				<Box style={{ position: 'relative' }}>
					<Box className={classes.accountPendingTopImage}>
						<img src={Logo} alt='' />
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Card className={classes.card}>
								<CardContent>
									{/* health coin logo */}
									<Grid container justifyContent='center' alignItems='center'>
										<Grid item xs={12} className={classes.logoTopGrid}>
											<Grid
												container
												justifyContent='center'
												alignItems='center'
											></Grid>
										</Grid>
										{/* healthcoinName logo */}
										<Grid item xs={12}>
											<Grid
												container
												justifyContent='center'
												alignItems='center'
											>
												<img
													className={classes.healthcoinlogo}
													src={Logo}
													alt='Logo'
												/>

											</Grid>
											<Grid
											container
											justifyContent='center'
											alignItems='center'
											>
												<img src={healthcoinLogo} alt="healthcoinlogo" />
											</Grid>
										</Grid>
										{/* Login to your account heading */}
										{/* <Grid>
											<Grid className={classes.loginHeadingGrid}>
												<Typography
													variant='h1'
													style={{
														fontWeight: '500 ',
														color: '#23262F ',
														fontSize: '24px ',
													}}
												>
													Account Approved!
												</Typography>
											</Grid>
										</Grid> */}
									</Grid>
									{/* email and password  */}
									<br />
									<Box className={classes.msgTxt}>
									<Typography variant='p'  style={{fontSize:'24px', fontWeight:'600', color:'#23262F',textAlign: 'center',}}>
									Step 1
										</Typography>
										<Typography variant='p'  style={{fontSize:'24px',textDecoration:'underline', fontWeight:'600', color:'#23262F',textAlign: 'center',}}>
										Invite Employees
										</Typography>

									</Box>

									<Box>
										
								<Box style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'30px'}}>
								<Typography variant='p' className={classes.centerText}>
											Employees will be sent an email link that guides them
											through a step-by-step questionnaire. You will have a
											chance to review their benefit package requests. It’s that
											simple!
										</Typography>
								</Box>
									
									</Box>
									
									<Grid
										sx={{ mt: 7 }}
										style={{
											display: 'flex',
											justifyContent: 'center',
											gap: '15px',
										}}
									>
										<Button
											type='submit'
											style={{
												color: 'white ',
												background: ' #4157FF ',
												borderRadius: '20px ',
												border: 'none',
												width: 'auto',
												height: '50px ',
												cursor: 'pointer ',
												fontWeight: '700',
												fontSize: '16px',
												textTransform: 'capitalize',
											}}
											onClick={() => handleOpenImportFile()}
										>
											Invite by groups
										</Button>
										<Button
											type='submit'
											style={{
												color: 'white ',
												background: ' #4157FF ',
												borderRadius: '20px ',
												border: 'none',
												width: 'auto',
												height: '50px ',
												cursor: 'pointer ',
												fontWeight: '700',
												fontSize: '16px',
												textTransform: 'capitalize',
											}}
											onClick={() => handleOpen()}
										>
											Invite Individually
										</Button>
									</Grid>
								</CardContent>
								<Button
									type='submit'
									style={{
										color: '#3772FF',
										/* background: rgb(65, 87, 255); */
										borderRadius: '14px',
										border: 'none',
										width: 'auto',
										height: '50px',
										cursor: 'pointer',
										fontWeight: '500',
										fontSize: '14px',
										float: 'right',
										marginRight: '22px',
										marginTop: '25px',
										textTransform: 'capitalize',
									}}
									onClick={() => continueProfile()}
									onMouseOver={e =>
										(e.target.style.textDecoration = 'underline')
									}
									onMouseOut={e => (e.target.style.textDecoration = 'none')}
								>
									Skip For Now
								</Button>
							</Card>
						</Box>
						<User
							handleOpen={handleOpen}
							handleClose={handleClose}
							open={open}
							step={step}
							setStep={setStep}
							setUpdate={setUpdate}
							update={update}
						/>
						<MemberModel
							showImportFile={showImportFile}
							handleOpenImportFile={handleOpenImportFile}
							handleCloseImportFile={handleCloseImportFile}
							step={step}
							setStep={setStep}
							setUpdate={setUpdate}
							update={update}
							// showMembers={showMembers}
							// setShowMembers={setShowMembers}
							// query={query}
							// currentPage={currentPage}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default AccountApproved;
