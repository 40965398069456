import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CardSection from './CardSection';
import { Box, Button, Paper } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
export default function CheckoutForm({ handleSubmit1 }) {
	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate();
	const { user } = useSelector(state => state?.user);
	const [stripeData, setStripeData] = useState(null);
	const handleSubmit = async event => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make  sure to disable form submission until Stripe.js has loaded.
			return;
		}
		
		const card = elements.getElement(CardElement);
		const result = await stripe.createToken(card);
		if (result.error) {
			// Show error to your customer.
			console.log(result.error.message);
			toast.error(result.error.message);
		} else {
			// try {
			handleSubmit1(result);
			return;
			
			// axios
			// 	.post(
			// 		'http://3.21.180.243/api/clientBillingInfoAndPay',
			// 		{ stripe_token: result.token.id },
			// 		{
			// 			headers: {
			// 				Accept: 'application/json',
			// 				Authorization: `Bearer ${token} `,
			// 			},
			// 		}
			// 	)
			// 	.then(response => {
			// 		setStripeData(response);
			// 		toast.success(response.data.message);
			// 		if (response.data.success === true) {
			// 			navigate('/');
			// 		}
			// 	})
			// 	.catch(error => {
			// 		toast.error(error.response?.data?.message);
			// 	});

		}
	};
	const handleGoSignUp = () => {
		window.localStorage.clear()
		navigate('/signup')
	}

	return (
		<form onSubmit={handleSubmit}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					// minHeight: '100vh',
				}}
			>
				<Paper elevation={2} sx={{ p: 6, bgcolor: '#F5F5F5' }}>
					<CardSection />
					<Button
						type='submit'
						sx={{ mt: 2 , float: 'right' , fontWeight:'600'}}
						variant='contained'
						color='primary'
						disabled={!stripe}
					>
						{' '}
						Proceed
					</Button>
					{user?.user_type === "patient" &&(
					<Button
						style={{fontWeight:'600', color: 'white',
						marginTop: '16px',
						float: 'right',
						background: 'red',
						marginRight: '12px'}}
						
						onClick={handleGoSignUp}
					>
						
						Login
					</Button>
					)}
				</Paper>
				
			</Box>
		</form>
	);
}
