import { Password } from '@mui/icons-material';
import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeAdminPassword } from '../../redux/action/user';
import Header from '../Layout/Header/Header';
import Sidebar from '../Layout/Sidebar/Sidebar';
import './detail.css';
import '../Home/home.css';
import RequestDetail from './RequestDetail';
const Detail = () => {
	const { user } = useSelector(state => state.user);
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
	const [confirmErrorMessage, setConfirmErrorMessage] = useState('');
	const [oldErrorMessage, setOldErrorMessage] = useState('');
	const dispatch = useDispatch();
	const changePassword = () => {
		const passwordRegex =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/;

		if (!oldPassword) {
			return setOldErrorMessage('*old password must be required');
		} else if (!passwordRegex.test(newPassword)) {
			return setPasswordErrorMessage(
				'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol'
			);
		} else if (!newPassword || newPassword?.length < 6) {
			return setPasswordErrorMessage('*password must be at least 6 characters');
		} else if (!confirmPassword || confirmPassword.length < 6) {
			return setConfirmErrorMessage(
				'*confirm password must be at least 6 characters'
			);
		} else if (newPassword !== confirmPassword) {
			return setErrorMessage('*confirm password does not match');
		} else if (oldPassword === newPassword && newPassword === confirmPassword) {
			return setErrorMessage(
				'*a new password and an old password cannot be the same'
			);
		} else {
			dispatch(changeAdminPassword(oldPassword, newPassword, confirmPassword));
		}
	};
	return (
		<div className='detail'>
			<div className='home'>
				<Sidebar />
				<div className='homeContainer'>
					<Header />
					<div className='requestDetail'>
						<RequestDetail
							show={false}
							name={user?.name}
							email={user?.email}
							healthcoin_id={user?.healthcoin_id}
							page='Settings'
						/>
					</div>
					<div style={{ background: '#F8F9FE' }}>
						<div style={{ marginLeft: '20px' }}>
							<div>
								<br />
								<span className='infColor boldinfo'> Info</span>
								<hr
									style={{
										width: '43px',
										position: 'absolute',
										border: '1px solid #4157FF',
										marginTop: '1px',
										borderRadius: '20px',
									}}
								/>
							</div>
							<div style={{ background: 'white', width: '97.4%' }}>
								<div className='detail-main'>
									<br />
									<p className='infColor' style={{ opacity: '0.9' }}>
										Admin Level: Non-sensitive only
									</p>

									<div className='password-change'>
										<span className='infColor'>Password Change</span>
										<br />
										<br />
										<div>
											<span style={{ float: 'left' }} className='infColor'>
												{' '}
												Old Password:&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											</span>
											<div>
												<TextField
													style={{ marginTop: '-17px', marginLeft: '7px' }}
													type='password'
													autoComplete='current-password'
													variant='standard'
													defaultValue={oldPassword}
													onChange={e => {
														setOldPassword(e.target.value);
														setOldErrorMessage('');
													}}
												/>
											</div>
											<div style={{ width: '50%' }}>
												<span
													style={{
														color: 'red',
														fontSize: '12px',
														textAlign: 'center',
														display: 'flex',
														justifyContent: 'flex-start',
														marginLeft: '134px',
													}}
												>
													{oldErrorMessage}
												</span>
											</div>
										</div>
										<br />

										<div>
											<span style={{ float: 'left' }} className='infColor'>
												New Password: &nbsp;&nbsp;&nbsp;&nbsp;
											</span>
											<div>
												<TextField
													style={{ marginTop: '-17px', marginLeft: '7px' }}
													type='password'
													autoComplete='current-password'
													variant='standard'
													defaultValue={newPassword}
													onChange={e => {
														setNewPassword(e.target.value);
														setErrorMessage('');
														setPasswordErrorMessage('');
													}}
												/>
											</div>
											<div style={{ width: '50%' }}>
												<span
													style={{
														color: 'red',
														fontSize: '12px',
														textAlign: 'center',
														display: 'flex',
														justifyContent: 'flex-start',
														marginLeft: '134px',
													}}
												>
													{passwordErrorMessage}
												</span>
											</div>
										</div>
										<br />
										<div>
											<span style={{ float: 'left' }} className='infColor'>
												Confirm Password:{' '}
											</span>
											<div>
												<TextField
													style={{ marginTop: '-17px', marginLeft: '7px' }}
													type='password'
													autoComplete='current-password'
													variant='standard'
													defaultValue={confirmPassword}
													onChange={e => {
														setConfirmPassword(e.target.value);
														setErrorMessage('');
														setConfirmErrorMessage('');
													}}
												/>
											</div>
											<div style={{ width: '50%' }}>
												<span
													style={{
														color: 'red',
														fontSize: '12px',
														textAlign: 'center',
														display: 'flex',
														justifyContent: 'flex-start',
														marginLeft: '134px',
													}}
												>
													{confirmErrorMessage}
												</span>
												<span
													style={{
														color: 'red',
														fontSize: '12px',
														textAlign: 'center',
														display: 'flex',
														justifyContent: 'flex-start',
														marginLeft: '134px',
													}}
												>
													{errorMessage}
												</span>
											</div>
										</div>
										<br />
									</div>
								</div>
							</div>

							<div style={{ marginTop: '26px' }}>
								<button
									onClick={() => changePassword()}
									style={{
										backgroundColor: ' #4157FF',
										color: 'white',
										border: 'none',
										borderRadius: '100%',
										width: '150px',
										height: '18px',
										padding: '10px',
										width: '150px',
										/* margin-left: 20px; */
										borderRadius: '20px',
										border: ' none',
										height: '40px',
										cursor: 'pointer',
									}}
								>
									Save Changes
								</button>
							</div>
						</div>
					</div>
				</div>
				<br />
			</div>
		</div>
	);
};

export default Detail;
