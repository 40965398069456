import React, { useState, useEffect } from 'react';
// import "./employeeTable.css";
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import { makeStyles } from '@mui/styles';
import { Grid, Container, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { MdModeEdit } from 'react-icons/md';
import eyeView from '../../asset/image/eyeView.svg';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import avatar from '../../asset/image/avatar.svg';
import {
	Table,
	TableHead,
	TableContainer,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import EyeView from '../../asset/image/eyeView.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Checkbox from "./Checkbox";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../redux/store';
import { setValue } from '../../redux/action/tabAction';
import StatusHandling from '../StatusHandling/StatusHandling';
import Spinner from '../SpinnerTrueFalse/Spinner';
import {
	getEmergencyCaseDetails,
	AddEmergencyNotes,
} from '../../redux/action/user';
import ContactHospital from './ContactHospital';
const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };

// css
const useStyles = makeStyles({
	companySet: {
		marginLeft: '24px',
		fontWeight: 600,
		fontSize: '24px',
	},
	tabsetting: {
		fontWeight: '600 !important',
		fontSize: '16px !important',
		textTransform: 'none !important',
		color: '#949494 !important',
		'&:focus': {
			color: '#000000 !important',
		},
		'&:active': {
			color: '#000000 !important',
		},
	},

	rootEmployer: {
		fontSize: '12px ',
		fontWeight: 'bold',
		textAlign: 'center ',
		color: '#23262F',
	},

	headingIconEmployer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	imageCircleEmployer: {
		borderRadius: '22px',
		width: '39px',
		height: '39px',
	},
	eyesEmployer: {
		cursor: 'pointer',
	},
	tablerow: {
		cursor: 'pointer',
	},
});
const root = {
	fontSize: '12px',
	fontWeight: 'bold',
	textAlign: 'center',
	color: '#23262F',
	margin: '0',
	padding: '0',
};
const EmergencyServiceDetails = () => {
	const { getDetailsEmergency } = useSelector(state => state.user);
	const { uid } = useParams();
	const { id } = useParams();
	const classes = useStyles();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheckAll2, setIsCheckAll2] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const [companyData, setCompanyData] = useState(null);
	const [companyMembers, setCompanyMembers] = useState([]);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [load, setLoad] = useState(false);
	const [status, setStatus] = useState('true');
	const [tableData, setTableData] = useState([]);
	const navigate = useNavigate();
	const [dataAvailable, setDataAvailable] = useState(false);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const [editable, setEditable] = useState(false);
	const [amount, setAmount] = useState(0);
	const [notes, setNotes] = useState('');
	const [showSaveButton, setShowSaveButton] = useState(true);
	const [path, setPath] = useState(
		<span
			style={{
				marginLeft: '10px',
				fontSize: '12px',
				fontWeight: '500',
				color: '#D0D1D2',
			}}
		>
			EMERGENCY SERVICE CASE
		</span>
	);

	useEffect(() => {
		dispatch(getEmergencyCaseDetails(uid));
	}, []);
	// console.log("abcdas", uid);
	const [value, setValue] = React.useState('1');
	const handleChange = (event, newValue) => {
		setValue(newValue);
		// Check if the new tab value is "2" (Upload Documents)
		// If it is, hide the Save button; otherwise, show it
		setShowSaveButton(newValue !== '2');
	};

	// save notes
	const handleNotesChange = event => {
		setNotes(event.target.value);
	};

	const handleSubmit = () => {
		// Get the current system default date and time and format it
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
		const day = String(currentDate.getDate()).padStart(2, '0');
		const hours = String(currentDate.getHours()).padStart(2, '0');
		const minutes = String(currentDate.getMinutes()).padStart(2, '0');
		const seconds = String(currentDate.getSeconds()).padStart(2, '0');
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

		// Send the formatted date and time along with notes
		dispatch(AddEmergencyNotes(uid, notes, formattedDate));
		// console.log(formattedDate, "dssdsdsds")
	};

	useEffect(() => {
		// Update the 'notes' state when 'getDetailsEmergency' changes
		if (getDetailsEmergency?.notes) {
			setNotes(getDetailsEmergency.notes);
		}
	}, [getDetailsEmergency]);

	// CheckBox
	const handleSelectAll = e => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(companyMembers?.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, "checkall");
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};

	return (
		<>
			{/* {loading ? (
        <Spinner />
      ) : !dataAvailable ? (
        <StatusHandling />
      ) : ( */}
			<div className='home'>
				<div>
					<Sidebar showSidebar={false} showHelpButton={false} />
				</div>
				<div className='homeContainer'>
					<Header showHeader={true} currentPath={path} />

					<Container
						style={{
							position: 'relative',
							top: '78px',
							background: '#F8F9FE',
						}}
						maxWidth='xl'
					>
						<Grid container Spacing={2}>
							<Box sx={{ width: '100%', typography: 'body1' }}>
								<p className={classes.companySet}>Emergency Service Case</p>
								<div className='header'>
									<div className='main1234'>
										<div className='child-right'>
											<div>
												<img
													src={
														getDetailsEmergency?.patient?.profile_image
															? 'https://api.healthcoinxchangeportal.com/' +
															getDetailsEmergency?.patient?.profile_image
															: avatar
													}
													alt=''
													style={{
														borderRadius: '50px',
														width: '60px',
														height: '60px',
													}}
												/>
											</div>{' '}
											&nbsp;&nbsp;&nbsp;
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													boxSizing: 'border-box',
												}}
											>
												<span style={{ fontWeight: '700' }}>
													{getDetailsEmergency?.patient?.name}
												</span>
												<span style={{ color: '#6E757C', fontSize: '14px' }}>
													{getDetailsEmergency?.patient?.email}
												</span>
												<span style={{ color: '#6E757C', fontSize: '14px' }}>
													HC ID:{getDetailsEmergency?.patient?.healthcoin_id}
												</span>
											</div>
										</div>
										{/* {show && */}
										<div
											className='child-right'
											style={{
												display: 'flex',
												flexDirection: 'column',
												boxSizing: 'border-box',
											}}
										></div>
										<div className='child-right'>
											{showSaveButton && (
												<button
													style={{
														background: '#4157FF',
														color: 'white',
														fontWeight: '700',
														height: '44px',
														cursor: 'pointer',
													}}
													onClick={handleSubmit}
												>
													Save
												</button>
											)}
											<ContactHospital
												name={getDetailsEmergency?.hospital_name}
												number={getDetailsEmergency?.phone_number}
											/>
										</div>
									</div>
								</div>
								<TabContext sx={{ mt: 2 }} value={value}>
									<Box>
										<TabList
											onChange={handleChange}
											aria-label='lab API tabs example'
											sx={{ marginLeft: '30px', marginTop: '22px' }}
										>
											<Tab
												sx={{
													minWidth: 'unset',
													width: '30px',
													'&.Mui-selected': {
														color: '#000000 !important',
													},
												}}
												className={classes.tabsetting}
												label='Info'
												value='1'
											/>
											<Tab
												className={classes.tabsetting}
												// label="Payment Details"
												sx={{
													minWidth: 'unset',
													width: '184px',
													marginLeft: '30px',
													'&.Mui-selected': {
														color: '#000000 !important',
													},
												}}
												label={
													<div style={{ gap: '5px', display: 'flex' }}>
														<span>Uploaded</span>
														<span>Documents</span>
													</div>
												}
												value='2'
											/>
										</TabList>
									</Box>
									<TabPanel value='1'>
										<Box
											style={{
												width: '100%',
												minHeight: '180px',
												borderRadius: '2px',
												margin: 'auto',
												backgroundColor: 'white',
												display: 'flex',
												flexDirection: 'column', // Stack content vertically
												justifyContent: 'space-between',
												overflow: 'auto',
											}}
										>
											<Grid
												sx={{ mt: 2, ml: 3 }}
												style={{
													fontWeight: '500 ',
													fontSize: '14px ',
													color: '#000000',
													fontFamily: 'DM sans',
												}}
											>
												<p
													style={{
														maxWidth: '1115px',
														wordBreak: 'break-all',
													}}
												>
													<span style={{ fontWeight: '600' }}>Comments:{''}</span> {getDetailsEmergency?.description}{' '}
												</p>
												<p
													style={{
														maxWidth: '1115px',
														wordBreak: 'break-all',
													}}
												>
													{' '}
													<span style={{ fontWeight: '600' }}>Hospital Name:{''}</span> {getDetailsEmergency?.hospital_name}
												</p>
												{getDetailsEmergency?.notes ? (
													getDetailsEmergency?.notes.map((note, index) => (
														<div key={index}>
															{note.note ? (
																<p
																	style={{
																		maxWidth: '1115px',
																		wordBreak: 'break-all',
																	}}
																>
																	<span style={{ fontWeight: '600' }}>Admin Notes:{' '}</span>
																	<span>
																		{note.timestamp}:&nbsp; {note.note}
																	</span>
																</p>
															) : (
																<p>Admin Notes</p>
															)}
														</div>
													))
												) : (
													<p>No notes available</p>
												)}
											</Grid>
										</Box>
										<br />
										<br />
										<Box
											sx={{
												display: 'flex',
												width: '100%',
												margin: 'auto',
												backgroundColor: '#fff',
												marginBottom: '15px',
											}}
										>
											<TextField
												fullWidth
												multiline
												placeholder='Notes:'
												name='description'
												rows={4}
												// value={notes}
												onChange={handleNotesChange}
												style={{ fontSize: '10px', color: '#6E757C' }}
											/>
										</Box>
									</TabPanel>

									<TabPanel value='2'>
										<TableContainer
											style={{
												margin: 'auto',
												width: '100%',
												overflow: 'auto',
											}}
											sx={{ mt: 2 }}
										>
											<Table>
												<TableHead>
													<TableRow>
														{/* <TableCell style={{ padding: "-0px", margin: "-12px" }}>
                              <Checkbox
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                              />
                            </TableCell> */}

														<TableCell style={root}>
															<span className={classes.headingIcon}>
																FILE NAME
																<KeyboardArrowDownIcon
																	style={{ padding: '2px' }}
																/>
															</span>
														</TableCell>

														<TableCell style={root} align='center'>
															<span className={classes.headingIcon}>
																UPLOADED BY
																<KeyboardArrowDownIcon
																	style={{ padding: '2px' }}
																/>
															</span>
														</TableCell>

														<TableCell style={root} align='center'>
															<span className={classes.headingIcon}>
																ACTION
																<KeyboardArrowDownIcon
																	style={{ padding: '2px' }}
																/>
															</span>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody style={{ background: 'white' }}>
													{getDetailsEmergency?.document?.map(item => (
														<TableRow>
															{/* <TableCell style={{ padding: '0', margin: '0' }}>
                                <Checkbox
                                  type="checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                />
                              </TableCell> */}
															<TableCell
																align='center'
																className={classes.bodyCell}
																style={{
																	padding: '0px',
																	margin: '0px',
																	opacity: 0.8,
																	fontSize: '10px',
																	fontFamily: 'DM Sans',
																}}
															>
																{item.document.split('/').pop()}
															</TableCell>
															<TableCell
																align='center'
																className={classes.bodyCell}
																style={{
																	padding: '0px',
																	margin: '0px',
																	opacity: 0.8,
																	fontSize: '10px',
																	fontFamily: 'DM Sans',
																}}
															>
																{getDetailsEmergency?.patient?.name}
																{item?.uploaded_by}
															</TableCell>
															<TableCell align='center'>
																<Box sx={{ cursor: 'pointer' }}>
																	{/* <img src={eyeView} alt="" /> */}
																	<a
																		href={
																			'https://api.healthcoinxchangeportal.com/' +
																			item?.document
																		}
																		target='_blank'
																	>
																		<img
																			src={eyeView}
																			alt='eyeView'
																			className={classes.eyes}
																		/>
																	</a>
																</Box>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</TabPanel>
								</TabContext>
							</Box>
						</Grid>
					</Container>
				</div>
			</div>
			{/* )} */}
		</>
	);
};

export default EmergencyServiceDetails;
