import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
	summaryMainDiv:{
	display: 'flex',
	width:'66vw',
	justifyContent:'space-between',

	[theme.breakpoints.between(0,900)]:{
		display: 'grid',
	}
	},
	summaryHeadingDiv:{
		width: 'auto',
		margin: 'auto',
		border: '1px solid #949494',
		padding: '20px',
		borderRadius: '20px',

		[theme.breakpoints.between(0,700)]:{
			width:'280px'
		}
	}
}));

const SummaryPatient = ({
	memberData,
	kids,
	allUserAges,
	preExistingArray,
	setkids,
}) => {
	const classes = useStyles();
	const [primaryPrice, setPrimaryPrice] = useState(0);
	const [primaryAge, setPrimaryAge] = useState('');
	const [spousePartnerAge, setSpouseAge] = useState('');
	const [primaryPreExistingPrice, setPrimaryPreExistingPrice] = useState(0);
	const [spousePrice, setSpousePrice] = useState(0);
	const [spousePreExistingPrice, setSpousePreExistingPrice] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);


	// console.log(kids);

	const spouseAge = allUserAges.find(
		user => user.id == memberData?.full_coverage_partner_age
	);
	const patientAge = allUserAges.find(
		user => user.id == memberData?.full_coverage_age
	);
	useEffect(() => {
		// const calculateFee=()=>{
		const { full_coverage_age, plan, full_coverage_partner_age } = memberData;
		const getFee = allUserAges?.find(data => data.id == full_coverage_age);
		const full_coverage_partner_age_Fee = allUserAges?.find(
			data => data.id == full_coverage_partner_age
		);
		var patientFee = +getFee?.price;
		setPrimaryAge(getFee?.age);
		setPrimaryPrice(patientFee);
		var totalFee = +getFee?.price;

		var partnerFee = +full_coverage_partner_age_Fee?.price;
		// console.log(
		// 	'totalFeetotalFeetotalFee',
		// 	totalFee,
		// 	full_coverage_age,
		// 	plan,
		// 	totalFee
		// );
		// full_coverage_partner_plan

		// console.log({ memberData });

		
		// if (memberData?.full_coverage_partner_plan == 'yes') {
		// 	if (memberData?.plan == 'full_coverage') {
		// 		const spouseDiscount = (partnerFee / 100) * 20;
		// 		const spouseCal = partnerFee - spouseDiscount;
		// 		totalFee += spouseCal;
		// 		setSpousePrice(spouseCal);
		// 	} else {
		// 		totalFee += partnerFee;
		// 		setSpousePrice(partnerFee);
		// 	}
		// }
		setSpouseAge(full_coverage_partner_age_Fee?.age);
		if (memberData?.full_coverage_partner_plan == 'yes') {
			if (memberData?.plan == 'full_coverage') {
				// Remove the 20% discount for the spouse
				const spouseCal = partnerFee;
				totalFee += spouseCal;
				setSpousePrice(spouseCal);
			} else {
				totalFee += partnerFee;
				setSpousePrice(partnerFee);
			}
		}

		// if (memberData?.full_coverage_partner_is_pre_existing == 'yes') {
		// 	const spousePreexisting = memberData?.preExisting?.length;
		// 	var patientprExistingFee = 0;
		// 	if (memberData?.plan == 'full_coverage') {
		// 		const spouseDiscount = (partnerFee / 100) * 20;
		// 		patientprExistingFee = partnerFee - spouseDiscount;
		// 	} else {
		// 		patientprExistingFee = partnerFee;
		// 	}
		// 	const price = (patientprExistingFee / 100) * 20 * spousePreexisting;
		// 	totalFee = totalFee + price;
		// 	setSpousePreExistingPrice(price);
		// }
		
		// if (preExistingArray?.length > 0) {
		// 	const selectedPreexisting = preExistingArray?.length;
		// 	const patientpreExistingCost =
		// 		(patientFee / 100) * 20 * selectedPreexisting;
		// 	totalFee += patientpreExistingCost;

		// 	setPrimaryPreExistingPrice(patientpreExistingCost);
		// }
		

		// if (memberData.full_coverage_kids == 'yes' && kids.length > 0) {
		// 	kids.map((kid, index) => {
		// 		const kidAge = allUserAges?.find(data => data.id == kid?.id);

		// 		var kidFee = +kidAge?.price;
		// 		if (index == 0) {
		// 			if (memberData?.plan == 'full_coverage') {
		// 				kidFee -= (kidFee / 100) * 10;
		// 			}
		// 		}
		// 		if (index == 1) {
		// 			if (memberData?.plan == 'full_coverage') {
		// 				kidFee -= (kidFee / 100) * 25;
		// 			}
		// 		}
		// 		if (index >= 2) {
		// 			if (memberData?.plan == 'full_coverage') {
		// 				kidFee -= (kidFee / 100) * 30;
		// 			}
		// 		}

		// 		totalFee += kidFee;

		// 		updatePriceAtIndex(index, kidFee);
		// 		if (kid.isPreExistingCondition == 'yes') {
		// 			const kidPreExistingFee =
		// 				(kidFee / 100) * 20 * kid.preExistingArray.length;
		// 			totalFee += kidPreExistingFee;
		// 			const kPrice = kidFee + kidPreExistingFee;

		// 			updatePriceAtIndex(index, kPrice);
		// 		}
		// 	});
		// }
		if (memberData.full_coverage_kids == 'yes' && kids.length > 0) {
			kids.map((kid, index) => {
				const kidAge = allUserAges?.find(data => data.id == kid?.id);

				var kidFee = +kidAge?.price;

				totalFee += kidFee;

				updatePriceAtIndex(index, kidFee);

			});
		}

		// console.log(
		// 	'totalFeetotalFeetotalFee',
		// 	totalFee,
		// 	primaryPrice,
		// 	spousePrice
		// );
		setTotalPrice(totalFee);
	}, [JSON.stringify(kids), JSON.stringify(memberData)]);
	function capitalizeFirstLetter(str = '') {
		return str?.charAt(0)?.toUpperCase() + str?.slice(1);
	}
	// Function to update the price at a specific index
	const updatePriceAtIndex = (index, newPrice) => {
		const updatedChild = [...kids];
		updatedChild[index].price = newPrice;

		// Update the state with the modified array
		setkids(updatedChild);
	};

	// console.log('memberDatamemberDatamemberDatamemberData', memberData, kids);

	// }
	return (
		<Container>
			<div>
				<p>
					<b>Include: </b>
				</p>

				<div className={classes.summaryMainDiv}>
					<div style={{ display: 'flex',}}>
						<div>
							<p style={{ fontWeight: 800 }}>Myself</p>
							<p>
								Plan:{' '}
								<span style={{ fontWeight: 700 }}>
									{capitalizeFirstLetter(
										memberData?.plan == 'full_coverage'
											? 'Catastrophic + DPC + Meds'
											: 'Catastrophic'
									)}
								</span>
							</p>

							<p>
								Gender:{' '}
								<span style={{ fontWeight: 700 }}>
									{capitalizeFirstLetter(memberData?.full_coverage_gender)}
								</span>
							</p>
							<p>
								Age: <span style={{ fontWeight: 700 }}>{patientAge?.age}</span>
							</p>

							<p>
								Marital status:{' '}
								<span style={{ fontWeight: 700 }}>
									{memberData.full_coverage_partner_plan == 'yes'
										? 'Spouse/partner'
										: 'Single'}
								</span>
							</p>

							<p>
								Include to the plan:{' '}
								<span style={{ fontWeight: 700 }}>
									{capitalizeFirstLetter(
										memberData?.full_coverage_partner_plan
									)}
								</span>
							</p>
							<br />
							<br />
							{memberData?.full_coverage_partner_plan == 'yes' && (
								<>
									<p style={{ fontWeight: 800 }}>Spouse/partner</p>
									<p>
										Gender:{' '}
										<span style={{ fontWeight: 700 }}>
											{memberData.full_coverage_partner_gender == 'male'
												? 'Male'
												: 'Female'}
										</span>
									</p>
									<p>
										Age:{' '}
										<span style={{ fontWeight: 700 }}>{spouseAge?.age}</span>
									</p>

									<br />
									<br />
								</>
							)}
							{memberData?.full_coverage_kids == 'yes' && (
								<>
									<p style={{ fontWeight: 800 }}>Kids</p>
									<p>
										Have kids:{' '}
										<span style={{ fontWeight: 700 }}>
											{memberData.full_coverage_kids == 'yes' ? 'Yes' : 'No'}
										</span>
									</p>
									<p>
										How many Kids:{' '}
										<span style={{ fontWeight: 700 }}>{kids?.length}</span>
									</p>
								</>
							)}
						</div>
					</div>

					{memberData?.full_coverage_kids == 'yes' && (
						<>
							<div
								style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
							>
								{kids?.map((d1, index) => {
									const jsonObject = d1?.age;
									const Uage = allUserAges.find(user => user.id == d1?.age);

									return (
										<div>
											<p style={{ fontWeight: 700 }}>Child {index + 1}</p>
											<p>
												Gender:{' '}
												<span style={{ fontWeight: 700 }}>
													{d1.gender == 'male' ? 'Male' : 'Female'}
												</span>
											</p>
											<p>
												Age:{' '}
												<span style={{ fontWeight: 700 }}>
													{d1?.range || 'N/A'}
												</span>
											</p>
											<br />
											<br />
										</div>
									);
								})}
							</div>
						</>
					)}
					
					<div style={{ display: 'flex'}}> 
						<div
						className={classes.summaryHeadingDiv}
						>
							<p style={{ fontWeight: 'bolder', fontSize: '18px' }}>Summary</p>
							<p>
								{capitalizeFirstLetter(
									memberData?.plan == 'full_coverage'
										? 'Catastrophic + DPC + Meds'
										: 'Catastrophic'
								)}
								/Age {primaryAge}{' '}
								{memberData?.full_coverage_gender == 'male' ? 'M' : 'F'} ={' '}
								<span style={{ float: 'right' }}>
									{' '}
									{/* ${primaryPrice + primaryPreExistingPrice}/mo */}
									${(primaryPrice).toFixed(2)}/mo
								</span>
							</p>
							{memberData?.full_coverage_partner_plan == 'yes' && (
								<>
									<p>
										{capitalizeFirstLetter(
											memberData?.plan == 'full_coverage'
												? 'Catastrophic + DPC + Meds'
												: 'Catastrophic'
										)}
										/Age {spousePartnerAge}{' '}
										{memberData?.full_coverage_partner_gender == 'male'
											? 'M'
											: 'F'}{' '}
										
										{memberData?.plan === 'full_coverage' ? (
											<>
												<br /> Spouse =  &nbsp;{' '}
											</>
										) : (
											<>Spouse = &nbsp;</>
										)}
										<span style={{ float: 'right' }}>
											{' '}
											{/* ${spousePrice + spousePreExistingPrice}/mo */}$
											{(spousePrice).toFixed(2)}/mo
										</span>
									</p>
								</>
							)}
							{/* {console.log({ kids })} */}
							{/* {memberData?.full_coverage_kids == 'yes' && kids.length > 0 && (
								<>
									{kids?.map((d1, index) => {
										return (
											<>
												<p>
													{capitalizeFirstLetter(
														memberData?.plan == 'full_coverage'
															? 'Catastrophic/Direct Primary Care/Medications'
															: 'Catastrophic'
													)}
													/Age {d1?.range || 'N/A'}
													{d1?.gender == 'male' ? 'M' : 'F'} =
											
													{memberData?.plan == 'full_coverage' ? (
														<>
															Child {index + 1} <br /> +{' '}
															{index == 0 && '10% off'}
															{index == 1 && '25% off'}
															{index >= 2 && '30% off'} ={' '}
														</>
													) : (
														<> Child {index + 1}</>
													)}
													<span style={{ float: 'right' }}>${((d1.price)).toFixed(2)}/mo</span>
												</p>
											</>
										);
									})}
								</>
							)} */}
							{memberData?.full_coverage_kids == 'yes' && kids.length > 0 && (
								<>
									{kids?.map((d1, index) => {
										return (
											<>
												<p>
													{capitalizeFirstLetter(
														memberData?.plan == 'full_coverage'
															? 'Catastrophic + DPC + Meds'
															: 'Catastrophic'
													)}
													/Age {d1?.range || 'N/A'}
													{d1?.gender == 'male' ? 'M' : 'F'} &nbsp;
													{memberData?.plan == 'full_coverage' ? (
														<>Child {index + 1} = </>
													) : (
														<> Child {index + 1}</>
													)}
													<span style={{ float: 'right' }}>
														${d1.price.toFixed(2)}/mo
													</span>
												</p>
											</>
										);
									})}
								</>
							)}

							<br />
							<p>
								Total ={' '}
								<span style={{ float: 'right' }}>
									${totalPrice.toFixed(2)}/mo
								</span>
							</p>
						</div>
					</div>
				</div>
				<div>
					<p style={{ fontSize: '15px' }}>
						<span style={{ fontWeight: 'bold' }}>Note: </span>
						Preexisting conditions may have been factored into the pricing.{' '}
						<br />
						If removed or fraudulently altered, healthcare gifting requests may
						be denied.
					</p>
				</div>
			</div>
		</Container>
	);
};

export default SummaryPatient;
