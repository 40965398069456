import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import avatar from '../../asset/image/avatar.svg';
import Search from '../../asset/image/search.svg';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import search from '../../asset/image/search.svg';
import Box from '@mui/material/Box';
import { FaCheck, FaTimes } from 'react-icons/fa';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../Table/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import { getEmergencyServices } from '../../redux/action/user';
import moment from 'moment';
// Css
const useStyles = makeStyles({
	root: {
		fontSize: '12px !important',
		fontWeight: 'bold !important',
		textAlign: 'center !important',
	},
	eyes: {
		cursor: 'pointer',
	},
});

const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
const EmergencyService = () => {
	const { getEmergencyServicesData } = useSelector(state => state.user);
	const formatName = patient_name => {
		if (patient_name?.length > 15) {
			return patient_name.replace(/(.{15})/g, '$1\n');
		}
		return patient_name;
	};

	const navigate = useNavigate();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const [query, setQuery] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [arrayState, setArrayState] = useState([]);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [currentstatus, setCurrentstatus] = useState(false);

	// Search
	const handleSearch = event => {
		const query = event.target.value;
		setQuery(query);
		setCurrentPage(1);
	};
	// console.log(query,'wqwqwqwq')
	// const dispatch=useDispatch()
	useEffect(() => {
		dispatch(getEmergencyServices(query));
	}, [query]);

	// Checkbox
	const handleSelectAll = e => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(getEmergencyServicesData.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, "checkall")
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};
	return (
		<>
			<div className='home'>
				<div>
					<Sidebar showSidebar={false} showHelpButton={false} />
				</div>
				<div className='homeContainer'>
					<Header showHeader={true} />

					<Container
						style={{ position: 'relative', top: '78px', background: '#f8f9fe' }}
						maxWidth='xl'
						sx={{ mt: 4 }}
					>
						<Grid container spacing={2}>
							<Grid xs={6} md={10}>
								<TextField
									id='outlined-start-adornment'
									placeholder='Search'
									sx={{ marginLeft: '3px', width: '30ch' }}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<img src={search} alt='search' />
											</InputAdornment>
										),
									}}
									defaultValue={query}
									onChange={handleSearch}
								/>
							</Grid>
						</Grid>
						<TableContainer component={Paper} sx={{ mt: 2 }}>
							<Table sx={{ minWidth: 650 }} aria-label='simple table'>
								<TableHead>
									<TableRow
										sx={{ background: '#F8F9FA !important', border: 'none' }}
									>
										{/* <TableCell>
                      <Checkbox
                        {...label1}
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </TableCell> */}
										<TableCell></TableCell>
										<TableCell className={classes.root}>
											PATIENT'S NAME{' '}
											<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
										</TableCell>
										<TableCell className={classes.root} align='right'>
											HOSPITAL{' '}
											<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
										</TableCell>
										<TableCell className={classes.root} align='right'>
											HEALTHCOIN ID{' '}
											<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
										</TableCell>
										<TableCell className={classes.root} align='right'>
											DATE SUBMITTED{' '}
											<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody sx={{ background: 'white' }}>
									{getEmergencyServicesData?.length === 0 ? (
										<TableRow>
											<TableCell
												colSpan={9}
												align='center'
												style={{ fontWeight: 'bold', background: '#f8f9fe' }}
											>
												No Data Found
											</TableCell>
										</TableRow>
									) : (
										getEmergencyServicesData?.map((row, index, id) => {
											const dateString = row?.submitted_date;

											// Convert the date string to a Moment object
											const dateMoment = moment(
												dateString,
												'YYYY-MM-DD HH:mm:ss'
											);

											// Format the date as "MMMM Do, YYYY"
											const formattedDate = dateMoment.format('MMMM Do, YYYY');
											return (
												<TableRow
													// key={row.name}
													sx={{
														'&:last-child td, &:last-child th': { border: 0 },
													}}
												>
													{/* <TableCell>
                          <Checkbox
                            {...label1}
                            key={row.id}
                            type="checkbox"
                            // name={name}
                            id={row.id}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(row?.id)}
                          />
                        </TableCell> */}

													<TableCell
														align='right'
														onClick={() =>
															navigate(`/EmergencyServiceDetails/${row?.id}`)
														}
														style={{ cursor: 'pointer' }}
													>
														<img
															src={
																row?.patient?.profile_image
																	? 'https://api.healthcoinxchangeportal.com/' +
																	row?.patient?.profile_image
																	: avatar
															}
															alt=''
															style={{
																borderRadius: '22px',
																width: '39px',
																height: '39px',
															}}
														/>
													</TableCell>
													<TableCell
														align='left'
														component='th'
														scope='row'
														// align="center"
														onClick={() =>
															navigate(`/EmergencyServiceDetails/${row?.id}`)
														}
														style={{ cursor: 'pointer' }}
													>
														{/* <span className={classes.bold}>{row.company}</span> */}
														<span
															key={row.patient_name}
															style={{
																fontWeight: 'bold',
																color: '#3772FF',
																whiteSpace: 'pre-line',
																wordBreak: 'break-word',
															}}
														>
															{formatName(row?.patient?.name)}
														</span>
														<br />
														<span className='textcolorchange'>
															{row?.patient?.email}
														</span>
													</TableCell>
													<TableCell
														align='center'
														style={{
															maxWidth: '200px', // Adjust the maximum width as needed
															wordBreak: 'break-all',
															cursor: 'pointer',
														}}
														className='textcolorchange'
														onClick={() =>
															navigate(`/EmergencyServiceDetails/${row?.id}`)
														}
													>
														{row?.hospital_name}
													</TableCell>
													<TableCell
														align='center'
														className='textcolorchange'
														style={{ cursor: 'pointer' }}
														onClick={() =>
															navigate(`/EmergencyServiceDetails/${row?.id}`)
														}
													>
														{row?.patient?.healthcoin_id}
														{/* {row?.patient?.healthcoin_id} */}
													</TableCell>
													<TableCell
														align='center'
														className='textcolorchang'
														style={{ cursor: 'pointer' }}
														onClick={() =>
															navigate(`/EmergencyServiceDetails/${row?.id}`)
														}
													>
														{row?.submitted_date}
														{/* {formattedDate} */}
													</TableCell>
												</TableRow>
											);
										})
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{/* <Box
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, page) => setCurrentPage(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Grid>
            </Box>{" "} */}
						<br />
					</Container>
				</div>
			</div>
		</>
	);
};
export default EmergencyService;
