import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import PhoneVerified from '../../asset/image/phone_verified.png';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(theme => ({}));
const PhoneNumberVerified = ({ isShown = false, setIsContinue }) => {
	const [open, setOpen] = useState(isShown);
	const classes = useStyles();

	const nextState = () => {
		setIsContinue(true);
	};
	const removeBorder = {
		border: 'none',
	};
	return (
		<div>
			<Modal
				open={open}
				className={classes.modal}
				// onClose={handleClose}
				aria-labelledby='modal-title'
				aria-describedby='modal-description'
				BackdropProps={{
					timeout: 500,
					style: {
						backdropFilter: 'blur(3px)',
						border: 'none',
						border: 'none',
					}, // Apply the blur effect
				}}
			>
				<Box style={removeBorder}>
					<Box
						style={{ border: 'none' }}
						sx={{
							position: 'absolute',
							borderRadius: '3.125rem',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '425px',
							height: '500px',
							margin: 'auto',
							bgcolor: 'background.paper',
							boxShadow: 18,
							p: 4,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Box
							sx={{
								display: 'grid',
								gap: '20px',
								flexDirection: 'column',
								justifyContent: 'center',
								width: '300px',
								alignItems: 'center',
								textAlign: 'center',
								margin: 'auto auto',
							}}
						>
							<Box>
								<img src={PhoneVerified} alt='Phone_Verified' width={'180px'} />
								<p style={{ fontSize: '24px', fontWeight: 700 }}>
									Phone Number and Email Verified
								</p>

								<p
									style={{
										fontSize: '16px',
										color: '#66737F',
										lineHeight: '1.5rem',
									}}
								>
									Congratulations, your phone number and email has been verified. You can
									start.
								</p>
							</Box>

							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Button
									variant='contained'
									color='primary'
									size='large'
									onClick={nextState}
									style={{ padding: '1.25rem 2.375rem;' }}
								>
									CONTINUE
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default PhoneNumberVerified;
