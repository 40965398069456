// import './login.css'
import Logo from '../../../asset/image/Logo.svg';
import bg from '../../../asset/image/bg.svg';
import Pending from '../../../asset/image/pending.svg';
import Email from '../../../asset/image/email.svg';
import Password from '../../../asset/image/password.svg';
import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
// import './login.css'
import {
	Box,
	Card,
	CardContent,
	Grid,
	Typography,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Button,
	Link,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../../redux/action/user';
import axios from 'axios';
import { server } from '../../../redux/store';
const useStyles = makeStyles(theme => ({
	accountPendingMain:{
		backgroundImage: `url(${bg})`,
		backgroundSize: 'cover', 
		backgroundPosition: 'center', 
		backgroundRepeat: 'no-repeat', 
		width: '100%', 
		height: '100vh',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: -1, 


		[theme.breakpoints.between(850,1890)]:{
			height: '100vh', 
		},

		[theme.breakpoints.between(0,850)]:{
			height: '100vh', 
		}
	},
	card: {
		width: 445,
		height: 553,
		borderRadius: '25px ',
		[theme.breakpoints.between(0,850)]:{
			width:340,
			marginTop:'20px',
		}
	},
	accountPendingTopImage:{
		marginTop:'20px',
		[theme.breakpoints.between(0,850)]:{
			display:'none',
		}

	},
	logoTopGrid: {
		marginTop: '22px',
	},
	topLogo: {
		width: '253px',
		height: '63',
	},
	healthcoinlogo: {
		width: '300px',
		height: '180px',
	},
	loginHeadingGrid: {
		marginLeft: '22px',
		marginTop: '28px',
	},
	loginHeading: {
		fontWeight: '500 ',
		color: '#23262F ',
		fontSize: '24px',
	},
	formGrid: {
		marginLeft: '33px',
		marginTop: '33px',
	},
	msgTxt: {
		width: '70%',
		display: 'flex',
		margin: 'auto',
		justifyContent: 'center',
	},

	footerPanding: {
		display: 'flex',
		gap: '141px',
		marginTop: '19px',
	},
	centerText: {
		textAlign: 'center',
		color: 'grey',
	},
	footerPanding: {
		display: 'flex',
		gap: '78px',
		marginTop: '19px',
	},
}));
const AccountPending = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const submitHandler = e => {
		e.preventDefault();
		// console.log("Data", email, password);
		dispatch(login(email, password));
		// navigate('/providers')
	};
	const logoutUser = async () => {
		try {
			const logoutToken = `Bearer ${localStorage.getItem('token')}`;
			let { data } = await axios.post(
				`${server}/logout`,
				{},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: logoutToken,
					},
				}
			);
			// toast.success(data.message);
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			localStorage.removeItem('employerSignup');
			window.location.href = `/`;
		} catch (err) {
			// console.log(err?.response)
		}
	};
	return (
		<>
		<Box className={classes.accountPendingMain}> 
		<Box style={{position:'relative'}}>
			<Box className={classes.accountPendingTopImage}>
				<img src={Logo} alt='' />
			</Box>
			<Box
				sx={{
				display:'flex',
				justifyContent:'center',
				}}
			>
				{/* <Box sx={{position:'absolute',display:'flex',justifyContent:'flex-start'}}>
        
      </Box> */}

				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Card className={classes.card}>
						<CardContent>
							{/* health coin logo */}
							<Grid container justifyContent='center' alignItems='center'>
								<Grid item xs={12} className={classes.logoTopGrid}>
									<Grid
										container
										justifyContent='center'
										alignItems='center'
									></Grid>
								</Grid>
								{/* healthcoinName logo */}
								<Grid item xs={12}>
									<Grid container justifyContent='center' alignItems='center'>
										<img
											className={classes.healthcoinlogo}
											src={Pending}
											alt='healthcoinlogo'
											width='700px'
											height='700px'
										/>
									</Grid>
								</Grid>
								{/* Login to your account heading */}
								<Grid>
									<Grid className={classes.loginHeadingGrid}>
										<Typography variant='h1' className={classes.loginHeading}>
											Account Pending Approval
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							{/* email and password  */}
							<br />
							<br />
							<br />
							<Box className={classes.msgTxt}>
								<Typography variant='p' className={classes.centerText}>
									Check back again later for account verification results.
								</Typography>
							</Box>
							<br />
							<br />
							<Grid
								sx={{ mt: 3 }}
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<Button
									type='submit'
									style={{
										color: 'white ',
										background: ' #4157FF ',
										borderRadius: '56px',
										border: 'none',
										width: '203px ',
										height: '50px ',
										cursor: 'pointer',
										fontWeight: '700',
										fontSize: '16px',
									}}
									onClick={() => logoutUser()}
								>
									Logout
								</Button>
							</Grid>
						</CardContent>
					</Card>
				</Box>
			</Box>
		</Box>
		</Box>
		</>
	);
};

export default AccountPending;
