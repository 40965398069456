// import { TextField } from '@mui/material';
import React, { useState } from "react";
import Header from "../Layout/Header/Header";
import Sidebar from "../Layout/Sidebar/Sidebar";
// import "./info.css";
import RequestDetail from "../Request/RequestDetail";
import { useParams } from "react-router-dom";
// import UploadedDocument from "../UploadedDocument";
import { useEffect } from "react";
import axios from "axios";
import { server } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
// import {  Toaster } from "react-hot-toast";
import Dropzone from "react-dropzone";
import { makeStyles } from "@mui/styles";
import { toast, Toaster } from "react-hot-toast";
import InfoDropZone from "../../asset/image/InfoDropZone.svg";
import Shape from "../../asset/image/Header/Shape.png";
import { useNavigate } from "react-router-dom";
import StatusHandling from "../StatusHandling/StatusHandling";
import Spinner from "../SpinnerTrueFalse/Spinner";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ServiceImage from "../../asset/image/ServiceImage.png";
import DeleteServices from "../../asset/image/DeleteServices.png";
import ServiceEdit from "../../asset/image/ServiceEdit.png";
import {
  getSelfPayPatientDetails,
  adminChangePatientPassword,
  adminSendReminder,
  resendLoginCredentials,
} from "../../redux/action/user";
import {
  Box,
  Divider,
  TextField,
  Typography,
  Tab,
  Row,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Grid,
  TableContainer,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  errorMessage: {
    color: "red",
    fontSize: "12px",
    textAlign: "left",
  },
  //
  isLargeScreen: {
    width: " 510px",
    height: "300px",
    overflow: "auto",
    cursor: "grab",
    display: "grid",
    justifyContent: "center",
    border: "1px solid var(--grey, #949494)",
    borderRadius: "12px",

    [theme.breakpoints.down("md")]: {
      width: " 400px",
      height: "300px",
      overflow: "auto",
      cursor: "grab",
      display: "grid",
      justifyContent: "center",
      border: "1px solid var(--grey, #949494)",
      borderRadius: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      width: " 592px",
      height: "300px",
      overflow: "auto",
      cursor: "grab",
      display: "grid",
      justifyContent: "center",
      border: "1px solid var(--grey, #949494)",
      borderRadius: "12px",
    },
  },

  rightServices: {
    marginTop: "12px",
    borderRadius: "12px",
    width: "460px",
    height: "124px",
    padding: "14px",
    border: "1px solid black",
    [theme.breakpoints.down("md")]: {
      marginTop: "12px",
      borderRadius: "12px",
      width: "360px",
      height: "124px",
      padding: "14px",
      border: "1px solid black",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "12px",
      borderRadius: "12px",
      width: "560px",
      height: "124px",
      padding: "14px",
      border: "1px solid black",
    },
  },
  bodyCell: {
    border: "none",
    fontSize: "12px",
    fontWeight: "500",
    width: "150px ",
  },
  headingCell: {
    border: "none  !important",
    fontSize: "12px  !important",
    fontWeight: "600  !important",
    padding: "3px !important",
  },

  root: {
    fontSize: "12px !important",
    fontWeight: "bold !important",
    textAlign: "center  !important",
    color: "#23262F !important",
  },

  headingIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },


  payReqIno: {
    color: "#000",
    fontFamily: "DM Sans",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    // lineHeight: 'normal',
    letterSpacing: "-0.016rem",
  },
  passwordClorIno: {
    color: "#66737F",
    fontFamily: "DM Sans",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    // lineHeight: 'normal',
    letterSpacing: "-0.016rem",
  },
}));
// Css
const SelfPayDetails = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const { uid } = useParams();
  const [userprofile, setUserProfile] = useState(null);
  const [info, setInfo] = useState("Info");
  const [providerProfileImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [confirmErrorMessage, setConfirmErrorMessage] = useState("");
  const [patientDetails, setPatientDetails] = useState(null);
  const [familyPlan, setFamilyPlan] = useState(null);
  const [patientInvoices, setPatientInvoices] = useState(null);
  const { user, PatientDetail } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // console.log(PatientDetail, '1234assasa')

  useEffect(() => {
    dispatch(getSelfPayPatientDetails(uid));
    fetchPatientDetails();
  }, [JSON.stringify(PatientDetail)]);

  const fetchPatientDetails = async () => {
    setLoad(true);
    setPatientDetails(PatientDetail?.patient);
    setPatientInvoices(PatientDetail?.patient_invoices);
    setFamilyPlan(PatientDetail?.family);
    setLoading(false);
  };
  const updateSendReminder = () => {
    dispatch(adminSendReminder(uid));
  };
  const resendCredentials = () => {
    dispatch(resendLoginCredentials(uid));
  };
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setErrorMessage("");
    setPasswordErrorMessage("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setErrorMessage("");
    setConfirmErrorMessage("");
  };


  const changePassword = () => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/;

    if (!passwordRegex.test(newPassword)) {
      setPasswordErrorMessage(
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol"
      );
    } else if (!newPassword || newPassword?.length < 6) {
      setPasswordErrorMessage("*password must be at least 6 characters");
    } else if (!confirmPassword || confirmPassword?.length < 6) {
      setConfirmErrorMessage("*confirm password must be at least 6 characters");
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("*confirm password does not match");
    } else {
      dispatch(adminChangePatientPassword(newPassword, confirmPassword, uid));
    }
  };
  function capitalizeFirstLetter(str) {
    if (!str) return null;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const boxStylingFamily = {
    width: "96%",
    minHeight: "418px",
    borderRadius: "2px",
    margin: "auto",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: '-14px',
  };

  let childCount = 0;
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="detail">
          <div className="home">
            <Sidebar info={info == "Transactions" ? "Transactions" : ""} />
            <div className="homeContainer">
              <Header
                currentPath=<span
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#D0D1D2",
                  }}
                >
                  PATIENT SELF-PAY &nbsp; <img src={Shape} alt="" /> &nbsp;{" "}
                  {info == "Info" && "INFO"} {info == "Family" && "FAMILY"}{" "}
                  {info == "Invoices" && "INVOICES"}{" "}
                  {info == "Password" && "PASSWORD"}
                </span>
              />
              <div className="requestDetail">
                <RequestDetail
                  resendLoginCredentional={true}
                  sendReminder={true}
                  // downloadAs={downloadAs}
                  // verifyButton={verifyButton}

                  show={true}
                  id={patientDetails?.id}
                  name={patientDetails?.name}
                  email={patientDetails?.email}
                  healthcoin_id={patientDetails?.healthcoin_id}
                  profile_image={patientDetails?.profile_image}
                  // id={userData?.id}
                  page="Patient Details"
                  resendCredentials={resendCredentials}
                  updateSendReminder={updateSendReminder}
                  contactProvider={false}
                />
              </div>
              <div
                className="info-content"
                style={{ width: "auto", backgroundColor: "#F8F9FE" }}
              >
                <div className="info" style={{ width: "96%" }}>
                  <br />
                  <span
                    className={info === "Info" ? "active" : ""}
                    style={{
                      fontWeight: 600,
                      color: info === "Info" ? "#000000" : "#949494",
                      fontSize: "15px",
                      paddingBottom: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setInfo("Info");
                    }}
                  >
                    Info &nbsp;
                  </span>

                  <span
                    className={
                      (info === "Password" || info === "Password") && "active"
                    }
                    style={{
                      fontWeight: 600,
                      color: info === "Password" ? "#000000" : "#949494",
                      fontSize: "15px",
                      paddingBottom: "3px",
                    }}
                    onClick={() => {
                      setInfo("Password");
                    }}
                  >
                    Password &nbsp;
                  </span>
                  <span
                    className={info == "Invoices" && "active"}
                    style={{
                      fontWeight: 600,
                      color: info === "Invoices" ? "#000000" : "#949494",
                      fontSize: "15px",
                      paddingBottom: "3px",
                    }}
                    onClick={() => {
                      setInfo("Invoices");
                    }}
                  >
                    Invoices &nbsp;
                  </span>
                  <span
                    className={info == "Family" && "active"}
                    style={{
                      fontWeight: 600,
                      color: info === "Family" ? "#000000" : "#949494",
                      fontSize: "15px",
                      paddingBottom: "3px",
                    }}
                    onClick={() => {
                      setInfo("Family");
                    }}
                  >
                    Family &nbsp;
                  </span>
                  {/* abbas */}
                </div>

                {info === "Info" && (
                  <>
                    <div
                      style={{
                        width: "96%",
                        // height: "50%",
                        backgroundColor: "white",
                        margin: "auto",
                        minHeight: "39vh",
                      }}
                    >
                      <div className="detail_main_edit">
                        <br />
                        <div className="user_info_Update">
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                width: "96%",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box style={{}}>
                                <br />

                                <span className={classes.payReqIno}>
                                <span style={{fontWeight:'600'}}>Phone Number:{" "}</span> 
                                  <span>{patientDetails?.phone}</span>
                                </span>
                                <br />
                                <br />
                                <span className={classes.payReqIno}>
                                <span style={{fontWeight:'600'}}>Address:{" "}</span> 
                                  <span>{patientDetails?.location}</span>
                                </span>
                                <br />
                                <br />
                              </Box>
                            </Box>

                            <Divider
                              style={{ marginTop: "135px", width: "78vw" }}
                            />
                          </Box>

                          {/* here Write the code  */}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/*  */}
                {info === "Password" && (
                  <>
                    <div
                      className="details_edit"
                      style={{
                        width: "96%",
                        minHeight: "auto",
                        height: "auto",
                      }}
                    >
                      <div className="detail_main_edit">
                        <br />
                        <div className="user_info_Update">
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                width: "96%",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box style={{}}>
                                <br />

                                <span className={classes.passwordClorIno}>
                                  Admin Level: Non-sensitive only
                                </span>
                                <br />
                                <br />
                                <div className={classes.container}>
                                  <div className={classes.row}>
                                    <span className="infColor">
                                      New Password: &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <TextField
                                      type="password"
                                      autoComplete="current-password"
                                      variant="standard"
                                      value={newPassword}
                                      onChange={handleNewPasswordChange}
                                      style={{ marginTop: '-19px' }}
                                    />
                                  </div>
                                  <span className={classes.errorMessage}>
                                    {passwordErrorMessage}
                                  </span>

                                  <div className={classes.row}>
                                    <span className="infColor">
                                      Confirm Password:
                                    </span>
                                    <TextField
                                      type="password"
                                      autoComplete="current-password"
                                      variant="standard"
                                      value={confirmPassword}
                                      onChange={handleConfirmPasswordChange}
                                      style={{ marginTop: '-19px' }}
                                    />
                                  </div>
                                  <span className={classes.errorMessage}>
                                    {confirmErrorMessage}
                                  </span>
                                  <span className={classes.errorMessage}>
                                    {errorMessage}
                                  </span>

                                  <div style={{ marginTop: "26px" }}>
                                    <button
                                      onClick={() => changePassword()}
                                      style={{
                                        backgroundColor: " #4157FF",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "100%",
                                        width: "150px",
                                        height: "18px",
                                        padding: "10px",
                                        width: "150px",
                                        /* margin-left: 20px; */
                                        borderRadius: "20px",
                                        border: " none",
                                        height: "40px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Save Changes
                                    </button>
                                  </div>
                                </div>
                                <br />
                              </Box>
                            </Box>

                            <Divider
                              style={{ marginTop: "10px", width: "72vw" }}
                            />
                          </Box>

                          {/* here Write the code  */}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {info === "Invoices" && (
                  <>
                    <TableContainer
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        overflow: "auto",
                      }}
                    >
                      <Table sx={{ width: "96%" }}>
                        <TableHead>
                          <TableRow
                            sx={{
                              background: "none !important",
                              border: "none",
                            }}
                          >
                            <TableCell className={classes.headingCell}>
                              UNIT
                              <KeyboardArrowDownIcon
                                sx={{ paddingTop: "10px" }}
                              />
                            </TableCell>

                            {/* <Box sx={{display:'flex', justifyContent:'flex-end'}}> */}
                            {/* <TableCell className={classes.headingCell}>
                              DEBIT
                              <KeyboardArrowDownIcon
                                sx={{ paddingTop: "10px" }}
                              />{" "}
                            </TableCell>
                            <TableCell className={classes.headingCell}>
                              CREDIT
                              <KeyboardArrowDownIcon
                                sx={{ paddingTop: "10px" }}
                              />
                            </TableCell> */}
                            <TableCell
                              align="center"
                              className={classes.headingCell}
                            >
                              RUNNING BALANCE
                              <KeyboardArrowDownIcon
                                sx={{ paddingTop: "8px" }}
                              />
                            </TableCell>
                            {/* </Box> */}
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{ background: "white" }}>
                          {/* {patientInvoices?.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="center"
                        style={{ fontWeight: "bold", background: "#f8f9fe" }}
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  ) : ( */}
                          {patientInvoices?.map((row, index, id) => (
                            <TableRow key={index}>
                              <TableCell
                                sx={{
                                  border: "none",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  fontFamily: "DM Sans",
                                }}
                              >
                                PROVIDER PAYMENT ({patientDetails?.name})
                                <br />
                                Month ({row?.month})
                              </TableCell>

                              {/* <TableCell className={classes.bodyCell}>
                                {row?.payer_debit}
                              </TableCell>
                              <TableCell className={classes.bodyCell}>
                                {row?.payer_credit}
                              </TableCell> */}
                              <TableCell
                                align="center"
                                className={classes.bodyCell}
                              >
                                {/* {row?.payer_running_balance} */}
                                {row?.payer_debit}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        marginLeft: "auto",
                        marginTop: "15px",
                        marginBottom: "auto",
                        justifyContent: "end",
                        marginRight: "44px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: "600",
                          margin: "5px",
                        }}
                      >
                        TOTAL BALANCE:
                      </span>
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        {PatientDetail?.totalDebt}
                      </span>
                    </Box>
                  </>
                )}
                <br />
                {info === "Family" && <>
                  <Box style={boxStylingFamily}>
                    <Grid container spacing={2}>
                      {/* First Column */}
                      <Grid item xs={4}>
                        <Box
                          style={{
                            marginTop: "15px",
                            display: "grid",
                            gap: "6px",
                          }}
                        >
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "700",
                                fontSize: "24px",
                                // textAlign: "Center",
                                marginLeft: "50px",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Employee Plan
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            <Typography
                              style={{
                                marginLeft: "52px",
                                fontWeight: "700",
                                fontSize: "16px",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Include:{" "}
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Typography
                              style={{
                                marginLeft: "52px",
                                fontWeight: "700",
                                fontSize: "16px",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Employee:{" "}
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            <Typography
                              style={{
                                marginLeft: "52px",
                                fontWeight: "400",
                                fontSize: "16px",
                                fontFamily: "DM Sans",
                                color: 'black',
                              }}
                            >
                              Gender:{" "}
                              <span
                                style={{ fontWeight: "700", fontSize: "16px" }}
                                >
                                  {patientDetails?.gender ? (patientDetails?.gender.charAt(0).toUpperCase() + patientDetails?.gender.slice(1)) : null}
                                  
                              </span>
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            <Typography
                              style={{
                                marginLeft: "52px",
                                fontWeight: "400",
                                fontSize: "16px",
                                fontFamily: "DM Sans",
                                color: 'black',
                              }}
                            >
                              Age:{" "}
                              <span
                                style={{ fontWeight: "700", fontSize: "16px" }}
                              >
                                {" "}
                                {patientDetails?.plans?.age}
                              </span>
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            <Typography
                              style={{
                                marginLeft: "52px",
                                fontWeight: "400",
                                fontSize: "16px",
                                fontFamily: "DM Sans",
                                color: 'black',
                              }}
                            >
                              Marital status:{" "}
                              <span
                                style={{ fontWeight: "700", fontSize: "16px" }}
                              >
                                {" "}
                                {patientDetails?.martial_status}
                              </span>
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            <Typography
                              style={{
                                marginLeft: "52px",
                                fontWeight: "400",
                                fontSize: "16px",
                                fontFamily: "DM Sans",
                                color: 'black',
                              }}
                            >
                              Include to the plan:{" "}
                              <span
                                style={{ fontWeight: "700", fontSize: "16px" }}
                              >
                                {patientDetails?.include_plan}
                              </span>
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Typography
                              style={{
                                marginLeft: "52px",
                                fontWeight: "700",
                                fontSize: "16px",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Spouse/partner{" "}
                            </Typography>
                          </Box>


                          <>
                            <div>
                              <Box sx={{ mt: 1 }}>
                                <Typography
                                  style={{
                                    marginLeft: "52px",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    fontFamily: "DM Sans",
                                    color: 'black',
                                  }}
                                >
                                  Gender:{" "}
                                  <span
                                    style={{ fontWeight: "700", fontSize: "16px" }}
                                    >
                                      {familyPlan[0]?.user_type === "spouse" && familyPlan[0]?.gender ?
                                        (familyPlan[0]?.gender.charAt(0).toUpperCase() + familyPlan[0]?.gender.slice(1)) :
                                        null
                                      }
                                  </span>
                                </Typography>
                              </Box>
                              <Box sx={{ mt: 1 }}>
                                <Typography
                                  style={{
                                    marginLeft: "52px",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    fontFamily: "DM Sans",
                                    color: 'black',
                                  }}
                                >
                                  Age:{" "}
                                  <span
                                    style={{ fontWeight: "700", fontSize: "16px" }}
                                  >
                                      {familyPlan[0]?.user_type === "spouse" ?
                                        (familyPlan[0]?.plans?.age ?
                                          familyPlan[0]?.plans?.age.charAt(0).toUpperCase() + familyPlan[0]?.plans?.age.slice(1) :
                                          null
                                        ) :
                                        null
                                      }
                                  </span>
                                </Typography>
                              </Box>
                            </div>

                          </>

                        </Box>
                      </Grid>

                      {/* Second Column */}
                      <Grid item xs={3}>
                        <Box
                          style={{
                            marginTop: "34px",
                            display: "grid",
                            gap: "6px",
                          }}
                        >
                          <Box>
                            <Typography
                              style={{
                                fontWeight: "700",
                                fontSize: "16px",
                                // textAlign: "Center",
                                marginLeft: "50px",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Kids
                            </Typography>
                          </Box>

                          <Box sx={{ mt: 1 }}>
                            <Typography
                              style={{
                                marginLeft: "52px",
                                fontWeight: "400",
                                fontSize: "16px",
                                fontFamily: "DM Sans",
                                color: 'black',
                              }}
                            >
                              Have kids:{" "}
                              <span
                                style={{ fontWeight: "700", fontSize: "16px" }}
                              >
                                {patientDetails?.kids}
                              </span>
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            <Typography
                              style={{
                                marginLeft: "52px",
                                fontWeight: "400",
                                fontSize: "16px",
                                fontFamily: "DM Sans",
                                color: 'black',
                              }}
                            >
                              How many kids:{" "}
                              <span
                                style={{ fontWeight: "700", fontSize: "16px" }}
                              >
                                {" "}
                                {patientDetails?.total_kids}
                              </span>
                            </Typography>
                          </Box>
                          {familyPlan.map((child, index) => {
                            if (child?.user_type === "child") {
                              const childNumber = index + 0; // Calculate the child number
                              const capitalizedGender = capitalizeFirstLetter(child?.gender);  // Capitalize the gender
                              return (
                                <div key={index}>
                                  <Box sx={{ mt: 2 }}>
                                    <Typography
                                      style={{
                                        marginLeft: "52px",
                                        fontWeight: "700",
                                        fontSize: "16px",
                                        fontFamily: "DM Sans",
                                      }}
                                    >
                                      Child {childNumber}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ mt: 1 }}>
                                    <Typography
                                      style={{
                                        marginLeft: "52px",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        fontFamily: "DM Sans",
                                        color: 'black',
                                      }}
                                    >
                                      Gender:{" "}
                                      <span
                                        style={{ fontWeight: "700", fontSize: "16px" }}
                                      >
                                        {capitalizedGender}
                                        {/* {child.gender} */}
                                      </span>
                                    </Typography>
                                  </Box>
                                  <Box sx={{ mt: 1 }}>
                                    <Typography
                                      style={{
                                        marginLeft: "52px",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        fontFamily: "DM Sans",
                                        color: 'black',
                                      }}
                                    >
                                      Age:{" "}
                                      <span
                                        style={{ fontWeight: "700", fontSize: "16px" }}
                                      >
                                        {child?.plans?.age || null}
                                      </span>
                                    </Typography>
                                  </Box>
                                </div>
                              );
                            }
                            return null; // Skip if it's not a child
                          })}
                        </Box>
                      </Grid>

                      {/* Third Column */}
                      <Grid item xs={5}>
                        <Box
                          style={{
                            marginTop: "24px",
                            display: "grid",
                            gap: "6px",
                            overflow: "auto",
                          }}
                        >
                          <Box
                            style={{
                              border: "1px solid #949494",
                              width: "400px",
                              height: "381px",
                              overflow: "auto",
                              borderRadius: "10px",
                            }}
                          >
                            <Box>
                              <Typography
                                style={{
                                  fontWeight: "700",
                                  fontSize: "24px",
                                  // textAlign: "Center",
                                  marginLeft: "15px",
                                  marginTop: "13px",
                                  fontFamily: "DM Sans",
                                }}
                              >
                                Summary
                              </Typography>
                            </Box>
                            <Box>
                              <Box style={{ display: "flex" }}>
                                <Grid item xs={8}>
                                  <Box sx={{ mt: 3 }}>
                                    <Typography
                                      style={{
                                        marginLeft: "15px",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        fontFamily: "DM Sans",
                                        color: '#000000',
                                      }}
                                      >
                                        {(patientDetails?.plans?.plan ? patientDetails?.plans?.plan.charAt(0).toUpperCase() + patientDetails?.plans?.plan.slice(1) : "")}

                                        /Age {patientDetails?.plans?.age} {patientDetails?.gender ? patientDetails.gender.charAt(0).toUpperCase() + patientDetails.gender.slice(1) : ""}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={2}>
                                  <Box sx={{ mt: 3 }}>
                                    <span>${patientDetails?.total}/mo</span>{" "}
                                  </Box>
                                  </Grid>
                                  <Grid item xs={2}>
                                    
                                  </Grid>
                              </Box>
                                {familyPlan?.map((row, index) => {
                                  
                                  if (row?.user_type === "child") {
                                    childCount++;
                                  }
                                return (
                                  <div key={index}>
                                    <Box style={{ display: "flex" }}>
                                      <Grid item xs={8}>
                                        <Box sx={{ mt: 3 }}>
                                          <Typography
                                            style={{
                                              marginLeft: "15px",
                                              fontWeight: "400",
                                              fontSize: "14px",
                                              fontFamily: "DM Sans",
                                              color: '#000000',
                                            }}
                                          >
                                            {/* For patientDetails?.plans.plan */}
                                            {(patientDetails?.plans.plan ? patientDetails?.plans?.plan.charAt(0).toUpperCase() + patientDetails?.plans?.plan.slice(1) : null)}

                                            {/* For row?.plans?.age */}
                                            /Age {row?.plans?.age}  {row?.gender ? row?.gender.charAt(0).toUpperCase() + row?.gender.slice(1) : null}

                                            {/* For row?.discount */}
                                            {/* {row?.discount !== null ? `= ${row.discount}% off` : null}&nbsp; */}
                                            {row?.discount !== null && patientDetails?.plans?.plan !== "catastrophic" ? `= ${row?.discount} % off` : null} &nbsp;


                                            {/* For row?.user_type */}
                                            {row?.user_type === "child"
                                              ? `Child ${childCount}`
                                              : row?.user_type // Render user_type if not "child"
                                                ? row?.user_type.charAt(0).toUpperCase() + row?.user_type.slice(1)
                                                : null}

                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Box sx={{ mt: 3 }}>
                                          <span>${row?.total || null}/mo</span>{" "}
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                      </Grid>

                                    </Box>
                                  </div>
                                    );
                                 
                              })}

                              <Box style={{ display: "flex" }}>
                                <Grid item xs={8}>
                                  <Box sx={{ mt: 3 }}>
                                    <Typography
                                      style={{
                                        marginLeft: "15px",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        fontFamily: "DM Sans",
                                        color: '#000000',
                                      }}
                                    >
                                      Total =
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={2}>
                                  <Box sx={{ mt: 3 }}>
                                      <span>${PatientDetail?.total_amount || null }/mo</span>{" "}
                                  </Box>
                                  </Grid>
                                  <Grid item xs={2}>
                                    
                                  </Grid>

                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>}
                <br></br>
              </div>
            </div>
            <br />
          </div>
          <Toaster />
        </div>
      )}
    </>
  );
};

export default SelfPayDetails;
