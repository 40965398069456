import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import Rating from '@mui/material/Rating';
import Click from '../../asset/image/click.svg';
import EyeView from '../../asset/image/eyeView.svg';
import Search from '../../asset/image/search.svg';
import Delete from '../../asset/image/delete.svg';
import Switch from '@mui/material/Switch';
import Spinner from '../SpinnerTrueFalse/Spinner';
//
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import Box from '@mui/material/Box';
import { FaCheck, FaTimes } from 'react-icons/fa';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
// import AddNewEmployee from "./AddNewEmployeeModel";
import Checkbox from '../Table/Checkbox';
import {
	EnterpriseAccountApproal,
	adminStatusOnHandle,
	adminStatusOffHandle,
	continueEnterpriseAccount,
	getAllEnterprise,
	getAllAdmins,
	deleteAdmin,
} from '../../redux/action/user';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { server } from '../../redux/store';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import { createStore } from 'redux'; // Css
import avatar from '../../asset/image/avatar.svg';
import { setValue } from '../../redux/action/tabAction';

import Pagination from '@mui/material/Pagination';
import UsersPanelModal from './UsersPanelModal';
import { toast, Toaster } from 'react-hot-toast';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
// Css
const useStyles = makeStyles({
	root: {
		fontSize: '12px !important',
		fontWeight: 'bold !important',
		textAlign: 'center  !important',
	},
	bold: {
		fontWeight: 'bold !important',
		color: '#3772FF !important',
		whiteSpace: 'pre-line',
		wordBreak: 'break-word',
	},

	imageCircle: {
		borderRadius: '22px !important',
		width: '39px',
		height: '39px',
	},
	rounded: {
		borderRadius: '22px !important',
	},
	eyes: {
		cursor: 'pointer',
	},
	adminActivityStyle: {
		opacity: '0.2',
	},
});

const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
const UsersPanel = () => {
	const printHippa = {
		color: 'white',
		background: '#4157FF',
		borderRadius: '56px',
		border: 'none',
		width: '203px',
		height: '50px',
		cursor: 'pointer',
		fontWeight: 700,
		fontFamily: ' DM Sans',
	};
	const value = useSelector(state => state.counter.value);
	const { user, allAdmins, user_loader } = useSelector(state => state.user);

	const formatName = name => {
		if (name?.length > 12) {
			return name.replace(/(.{12})/g, '$1\n');
		}
		return name;
	};
	const navigate = useNavigate();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const [query, setQuery] = useState('');
	const [isActive, setIsActive] = useState(false);
	// const arrayState = useSelector(data => data?.user?.allEnterprise);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [tabValue, setTabValue] = useState('1');
	const [activeTab, setActiveTab] = useState('1');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [arrayState, setArrayState] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentstatus, setCurrentstatus] = useState(false);
	const [userFlag, setUserFlag] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [userId, setUserId] = useState('');
	const [refreshUser, setRefreshUser] = useState(false);
	const label2 = { inputProps: { 'aria-label': 'Switch demo' } };

	// const handleEyeViewClick = (rowId) =>{
	//   changeValueOne();
	//   navigate(`/employers_Info/${rowId}`);
	// }

	const handleIconClick = rowId => {
		changeValue();
		navigate(`/employers_Info/${rowId}`);
	};

	const handleEyeIconClick = rowId => {
		changeValueOne();
		navigate(`/employers_Info/${rowId}`);
	};

	const changeValue = () => {
		dispatch(setValue('2'));
	};

	const changeValueOne = () => {
		dispatch(setValue('1'));
	};
	const deleteUser = async id => {
		setOpenDialog(false);
		dispatch(deleteAdmin(userId));
	};
	const refreshAdminUsersList = () => {
		// Call the function responsible for fetching all admin users
		dispatch(getAllAdmins(query));
	};
	// api ended
	const handleChange = (e, id, expire_token) => {
		const isCheked = e.target.value;
		// console.log("isChekedisChekedisCheked", isCheked);
		if (expire_token == 0) {
			dispatch(adminStatusOffHandle(id));
		} else {
			dispatch(adminStatusOnHandle(id));
		}
		// console.log("eeeee", e.target.checked);
	};

	useEffect(() => {
		dispatch(getAllAdmins(query));
		setArrayState(allAdmins);
	}, [query, JSON.stringify(allAdmins)]);
	const [load, setLoad] = useState(false);

	// Search

	const handleSearch = event => {
		const query = event.target.value;
		// console.log(query, "ehkoase");
		setQuery(query);
	};

	const activebuttonStyle = {
		backgroundColor: '#4CD964',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};
	const adminActivityStyle = {
		opacity: '0.2',
		backgroundColor: 'red',
		color: 'blue',
	};
	const superAdminActivityStyle = {
		backgroundColor: '#4CD964',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};
	const deActiveButtonStyle = {
		backgroundColor: 'rgb(232, 80, 91)',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};

	const handleClickNew = () => {
		// navigate("/addNewEmployer");
	};

	const handleSelectAll = e => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(arrayState?.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, "checkall")
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};

	const handleOpenDialog = id => {
		setOpenDialog(true);
		setUserId(id);
	};

	return (
		<>
			{user_loader ? (
				<Spinner />
			) : (
				<div className='home'>
					<div>
						<Sidebar showSidebar={false} showHelpButton={false} />
					</div>
					<div className='homeContainer'>
						<Header showHeader={true} />

						<Container
							style={{
								position: 'relative',
								top: '78px',
								background: '#f8f9fe',
							}}
							maxWidth='xl'
							sx={{ mt: 4 }}
						>
							<Grid container spacing={2}>
								<Grid xs={6} md={10}>
									<TextField
										id='outlined-start-adornment'
										placeholder='Search'
										value={query}
										onChange={handleSearch}
										sx={{ m: 1, width: '30ch' }}
										style={{ marginLeft: '15px' }}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<img src={Search} alt='search' />
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid sx={{ mt: 2, ml: 0 }} md={2}>
									<UsersPanelModal
										userFlag={userFlag}
										setUserFlag={setUserFlag}
										refreshAdminUsersList={refreshAdminUsersList}
									/>
								</Grid>
							</Grid>
							<TableContainer component={Paper} sx={{ mt: 2 }}>
								<Table sx={{ minWidth: 650 }} aria-label='simple table'>
									<TableHead>
										<TableRow
											sx={{ background: '#F8F9FA !important', border: 'none' }}
										>
											{/* <TableCell>
                      <Checkbox
                        {...label1}
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </TableCell> */}

											<TableCell className={classes.root}>
												<div
													style={{
														display: 'flex',
														justifyContent: 'flex-start',
														marginLeft: '76px',
													}}
												>
													<span>
														USER
														<KeyboardArrowDownIcon
															sx={{ paddingTop: '10px' }}
														/>
													</span>
												</div>
											</TableCell>
											<TableCell className={classes.root} align='right'>
												HEALTHCOIN ID{' '}
												<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
											</TableCell>
											<TableCell className={classes.root} align='right'>
												ROLE{' '}
												<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
											</TableCell>
											<TableCell className={classes.root} align='right'>
												STATUS{' '}
												<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
											</TableCell>
											<TableCell className={classes.root} align='right'>
												{user?.user_type == 'super_admin' ? (
													<>
														ACTION{' '}
														<KeyboardArrowDownIcon
															sx={{ paddingTop: '10px' }}
														/>
													</>
												) : (
													''
												)}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody sx={{ background: 'white' }}>
										{arrayState?.length === 0 ? (
											<TableRow>
												<TableCell
													colSpan={9}
													align='center'
													style={{ fontWeight: 'bold', background: '#f8f9fe' }}
												>
													No Data Found
												</TableCell>
											</TableRow>
										) : (
											arrayState?.map((row, index, id) => (
												<TableRow
													// key={row.name}
													sx={{
														'&:last-child td, &:last-child th': { border: 0 },
													}}
												>
													{/* <TableCell>
                          <Checkbox
                            {...label1}
                            key={row.id}
                            type="checkbox"
                            // name={name}
                            id={row.id}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(row.id)}
                          />
                        </TableCell> */}

													<TableCell>
														<div style={{ display: 'flex', gap: '30px' }}>
															<div>
																<img
																	src={
																		row.profile_image
																			? 'https://api.healthcoinxchangeportal.com/' +
																			row?.profile_image
																			: avatar
																	}
																	alt=''
																	className={classes.imageCircle}
																/>
															</div>

															<div style={{ display: 'grid' }}>
																<span className={classes.bold}>
																	{row?.name}
																</span>
																<span className='textcolorchange'>
																	{row?.email}
																</span>
															</div>
														</div>
													</TableCell>
													<TableCell component='th' scope='row' align='center'>
														{row?.healthcoin_id}

														<span className='textcolorchange'></span>
													</TableCell>
													<TableCell align='center' className='textcolorchange'>
														{row?.user_type == 'admin' ||
															row?.user_type == 'sub_admin'
															? 'Administrator'
															: 'Super Admin'}
													</TableCell>
													{/* className={`${row?.role=="Administrator"?adminActivityStyle:adminActivityStyle}`} */}
													<TableCell
														align='center'
														className={`textcolorchange`}
													>
														{user?.user_type == 'super_admin' ? (
															<span
																className={`${row.role == 'Administrator'
																	? classes.adminActivityStyle
																	: ''
																	}`}
															>
																<Switch
																	{...label2}
																	onChange={e =>
																		handleChange(e, row.id, row?.expire_token)
																	}
																	checked={
																		row?.expire_token == 0 ? true : false
																	}
																/>
																&nbsp;{' '}
															</span>
														) : (
															<>{row?.status == '1' ? 'Active' : 'InActive'} </>
														)}
													</TableCell>

													<TableCell align='center'>
														{user?.user_type == 'super_admin' ? (
															<img
																src={Delete}
																alt='delete'
																className={classes.eyes}
																onClick={() => handleOpenDialog(row?.id)}
															// onClick={()=>{deleteUser(row?.id)}}
															/>
														) : (
															''
														)}
													</TableCell>
												</TableRow>
											))
										)}
									</TableBody>
								</Table>
							</TableContainer>
							{/* <Box
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              > */}
							{/* <Grid>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, page) => setCurrentPage(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Grid>
            </Box>{" "}
            <br />
            {/* <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-22px",
              }}
            >
              <button style={printHippa}>PRINT HIPAA PDF</button>
            </Box> */}
							<br />
						</Container>
					</div>
				</div>
			)}
			<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
				<DialogTitle style={{ padding: '8px' }}>
					<IconButton
						onClick={() => setOpenDialog(false)}
						color='inherit'
						aria-label='close'
						style={{ display: 'flex', marginLeft: 'auto', padding: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '34px',
								height: '34px',
								fontSize: '11px',
								boxShadow: 'none',
								borderRadius: '24px',
							}}
						>
							<CloseIcon style={{ margin: '5px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<Divider />
				<DialogContent>
					<p style={{ fontWeight: 600 }}>
						Are you sure want to delete this user?
					</p>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						onClick={() => deleteUser()}
						variant='contained'
						color='error'
					>
						Delete
					</Button>
					<Button
						onClick={() => setOpenDialog(false)}
						variant='contained'
						style={{ background: '#4157FF' }}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
			<Toaster />
		</>
	);
};
export default UsersPanel;
