import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MembershipSuccess from '../../asset/image/membershipSuccess.png';
import { Button, Container } from '@mui/material';

const MemberShipSuccess = ({ nextState }) => {
	const removeBorder = {
		border: 'none',
	};
	const [openPhoneVerifiedModal, setOpenPhoneVerifiedModal] =
		React.useState(true); // OTP Verification Modal

	return (
		<div>
			<Container>
				<Modal
					//  className={classes.modal}

					open={openPhoneVerifiedModal}
					// onClose={handleClosePhoneModal}
					aria-labelledby='modal-title'
					aria-describedby='modal-description'
					BackdropProps={{
						timeout: 500,
						style: { backdropFilter: 'blur(2px)' }, // Apply the blur effect
					}}
				>
					<Box style={removeBorder}>
						<Box
							sx={{
								position: 'absolute',
								borderRadius: '1rem',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: '335px',
								height: '500px',
								margin: 'auto',
								bgcolor: 'background.paper',
								boxShadow: 24,
								p: 4,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								alignItems: 'space-between',
								height: '450px',
							}}
						>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Box>
									<img src={MembershipSuccess} alt='MembershipSuccess' />
								</Box>
								<Box>
									<p style={{ fontSize: '16px', fontWeight: 800 }}>
										Registration was successful
									</p>
								</Box>
								<Box>
									<p style={{ textAlign: 'center' }}>
										Please download the mobile app to access the software
										features your membership includes.
									</p>
								</Box>
							</Box>
							<Box style={{ width: '100%' }}>
								<Button
									variant='contained'
									color='primary'
									style={{
										width: '100%',
										borderRadius: '20px',
										height: '45px',
									}}
									onClick={() => {
										localStorage.removeItem('token');
										localStorage.removeItem('user');
										window.location.reload();
									}}
								>
									Logout
								</Button>
							</Box>
						</Box>
					</Box>
				</Modal>
			</Container>
		</div>
	);
};

export default MemberShipSuccess;
