import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import Rating from '@mui/material/Rating';
import Click from '../../asset/image/click.svg';
import EyeView from '../../asset/image/eyeView.svg';
import Search from '../../asset/image/search.svg';
import Delete from '../../asset/image/delete.svg';
import Switch from '@mui/material/Switch';
// import Spinner from '../Spinner/Spiner'
//
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';
import Box from '@mui/material/Box';
import { FaCheck, FaTimes } from 'react-icons/fa';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import AddNewEmployee from './AddNewEmployeeModel';
import Checkbox from '../Table/Checkbox';
import {
	EnterpriseAccountApproal,
	continueEnterpriseAccount,
	getAllEmployees,
	getAllEnterprise,
} from '../../redux/action/user';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { server } from '../../redux/store';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import { createStore } from 'redux'; // Css
import avatar from '../../asset/image/avatar.svg';
import { setValue } from '../../redux/action/tabAction';

import Pagination from '@mui/material/Pagination';
// Css
const useStyles = makeStyles({
	root: {
		fontSize: '12px !important',
		fontWeight: 'bold !important',
		textAlign: 'center  !important',
	},
	bold: {
		fontWeight: 'bold !important',
		color: '#3772FF !important',
		whiteSpace: 'pre-line',
		wordBreak: 'break-word',
	},

	// rounded: {
	//   borderRadius: "22px !important",
	// },
	eyes: {
		cursor: 'pointer',
	},
});

const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
const Employer = () => {
	const value = useSelector(state => state.counter.value);

	const formatName = name => {
		if (name.length > 12) {
			return name.replace(/(.{12})/g, '$1\n');
		}
		return name;
	};
	const navigate = useNavigate();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const [query, setQuery] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [arrayState, setArrayState] = useState([]);
	const [token, setToken] = useState(`Bearer ${localStorage.getItem('token')}`);
	const [tabValue, setTabValue] = useState('1');
	const [activeTab, setActiveTab] = useState('1');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [currentstatus, setCurrentstatus] = useState(false);
	const { allEmployes, employeePage } = useSelector(state => state.user);
	// const handleEyeViewClick = (rowId) =>{
	//   changeValueOne();
	//   navigate(`/employers_Info/${rowId}`);
	// }

	useEffect(() => {
		dispatch(getAllEmployees(query, currentPage));
		setArrayState(allEmployes);
		setTotalPages(employeePage);
		setIsCheckAll(false);
		setIsCheck([]);
	}, [currentPage, query, currentstatus, employeePage]);
	const handleIconClick = rowId => {
		changeValue();
		navigate(`/employers_Info/${rowId}`);
	};

	const handleEyeIconClick = rowId => {
		changeValueOne();
		navigate(`/employers_Info/${rowId}`);
	};

	const changeValue = () => {
		dispatch(setValue('2'));
	};

	const changeValueOne = () => {
		dispatch(setValue('1'));
	};

	const [load, setLoad] = useState(false);

	// Search

	const handleSearch = event => {
		const query = event.target.value;
		setQuery(query);
		setCurrentPage(1);
	};
	const buttonText = isActive ? (
		<>
			<FaTimes /> &nbsp; DEACTIVE
		</>
	) : (
		<>
			<FaCheck /> &nbsp; ACTIVE
		</>
	);
	const activebuttonStyle = {
		backgroundColor: '#4CD964',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};
	const deActiveButtonStyle = {
		backgroundColor: 'rgb(232, 80, 91)',
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		borderRadius: '30px',
		border: 'none',
		cursor: 'pointer',
		minWidth: '7rem',
	};
	const handleButtonClick = (email, status, is_continue) => {
		setIsActive(!isActive);
		dispatch(EnterpriseAccountApproal(email, status));
		setCurrentstatus(!currentstatus);
	};
	const handleClickNew = () => {
		navigate('/addNewEmployer');
	};
	// Checkbox
	const handleSelectAll = e => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(allEmployes.map(li => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const handleClick = e => {
		const { id, checked } = e.target;
		// console.log(id, "checkall")
		if (checked) {
			setIsCheck([...isCheck, Number(id)]);
		} else {
			setIsCheck(isCheck.filter(item => item !== Number(id)));
		}
	};
	// console.log("allEmployesallEmployes", allEmployes);
	return (
		<>
			<div className='home'>
				<div>
					<Sidebar showSidebar={false} showHelpButton={false} />
				</div>
				<div className='homeContainer'>
					<Header showHeader={true} />

					<Container
						style={{ position: 'relative', top: '78px', background: '#f8f9fe' }}
						maxWidth='xl'
						sx={{ mt: 4 }}
					>
						<Grid container spacing={2}>
							<Grid xs={6} md={10}>
								<TextField
									id='outlined-start-adornment'
									placeholder='Search'
									value={query}
									onChange={handleSearch}
									sx={{ m: 1, width: '30ch' }}
									style={{ marginLeft: '15px' }}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<img src={Search} alt='search' />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid sx={{ mt: 2, ml: 0 }} md={2}>
								<Button
									onClick={handleClickNew}
									size='large'
									style={{
										width: '120px',
										height: '46px',
										textTransform: 'none',
										backgroundColor: '#4157FF',
										float: 'right',
										fontWeight: 700,
										borderRadius: '24px',
										color: 'white',
									}}
								>
									+ Add New
								</Button>
							</Grid>
						</Grid>
						<TableContainer component={Paper} sx={{ mt: 2 }}>
							<Table sx={{ minWidth: 650 }} aria-label='simple table'>
								<TableHead>
									<TableRow
										sx={{ background: '#F8F9FA !important', border: 'none' }}
									>
										{/* <TableCell>
                      <Checkbox
                        {...label1}
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </TableCell> */}
										<TableCell></TableCell>
										<TableCell className={classes.root}>
											COMPANY'S NAME{' '}
											<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
										</TableCell>
										<TableCell className={classes.root} align='right'>
											LOCATION{' '}
											<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
										</TableCell>
										<TableCell className={classes.root} align='right'>
											HEALTHCOIN ID{' '}
											<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
										</TableCell>
										<TableCell className={classes.root} align='right'>
											MEMBERS{' '}
											<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
										</TableCell>
										<TableCell className={classes.root} align='right'>
											STATUS{' '}
											<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
										</TableCell>
										<TableCell className={classes.root} align='right'>
											ACTION{' '}
											<KeyboardArrowDownIcon sx={{ paddingTop: '10px' }} />
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody sx={{ background: 'white' }}>
									{allEmployes?.length === 0 ? (
										<TableRow>
											<TableCell
												colSpan={9}
												align='center'
												style={{ fontWeight: 'bold', background: '#f8f9fe' }}
											>
												No Data Found
											</TableCell>
										</TableRow>
									) : (
										allEmployes?.map((row, index, id) => (
											<TableRow
												// key={row.name}
												sx={{
													'&:last-child td, &:last-child th': { border: 0 },
												}}
											>
												{/* <TableCell>
                          <Checkbox
                            {...label1}
                            key={row.id}
                            type="checkbox"
                            // name={name}
                            id={row.id}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(row?.id)}
                          />
                        </TableCell> */}

												<TableCell align='center'>
													<img
														src={
															row.profile_image
																? 'https://api.healthcoinxchangeportal.com/' +
																row.profile_image
																: avatar
														}
														alt=''
														style={{
															borderRadius: '22px',
															width: '39px',
															height: '39px',
														}}
													/>
												</TableCell>
												<TableCell component='th' scope='row' align='center'>
													{/* <span className={classes.bold}>{row.company}</span> */}
													<span key={row.id} className={classes.bold}>
														{formatName(row.company)}
													</span>
													<br />
													<span className='textcolorchange'>
														HD ID: {row?.healthcoin_id}
													</span>
												</TableCell>
												<TableCell align='center' className='textcolorchange'>
													{row?.company_address}
												</TableCell>
												<TableCell align='center' className='textcolorchange'>
													{row?.healthcoin_id}
												</TableCell>
												<TableCell align='center' className='textcolorchange'>
													<SocialDistanceIcon
														style={{ fontSize: '34px', cursor: 'pointer' }}
														onClick={() => handleIconClick(row.id)}
													/>
												</TableCell>
												<TableCell align='center'>
													{' '}
													<button
														style={
															!row?.status ||
																row?.status == '0' ||
																row?.status == '-1'
																? deActiveButtonStyle
																: activebuttonStyle
														}
														onClick={() =>
															handleButtonClick(
																row?.email,
																row?.status,
																row?.is_continue
															)
														}
													>
														{!row?.status ||
															row?.status == '0' ||
															row?.status == '-1' ? (
															<>
																<FaTimes /> &nbsp; DEACTIVE
															</>
														) : (
															<>
																<FaCheck /> &nbsp; ACTIVE
															</>
														)}
													</button>
												</TableCell>
												<TableCell align='center'>
													<img
														onClick={() => handleEyeIconClick(row.id)}
														src={EyeView}
														alt='eyeView'
														className={classes.eyes}
													/>
													&nbsp;&nbsp;
													{/* <img src={Delete} alt="delete" className={classes.eyes} /> */}
												</TableCell>
											</TableRow>
										))
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{/* <Box
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, page) => setCurrentPage(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Grid>
            </Box>{" "} */}
						<br />
					</Container>
				</div>
			</div>
		</>
	);
};
export default Employer;
