import React, { useEffect, useState } from "react";
import "./Membersidebar.css";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useParams } from 'react-router-dom';
// import Logo from "./images/Logo.png";
// import healthcoinlogo from "./images/healthcoinlogo.svg";
import LogoIcon from "../../../asset/image/LogoIcon.png";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { logoutUser } from "../../../redux/action/user";
import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ContactSupport from "../../Employer module/ContactSupport/ContactSupport";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {},

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        // overflow: "auto",
    },
    list: {
        marginTop: "14px",
    },
    dashboard: {
        "&:hover,&:focus": {
            background: " #F7FBFF !important",
        },
    },
    providers: {
        //  color: ' black !important',
        //  background: '#F7FBFF !important',
    },
    // active: {
    //   color: " black !important",
    //   background: "#F7FBFF !important",
    // },
    payment: {
        "&:hover,&:focus": {
            background: " #F7FBFF !important",
        },
    },

    payment: {
        "& payment:active": {
            backgroundColor: "black !important",
        },
    },

    employee: {
        "&:hover,&:focus": {
            background: " #F7FBFF !important",
        },
    },

    hspdispute: {
        "&:hover,&:focus": {
            background: " #F7FBFF !important",
        },
    },
    user: {
        "&:hover,&:focus": {
            background: " #F7FBFF !important",
        },
    },
    invoice: {
        "&:hover,&:focus": {
            background: " #F7FBFF !important",
        },
    },

    specialization: {
        "&:hover,&:focus": {
            background: " #F7FBFF !important",
        },
    },
    setting: {
        "&:hover,&:focus": {
            background: " #F7FBFF !important",
        },
    },

    DashboardActive: {
        // background: "black !important",
        background: "#F7FBFF !important",
    },

    ///// color changed ////
    dashboard: {
        color: "#66737F !important",
    },
    payment: {
        color: "#66737F !important",
    },
    employee: {
        color: "#66737F !important",
    },
    hspdispute: {
        color: "#66737F !important",
    },
    user: {
        color: "#66737F !important",
    },
    invoice: {
        color: "#66737F !important",
    },
    specialization: {
        color: "#66737F !important",
    },
    setting: {
        color: "#66737F !important",
    },

    content: {
        flexGrow: 1,
    },
}));

const Sidebar = (props, { isDisabled }) => {
    const settingTab = props.settingTab ? true : false
    const showHelpButton = props.showHelpButton;
    const showSidebar = props.showSidebar;
    const navigate = useNavigate();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    const [tab, setTab] = useState(window.location.pathname);
    const [data, setDate] = useState("");
    useEffect(() => {
        const providerInfo = tab;
        const regex = /^\/?provider_info/;
        const startsWithProviderInfo = regex.test(providerInfo);
        if (startsWithProviderInfo) {
            setDate("provider_info");
        } else {
            setDate(window.location.pathname);
        }
    }, [tab]);
    useEffect(() => {
        const employeeDetails_info = tab;
        const regex = /^\/?employeeDetails_info/;
        const startsWithemployeeDetails_info = regex.test(employeeDetails_info);
        if (startsWithemployeeDetails_info) {
            setDate("employeeDetails_info");
        } else {
            setDate(window.location.pathname);
        }
    }, [tab]);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    // console.log("window.location.href", tab);

    // for provider_info dashboardActive background color
    // we do that
    const location = useLocation();
    const { uid } = useParams();
    const { id } = useParams();

    return (
        <>
            <div className={classes.root}>
                <CssBaseline />
                {/* <AppBar position="fixed" className={classes.appBar}> */}
                <Toolbar>
                    {matches ? (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : (
                        <Typography variant="h6" noWrap>
                            {/* Header */}
                        </Typography>
                    )}
                </Toolbar>
                {/* </AppBar> */}
                <nav className={classes.drawer} aria-label="mailbox folders">
                    <Drawer
                        variant={matches ? "temporary" : "permanent"}
                        open={open}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <div className={classes.drawerContainer}>
                            <List>
                                <ListItem button>
                                    <div
                                        
                                        className="topMember">
                                        <img
                                            src={LogoIcon}
                                            alt="logo"
                                            onClick={() => navigate("/")}
                                        />
                                        {/* <img src={healthcoinlogo} alt="" /> */}
                                    </div>
                                </ListItem>
                                <Divider sx={{ mt: 1 }} style={{ borderColor: "#fff" }} />
                                <div style={{ marginTop: "14px", }}
                                    // className={classes.list}
                                >
                                    {showSidebar ? (
                                        <>
                                            <Box
                                                className={
                                                    tab === "/dashboard" ? classes.DashboardActive : null
                                                }
                                            >
                                                <ListItem className={classes.dashboard} button>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText primary="Dashboard" />
                                                </ListItem>
                                            </Box>

                                            <Box
                                                onClick={() => navigate("/members")}
                                                className={
                                                    (tab === "/members") || tab=="/"|| tab == `/employeeDetails_info/${id}` ? classes.DashboardActive : null
                                                }
                                            >
                                                <ListItem className={classes.members} button>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText
                                                        className={
                                                            ((data === "/members" || data=="/"|| data == "employeeDetails_info"))
                                                                // data === "/members || data == "Provider Info"
                                                                ? classes.active
                                                                : classes.specialization
                                                        }
                                                        // onClick={() => navigate("/members")}
                                                        primary="Members"
                                                    />
                                                </ListItem>
                                            </Box>

                                            <Box

                                                onClick={() => navigate("/invoices")}
                                                className={
                                                    tab === "/invoices" || tab === `/InvoiceMonthTable/${id}` ? classes.DashboardActive : null
                                                }
                                            >
                                                <ListItem className={classes.invoice} button>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText
                                                        className={
                                                            data === "/invoices" || data == "InvoiceMonthTable"
                                                                // tab === "/invoices"
                                                                ? classes.active
                                                                : classes.specialization
                                                        }
                                                        // onClick={() => navigate("/invoices")}
                                                        primary="Invoices"
                                                    />
                                                </ListItem>
                                            </Box>

                                            <Box
                                                onClick={() => navigate("/settings")}
                                                className={
                                                    (tab === "/settings") ? classes.DashboardActive : null
                                                }
                                            >
                                                <ListItem className={classes.setting} button>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText
                                                        className={
                                                            (tab === "/settings")
                                                                ? classes.active
                                                                : classes.specialization
                                                        }
                                                        // onClick={() => navigate("/settings")}
                                                        button
                                                        primary="Settings"
                                                    />
                                                </ListItem>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Box
                                                className={
                                                    tab === "/dashboard" ? classes.DashboardActive : null
                                                }
                                            >
                                                <ListItem className={classes.dashboard} button>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText primary="Dashboard" />
                                                </ListItem>
                                            </Box>

                                            <Box className={tab == "/providers" || tab == `/provider_info/${uid}` ? classes.DashboardActive : null}>
                                                <ListItem className={(data === '/providers' || data == '/provider_info/${uid}') ? classes.active : classes.specialization} onClick={() => navigate('/providers')} button>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText primary="Providers" />
                                                </ListItem>
                                            </Box>

                                            {/*  */}
                                            <Box className={tab == "/payment_request" ? classes.DashboardActive : null}>
                                                <ListItem
                                                    className={
                                                        tab === "/payment_request"
                                                            ? classes.active
                                                            : classes.specialization
                                                    }
                                                    // onClick={() => navigate("/payment_request")}
                                                    button
                                                >

                                                    <ListItemIcon></ListItemIcon>

                                                    <ListItemText primary="Payment Request" />
                                                </ListItem>
                                            </Box>
                                            {/*  */}
                                            <Box className={tab == "/employers" || tab == `/employers_Info/${id}` || tab == `/invoicesDetails` ? classes.DashboardActive : null}>
                                                <ListItem className={classes.employee} button>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText
                                                        className={
                                                            data === "/employers" || data == "employers_Info" || data == "invoicesDetails"
                                                                ? classes.active
                                                                : classes.specialization
                                                        }
                                                        onClick={() => navigate("/employers")}
                                                        button
                                                        primary="Employers" />
                                                </ListItem>
                                            </Box>
                                            {/*  */}
                                            <Box className={tab == "/hospital_disputes" ? classes.DashboardActive : null}>
                                                <ListItem
                                                    className={
                                                        tab === "/hospital_disputes"
                                                            ? classes.active
                                                            : classes.specialization
                                                    }
                                                    // onClick={() => navigate("/hospital_disputes")}
                                                    button
                                                >
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText primary="Hospitals Disputes" />
                                                </ListItem>
                                            </Box>

                                            <Box className={tab == "/user" ? classes.DashboardActive : null}>
                                                <ListItem className={classes.user} button>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText primary="User" />
                                                </ListItem>
                                            </Box>

                                            <Box
                                                className={
                                                    tab === "/invoices" ? classes.DashboardActive : null
                                                }
                                            >
                                                <ListItem className={classes.invoice} button>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText primary="Invoices" />
                                                </ListItem>
                                            </Box>

                                            <Box className={tab == "/specializations" ? classes.DashboardActive : null}>
                                                <ListItem className={classes.specialization} button>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText primary="Specializations" />
                                                </ListItem>
                                            </Box>

                                            <Box
                                                className={
                                                    tab === "/setting" ? classes.DashboardActive : null
                                                }
                                            >
                                                <ListItem
                                                    className={
                                                        (tab === "/setting")
                                                            ? classes.active
                                                            : classes.specialization
                                                    }
                                                    onClick={() => navigate("/setting")}
                                                    button
                                                >
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText primary="Settings" />
                                                </ListItem>
                                            </Box>
                                        </>
                                    )}

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            gap: "12px",
                                            marginTop: "100px",
                                            alignContent: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignContent: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {showHelpButton && <ContactSupport />}
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignContent: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    color: 'white',
                                                    background: '#4157FF',
                                                    borderRadius: '56px' ,
                                                    border: 'none',
                                                    width: '203px',
                                                    height: '50px',
                                                    cursor: 'pointer',
                                                    fontWeight: 700 ,
                                                    fontFamily: ' DM Sans',
                                                }}
                                                className={`${isDisabled ? "disabled" : ""
                                                    }`}
                                                onClick={() => dispatch(logoutUser())}
                                            >
                                                Logout
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </List>
                        </div>
                    </Drawer>
                </nav>
            </div>
        </>
    );
};


export default Sidebar;
