import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Divider,
	Box,
} from '@mui/material';
import { toast, Toaster } from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
	encryptData,
	decryptData,
} from '../../../redux/incript&decript/cryptoUtils';
// import PayConf from "../Payment Confirmed/PayConf";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../../redux/store';
import {getmembersInEnterprise} from '../../../redux/action/user';
const useStyles = makeStyles({});

const MemberMakePayment = ({
	total_per_employee_amount,
	totalUser,
	grandTotal,
	total_due,
	selectedEmails,
	handleOpen,
	handleClose,
	show,
	handlePaymentCOnfirmedOpen,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// modal open

	const [openPayConf, setPayConf] = useState(false);
	const [userSignature, setUserSignature] = useState('');
	const [perMember, setPerMember] = useState(total_per_employee_amount);
	const [totalDue, setTotalDue] = useState(total_due);
	// const { countUser, totalUser, recordUser, isObject, csvFile } = useSelector(state => state.user)
	useEffect(() => {
		setPerMember(total_per_employee_amount);
		setTotalDue(total_due);
	}, [total_per_employee_amount, total_due]);
	const handleOpenPayConf = () => {
		setPayConf(true);
	};

	const handleClosePayConf = () => {
		setPayConf(false);
	};
	//

	const [initialValues, setInitiateValue] = useState({
		monthly_payment: perMember ? perMember : '0',
		total_due: totalDue ? perMember : '0',
		signature: '',
	});
	const handleButtonClick = (setFieldValue, submitForm, errors) => {
		setFieldValue('published');
		submitForm();
		// console.log("published123", errors);
	};
	const validationSchema = Yup.object().shape({
		monthly_payment: Yup.string().required('*Monthly payment is required'),
		total_due: Yup.string().required('*Total due is required'),
	});
	const formSubmit = values => {
		addMember();
	};
	const submitHandler = e => {
		e.preventDefault();
	};
	const addMember = async () => {
		try {
			const token = `Bearer ${localStorage.getItem('token')}`;

			// Check if selectedEmails is an array or a single email
			if (Array.isArray(selectedEmails) && selectedEmails.length === 1) {
				// Single email
				
				const encrypted_data = encryptData({ email: selectedEmails[0],type: 'singleUser', });
				const data = await axios.post(`${server}/payment_for_single_multiple_patinet`,
					{ encrypted_data: encrypted_data },
					{
						headers: {
							Authorization: token,
							'Content-Type': 'application/json',
						},
					}
				);
				if(data.status === 200){
					const decryptedData = decryptData(data?.data.encrypted_data);
					if(decryptedData.success === false){
						toast.success(decryptedData.message);
						handleClose();
						return
					}
					toast.success(`${decryptedData.message} ${decryptedData.paid_users}`);

				}
			} else {
				
				// Multiple emails
				const encrypted_data = encryptData({ email: selectedEmails,type: 'multiUsers', });
				const data = await axios.post(
					`${server}/payment_for_single_multiple_patinet`,
					{ encrypted_data: encrypted_data },
					{
						headers: {
							Authorization: token,
							'Content-Type': 'application/json',
						},
					}
				);
				if(data.status === 200){
				const decryptedData = decryptData(data?.data.encrypted_data);
					toast.success(decryptedData.message);
					if (decryptedData?.unpaid_users && decryptedData?.unpaid_users.length > 0) {
						const Unpaid_user_message = decryptedData?.unpaid_users
						toast.error(Unpaid_user_message.map((message, index) => `${index + 1}. ${message.replace(/\. /g, '.\n')}`).join('\n'));
					}
					if (decryptedData?.paid_users && decryptedData?.paid_users.length > 0) {
						const paid_user_message = decryptedData?.paid_users
						toast.success(paid_user_message.map((message, index) => `${index + 1}. ${message.replace(/\. /g, '.\n')}`).join('\n'));
					}
				}
			}
			
			handleClose();
			// handlePaymentCOnfirmedOpen();
			dispatch(getmembersInEnterprise());
			navigate('/members');
		} catch (error) {
			const decryptedErrorMessage = decryptData(error?.response?.data?.encrypted_data);
			toast.error(decryptedErrorMessage.message);
			handleClose();

			navigate('/members');
		}
	};

	return (
		<div>
			<Toaster />
			{/* <PayConf openPayConf={openPayConf} handleOpenPayConf={handleOpenPayConf} handleClosePayConf={handleClosePayConf} /> */}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					// console.log("Values About fee", values);
					formSubmit(values, setSubmitting, resetForm);
				}}
			>
				{({
					errors,
					touched,
					values,
					handleChange,
					setFieldValue,
					submitForm,
				}) => (
					<Form onSubmit={submitHandler}>
						<Dialog open={show} onClose={handleClose} maxWidth='md'>
							{' '}
							{/* maxWidth="sm" ,md,lg */}
							<DialogTitle className={classes.buttonAddnew}>
								<span
									style={{
										fontSize: '24px ',
										fontWeight: '500 ',
										fontFamily: 'DM Sans',
										color: '#32325D',
									}}
								>
									Make Payment
								</span>
								<IconButton
									onClick={handleClose}
									style={{ position: 'absolute', top: 0, right: 0 }}
								>
									<Box
										style={{
											background: '#4157FF',
											width: '42px',
											height: '42px',
											fontSize: '11px ',
											boxShadow: 'none ',
											borderRadius: '24px',
										}}
									>
										<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
									</Box>
								</IconButton>
							</DialogTitle>
							<DialogContent
								sx={{ marginTop: '10px' }}
								style={{ minWidth: '550px', height: '480px' }}
							>
								<Box
									sx={{ display: 'flex', gap: '22px', flexDirection: 'column' }}
								>
									<Box>
										<Typography
											variant='h6'
											sx={{
												fontSize: '14px ',
												fontWeight: '700',
												color: '#525F7F',
											}}
											gutterBottom
										>
											Total Number of Employee
										</Typography>

										<TextField
											margin='dense'
											// disabled
											// placeholder="$"
											fullWidth
											// onChange={handleChange}
											value={totalUser}
											InputProps={{
												readOnly: true,
												style: { color: 'black' },
											}}
											sx={{
												boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
												width: '489px',
											}}
										/>
										{errors.monthly_payment && touched.monthly_payment ? (
											<div className={`red mb-1 fs15`}>
												{errors.monthly_payment}
											</div>
										) : null}
									</Box>

									<Box>
										<Typography
											variant='h6'
											sx={{
												fontSize: '14px ',
												fontWeight: '700',
												color: '#525F7F',
											}}
											gutterBottom
										>
											Total Due
										</Typography>
										<TextField
											// disabled
											margin='dense'
											// placeholder="$16,200"
											// onChange={handleChange}
											value={grandTotal}
											fullWidth
											InputProps={{
												readOnly: true,
												style: { color: 'black' },
											}}
											sx={{
												boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
												width: '489px',
											}}
										/>
										{errors.total_due && touched.total_due ? (
											<div className={`red mb-1 fs15`}>{errors.total_due}</div>
										) : null}
									</Box>

									<Box>
										<Typography
											variant='h6'
											inputProps={{ maxLength: 15 }}
											sx={{
												fontSize: '14px ',
												fontWeight: '700',
												color: '#525F7F',
											}}
											gutterBottom
										>
											Signature
										</Typography>
										<TextField
											margin='dense'
											placeholder='By typing your name you authorize today’s payment.'
											fullWidth
											inputProps={{ maxLength: 70 }}
											onChange={e => setUserSignature(e.target.value)}
											defaultValue={userSignature}
											InputProps={
												{
													// style: {height:'31px'}
												}
											}
											sx={{
												boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
												width: '489px',
											}}
										/>
										{errors.signature && touched.signature ? (
											<div className={`red mb-1 fs15`}>{errors.signature}</div>
										) : null}
									</Box>
								</Box>
							</DialogContent>
							<DialogActions
								sx={{
									display: 'flex',
									justifyContent: 'center',
									margin: '0px 0px 36px',
								}}
							>
								<Button
									// onClick={handleOpenPayConf}
									style={{
										height: '46px ',
										width: '169px ',
										borderRadius: '24px ',
										background: '#4157FF ',
										textTransform: 'none',
										fontSize: '15px ',
										fontWeight: 700,
									}}
									variant='contained'
									color='primary'
									onClick={() =>
										handleButtonClick(setFieldValue, submitForm, errors)
									}
								>
									Submit Payment
								</Button>
							</DialogActions>
						</Dialog>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default MemberMakePayment;
