import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import InitiateBiling from "../Invoices/InitiateBiling";
import axios from "axios";
import { server } from "../../../redux/store";
import { toast, Toaster } from "react-hot-toast";
import Settings from "../Setting/Settings";
import { userRecords, getmembersInEnterprise, addSingleMember,continueEnterpriseAccount } from "../../../redux/action/user";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
const useStyles = makeStyles({
  buttonAddnew: {
    fontSize: "24px",
    fontWeight: "600 !important",
  },
});

const Users = ({ handleOpen, handleClose, open, showMembers, setShowMembers, step, setStep, currentPage, query,update,setUpdate }) => {
  const classes = useStyles();
  const { countUser, allMembersRecord, recordUser, isObject, csvFile } = useSelector(state => state.user)
  const [openBilling, setOpenBilling] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const { user } = useSelector(state => state?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState({
    label: "",
    value: {},
  });
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    department: "",
    dateofbirth: "",
    address: "",
  };

  const singleMemberAdd = async (email) => {
    try {
      const token = `Bearer ${localStorage.getItem("token")}`;
      let { data } = await axios.post(
        `${server}/single_member_email_check`,
        {
          email: email,
        },
        {
          headers: { Authorization: token, "Content-Type": "application/json" },
        }
      );
      // console.log(data?.data)
      handleClose();
      setStep(2)

    }
    catch (error) {
      // alert(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }

  }

  

  const formSubmit = async (values) => {
  const locationRecord = { ...values, address: value.label, longitude, latitude }
    dispatch(userRecords(locationRecord))
    const recordUser = { ...values, address: value.label, longitude, latitude };
    await dispatch(addSingleMember(recordUser))
    await  dispatch(getmembersInEnterprise(query, currentPage));
    setUpdate(!update);
    await dispatch(continueEnterpriseAccount(user?.email, 1))
    handleClose()

  };
  useEffect(() => {
    dispatch(getmembersInEnterprise());
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const handlePlaceSelect = async (value) => {
    try {
      setValue(value);
      const results = await geocodeByPlaceId(value?.value?.place_id);
      if (results && results?.length > 0) {
        const { lat, lng } = await getLatLng(results[0]);
        setLatitude(lat);
        setLongitude(lng);
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  };
  const handleButtonClick = (e, setFieldValue, submitForm, errors) => {
    e.preventDefault()
    setFieldValue("published");
    submitForm();
  };

  const validationSchema = Yup.object().shape({
    // first_name: Yup.string()
    //   .required("*first name is required")
    //   .min(3, "must be at least 3 characters"),
    // last_name: Yup.string()
    //   .required("*last name is required")
    //   .min(3, "must be at least 3 characters"),
    email: Yup.string()
      .required("*email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "*please enter a valid email address"
      ),
    // department: Yup.string().required("*department is required"),
    // dateofbirth: Yup.string()
    //   .required("*date of birth is required")
    //   .test("valid-date", "*Invalid date", (value) => {
    //     if (!value) return false; // Handle empty value
    //     const date = new Date(value);
    //     const currentDate = new Date();
    //     const year = date.getFullYear();
    //     const isBeforeCurrentDate = date < currentDate;
    //     const isSameAsCurrentDate = date.toDateString() === currentDate.toDateString();

    //     return (
    //       date instanceof Date &&
    //       !isNaN(date) &&
    //       isBeforeCurrentDate &&
    //       year >= 1900 &&
    //       !isSameAsCurrentDate
    //     );
    //   }),
  });
  const clearFormValues = (setFieldValue) => {
    setFieldValue("first_name", "");
    setFieldValue("last_name", "");
    setFieldValue("email", "");
    setFieldValue("department", "");
    setFieldValue("dateofbirth", "");
    setFieldValue("address", "");
  };
  const handleCloseBilling = () => {
    setOpenBilling(false);
    handleClose()
    setStep(1)

  };
  const nextBillingStep = () => {
    setOpenBilling(false);
    setStep(3)
    setShowMembers(false)

  };
  const handleOpenBilling = () => {
    setOpenBilling(true);
  };

  return (
    <div>

      {/* <Button variant="outlined" color="primary" onClick={handleOpen}>
                    Open Dialog
                </Button> */}
      {step == 1 &&
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // console.log("Values About fee", values);
            formSubmit(values, setSubmitting, resetForm);
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            setFieldValue,
            submitForm,
          }) => (
            <Form onSubmit={submitHandler}>
              <Dialog open={open} onClose={() => { handleClose(); clearFormValues(setFieldValue) }} maxWidth="md">
                {" "}
                {/* maxWidth="sm" ,md,lg */}
                <DialogTitle className={classes.buttonAddnew}>
                  <span style={{ fontSize: '24px', fontWeight: '500' }}>Add new user</span>
                  <IconButton
                    onClick={() => {
                      handleClose();
                      clearFormValues(setFieldValue)
                    }}
                    style={{ position: "absolute", top: 0, right: 0 }}
                  >
                    <Box
                      style={{
                        background: "#4157FF",
                        width: "42px",
                        height: "42px",
                        fontSize: "11px",
                        boxShadow: "none",
                        borderRadius: "24px",
                      }}

                    >
                      <CloseIcon sx={{ margin: "8px 5px", color: "white" }} />
                    </Box>
                  </IconButton>
                </DialogTitle>
                <DialogContent
                  sx={{ marginTop: "10px" }}
                  style={{ minWidth: "480px" }}
                >
                  <Box
                    sx={{ display: "flex", gap: "12px", flexDirection: "column" }}
                  >
                    {/* <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "14px ", fontWeight: "700" }}
                        gutterBottom
                      >
                        First Name
                      </Typography>
                      <TextField
                        margin="dense"
                        placeholder="First Name"
                        onChange={handleChange}
                        value={values.first_name}
                        name='first_name'
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        InputProps={{
                          style: { height: "46px" },
                        }}
                        sx={{ width: "489px" }}
                      />{" "}
                      {errors.first_name && touched.first_name ? (
                        <div className={`red mb-1 fs15`}>{errors.first_name}</div>
                      ) : null}
                    </Box>

                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "14px ", fontWeight: "700" }}
                        gutterBottom
                      >
                        Last Name
                      </Typography>
                      <TextField
                        margin="dense"
                        placeholder="Last Name"
                        onChange={handleChange}
                        inputProps={{ maxLength: 50 }}
                        value={values.last_name}
                        name='last_name'
                        fullWidth
                        InputProps={{
                          style: { height: "46px" },
                        }}
                        sx={{ width: "489px" }}
                      />
                      {errors.last_name && touched.last_name ? (
                        <div className={`red mb-1 fs15`}>{errors.last_name}</div>
                      ) : null}
                    </Box> */}

                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "14px ", fontWeight: "700" }}
                        gutterBottom
                      >
                        Email
                      </Typography>
                      <TextField
                        margin="dense"
                        placeholder="Email"
                        onChange={handleChange}
                        // inputProps={{ maxLength: 15 }}
                        value={values.email}
                        name='email'
                        fullWidth
                        InputProps={{
                          style: { height: "46px" },
                        }}
                        sx={{ width: "489px" }}
                      />
                      {errors.email && touched.email ? (
                        <div className={`red mb-1 fs15`}>{errors.email}</div>
                      ) : null}
                    </Box>
                    {/* <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "14px ", fontWeight: "700" }}
                        gutterBottom
                      >
                        Address
                      </Typography>
                      <GooglePlacesAutocomplete
                        style={{ overflow: "auto", overflowY: 'auto' }}
                        selectProps={{
                          value,
                          onChange: (value) => {
                            handlePlaceSelect(value);
                          },
                        }}
                        apiKey={process.env.REACT_APP_API_KEY}
                      />
                      {errors.address && touched.address ? (
                        <div className={`red mb-1 fs15`}>{errors.address}</div>
                      ) : null}
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "14px ", fontWeight: "700" }}
                        gutterBottom
                      >
                        Date of Birth
                      </Typography>
                      <TextField
                        margin="dense"
                        type="date"
                        value={values.dateofbirth}
                        onChange={handleChange}
                        name='dateofbirth'
                        fullWidth
                        InputProps={{
                          style: { height: "46px" },
                        }}
                        sx={{ width: "489px" }}
                      />
                      {errors.dateofbirth && touched.dateofbirth ? (
                        <div className={`red mb-1 fs15`}>{errors.dateofbirth}</div>
                      ) : null}

                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "14px ", fontWeight: "700" }}
                        gutterBottom
                      >
                        Department
                      </Typography>
                      <TextField
                        margin="dense"
                        placeholder="Department"
                        onChange={handleChange}
                        inputProps={{ maxLength: 200 }}
                        value={values.department}
                        name='department'
                        fullWidth
                        InputProps={{
                          style: {
                            height: "46px",
                          },
                        }}
                        sx={{ width: "489px" }}
                      />
                      {errors.department && touched.department ? (
                        <div className={`red mb-1 fs15`}>{errors.department}</div>
                      ) : null}
                    </Box> */}

                  </Box>
                </DialogContent>
                <DialogActions
                  sx={{
                    borderRadius: "0 0 20px 20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ margin: "32px" }}>
                    <Button
                      style={{
                        height: "48px ",
                        width: "195px ",
                        borderRadius: "24px ",
                        background: "#4157FF ",
                        fontSize: "15px  ",
                        color: "white  ",
                        fontWeight: '700 ',
                      }}
                      variant="contained"
                      color="primary"

                      onClick={(e) =>
                        handleButtonClick(e, setFieldValue, submitForm, errors)
                      }
                    >
                      SEND CREDENTIALS
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      }
      {
        step == 2 && <InitiateBiling setStep={setStep} handleClose={handleCloseBilling} open={openBilling} setOpen={setOpenBilling} handleOpen={handleOpenBilling} nextBillingStep={nextBillingStep} />
      }
      <Toaster />
    </div>
  );
};

export default Users;
