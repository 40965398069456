import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TermAndCondition from './TermAndConditions';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Tooltip } from '@mui/material';
import {
	getTotalAmount,
	patientPayoutSubcribtion,
} from '../../redux/action/user';

const MemberShip = ({ step, setStep }) => {
	const removeBorder = {
		border: 'none',
	};
	const { familyMemberAmount } = useSelector(state => state.user);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getTotalAmount());
	}, []);
	// console.log("familyMemberAmountfamilyMemberAmount", familyMemberAmount);
	const [openPhoneVerifiedModal, setOpenPhoneVerifiedModal] =
		React.useState(true); // OTP Verification Modal
	const [open, setOpen] = React.useState(false); // success Phone Verification Modal
	const handleOpen = () => setOpen(true);
	const handleOpenPhoneModal = () => setOpenPhoneVerifiedModal(true);
	const handleClosePhoneModal = () => setOpenPhoneVerifiedModal(false);

	const proceedPayment = () => {
		dispatch(patientPayoutSubcribtion(step, setStep));
	};
	useEffect(() => {
		if (step == 9) {
			handleOpenPhoneModal();
		}
	}, []);
	return (
		<div>
			<Container>
				<Modal
					//  className={classes.modal}

					open={openPhoneVerifiedModal}
					// onClose={handleClosePhoneModal}
					aria-labelledby='modal-title'
					aria-describedby='modal-description'
					BackdropProps={{
						timeout: 500,
						style: { backdropFilter: 'blur(2px)' }, // Apply the blur effect
					}}
				>
					<Box style={removeBorder}>
						<Box
							sx={{
								position: 'absolute',
								borderRadius: '1rem',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: '335px',
								height: '500px',
								margin: 'auto',
								bgcolor: 'background.paper',
								boxShadow: 24,
								p: 4,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: 'auto',
							}}
						>
							<Box>
								<Box style={{ margin: 'auto', width: '100%' }}>
									<p
										style={{
											fontSize: '22px',
											fontWeight: 800,
											textAlign: 'center',
										}}
									>
										{' '}
										Membership
									</p>
									<br />
								</Box>
								{/* <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Transaction ID</p>
                  <p>asdfgh2345678df</p>
                </Box> */}
								{/* <hr /> */}
								{/* <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Total Amount</p>
                  <p>$450</p>
                </Box> */}
								<hr />
								{/* <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Service Fee</p>
                  <p>$13</p>
                </Box> */}

								<Box
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<p>Total Amount Due</p>
									<p>${familyMemberAmount}</p>
								</Box>
								<hr />

								<p style={{ textAlign: 'center' }}>
									By clicking Proceed you are accepting our <br />{' '}
									<TermAndCondition />
									You are agreeing to a <br />
									recurring monthly subscription that you may <br />
									cancel at any time
								</p>
								<br />
								<Box style={{ width: '100%', margin: 'auto' }}>
									<Button
										style={{
											width: '100%',
											borderRadius: '20px',
											height: '45px',
										}}
										variant='contained'
										color='primary'
										onClick={() => proceedPayment()}
									>
										Proceed
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				</Modal>
			</Container>
		</div>
	);
};

export default MemberShip;
