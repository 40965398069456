import { createReducer } from '@reduxjs/toolkit';

const userReducer = createReducer(
	{},
	{
		logingRequest: state => {
			state.loading = true;
			state.isAuthenticated = false;
		},
		loginSuccess: (state, action) => {
			state.loading = false;
			state.message = action.message;
			state.isAuthenticated = true;
			state.user = action.payload;
			state.token = action.token;
			state.bankAccountDetails = action?.provider_bank_details;
		},
		tokenSet: (state, action) => {
			state.token = action.token;
		},
		loginFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},

		// get user profile

		loadProfileRequest: state => {
			state.loading = true;
		},
		loadProfileSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.user = action.payload;
			state.user = action.user;
			state.bankAccountDetails = action.bankAccountDetails;
		},
		loadProfileFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},

		// get all Provider

		loadProviderRequest: state => {
			state.loading = true;
		},
		loadProviderSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.providers = action.payload;
			state.providerPages = action.page;
		},
		loadProviderFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},
		// get all Provider

		loadProviderRequest: state => {
			state.loading = true;
		},
		loadAllPaymentSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allAdminPayments = action.payload;
		},
		loadAllPaymentFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},
		// get all Employees

		getAllEmployeeRequest: state => {
			state.loading = true;
		},
		getAllEmployeeSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allEmployes = action.payload;
			state.employeePage = action.page;
		},
		getAllEmployeeFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},
		// get all Employees

		getAllEmployeeRequest: state => {
			state.loading = true;
		},
		getAllDisputesSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allDisputes = action.payload;
		},
		getAllDisputesFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},
		// get all payment Logs

		getAllLogs: state => {
			state.loading = true;
		},
		getAllLogsSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.paymentLogs = action.payload;
		},
		getAllLogsFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},
		// get all payment Requests

		getAllPaymentRequest: state => {
			state.loading = true;
		},
		getAllPaymentRequestSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allAdminPayments = action.payload;
		},
		getAllPaymentRequestFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},

		// Logout

		logoutRequest: (state, action) => {
			state.loading = true;
		},
		logoutSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.message = action.payload;
		},
		logoutFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// get all Provider

		verifyProviderRequest: state => {
			state.loading = true;
		},
		verifyProviderSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		verifyProviderFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		/// change Password
		updateAdminPasswordRequest: state => {
			state.loading = true;
		},
		updateAdminPasswordSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		updateAdminPasswordFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// signup employere

		signupRequest: state => {
			state.loading = true;
		},
		signupSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
			state.email = action.employerEmail;
			state.user = action.payload;
		},
		signupFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},

		//  Verify Email Code

		verifyRequest: state => {
			state.loading = true;
		},
		verifySuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
			state.user = action.user;
		},
		verifyFail: (state, action) => {
			state.loading = false;
			state.message = false;
			state.error = action.payload;
		},
		continueRequest: state => {
			state.loading = true;
		},

		// continue
		continueSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
			state.user = action.user;
		},

		// 2nd work
		// signup employere

		signupEmployerRequest: state => {
			state.loading = true;
		},
		signupSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
			state.email = action.employerEmail;
			state.user = action.payload;
		},
		signupFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},

		getAllEmployerRequest: state => {
			state.loading = true;
		},
		getAllEmployerSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allEnterprise = action.payload;
		},
		getAllEmployerFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		getSingleEmployerRequest: state => {
			state.loading = true;
		},
		getSingleEmployerSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.enterpriseUser = action.payload;
		},
		getSingleEmployerFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		addEmployerRequest: state => {
			state.loading = true;
		},
		addEmployerSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		addEmployerFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		//  singup employee

		signupEnterpriseRequest: state => {
			state.loading = true;
		},
		signupEnterpriseSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		signupEnterpriseFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// Enterprise Account Approal

		enterpriseAccountApproalRequest: state => {
			state.loading = true;
		},
		enterpriseAccountApproalSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		enterpriseAccountApproalFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// Verify Account

		verifyCodeRequest: state => {
			state.loading = true;
		},
		verifyCodeSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		verifyCodeFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// Resend Verify Code Account

		resendCodeRequest: state => {
			state.loading = true;
		},
		resendCodeSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		resendCodeFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// Continue enterprise account

		enterpriseContinueRequest: state => {
			state.loading = true;
		},
		enterpriseContinueSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		enterpriseContinueFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// Single Member add enterprise account

		singleMemberRequest: state => {
			state.loading = true;
		},
		singleMemberSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		singleMemberFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// update enterprise setting

		settingRequestRequest: state => {
			state.loading = true;
		},
		settingSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.user = action.payload;
			state.message = action.message;
		},
		settingFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// update enterprise setting

		settingRequestRequest: state => {
			state.loading = true;
		},
		settingSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.user = action.payload;
			state.message = action.message;
		},
		settingFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// update billing info routing etc

		getInvoiceByIDRequest: (state, action) => {
			state.loading = true;
			state.isAuthenticated = true;
		},

		// singleMemberRecordSuccess
		singleMemberRecordSuccess: (state, action) => {
			state.recordUser = action.payload;
			state.countUser = 1;
			state.isObject = true;
		},

		getInvoiceByIDSuccess: (state, action) => {
			state.loading = true;
			state.isAuthenticated = true;
			state.singleInvoiceData = action.payload;
		},
		bulkMemberRecordSuccess: (state, action) => {
			state.countUser = action.payload;
			state.isObject = false;
			state.csvFile = action?.file;
		},

		// update payment status

		updateEnterprisePaymentStatusRequest: state => {
			state.loading = true;
		},
		updateEnterprisePaymentStatusSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		updateEnterprisePaymentStatusFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// update payment status

		updateProviderProfileByAdminRequest: state => {
			state.loading = true;
		},
		updateProviderProfileByAdminSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		updateProviderProfileByAdminFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// admin status Off handling

		adminStatusOffRequest: state => {
			state.loading = true;
		},
		adminStatusOffSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
			// state.allAdmins = action.payload;
		},
		adminStatusOffFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// admin status Onn handling

		adminStatusOnRequest: state => {
			state.loading = true;
		},
		adminStatusOnSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
			// state.allAdmins = action.payload;
		},
		adminStatusOnFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// admin status Onn handling

		getAllAdminRequest: state => {
			state.user_loader = true;
		},
		getAllAdminSuccess: (state, action) => {
			state.user_loader = false;
			state.isAuthenticated = true;
			state.allAdmins = action.payload;
		},
		getAllAdminFail: (state, action) => {
			state.user_loader = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// admin status Onn handling

		getAllAdminInvoicesRequest: state => {
			state.loadingForallAdminInvoice = true;
		},
		getAllAdminInvoicesSuccess: (state, action) => {
			state.loadingForallAdminInvoice = false;
			state.isAuthenticated = true;
			state.allAdminsInvoices = action.payload;
		},
		getAllAdminInvoicesFail: (state, action) => {
			state.loadingForallAdminInvoice = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// admin status Onn handling

		getAdminInvicesDetailedRequest: state => {
			state.loadingForInVoiceDetailed = true;
			// state.adminInvoiceDetailed=null
		},
		getAdminInvicesDetailedSuccess: (state, action) => {
			state.loadingForInVoiceDetailed = false;
			state.isAuthenticated = true;
			state.adminInvoiceDetailed = action.payload;
			state.message = action.message;
		},
		getAdminInvicesDetailedFail: (state, action) => {
			state.loadingForInVoiceDetailed = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// admin status Onn handling

		adminDeleteRequest: state => {
			state.loading = true;
			// state.adminInvoiceDetailed=null
		},
		adminDeleteSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
			state.allAdmins = action.payload;
		},
		adminDeleteFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// get all patients for filter in logs

		getAllPatentForFilterSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allPatients = action.payload;
		},
		getAllPatentForFilterFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// get all admin for filter in logs

		getAllAdminForFilterSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allSuperAdmins = action.payload;
		},
		getAllAdminForFilterFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// 2nd work
		getInvoiceByIDFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// Monthly Expenses
		getMonthlyInvoiceSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.monthlyExpenses = action.payload;
		},
		getAllAdminForFilterFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		//  Get total member
		getAllUsersSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.EnrolledPendingUsers = action.payload;
		},
		getAllUsersFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// AutoDebitApi

		AutoDebitAPISuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.auto_debit = action.payload;
		},
		AutoDebitAPIFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.err = action.payload;
		},

		// admin_send_email_to_enterprise
		sendEmailToEnterpriseSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		sendEmailToEnterpriseFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.err = action.payload;
		},
		//
		loadDisputePaymentRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		loadDisputePaymentSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.disputePaymentDetail = action.payload;
		},
		loadDisputePaymentFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		//
		getAllPaymentRequestDetailRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		getAllPaymentRequestDetailSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.PaymentRequestDetail = action.payload;
		},
		getAllPaymentRequestDetailFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		//
		getInvoiceByEnterpriseRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		getInvoiceByEnterpriseSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.enterpriseInvoices = action.payload;
		},
		getInvoiceByEnterpriseFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		//
		adminRequestForDisputesRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		adminRequestForDisputesSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.success = action.payload;
			state.message = action.message;
		},
		adminRequestForDisputesFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		adminUpdateProviderDataRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		adminUpdateProviderDataSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.updateProviderImage = action.payload;
			state.message = action.message;
		},
		adminUpdateProviderDataFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		getUserDetailInProvierRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		getUserDetailInProvierSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.getProviderUserDetail = action.payload;
		},
		getUserDetailInProvierFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		getProviderTransactionRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		getProviderTransactionSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.getProviderTransactionRecord = action.payload;
		},
		getProviderTransactionFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		/////////////////////////////////////////////////////////////////////////////

		//////////      Enterprise State

		// All Invoices
		enterpriseInvoicesRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		enterpriseInvoicesSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.enterpriseInvoicesRecord = action.payload;
		},
		enterpriseInvoicesFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		//  All Members

		getAllMemberRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		getAllMemberSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allMembersRecord = action.payload;
			state.totalMemberPages = action.totalPages;
		},
		getAllMemberFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			// state.error = action.payload;
		},

		// addSingleMember
		addSingleMemberRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		addSingleMemberSuccess: (state, action) => {
			state.loading = true;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		addSingleMemberFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// addBulkMembers

		addBulkMembersSuccess: (state, action) => {
			state.loading = true;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		addBulkMembersFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		//  Delete Member By Enterprise

		deleteMemberByEnterpriseRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		deleteMemberByEnterpriseSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		deleteMemberByEnterpriseFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		//  Total Charges In Enterprise

		totalChargesEnterpriseRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		totalChargesEnterpriseSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.totalAmountEnterprise = action.payload;
		},
		totalChargesEnterpriseFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		//  Total Charges in Setting in Enterprise
		totalChargesInSettingEnterpriseRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		totalChargesInSettingEnterpriseSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.totalAmountInSettingEnterprise = action.payload;
		},
		totalChargesInSettingEnterpriseFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		//  Total Charges in Setting in Enterprise
		billingInfoUpdateRequest: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.errorBillingInfo = null;
			state.isSuccess = true;
			state.error = action.payload;
		},
		billingInfoUpdateSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.falseMessage = '';
			state.message = action.message;
			// state.isSuccess = true;
			state.user.is_stripe_configured = 1;
			state.UpdateUserAccount = action.payload;
		},
		clearBillingState: state => {
			state.UpdateUserAccount = {};
		},
		billingInfoUpdateSuccessfalse: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.isSuccess = false;
			state.falseMessage = action.payload;
		},
		billingInfoUpdateFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		//  Get All Services
		getAllServicesSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.getAllServicesRecord = action.payload;
		},
		getAllServicesFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		//  Get All Speciality
		getAllSpecilizationSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.getAllSpecilizationRecord = action.payload;
		},
		getAllSpecilizationFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		//  Get All Out Of Network
		getAllOutOfNetworkSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.getAllOutOfNetworkRecord = action.payload;
		},
		getAllOutOfNetworkFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// add Out Of Network
		addOutOfNetworkSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
			state.isOutNetworkAdded = true;
		},
		addOutOfNetworkFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action?.payload;
			state.isOutNetworkAdded = false;
		},
		// add Out Of Network
		getDetailOutOfNetworkSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.getDetailOutOfNetworkRecord = action.payload;
		},
		getDetailOutOfNetworkFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// resetpassword
		GetCodeRequestSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
			state.error = null;
		},
		GetCodeRequestFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// selfPayPatient
		getSelfPayPatientSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allSelfPayPatient = action.payload;
		},
		getSelfPayPatientFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
		},

		// / SlefPayPatientDetails

		// getSelfPayPatientDetailsRequest: (state) => {
		//   state.loading = true;
		//   state.isAuthenticated = true;
		// },
		getSelfPayPatientDetailsSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.PatientDetail = action.payload;
		},
		getSelfPayPatientDetailslFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		///adminChangePatientPassword
		updateChangePatientPassword: state => {
			state.loading = true;
		},
		updateChangePatientPasswordSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		updateChangePatientPasswordFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// SendReminder
		updateSendReminder: state => {
			state.loading = true;
		},
		SendReminderSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		SendReminderFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// resendLoginCredentials
		resendLoginCredentials: state => {
			state.loading = true;
		},
		resendLoginCredentialsSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		resendLoginCredentialsFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// UpdatePatientStatus
		UpdatePatientStatusRequest: state => {
			state.loading = true;
		},
		UpdatePatientStatusSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
			state.updatePatientDate = action.payload;
		},
		UpdatePatientStatusFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// Emergency Services
		getEmergencyServicesSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.getEmergencyServicesData = action.payload;
		},
		getEmergencyServicesFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// getEmergencyCaseDetails
		EmergencyCaseDetailsRequest: state => {
			state.loading = true;
			state.isAuthenticated = true;
		},
		getEmergencyCaseDetailsSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.getDetailsEmergency = action.payload;
		},
		getEmergencyCaseDetailsFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// AddEmergencyNotes
		EmergencyNotesRequest: state => {
			state.loading = true;
		},
		UpdateEmergencyNotesSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		UpdateEmergencyNotesFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// Self Pay Signup
		SelfPaySignupRequest: state => {
			state.loading = true;
		},
		SelfPaySignupSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
			state.user = action.payload;
			state.selpPayFirstStep = true;
		},
		SelfPaySignupFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
			state.selpPayFirstStep = false;
		},
		clearSelfPayFirstStep: (state, action) => {
			state.selpPayFirstStep = false;
		},
		// Phone Verified
		PhoneVerifiedRequest: state => {
			state.loading = true;
		},
		PhoneVerifiedSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
			state.isSignupCodeVerified = true;
		},
		PhoneVerifiedFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = false;
			state.error = action.payload;
			state.isSignupCodeVerified = false;
		},

		//
		getPreConditionSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allPreConditions = action.payload;
		},
		getPreConditionFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		getAllAgesSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.allUserAges = action.payload;
		},
		getAllAgesFailure: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// updatepatientfamilyPlan
		updatePatientFamilySuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		updatePatientFamilyFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// EnterprisePayPatientFamily
		submitPatientPaymentSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		submitPatientPaymentFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// resendCredentialsPatient
		CredentialsPatientSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		CredentialsPatientFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// outOfNetwrokNotes
		outNetwrokNotesRequest: state => {
			state.loading = true;
		},
		// AddFamilyMember
		AddFamilyMemberSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		AddFamilyMemberFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		UpdateOutNetworkSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		UpdateOutNetworkFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		addFamilySuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.familyMemberAmount = action.payload;
		},
		addFamilyFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		patientPayoutSubcribtionSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
		},
		patientPayoutSubcribtionFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		addDebitMeetingSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.payload;
			state.cardAdded = true;
		},
		addDebitMeetingFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
			state.cardAdded = false;
		},

		clearError: (state, action) => {
			state.error = null;
		},
		clearMessage: (state, action) => {
			state.message = null;
		},
		//AdminPayPatientPayment
		AdminPayPatientPaymentSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		AdminPayPatientPaymentFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		//checkUsernameExist
		checkUsernameExistSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		checkUsernameExistFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		//OutOfNetWorkProvider
		OutOfNetWorkProviderSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			// state.message = action.message;
			state.getAllNetworkProvider = action.payload;
		},
		OutOfNetWorkProviderFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		//OutOfNetWorkProviderDetails
		OutOfNetWorkProviderDetailsSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			// state.message = action.message;
			state.getProviderDetails = action.payload;
		},
		OutOfNetWorkProviderDetailsFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		//percentageAdjustment
		PercentageAdjustmentSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		PercentageAdjustmentFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},

		// enterprise NOtes
		EnterpriseNotesRequest: state => {
			state.loading = true;
		},
		AddEnterpriseNotesSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		AddEnterpriseNotesFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
		// providerNotes
		ProviderNotesRequest: state => {
			state.loading = true;
		},
		AddProviderNoteSuccess: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.message = action.message;
		},
		AddProviderNoteFail: (state, action) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.error = action.payload;
		},
	}
);

export default userReducer;
