import { InfinitySpin } from 'react-loader-spinner';
import React from 'react';
import Sidebar from '../Layout/Sidebar/Sidebar';
import Header from '../Layout/Header/Header';

const Spinner = () => {
	return (
		<>
			<div className='home'>
				<div>
					<Sidebar showSidebar={false} showHelpButton={false} />
				</div>
				<div className='homeContainer'>
					<Header showHeader={true} />
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '100vh',
						}}
					>
						<InfinitySpin width='300' color='#4157FF' />
					</div>
				</div>
			</div>
		</>
	);
};

export default Spinner;
