import './PageNotFound.css';
import React from 'react';
import { Button } from '@mui/material';
import Logo from '../../asset/image/Logo.svg';
import healthcoinLogo from '../../asset/image/healthcoinLogo.png';
import {Box,} from '@mui/material';
import { useNavigate } from 'react-router-dom';
const PageNotFound = () => {
	const navigate = useNavigate();
const handleHome = () =>{
  window.localStorage.clear()
  navigate('/signup')
}
	return (
		<div className='pg-container'>
			<Box
								sx={{
									display: 'grid',
									flexDirection: 'coloumn',
									justifyContent: 'center',
									alignContent: 'center',
								}}
                onClick={handleHome}
							>
								<img style={{width: '253px',height: '63', cursor: 'pointer'}} src={Logo} alt='Logo'   onClick={handleHome} />
								<img
									style={{ marginTop: '4px' ,width: '273px', height: '49.26px', cursor: 'pointer' }}
									
									src={healthcoinLogo}
									alt='healthcoinlogo'
                  onClick={handleHome}
								/>
							</Box>
			<Button
            onClick={handleHome}
            variant="contained"
            color="info"
            style={{width:'120px', height:'50px', fontWeight:'bold', marginTop:'18px'}}
          >
            Continue
          </Button>
		</div>
	);
};

export default PageNotFound;
