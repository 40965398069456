import { async } from 'rxjs';

export function blobToBase64(blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = function () {
			const dataUrl = reader.result;
			const base64 = dataUrl.split(',')[1];
			resolve(base64);
		};

		reader.onerror = function (error) {
			reject(error);
		};

		reader.readAsDataURL(blob);
	});
}

export async function convertFormDataToString(formData) {
	let formDataString = '';
	for (let [key, value] of formData.entries()) {
		if (value instanceof Blob) {
			// If the value is a blob (e.g., an image), convert it to Base64
			let base64 = await blobToBase64(value);
			formDataString += key + '=' + base64 + '&';
		} else {
			formDataString += key + '=' + value + '&';
		}
	}
	return formDataString.slice(0, -1); // Remove the last '&'
}

export async function convertToJson(params) {
	
	for (const [key, value] of params) {
		
		const resultObject = {};

		if (/\[.*\]$/.test(key)) {
			// If the key contains square brackets, treat it as an array
			const arrayKey = key.replace(/\[.*\]$/, '');
			resultObject[arrayKey] = resultObject[arrayKey] || [];
			resultObject[arrayKey].push(value);
		} else {
			// Otherwise, treat it as a regular key-value pair
			resultObject[key] = value;
		}
		
		return resultObject;
	}
}
