import CryptoJS from 'crypto-js';

const SECRET_KEY = '12345678901234561234567890123456';
const IV = '0000000000000000';

// Encryption function
export const encryptData = data => {
	const ciphertext = CryptoJS.AES.encrypt(
		JSON.stringify(data),
		CryptoJS.enc.Utf8.parse(SECRET_KEY),
		{
			iv: CryptoJS.enc.Utf8.parse(IV),
		}
	).toString();
	return ciphertext;
};

// Decryption function
export const decryptData = ciphertext => {
	const bytes = CryptoJS.AES.decrypt(
		ciphertext,
		CryptoJS.enc.Utf8.parse(SECRET_KEY),
		{
			iv: CryptoJS.enc.Utf8.parse(IV),
		}
	);
	const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	return decryptedData;
};
