import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
	Box,
	Card,
	CardContent,
	Typography,
	TextField,
	Button,
} from '@mui/material';
import GrpHealthcoinLogo from '../../asset/image/GrpHealthcoinLogo.svg';
import { makeStyles } from '@mui/styles';
import './SetEnterprisePassword.css';

const useStyles = makeStyles({
	card: {
		width: '550',
		height: '550px',
		borderRadius: '20px',
	},
	groupLogo: {
		width: '290px',
	},
	groupLogoBox: {
		display: 'flex',
		flexDirection: 'coloumn',
		justifyContent: 'center',
		alignContent: 'center',
	},

	errorMessage: {
		color: 'red',
		fontSize: '15px',
	},
});

const validationSchema = Yup.object().shape({
	password: Yup.string()
		.required('Password is required')
		.min(6, 'Password must be at least 6 characters long'),
	confirmPassword: Yup.string()
		.required('Confirm Password is required')
		.oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const SetEnterprisePassword = () => {
	const classes = useStyles();

	return (
		<Box sx={{ height: '100vh' }} className='mainBox'>
			<Card className={classes.card}>
				<CardContent>
					<Box className={classes.groupLogoBox}>
						<img src={GrpHealthcoinLogo} className={classes.groupLogo} />
					</Box>

					<Formik
						initialValues={{ password: '', confirmPassword: '' }}
						validationSchema={validationSchema}
						onSubmit={values => {
							// console.log(values);
						}}
					>
						<Form>
							<Box
								style={{
									display: 'grid',
									flexDirection: 'column',
									gap: '22px',
									justifyContent: 'center',
									alignContent: 'center',
									margin: '32px',
									marginTop: '10px',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignContent: 'center',
										justifyContent: 'center',
									}}
								>
									<Typography
										sx={{
											fontSize: '18px',
											fontWeight: '700',
											marginTop: '10px',
										}}
									>
										Create New Password
									</Typography>
								</Box>

								<Field
									type='password'
									name='password'
									as={TextField}
									placeholder='Enter Password'
									fullWidth
									sx={{ width: '351px' }}
									size='sm'
								/>
								<ErrorMessage
									name='password'
									component='div'
									className={classes.errorMessage}
								/>

								<Field
									type='password'
									name='confirmPassword'
									as={TextField}
									placeholder='Confirm Password'
									fullWidth
									sx={{ width: '351px' }}
									size='sm'
								/>
								<ErrorMessage
									name='confirmPassword'
									component='div'
									className={classes.errorMessage}
								/>
							</Box>

							<Box
								sx={{
									display: 'flex',
									alignContent: 'center',
									justifyContent: 'center',
									marginTop: '46px',
								}}
							>
								<Button
									type='submit'
									style={{
										color: 'white ',
										background: ' #4157FF ',
										borderRadius: '56px ',
										border: 'none',
										width: '273px ',
										height: '55px ',
										cursor: 'pointer ',
										fontWeight: '600',
									}}
								>
									SUBMIT
								</Button>
							</Box>
						</Form>
					</Formik>
				</CardContent>
			</Card>
		</Box>
	);
};

export default SetEnterprisePassword;
