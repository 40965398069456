import React from "react";
import { Box, Card, Typography, Grid, CardContent } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
// import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import facaIcon from "../../../asset/image/memberImages/facaIcon.svg";
const useStyles = makeStyles({
  root: {},

});
const Cards = ({ value, description }) => {
  const classes = useStyles();
  const capitalizeFirstLetter = (str) => {
    if (typeof str === 'string') {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return ''; // Return an empty string if str is not a string
    }
  };
  return (
    <Box>
      <Grid>
        <Card
          style={{
            width: "233px",
            height: "92px",
          }}
       
        >
          <CardContent>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", flex: "1" }}>
                <Typography
                  style={{
                    fontSize: "24px ",
                    fontWeight: "500 ",
                    color: '#23262F',
                    fontFamily: 'DM Sans'
                  }}
                // className={classes.amountHeading}
                >
                  {capitalizeFirstLetter(value)}
              </Typography>
              </Box>
              <Box>
                <img src={facaIcon} alt="" />
              </Box>
            </Box>
            <Typography
              style={{
                fontSize: '13px',
                color: '#66737F',
                fontWeight: '500',
                fontFamily: 'DM Sans'
              }}
          
            >{description}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
};

export default Cards;
