import GrpHealthcoinLogo from "../../asset/image/GrpHealthcoinLogo.svg";
import Email from "../../asset/image/email.svg";
import Password from "../../asset/image/password.svg";
import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import { useNavigate, useHistory  } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import "./ResetPassword.css";
import { ResetPasswordEnterprise } from '../../redux/action/user'
import axios from 'axios';
import { toast, Toaster } from "react-hot-toast";
import { server } from "../../redux/store";
import { encryptData, decryptData } from '../../redux/incript&decript/cryptoUtils';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Link,
} from "@mui/material";
const useStyles = makeStyles({
  card: {
    width: "560px",
    height: "auto",
    borderRadius: "20px",
  },
  groupLogo: {
    width: '201px'

  },

  resetPassHeading: {
    fontWeight: "700 !important",
    color: "#23262F !important",
    fontSize: "19px !important",
  },
  boxResetPass: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: "24px",

  },

  
});
const ResetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //navigator
  const backToLogin = (e) => {
    navigate('/')
  }
  //validation
  const initialValues = {
    email: "",
  };
  const handleButtonClick = (setFieldValue, submitForm, errors) => {
    setFieldValue("published");
    submitForm();
    // console.log("published123", errors);
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("*Email or phone number is required")
    // .matches(
    //   /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    //   "*Please enter a valid email address"
    // )
    // .matches(
    //   /^\d{10}$/,
    //   "*Please enter a valid 10-digit phone number"
    // )
  });
  const error = useSelector(state => state.error); 
  const formSubmit = async (values) => {
    try {
      // console.log("heloooooo", values);
      const token = `Bearer ${localStorage.getItem("token")}`;
      const encrypted_data = encryptData({
        email: values.email,
        status: 1 });
      let { data } = await axios.post(
        `${server}/password_reset`,
        {
          encrypted_data: encrypted_data
        },
        {
          headers: { Authorization: token, "Content-Type": "application/json" },
        }
      );
      
      const decryptedData = decryptData(data.encrypted_data);
      toast.success(decryptedData.message);
      const enteredEmail = values.email;
      navigate('/getCode', { state: { email: enteredEmail } });
      
    } catch (err) {
      const decryptedErrorMessage = decryptData(err?.response?.data?.encrypted_data);
      toast.error(decryptedErrorMessage.message);
    }

    
    // dispatch(ResetPasswordEnterprise(values.email, 1));
    // const enteredEmail = values.email;
    // navigate('/getCode', { state: { email: enteredEmail } });
    
   
      // try {
      //   await dispatch(ResetPasswordEnterprise(values.email, 1));
      //   const enteredEmail = values.email;
      //   if (error.success) {
      //     navigate('/getCode', { state: { email: enteredEmail } });
      //   }
      // } catch (err) {
      //   console.log(err); 
      // }
  

   }
  
  const submitHandler = (e, values) => {
    e.preventDefault();
   
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        console.log("Values About fee", values);
        formSubmit(values, setSubmitting, resetForm);
      }}>
      {({
        errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        submitForm,
      }) => (
        <Form onSubmit={submitHandler}>
          <Box
            sx={{
              height: "100vh",
            }}
            className="mainBox"
          >
            <Card className={classes.card}>
              <CardContent>
                {/* health coin logo */}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "coloumn",
                    justifyContent: "center",
                    alignContent: "center",
                    //   margin: "10px",
                  }}
                >
                  <img src={GrpHealthcoinLogo} className={classes.groupLogo} />


                </Box>
                <Box
                  className={classes.boxResetPass}

                >
                  <Typography variant="h1" className={classes.resetPassHeading}>
                    Reset Password
                  </Typography>
                </Box>

                <Box
                  style={{
                    display: "grid",
                    alignContent: "center",
                    justifyContent: "center",
                    flexDirection: 'column',
                    gap: '32px'
                  }}
                
                >

                  <Typography sx={{ width: '324px', fontSize: '12px', color: 'rgba(9, 15, 71, 0.45)', textAlign: 'center' }}>
                    Please enter your  email address or phone number.
                    An email or text will be sent to the email address or phone number
                    associated with that account. Follow the instructions in email or
                    text to finish setting up your new password.
                  </Typography>
                  <Box sx={{marginTop: '22px', marginBottom:'6px'}}>
                    <TextField
                      // className="mailField"
                      placeholder="Enter email / phone number *"
                      id="outlined-start-adornment"
                      sx={{ width: "311px", height: "20px" }}
                      size="sm"
                      fullWidth
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      // 
                      
                    />
                  </Box>
                  {errors.email && touched.email ? (
                    <div className={`red mb-1 fs15`}>{errors.email}</div>
                  ) : null}
                </Box>

                <Box sx={{ display: 'grid', alignContent: 'center', justifyContent: 'center', flexDirection: 'coloumn', marginTop: '72px', }}>

                  <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <span style={{ fontSize: '13px', fontWeight: '500' }}>Remember Your Password?</span>


                    <span
                      style={{ fontSize: '13px', fontWeight: '500', color: '#4157FF', cursor: 'pointer' }}
                    onClick={backToLogin}
                    >
                        &nbsp;Login
                     </span>
                  </Box>
                  <Box sx={{ marginTop: '63px', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <Button
                      style={{
                        color: "white ",
                        background: " #4157FF ",
                        borderRadius: "56px ",
                        border: "none",
                        width: "300px ",
                        height: "50px ",
                        cursor: "pointer ",
                        fontWeight: "600 ",
                      }}
                     
                      onClick={() =>
                        handleButtonClick(setFieldValue, submitForm, errors)
                      }>Submit
                    </Button>
                  </Box>
                </Box>

                {/* email and password  */}
              </CardContent>
            </Card>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
