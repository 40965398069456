import {
	Box,
	Button,
	CircularProgress,
	Container,
	FormControl,
	TextField,
	Tooltip,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import BorderDesign from '../../asset/image/BorderDesign.png';
import Imbolance from '../../asset/image/imbolance.png';
import Vector from '../../asset/image/vector.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';

import './PatientRegistrationcss.css';
import { SelfPayPatient } from '../../redux/action/user';
import { useDispatch, useSelector } from 'react-redux';
import OTPscreen from './OTPscreen';
import GooglePlacesAutocomplete, {
	geocodeByPlaceId,
	getLatLng,
} from 'react-google-places-autocomplete';
import {
	decryptData,
	encryptData,
} from '../../redux/incript&decript/cryptoUtils';
import axios from 'axios';
import { server } from '../../redux/store';
import { Stack } from '@mui/system';
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	error: {
		color: 'red',
		fontSize: '14px',
	},
	customList: {
		// listStyleType: 'none', // Remove default bullet points
		padding: '5px', // Remove default padding
		margin: 0, // Remove default margin
	},
	customListItem: {
		fontSize: '16px',
		marginBottom: theme.spacing(2), // Customize spacing between list items
		fontWeight: 700,
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
	customListPrice: {
		// listStyleType: 'none', // Remove default bullet points
		padding: '5px',
		margin: 0,
	},
	customListItemPrice: {
		fontSize: '15px',
		marginBottom: theme.spacing(1), // Customize spacing between list items
		fontWeight: 600,
		lineHeight: '18px',
		// eslint-disable-next-line no-dupe-keys
		marginBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
	menuListBorder: {
		borderBottom: '1px solid red',
		// borderBottom:'1px solid #CBCBDD',
		padding: '400px',
	},
	responsiveValue: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			display: 'grid',
		},
	},
	responsiveText: {
		display: 'flex',
		justifyContent: 'center',

		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'flex-start',
			fontSize: '10px',
			flexWrap: 'wrap',
			whiteSpace: 'break-spaces',
			'& h1': {
				whiteSpace: 'break-spaces',
				marginTop: theme.spacing(2),
			},
		},
	},
	TextSmallScreen: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
			'& h1': {
				whiteSpace: 'break-spaces',
				marginTop: theme.spacing(2),
			},
		},
	},
	responsiveMainDiv: {
		width: '1030px',
		padding: '23px',
		margin: 0,
		backgroundColor: '#F7FBFF',
		border: '1px solid #3772FF',
		borderRadius: '16px',
		[theme.breakpoints.down('sm')]: {
			width: '300px',
			padding: '17px',
			marginLeft: '-70px',
		},
	},
	responsiveMainDivCatastrophic: {
		width: '1030px',
		padding: '23px',
		margin: 0,
		backgroundColor: '#F7FBFF',
		border: '1px solid #3772FF',
		borderRadius: '16px',
		[theme.breakpoints.down('sm')]: {
			width: '300px',
			padding: '17px',
			marginLeft: '10px',
		},
	},
	registrationScreenResponsive: {
		width: '350px',
		[theme.breakpoints.down('sm')]: {
			width: '290px',
		},
	},
	PasswordScreenResponsive: {
		width: '350px',
		margin: 0,
		[theme.breakpoints.down('sm')]: {
			width: '290px',
		},
	},
	FullScreenMargin: {
		marginLeft: '50px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '5px',
		},
	},
}));

const theme = createTheme({
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					color: 'black',
					backgroundColor: 'white',
					marginTop: '100px',
				},
			},
		},
	},
});

const PatientRegistration = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [emailValidity, setEmailValidity] = useState(undefined);
	const [googleLocation, setGoogleLocation] = useState({
		label: '',
		value: {},
	});
	const [longitude, setLongitude] = useState('');
	const [latitude, setLatitude] = useState('');
	const [showPassword, setShowPassword] = React.useState(false);
	const [confirmPassword, setConfirmPassword] = React.useState(false);
	const [timeLeft, setTimeLeft] = useState(60);
	const [loading, setLoading] = useState(false);
	const [isTooltipOpen, setTooltipOpen] = useState(false);
	const [isTooltipOpen1, setTooltipOpen1] = useState(false);
	//  Step 4 for modal tooltip
	const [step, setStep] = useState(2);
	const navigate = useNavigate();

	const [openPhoneVerifiedModal, setOpenPhoneVerifiedModal] = useState(false); // OTP Verification Modal

	const handleTooltipOpen = () => {
		setTooltipOpen(!isTooltipOpen);
		setTooltipOpen1(false);
	};

	const handleTooltipClose = () => {
		setTooltipOpen(false);
	};
	const handleTooltipOpen1 = () => {
		setTooltipOpen1(!isTooltipOpen1);
		setTooltipOpen(false);
	};

	const handleTooltipClose1 = () => {
		setTooltipOpen1(false);
	};
	const handlePlaceSelect = async value => {
		setInformationDetail({
			...informationDetail,
			address: value.label,
		});
		try {
			setGoogleLocation(value);
			const results = await geocodeByPlaceId(value?.value?.place_id);
			if (results && results?.length > 0) {
				const { lat, lng } = await getLatLng(results[0]);
				setLatitude(lat);
				setLongitude(lng);
			}
		} catch (error) {
			console.error('Error fetching geolocation:', error);
		}
	};
	const { message, selpPayFirstStep, user } = useSelector(state => state.user);
	const [informationDetail, setInformationDetail] = useState({
		first_name: '',
		last_name: '',
		gender: '',
		phone_number: '',
		email: '',
		address: '',
		password: '',
		confirm_password: '',
	});

	const validateEmail = email => {
		// Regular expression for basic email validation
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(email);
	};

	useEffect(() => {
		setEmailValidity(undefined);
		const isValidEmail = validateEmail(informationDetail.email);
		if (isValidEmail) {
			const token = `Bearer ${localStorage.getItem('token')}`;
			const encrypted_data = encryptData({
				email: informationDetail.email,
			});
			axios
				.post(
					`${server}/checkEmailWeb`,
					{
						encrypted_data: encrypted_data,
					},
					{
						headers: {
							Authorization: token,
							'Content-Type': 'application/json',
						},
					}
				)
				.then(({ data }) => {
					console.log(data);
					const decryptedData = decryptData(data.encrypted_data);
					if (decryptedData?.success) {
						setEmailValidity(true);
					} else {
						toast.success(decryptedData?.message )
						navigate('/?formAlreadyRegEmail=true');
					}

					// toast.success(decryptedData.message);
				});
		}
	}, [informationDetail.email]);

	console.log({ informationDetail });
	useEffect(() => {
		console.log({ selpPayFirstStep });
		setLoading(false);
		if (selpPayFirstStep) {
			navigate('/');
		}
	}, [selpPayFirstStep]);

	const changeInformationDetail = e => {
		const { name, value } = e.target;

		setInformationDetail({
			...informationDetail,
			[name]: value,
		});
	};
	// console.log('informationDetailinformationDetail', informationDetail);
	useEffect(() => {
		if (!timeLeft) return;

		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		if (
			message ==
			'Self-Pay Patient registered successfully and email is sent to patient email address.'
		) {
			// window.location.href = '/';
		}
		if (message == 'Code Verified.') {
			setOpenPhoneVerifiedModal(false);
			// setOpen(true);
		}
	}, [message]);

	const nextState = () => {
		if (step == 1) {
		}
		if (step == 2) {
			const {
				first_name,
				last_name,
				gender,
				phone_number,
				email,
				address,
				password,
				confirm_password,
			} = informationDetail;
			if (!first_name) {
				return toast.error('First name must be required');
			}
			if (!last_name) {
				return toast.error('Last name must be required');
			}
			// if (!gender) {
			// 	return toast.error('Gender must be required');
			// }
			if (!phone_number) {
				return toast.error('Phone number must be required');
			}
			if (phone_number.length < 10) {
				return toast.error('Phone number is not valid');
			}
			if (!email) {
				return toast.error('Email must be required');
			}
			if (!address) {
				return toast.error('Address must be required');
			}
			if (!password) {
				return toast.error('Password must be required');
			}
			if (!confirm_password) {
				return toast.error('Confirm Password must be required');
			}
			if (password !== confirm_password) {
				return toast.error('Confirm Password is not matched');
			}
			// console.log('informationDetail', informationDetail);
			setLoading(true);
			dispatch(SelfPayPatient(informationDetail));
			// console.log('messagemessagemessagemessagemessage', message);
			if (message) {
				//  window.location.href="/"
				// setOpenPhoneVerifiedModal(true);
			}
			return;
		}

		if (step < 10) {
			// setStep(step + 1);
		}
	};

	const backStep = () => {
		if (step >= 2) {
			// setStep(step - 1);
			navigate('/signup');
		}
	};
	const handleClickShowPassword = () => setShowPassword(show => !show);
	const handleClickShowConfirmPassword = () =>
		setConfirmPassword(show => !show);

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	const htmlContent = (
		<>
			<div className={classes.responsiveMainDiv}>
				<div className={classes.responsiveText}>
					<h1 style={{ textDecoration: 'underline' }}>
						Catastrophic + Direct Primary Care + Medications
					</h1>
				</div>
				<div className={classes.responsiveValue}>
					<div style={{ width: '300px' }}>
						<h1
							style={{ textDecoration: 'underline' }}
							className={classes.TextSmallScreen}
						>
							What You Get
						</h1>
						<ul className={classes.customList}>
							<li className={classes.customListItem}>
								Free Direct Primary Care
							</li>
							<li className={classes.customListItem}>
								{' '}
								Savings on Medications
							</li>
							<li className={classes.customListItem}>
								{' '}
								Annual Membership Buy-Downs*
							</li>
							<li className={classes.customListItem}>
								Free Pharmaceuticals Coming Soon*
							</li>
							<li className={classes.customListItem}>
								Full Access Gifting After First $10,000 Spent
							</li>
							<li className={classes.customListItem}>
								ER, Surgical, & Cancer Access
							</li>
							<li className={classes.customListItem}>
								Access to Responsive & Caring Support{' '}
							</li>
						</ul>
					</div>
					<div style={{ width: '264px' }}>
						<h1
							style={{ textAlign: 'center', textDecoration: 'underline' }}
							className={classes.TextSmallScreen}
						>
							What You Are Responsible For
						</h1>
						<div>
							<p
								style={{
									fontSize: '14px',
									fontWeight: '700',
									textAlign: 'center',
								}}
							>
								DPC (Direct Primary Care) is a<br /> membership based form of
								healthcare.
								<br /> HealthCoin is picking up the tab.
								<br /> Go as often as you’d like. It’s on us!
							</p>

							<div style={{ textAlign: 'center', margin: 'auto' }}>
								<img
									src={BorderDesign}
									alt='BorderDesign'
									width={'80px'}
									height={'7.6px'}
								/>
							</div>

							<p
								style={{
									fontSize: '14px',
									fontWeight: '700',
									textAlign: 'center',
								}}
							>
								Catastrophic access takes care <br /> of any medical services
								after the
								<br />
								first $10,000 spent by the patient.
							</p>

							<div style={{ textAlign: 'center', margin: 'auto' }}>
								<img
									src={BorderDesign}
									alt='BorderDesign'
									width={'80px'}
									height={'7.6px'}
								/>
							</div>
							<p
								style={{
									fontSize: '14px',
									fontWeight: '700',
									textAlign: 'center',
								}}
							>
								This plan is best for healthy <br /> individuals who want access
								to a dedicated <br /> provider, pharmaceutical savings, and
								peace <br /> of mind for major medical bills in case of
								emergency
							</p>
							<br />
						</div>
					</div>
					<div>
						<h1
							style={{ textDecoration: 'underline' }}
							className={classes.TextSmallScreen}
						>
							Pricing
						</h1>

						<ul className={classes.customListPrice}>
							<li className={classes.customListItemPrice}>
								Age 0-7 M/F = $210/mo
							</li>
							<li className={classes.customListItemPrice}>
								Age 7-17 M/F = $230/mo{' '}
							</li>
							<li className={classes.customListItemPrice}>
								Age 18-24 M/F = $197/mo{' '}
							</li>
							<li className={classes.customListItemPrice}>
								Age 25-32 M/F = $262/mo{' '}
							</li>
							<li className={classes.customListItemPrice}>
								Age 33-44 M/F = $295/mo{' '}
							</li>
							<li className={classes.customListItemPrice}>
								Age 45-54 M/F = $361/mo{' '}
							</li>
							<li className={classes.customListItemPrice}>
								Age 55+ M/F = $450/mo{' '}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
	const CatastrophicContent = (
		<div className={classes.responsiveMainDivCatastrophic}>
			<div className={classes.responsiveText}>
				<h1 style={{ textAlign: 'center', textDecoration: 'underline' }}>
					Catastrophic
				</h1>
			</div>
			<div className={classes.responsiveValue}>
				<div style={{ width: '300px' }}>
					<h1
						style={{ textDecoration: 'underline' }}
						className={classes.TextSmallScreen}
					>
						What You Get
					</h1>
					<ul className={classes.customList}>
						<li className={classes.customListItem}>
							Emergency Access Over $10,000
						</li>
						<br />
						<li className={classes.customListItem}>
							{' '}
							ER, Surgical, & Cancer Access
						</li>
						<br />
						<li className={classes.customListItem}>
							{' '}
							Access to Responsive & Caring Support
						</li>
						<br />
					</ul>
					<div style={{ marginTop: '30px' }}>
						<img src={Imbolance} alt='Imbolance' />
					</div>
				</div>
				<div style={{ width: '264px' }}>
					<h1
						style={{ textAlign: 'center', textDecoration: 'underline' }}
						className={classes.TextSmallScreen}
					>
						What You Are Responsible For
					</h1>
					<div>
						<p
							style={{
								fontSize: '14px',
								fontWeight: '700',
								textAlign: 'center',
							}}
						>
							Catastrophic Access takes care
							<br /> of any medical services above
							<br />
							$10,000.
						</p>
						<div style={{ textAlign: 'center', margin: 'auto' }}>
							<img
								src={BorderDesign}
								alt='BorderDesign'
								width={'80px'}
								height={'7.6px'}
							/>
						</div>
						<p
							style={{
								fontSize: '14px',
								fontWeight: '700',
								textAlign: 'center',
							}}
						>
							Regular check-ups, lab work,
							<br /> minor surgeries, etc are all the
							<br />
							responsibility of the patient.
						</p>
						<div style={{ textAlign: 'center', margin: 'auto' }}>
							<img
								src={BorderDesign}
								alt='BorderDesign'
								width={'80px'}
								height={'7.6px'}
							/>
						</div>
						<p
							style={{
								fontSize: '14px',
								fontWeight: '700',
								textAlign: 'center',
							}}
						>
							This plan is best for healthy young <br /> individuals who do not
							mind <br />
							covering their doctor visits but do
							<br /> no want to run the risk of major
							<br /> hospital bills in case of <br />
							emergency.
						</p>
						<br />
					</div>
				</div>
				<div>
					<h1
						style={{ textDecoration: 'underline' }}
						className={classes.TextSmallScreen}
					>
						Pricing
					</h1>

					<ul className={classes.customListPrice}>
						<li className={classes.customListItemPrice}>
							Age 0-7 M/F = $95/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 7-17 M/F = $80/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 18-24 M/F = $70/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 25-32 M/F = $110/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 33-44 M/F = $185/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 45-54 M/F = $200/mo
						</li>
						<li className={classes.customListItemPrice}>
							Age 55+ M/F = $300/mo
						</li>
					</ul>
				</div>
			</div>
		</div>
	);

	return (
		<div>
			<Container>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: '20px',
					}}
				>
					<span
						style={{ fontSize: '1.5rem', fontWeight: 700, marginLeft: '50px' }}
					>
						{step == 1 && 'Personal information'}
						{step == 2 || step == 6 ? 'Information details' : ''}
						{step == 3 || step == 4 ? 'Information details' : ''}
						{step == 5 ? (
							<span style={{ fontWeight: '400' }}>Price plan</span>
						) : (
							''
						)}
					</span>
					{step == 1 || step == 3 ? (
						<span
							style={{ fontSize: '1.5rem', color: '#4157FF', fontWeight: 700 }}
						>
							More Info
						</span>
					) : (
						''
					)}
				</div>

				{openPhoneVerifiedModal ? (
					<>
						<OTPscreen
							isShow={true}
							phone_number={informationDetail.phone_number}
						/>
					</>
				) : (
					''
				)}

				{step == 2 ? (
					<div
						className={classes.FullScreenMargin}
						// style={{ marginLeft: '50px' }}
					>
						<p
							style={{
								fontSize: '1.05rem',
								fontWeight: 600,
								color: 'blue',
								cursor: 'pointer',
							}}
							onClick={handleTooltipOpen}
							onClose={handleTooltipClose}
						>
							Catastrophic + DPC + Meds
							<ThemeProvider theme={theme}>
								<Tooltip
									title={htmlContent}
									placement='bottom'
									style={{
										padding: 0,
										margin: 0,
										backgroundColor: 'red',
										border: 'none',
										outline: 'none',
									}}
									classes={{ tooltip: classes.customTooltip }}
									open={isTooltipOpen}
									onClose={handleTooltipClose}
								>
									<img
										src={Vector}
										alt='Vector'
										width={'18px'}
										height={'18px'}
										style={{
											alignItems: 'center',
											textAlign: 'center',
											cursor: 'pointer',
											marginLeft: '5px',
										}}
									/>
								</Tooltip>
							</ThemeProvider>
						</p>

						<p
							style={{
								fontSize: '1.05rem',
								fontWeight: 600,
								color: 'blue',
								cursor: 'pointer',
							}}
							onClick={handleTooltipOpen1}
							onClose={handleTooltipClose1}
						>
							Catastrophic
							<ThemeProvider theme={theme}>
								<Tooltip
									title={CatastrophicContent}
									placement='bottom'
									style={{
										padding: 0,
										margin: 0,
										backgroundColor: 'red',
										border: 'none',
										outline: 'none',
									}}
									classes={{ tooltip: classes.customTooltip }}
									open={isTooltipOpen1}
									onClose={handleTooltipClose1}
								>
									<img
										src={Vector}
										alt='Vector'
										width={'18px'}
										height={'18px'}
										style={{
											alignItems: 'center',
											textAlign: 'center',
											marginLeft: '7px',
											cursor: 'pointer',
										}}
									/>
								</Tooltip>
							</ThemeProvider>
						</p>

						<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>
							Account Holder (You)s
						</p>
						<div className={classes.registrationScreenResponsive}>
							<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>Name</p>
							<TextField
								fullWidth
								id='outlined-basic'
								label='First name'
								variant='outlined'
								name='first_name'
								value={informationDetail?.first_name}
								onChange={e => changeInformationDetail(e)}
							/>
							<br />
							<br />
							<TextField
								fullWidth
								id='outlined-basic'
								label='Last name'
								variant='outlined'
								name='last_name'
								onChange={e => changeInformationDetail(e)}
							/>
							<br />
							<br />
							{/* <FormControl variant='outlined' fullWidth>
								<InputLabel htmlFor='outlined-basic'>Gender</InputLabel>
								<Select
									fullWidth
									label='Gender'
									id='outlined-basic'
									variant='outlined'
									name='gender'
									// value={gender}
									onChange={e => changeInformationDetail(e)}
									labelWidth={60} // Adjust the width of the label to fit your needs
								>
									<MenuItem value='' disabled>
										<em>Select Gender</em>
									</MenuItem>
									<MenuItem value='male'>Male</MenuItem>
									<MenuItem value='female'>Female</MenuItem>
								</Select>
							</FormControl> */}

							<br />
							<br />
						</div>
						<div className={classes.registrationScreenResponsive}>
							<p style={{ fontSize: '1.25rem', fontWeight: 600 }}>Details</p>
							<TextField
								fullWidth
								id='outlined-basic'
								label='Phone number'
								variant='outlined'
								name='phone_number'
								// style={{ width: '350px' }}
								type='number'
								onChange={e => {
									const record = e.target.value;
									// console.log('record', record, 'record.length', record.length);
									changeInformationDetail(e);
								}}
							/>
							<br />
							<br />

							<Box position={'relative'}>
								<TextField
									fullWidth
									id='outlined-basic'
									label='Email'
									variant='outlined'
									// style={{ width: '350px', margin: 0 }}
									name='email'
									type='email'
									onChange={e => changeInformationDetail(e)}
								/>
								{emailValidity && (
									<Box position={'absolute'} right={-50} bottom={'25%'}>
										<VerifiedIcon sx={{ color: 'green' }} />
									</Box>
								)}
							</Box>

							<br />
							<br />

							<InputLabel htmlFor='outlined-adornment-password'>
								&nbsp;&nbsp;Address
							</InputLabel>
							<GooglePlacesAutocomplete
								selectProps={{
									styles: {
										input: provided => ({
											...provided,
										}),
										option: provided => ({
											...provided,

											zIndex: 99999999,
											// opacity: 0.2,
											color: 'black',
											fontWeight: '500',
											backfaceVisibility: 'hidden',
										}),
										singleValue: provided => ({
											...provided,
										}),
									},

									value: googleLocation,
									onChange: value => {
										handlePlaceSelect(value);
									},
								}}
								apiKey={process.env.REACT_APP_API_KEY}
							/>

							<br />
							<br />
							<FormControl
								className={classes.PasswordScreenResponsive}
								// sx={{ m: 0, width: '350px' }}
								variant='outlined'
							>
								{/* <InputLabel htmlFor='outlined-adornment-password'>
									Password1
								</InputLabel> */}
								<OutlinedInput
									id='outlined-adornment-password'
									type={showPassword ? 'text' : 'password'}
									onChange={e => changeInformationDetail(e)}
									name='password'
									placeholder='Password'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label='Password'
								/>
							</FormControl>
							<br />
							<br />
							<FormControl
								className={classes.PasswordScreenResponsive}
								// sx={{ m: 0, width: '350px' }}
								variant='outlined'
							>
								{/* <InputLabel htmlFor='outlined-adornment-password'>
									Confirm password
								</InputLabel> */}
								<OutlinedInput
									id='outlined-adornment-password'
									type={confirmPassword ? 'text' : 'password'}
									onChange={e => changeInformationDetail(e)}
									name='confirm_password'
									placeholder='Confirm Password'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowConfirmPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'
											>
												{confirmPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label='Confirm Password'
								/>
							</FormControl>
							<br />
						</div>
						<br />
					</div>
				) : (
					''
				)}

				<div style={{ float: 'right' }}>
					{step == 7 ? (
						<p style={{ textAlign: 'center' }}>Terms & Conditions apply</p>
					) : (
						''
					)}
					{step == 8 || step == 9 || step == 10 ? (
						''
					) : (
						<>
							{step == 3 && (
								<>
									<br />
								</>
							)}
							<Button
								variant='outlined'
								size='large'
								onClick={() => backStep()}
								style={{
									width: '150px',
									color: '#66737F',
									borderColor: '#66737F',
								}}
							>
								BACK
							</Button>{' '}
							&nbsp;&nbsp;
							<Button
								variant='contained'
								color='primary'
								size='large'
								disabled={loading}
								onClick={nextState}
								style={{ width: '150px' }}
							>
								{step == 1 ? 'NEXT' : ''}
								{step == 2 ? 'SUBMIT' : ''}
								{step == 3 ? 'NEXT' : ''}
								{step == 4 ? 'NEXT' : ''}
								{step == 5 ? 'CONTINUE' : ''}
								{step == 6 ? 'CONTINUE' : ''}
								{step == 7 ? 'JOIN' : ''}
								{loading && (
									<CircularProgress sx={{ color: 'white' }} size={20} />
								)}
							</Button>
						</>
					)}
					<br />
					<br />
				</div>

				<Toaster />
			</Container>
		</div>
	);
};

export default PatientRegistration;
