import React from 'react';
import { UserRoutes } from './Routes';
import { Route, Routes } from 'react-router-dom';
import PageNotFound from '../component/PageNotFound/PageNotFound';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import subject from '../helper/Rxjs';

const DynamicRoutes = ({ role = 'enterprise' }) => {
	let user1 = localStorage.getItem('user');

	const navigate = useNavigate();
	var routeComponents = [];
	const { isAuthenticated, user, error, message, loading } = useSelector(
		state => state.user
	);

	// Interceptor
	axios.interceptors.response.use(
		function (res) {
			if (res?.status == 403) {
				subject.next(1);
				localStorage.clear();
			}

			return res;
		},
		err => {
			if (err?.response?.status == 401) {
				localStorage.setItem('error_message', err?.response?.data?.message);
				subject.next(1);
			}
			if (err?.response?.status == 404) {
				localStorage.setItem('error_message', err?.response?.data?.message);
				subject.next(2);
			}

			return Promise.reject(err);
		}
	);
	useEffect(() => {
		if (user?.user_type == 'enterprise') {
			if (
				user?.status == '1' &&
				user?.is_verified == '1' &&
				user?.is_continue
			) {
			} else {
				return navigate('/members');
			}
		}
	}, [JSON.stringify(user)]);
	if (user1 && user1 != 'undefined') {
		user1 = JSON.parse(user1);
		var userType = user1?.user_type;
		if (userType == 'super_admin') {
			userType = 'sub_admin';
		}
		if (userType == 'admin') {
			userType = 'sub_admin';
		}
		// console.log("userTypeuserTypeuserTypeuserTypeuserTypeuserTypeuserTypeuserType",userType)
		routeComponents = UserRoutes[userType?.toLowerCase()]?.map(
			({ path, component }, key) => {
				// console.log("pathhhhhhhhh",path)
				return <Route exact path={path} element={component} key={key} />;
			}
		);
	} else {
		routeComponents = UserRoutes['Public'].map(({ path, component }, key) => (
			<Route exact path={path} element={component} key={key} />
		));
	}
	return (
		<Routes>
			{routeComponents}

			{/* {publicComponents} */}
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default DynamicRoutes;
