import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import moment from 'moment';

import Rating from "@mui/material/Rating";
import Click from "../../asset/image/click.svg";
import EyeView from "../../asset/image/eyeView.svg";
import Search from "../../asset/image/search.svg";
import Delete from "../../asset/image/delete.svg";
import Switch from "@mui/material/Switch";
// import Spinner from '../Spinner/Spiner'
//
import download from "../../asset/image/download.png";
import {
  MenuItem,
  Select,
  Table,
  Typography,
  InputLabel,
  FormControl,

} from "@mui/material";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Sidebar from "../Layout/Sidebar/Sidebar";
import Header from "../Layout/Header/Header";
import Box from "@mui/material/Box";
import { FaCheck, FaTimes } from "react-icons/fa";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
// import AddNewEmployee from "./AddNewEmployeeModel";
import Checkbox from "../Table/Checkbox";
import {
  EnterpriseAccountApproal,
  continueEnterpriseAccount,
  getAllAdminInvices,
  getAllEnterprise,
} from "../../redux/action/user";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../redux/store";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import { createStore } from "redux"; // Css
import avatar from "../../asset/image/avatar.svg";
import { setValue } from "../../redux/action/tabAction";

import Pagination from "@mui/material/Pagination";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import AdapterMoment from '@material-ui/pickers/adapter/moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Spinner from "../SpinnerTrueFalse/Spinner";
import StatusHandling from "../StatusHandling/StatusHandling";
const useStyles = makeStyles({
  root: {
    fontSize: "12px !important",
    fontWeight: "bold !important",
    textAlign: "center  !important",
  },
  bold: {
    fontWeight: "bold !important",
    color: "#3772FF !important",
    whiteSpace: "pre-line",
    wordBreak: "break-word",
  },

  imageCircle: {
    borderRadius: "22px !important",
    width: "39px",
    height: "39px",
  },
  rounded: {
    borderRadius: "22px !important",
  },
  eyes: {
    cursor: "pointer",
  },
});

const label1 = { inputProps: { "aria-label": "Checkbox demo" } };
const InvoicesPage = () => {
  const printHippa = {
    color: "white",
    background: "#4157FF",
    borderRadius: "56px",
    border: "none",
    width: "203px",
    height: "50px",
    cursor: "pointer",
    fontWeight: 700,
    fontFamily: " DM Sans",
  };
  const value = useSelector((state) => state.counter.value);
  const { allAdminsInvoices, loadingForallAdminInvoice } = useSelector((data) => data.user)
  const formatName = (name) => {
    if (name?.length > 12) {
      return name.replace(/(.{12})/g, "$1\n");
    }
    return name;
  };
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [query, setQuery] = useState("");
  const [isActive, setIsActive] = useState(false);
  // const arrayState = useSelector(data => data?.user?.allEnterprise);
  const [token, setToken] = useState(`Bearer ${localStorage.getItem("token")}`);
  const [tabValue, setTabValue] = useState("1");
  const [activeTab, setActiveTab] = useState("1");
  const [selectedDate, setSelectedDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentstatus, setCurrentstatus] = useState(false);
  const [paymentFilter, setPaymentFilter] = useState('');

  // const handleEyeViewClick = (rowId) =>{
  //   changeValueOne();
  //   navigate(`/employers_Info/${rowId}`);
  // }

  const [showTypography, setShowTypography] = useState(true);
  const [showPatient, setShowPatient] = useState(true);
  const [search, setSearch] = useState('');

  const [selectedValue, setSelectedValue] = useState("Filter by account");
  const [selectedPatient, setSelectedPatient] = useState("Patient");
  const [monthFilter, setMonthFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setShowTypography(false);
  };

  const handleChangePatient = (event) => {
    selectedPatient(event.target.value);
    setShowPatient(false);
  };

  const handleIconClick = (rowId) => {
    changeValue();
    navigate(`/employers_Info/${rowId}`);
  };

  const handleEyeIconClick = (rowId) => {
    changeValueOne();
    navigate(`/employers_Info/${rowId}`);
  };

  const changeValue = () => {
    dispatch(setValue("2"));
  };

  const changeValueOne = () => {
    dispatch(setValue("1"));
  };

  
  // console.log("paymentFilterpaymentFilterpaymentFilterpaymentFilter", paymentFilter)
  const [load, setLoad] = useState(false);
  useEffect(() => {
    // console.log("search,paymentFilter,monthFilter,yearFilter", search, paymentFilter, monthFilter, yearFilter)
    dispatch(getAllAdminInvices(search, paymentFilter, monthFilter, yearFilter))
  }, [search, paymentFilter, monthFilter, yearFilter]);
  // console.log("allAdminsInvoicesallAdminsInvoicesallAdminsInvoices", allAdminsInvoices)
  
  // Search

  // const handleSearch = (event) => {
  //   const query = event.target.value;
  //   setQuery(query);
  //   setCurrentPage(1);
  // };

  const activebuttonStyle = {
    backgroundColor: "#4CD964",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "30px",
    border: "none",
    cursor: "pointer",
    minWidth: "7rem",
  };
  const deActiveButtonStyle = {
    backgroundColor: "rgb(232, 80, 91)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "30px",
    border: "none",
    cursor: "pointer",
    minWidth: "7rem",
  };

  const handleClickNew = () => {
    // navigate("/addNewEmployer");
  };
  // Checkbox
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(allAdminsInvoices?.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    // console.log(id, "checkall")
    if (checked) {
      setIsCheck([...isCheck, Number(id)]);
    } else {
      setIsCheck(isCheck.filter((item) => item !== Number(id)));
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const jsDate = new Date(date).toISOString();
    // console.log("helllllllll",jsDate.getMonth())
    const momentDate = moment(jsDate, moment.ISO_8601);
    const month = momentDate.format('MMMM'); // Output: April
    const year = momentDate.year(); // Output: 2022
    // console.log("monthmonthmonth", month)
    setMonthFilter(month)
    setYearFilter(year)
  };

  return (
    <>
      {loadingForallAdminInvoice ? (
        <Spinner />
      ) : (
        <div className="home">

          <div>
            <Sidebar showSidebar={false} showHelpButton={false} />
          </div>
          <div className="homeContainer">
            <Header showHeader={true} />

            <Container
              style={{ position: "relative", top: "78px", background: "#f8f9fe" }}
              maxWidth="xl"
              sx={{ mt: 4 }}
            >
              <Grid container spacing={2}>
                <Grid xs={6} md={9.5}>
                  <TextField
                    id="outlined-start-adornment"
                    placeholder="Search"
                    // value={query}
                    // onChange={handleSearch}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{ m: 1, width: "30ch" }}
                    style={{ marginLeft: "15px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={Search} alt="search" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid sx={{ mt: 2, ml: 0 }} md={2.5}>
                  {/* <Button
                  style={{
                    width: "120px",
                    height: "46px",
                    textTransform: "none",
                    backgroundColor: "#4157FF",
                    backgroundColor: "#ddd",
                    float: "right",
                    fontWeight: 700,
                    borderRadius: "24px",
                    color: "white",
                    cursor: "not-allowed",
                  }}
                >
                  +Add New
                </Button> */}
                  <Typography
                    style={{
                      cursor: "pointer",
                      marginTop: "10px",
                      color: "black",
                      fontSize: "13px",
                      textDecoration: "underline",
                      marginTop: "16px",
                    }}
                  >
                    {/* EXPORT FILE */}
                  </Typography>
                </Grid>
              </Grid>
              <Box
                style={{
                  display: "flex",
                  margin: "4px",
                }}
              >
                <Box style={{ margin: 'auto 0' }}>
                  <span style={{ fontWeight: "500" }}>
                    Showing{" "}
                    <span style={{ fontWeight: "700" }}> {allAdminsInvoices?.length} Invoices </span>
                  </span>
                </Box>

                <Box style={{ display: "flex", marginLeft: "auto", gap: "50px" }}>
                  <Box display="flex" flexDirection="row">
                    <FormControl>
                      <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" onChange={(e) => setPaymentFilter(e.target.value)}>
                        <FormControlLabel value="paid" control={<Radio />} label="Paid " />
                        <FormControlLabel value="unpaid" control={<Radio />} label="Unpaid" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box style={{ display: "flex", gap: "10px" }}>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* <DatePicker label="select date filter"/> */}
                        <DatePicker label={'"month" and "year"'}
                          value={selectedDate}
                          views={['month', 'year']}
                          onChange={handleDateChange}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      sx={{ background: "#F8F9FA !important", border: "none" }}
                    >
                      {/* <TableCell>
                      <Checkbox
                        {...label1}
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </TableCell> */}

                      {/* <TableCell className={classes.root}>
                      <div
                        style={{
                          display: "flex",
                          width:'10vw',
                         
                        }}
                      >
                        <span>
                          INVOICE NO.
                          <KeyboardArrowDownIcon sx={{ paddingTop: "10px" }}/>
                        </span>
                      </div>  
                    </TableCell> */}
                      <TableCell className={classes.root}>
                        ACCOUNT NAME{" "}
                        <KeyboardArrowDownIcon sx={{ paddingTop: "10px" }} />
                      </TableCell>

                      <TableCell className={classes.root}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <span>
                            INVOICE DATE
                            <KeyboardArrowDownIcon sx={{ paddingTop: "10px" }} />
                          </span>
                        </div>
                      </TableCell>

                      {/* <TableCell className={classes.root} align="right">
                      DUE DATE{" "}
                      <KeyboardArrowDownIcon sx={{ paddingTop: "10px" }} />
                    </TableCell> */}
                      <TableCell className={classes.root} align="right">
                        TOTAL AMOUNT DUE{" "}
                        <KeyboardArrowDownIcon sx={{ paddingTop: "10px" }} />
                      </TableCell>

                      <TableCell className={classes.root} align="right">
                        STATUS{" "}
                        <KeyboardArrowDownIcon sx={{ paddingTop: "10px" }} />
                      </TableCell>

                      <TableCell className={classes.root} align="right">
                        ACTION{" "}
                        <KeyboardArrowDownIcon sx={{ paddingTop: "10px" }} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ background: "white" }}>
                    {allAdminsInvoices?.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          align="center"
                          style={{ fontWeight: "bold", background: "#f8f9fe" }}
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      allAdminsInvoices?.map((row, index, id) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <TableCell>
                          <Checkbox
                            {...label1}
                            key={row.id}
                            type="checkbox"
                            id={row.id}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(row.id)}
                          />
                        </TableCell> */}

                          {/* <TableCell>{row.invoice_number}</TableCell> */}
                          <TableCell component="th" scope="row" align="center">
                            <div
                              style={{
                                gap: "10px",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <span>{row?.enterprise[0]?.name}</span>
                              </div>

                              <div>
                                <button
                                  style={{
                                    height: "auto",
                                    padding: "5px",
                                    width: "85px",
                                    // maxWidth: "auto",
                                    borderRadius: "24px",
                                    border: "none",
                                    padding: "5px",
                                    color: "white",
                                    backgroundColor: "#4CD964",
                                  }}
                                >
                                  Enterprise
                                </button>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="center" className="textcolorchange">
                            {row?.month}-{row?.year}
                          </TableCell>

                          {/* {row.due_date} */}
                          {/* <TableCell align="center" className="textcolorchange">
                          {row?.month}-{row?.year}
                        </TableCell> */}

                          <TableCell align="center" className="textcolorchange">
                            {row?.total_amount_due}
                          </TableCell>

                          <TableCell align="center" className="textcolorchange">
                            {row?.status == 'paid' ? <span style={{ color: 'rgb(76, 217, 100)' }}><b>PAID</b></span> : <span style={{ color: 'red' }}>UNPAID</span>}

                          </TableCell>

                          <TableCell align="center">
                            <img
                              src={EyeView}
                              alt="eyeView"
                              onClick={() => navigate(`/invoice_list/${row?.enterprise_id}/month/${row?.month}/year/${row?.year}`)}
                              className={classes.eyes}
                            />
                            {/* &nbsp;&nbsp;
                          <img
                            src={download}
                            alt="delete"
                            className={classes.eyes}
                          /> */}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <Grid>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, page) => setCurrentPage(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Grid> */}
              </Box>{" "}
            </Container>
          </div>
        </div>
      )
      }
    </>
  );
};
export default InvoicesPage;
