import GrpHealthcoinLogo from "../../asset/image/GrpHealthcoinLogo.svg";
import Email from "../../asset/image/email.svg";
import Password from "../../asset/image/password.svg";
import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { ResetPasswordEnterprise } from "../../redux/action/user";
import "./ResetPassword.css";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  FormControlLabel,
  Link,
} from "@mui/material";
const useStyles = makeStyles({
  card: {
    width: "445px",
    height: "553px",
    borderRadius: "20px",
  },
  groupLogo: {
    width: "261px",
  },
  topLogo: {
    width: "223px",
    height: "63px",
  },
  healthcoinlogo: {
    width: "273px",
    height: "49px",
  },

  nthchildTwo: {
    width: "205px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(9, 15, 71, 0.45)",
  },
  viaEmailBox: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  viaMobileBox: {
    display: "flex",
    // marginLeft: "108px",
    justifyContent: "center",
    alignContent: "center",
    marginTop: "12px",
  },
  sbmitButtonBox: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: "53px",
  },
});
const GetCode = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const { email } = location.state;
  const email = location.state?.email;
  const [value, setValue] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const submitForm = (values) => {
    // dispatch(ResetPasswordEnterprise(values.email, 1));
    navigate("/setPassword");
  };

  return (
    <Box
      sx={{
        height: "100vh",
      }}
      className="mainBox"
    >
      <Card className={classes.card}>
        <CardContent>
          {/* health coin logo */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "coloumn",
              justifyContent: "center",
              alignContent: "center",
              //   margin: "10px",
            }}
          >
            <img src={GrpHealthcoinLogo} className={classes.groupLogo} />
          </Box>
          <Box
            style={{
              display: "grid",
              alignContent: "center",
              justifyContent: "center",
              flexDirection: "coloumn",
              margin: "32px",
              gap: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Typography sx={{ ml: 5, fontSize: "19px", fontWeight: "700" }}>
                Reset Password
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                <span style={{ fontSize: "15px", color: "grey" }}>
                  How do you want to get the code to reset
                </span>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <span style={{ fontSize: "15px", color: "grey" }}>
                  {" "}
                  your password?{" "}
                </span>
              </Box>
            </Box>
          </Box>
          {/* send code via email */}
          <RadioGroup
            aria-label="options"
            name="options"
            value={value}
            onChange={handleChange}
          >
            <Box className={classes.viaEmailBox}>
              <FormControlLabel
                value="a"
                onChange={handleChange}
                control={<Radio checked={true} />}
              />
              <Box sx={{ display: "grid" }}>
                <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                  Send code via email or phone number
                </Typography>
                <Typography sx={{ fontSize: "15px" }}>{email}</Typography>
              </Box>
            </Box>
            {/* send code via number */}
            {/* <Box className={classes.viaMobileBox}>
              <FormControlLabel
                value="b"
                onChange={handleChange}
                control={<Radio disabled />}
              />
              <Box sx={{ display: "grid" }}>
                <Typography sx={{ fontSize: "15px" }}>
                  Send code via mobile number
                </Typography>
                <Typography sx={{ fontSize: "15px" }}>
                  +1(***)***-**35
                </Typography>
              </Box>
            </Box> */}
          </RadioGroup>
          <Box className={classes.sbmitButtonBox}>
            <Button
              style={{
                color: "white ",
                background: " #4157FF ",
                borderRadius: "56px ",
                border: "none",
                width: "203px ",
                height: "45px ",
                cursor: "pointer ",
                fontWeight: "600 ",
              }}
              onClick={submitForm}
            >
              SUBMIT
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GetCode;
