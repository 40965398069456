import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Divider,
	Box,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link, useNavigate } from 'react-router-dom';

// redux toolkit
import { setValue } from '../../../redux/action/tabAction';
import { useDispatch, useSelector } from 'react-redux';
import { server } from '../../../redux/store';

import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles({
	buttonAddnew: {
		fontSize: '24px',
		fontWeight: '500',
	},
});

const PaymentGuidanceModel = ({
	accountStatus,
	closeAccountStatusDialogue,
	openAccountStatusDialogue,
}) => {
	const navigate = useNavigate();
	const value = useSelector(state => state.counter.value);
	const { bankAccountDetails } = useSelector(state => state.user);
	const { user } = useSelector(state => state.user);
	const dispatch = useDispatch();
	const handleChange = () => {
		dispatch(setValue('3'));
		navigate('/settings');
	};

	const PaymentModel = {
		width: '153px',
		height: '46px',
		borderRadius: '24px',
		textTransform: 'none',
		color: 'white',
		background: '#4157FF',
		fontWeight: 700,
		fontSize: '15px',
	};

	const PaymentSetting = {
		width: '153px',
		height: '46px',
		borderRadius: '24px',
		textTransform: 'none',
		border: 'none',
		color: 'white',
		background: '#4157FF',
		fontWeight: 700,
		fontSize: '15px',
		cursor: 'pointer',
	};

	const handleClose = () => {
		closeAccountStatusDialogue();
	};

	const classes = useStyles();

	return (
		<div>
			<Dialog
				open={accountStatus}
				onClose={handleClose}
				maxWidth='md'
				style={{ borderRadius: '10px' }}
			>
				{' '}
				{/* maxWidth="sm" ,md,lg */}
				<DialogTitle className={classes.buttonAddnew}>
					<IconButton
						onClick={handleClose}
						style={{ position: 'absolute', top: 0, right: 0 }}
					>
						<Box
							style={{
								background: '#4157FF',
								width: '42px',
								height: '42px',
								fontSize: '11px  ',
								boxShadow: 'none',
								borderRadius: '24px',
							}}
						>
							<CloseIcon sx={{ margin: '8px 5px', color: 'white' }} />
						</Box>
					</IconButton>
				</DialogTitle>
				<DialogContent
					// sx={{ marginTop: "10px" }}
					style={{ minWidth: '250px', height: '290px', overflow: 'hidden' }}
				>
					<Box
						sx={{
							display: 'flex',
							gap: '43px',
							margin: '1px 22px',
							marginTop: '62px',
							flexDirection: 'column',
							textAlign: 'center',
							justifyContent: 'center',
						}}
					>
						<span
							style={{
								width: '357px',
								height: '55px',
								// textAlign: "justify",
								fontSize: '13px',
								margin: '1px 22px',
								fontWeight: '600',
								marginLeft: '58px',
							}}
						>
							Please set up your stripe account first by clicking on the
							settings button.
						</span>
					</Box>
					<Box
						sx={{
							display: 'grid',
							justifyContent: 'center',
							alignContent: 'center',
							marginTop: '60px',
							flexDirection: 'column',
						}}
					>
						<Button onClick={handleChange} style={PaymentModel}>
							Settings
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default PaymentGuidanceModel;
